import {
  RelativePercentTopFiveActivity,
  TeamMembersPoints,
  ConnectionPointsByPostType,
  DashBoardData
} from '../../../../Types';
import {
  SetTopActivitiesAction,
  SetTeamMembersPointsAction,
  SetTopConnectionAction,
  SetDashBoardDataAction,
  SET_TOP_ACTIVITIES,
  SET_TEAM_MEMBERS_POINTS,
  SET_TOP_CONNECTION,
  SET_DASHBOARD_DATA
} from './types';

export const setTopActivities = (
  topActivities: RelativePercentTopFiveActivity[]
): SetTopActivitiesAction => ({
  type: SET_TOP_ACTIVITIES,
  topActivities
});

export const setTeamMembersPoints = (
  teamMembersPoints: TeamMembersPoints[]
): SetTeamMembersPointsAction => ({
  type: SET_TEAM_MEMBERS_POINTS,
  teamMembersPoints
});

export const setTopConnection = (
  topConnection: ConnectionPointsByPostType[]
): SetTopConnectionAction => ({
  type: SET_TOP_CONNECTION,
  topConnection
});

export const setDashBoardData = (dashBoardData: DashBoardData): SetDashBoardDataAction => ({
  type: SET_DASHBOARD_DATA,
  dashBoardData
});

export default {
  setTopActivities,
  setTeamMembersPoints,
  setTopConnection,
  setDashBoardData
};
