import React, { FC } from 'react';
import moment from 'moment';
import { Post } from '../../../../lib/Types';
import { heartOutline, heartFilled } from '../../../../assets/icons';
import {
  CommentAction,
  CommentBody,
  CommentColumn,
  CommentContainer,
  CommentStat,
  CommentStats,
  CommentUser,
  CommentProfilePicture,
  CommentMainColumn,
  CommentImage
} from './Styles';
import ContextMenu from '../ContextMenu';
import VideoPlayer from '../../VideoPlayer/VideoPlayer';

interface CommentProps {
  post: Post;
  onLike: () => void;
  onDelete: () => void;
  handleReport: (postId: number) => void;
}

const Comment: FC<CommentProps> = ({
  post: {
    message,
    likes,
    liked,
    date,
    image,
    video,
    user: { firstName, lastName, profileImage },
    postId
  },
  onLike,
  onDelete,
  handleReport
}) => (
  <CommentContainer>
    <CommentColumn>
      <CommentProfilePicture src={profileImage} alt={`${firstName} ${lastName}`} />
    </CommentColumn>
    <CommentMainColumn>
      <CommentBody>
        {image && <CommentImage src={image} alt={`Picture posted by ${firstName} ${lastName}`} />}
        {video && <VideoPlayer video={video} />}
        <CommentUser>{`${firstName} ${lastName} `}</CommentUser>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span>{message}</span>
      </CommentBody>
      <CommentStats>
        <CommentStat>{moment(date, 'x').fromNow()}</CommentStat>
        <CommentStat>{`${likes} Like${likes !== 1 ? 's' : ''}`}</CommentStat>
      </CommentStats>
    </CommentMainColumn>
    <CommentColumn>
      <ContextMenu handleDelete={onDelete} handleReport={() => handleReport(postId)} />
      <CommentAction onClick={onLike} src={liked ? heartFilled : heartOutline} />
    </CommentColumn>
  </CommentContainer>
);

export default Comment;
