import React, { FC } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import { useDropzone } from 'react-dropzone';

import { resizeImage } from '../../../../lib/Utils';

import { ImageViewer } from '../../../UI';
import { commentImage, commentVideo, pencilWhite, closeWhite } from '../../../../assets/icons';
import { audioFileIcon } from '../../../../assets/img';

import {
  createReply,
  CreateReply,
  createPost,
  CreatePost,
  uploadPostMedia,
  UploadPostMedia
} from '../../../../lib/Api/Posts';
import { Post, ApiStatus } from '../../../../lib/Types';

import {
  Form,
  Submit,
  CommentFormBody,
  CommentFormContainer,
  CommentFormControls,
  CommentFormMedia,
  CommentFormInput,
  CommentFormProfileImage,
  CommentFormImageContainer,
  CommentFormTitle,
  CommentFormMediaError,
  CommentFormVideoContainer,
  CommentFormVideoRow,
  CommentFormVideoIcon,
  CommentFormMediaControl,
  CommentFormImageRow
} from './Styles';
import {
  MAX_VIDEO_SIZE,
  MAX_IMAGE_SIZE,
  MAX_IMAGE_SIZE_LABEL,
  MAX_VIDEO_SIZE_LABEL
} from '../../../../CONSTANTS';
import { CreateReplySchema } from '../../../../lib/validators/createReplySchema';
import { CreatePostSchema } from '../../../../lib/validators/createPostSchema';
import { PostMediaButton } from '../Styles';

interface CommentFormProps {
  parentPostId?: number;
  companyIds?: number[];
  userId: string;
  eventId: number;
  profileImage: string;
  title?: string;
  placeholder?: string;
  buttonLabel?: string;
  isReply: boolean;
  onSuccess?: (data: Post) => void; // For refreshing parent page when new comment added
  onCreateReply: CreateReply;
  onCreatePost: CreatePost;
  onUploadMedia: UploadPostMedia;
}

const formikEnhancer = withFormik<CommentFormProps, any>({
  mapPropsToValues: ({ parentPostId, companyIds, userId, eventId }) => ({
    parentPostId: parentPostId || 1, // Satisfy validation but not actually used
    companyIds,
    userId,
    message: '',
    eventId
  }),
  handleSubmit: async (
    { parentPostId, companyIds, userId, message, image, video, audio, eventId },
    {
      props: { isReply, onSuccess, onCreateReply, onCreatePost, onUploadMedia },
      setFieldError,
      resetForm
    }
  ) => {
    if (!message || message.match(/^ *$/))
      return setFieldError('message', 'Please write something');
    const replyData: CreateReplySchema = { userId, message, parentPostId, eventId };
    const postData: CreatePostSchema = { userId, message, companyIds, eventId };
    const data = !!isReply && !!parentPostId ? replyData : postData;
    if (image || video || audio) {
      const { data: uri, status: mediaUpload } = await onUploadMedia(
        (image || video || audio) as any
      );

      if (mediaUpload === ApiStatus.SUCCESS) {
        if (!!image) {
          data.image = uri;
        } else if (!!video) {
          data.video = uri;
        } else if (!!audio) {
          data.audio = uri;
        }
      } else {
        return setFieldError(!!image ? 'image' : !!video ? 'video' : 'audio', 'Unable to upload');
      }
    }

    const { data: post, status } = await (!!isReply && !!parentPostId
      ? onCreateReply
      : onCreatePost)(data);
    if (status === ApiStatus.SUCCESS) {
      if (onSuccess) onSuccess(post);
      resetForm();
    } else setFieldError('message', 'Unable to submit comment, please try again later');
  }
});

const CommentForm: FC<CommentFormProps & FormikProps<any>> = ({
  profileImage,
  title,
  placeholder = "What's on your mind?",
  buttonLabel = 'Submit My Comment',
  values,
  errors,
  handleChange,
  handleSubmit,
  setFieldError,
  setFieldValue,
  isSubmitting
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: 'image/jpeg,image/png,video/*',
    maxSize: MAX_VIDEO_SIZE,
    onDropAccepted: async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const type = file.type.split('/')[0];
      const isVideo = type === 'video';
      const isImage = type === 'image';
      const isAudio = type === 'audio';
      if (isImage && file.size > MAX_IMAGE_SIZE)
        return setFieldError('image', `Image must be JPG/PNG <${MAX_IMAGE_SIZE_LABEL}`);
      if (!isImage && !isVideo) {
        return setFieldError('image', 'File must be jpg/png, Audio or a video');
      }
      if (file) {
        if (isVideo) {
          setFieldValue('video', file);
          setFieldValue('image', null);
          setFieldValue('audio', null);
        } else if (isAudio) {
          setFieldValue('audio', file);
          setFieldValue('video', null);
          setFieldValue('image', null);
        } else {
          const image = await resizeImage(file);
          setFieldValue('image', image);
          setFieldValue('video', null);
          setFieldValue('audio', null);
        }
      }
    },
    onDropRejected: (rejectedFiles: File[]) => {
      const file = rejectedFiles[0];
      const isVideo = file.type.split('/')[0] === 'video';
      const isAudio = file.type.split('/')[0] === 'audio';
      if (isVideo) setFieldError('video', `Video must be <${MAX_VIDEO_SIZE_LABEL}`);
      else if (isAudio) setFieldError('audio', `Audio must be <${MAX_VIDEO_SIZE_LABEL}`);
      else setFieldError('image', `Image must be JPG/PNG <${MAX_IMAGE_SIZE_LABEL}`);
    }
  });

  return (
    <Form onSubmit={handleSubmit}>
      <CommentFormContainer>
        <CommentFormProfileImage src={profileImage} alt="Make a comment" />
        <CommentFormBody>
          <CommentFormTitle>{title && title}</CommentFormTitle>
          {values.image && (
            <CommentFormImageContainer>
              <ImageViewer src={values.image} alt="Post image" />
              <CommentFormImageRow reverse>
                <CommentFormMediaControl onClick={open}>
                  <img src={pencilWhite} />
                </CommentFormMediaControl>
                <CommentFormMediaControl onClick={() => setFieldValue('image', null)}>
                  <img src={closeWhite} />
                </CommentFormMediaControl>
              </CommentFormImageRow>
            </CommentFormImageContainer>
          )}
          {values.video && (
            <CommentFormVideoContainer>
              <CommentFormVideoRow>
                <div>
                  <CommentFormVideoIcon src={commentVideo} />
                </div>
                <div>
                  <p>{(values.video as File).name}</p>
                  <p>{((values.video as File).size / 1000000).toFixed(2)}MB</p>
                </div>
              </CommentFormVideoRow>
              <CommentFormVideoRow reverse>
                <CommentFormMediaControl onClick={open}>
                  <img src={pencilWhite} />
                </CommentFormMediaControl>
                <CommentFormMediaControl onClick={() => setFieldValue('video', null)}>
                  <img src={closeWhite} />
                </CommentFormMediaControl>
              </CommentFormVideoRow>
            </CommentFormVideoContainer>
          )}
          {values.audio && (
            <CommentFormVideoContainer>
              <CommentFormVideoRow>
                <div>
                  <CommentFormVideoIcon src={audioFileIcon} />
                </div>
                <div>
                  <p>{(values.audio as File).name}</p>
                  <p>{((values.audio as File).size / 1000000).toFixed(2)}MB</p>
                </div>
              </CommentFormVideoRow>
              <CommentFormVideoRow reverse>
                <CommentFormMediaControl onClick={open}>
                  <img src={pencilWhite} />
                </CommentFormMediaControl>
                <CommentFormMediaControl onClick={() => setFieldValue('video', null)}>
                  <img src={closeWhite} />
                </CommentFormMediaControl>
              </CommentFormVideoRow>
            </CommentFormVideoContainer>
          )}
          <CommentFormInput
            required
            textArea
            hideLabel
            id="message"
            name="message"
            type="text"
            label={placeholder}
            value={values.message}
            error={errors.message}
            onChange={handleChange}
          />
          {errors.image && <CommentFormMediaError>{errors.image}</CommentFormMediaError>}
          {errors.video && <CommentFormMediaError>{errors.video}</CommentFormMediaError>}
          <CommentFormControls {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <CommentFormMedia>
              <PostMediaButton
                role="button"
                disabled={!!values.video}
                src={commentImage}
                alt="Upload image"
                onClick={open}
              />
              <PostMediaButton
                role="button"
                disabled={!!values.image}
                src={commentVideo}
                alt="Upload Video"
                onClick={open}
              />
            </CommentFormMedia>
            <Submit
              disabled={isSubmitting}
              variant={
                !values.message || values.message.match(/^( |\n|\r|\s)*$/) ? 'disabled' : 'primary'
              }
              type="submit"
            >
              {buttonLabel}
            </Submit>
          </CommentFormControls>
        </CommentFormBody>
      </CommentFormContainer>
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onUploadMedia: uploadPostMedia as any,
      onCreateReply: createReply as any,
      onCreatePost: createPost as any
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(formikEnhancer(CommentForm));
