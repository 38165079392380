import styled from 'styled-components/macro';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Header = styled.div`
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.8rem;
  font-style: oblique;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const LegendWrapper = styled.div`
  flex: 2;
  display: table;
  border-collapse: separate;
  border-spacing: 0.5rem 1rem;
  margin-top: 2rem;
`;

export const KeyWrapper = styled.div`
  display: table-row;
`;

export const KeyIcon = styled.img`
  display: table-cell;
  width: 28px;
  height: 28px;
`;

export const KeyName = styled.div`
  display: table-cell;
  width: 8rem;
  vertical-align: middle;

  color: #000000;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 12px;
  line-height: 16.9px;

  @media (max-width: 480px) {
    width: auto;
  }
`;

export const KeyValue = styled.div`
  display: table-cell;
  vertical-align: middle;

  color: #aeaeae;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 12px;
  line-height: 16.9px;
`;

export const MascotIcon = styled.img`
  flex: 2;
  height: 292px;

  @media (max-width: 580px) {
    display: none;
  }
`;
