import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import SideBar from '../SideBar';
import PageContentHeader from './PageContentHeader';
import { footerLogo } from '../../../assets/img/index';
import {
  GlobalWrap,
  CopyrightText,
  PageWrap,
  SideBarWrap,
  MainWrap,
  HeaderWrap,
  ContentWrap,
  FooterWrap
} from './Styles';
import { ApplicationState } from '../../../lib/Store';
import { RoleCode, CompanyEventStatus } from '../../../lib/Types';

interface PageWrapperProps extends RouteComponentProps {
  code: RoleCode;
  nav?: boolean;
  event?: boolean; // show filter
  company?: boolean; // show filter
  companyEvent?: CompanyEventStatus;
  formOpener?: JSX.Element;
  handleDropdownClick?: () => void;
}

const PageWrapper: FC<PageWrapperProps> = ({
  children,
  history,
  location,
  code,
  nav = true,
  event = true,
  company = true,
  formOpener = <></>,
  handleDropdownClick = () => null
}) => {
  const handleNavClick = React.useCallback(
    (dataType: string, { data }: any) => history.push(data.path),
    [history.push]
  );

  return (
    <GlobalWrap>
      <PageWrap>
        <SideBarWrap>
          <SideBar code={code} />
        </SideBarWrap>
        <MainWrap>
          <HeaderWrap>
            <PageContentHeader
              code={code}
              nav={nav}
              navLocation={location.pathname}
              onNavClick={handleNavClick}
              handleDropdownClick={handleDropdownClick}
              event={event}
              company={company}
              formOpener={formOpener}
            />
          </HeaderWrap>
          {children}
          <ContentWrap />
        </MainWrap>
      </PageWrap>
      <FooterWrap>
        <img width="70px" src={footerLogo} alt={''} />
        <CopyrightText>&copy; 2020 Moving Mindz Pty Ltd</CopyrightText>
      </FooterWrap>
    </GlobalWrap>
  );
};

export default connect<{}, {}, PageWrapperProps>((state: ApplicationState) => ({
  user: state.userState.userData,
  code: state.permissionsState.code,
  companyEvent: state.companyAdminEventState.currentEvent
}))(withRouter(PageWrapper)) as any;
