import styled from 'styled-components/macro';

interface StyledHeaderProps {
  numbered?: boolean;
  dropable?: boolean;
}

export const StyledHeader = styled.div<StyledHeaderProps>`
  display: table-cell;
  text-align: ${props => (props.numbered ? 'center' : 'left')};
  color: ${({ theme }) => theme.primary.main};
  font-family: 'Sarabun', sans-serif;
  font-size: calc(0.9rem + 0.3vw);
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: calc((0.9rem + 0.5vw) * 1.3);
  padding: 1vw 1vw 0.75vw 0;
  border-bottom: 1px solid ${({ theme }) => theme.borders.xlight};
  text-transform: capitalize;

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    display: ${props => (props.dropable ? 'none' : 'table-cell')};
  }

  span {
    cursor: pointer;
  }
`;

export const SortIcon = styled.img`
  margin-left: 3px;
  transform: translateY(-50%);
  z-index: 1;
`;
