import styled from 'styled-components/macro';
import Paper from '../Paper';
import { DarkBlueBodyM, DarkBlueHeaderL } from '../../../Themes';

interface HoldingImageProps {
  backgroundImage: string;
  backgroundImageMob?: string;
  noEvent?: boolean;
}

export const ImageContainer = styled(Paper)<{ noEvent?: boolean }>`
  height: ${({ noEvent }) => (noEvent ? '650px' : 'calc(100vh - 4rem)')};
  padding: 0;
  overflow: hidden;
`;

export const HoldingPageHeader = styled(DarkBlueHeaderL)`
  margin-bottom: 1rem;

  @media (max-width: ${({ theme }) => theme.device.mobileL + 100}px) {
    margin-top: 2.5rem;
  }
`;

export const NoEventsHeader = styled(DarkBlueHeaderL)`
  margin-bottom: 1rem;

  @media (max-width: ${({ theme }) => theme.device.mobileL + 100}px) {
    margin-top: 2.5rem;
  }
`;

export const NoEventsBody = styled(DarkBlueBodyM)``;

export const HoldingImage = styled.div<HoldingImageProps>`
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-size: ${({ noEvent }) => (noEvent ? '104%' : '100%')};
  background-position: ${({ noEvent }) => (noEvent ? '-1px bottom' : 'top left')};;
  background-repeat: no-repeat;
  width: 100%; height: 100%;

  
  ${({ noEvent }) =>
    noEvent &&
    `
      padding: 3rem;
    `}

  @media (max-width: ${({ theme }) => theme.device.mobileL + 100}px) {
    background-image: url('${({ backgroundImage, backgroundImageMob }) =>
      backgroundImageMob ? backgroundImageMob : backgroundImage}');
    background-position: 0 -2.5rem;
  }
`;
