import { PaginationFilter, Post } from '../../../Types';

export const SET_POSTS = 'SET_POSTS';
export const ADD_POST = 'ADD_POST';
export const UPDATE_POST = 'UPDATE_POSTS_POST';
export const ADD_POSTS = 'ADD_POSTS';
export const UPDATE_POSTS_FILTER = 'UPDATE_POSTS_FILTER';
export const UPDATE_REPLIES_FILTER = 'UPDATE_REPLIES_FILTER';
export const SET_ALL_LOADED = 'SET_ALL_POSTS_LOADED';
export const SET_ALL_REPLIES_LOADED = 'SET_ALL_REPLIES_LOADED';
export const SET_MINI_CHALLENGES = 'SET_MINI_CHALLENGES';
export const UPDATE_MINI_CHALLENGE = 'UPDATE_MINI_CHALLENGE';

export type PrimaryPostTypeFilter = 'MINI_CHALLENGES' | 'DAILY_ACTIVITIES' | 'GENERAL_ACTIVITIES';
export type SecondaryPostTypeFilter = 'ALL' | 'COMPLETED' | 'TO_DO' | 'MY_TEAM' | 'MY_POSTS';

export interface ExtendedPostsFilter extends PaginationFilter {
  primaryPostTypeFilter?: PrimaryPostTypeFilter;
  secondaryPostTypeFilter?: SecondaryPostTypeFilter;
}

export interface PostsState {
  posts: Post[];
  filter: ExtendedPostsFilter;
  repliesFilter: PaginationFilter;
  allLoaded: boolean;
  allRepliesLoaded: boolean;
  miniChallenges: Post[];
}

export interface SetPostsAction {
  type: typeof SET_POSTS;
  posts: Post[];
}

export interface AddPostAction {
  type: typeof ADD_POST;
  post: Post;
}

export interface UpdatePostAction {
  type: typeof UPDATE_POST;
  postData: Partial<Post>;
}

export interface AddPostsAction {
  type: typeof ADD_POSTS;
  posts: Post[];
}

export interface UpdatePostsFilterAction {
  type: typeof UPDATE_POSTS_FILTER;
  filter: Partial<ExtendedPostsFilter>;
}

export interface UpdateRepliesFilterAction {
  type: typeof UPDATE_REPLIES_FILTER;
  repliesFilter: Partial<PaginationFilter>;
}

export interface SetAllLoadedAction {
  type: typeof SET_ALL_LOADED;
  allLoaded: boolean;
}

export interface SetAllRepliesLoadedAction {
  type: typeof SET_ALL_REPLIES_LOADED;
  allRepliesLoaded: boolean;
}

export interface SetMiniChallengesAction {
  type: typeof SET_MINI_CHALLENGES;
  miniChallenges: Post[];
}

export interface UpdateMiniChallengeAction {
  type: typeof UPDATE_MINI_CHALLENGE;
  miniChallengeData: Partial<Post>;
}

export type PostsActionTypes =
  | SetPostsAction
  | AddPostAction
  | UpdatePostAction
  | AddPostsAction
  | UpdatePostsFilterAction
  | UpdateRepliesFilterAction
  | SetAllLoadedAction
  | SetAllRepliesLoadedAction
  | SetMiniChallengesAction
  | UpdateMiniChallengeAction;
