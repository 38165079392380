import * as yup from 'yup';
import CreateTeamMemberSchema from './CreateTeamMemberSchema';
import { ApprovalStatus } from '../../../../lib/Types';

const createTeamSchema = yup.object({
  active: yup.boolean(),
  eventId: yup.number().required(),
  name: yup
    .string()
    .max(25, 'Must be 25 characters or less')
    .required(),
  captain: yup.object().shape({
    userId: yup.string().nullable(true),
    joinedStatus: yup.boolean().required(),
    teamCaptain: yup.boolean().required()
  }),
  approvalStatus: yup.string().oneOf(Object.keys(ApprovalStatus)),
  companyId: yup.number().required(),
  teamMembers: yup
    .array()
    .of(CreateTeamMemberSchema)
    .required()
});

export type CreateTeamSchema = yup.InferType<typeof createTeamSchema>;

export default createTeamSchema;
