import { Team, UserTeamRegistration, IndividualEventRegistration } from '../../../../Types';

import {
  SetTeamAction,
  SetTeamMemberRegistrationsAction,
  SetIndividualEventRegistrationAction,
  SetTeamDataAction,
  SET_TEAM,
  SET_TEAM_MEMBER_REGISTRATIONS,
  SET_INDIVIDUAL_EVENT_REGISTRATION,
  SET_TEAM_DATA
} from './types';

export const setTeam = (team: Team): SetTeamAction => ({
  type: SET_TEAM,
  team
});

export const setTeamMemberRegistrations = (
  teamMemberRegistrations: UserTeamRegistration[]
): SetTeamMemberRegistrationsAction => ({
  type: SET_TEAM_MEMBER_REGISTRATIONS,
  teamMemberRegistrations
});

export const setIndividualEventRegistration = (
  individualEventRegistration: IndividualEventRegistration
): SetIndividualEventRegistrationAction => ({
  type: SET_INDIVIDUAL_EVENT_REGISTRATION,
  individualEventRegistration
});

export const setTeamData = (
  team: Team,
  teamMemberRegistrations: UserTeamRegistration[],
  individualEventRegistration: IndividualEventRegistration
): SetTeamDataAction => ({
  type: SET_TEAM_DATA,
  team,
  teamMemberRegistrations,
  individualEventRegistration
});

export default {
  setTeam,
  setTeamMemberRegistrations,
  setIndividualEventRegistration,
  setTeamData
};
