import React, { FC } from 'react';
import { Group, Heading } from '../Styles';
import { Input } from '../../../../../components/UI';
import { FormikFormComponent, Company, Team } from '../../../../../lib/Types';
import { ParticipantSchema } from '../../../validators/participantSchema';
import theme from '../../../../../theme';

interface PersonalDetailsProps extends FormikFormComponent<ParticipantSchema> {
  companyOptions: Company[];
  participantTeam: Team;
}

const PersonalDetails: FC<PersonalDetailsProps> = ({ values, errors, handleChange }) => (
  <Group>
    <Heading>Personal Details</Heading>
    <Input
      required
      id="firstName"
      name="firstName"
      value={values.firstName}
      error={errors.firstName}
      label="First Name"
      type="text"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
    <Input
      required
      id="lastName"
      name="lastName"
      value={values.lastName}
      error={errors.lastName}
      label="Last Name"
      type="text"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
    <Input
      required
      id="email"
      name="email"
      value={values.email}
      error={errors.email}
      label="Email"
      type="text"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
  </Group>
);

export default PersonalDetails;
