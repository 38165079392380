import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector, connect } from 'react-redux';
import moment from 'moment';

import { LoadEventFilter, loadEventOptions, LoadEventOptions } from '../../lib/Api/Event';
import { fetchRewardOptions, FetchRewardOptions } from '../../lib/Api/Rewards';
import {
  createPost,
  createSpecialPost,
  CreatePost,
  CreateSpecialPost,
  uploadPostMedia,
  UploadPostMedia
} from '../../lib/Api/Posts';

import { PageWrapper } from '../../components/UI';
import PostMeta from './PostMeta';
import WritePost from './WritePost';

import { ModalTitle, TitleWrapper, BackBtn } from './Styles';
import { backArrow } from '../../assets/icons';

import { PostCategory, PostType, RoleCode, Event, Post, ApiStatus } from '../../lib/Types';
import { ApplicationState } from '../../lib/Store';

import { setNotification } from '../../lib/Store/contexts/notification/actions';

export type SpecialPostMetaData = {
  type: PostType;
  category: PostCategory;
  event: Event;
  companyIds: number[];
};

interface CreatePostScreenProps {
  fetchEventOptions: LoadEventOptions;
  fetchRewardOptions: FetchRewardOptions;
  onUploadMedia: UploadPostMedia;
  submitGeneralPost: CreatePost;
  submitSpecialPost: CreateSpecialPost;
}

enum FormState {
  Meta = 1,
  WritePost,
  Submit
}

const CreatePostScreen: React.FC<CreatePostScreenProps> = ({
  fetchEventOptions,
  fetchRewardOptions,
  onUploadMedia,
  submitGeneralPost,
  submitSpecialPost
}) => {
  const Dispatch = useDispatch();
  const {
    code,
    eventOptions,
    currentCompanyEvent,
    rewardOptions,
    company,
    userEvent
  } = useSelector(
    ({
      permissionsState,
      eventState,
      companyAdminEventState,
      rewardsState,
      userState
    }: ApplicationState) => ({
      code: permissionsState.code,
      eventOptions: eventState.eventOptions,
      currentCompanyEvent: companyAdminEventState.currentEvent,
      userEvent: userState.userEvent,
      rewardOptions: rewardsState.rewardOptions,
      company: userState.userCompany
    })
  );

  const masterAdminPost = code === RoleCode.MasterAdmin;

  const [postMeta, setPostMeta] = useState<SpecialPostMetaData>(null);
  const [formState, setFormState] = React.useState<FormState>(
    masterAdminPost ? FormState.Meta : FormState.WritePost
  );

  const handleCompleteMeta = (metaData: SpecialPostMetaData) => {
    setPostMeta(metaData);
    setFormState(FormState.WritePost);
  };

  const handleSubmit = async (postData: Partial<Post>): Promise<boolean> => {
    const { image, video, audio } = postData;
    const data = {
      image: '',
      video: '',
      audio: '',
      type: '',
      eventId: NaN,
      companyIds: []
    };
    Object.assign(data, postData);
    if (image || video || audio) {
      const { data: uri, status: mediaUpload } = await onUploadMedia(
        (image || video || audio) as any
      );
      if (mediaUpload === ApiStatus.SUCCESS) {
        if (!!image) {
          data.image = uri;
        } else if (!!video) {
          data.video = uri;
        } else if (!!audio) {
          data.audio = uri;
        }
      } else {
        Dispatch(setNotification({ variant: 'warning', message: 'Error uploading media' }));
      }
    }
    if (masterAdminPost) {
      Object.assign(data, postMeta);
      data.eventId = postMeta.event.eventId;
    } else {
      data.eventId =
        code === RoleCode.CompanyAdmin ? currentCompanyEvent.eventId : userEvent.eventId;
      data.companyIds = [company.companyId];
    }

    const isSpecial = masterAdminPost && postMeta.type !== PostType.General;
    if (!isSpecial) delete data.type;
    const { status } = await (isSpecial ? submitSpecialPost : submitGeneralPost)(data);

    if (status === ApiStatus.SUCCESS) {
      setFormState(masterAdminPost ? FormState.Meta : FormState.WritePost);
      setPostMeta(null);
      setPostMeta(null);
      return true;
    }
  };

  useEffect(() => {
    if (!masterAdminPost) return;
    (async () => {
      // Load event options - filter by current or future events
      await fetchEventOptions({
        endDateMoreThan: moment()
          .valueOf()
          .toString()
      });
      await fetchRewardOptions();
    })();
  }, [Dispatch, code]);

  const renderContent = () => {
    if (masterAdminPost && (eventOptions || []).length === 0) {
      return (
        <PageWrapper event={false} company={false}>
          <TitleWrapper>
            <ModalTitle>There are no events to post to.</ModalTitle>
          </TitleWrapper>
        </PageWrapper>
      );
    }
    if (!masterAdminPost && !currentCompanyEvent) {
      return (
        <PageWrapper event={false} company={false}>
          <TitleWrapper>
            <ModalTitle>Your company is not part of any events to post to.</ModalTitle>
          </TitleWrapper>
        </PageWrapper>
      );
    }
    switch (formState) {
      case FormState.Meta:
        return (
          <PageWrapper event={false} company={false}>
            <TitleWrapper>
              <ModalTitle>Create a Post</ModalTitle>
            </TitleWrapper>
            <PostMeta events={eventOptions} onLoadValues={postMeta} onSubmit={handleCompleteMeta} />
          </PageWrapper>
        );
      case FormState.WritePost:
        return (
          <PageWrapper event={false} company={false}>
            <TitleWrapper>
              {masterAdminPost && (
                <BackBtn alt="back" src={backArrow} onClick={() => setFormState(FormState.Meta)} />
              )}
              <ModalTitle>Write Your Post</ModalTitle>
            </TitleWrapper>
            <WritePost
              postMeta={postMeta}
              companyAdminPost={!masterAdminPost}
              currentCompanyEvent={currentCompanyEvent}
              rewardOptions={rewardOptions}
              onSubmit={handleSubmit}
              back={() => setFormState(FormState.Meta)}
            />
          </PageWrapper>
        );
      default:
        return (
          <PageWrapper event={false} company={false}>
            <TitleWrapper>
              <ModalTitle>There are no events to post to.</ModalTitle>
            </TitleWrapper>
          </PageWrapper>
        );
    }
  };

  return renderContent();
};

export default connect(null, dispatch =>
  bindActionCreators(
    {
      fetchEventOptions: (filter?: LoadEventFilter) => loadEventOptions(filter) as any,
      fetchRewardOptions: fetchRewardOptions as any,
      onUploadMedia: uploadPostMedia as any,
      submitGeneralPost: createPost as any,
      submitSpecialPost: createSpecialPost as any
    },
    dispatch
  )
)(CreatePostScreen);
