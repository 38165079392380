import styled from 'styled-components/macro';

import { ActionButtonProps } from './ActionButton';

export const StyledButton = styled.button<ActionButtonProps>`
  align-items: center;
  outline: none;
  background: ${({ backgroundColour }) => (backgroundColour ? backgroundColour : 'transparent')};
  border-radius: 5rem;
  border: ${({ border }) => (border ? border : 'none')};
  color: ${({ darkText, textColourOverride, theme }) => {
    if (darkText) return theme.primary.dark;
    if (textColourOverride) return textColourOverride;
    return 'white';
  }};
  display: flex;
  font-size: 14px;
  justify-content: center;
  opacity: ${({ opaque }) => (opaque ? 0.5 : 1)};
  padding: 0.8rem 2rem;
  font-weight: semibold;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  transition: all 0.1s linear;
  max-width: ${({ maxWidth }) => maxWidth};

  &:hover {
    transform: ${({ disabled }) => !disabled && 'scale(1.08)'};
  }
`;

export const ButtonIcon = styled.img<{ inverted: boolean; noMargin: boolean }>`
  margin-right: ${({ noMargin }) => !noMargin && '0.8rem'};
  transform: rotate(${({ inverted }) => (inverted ? '180deg' : 0)});
`;
