import React, { FC } from 'react';
import PageWrapper from '../../components/UI/PageWrapper';
import SDAListView from './components/SDAListView';

const SuspectDailyActivities: FC = () => (
  <PageWrapper>
    <SDAListView />
  </PageWrapper>
);

export default SuspectDailyActivities;
