export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const AUTH_LOADING_SUCCESS = 'AUTH_LOADING_SUCCESS';
export const AUTH_LOADING_ERROR = 'AUTH_LOADING_ERROR';
export const SET_ACTIVITY_LOADING = 'SET_ACTIVITY_LOADING';

export const SET_API_LOADING = 'SET_API_LOADING';
export const API_LOADING_SUCCESS = 'API_LOADING_SUCCESS';
export const API_LOADING_ERROR = 'API_LOADING_ERROR';

export const SET_LOADING_SPINNER = 'SET_LOADING_SPINNER';
export const SET_LOADING_PERCENT = 'SET_LOADING_PERCENT';

export interface LoadingState {
  authLoading: boolean;
  apiCallsInProgress: number;
  loadingSpinner: boolean;
  loadingPercent: number;
}

export interface SetAuthLoadingAction {
  type: typeof SET_AUTH_LOADING;
}

export interface SetActivityLoadingAction {
  type: typeof SET_ACTIVITY_LOADING;
}

export interface AuthLoadingSuccessAction {
  type: typeof AUTH_LOADING_SUCCESS;
}

export interface AuthLoadingErrorAction {
  type: typeof AUTH_LOADING_ERROR;
}

export interface SetApiLoadingAction {
  type: typeof SET_API_LOADING;
}

export interface ApiLoadingSuccessAction {
  type: typeof API_LOADING_SUCCESS;
}

export interface ApiLoadingErrorAction {
  type: typeof API_LOADING_ERROR;
}

export interface SetLoadingSpinnerAction {
  type: typeof SET_LOADING_SPINNER;
  status: boolean;
}

export interface SetLoadingPercentAction {
  type: typeof SET_LOADING_PERCENT;
  percent: number;
}

export type LoadingActionTypes =
  | SetAuthLoadingAction
  | SetActivityLoadingAction
  | AuthLoadingSuccessAction
  | AuthLoadingErrorAction
  | SetApiLoadingAction
  | ApiLoadingSuccessAction
  | ApiLoadingErrorAction
  | SetLoadingSpinnerAction
  | SetLoadingPercentAction;
