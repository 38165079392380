import { CompanyEventStatus, EventLinkingInfo, Event, PagedResponse } from '../../../../Types';

export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT';
export const SET_EVENT_LINKING_INFO = 'SET_EVENT_LINKING_INFO';
export const SET_EVENT_LINKING_LOADING = 'SET_EVENT_LINKING_LOADING';
export const SET_CURRENT_EVENT_LOADING = 'SET_CURRENT_EVENT_LOADING';
export const SET_PAST_EVENTS = 'SET_PAST_EVENTS';
export const SET_PAST_EVENTS_LOADING = 'SET_CURRENT_EVENT_LOADING';

export interface EventState {
  currentEvent: CompanyEventStatus;
  currentEventLoading: boolean;
  eventLinkingInfo: EventLinkingInfo;
  eventLinkingLoading: boolean;
  pastEvents: PagedResponse<Event>;
  pastEventsLoading: boolean;
}

export interface SetCurrentEventAction {
  type: typeof SET_CURRENT_EVENT;
  currentEvent: CompanyEventStatus;
}

export interface SetPastEventsAction {
  type: typeof SET_PAST_EVENTS;
  pastEvents: PagedResponse<Event>;
}

export interface SetCurrentEventLoadingAction {
  type: typeof SET_CURRENT_EVENT_LOADING;
  loading: boolean;
}

export interface SetPastEventsLoadingAction {
  type: typeof SET_PAST_EVENTS_LOADING;
  loading: boolean;
}

export interface SetEventLinkingInfoAction {
  type: typeof SET_EVENT_LINKING_INFO;
  payload: EventLinkingInfo;
}

export interface SetEventLinkingLoadingAction {
  type: typeof SET_EVENT_LINKING_LOADING;
  loading: boolean;
}

export type EventActionTypes =
  | SetCurrentEventAction
  | SetCurrentEventLoadingAction
  | SetEventLinkingInfoAction
  | SetEventLinkingLoadingAction
  | SetPastEventsAction
  | SetPastEventsLoadingAction;
