import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TabWrapper, TabText, TabFilter, Tab } from './Styles';

import { PostsState, SecondaryPostTypeFilter } from '../../../lib/Store/contexts/posts/types';
import { ApplicationState } from '../../../lib/Store';
import {
  updatePostsFilter,
  setPosts,
  setAllLoaded
} from '../../../lib/Store/contexts/posts/actions';
import { DashBoardData } from '../../../lib/Types';

export interface FeedSecondaryFilterProps {
  postEngagementData?: DashBoardData;
  onFilterChange: () => void;
}

const FeedSecondaryFilter: React.FC<FeedSecondaryFilterProps> = ({
  postEngagementData,
  onFilterChange
}) => {
  const Dispatch = useDispatch();
  const { postsState }: { postsState: PostsState } = useSelector(
    ({ postsState }: ApplicationState) => ({
      postsState
    })
  );
  const [counts, setCounts] = useState({
    completed: 0,
    toDo: 0
  });

  const primaryFilter = postsState.filter.primaryPostTypeFilter;
  const secondaryFilter = postsState.filter.secondaryPostTypeFilter;

  useEffect(() => {
    let total = 0;
    let completed = 0;
    if (postEngagementData) {
      if (primaryFilter === 'MINI_CHALLENGES' && postEngagementData.miniChallenges.length) {
        total = postEngagementData.miniChallenges.length;
        completed = postEngagementData.miniChallenges.filter(mc => mc.participated).length;
      }
      if (primaryFilter === 'DAILY_ACTIVITIES' && postEngagementData.dailyActivities.length) {
        total = postEngagementData.dailyActivities.length;
        completed = postEngagementData.dailyActivities.filter(mc => mc.participated).length;
      }
    }
    setCounts({
      completed,
      toDo: total - completed
    });
  }, [postEngagementData, primaryFilter]);

  const handleTabClick = (filter: SecondaryPostTypeFilter) => {
    if (filter === secondaryFilter) return; // Do nothing if no filter change.
    Dispatch(updatePostsFilter({ ...postsState.filter, secondaryPostTypeFilter: filter }));
    Dispatch(setPosts([]));
    Dispatch(setAllLoaded(false));
    onFilterChange();
  };

  return (
    <TabWrapper>
      <TabFilter>
        <Tab isLeft selected={secondaryFilter === 'ALL'} onClick={() => handleTabClick('ALL')}>
          {primaryFilter === 'MINI_CHALLENGES' && <TabText>All Mini Challenges</TabText>}
          {primaryFilter === 'DAILY_ACTIVITIES' && <TabText>All Daily Activity</TabText>}
          {primaryFilter === 'GENERAL_ACTIVITIES' && <TabText>All Activity</TabText>}
        </Tab>
        <Tab
          selected={secondaryFilter === 'COMPLETED' || secondaryFilter === 'MY_TEAM'}
          onClick={() => {
            (primaryFilter === 'MINI_CHALLENGES' || primaryFilter === 'DAILY_ACTIVITIES') &&
              handleTabClick('COMPLETED');
            primaryFilter === 'GENERAL_ACTIVITIES' && handleTabClick('MY_TEAM');
          }}
        >
          {(primaryFilter === 'MINI_CHALLENGES' || primaryFilter === 'DAILY_ACTIVITIES') && (
            <TabText>Completed ({counts.completed})</TabText>
          )}
          {primaryFilter === 'GENERAL_ACTIVITIES' && <TabText>My Team</TabText>}
        </Tab>
        <Tab
          isRight
          selected={secondaryFilter === 'TO_DO' || secondaryFilter === 'MY_POSTS'}
          onClick={() => {
            (primaryFilter === 'MINI_CHALLENGES' || primaryFilter === 'DAILY_ACTIVITIES') &&
              handleTabClick('TO_DO');
            primaryFilter === 'GENERAL_ACTIVITIES' && handleTabClick('MY_POSTS');
          }}
        >
          {(primaryFilter === 'MINI_CHALLENGES' || primaryFilter === 'DAILY_ACTIVITIES') && (
            <TabText>To Do ({counts.toDo})</TabText>
          )}
          {primaryFilter === 'GENERAL_ACTIVITIES' && <TabText>My Posts</TabText>}
        </Tab>
      </TabFilter>
    </TabWrapper>
  );
};

export default FeedSecondaryFilter;
