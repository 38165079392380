import React, { FC, MouseEvent } from 'react';
import { ListWrapper, ListInputContainer, ActionsContainer, RemoveIcon, AddIcon } from './Styles';
import Button from '../../Button';
import { plusBlue, removeIcon } from '../../../../assets/icons/index';

export interface ListInputProps {
  inputArray: JSX.Element[];
  buttonLabel?: string;
  buttonFlex?: number;
  buttonMargin?: string;
  canRemoveLast?: boolean;
  horizontalActions?: boolean;
  extraActions?: JSX.Element[];
  onRemove: (e: MouseEvent<HTMLImageElement | MouseEvent>, index: number) => void;
  onAdd: () => void;
}

const ListInput: FC<ListInputProps> = ({
  inputArray,
  buttonLabel = 'Add Input',
  canRemoveLast = false,
  horizontalActions = true,
  buttonFlex = null,
  buttonMargin = null,
  extraActions,
  onRemove,
  onAdd
}) => {
  const generateList = (inputArray: JSX.Element[]): JSX.Element[] => {
    let inputGroups: JSX.Element[] = [<React.Fragment key={0}></React.Fragment>];

    if (inputArray.length > 0) {
      inputGroups = inputArray.map((input, index) => {
        return (
          <ListInputContainer key={`${index}-inputContainer`}>
            {input}
            {(inputArray.length > 1 || canRemoveLast) && (
              <RemoveIcon alt="remove" src={removeIcon} onClick={e => onRemove(e, index)} />
            )}
          </ListInputContainer>
        );
      });
    }

    return inputGroups;
  };

  return (
    <ListWrapper>
      {generateList(inputArray)}
      <ActionsContainer horizontal={horizontalActions}>
        <Button
          type="button"
          onClick={onAdd}
          variant={'clear'}
          margin={buttonMargin}
          buttonFlex={buttonFlex}
        >
          <AddIcon src={plusBlue} alt="hi" />
          {buttonLabel}
        </Button>
        {extraActions && extraActions}
      </ActionsContainer>
    </ListWrapper>
  );
};

export default ListInput;
