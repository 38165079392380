import * as yup from 'yup';

const updatePasswordSchema = yup
  .object({
    currentPassword: yup
      .string()
      .trim()
      .required('Your old password is required'),
    newPassword: yup
      .string()
      .trim()
      .matches(/^.*[0-9].*$/, 'Password must contain 1 number.')
      .matches(/^.*[A-Z].*$/, 'Password must contain 1 capital letter.')
      .min(8, 'Password must be greater than 8 characters.')
      .max(16, 'Password must be less than 16 characters.')
      .required('Password is required')
  })
  .required();

export type UpdatePasswordSchema = yup.InferType<typeof updatePasswordSchema>;

export default updatePasswordSchema;
