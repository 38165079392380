import {
  SET_SELECTED_COMPANY_IDS,
  SET_SELECTED_EVENT_ID,
  SET_COMPANY_OPTIONS,
  SET_EVENT_OPTIONS,
  SetSelectedEventIdAction,
  SetSelectedCompanyIdsAction,
  SetEventOptionsAction,
  SetCompanyOptionsAction
} from './types';
import { Company, Event } from '../../../../lib/Types';

export interface EventCompanyPageFilterState {
  selectedEventId: number | null;
  selectedCompanyIds: number[];
  companyOptions: Company[];
  eventOptions: Event[];
}

export const setSelectedEventId = (eventId: number): SetSelectedEventIdAction => ({
  type: SET_SELECTED_EVENT_ID,
  eventId
});

export const setSelectedCompanyIds = (companyIds: number[]): SetSelectedCompanyIdsAction => ({
  type: SET_SELECTED_COMPANY_IDS,
  companyIds
});

export const setEventOptions = (eventOptions: Event[]): SetEventOptionsAction => ({
  type: SET_EVENT_OPTIONS,
  eventOptions
});

export const setCompanyOptions = (companies: Company[]): SetCompanyOptionsAction => ({
  type: SET_COMPANY_OPTIONS,
  companies
});
