import styled from 'styled-components/macro';

interface ButtonProps {
  selected?: boolean;
}

interface EntryProps {
  highlighted?: boolean;
}

interface ImageProps {
  image: string;
}

export const LeaderBoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 300px;
  padding: 2rem;
  height: fit-content;
  background-color: ${({ theme }) => theme.primary.white};
  box-shadow: 1px 1px 12px 0px rgb(0 0 0 / 10%);
  border-radius: 14px;

  margin: calc(35px + 1.5rem) 0 2rem 0;

  @media (max-width: 1135px) {
    display: none;
  }
`;

export const Title = styled.h3`
  color: #366696;
  font-size: ${({ theme }) => theme.fontScale.md};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-style: italic;
  margin: 0.5rem 1rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
`;

export const Button = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0.25rem 1rem;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  color: ${({ theme, selected }) => (selected ? theme.primary.white : '#D4D4D4')};
  background-color: ${({ theme, selected }) => (!selected ? theme.primary.white : '#75DBFF')};
  border-radius: 14px;
  cursor: pointer;
`;

export const LeaderBoardEntry = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
`;

export const EntryMain = styled.div<EntryProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 5;
  padding: 0.5rem 0 0.75rem 0;
  background-color: ${({ highlighted }) => (!highlighted ? '#F7F7F7' : '#ECF0FA')};
  border-radius: 26px 0 0 26px;
`;

export const EntryDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EntryIndex = styled.div`
  display: inline;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: #21679a;
`;

export const EntryName = styled.div`
  display: inline;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: #21679a;
  margin-left: 0.25rem;
`;

export const EntryAssociation = styled.div`
  font-size: 10px;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: #21679a;
`;

export const EntryCount = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  padding: 0.5rem;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: #21679a;
  margin-left: 1px;
  background-color: #e4e8f1;
  border-radius: 0 68px 68px 0;
`;

export const ProfileImage = styled.div<ImageProps>`
  min-width: 24px;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  margin-left: 1rem;
  margin-right: 1rem;
  background: center / cover ${props => `url('${props.image}') no-repeat`};
`;
