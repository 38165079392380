import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { uploadCompanyProfilePicture } from '../../../lib/Api/User';
import { deleteMedia } from '../../../lib/Api/Common';
import { MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_LABEL, DEFAULT_PIC } from '../../../CONSTANTS';

import LoadingIndicator from '../../../components/UI/LoadingIndicator';
import {
  PhotoUploadContainer,
  LogoTextContainer,
  LoaderContainer,
  PhotoPlaceholderWrapper,
  PhotoPlaceholder,
  UserPhoto,
  TextColumn,
  PhotoHeading,
  PhotoNote,
  UploadSection,
  UploadButton
} from '../Styles';

import { cloudUpload } from '../../../assets/img';
import { ApiStatus, APIThunkDispatch } from '../../../lib/Types';

export interface CompanyLogoUploadProps {
  value: string;
  setValue: (value: string) => void;
  setFieldError: (field: string, message: string) => void;
  publicSignUp?: boolean;
}

const CompanyLogoUpload: FC<CompanyLogoUploadProps> = ({
  value,
  setValue,
  setFieldError,
  publicSignUp = false
}) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const [loadingImage, setLoadingImage] = useState(false);

  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setLoadingImage(true);
    const {
      status,
      data: { profileImage }
    } = await dispatch(uploadCompanyProfilePicture(file));
    if (status === ApiStatus.SUCCESS) {
      if (value !== DEFAULT_PIC) dispatch(deleteMedia(value));
      setValue(profileImage);
    } else setFieldError('profileImage', 'File upload failed');
    setLoadingImage(false);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDropAccepted,
    accept: 'image/jpeg,image/png',
    maxSize: MAX_IMAGE_SIZE
  });

  const LogoImage = () => {
    if (value === DEFAULT_PIC || !value) {
      return (
        <PhotoPlaceholderWrapper>
          <PhotoPlaceholder src={cloudUpload} />
        </PhotoPlaceholderWrapper>
      );
    }
    return <UserPhoto src={value} />;
  };

  const UploadButtonComponent = () => {
    return (
      <UploadButton type="button" variant="clear" onClick={open}>
        {/* Note: This upload button handles if we have already uploaded an image or not and changes the text to be displayed*/}
        {value === undefined || value === '' || value === DEFAULT_PIC ? 'UPLOAD' : 'REPLACE'}
      </UploadButton>
    );
  };

  return (
    <PhotoUploadContainer>
      <LogoTextContainer>
        {loadingImage ? (
          <LoaderContainer>
            <LoadingIndicator />
          </LoaderContainer>
        ) : (
          <LogoImage />
        )}
        <TextColumn>
          <UploadSection {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <PhotoHeading>
              {publicSignUp ? 'Upload Your Company Logo' : 'Upload Company Logo'}
            </PhotoHeading>
            <PhotoNote>{`Image must be JPG/PNG <${MAX_IMAGE_SIZE_LABEL}`}</PhotoNote>
            {publicSignUp && <UploadButtonComponent />}
          </UploadSection>
        </TextColumn>
      </LogoTextContainer>
      {!publicSignUp && <UploadButtonComponent />}
    </PhotoUploadContainer>
  );
};

export default CompanyLogoUpload;
