import React, { FC } from 'react';
import { TimezoneSelector } from '../../../../../components/UI';
import { FormikFormComponent } from '../../../../../lib/Types';
import { EventSchema } from '../../../validators/eventSchema';

interface EventTimezoneProps extends FormikFormComponent<EventSchema> {
  handleChangeTimezone: (name: string, value: string | null) => void;
}

const EventTimezone: FC<EventTimezoneProps> = ({ values, errors, handleChangeTimezone }) => (
  <TimezoneSelector
    title="Event Timezone"
    value={values.timezone}
    error={errors.timezone}
    handleChangeTimezone={handleChangeTimezone}
    required
  />
);

export default EventTimezone;
