import styled from 'styled-components/macro';

import { popinEnlarge, getBoxShadow } from '../../../lib/Utils';

import { ContainerProps } from '../../../lib/Types';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-bottom: 1rem;

  @media (max-width: 490px) {
    flex-direction: column;
  }
`;

export const HeaderImage = styled.img`
  width: 210px;
  margin: 0 1rem 0 auto;
  @media (max-width: 490px) {
    margin: 0;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const Header = styled.div`
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.8rem;
  font-style: oblique;
  margin-bottom: 0.3rem;
`;

export const SubHeader = styled.div`
  color: ${({ theme }) => theme.primary.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
`;

export const SubText = styled.div`
  color: ${({ theme }) => theme.primary.lightGrey};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 12px;
  width: 70%;
  margin-top: 0.5rem;
  line-height: 14px;
`;

export const DashboardPanelWrapper = styled.div<ContainerProps>`
  border-radius: 1.5rem;
  box-shadow: ${({ elevation = 1, theme }) => getBoxShadow(elevation, theme)};
  min-height: 20rem;
  width: 100%;
  background: ${({ theme }) => theme.backgrounds.white};
  transition: all 0.6s cubic-bezier(0.4, 0.01, 0, 1.38);
  position: relative;
  transform: scale(0);
  animation: ${popinEnlarge} 0.8s cubic-bezier(0.4, 0.01, 0, 1.08)
    ${props => (props.delay ? props.delay : 1.2)}s forwards;
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 2.5rem;

  // horizontal space
  &:nth-child(2) {
    margin-left: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    margin-left: 0;

    // vertical space
    &:nth-child(1) {
      margin-bottom: 2rem;
    }

    // remove horizontal space
    &:nth-child(2) {
      margin-left: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    border-radius: 0;
  }
`;
