import styled from 'styled-components/macro';
import { FlexCol, MainContentContainer } from '../../Themes';
import { employeeDashboardRegBg } from '../../assets/img';

export const MainContentContainerReg = styled(MainContentContainer)`
  background: no-repeat bottom right/95% url(${employeeDashboardRegBg});
  background-color: ${({ theme }) => theme.primary.white};
  flex-direction: row;
  margin-top: 2rem;
  min-height: 600px;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    background: no-repeat #ffffff;
    padding: ${({ theme }) => theme.pad.md}%;
    margin: ${({ theme }) => theme.pad.md}% 0 0 0;
    margin-top: 2rem;
  }
`;

export const ColA = styled(FlexCol)`
  width: 60%;
  justify-content: flex-start;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    width: 100%;
  }
`;

export const ColB = styled(FlexCol)`
  width: 40%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: none;
  }
`;

export const OrgImage = styled.img`
  height: 50px;
  max-width: 150px;
  object-fit: contain;
  margin: 0 0 auto auto;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: none;
  }
`;

// Mobile Styles
export const OrgImageMob = styled.img`
  height: 50px;
  object-fit: contain;
  padding: 0 0 10px 0;
  margin: 0 0 auto auto;
`;
