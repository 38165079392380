import React, { FC, ChangeEvent } from 'react';

import EventDetails from './formComponents/EventDetails';
import RegistrationDates from './formComponents/RegistrationDates';
import EventTimezone from './formComponents/EventTimezone';
import EventDates from './formComponents/EventDates';
import GracePeriod from './formComponents/GracePeriod';
import ActivitiesToInclude from './formComponents/ActivitiesToInclude';
import TeamSize from './formComponents/TeamSize';
import Paper from '../../../../components/UI/Paper';
import DailyStepThreshold from './formComponents/DailyStepThreshold';
import CostPerParticipant from './formComponents/costPerParticipant';
import CompaniesInEventTable from './CompaniesInEventTable';

import { FormColumn, ColumnWrapper, ButtonWrapper, EventButton } from './Styles';

import { EventSchema } from '../../validators/eventSchema';
import {
  FormikForm,
  ActivityType,
  PagedResponse,
  CompanyRegistrationData,
  PaginationFilter
} from '../../../../lib/Types';

interface EventFormProps extends FormikForm<EventSchema> {
  activityTypes: ActivityType[];
  companiesInEvent?: PagedResponse<CompanyRegistrationData>;
  filter?: PaginationFilter;
  onCancel: () => void;
  handleDateChangeCustom: (name: string, date: Date) => void;
  handleTimeChangeCustom: (name: string, timeOptionId: number) => void;
  isSubmitting?: boolean;
  handleSetCost: (e: ChangeEvent<HTMLInputElement>) => void;
}

const EventForm: FC<EventFormProps> = ({
  activityTypes,
  handleChangeCustom,
  handleTimeChangeCustom,
  handleDateChangeCustom,
  handleSubmit,
  onCancel,
  companiesInEvent,
  filter,
  isSubmitting,
  handleSetCost,
  ...props
}) => (
  <>
    <Paper>
      <form onSubmit={handleSubmit}>
        <ColumnWrapper>
          <FormColumn>
            <EventDetails {...props} />
            <EventTimezone {...props} handleChangeTimezone={handleChangeCustom} />
            <RegistrationDates
              {...props}
              handleChangeTime={handleTimeChangeCustom}
              onChangeDate={handleDateChangeCustom}
            />
            <EventDates
              {...props}
              handleChangeTime={handleTimeChangeCustom}
              onChangeDate={handleDateChangeCustom}
            />
            <GracePeriod {...props} />
            <DailyStepThreshold {...props} />
            <TeamSize {...props} />
            <CostPerParticipant
              {...props}
              handleChange={handleSetCost}
              disabled={companiesInEvent?.results.some(company => company.status === 'Paid')}
            />
          </FormColumn>
          <FormColumn>
            <ActivitiesToInclude {...props} activityTypes={activityTypes} />
          </FormColumn>
        </ColumnWrapper>
        <ButtonWrapper>
          <EventButton onClick={onCancel} type="button" variant="cancel">
            Cancel
          </EventButton>
          <EventButton loading={isSubmitting} type="submit" variant="primary">
            {props.values.name ? 'Save' : 'Create'}
          </EventButton>
        </ButtonWrapper>
      </form>
    </Paper>
    {props.values.name && !!companiesInEvent?.results?.length && (
      <CompaniesInEventTable companiesInEvent={companiesInEvent} filter={filter} />
    )}
  </>
);

export default EventForm;
