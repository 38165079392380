import styled from 'styled-components/macro';
import { theme } from '../../../theme';

export const Group = styled.div`
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const Heading = styled.h4`
  font-size: ${({ theme }) => theme.fonts.subHeading / 1.25}rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.primary.dark};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.padding}px;

  span {
    font-size: 80%;

    @media (min-width: ${theme.device.tablet}px) and (max-width: ${theme.device.laptop}px) {
      display: block;
    }
  }
`;

export const SelectWrapper = styled.div<{ active: boolean; valid: boolean }>`
  position: relative;

  .react-select {
    display: flex;
    flex-direction: row;
    outline: none !important;
  }

  .react-select__control {
    flex: 1;
    border: ${({ theme }) => theme.borders.input.default};
    border-radius: 0.35rem;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 48px;
    padding: 0.3rem 0rem 0.3rem 1.5rem;
    width: 100%;
    outline: none !important;

    &:hover {
      border: ${({ theme }) => theme.borders.input.default};
      outline: none !important;
    }

    &--is-focused {
      border: 1px #44deff solid;
      outline: none !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        border: 1px #44deff solid;
      }
    }
  }

  .react-select__menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
  }

  .react-select__option {
    color: ${({ theme }) => theme.primary.dark};
    font-size: ${({ theme }) => theme.fonts.subBody + 0.2}rem;
    height: 46px;
    padding: ${({ theme }) => theme.padding}px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &--is-selected {
      background-color: transparent;
    }
  }

  .react-select__placeholder {
    font-size: ${({ theme }) => theme.fonts.subBody + 0.2}rem;
    color: ${({ theme }) => theme.primary.xlight};
  }

  .react-select__single-value {
    font-size: ${({ theme }) => theme.fonts.subBody + 0.2}rem;
    color: ${({ theme }) => theme.primary.dark};
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__indicators {
    cursor: pointer;
  }

  .react-select__indicator-separator {
    display: none;
  }
`;

export const SelectIcon = styled.img`
  padding: 2rem 1.5rem;
`;
