import styled from 'styled-components/macro';

interface ImageProps {
  image: string;
}

interface RowProps {
  odd?: boolean;
}

/* ----------------------- Table Text Styles ----------------------- */

// Default
export const TableText = styled.div`
  color: #515151;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: clamp(1.15rem, 1vw, 1.35rem);
  line-height: ${({ theme }) => theme.fonts.subBody * 1.5}rem;
  text-align: left;
`;

export const Joined = styled(TableText)`
  color: #55dad1;
  font-style: italic;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const NotJoined = styled(Joined)`
  color: #ff5e86;
`;

export const ReplaceText = styled(TableText)`
  color: #658cd7;
  font-style: italic;
  cursor: pointer;
  text-align: left;
`;

export const ResendText = styled(ReplaceText)``;

export const RemoveText = styled(ReplaceText)`
  color: ${({ theme }) => theme.primary.red};
`;

/* ----------------------- Table Structure ----------------------- */

export const Row = styled.div<RowProps>`
  display: table-row;
  width: 100%;
  border-radius: 6rem;
  margin: 1px 0;
  padding: 3px 5px;
  background-color: ${({ theme }) => theme.backgrounds.white};
  height: 36px;

  &:nth-child(odd) {
    background-color: #f7f7f7;
  }
`;

export const TableCell = styled(TableText)`
  display: table-cell;
  text-align: left;
  white-space: nowrap;
  padding: 1px calc(0.3rem + 0.3vw) 1px calc(0.4rem + 0.4vw);
  vertical-align: middle;
  min-width: fit-content;

  &:first-child {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    max-width: 36px;
    min-width: 36px;
    height: 36px;
    padding: 0 0 0 2rem;
  }
  &:nth-child(2) {
    max-width: 36px;
    min-width: 36px;
    height: 36px;
    padding: 0 0.1rem;
  }
  &:nth-child(3) {
    padding: 0 0 0 0.1rem;
  }
  &:nth-child(6) {
    padding-left: 0;
  }
  &:nth-child(7) {
    max-width: 46px;
    min-width: 46px;
    line-height: unset;
  }
  &:last-child {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
`;

//  Team Member Image
export const ProfilePic = styled.img<ImageProps>`
  /* This is a fix for chrome bug */
  padding: 1.3rem;
  height: 0;
  width: 0;
  border-radius: 50%;
  margin: 5px 0 1px;
  overflow: hidden;
  background: center / cover ${props => `url(${props.image}) no-repeat`};
`;
