import styled from 'styled-components/macro';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Header = styled.div`
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.8rem;
  font-style: oblique;
`;
