import React from 'react';
import { useSelector } from 'react-redux';

import { getToday, getYesterday, getWeek, getAllSteps } from '../../../lib/Utils';
import { ToSIPrefix } from '@cognativ/utilities';

import { pfpPlaceholder } from '../../../assets/img';
import {
  MyTeamContainer,
  MyTeamHeader,
  TeamName,
  TeamTotal,
  Table,
  TableHeaders,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  ProfilePic,
  ProfileCell,
  Rank,
  ProfileCaptain,
  ProfileName
} from './Styles';
import { ApplicationState } from '../../../lib/Store';

const MyTeam = () => {
  const {
    participantTeamState: { team },
    statsState: { teamStats }
  } = useSelector((state: ApplicationState) => state);

  const getTeamTotal = () => {
    let total = 0;

    teamStats.slice(0, 5).map(data => {
      const result = getAllSteps(data.steps);
      if (result !== 'none') {
        total += result;
      }
    });

    return ToSIPrefix(total);
  };

  return (
    <MyTeamContainer>
      <MyTeamHeader>
        <TeamName>{team?.name === '' || !team?.name ? 'No team name yet!' : team.name}</TeamName>
        <TeamTotal>Team Steps Total: {getTeamTotal()}</TeamTotal>
      </MyTeamHeader>
      <Table>
        <TableHeaders>
          <TableHeader />
          <TableHeader dropable={true}>Today</TableHeader>
          <TableHeader dropable={true}>Yesterday</TableHeader>
          <TableHeader dropable={true}>This Week</TableHeader>
          <TableHeader dropable={true}>Total Steps Tracked</TableHeader>
          <TableHeader dropable={true}>Connection Points</TableHeader>
          <TableHeader>Total Points</TableHeader>
        </TableHeaders>
        <TableBody>
          {teamStats.slice(0, 5).map((data, index) => (
            <TableRow highlight={index % 2 == 0} key={index}>
              <TableCell highlight={index % 2 == 0} dropable={false}>
                <ProfileCell>
                  <Rank>{index + 1}</Rank>
                  <ProfilePic image={data.profileImage ? data.profileImage : pfpPlaceholder} />
                  <ProfileName>{data.name}</ProfileName>
                  {data.teamCaptain && <ProfileCaptain>(Captain)</ProfileCaptain>}
                </ProfileCell>
              </TableCell>
              <TableCell
                none={getToday(data.steps) === 'none'}
                highlight={index % 2 == 0}
                dropable={true}
              >
                {getToday(data.steps)}
              </TableCell>
              <TableCell
                none={getYesterday(data.steps) === 'none'}
                highlight={index % 2 == 0}
                dropable={true}
              >
                {getYesterday(data.steps)}
              </TableCell>
              <TableCell
                none={getWeek(data.steps) === 'none'}
                highlight={index % 2 == 0}
                dropable={true}
              >
                {getWeek(data.steps)}
              </TableCell>
              <TableCell
                none={getAllSteps(data.steps) === 'none'}
                highlight={index % 2 == 0}
                dropable={true}
              >
                {typeof getAllSteps(data.steps) === 'number'
                  ? ToSIPrefix(getAllSteps(data.steps) as number)
                  : getAllSteps(data.steps)}
              </TableCell>
              <TableCell highlight={index % 2 == 0} dropable={true}>
                {data.connectionPoints}
              </TableCell>
              <TableCell highlight={index % 2 == 0} dropable={false}>
                {data.connectionPoints + data.movementPoints}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </MyTeamContainer>
  );
};

export default MyTeam;
