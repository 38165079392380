import React, { FC, useState, useRef } from 'react';
import { Formik } from 'formik';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import { createCompany, editCompany, CreateCompany, EditCompany } from '../../../lib/Api/Company';
import { verifyCaptcha, VerifyCaptcha } from '../../../lib/Api/companyAdmin/Auth';
import { DEFAULT_PIC } from '../../../CONSTANTS';

import {
  FormControl,
  Input,
  Checkbox,
  Button,
  PasswordInput,
  RadioGroup
} from '../../../components/UI';
import ModalButton from '../../../components/UI/Button/ModalButton';
import CompanyLogoUpload from './CompanyLogoUpload';

import { FormWrapper, ButtonWrapper, InputGroupHeading, InputGroup } from '../Styles';

import companySchema, { CompanySchema } from '../../../lib/validators/CompanySchema';
import { ApiStatus, Company, CompanyAdmin } from '../../../lib/Types';

export interface CreateOrEditCompanyFormProps {
  company?: Company; // infer editing from presence
  companyContact?: CompanyAdmin;
  publicSignUp?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
  onCreateCompany: CreateCompany;
  onEditCompany: EditCompany;
  onVerifyCaptcha: VerifyCaptcha;
}

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const CreateOrEditCompanyForm: FC<CreateOrEditCompanyFormProps> = ({
  company,
  companyContact,
  onCancel,
  onSuccess,
  onCreateCompany,
  onEditCompany,
  onVerifyCaptcha,
  publicSignUp = false
}) => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [passwordError, setPasswordError] = useState<string>();

  const captchaRef = useRef<ReCAPTCHA>();

  const resetCaptcha = () => {
    setCaptchaVerified(false);
    captchaRef.current.reset();
  };

  const handleVerifyCaptcha = async (token: string) => {
    if (!token) return;
    const { data, error } = await onVerifyCaptcha(token);
    if (!error && data?.success) {
      setCaptchaVerified(true);
    } else resetCaptcha();
  };

  return (
    <Formik
      initialValues={{
        name: company?.name || '',
        active:
          publicSignUp || company?.active === null || company?.active === undefined
            ? true
            : company?.active,
        profileImage: company?.profileImage,
        contact: {
          firstName: companyContact?.user.firstName || '',
          lastName: companyContact?.user.lastName || '',
          email: companyContact?.user.email || '',
          phone: companyContact?.phone || '',
          password: ''
        },
        allowManualPayment: company?.allowManualPayment || false
      }}
      validationSchema={companySchema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        if (passwordError) return;
        setSubmitting(true);
        if (publicSignUp) {
          const { status } = await onCreateCompany(values, true);
          if (status === ApiStatus.SUCCESS) onSuccess();
        } else {
          const { status, error } = company
            ? await onEditCompany(
                company.companyId,
                values,
                values.contact.email !== companyContact.user.email // If email changed, this is a new contact
              )
            : await onCreateCompany(values);
          if (status === ApiStatus.SUCCESS) onSuccess();
          if (error.data.field) setErrors({ [error.data.field]: error.message });
        }
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleSubmit,
        handleChange,
        setFieldValue,
        setFieldError
      }) => {
        return (
          <>
            <FormWrapper>
              <InputGroup>
                <Input
                  required
                  id="name"
                  name="name"
                  label="Company Name"
                  value={values.name}
                  onChange={value => {
                    handleChange(value);
                    setFieldError('name', '');
                  }}
                  error={errors.name}
                />
              </InputGroup>
              <InputGroup>
                <CompanyLogoUpload
                  value={values.profileImage || DEFAULT_PIC}
                  setValue={value => setFieldValue('profileImage', value)}
                  setFieldError={setFieldError}
                  publicSignUp={publicSignUp}
                />
              </InputGroup>
              <InputGroup>
                <FormControl>
                  <InputGroupHeading>Company Contact Details</InputGroupHeading>
                  <Input
                    required
                    id="contact.firstName"
                    name="contact.firstName"
                    label="First Name"
                    value={values.contact?.firstName}
                    onChange={value => {
                      handleChange(value);
                      setFieldError('contact.firstName', '');
                    }}
                    error={errors.contact?.firstName}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    required
                    id="contact.lastName"
                    name="contact.lastName"
                    label="Last Name"
                    value={values.contact?.lastName}
                    onChange={value => {
                      handleChange(value);
                      setFieldError('contact.lastName', '');
                    }}
                    error={errors.contact?.lastName}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    required
                    id="contact.phone"
                    name="contact.phone"
                    label="Phone"
                    value={values.contact?.phone}
                    onChange={value => {
                      handleChange(value);
                      setFieldError('contact.phone', '');
                    }}
                    error={errors.contact?.phone}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    required
                    id="contact.email"
                    name="contact.email"
                    label="Email"
                    value={values.contact?.email}
                    onChange={value => {
                      handleChange(value);
                      setFieldError('contact.email', '');
                    }}
                    error={errors.contact?.email}
                  />
                </FormControl>
                {publicSignUp && (
                  <FormControl>
                    <PasswordInput
                      required
                      id="contact.password"
                      name="contact.password"
                      value={values.contact?.password}
                      label="Create a Password"
                      error={passwordError || errors.contact?.password}
                      onChange={e => {
                        if (e.target) setPasswordError('');
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                )}
              </InputGroup>
              {!publicSignUp && (
                <>
                  <InputGroupHeading>Allow this company to pay manually?</InputGroupHeading>
                  <InputGroup>
                    <RadioGroup
                      initialValue={values.allowManualPayment ? 'YES' : 'NO'}
                      columns={1}
                      valueKey="value"
                      options={[
                        {
                          label: 'NO',
                          value: 'No'
                        },
                        {
                          label: 'YES',
                          value: 'Yes'
                        }
                      ]}
                      handleOptionClick={(field, value) =>
                        setFieldValue('allowManualPayment', value === 'Yes')
                      }
                    />
                  </InputGroup>
                  <InputGroup>
                    <Checkbox
                      name={'active'}
                      checked={!values.active}
                      label={'Make Inactive'}
                      boldLabel
                      onChange={e => setFieldValue('active', !e.currentTarget.checked)}
                    />
                  </InputGroup>
                </>
              )}
            </FormWrapper>
            {publicSignUp ? (
              <>
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={SITE_KEY}
                  size="normal"
                  onChange={handleVerifyCaptcha}
                  onExpired={resetCaptcha}
                  onErrored={resetCaptcha}
                />
                <Button
                  onClick={() => {
                    if (!values.contact?.password) {
                      setPasswordError('Password is a required field');
                    }
                    handleSubmit();
                  }}
                  type="submit"
                  variant={isSubmitting || !captchaVerified ? 'disabled' : 'primary'}
                  disabled={isSubmitting || !captchaVerified}
                  margin="2.5rem 0"
                  loading={isSubmitting}
                >
                  Create Free Company Account
                </Button>
              </>
            ) : (
              <ButtonWrapper>
                <ModalButton
                  onClick={onCancel}
                  type="button"
                  variant={'secondary'}
                  disabled={isSubmitting}
                >
                  Cancel
                </ModalButton>
                <ModalButton
                  onClick={() => handleSubmit()}
                  type="submit"
                  variant={isSubmitting ? 'disabled' : 'primary'}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  {company ? 'Save' : 'Create'}
                </ModalButton>
              </ButtonWrapper>
            )}
          </>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onCreateCompany: (data: CompanySchema, publicSignup: boolean) =>
        createCompany(data, publicSignup) as any,
      onEditCompany: (companyId: number, data: CompanySchema, newContact: boolean) =>
        editCompany(companyId, data, newContact) as any,
      onVerifyCaptcha: (token: string) => verifyCaptcha(token) as any
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CreateOrEditCompanyForm);
