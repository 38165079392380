import React, { FC } from 'react';
import { connect } from 'react-redux';
import { FieldArray, FormikProps, withFormik, FormikErrors } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import theme from '../../../../../theme';
import Input from '../../../../../components/UI/Input';
import Button from '../../../../../components/UI/Button';
import LoadingIndicator from '../../../../../components/UI/LoadingIndicator';

import {
  FlexRowBlue,
  HeaderTextBold,
  InputWrap,
  LightText,
  SubHeader,
  TeamMembers,
  TeamMemberRow,
  TeamMemberInputs,
  TeamNameField,
  TeamMemberInputWrap
} from './Styles';
import { LoaderContainer } from './Styles';

import { ApprovalStatus, Company, User } from '../../../../../lib/Types';
import { ApplicationState } from '../../../../../lib/Store';
import validationSchema, { CreateTeamSchema } from '../../validators/CreateTeamSchema';

interface CreateTeamFormProps extends RouteComponentProps {
  handleCloseModal?: () => void;
  numberOfMembers: number;
  createTeam: (data: CreateTeamSchema) => any;
  setCurrentStep: (data: number) => void;
  user: User;
  company: Company;
  userEventId: number;
  apiLoading: boolean;
}

const formikEnhancer = withFormik<CreateTeamFormProps, CreateTeamSchema>({
  mapPropsToValues: (props: CreateTeamFormProps) => ({
    name: '',
    eventId: props.userEventId,
    companyId: props.company.companyId,
    captain: {
      userId: props.user.userId || null,
      joinedStatus: true,
      teamCaptain: true
    },
    teamMembers: new Array(props.numberOfMembers || 4).fill({
      email: '',
      firstName: '',
      lastName: ''
    }),
    active: true,
    approvalStatus: ApprovalStatus.AwaitingApproval
  }),
  // TODO: Implement error validation for team member input fields - also provide feedback if user has added less than < props.numberOfMembers
  mapPropsToTouched: (props: CreateTeamFormProps) => ({
    name: false,
    teamMembers: new Array(props.numberOfMembers || 4).fill({
      email: false,
      firstName: false,
      lastName: false
    })
  }),
  validationSchema,
  validateOnBlur: false,
  handleSubmit: async (values: CreateTeamSchema, { props, setErrors, setValues }) => {
    const { error } = await props.createTeam(values);
    if (error) {
      setErrors({ [error.data.field]: error.message });
    } else {
      // If form submitted successfully, reset the form
      setValues({
        name: '',
        eventId: props.userEventId,
        companyId: props.company.companyId,
        captain: {
          userId: props.user.userId || null,
          joinedStatus: true,
          teamCaptain: true
        },
        teamMembers: new Array(props.numberOfMembers || 4).fill({
          firstName: '',
          lastName: '',
          email: ''
        }),
        active: true,
        approvalStatus: ApprovalStatus.AwaitingApproval
      });
    }
    props.setCurrentStep(2);
  }
});

const CreateTeamForm: FC<CreateTeamFormProps & FormikProps<CreateTeamSchema>> = ({
  user,
  handleChange,
  handleSubmit,
  values,
  errors,
  touched,
  apiLoading,
  handleBlur,
  setFieldTouched
}) => (
  <form onSubmit={handleSubmit}>
    <TeamNameField>
      <SubHeader>Give your team a name:</SubHeader>
      <InputWrap>
        <Input
          id="name"
          label="Team Name"
          value={values.name}
          onChange={handleChange}
          width="100%"
          error={touched.name && errors.name}
          onBlur={handleBlur}
        />
      </InputWrap>
    </TeamNameField>
    {user && (
      <>
        <HeaderTextBold>Team Captain</HeaderTextBold>
        <FlexRowBlue>
          <LightText>{user.firstName}</LightText>
          <LightText>{user.lastName}</LightText>
          <LightText>{user.email}</LightText>
          <LightText>Captain</LightText>
        </FlexRowBlue>
      </>
    )}

    <FieldArray
      name="teamMembers"
      render={() => (
        <>
          <TeamMembers>
            {values.teamMembers.length > 0 &&
              values.teamMembers.map((member, index) => {
                const fieldErrors =
                  errors.teamMembers &&
                  (errors.teamMembers[index] as FormikErrors<{
                    firstName: string;
                    lastName: string;
                    email: string;
                  }>);
                const touchedFields =
                  touched.teamMembers && (touched.teamMembers[index] as Record<string, boolean>);

                return (
                  <TeamMemberRow key={index}>
                    <HeaderTextBold>{`Team Member ${index + 1}`}</HeaderTextBold>
                    <TeamMemberInputs>
                      <TeamMemberInputWrap>
                        <Input
                          horizontalSiblings
                          error={
                            touchedFields?.firstName &&
                            !!fieldErrors?.firstName &&
                            'This field is required'
                          }
                          id="firstName"
                          value={member.firstName}
                          label="First Name"
                          name={`teamMembers[${index}].firstName`}
                          margin={`0px 0px ${theme.padding}px 0px`}
                          onChange={handleChange}
                          onBlur={() => setFieldTouched(`teamMembers[${index}].firstName`)}
                          required
                        />
                      </TeamMemberInputWrap>
                      <TeamMemberInputWrap>
                        <Input
                          horizontalSiblings
                          error={
                            touchedFields?.lastName &&
                            !!fieldErrors?.lastName &&
                            'This field is required'
                          }
                          id="lastName"
                          value={member.lastName}
                          name={`teamMembers[${index}].lastName`}
                          label="Last Name"
                          margin={`0px 0px ${theme.padding}px 0px`}
                          onChange={handleChange}
                          onBlur={() => setFieldTouched(`teamMembers[${index}].lastName`)}
                          required
                        />
                      </TeamMemberInputWrap>
                      <TeamMemberInputWrap>
                        <Input
                          horizontalSiblings
                          id="email"
                          error={
                            touchedFields?.email && !!fieldErrors?.email && 'This field is required'
                          }
                          value={member.email}
                          label="Email"
                          type="email"
                          name={`teamMembers[${index}].email`}
                          margin={`0px 0px ${theme.padding}px 0px`}
                          onChange={handleChange}
                          onBlur={() => setFieldTouched(`teamMembers[${index}].email`)}
                          required
                        />
                      </TeamMemberInputWrap>
                    </TeamMemberInputs>
                  </TeamMemberRow>
                );
              })}
          </TeamMembers>
          <Button
            fontWeight={theme.fontWeights.bold}
            variant={'secondary'}
            width={'55%'}
            type="submit"
            loading={apiLoading}
            disabled={apiLoading}
          >
            Submit my team for approval
          </Button>
        </>
      )}
    />
  </form>
);

const mapStateToProps = ({ loadingState, userState, permissionsState }: ApplicationState) => ({
  //numberOfMembers = teamSize - 1 to account for captain/team creator
  apiLoading: loadingState.apiCallsInProgress > 0,
  numberOfMembers: userState.userEvent.teamSize - 1,
  permissions: permissionsState.permissions,
  userEventId: userState.userEvent.eventId
});

export default connect(mapStateToProps, null)(withRouter(formikEnhancer(CreateTeamForm)));
