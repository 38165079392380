import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Paper, Button, ImageViewer } from '../../../components/UI';
import { PostProfileImageContainer } from './PostProfileImageContainer';
import { postTypeColour } from '../../../lib/Utils';

export const PostLink = styled(Link)`
  text-decoration: none;
`;

interface PostCardProps {
  maxWidth: boolean;
}

export const PostCard = styled(Paper)<PostCardProps>`
  color: ${({ theme }) => theme.primary.dark};
  padding: ${({ theme }) => theme.padding * 2}px;
  position: relative;
  margin-top: 1rem;
  h2,
  h3,
  h4 {
    font-style: italic;
    @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
      font-size: 1.5rem;
    }
  }
  img {
    font-size: 1rem;
  }
  display: flex;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? '' : 'max-width: calc(100% - 3% - 229px);')};

  @media (${({ theme }) => theme.device.laptopL}px) {
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    box-shadow: 1px -1px 5px 0px rgb(0 0 0 / 10%);
    border-radius: 0;
    margin: 0;
  }
`;

export const PostBody = styled.div`
  width: 100%;
  position: relative;
`;

export const PostDetails = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.padding}px;
  height: 33px;
`;

export const PostHeaderText = styled.h2<{ type: string }>`
  color: ${({ type, theme }) => postTypeColour(type, theme)};
  font-size: ${props => (props.type === 'General' ? '1.5rem' : '2rem')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    margin-left: 5rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
`;

export const PostTimestamp = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 1rem;
  font-size: 1.4rem;
`;

export const PostProfileImage = styled(PostProfileImageContainer)``;

export const PostContent = styled.div`
  line-height: 2.5rem;
  a {
    text-decoration: none !important;
  }
`;
export const PostTitle = styled.h3`
  font-size: 2.2rem;
`;
export const PostMedia = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.padding}px;
`;
export const PostMediaImage = ImageViewer;
export const PostMessage = styled.p`
  font-size: 1.35rem;
`;

export const PostCommentButton = styled(Button)<{ postType: string }>`
  color: ${({ postType, theme }) => postTypeColour(postType, theme) || theme.primary.main};
  border-color: ${({ postType, theme }) => postTypeColour(postType, theme) || theme.primary.main};
  border-width: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: normal;
  font-style: normal;
  white-space: pre-wrap;
  text-align: left;
`;

export const PostChallengeText = styled.div<{ postType: string }>`
  margin-top: ${({ theme }) => theme.padding}px;
  font-size: 1.5rem;
  color: ${({ postType, theme }) => postTypeColour(postType, theme) || theme.primary.main};
`;
export const PostSocial = styled.div`
  color: ${({ theme }) => theme.backgrounds.dark};
  display: flex;
  justify-content: flex-start;
  margin: ${({ theme }) => theme.padding}px 0;
  font-size: 1.4rem;

  img {
    width: 1.75rem;
  }
  img,
  span {
    margin-right: 0.5rem;
  }
`;

export const PostSocialLabel = styled.div`
  margin-right: ${({ theme }) => theme.padding}px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.backgrounds.grey};
`;

export const PostOptionsColumn = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.device.laptop}px) {
    display: block;
    margin-left: ${({ theme }) => theme.padding}px;
  }
`;

export const PinImage = styled.img`
  width: 2rem;
  height: 2rem;
  margin: ${({ theme }) => theme.padding}px;
`;

export interface PostMediaButtonProps {
  disabled?: boolean;
}

export const PostMediaButton = styled.img<PostMediaButtonProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const WebLink = styled.a`
  font-size: 1.35rem;
`;

export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.backgrounds.white};
  border-radius: 2rem;

  div {
    line-height: 0;
    width: 100%;
    min-height: 28rem;
    max-height: 60rem;

    div {
      max-height: 60rem;
      padding-top: 70% !important;
      iframe {
        width: max-content;
        min-height: inherit;
        max-height: 60rem;
      }
    }
  }

  max-height: 50vh;
  border-radius: 2rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 50vh;
`;
