import {
  TeamActionTypes,
  SET_PAGED_TEAMS,
  SET_TEAM,
  SET_GENERATING_TEAMS,
  SET_TEAMS_FILTER,
  SET_ADDING_TEAM_MEMBER
} from './types';
import { PagedResponse, TeamListView, Team, TeamListFilter } from '../../../Types';

export const setPagedTeamList = (pagedTeamList: PagedResponse<TeamListView>): TeamActionTypes => ({
  type: SET_PAGED_TEAMS,
  pagedTeamList
});

export const setTeam = (team: Team): TeamActionTypes => ({
  type: SET_TEAM,
  team
});

export const setGeneratingTeam = (generatingTeams: boolean): TeamActionTypes => ({
  type: SET_GENERATING_TEAMS,
  generatingTeams
});

export const setAddingTeamMember = (addingTeamMember: boolean): TeamActionTypes => ({
  type: SET_ADDING_TEAM_MEMBER,
  addingTeamMember
});

export const setTeamListFilter = (filter: TeamListFilter): TeamActionTypes => ({
  type: SET_TEAMS_FILTER,
  filter
});
