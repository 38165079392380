import React, { FC } from 'react';

import SelectedParticipant from '../ReplaceForm/formComponents/SelectedParticipant';
import ReplaceParticipant from '../ReplaceParticipant';
import ReplaceNewUser from '../ReplaceNewUser';
import { Button, Modal } from '../../../../../components/UI/';
import LoadingIndicator from '../../../../../components/UI/LoadingIndicator';
import { ColumnWrapper, Column, ButtonWrapper, ORText } from './Styles';

import { blueGuy } from '../../../../../assets/img';
import { ParticipantListView } from '../../../../../lib/Types';

interface ModalFormContainerProps {
  loading: boolean;
  participant: ParticipantListView;
  adding: boolean;
  onReplaceCancel: () => void;
}

const ModalFormContainer: FC<ModalFormContainerProps> = ({
  loading,
  onReplaceCancel,
  participant,
  adding
}) => (
  <Modal
    icon={blueGuy}
    width={70}
    minWidth={'880px'}
    padding={'2% 4% 1.5% 7%'}
    title={adding ? 'Add Participant' : 'Replace Participant'}
    left={15}
    top={10}
    titlePadding={'1rem 0 0 0'}
  >
    {loading ? (
      <LoadingIndicator />
    ) : (
      <>
        <Column>
          {participant.teamId && <SelectedParticipant participant={participant} />}
          <ColumnWrapper>
            <Column>
              <ReplaceParticipant />
            </Column>
            <ORText>- OR -</ORText>
            <Column>
              <ReplaceNewUser />
            </Column>
          </ColumnWrapper>
        </Column>

        <ButtonWrapper>
          <Button
            onClick={onReplaceCancel}
            type="button"
            variant={'admin-cancel'}
            minWidth={'15%'}
            width={'15%'}
            margin={'0 -1% 0 0'}
          >
            Cancel
          </Button>
        </ButtonWrapper>
      </>
    )}
  </Modal>
);

export default ModalFormContainer;
