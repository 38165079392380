import styled from 'styled-components/macro';

export const TabFilter = styled.div`
  display: flex;
  width: fit-content;

  box-shadow: 1px 1px 12px 0px rgb(0 0 0 / 10%);
  border-radius: 50px;

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    width: 100%;
    border-radius: 0;
  }
`;

export const Tab = styled.div<{
  isLeft?: boolean;
  isRight?: boolean;
  selected?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 2rem;
  border-radius: ${({ isLeft, isRight }) => {
    if (isLeft) return '50px 0 0 50px';
    if (isRight) return '0 50px 50px 0';
  }};

  color: ${({ theme, selected }) => (selected ? theme.primary.white : theme.primary.dark)};
  background: ${({ theme, selected }) =>
    selected ? theme.gradients.darkBlue : theme.backgrounds.white};
  cursor: pointer;

  border-right: ${({ theme }) => `1px solid ${theme.borders.xlight}`};
  &:last-child {
    border-right: none;
  }

  @media (max-width: 580px) {
    padding: 0 1rem;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    flex: 1;
    border-radius: 0;
  }
`;

export const TabText = styled.div`
  font-size: calc(0.6em + 0.5vw);
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin: 1rem 0;
  font-style: italic;
  text-align: center;

  @media (max-width: 580px) {
    font-size: calc(0.6em + 0.5vw);
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(0.5em + 0.5vw);
  }
`;
