import { EventCompanyPageFilterState } from './actions';
import {
  SET_SELECTED_COMPANY_IDS,
  SET_SELECTED_EVENT_ID,
  SET_EVENT_OPTIONS,
  SET_COMPANY_OPTIONS,
  EventCompanyPageFilterActionTypes
} from './types';

export const initialState: EventCompanyPageFilterState = {
  selectedEventId: null,
  selectedCompanyIds: [],
  eventOptions: [],
  companyOptions: []
};

const eventCompanyPageFilterReducer = (
  state: EventCompanyPageFilterState = initialState,
  action: EventCompanyPageFilterActionTypes
): EventCompanyPageFilterState => {
  switch (action.type) {
    case SET_SELECTED_EVENT_ID:
      return {
        ...state,
        selectedEventId: action.eventId,
        companyOptions: []
      };
    case SET_SELECTED_COMPANY_IDS:
      return {
        ...state,
        selectedCompanyIds: action.companyIds
      };
    case SET_EVENT_OPTIONS:
      return {
        ...state,
        eventOptions: action.eventOptions
      };
    case SET_COMPANY_OPTIONS:
      return {
        ...state,
        companyOptions: action.companies
      };
    default:
      return state;
  }
};

export default eventCompanyPageFilterReducer;
