import * as yup from 'yup';

const removeOwnTeamMateSchema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  joinedStatus: yup.boolean()
});

export type RemoveOwnTeamMateSchema = yup.InferType<typeof removeOwnTeamMateSchema>;

export default removeOwnTeamMateSchema;
