import { apiRequest, apiFetch, objectToQueryString } from '../Utils';
import { server } from '../../../CONSTANTS';
import moment from 'moment';

import {
  setCurrentEvent,
  setPastEvents,
  setEventLinkingInfo,
  setEventLinkingLoading,
  setCurrentEventLoading,
  setPastEventsLoading
} from '../../Store/contexts/companyAdmin/event/actions';
import { setNotification } from 'src/lib/Store/contexts/notification/actions';

import {
  APIThunkResult,
  CompanyEventStatus,
  CompanyDemographic,
  EventLinkingInfo,
  Event,
  PagedResponse,
  PaginationFilter,
  TeamListFilter
} from '../../Types';
import { setTeamListFilter } from 'src/lib/Store/contexts/team/actions';

export function getCurrentEvent(companyId: number): APIThunkResult<CompanyEventStatus> {
  return apiRequest<CompanyEventStatus>(async dispatch => {
    try {
      dispatch(setCurrentEventLoading(true));
      const result = await apiFetch<CompanyEventStatus>({
        url: `${server}/events/current/${companyId}`,
        method: 'GET'
      });
      dispatch(setCurrentEvent(result));
      // TODO: FIX THIS PROPERLY
      const filter = {
        page: 1,
        rowsPerPage: 20,
        orderColumn: 'teamId',
        orderDirection: 'ASC',
        keyword: '',
        companyId: companyId,
        eventId: result.eventId
      };
      dispatch(setTeamListFilter(filter as TeamListFilter));

      return result;
    } finally {
      dispatch(setCurrentEventLoading(false));
    }
  });
}

export function getPastEvents(
  companyId: number,
  filter: PaginationFilter
): APIThunkResult<PagedResponse<Event>> {
  return apiRequest<PagedResponse<Event>>(async dispatch => {
    try {
      dispatch(setPastEventsLoading(true));
      const query = objectToQueryString(filter);
      const response = await apiFetch<PagedResponse<Event>>({
        url: `${server}/events/past/${companyId}?${query}`,
        method: 'GET'
      });

      const eventsWithDates = response.results.map(event => {
        const startDateWTimezone = moment(event.startDate, 'x')
          .tz(event.timezone)
          .format('YYYY/MM/DD HH:mm');
        const endDateWTimezone = moment(event.endDate, 'x')
          .tz(event.timezone)
          .format('YYYY/MM/DD HH:mm');
        return {
          ...event,
          eventDates: `${startDateWTimezone} - ${endDateWTimezone}`
        };
      });

      const pastEventsData = { count: response.count, results: eventsWithDates };

      dispatch(setPastEvents(pastEventsData));
      return pastEventsData;
    } finally {
      dispatch(setPastEventsLoading(false));
    }
  });
}

export function getCurrentEvents(companyId: number): APIThunkResult<CompanyEventStatus> {
  return apiRequest<CompanyEventStatus>(async dispatch => {
    try {
      dispatch(setCurrentEventLoading(true));
      const result = await apiFetch<CompanyEventStatus>({
        url: `${server}/events/current/${companyId}`,
        method: 'GET'
      });
      dispatch(setCurrentEvent(result));

      return result;
    } finally {
      dispatch(setCurrentEventLoading(false));
    }
  });
}

export function validateEventPassCode(passCode: string): APIThunkResult<EventLinkingInfo> {
  return apiRequest<EventLinkingInfo>(async dispatch => {
    try {
      setEventLinkingLoading(true);
      const data = await apiFetch<EventLinkingInfo>({
        url: `${server}/events/validate-passcode`,
        method: 'POST',
        body: {
          passCode
        }
      });

      dispatch(setEventLinkingInfo(data));
      return data;
    } catch (e) {
      dispatch(setNotification({ message: e?.message, duration: 3, variant: 'danger' }));
    } finally {
      setEventLinkingLoading(false);
    }
  });
}

export function linkToEvent(
  companyId: number,
  passCode: string,
  maxParticipants: number,
  greeting: string,
  companyDemographics: Partial<CompanyDemographic>[]
): APIThunkResult<CompanyEventStatus> {
  return apiRequest<CompanyEventStatus>(async dispatch => {
    try {
      dispatch(setEventLinkingLoading(true));
      const data = await apiFetch<CompanyEventStatus>({
        url: `${server}/events/register-company`,
        method: 'POST',
        body: {
          companyId,
          passCode,
          maxParticipants,
          greeting,
          companyDemographics
        }
      });

      dispatch(setCurrentEvent(data));
      dispatch(
        setNotification({
          message: `Successfully Registered for ${data.name} Event!`,
          duration: 3,
          variant: 'success'
        })
      );
      return data;
    } catch (e) {
      dispatch(setNotification({ message: e?.message, duration: 3, variant: 'danger' }));
    } finally {
      dispatch(setEventLinkingLoading(false));
    }
  });
}

export function updateCompanyEventRegistration(
  companyId: number,
  eventId: number,
  maxParticipants: number,
  greeting: string,
  companyDemographics: Partial<CompanyDemographic>[]
): APIThunkResult<CompanyEventStatus> {
  return apiRequest<CompanyEventStatus>(async dispatch => {
    try {
      dispatch(setEventLinkingLoading(true));
      const data = await apiFetch<CompanyEventStatus>({
        url: `${server}/events/edit-company-registration`,
        method: 'PUT',
        body: {
          companyId,
          eventId,
          maxParticipants,
          greeting,
          companyDemographics
        }
      });

      dispatch(setCurrentEvent(data));
      dispatch(
        setNotification({
          message: 'Event Registration Updated!',
          duration: 3,
          variant: 'success'
        })
      );
      return data;
    } catch (e) {
      dispatch(setNotification({ message: e?.message, duration: 3, variant: 'danger' }));
    } finally {
      dispatch(setEventLinkingLoading(false));
    }
  });
}
