import styled from 'styled-components/macro';

interface RadioButtonProps {
  selected?: boolean;
}

interface InlineProps {
  inline?: boolean;
}

interface WrapperProps {
  inline?: boolean;
  horizontal?: boolean;
  columns?: number;
}

export const Wrapper = styled.div<WrapperProps>`
  margin: 0 0 2rem 0;
  @media (min-width: ${({ theme }) => theme.device.tablet}px) {
    ${({ horizontal }) => horizontal && 'display: flex'};
    ${({ horizontal, inline }) => {
      if (inline) {
        return 'justify-content: flex-start';
      } else if (horizontal) {
        return 'justify-content: flex-start; align-items: flex-start';
      } else {
        return 'justify-content: unset';
      }
    }};
    ${({ columns }) => columns && 'display: grid'};
    ${({ columns }) => columns && `grid-template-columns: repeat(${columns}, ${100 / columns}%)`};
  }
`;
export const Container = styled.div``;

export const RadioOptionWrapper = styled.div<{ margin?: boolean; inline?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: ${({ theme, margin }) => (margin ? `${theme.padding}px 0px;` : '0;')};
  padding-left: ${props => (props.inline ? '2rem' : 0)};
  cursor: pointer;
  &:first-child {
    padding-left: 0;
  }
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    padding-left: 0;
  }
`;

export const OptionInfoWrapper = styled.div<InlineProps>`
  flex: 1;
  display: ${props => (props.inline ? 'flex' : 'block')};
  padding-left: ${({ theme }) => theme.padding}px;

  p {
    &:last-child {
      margin-left: ${props => (props.inline ? '0.5rem' : '0')};
    }
  }
`;

export const OptionButtonWrapper = styled.div<RadioButtonProps>`
  font-size: 0;
  width: min-content;
  height: min-content;
  position: relative;
  transform: ${props => (props.selected ? 'scale(0.8)' : 'scale(1)')};

  &::after {
    display: ${props => (props.selected ? 'block' : 'none')};
    content: '';
    position: absolute;
    border: 1.5px #75dbff solid;
    border-radius: 50%;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    margin: auto;
  }
`;

export const RadioOptionIconWrapper = styled.div`
  margin-left: ${({ theme }) => theme.padding}px;
`;

interface ImageProps {
  image: string;
}

export const RadioOptionIcon = styled.div<ImageProps>`
  border-radius: 50%;
  position: relative;
  width: 4rem;
  height: 4rem;
  background: center / cover ${props => `url('${props.image}') no-repeat`};
`;

export const RadioOptionLabel = styled.p<InlineProps>`
  font-weight: ${({ theme, inline }) => {
    if (inline) {
      return theme.fontWeights.light;
    } else {
      return theme.fontWeights.bold;
    }
  }};
  font-size: ${({ inline }) => {
    if (inline) {
      return '1.2rem';
    } else {
      return '1.45rem';
    }
  }};
  color: ${({ theme }) => theme.primary.dark};
  font-style: oblique;
  padding-right: 3rem;
  white-space: nowrap;
`;
export const RadioOptionBody = styled.p<InlineProps>`
  font-weight: ${({ theme, inline }) => {
    if (inline) {
      return theme.fontWeights.light;
    } else {
      return theme.fontWeights.regular;
    }
  }};
  font-size: 1.2rem;
  color: ${({ theme }) => theme.primary.dark};
  font-style: oblique;
`;
