import styled from 'styled-components/macro';
import { DarkBlueHeaderM } from '../../../Themes';

interface Flex {
  gradient?: string;
  minHeight?: number | string;
  borderRadius?: string;
  disabled: boolean;
}

interface IconWrapperProps {
  iconBackground?: string;
}

interface BadgeProps {
  badgeBackground?: string;
}

export const LeftImage = styled.img``;

export const Card = styled.div<Flex>`
  flex: 1;
  background-image: ${props => (props.gradient ? props.gradient : null)};
  align-items: center;
  min-height: ${({ minHeight }) => (minHeight && minHeight !== 'auto' ? `${minHeight}px` : 'auto')};
  height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 1.3rem 2rem;
  border-radius: ${({ borderRadius = '1.3rem' }) => borderRadius};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.45 : 1)};
`;

export const CountBadge = styled.p<BadgeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
  min-width: 45px;
  min-height: 45px;
  height: 45px;
  vertical-align: middle;
  background-color: ${({ theme }) => theme.backgrounds.white};
  border-radius: 2.8rem/3rem;
  margin-right: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: ${({ theme }) => theme.fonts.heading / 1.1}rem;
  color: ${props => (props.color ? props.color : props.theme.primary.dark)};
  //background-image:url("${props => (props.badgeBackground ? props.badgeBackground : 'none')}");
`;

export const CountHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CountHeading = styled(DarkBlueHeaderM)`
  margin-right: auto;
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  margin-right: ${({ theme }) => theme.padding}px;
  padding: ${({ theme }) => theme.padding / 2}px;
  border-radius: ${({ theme }) => theme.borderRadius.half};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ iconBackground }) => (iconBackground ? iconBackground : 'transparent')};
`;

export const StatIcon = styled.img`
  margin-left: auto;
  margin-right: 0.5rem;
`;
