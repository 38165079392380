import styled from 'styled-components/macro';
import { StatusVariant } from '../../../lib/Types';

export interface AlertProps {
  variant: StatusVariant;
}

export const Container = styled.div<AlertProps>`
  background: ${({ variant, theme }) => theme.status[variant]};
  color: ${({ theme }) => theme.backgrounds.white};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-bottom: ${({ theme }) => theme.padding}rem;
  padding: ${({ theme }) => theme.padding}rem;
  width: inherit;
`;

export const Icon = styled.img``;

export const Text = styled.p`
  display: inline-block;
`;
