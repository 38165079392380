import React, { FC } from 'react';
import { Group, Heading } from '../../ModalFormContainer/Styles';
import { ReplaceSchema } from '../../../../validators/replaceSchema';
import { FormikFormComponent, ParticipantListView } from '../../../../../../lib/Types';
import Button from '../../../../../../components/UI/Button';
import AutoSuggest from '../../../../../../components/UI/Input/AutoSuggest';

interface ReplaceIndividualProps extends FormikFormComponent<ReplaceSchema> {
  participant: ParticipantListView;
  individualParticipants: ParticipantListView[];
  handleDropdownChange: (name: string, value: any) => void;
}

const ReplaceIndividual: FC<ReplaceIndividualProps> = ({
  values,
  errors,
  individualParticipants,
  handleDropdownChange
}) => (
  <Group>
    <Heading>Select a user without a team</Heading>
    <AutoSuggest
      placeholder={'Search for an unassigned participant'}
      data={individualParticipants}
      error={errors.replaceParticipantId}
      valueProp={'participantId'}
      displayProp={'fullName'}
      value={values.replaceParticipantId}
      onChange={value => handleDropdownChange('replaceParticipantId', value)}
      emailLabel
    />
    <br />
    <Button type="submit" variant={'admin-primary'} width={'130px'} minWidth={'130px'}>
      Add to team
    </Button>
  </Group>
);

export default ReplaceIndividual;
