import React, { FC } from 'react';
import { TableLink } from '../../../../../../components/UI/';
import { ParticipantListView } from '../../../../../../lib/Types';
import { TableCell, TableRow } from './Styles';

interface SelectedParticipantProps {
  participant: ParticipantListView;
}

//  The team member being replaced (shows in replace/add modal)
const SelectedParticipant: FC<SelectedParticipantProps> = ({ participant }) => (
  <TableRow>
    <TableCell>1</TableCell>
    {participant.profileImage && (
      <TableCell>
        <TableLink
          size={'20%'}
          borderRadius={'50%'}
          source={participant.profileImage}
          margin={'4px 4px 0'}
        />
      </TableCell>
    )}
    <TableCell>
      {participant.firstName} {participant.lastName}{' '}
    </TableCell>
  </TableRow>
);

export default SelectedParticipant;
