/* eslint-disable react/no-unescaped-entities */
import React, { useState, FC, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { getAvailableServices } from '../../../../lib/Api/SyncService';

import Modal from '../Modal';

import { fitbitLogoText, googleFitLogo, garminLogoText } from '../../../../assets/icons/index';
import { Wrapper, Description, Services, Service } from './Styles';

import { SyncServiceCode, FitnessDevice, SyncService } from '../../../../lib/Types';
import { ApplicationState } from '../../../../lib/Store';

const initState = {
  stage: 2,
  activeServices: []
};

interface SyncDeviceModalProps {
  onClose: Function;
  device?: any;
  devices: FitnessDevice[];
  availableServices: SyncService[];
  getAvailableServices: () => any;
  syncDeviceAction?: (code: SyncServiceCode, onError: () => void) => void;
}

const SyncDeviceModal: FC<SyncDeviceModalProps> = ({
  onClose,
  syncDeviceAction,
  availableServices,
  getAvailableServices,
  devices
}) => {
  const [stage, setStage] = useState<number>(initState.stage);

  const title = () => {
    switch (stage) {
      case 0:
        return 'Whoops, something went wrong.';
      case 1:
        return 'Contacting Sync Service.';
      case 2:
        return 'Please choose the type of fitness tracker you would like to sync';
      case 3:
        return 'All available device services connected!';
      default:
        return 'Whoops, something went wrong.';
    }
  };

  useEffect(() => {
    getAvailableServices();
  }, []);

  return (
    // textAlign & padding in-line as styled component property would not apply centre alignment.
    <Modal
      style={{
        textAlign: 'center'
      }}
      padding={'20px 40px 80px 40px'}
      tabletWidth={85}
      width={45}
      top={20}
      left={27.5}
      tabletLeft={7.5}
      title={title()}
      buttons={[]}
      onClose={() => onClose()}
      minWidth={'0px'}
    >
      <Wrapper>
        {stage === 0 ? (
          <>
            <Description>Couldn't load the list of services.</Description>
          </>
        ) : null}
        {stage === 1 ? (
          <>
            <Description>Loading...</Description>
          </>
        ) : null}
        {stage === 2 ? (
          <>
            <Description>
              Once connected you will be able to easily sync your steps by tapping the Sync button
              on the activity entry page.
            </Description>
            <Services>
              {availableServices.map((service: SyncService) => {
                // Check if all services have connected devices for the user
                if (devices.length >= availableServices.length) setStage(3);

                // Check if the current service has a device connected for this user
                let alreadySynced = false;
                devices.map(usersDevice => {
                  if (usersDevice.serviceId === service.serviceId) alreadySynced = true;
                });

                if (alreadySynced) return;

                // Alter the image for service buttons based on SyncServiceCode
                let serviceImg = null;
                const serviceImgWidth = '125px';
                const serviceImgHeight = '100%';
                switch (service.code) {
                  case SyncServiceCode.fitbit:
                    serviceImg = fitbitLogoText;
                    break;
                  case SyncServiceCode.google_fit:
                    serviceImg = googleFitLogo;
                    break;
                  case SyncServiceCode.garmin:
                    serviceImg = garminLogoText;
                    break;
                  case SyncServiceCode.apple_health:
                    return;
                  default:
                    break;
                }

                return (
                  <Service
                    onClick={(): void => {
                      syncDeviceAction(service.code, () => setStage(1));
                      onClose();
                    }}
                    key={service.serviceId}
                  >
                    {serviceImg && (
                      <img
                        width={serviceImgWidth}
                        height={serviceImgHeight}
                        alt={service.name}
                        src={serviceImg}
                      />
                    )}
                  </Service>
                );
              })}
            </Services>
          </>
        ) : null}
        {stage === 3 ? (
          <>
            <Description>
              Once connected you will be able to easily sync your steps by tapping the Sync button
              on the activity entry page.
            </Description>
          </>
        ) : null}
        {stage >= 4 ? (
          <>
            <Description>
              There was an unexpected issue on the server. Please contact your administrator.
            </Description>
          </>
        ) : null}
      </Wrapper>
    </Modal>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.loadingState.apiCallsInProgress > 0,
  availableServices: state.syncServiceState.availableServices
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAvailableServices: () => getAvailableServices()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SyncDeviceModal);
