import React, { FC, useState, useMemo, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { getAuthCodeURL } from '../../../../../lib/Api/SyncService';

import {
  Container,
  FormControl,
  PlusActivity,
  PlusActivityHeading,
  PlusWrapper,
  SubmitButton,
  ActivityHeader,
  StopWatchIcon,
  UpCarat,
  HeaderWrapper,
  DaySliderWrap,
  Spacer,
  PlusIcon,
  AwaitingApprovalBanner,
} from './Styles';
import { DaySlider } from '../../../';
import AddSingleActivity from '../../components/AddSingleActivity';
import SyncDeviceModal from '../../../Modal/SyncDeviceModal';
import StepApprovalModal from '../../components/StepApprovalModal';
import SelectActivityTypes from '../../components/SelectActivityTypes';
import SyncDeviceBasic from '../../../SyncDeviceBasic';

import { ApplicationState } from '../../../../../lib/Store';
import { SyncServiceCode, ApprovalStatus } from '../../../../../lib/Types';
import { stopwatch, downCarat } from '../../../../../assets/icons/index';
import { FormProps } from '../../types';

enum AddActivityStep {
  DisplayButton = 0,
  DisplayDropdown = 1,
}

const initState = {
  device: {
    deviceId: 0,
    serviceId: 0,
    lastSynced: '',
  },
  connecting: false,
};

const Form: FC<FormProps> = ({
  completionDate,
  activityTypeId,
  repetitions,
  disabled,
  onChange,
  onSubmit,
  creatingNewActivity,
  previousActivities,
  setFieldValue,
  setStatus,
  setCollapsed,
  activityTypes,
  favoriteTypes,
  startDate,
  endDate,
  deleteActivity,
  hasActivity,
  clearExistingValues,
  stepLimitBreached,
  stepApprovalReason,
  stepApprovalThreshold,
  formErrors,
  isSubmitting,
}) => {
  const dispatch = useDispatch();
  const [connecting, setConnecting] = useState(false);
  const [activityStep, setActivityStep] = useState<AddActivityStep>(
    previousActivities.length === 0
      ? AddActivityStep.DisplayDropdown
      : AddActivityStep.DisplayButton
  );
  const location = useLocation();

  let previousActivitiesBreached = false;

  if (previousActivities.length > 0) {
    const stepsToday = previousActivities.map(
      activity => activity.repetitions * activity.eventActivityType.activityType.conversion
    );
    const totalStepsToday = stepsToday.reduce((a, b) => a + b);

    previousActivitiesBreached = totalStepsToday >= stepApprovalThreshold;

    previousActivities.forEach(activity => {
      if (activity.approvalStatus !== ApprovalStatus.Approved) {
        // If there is any unapproved or awaiting approvals set breached true
        previousActivitiesBreached = true;
      } else previousActivitiesBreached = false;
    });
  } else {
    previousActivitiesBreached = false;
  }

  const { usersDevices } = useSelector(({ userState }: ApplicationState) => ({
    usersDevices: userState.userDevices,
  }));

  const MemoDaySlider = useMemo(
    () => (
      <DaySlider
        onChange={onChange}
        selectedDate={completionDate}
        startDate={startDate}
        endDate={endDate}
        hasActivity={hasActivity}
      />
    ),
    [completionDate, startDate, endDate, hasActivity, onChange]
  );

  const previousActivityTypeIds = previousActivities.map(({ activityTypeId }) => activityTypeId);

  // Filter out activity types that already have an entry associated with them
  const filteredFavoriteTypes = favoriteTypes.filter(
    ({ activityTypeId }) => !previousActivityTypeIds.some(id => id === activityTypeId)
  );

  const filteredActivityTypes = activityTypes.filter(
    ({ activityTypeId }) => !previousActivityTypeIds.some(id => id === activityTypeId)
  );

  const getAuthCode: any = (serviceCode: SyncServiceCode, state: string) =>
    dispatch(getAuthCodeURL(serviceCode, state));

  const getAuth = async (serviceCode: SyncServiceCode, onError: () => void) => {
    // State = pathname + serviceCode - used on callback. Object -> base64 encode (url safe + obfuscate data)
    const stateData = {
      location: location.pathname,
      serviceCode: serviceCode,
    };
    const encodedState = window.btoa(JSON.stringify(stateData));

    const { data, error } = await getAuthCode(serviceCode, encodedState);

    // If there is an issue getting the authURL from the server
    if (error || !data) onError();

    if (data.authURL) {
      window.location.href = data.authURL;
    }
  };

  return (
    <>
      {connecting ? (
        <div style={{ textAlign: 'left' }}>
          <SyncDeviceModal
            onClose={(): void => setConnecting(false)}
            device={usersDevices[0] ? usersDevices[0] : initState.device}
            devices={usersDevices}
            syncDeviceAction={getAuth}
          />
        </div>
      ) : null}
      {stepLimitBreached && (
        <StepApprovalModal
          errors={formErrors}
          handleSubmit={onSubmit}
          onCancel={clearExistingValues}
          setFieldValue={setFieldValue}
          stepApprovalReason={stepApprovalReason}
          date={completionDate}
        />
      )}
      <Container>
        <HeaderWrapper>
          <UpCarat onClick={() => setCollapsed(true)} src={downCarat} />
          <StopWatchIcon src={stopwatch} />
          <ActivityHeader>Add My Activity</ActivityHeader>
        </HeaderWrapper>
        <form onSubmit={onSubmit}>
          <DaySliderWrap>
            {MemoDaySlider}
            <SyncDeviceBasic setConnecting={setConnecting} />
          </DaySliderWrap>
          <Spacer />
          {previousActivities.map(
            ({ activityId, activityTypeId, repetitions, approvalStatus }, index) => (
              <FormControl key={`${activityId}`}>
                {approvalStatus !== ApprovalStatus.Approved && (
                  <AwaitingApprovalBanner>
                    This activity is awaiting admin approval and is locked
                  </AwaitingApprovalBanner>
                )}
                <AddSingleActivity
                  activityTypeId={activityTypeId}
                  repetitions={repetitions.toString()}
                  updateActivity={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(
                      `previousActivities[${index}].repetitions`,
                      parseInt(e.target.value, 10)
                    )
                  }
                  removeActivity={() => deleteActivity(activityId, location)}
                />
              </FormControl>
            )
          )}
          {!creatingNewActivity && !previousActivitiesBreached && (
            <FormControl>
              <PlusWrapper>
                {activityStep === AddActivityStep.DisplayDropdown ? (
                  <>
                    <PlusActivityHeading>
                      What type of activity would you like to add?
                    </PlusActivityHeading>
                    <SelectActivityTypes
                      favoriteTypes={filteredFavoriteTypes}
                      activityTypes={filteredActivityTypes}
                      onClick={event => {
                        setFieldValue('activityTypeId', parseInt(event.value, 10));
                        setStatus({ creatingNewActivity: true });
                        setActivityStep(AddActivityStep.DisplayButton);
                        return true;
                      }}
                    />
                  </>
                ) : (
                  <PlusActivity
                    type="button"
                    onClick={() => setActivityStep(AddActivityStep.DisplayDropdown)}
                  >
                    <PlusIcon />
                    Add another activity
                  </PlusActivity>
                )}
              </PlusWrapper>
            </FormControl>
          )}
          {creatingNewActivity && !previousActivitiesBreached && (
            <FormControl>
              <AddSingleActivity
                activityTypeId={activityTypeId}
                repetitions={repetitions}
                updateActivity={onChange}
                removeActivity={clearExistingValues}
              />
            </FormControl>
          )}
          {!previousActivitiesBreached && (
            <FormControl>
              <SubmitButton
                disabled={disabled || isSubmitting}
                onClick={() => setFieldValue(`windowLocation`, location)}
                type="submit"
              >
                Save
              </SubmitButton>
            </FormControl>
          )}
        </form>
      </Container>
    </>
  );
};
export default Form;
