import { Permission, RoleCode } from '../../../Types';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';

export interface PermissionsState {
  code: RoleCode;
  permissions: Permission[];
  mustUpdatePassword: boolean;
}

export interface SetPermissionsAction {
  type: typeof SET_PERMISSIONS;
  code: RoleCode;
  permissions: Permission[];
  mustUpdatePassword: boolean;
}

export type PermissionsActionTypes = SetPermissionsAction;
