import React, { FC } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import TermsAndConditionsForm from './TermsAndConditionsForm';
import { ExternalPageWrapper } from '../../components/UI';

const TermsAndConditions: FC = ({}) => (
  <ExternalPageWrapper tabletAlign="center" header="General Terms and Conditions">
    <TermsAndConditionsForm />
  </ExternalPageWrapper>
);

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(null, mapDispatchToProps)(TermsAndConditions);
