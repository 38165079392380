import React, { FC, ChangeEvent } from 'react';
import {
  ActivityType,
  FormikForm,
  PagedResponse,
  CompanyRegistrationData,
  PaginationFilter
} from '../../../../lib/Types';
import { EventSchema } from '../../validators/eventSchema';
import EventForm from '../EventForm/EventForm';

import { PageHeader } from '../CreateEvent/Styles';
import BackButton from '../../../../components/UI/Button/BackButton';

interface EditEventProps extends FormikForm<EventSchema> {
  activityTypes: ActivityType[];
  companiesInEvent: PagedResponse<CompanyRegistrationData>;
  filter: PaginationFilter;
  onCancel: () => void;
  handleDateChangeCustom: (name: string, date: Date) => void;
  handleTimeChangeCustom: (name: string, timeOptionId: number) => void;
  handleSetCost: (e: ChangeEvent<HTMLInputElement>) => void;
}

const EditEvent: FC<EditEventProps> = ({ companiesInEvent, filter, handleSetCost, ...props }) => (
  <>
    <PageHeader>
      <BackButton onClick={() => props.onCancel()} />
      Edit an Event
    </PageHeader>
    <EventForm
      companiesInEvent={companiesInEvent}
      filter={filter}
      handleSetCost={handleSetCost}
      {...props}
    />
  </>
);

export default EditEvent;
