import styled from 'styled-components/macro';
import { CommentProfileImageContainer } from './CommentProfileImageContainer';
import PostImage from '../PostImage';

export const CommentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.padding}px;
  margin-left: ${({ theme }) => theme.padding}px;
  position: relative;
  @media (min-width: ${({ theme }) => theme.device.laptopL}px) {
    margin-left: 0;
  }
`;

export const CommentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CommentMainColumn = styled.div`
  width: 85%;
`;

export const CommentProfilePicture = styled(CommentProfileImageContainer)``;

export const CommentImage = styled(PostImage)``;

export const CommentBody = styled.div`
  margin: ${({ theme }) => theme.padding * 2}px;
  position: relative;
  font-size: 1.4rem;

  @media (min-width: ${({ theme }) => theme.device.tablet}px) {
    margin: ${({ theme }) => theme.padding}px ${({ theme }) => theme.padding}px
      ${({ theme }) => theme.padding}px 0;
  }
`;

export const CommentUser = styled.span`
  font-size: 1.6rem;
  font-weight: 500;
`;

export const CommentAction = styled.img`
  margin-top: ${({ theme }) => theme.padding}px;
`;

export const CommentStats = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const CommentStat = styled.span`
  margin-right: ${({ theme }) => theme.padding}px;
  font-weight: 500;
`;
