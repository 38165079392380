import { Post, PaginationFilter } from '../../../Types';
import {
  ExtendedPostsFilter,
  SetPostsAction,
  SET_POSTS,
  UpdatePostsFilterAction,
  UPDATE_POSTS_FILTER,
  AddPostAction,
  ADD_POST,
  AddPostsAction,
  ADD_POSTS,
  SetAllLoadedAction,
  SET_ALL_LOADED,
  UpdatePostAction,
  UPDATE_POST,
  SetMiniChallengesAction,
  SET_MINI_CHALLENGES,
  UpdateMiniChallengeAction,
  UPDATE_MINI_CHALLENGE,
  SET_ALL_REPLIES_LOADED,
  SetAllRepliesLoadedAction,
  UpdateRepliesFilterAction,
  UPDATE_REPLIES_FILTER
} from './types';

export const setPosts = (posts: Post[]): SetPostsAction => ({
  type: SET_POSTS,
  posts
});

export const addPost = (post: Post): AddPostAction => ({
  type: ADD_POST,
  post
});

export const updatePost = (postData: Partial<Post>): UpdatePostAction => ({
  type: UPDATE_POST,
  postData
});

export const addPosts = (posts: Post[]): AddPostsAction => ({
  type: ADD_POSTS,
  posts
});

export const updatePostsFilter = (filter: ExtendedPostsFilter): UpdatePostsFilterAction => ({
  type: UPDATE_POSTS_FILTER,
  filter
});

export const updatePostRepliesFilter = (
  repliesFilter: PaginationFilter
): UpdateRepliesFilterAction => ({
  type: UPDATE_REPLIES_FILTER,
  repliesFilter
});

export const setAllLoaded = (allLoaded = true): SetAllLoadedAction => ({
  type: SET_ALL_LOADED,
  allLoaded
});

export const setAllRepliesLoaded = (allRepliesLoaded = true): SetAllRepliesLoadedAction => ({
  type: SET_ALL_REPLIES_LOADED,
  allRepliesLoaded
});

export const setMiniChallenges = (miniChallenges: Post[]): SetMiniChallengesAction => ({
  type: SET_MINI_CHALLENGES,
  miniChallenges
});

export const updateMiniChallenge = (
  miniChallengeData: Partial<Post>
): UpdateMiniChallengeAction => ({
  type: UPDATE_MINI_CHALLENGE,
  miniChallengeData
});

export default {
  setPosts,
  addPost,
  updatePost,
  addPosts,
  updatePostsFilter,
  updatePostRepliesFilter,
  setAllLoaded,
  setAllRepliesLoaded,
  setMiniChallenges,
  updateMiniChallenge
};
