import React, { FC, ChangeEvent } from 'react';
import { check } from '../../../assets/icons';
import { StyledCheckbox, Label, CheckboxContainer, HiddenCheckbox, Icon } from './Styles';
import ErrorLabel from '../ErrorLabel';

interface CheckboxProps {
  name?: string;
  label?: string;
  boldLabel?: boolean;
  error?: any;
  checked: boolean;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: FC<CheckboxProps> = (
  { label, error, checked, onChange, name, disabled, children, boldLabel = false },
  props
) => {
  const BoldLabel = boldLabel ? <b>{label}</b> : label;
  return (
    <>
      {error ? <ErrorLabel error={error} /> : ''}
      <Label>
        <CheckboxContainer>
          <HiddenCheckbox
            name={name}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            {...props}
          />
          <StyledCheckbox checked={checked}>
            <Icon src={check} />
          </StyledCheckbox>
        </CheckboxContainer>
        {BoldLabel || children}
      </Label>
    </>
  );
};

export default Checkbox;
