import { useState, useEffect } from 'react';

import Backend from '../lib/Backend';
import { getUser } from '../lib/Api/User';

import { AuthUser } from '../lib/Backend/Authentication';
import { Permission, RoleCode, ApiStatus, CustomClaims } from '../lib/Types';
import { store } from '..';

interface AuthState {
  permissions: Permission[];
  code: RoleCode;
  pending: boolean;
  mustUpdatePassword: boolean;
}

const useAuth = (): AuthState => {
  const [authState, setAuthState] = useState<AuthState>({
    code: null,
    permissions: [],
    pending: true,
    mustUpdatePassword: false
  });

  useEffect(() => {
    const unregisterObserver = Backend.onIdTokenChanged(async (authUser: AuthUser) => {
      if (authUser) {
        const idToken = await authUser.getIdTokenResult();
        const claims = idToken.claims as CustomClaims;
        const mustUpdatePassword = claims?.mustUpdatePassword;
        const { status } = await store.dispatch(getUser(claims.code) as any);

        if (status === ApiStatus.SUCCESS) {
          setAuthState({
            permissions: claims.permissions,
            code: claims.code,
            pending: false,
            mustUpdatePassword
          });

          return;
        }
      }
      setAuthState({ permissions: [], code: null, pending: false, mustUpdatePassword: false });
    });
    return () => unregisterObserver();
  }, []);

  return { ...authState };
};

export default useAuth;
