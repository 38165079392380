import React, { FC } from 'react';
import { FieldArray } from 'formik';

import { Input } from '../../../components/UI';
import ListInput from '../../../components/UI/Input/ListInput';

import { Container, PaddedContainer, ListInputWrapper } from './Styles';

import { FormikFormComponent } from '../../../lib/Types';
import { LinkCompanyEventSchema } from '../../../lib/validators/linkCompanyEventSchema';

export interface CompanyDemographicsProps extends FormikFormComponent<LinkCompanyEventSchema> {}

type OptionsError = { name?: string }[];
type LocalError = {
  name?: string;
  options?: string | OptionsError;
};

const CompanyEventDemographics: FC<CompanyDemographicsProps> = ({
  values,
  errors,
  handleChange
}) => {
  // Handling errors for questions and answers, UI is too compact to display error messages on answers.
  const getError = (index: number) => {
    if (!errors.demographics) return null;
    const localError = errors.demographics[index] as LocalError; // Error relative to this demographic
    if (localError.name) return localError.name; // Error is question
    // Error is with answers
    if (localError.options) {
      const optionsError = localError.options;
      if (Array.isArray(optionsError)) {
        return (optionsError as OptionsError)?.find(o => o && !!o.name)?.name; // Error is with one of answers inputs
      } else if ((optionsError as string) !== undefined) {
        return optionsError as string; // Error is with state of all answers
      }
    }
    return null;
  };
  return (
    <FieldArray name="demographics">
      {({ push, remove }) => (
        <ListInputWrapper>
          <ListInput
            canRemoveLast
            inputArray={[
              ...values.demographics.map((demo, index) => (
                <Container key={`demographic-${index}`}>
                  <Input
                    required
                    margin={'0px 0px 20px 0px'}
                    id={`demographics.${index}.name`}
                    name={`demographics.${index}.name`}
                    label="Demographic Question"
                    value={demo.name}
                    onChange={handleChange}
                    error={getError(index)}
                  />
                  <FieldArray name={`demographics.${index}.options`}>
                    {({ push, remove }) => (
                      <PaddedContainer>
                        <ListInput
                          canRemoveLast
                          inputArray={[
                            ...values.demographics[index].options.map((demoOpt, demoIndex) => (
                              <>
                                <Input
                                  key={`demographicOpt-${demoIndex}`}
                                  required
                                  id={`demographics.${index}.options.${demoIndex}.name`}
                                  name={`demographics.${index}.options.${demoIndex}.name`}
                                  label="Demographic Option"
                                  value={demoOpt.name}
                                  onChange={handleChange}
                                  error={null}
                                />
                                <br></br>
                              </>
                            ))
                          ]}
                          buttonLabel="Option"
                          buttonFlex={1}
                          buttonMargin={'0px 10px 0px 0px'}
                          onAdd={() => push({ name: '', options: [] })}
                          onRemove={(_, index) => remove(index)}
                        />
                      </PaddedContainer>
                    )}
                  </FieldArray>
                </Container>
              ))
            ]}
            buttonLabel="Add A New Demographic Question"
            onAdd={() => push({ name: '', options: [{ name: '' }] })} // default one answer with new question
            onRemove={(_, index) => remove(index)}
          />
        </ListInputWrapper>
      )}
    </FieldArray>
  );
};

export default CompanyEventDemographics;
