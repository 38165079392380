import React, { FC, useState } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { dateSingleInputPhrases } from '@datepicker-react/styled';
import { DateSingleInputProps } from '@datepicker-react/styled/lib/components/DateSingleInput/DateSingleInput';
import { StyledDatePicker, Wrapper } from './Styles';
import { RequiredLabel, ErrorLabel } from '..';
import { datePickerTheme } from '../../../theme';

interface DatePickerProps extends Partial<DateSingleInputProps> {
  id: string;
  required?: boolean;
  error?: any;
  datePlaceholder?: string;
  onChange: (name: string, date: Date | null) => void;
  margin?: string;
}

const DatePicker: FC<DatePickerProps> = ({
  id,
  date,
  displayFormat = 'dd/MM/yyyy',
  required,
  error,
  datePlaceholder = 'Select Date',
  onChange,
  margin,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ThemeProvider theme={datePickerTheme}>
      {error ? <ErrorLabel error={error} /> : ''}
      <Wrapper margin={margin}>
        {required ? <RequiredLabel /> : ''}
        <StyledDatePicker
          inputId={id}
          date={date}
          displayFormat={displayFormat}
          showDatepicker={isOpen}
          onDateChange={({ date }) => {
            onChange(id, date);
            setIsOpen(false);
          }}
          onFocusChange={() => setIsOpen(!isOpen)}
          valid={!error}
          phrases={{ ...dateSingleInputPhrases, datePlaceholder }}
          {...props}
        />
      </Wrapper>
    </ThemeProvider>
  );
};

export default DatePicker;
