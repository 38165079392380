import { AnyAction, applyMiddleware, CombinedState, compose, createStore, Store } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import rootReducer, { ApplicationState } from '.';

const configureStore = (): Store<CombinedState<ApplicationState>, AnyAction> => {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant()))
  );
};

export default configureStore;
