import React, { FC, MouseEvent } from 'react';
import { StyledButton } from './Styles';
import { InputVariant } from '../../../../lib/Types';

interface ModalButtonProps {
  variant: InputVariant;
  type?: 'button' | 'submit';
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
}

const ModalButton: FC<ModalButtonProps> = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
);

export default ModalButton;
