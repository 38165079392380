import { PermissionsActionTypes, SET_PERMISSIONS } from './types';
import { Permission, RoleCode } from '../../../Types';

export const setPermissions = (
  code: RoleCode,
  permissions: Permission[],
  mustUpdatePassword: boolean
): PermissionsActionTypes => ({
  type: SET_PERMISSIONS,
  code,
  permissions,
  mustUpdatePassword
});
