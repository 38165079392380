import React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import CompanyEventRegistration from '../../components/UI/CompanyEventRegistration';
import PageWrapper from '../../components/UI/PageWrapper';

import { ApplicationState } from '../../lib/Store';
import { setParticipantsFilter } from '../../lib/Store/contexts/participants/actions';
import { setTeamListFilter } from '../../lib/Store/contexts/team/actions';
import { APIThunkDispatch } from '../../lib/Types';

interface RegistrationDashboardProps {}

const RegistrationDashboard: React.FC<RegistrationDashboardProps> = () => {
  const Dispatch: APIThunkDispatch = useDispatch();
  const { currentEventLoading, participantsFilter, teamsFilter } = useSelector(
    ({ companyAdminEventState, participantsState, teamsState }: ApplicationState) => ({
      companyAdminEvent: companyAdminEventState.currentEvent,
      currentEventLoading: companyAdminEventState.currentEventLoading,
      participantsFilter: participantsState.filter,
      teamsFilter: teamsState.filter
    })
  );

  const handleDropdownClick = (dataType: string, props: any) => {
    let id;
    if (props.id === 'all') id = null;
    else id = typeof props.id === 'number' ? props.id : parseInt(props.id, 10);

    if (dataType === 'company') {
      Dispatch(setParticipantsFilter({ ...participantsFilter, companyId: id, page: 1 }));
      Dispatch(setTeamListFilter({ ...teamsFilter, companyId: id, page: 1 }));
    } else if (dataType === 'event') {
      Dispatch(setParticipantsFilter({ ...participantsFilter, eventId: id, page: 1 }));
      Dispatch(setTeamListFilter({ ...teamsFilter, eventId: id, page: 1 }));
    }
  };

  return (
    <PageWrapper handleDropdownClick={handleDropdownClick}>
      {!currentEventLoading && <CompanyEventRegistration />}
    </PageWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
export default connect(null, mapDispatchToProps)(RegistrationDashboard);
