import React, { useState, FC } from 'react';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import InstructionsSent from './components/InstructionsSent';

export interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const [passwordReset, setPasswordReset] = useState(false);
  return (
    <>
      {passwordReset === false ? (
        <ForgotPasswordForm setPasswordReset={setPasswordReset} />
      ) : (
        <InstructionsSent />
      )}
    </>
  );
};

export default ForgotPassword;
