import React, { FC } from 'react';
import { TeamApprovalStatus } from '../../../../../lib/Types';
import { DarkBlueBodyS, OrangeText, TurqText } from '../../../../../Themes';

export interface ApprovalStatusProps {
  approvalStatus: TeamApprovalStatus;
}

const ApprovalStatus: FC<ApprovalStatusProps> = ({ approvalStatus }) => (
  <>
    {approvalStatus === TeamApprovalStatus.Approved ? (
      <DarkBlueBodyS>
        Your team is <TurqText>approved</TurqText> by Admin.
      </DarkBlueBodyS>
    ) : (
      <DarkBlueBodyS>
        Your team is <OrangeText>awaiting approval</OrangeText> by an admin. We will let you know
        once approved.
      </DarkBlueBodyS>
    )}
  </>
);

export default ApprovalStatus;
