import styled from 'styled-components/macro';
import theme from '../../../../theme';
import { Button } from '../../../../components/UI';

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: auto;

  label {
    display: flex;
    flex: 0 0 50%;
    word-break: break-word;
    min-width: 10rem;

    &:nth-of-type(2n + 2) {
      padding-left: 1rem;
    }
  }
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${theme.device.tablet + 110}px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const FormColumn = styled.div`
  flex: 1;
  padding-right: ${({ theme }) => theme.padding * 2}px;
  padding-left: 0;

  @media (min-width: ${theme.device.laptop}px) {
    padding-right: ${({ theme }) => theme.padding * 4}px;
  }

  & ~ & {
    padding-left: ${({ theme }) => theme.padding * 2}px;
    padding-right: 0;

    @media (min-width: ${theme.device.laptop}px) {
      padding-left: ${({ theme }) => theme.padding * 4}px;
    }

    @media (max-width: ${theme.device.tablet + 110}px) {
      padding: 1.5rem 0 0;
    }
  }

  @media (max-width: ${theme.device.tablet + 110}px) {
    flex-direction: row;
    flex: 0 0 100%;
    padding: 0;
  }
`;

export const Group = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const Heading = styled.h4`
  font-size: ${({ theme }) => theme.fonts.subHeading / 1.25}rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.primary.dark};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.padding}px;

  span {
    font-size: 80%;

    @media (min-width: ${theme.device.tablet}px) and (max-width: ${theme.device.laptop}px) {
      display: block;
    }
  }
`;

export const Body = styled.p`
  font-size: ${({ theme }) => theme.fonts.body / 1.25}rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.primary.dark};
  margin-bottom: ${({ theme }) => theme.padding * 1.5}px;
`;

export const InlineInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InlineInput = styled.div`
  flex: 1;
  &:nth-child(1) {
    padding-right: ${({ theme }) => theme.padding / 2}px;
  }
  &:nth-child(2) {
    padding-left: ${({ theme }) => theme.padding / 2}px;
  }
`;

export const EventButton = styled(Button)`
  padding: 1.5rem 4rem;
  min-width: 0;
  max-width: none;
  width: auto;
  margin-left: 0;
  text-transform: uppercase;

  & ~ & {
    margin-left: 1.5rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.padding * 4}px;
`;

export const PaymentStatus = styled.div<{ isPaid: boolean; hasPending: boolean }>`
  color: ${({ isPaid, hasPending }) => {
    if (isPaid) return theme.status.success;
    if (hasPending) return theme.status.warning;
    return theme.status.danger;
  }};
`;
