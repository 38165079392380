import { apiRequest, apiFetch, objectToQueryString } from '../Utils';
import { generateTempAdminPassword } from '../../../lib/Utils';
import { server } from '../../../CONSTANTS';

import {
  APIResult,
  APIThunkResult,
  User,
  PagedResponse,
  PaginationFilter,
  CompanyAdminListView,
  Company,
  EventPayment
} from '../../Types';
import { SignupCompanyAdminSchema } from '../../validators/signupCompanyAdminSchema';
import { EditCompanyAdminSchema } from '../../validators/editCompanyAdminSchema';
import { EditCompanyDetailsSchema } from '../../validators/editCompanyDetailsSchema';

import { setNotification } from '../../Store/contexts/notification/actions';
import { setCompanyEventPayments } from '../../Store/contexts/companyAdmin/company/actions';
import { defaultCompanyAdminFilter } from '../../Store/contexts/companyAdmin/company/types';

export type GetPagedAdmins = (
  filter: PaginationFilter
) => Promise<APIResult<PagedResponse<CompanyAdminListView>>>;
export function getPagedAdmins(
  filter: PaginationFilter
): APIThunkResult<PagedResponse<CompanyAdminListView>> {
  return apiRequest<PagedResponse<CompanyAdminListView>>(async () => {
    const query = objectToQueryString(filter);
    const result = await apiFetch<PagedResponse<CompanyAdminListView>>({
      url: `${server}/companies/admins/paged?${query}`,
      method: 'GET'
    });
    return result;
  });
}

export type CreateAdmin = (data: SignupCompanyAdminSchema) => Promise<APIResult<User>>;
export function createAdmin(data: SignupCompanyAdminSchema): APIThunkResult<User> {
  return apiRequest<User>(async dispatch => {
    try {
      const response = await apiFetch<User>({
        url: `${server}/companies/admin/create`,
        method: 'POST',
        body: {
          ...data,
          password: generateTempAdminPassword()
        }
      });
      dispatch(setNotification({ message: 'Admin Created Successfully!' }));
      dispatch(getPagedAdmins(defaultCompanyAdminFilter));
      return response;
    } catch (err) {
      dispatch(
        setNotification({
          message: err?.message || 'Could not create company.',
          variant: 'danger',
          duration: 5
        })
      );
      throw err;
    }
  });
}

export type EditAdmin = (
  companyAdminId: number,
  data: EditCompanyAdminSchema
) => Promise<APIResult<User>>;
export function editAdmin(
  companyAdminId: number,
  data: EditCompanyAdminSchema
): APIThunkResult<User> {
  return apiRequest<User>(async dispatch => {
    try {
      const res = await apiFetch<User>({
        method: 'PUT',
        url: `${server}/companies/admin/edit/${companyAdminId}`,
        body: data
      });
      dispatch(setNotification({ message: 'Admin Saved!' }));
      dispatch(getPagedAdmins(defaultCompanyAdminFilter));
      return res;
    } catch (err) {
      dispatch(
        setNotification({
          message: err?.message || 'Could not create company.',
          variant: 'danger',
          duration: 5
        })
      );
      throw err;
    }
  });
}

export function updateCompanyDetails(currentCompanyData: Company, data: EditCompanyDetailsSchema) {
  return apiRequest<Company>(async dispatch => {
    try {
      const payload: EditCompanyDetailsSchema = { name: data.name };
      if (data?.profileImage && data?.profileImage !== currentCompanyData.profileImage) {
        payload.profileImage = data.profileImage;
      }
      const res = await apiFetch<Company>({
        method: 'PUT',
        url: `${server}/companies/edit/company-details`,
        body: payload
      });
      dispatch(setNotification({ message: 'Company Details Saved!' }));
      return res;
    } catch (err) {
      dispatch(
        setNotification({
          message: err?.message || 'Could not edit company.',
          variant: 'danger',
          duration: 5
        })
      );
      throw err;
    }
  });
}

export function getCompanyEventPayments(filter: PaginationFilter) {
  return apiRequest<PagedResponse<EventPayment>>(async dispatch => {
    try {
      const query = objectToQueryString(filter);
      const res = await apiFetch<PagedResponse<EventPayment>>({
        method: 'GET',
        url: `${server}/companies/paged/event-payments?${query}`
      });
      dispatch(setCompanyEventPayments(res));
      return res;
    } catch (err) {
      dispatch(
        setNotification({
          message: err?.message || 'Could not load event payments.',
          variant: 'danger',
          duration: 5
        })
      );
      throw err;
    }
  });
}
