import React, { FC } from 'react';

import {
  FormikForm,
  UserDemographic,
  ParticipantListView,
  Team,
  Company,
  CompanyDemographic
} from '../../../../lib/Types';
import { ParticipantSchema } from '../../validators/participantSchema';

import { ModalButton, Paper } from '../../../../components/UI';
import TeamDetails from './formComponents/TeamDetails';
import ActiveStatus from './formComponents/ActiveStatus';
import PasswordChange from './formComponents/PasswordChange';
import PersonalDetails from './formComponents/PersonalDetails';
import ParticipantDemographics from './formComponents/ParticipantDemographics';
import SDAExemption from './formComponents/SDAExemption';

import { backArrow } from '../../../../assets/icons';
import {
  FormColumn,
  ColumnWrapper,
  ButtonWrapper,
  ModalTitle,
  ModalTitleContainer
} from './Styles';

interface ParticipantFormProps extends FormikForm<ParticipantSchema> {
  participant: ParticipantListView;
  participantTeam: Team;
  userDemographics: UserDemographic[];
  companyOptions: Company[];
  companyDemographics: CompanyDemographic[];
  onCancel: () => void;
}

const ParticipantForm: FC<ParticipantFormProps> = ({
  handleSubmit,
  onCancel,
  handleChangeCustom,
  ...props
}) => (
  <Paper>
    <ModalTitleContainer>
      <img
        style={{ cursor: 'pointer', marginRight: '20px' }}
        alt="back"
        src={backArrow}
        onClick={onCancel}
      />
      <ModalTitle>Edit Participant</ModalTitle>
    </ModalTitleContainer>
    <br />
    <form onSubmit={handleSubmit}>
      <ColumnWrapper>
        <FormColumn>
          <PersonalDetails {...props} />
          <TeamDetails {...props} />
        </FormColumn>
        <FormColumn>
          <PasswordChange {...props} />
          <ParticipantDemographics {...props} />
          <ActiveStatus {...props} handleCheckboxChange={handleChangeCustom} />
          <SDAExemption {...props} handleCheckboxChange={handleChangeCustom} />
        </FormColumn>
      </ColumnWrapper>
      <ButtonWrapper>
        <ModalButton onClick={onCancel} type="button" variant={'secondary'}>
          Cancel
        </ModalButton>
        <ModalButton type="submit" variant={'primary'}>
          Save
        </ModalButton>
      </ButtonWrapper>
    </form>
  </Paper>
);

export default ParticipantForm;
