import React from 'react';

import { audioSplashImg } from '../../../assets/img';

import { AudioContainer } from './Styles';

interface AudioPlayerProps {
  audioURI: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioURI }) => {
  return (
    <>
      <img alt="PostAudio" src={audioSplashImg} style={{ width: '100%' }} />
      <AudioContainer preload="none" src={audioURI} controls controlsList="nodownload" />
    </>
  );
};

export default AudioPlayer;
