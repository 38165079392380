import React, { FC, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import { getMyCompanyContact } from '../../lib/Api/participant/Company';
import { checkEventAvailability } from '../../lib/Api/participant/Event';

import { EmployeePageWrapper } from '../../components/UI';
import RegisterModal from './RegisterModal';
import ParticipantCapModal from './ParticipantCapModal';
import { ParticipantRegistration, Submit } from './Styles';

import { DarkBlueHeaderL, TurqText, DarkBlueBodyS, BoldText } from '../../Themes';
import { Event, CompanyAdmin, APIThunkDispatch } from '../../lib/Types';

export interface RegisterEmployeeDashboardProps {
  event: Event;
}

const RegisterEmployeeDashboard: FC<RegisterEmployeeDashboardProps> = ({ event }) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const [isRegistering, setIsRegistering] = useState(true);
  const [participantCapError, setParticipantCapError] = useState(false);
  const [contactData, setContactData] = useState<CompanyAdmin>();

  const formatTime = (dateString: string, timezone: string): string =>
    `${moment(dateString, 'x')
      .tz(timezone)
      .format('h:mma z')}`;

  const formatDate = (dateString: string, timezone: string): string =>
    `${moment(dateString, 'x')
      .tz(timezone)
      .format('Do of MMMM YYYY')}`;

  const handleParticipantCapError = async () => {
    setIsRegistering(false);
    setParticipantCapError(true);

    const { data } = await dispatch(getMyCompanyContact());
    if (data) setContactData(data);
  };

  useEffect(() => {
    if (isRegistering) {
      const handleCheckAvailability = async () => {
        const { data } = await dispatch(checkEventAvailability({ eventId: event.eventId }));
        if (!data.isEventAvailability) handleParticipantCapError();
      };

      handleCheckAvailability();
    }
  }, [event, isRegistering]);

  return (
    <EmployeePageWrapper>
      {isRegistering && (
        <RegisterModal
          onCancel={() => setIsRegistering(false)}
          onParticipantCapError={handleParticipantCapError}
        />
      )}
      {participantCapError && (
        <ParticipantCapModal
          onConfirm={() => setParticipantCapError(false)}
          contactData={contactData}
        />
      )}
      <ParticipantRegistration>
        <DarkBlueHeaderL>
          You&apos;ve been invited to join the <TurqText> {event.name} </TurqText> Event
        </DarkBlueHeaderL>
        <DarkBlueBodyS padding={'1rem 10% 0 0'}>
          {'Event runs from '}
          <BoldText>{formatTime(event.startDate, event.timezone)}</BoldText>
          {' on the '}
          <BoldText>{formatDate(event.startDate, event.timezone)}</BoldText>
          {' until '}
          <BoldText>{formatTime(event.endDate, event.timezone)}</BoldText>
          {' on the '}
          <BoldText>{formatDate(event.endDate, event.timezone)}</BoldText>
          {'.'}
        </DarkBlueBodyS>
        <DarkBlueBodyS>
          {'Team registrations close at'}
          <BoldText> {formatTime(event.registrationsEndDate, event.timezone)}</BoldText>
          {' on the '}
          <BoldText>{formatDate(event.registrationsEndDate, event.timezone)}</BoldText>
        </DarkBlueBodyS>
        <Submit onClick={() => setIsRegistering(true)} variant="primary">
          Register
        </Submit>
      </ParticipantRegistration>
    </EmployeePageWrapper>
  );
};

export default RegisterEmployeeDashboard;
