import React, { FC } from 'react';
import {
  AddText,
  TableHeader,
  CaptainHeader,
  TableWrap,
  AddMemberRow,
  TableContainer
} from '../Styles';
import { Heading } from './Styles';
import { FormikFormComponent, Team, PendingParticipant } from '../../../../../lib/Types';
import { TeamSchema } from '../../../validators/teamSchema';
import TeamTable from '../../../../../components/UI/TeamTable';
import { TableLink } from 'src/components/UI';
import { addParticipantIcon } from 'src/assets/icons';

interface TeamMembersProps extends FormikFormComponent<TeamSchema> {
  team: Team;
  handleRadioChange: (name: string, value: any) => void;
  resendInvitationEmail: (email: string) => void;
  loadParticipant: (participantId: string) => void;
  loadPendingParticipant: (data: PendingParticipant) => void;
  setDeleting: (deletingParticipant: boolean) => any;
  setAddingTeamMember: (addingTeamMember: boolean) => any;
  removeRegistration: (team: Team, userId: string) => void;
}

const TeamMembers: FC<TeamMembersProps> = ({
  resendInvitationEmail,
  loadParticipant,
  loadPendingParticipant,
  setDeleting,
  team,
  removeRegistration,
  handleRadioChange,
  setAddingTeamMember
}) => {
  const onSetDeleting = (data: PendingParticipant) => {
    setDeleting(true);
    loadPendingParticipant(data);
  };

  const registrations = team.registrations.map(registration => ({
    ...registration.user,
    participantId: registration.user.userId,
    joinedStatus: registration.joinedStatus,
    teamCaptain: registration.teamCaptain,
    teamId: team.teamId
  }));

  const AddParticipantComponent = () => {
    const teamSize = team.event.teamSize;
    return team.registrations.length + team.pendingRegistrations.length < teamSize ? (
      <AddText>
        <TableLink
          size={'45%'}
          margin={'6px 6px 2px 25px'}
          source={addParticipantIcon}
          verticalAlign={'sub'}
        />
        Add Participant
      </AddText>
    ) : null;
  };

  return (
    <TableContainer>
      <TableHeader>
        <Heading>Team Members</Heading>
        <CaptainHeader>Captain</CaptainHeader>
      </TableHeader>
      <TableWrap>
        {team.registrations.length > 0 && (
          <TeamTable
            members={registrations}
            team={team}
            adminView={true}
            teamHasRegistrations
            resendInvitationEmail={resendInvitationEmail}
            removeRegistration={removeRegistration}
            handleCaptainRadioChange={handleRadioChange}
            triggerReplaceParticipant={loadParticipant}
          />
        )}

        {team.pendingRegistrations.length > 0 && (
          <TeamTable
            members={team.pendingRegistrations}
            team={team}
            adminView={true}
            listOffset={team.registrations.length}
            teamHasPendingRegistrations
            resendInvitationEmail={resendInvitationEmail}
            setDeleting={onSetDeleting}
          />
        )}
      </TableWrap>
      <AddMemberRow
        odd={(team.registrations.length + team.pendingRegistrations.length) % 2 === 1}
        onClick={() => setAddingTeamMember(true)}
      >
        {AddParticipantComponent()}
      </AddMemberRow>
    </TableContainer>
  );
};

export default TeamMembers;
