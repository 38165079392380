import { ReportState, ReportActionTypes, SET_REPORT_TYPES } from './types';
import { Reducer } from 'redux';

export const initialReportState: ReportState = {
  reportTypes: []
};

const ReportReducer: Reducer<ReportState, ReportActionTypes> = (
  state = initialReportState,
  action
) => {
  switch (action.type) {
    case SET_REPORT_TYPES:
      return { ...state, reportTypes: action.reportTypes };
    default:
      return state;
  }
};

export default ReportReducer;
