import { Event, PaginationFilter } from '../../../Types';

export const SET_EVENT_OPTIONS = 'SET_EVENT_OPTIONS';
export const SET_EVENT = 'SET_EVENT';
export const SET_EVENT_FILTER = 'SET_EVENT_FILTER';

export interface EventState {
  eventOptions: Event[];
  event: Event;
  filter: PaginationFilter;
}
export interface SetEventOptionsAction {
  type: typeof SET_EVENT_OPTIONS;
  eventOptions: Event[];
}

export interface SetEventAction {
  type: typeof SET_EVENT;
  event: Event;
}

export interface SetEventFilterAction {
  type: typeof SET_EVENT_FILTER;
  filter: PaginationFilter;
}

export type EventActionTypes = SetEventAction | SetEventOptionsAction | SetEventFilterAction;
