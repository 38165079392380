import firebase from 'firebase/app';
import 'firebase/storage';
import { v4 as uuid } from 'uuid';
import { APIResult, APIThunkResult, ProgressCallback } from '../Types';
import { apiRequest } from './Utils';
import { setLoadingSpinner, setLoadingPercent } from '../Store/contexts/loading/actions';

export type UploadMedia = (
  media: File,
  onProgress?: ProgressCallback,
  storagePath?: string,
  fileName?: string,
  loading?: boolean
) => Promise<APIResult<string | null>>;
export const uploadMedia = (
  media: File,
  onProgress?: ProgressCallback,
  storagePath = 'media/',
  fileName = null,
  loading = true
): APIThunkResult<string | null> => {
  return apiRequest<string | null>(async dispatch => {
    const ext = media.name
      .split('.')
      .pop()
      .toLowerCase();

    const uploadTask = firebase
      .storage()
      .ref()
      .child(`${storagePath}/${fileName || uuid()}.${ext}`)
      .put(media);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, ({ bytesTransferred, totalBytes }) => {
      const progress = (bytesTransferred / totalBytes) * 100;
      if (onProgress) onProgress(progress, bytesTransferred, totalBytes);
      dispatch(setLoadingPercent(progress));
    });

    try {
      if (loading) dispatch(setLoadingSpinner(true));
      const finalSnapshot = await uploadTask;
      dispatch(setLoadingSpinner(false));
      dispatch(setLoadingPercent(0));
      return finalSnapshot.ref.getDownloadURL();
    } catch (e) {
      dispatch(setLoadingSpinner(false));
      dispatch(setLoadingPercent(0));
      throw e;
    }
  });
};

export type DeleteMedia = (url: string) => Promise<APIResult>;
export const deleteMedia = (url: string): APIThunkResult => {
  return apiRequest(async () => {
    await firebase
      .storage()
      .refFromURL(url)
      .delete();
    return null;
  });
};
