import React, { FC, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';

import PaginatedTable from '../../../../components/UI/PaginatedTable';
import BackButton from '../../../../components/UI/Button/BackButton';
import {
  loadPagedSDAList,
  approveSDA,
  rejectSDA,
  getSDA
} from '../../../../lib/Api/SuspectDailyActivity';
import {
  setSDA as setSDAAction,
  setSDAFilter
} from '../../../../lib/Store/contexts/suspectDailyActivity/actions';
import { initialState as SDAInitialState } from '../../../../lib/Store/contexts/suspectDailyActivity/reducers';

import {
  OrderDirection,
  Permission,
  TableHeaderItem,
  SuspectDailyActivity,
  PaginationFilter,
  SDAFilter
} from '../../../../lib/Types';
import { ApplicationState } from '../../../../lib/Store';
import { PageHeader } from '../../../../pages/Events/components/CreateEvent/Styles';

export interface SDASingleViewProps {
  SDA: SuspectDailyActivity;
  permissions: Permission[];
  filter: SDAFilter;
  loading: boolean;
  setFilter: (filter: SDAFilter) => void;
  loadSDAs: (filter: SDAFilter) => void;
  setSDA: (suspectDailyActivityId: number) => any;
  approveSDA: (suspectDailyActivityId: number) => any;
  rejectSDA: (suspectDailyActivityId: number) => any;
}

const SDASingleView: FC<SDASingleViewProps> = ({ filter, setFilter, loading, loadSDAs, SDA }) => {
  const headers: TableHeaderItem[] = [
    {
      display: 'Name',
      mapProp: '',
      mapCombine: {
        firstField: 'firstName',
        secondField: 'lastName'
      },
      mapDeep: {
        flatField: 'user',
        deepField: 'firstName'
      }
    },
    { display: 'Date', mapProp: 'completionDate', millis: true },
    {
      display: 'Activity',
      mapProp: '',
      mapDeep: {
        depth: 2,
        flatField: 'eventActivityType',
        deepField: 'activityType',
        deepField2: 'code'
      }
    },
    { display: 'Steps/Minutes', mapProp: 'repetitions', rawNumber: true },
    {
      display: 'Conversion',
      mapProp: '',
      mapDeep: {
        depth: 2,
        flatField: 'eventActivityType',
        deepField: 'activityType',
        deepField2: 'conversion'
      }
    },
    {
      display: 'Status',
      mapProp: 'approvalStatus'
    }
  ];

  const setPage = (page: number) => setFilter({ ...filter, page });
  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    setFilter({ ...filter, orderColumn, orderDirection });
  const Dispatch = useDispatch();

  useEffect(() => {
    loadSDAs(filter);
  }, [filter]);

  useEffect(() => {
    loadSDAs(filter);
    return () => {
      Dispatch(setSDAAction({} as SuspectDailyActivity));
      Dispatch(setSDAFilter(SDAInitialState.filter));
    };
  }, []);

  return (
    <>
      {false ? (
        <></>
      ) : (
        <>
          <PageHeader>
            <BackButton onClick={() => Dispatch(setSDAAction({} as SuspectDailyActivity))} />
            {`${SDA.user.firstName} ${SDA.user.lastName} - ${moment(SDA.completionDate, 'x').format(
              'YYYY-MM-DD'
            )}`}
          </PageHeader>
          {loading ? (
            <p>Loading&hellip;</p>
          ) : (
            <PaginatedTable
              headers={headers}
              filter={filter}
              idProp={'activityId'}
              alignNumberedCellsCenter={false}
              data={{ results: [...SDA.suspectActivities], count: SDA.suspectActivities.length }}
              loading={loading}
              onSetPage={setPage}
              onSort={setSort}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ SDAState, loadingState, permissionsState }: ApplicationState) => ({
  SDA: SDAState.SDA,
  filter: SDAState.filter,
  loading: loadingState.apiCallsInProgress > 0,
  permissions: permissionsState.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setFilter: (filter: SDAFilter) => setSDAFilter(filter),
      loadSDAs: (filter: SDAFilter) => loadPagedSDAList(filter),
      setSDA: (suspectDailyActivityId: number) => getSDA(suspectDailyActivityId),
      approveSDA: (suspectDailyActivityId: number) => approveSDA(suspectDailyActivityId),
      rejectSDA: (suspectDailyActivityId: number) => rejectSDA(suspectDailyActivityId)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SDASingleView);
