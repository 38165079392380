import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  ColumnWrapper,
  FormColumn,
  ButtonWrapper,
  Group,
  Heading,
  InlineInputWrapper,
  CreateReportContainer,
  DatePickerStyled,
  InlineDateInput
} from '../Styles';
import {
  Button,
  DatePicker,
  SelectDropdown,
  Paper,
  AdminFormHeader,
  TimezoneSelector
} from '../../../components/UI';
import { FormikForm, ReportType } from '../../../lib/Types';
import { ApplicationState } from '../../../lib/Store';

// TODO: Delete schema

interface CreateReportProps extends FormikForm<any> {
  reportTypes: ReportType[];
  loading: boolean;
  onCancel: () => void;
  handleChangeCustom: (name: string, value: Date | number | string | null) => void;
}

const CreateReport: FC<CreateReportProps> = ({
  values,
  errors,
  reportTypes,
  handleSubmit,
  handleChangeCustom,
  loading
}) => {
  const { selectedCompanyIds, selectedEventId, eventOptions, companyOptions } = useSelector(
    ({ eventCompanyPageFilterState }: ApplicationState) => ({
      selectedEventId: eventCompanyPageFilterState.selectedEventId,
      selectedCompanyIds: eventCompanyPageFilterState.selectedCompanyIds,
      companyOptions: eventCompanyPageFilterState.companyOptions,
      eventOptions: eventCompanyPageFilterState.eventOptions
    })
  );

  const selectedEvent = eventOptions?.length
    ? eventOptions?.find(event => event.eventId === selectedEventId)
    : null;

  const selectedCompanyName = useMemo(() => {
    if (!selectedCompanyIds?.length) return 'No Company Selected';
    if (selectedCompanyIds?.length > 1) return 'All Companies';

    const selectedCompany = companyOptions?.find(
      company => company.companyId === selectedCompanyIds[0]
    );
    return selectedCompany.name;
  }, [selectedCompanyIds]);

  return (
    <form onSubmit={handleSubmit}>
      <AdminFormHeader
        title={'Generate a Report'}
        onClick={null}
        backBtn={false}
        margin={'0 0 1.5rem 0'}
      />
      <Paper width={'80%'} margin={'0 0 22rem'} minWidth={'792px'}>
        <ColumnWrapper>
          <FormColumn>
            <CreateReportContainer>
              <Group>
                <Heading>Selected Event: {selectedEvent?.name || 'No Event Selected.'}</Heading>
                <Heading>Selected Company: {selectedCompanyName}</Heading>
              </Group>
              <Group>
                <Heading>Select your report type</Heading>
                <SelectDropdown
                  required
                  data={reportTypes}
                  value={values.reportTypeId}
                  error={errors.reportTypeId}
                  valueProp="reportTypeId"
                  displayProp="name"
                  placeholder="Select your report type"
                  onChange={value => handleChangeCustom('reportTypeId', value)}
                />
              </Group>
              <Group>
                <Heading>Set a date range for the report</Heading>
                <InlineInputWrapper>
                  <InlineDateInput>
                    <DatePicker
                      required
                      id="startDate"
                      date={values.startDate}
                      error={errors.startDate}
                      onChange={handleChangeCustom}
                      // transform={'scale(100)'}
                    />
                  </InlineDateInput>
                  <InlineDateInput>
                    <DatePickerStyled
                      required
                      id="endDate"
                      date={values.endDate}
                      error={errors.endDate}
                      onChange={handleChangeCustom}
                    />
                  </InlineDateInput>
                </InlineInputWrapper>
              </Group>
              <Group>
                <TimezoneSelector
                  title="Report Timezone"
                  value={values.timezone}
                  error={errors.timezone ? errors.timezone[0] : null}
                  handleChangeTimezone={handleChangeCustom}
                />
              </Group>
            </CreateReportContainer>
          </FormColumn>
        </ColumnWrapper>
        <ButtonWrapper>
          <Button
            type="submit"
            variant={'admin-primary'}
            width={'25%'}
            minWidth={'175px'}
            margin={'16rem 0 1rem auto'}
            disabled={loading}
          >
            Generate CSV file
          </Button>
        </ButtonWrapper>
      </Paper>
    </form>
  );
};

export default CreateReport;
