/* eslint-disable @typescript-eslint/camelcase */
import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import EmployeePageWrapper from '../../components/UI/EmployeePageWrapper';
import Registered from './components/Registered';
import ReviewTeam from './components/ReviewTeam';
import CreateTeam from './components/CreateTeam';
import { CreateTeamSchema } from './components/validators/CreateTeamSchema';
import Modal from '../../components/UI/Modal';

import {
  Company,
  CreateTeamResponse,
  Event,
  IgnoreHoldingStateCondition,
  Team,
  User
} from '../../lib/Types';
import { ApplicationState } from '../../lib/Store';
import { createTeam } from '../../lib/Api/Team';
import { isIosDevice, hasBeenSetInStorage } from '../../lib/Utils';
import { panel } from '../../assets/img';
import { StyledPageHd } from '../../Themes';
import { SurveyContainer } from '../../components/UI';
import { Pearson_preEventSurveyURL } from '../../CONSTANTS';

import PreSurvey from '../EmployeeDashboard/PreSurvey';

export interface TeamEmployeeDashboardProps extends RouteComponentProps {
  user: User;
  company: Company;
  team: Team;
  event: Event;
  createTeam: (data: CreateTeamSchema) => CreateTeamResponse;
}

const TeamEmployeeDashboard: FC<TeamEmployeeDashboardProps> = ({
  user,
  company,
  createTeam,
  team,
  event
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const [displayPopup, setDisplayPopup] = useState(
    isIosDevice() && !hasBeenSetInStorage('hasClickedIOSPopup')
  );

  const closePopup = () => {
    setDisplayPopup(false);
    window.localStorage.setItem('hasClickedIOSPopup', 'true');
  };

  
  // TODO: Fix inline styles
  const IOSModal = () => (
    <Modal
      style={{
        width: '80vw',
        left: '10vw',
        top: '5vh',
        padding: '0px 10px 0px 10px',
        display: 'flex',
        flexDirection: 'column'
      }}
      headerPadding="0px"
      titlePadding="0px"
      showFooter={false}
      onClose={closePopup}
    >
      <img src={panel} onClick={closePopup} style={{ height: '33vh' }} alt={''} />
    </Modal>
  );

  // Registered to a team else show landing page, else show create team page
  return !!team ? (
    <>
      <EmployeePageWrapper
        holdingPageTitle="Dashboard"
        ignoreHoldingState={IgnoreHoldingStateCondition.REG_OPEN}
      >
        <ReviewTeam companyLogo={company.profileImage} team={team} event={event} />
        {displayPopup && <IOSModal />}
        <PreSurvey />
      </EmployeePageWrapper>
    </>
  ) : (
    <EmployeePageWrapper
      holdingPageTitle="Dashboard"
      ignoreHoldingState={IgnoreHoldingStateCondition.REG_OPEN}
    >
      {currentStep === 0 ? (
        <>
          <Registered
            event={event}
            company={company}
            setCurrentStep={setCurrentStep}
            companyLogo={company.profileImage}
          />
          <PreSurvey />
        </>
      ) : (
        <CreateTeam
          user={user}
          company={company}
          event={event}
          createTeam={createTeam}
          setCurrentStep={setCurrentStep}
        />
      )}
      {displayPopup && <IOSModal />}
    </EmployeePageWrapper>
  );
};

const mapStateToProps = ({ userState, participantTeamState }: ApplicationState) => ({
  user: userState.userData,
  company: userState.userCompany,
  team: participantTeamState.team,
  event: userState.userEvent
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ createTeam }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamEmployeeDashboard as any)
) as any;
