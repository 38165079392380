import React, { FC, useState } from 'react';
import { PageWrapper } from '../../components/UI';

import ListRewards from './ListRewards';
import CreateRewardForm from './CreateRewardForm';
import ToolbarButton from '../../components/UI/Button/ToolbarButton';
import { plus } from '../../assets/icons/index';

const Rewards: FC = () => {
  const [create, setCreate] = useState<boolean>(false);

  const toolbar = (
    <ToolbarButton variant="primary" icon={plus} onClick={() => setCreate(true)}>
      Create Reward
    </ToolbarButton>
  );

  return (
    <PageWrapper formOpener={toolbar} event={false} company={false}>
      {create ? <CreateRewardForm onCancel={setCreate} /> : <ListRewards />}
    </PageWrapper>
  );
};

export default Rewards;
