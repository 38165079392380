import styled from 'styled-components/macro';
import { Button } from '../../components/UI';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.padding * 4}px;
`;

export const InputGroup = styled.div`
  padding: ${({ theme }) => theme.padding}px 0;
  width: 100%;
`;

export const InputGroupHeading = styled.h4`
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.padding * 1.5}px;
`;

export const Section = styled.div`
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const PhotoSection = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${({ margin = '24px 0' }) => margin};
`;

export const PhotoUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    // flex-direction: column;
  }
`;

export const LoaderContainer = styled.div`
  height: 10rem;
  width: 14.5rem;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PhotoPlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 14.5rem;
  margin-right: 2rem;
  border-radius: 10px;
  border: dashed 1px rgba(101, 140, 215, 0.5);
`;

export const PhotoPlaceholder = styled.img``;

export const UserPhoto = styled.img`
  height: auto;
  width: 14.5rem;
  margin-right: 2rem;
  border-radius: 10px;
  margin-right: ${({ theme }) => theme.padding * 2}px;
  text-align: center;
  border: 1px solid #d6d9e0;
`;

export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PhotoHeading = styled.h4`
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 1.4rem;
  line-height: 2.4rem;
`;

export const PhotoNote = styled.p`
  color: #366696;
  font-style: italic;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  opacity: 0.5;
`;

export const UploadSection = styled.div``;

export const UploadButton = styled(Button)`
  margin-top: ${({ theme }) => theme.padding}px;
  max-width: 12.5rem;
  height: 5rem;
  border: 1px #75dbff solid;
  color: #658cd7;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: unset;
`;

export const Spacer = styled.div`
  height: 10px;
  width: 10px;
`;
