import {
  loadPagedParticipants,
  loadPagedParticipantsWithoutTeam,
  loadParticipantsWithoutTeam
} from './Participants';
import { getUserRegistrationData } from './participant/Team';
import { apiFetch, apiRequest, objectToQueryString } from './Utils';
import { server } from '../../CONSTANTS';

import {
  APIThunkResult,
  CreateTeamResponse,
  Team,
  PagedResponse,
  TeamListView,
  Permission,
  TeamMembers,
  APIResult,
  GenerateTeamsResponse,
  TeamListFilter
} from '../Types';

import { CreateTeamSchema } from '../../pages/TeamEmployeeDashboard/components/validators/CreateTeamSchema';
import { ReplaceNewUserSchema } from '../../pages/Teams/validators/replaceNewUserSchema';
import { TeamSchema } from '../../pages/Teams/validators/teamSchema';
import { AddOwnTeamMateSchema } from '../validators/addOwnTeamMateSchema';
import { RemoveOwnTeamMateSchema } from '../validators/removeOwnTeamMateSchema';

import { setNotification } from '../Store/contexts/notification/actions';
import {
  setPagedTeamList,
  setTeam,
  setAddingTeamMember,
  setGeneratingTeam
} from '../Store/contexts/team/actions';
import { setParticipant } from '../Store/contexts/participants/actions';
import { initialState } from '../Store/contexts/participants/reducers';

export type CreateTeamResult = Promise<APIResult<CreateTeamResponse>>;
export function createTeam(data: CreateTeamSchema): APIThunkResult<CreateTeamResponse> {
  return apiRequest<CreateTeamResponse>(async dispatch => {
    try {
      const result = await apiFetch<CreateTeamResponse>({
        method: 'POST',
        url: `${server}/team/create`,
        body: data
      });
      // TODO: This fixes bug causing incorrect team page to be rendered after team creation, but does another request.
      //dispatch(setUserTeam(result.team));
      dispatch(getUserRegistrationData());
      dispatch(setNotification({ message: 'Created Team successfully' }));
      return result;
    } catch (err) {
      const { message = 'Unexpected error occurred.' } = err;
      dispatch(setNotification({ message, variant: 'danger', duration: 5 }));
    }
  });
}

export function createEmptyTeam(data: {
  companyId: number;
  eventId: number;
}): APIThunkResult<Team> {
  return apiRequest<Team>(async dispatch => {
    try {
      const result = await apiFetch<Team>({
        method: 'POST',
        url: `${server}/team/create-empty`,
        body: data
      });

      dispatch(setTeam(result));
      dispatch(setNotification({ message: 'Created Team successfully' }));
      return result;
    } catch (err) {
      const { message = 'Unexpected error occurred.' } = err;
      dispatch(setNotification({ message, variant: 'danger', duration: 5 }));
    }
  });
}

export function getTeam(teamId: number): APIThunkResult<Team> {
  return apiRequest<Team>(async dispatch => {
    const result = await apiFetch<Team>({
      method: 'GET',
      url: `${server}/team/${teamId}`
    });
    dispatch(setTeam(result));
    return result;
  });
}

export function getTeamMembers(teamId: number): APIThunkResult<TeamMembers> {
  return apiRequest<TeamMembers>(async () => {
    const result = await apiFetch<TeamMembers>({
      method: 'GET',
      url: `${server}/team/members/${teamId}`
    });
    return result;
  });
}

export function loadPagedTeamList(
  filter: TeamListFilter
): APIThunkResult<PagedResponse<TeamListView>> {
  return apiRequest<PagedResponse<TeamListView>>(async (dispatch, getState) => {
    const query = objectToQueryString(filter);
    let url = `${server}/team/paged/company/team-list?${query}`;
    const { permissionsState } = getState();

    if (permissionsState.permissions.includes(Permission.VIEW_ALL_TEAMS)) {
      url = `${server}/team/paged/team-list?${query}`;
    }

    const results = await apiFetch<PagedResponse<TeamListView>>({
      method: 'GET',
      url
    });
    dispatch(setPagedTeamList(results));

    return results;
  });
}

export function approveTeam(teamId: number): APIThunkResult<any> {
  return apiRequest<any>(async (dispatch, getState) => {
    const { teamsState } = getState();
    try {
      const result = await apiFetch<any>({
        method: 'PUT',
        url: `${server}/team/${teamId}/approve`
      });
      dispatch(loadPagedTeamList(teamsState.filter));
      dispatch(setTeam({} as Team));
      dispatch(setNotification({ message: 'Team Approved' }));

      return result;
    } catch (err) {
      const { message } = err;
      console.warn(err);
      dispatch(
        setNotification({
          message,
          variant: 'danger',
          duration: 5
        })
      );
    }
  });
}

export function resendInvitationEmail(email: string): APIThunkResult<void> {
  return apiRequest<void>(async (dispatch, getState) => {
    const { teamsState } = getState();
    const result = await apiFetch<void>({
      method: 'POST',
      url: `${server}/team/resendInvitation/${email}`
    });
    dispatch(setNotification({ message: 'Invitation email sent' }));
    dispatch(loadPagedTeamList(teamsState.filter));
    return result;
  });
}

export function resendInvitationEmailInternal(email: string): APIThunkResult<void> {
  return apiRequest<void>(async (dispatch, getState) => {
    const { teamsState } = getState();
    const result = await apiFetch<void>({
      method: 'POST',
      url: `${server}/team/resendInvitation/internal/${email}`
    });
    dispatch(setNotification({ message: 'Invitation email sent' }));
    dispatch(loadPagedTeamList(teamsState.filter));
    return result;
  });
}

export function editTeam(teamId: number, data: TeamSchema): APIThunkResult<Team> {
  return apiRequest<Team>(async (dispatch, getState) => {
    const { teamsState } = getState();
    const result = await apiFetch<Team>({
      method: 'PUT',
      url: `${server}/team/${teamId}`,
      body: data
    });
    dispatch(loadPagedTeamList(teamsState.filter));
    dispatch(setTeam({} as Team));
    dispatch(setNotification({ message: 'Team updated successfully' }));
    return result;
  });
}

export function addExistingToTeam(team: Team, userId: string): APIThunkResult<any> {
  return apiRequest<any>(async (dispatch, getState) => {
    const { participantsState, teamsState } = getState();
    const result = await apiFetch<any>({
      method: 'POST',
      url: `${server}/team/${team.teamId}/addParticipant/${userId}`
    });
    dispatch(getTeam(team.teamId));
    dispatch(setParticipant(initialState.participant));
    dispatch(setAddingTeamMember(false));
    dispatch(loadPagedParticipants(participantsState.filter, team.eventId));
    dispatch(loadPagedParticipantsWithoutTeam(participantsState.filter, team.eventId));
    dispatch(loadParticipantsWithoutTeam(team.eventId, teamsState.team?.companyId || null));
    dispatch(setNotification({ message: 'Added Existing Participant successfully' }));
    return result;
  });
}

export function addNewUserToTeam(team: Team, data: ReplaceNewUserSchema): APIThunkResult<any> {
  return apiRequest<any>(
    async (dispatch, getState) => {
      const { participantsState } = getState();
      const result = await apiFetch<any>({
        method: 'POST',
        url: `${server}/team/${team.teamId}/inviteParticipant`,
        body: data
      });
      dispatch(getTeam(team.teamId));
      dispatch(setParticipant(initialState.participant));
      dispatch(setAddingTeamMember(false));
      dispatch(loadPagedParticipants(participantsState.filter, team.eventId));
      dispatch(loadPagedParticipantsWithoutTeam(participantsState.filter, team.eventId));
      dispatch(loadParticipantsWithoutTeam(team.eventId));
      dispatch(setNotification({ message: 'Invited New User successfully' }));
      return result;
    },
    true,
    true
  );
}

export function generateTeams(
  companyId: number,
  eventId: number,
  companyDemographicId: string
): APIThunkResult<GenerateTeamsResponse> {
  return apiRequest<GenerateTeamsResponse>(async (dispatch, getState) => {
    const { participantsState, teamsState } = getState();
    const url = `${server}/events/${eventId}/generateTeams?companyId=${companyId}`;

    const result = await apiFetch<GenerateTeamsResponse>({
      method: 'POST',
      url,
      body: { companyDemographicId }
    });

    dispatch(loadPagedParticipants(participantsState.filter, eventId));
    dispatch(loadPagedParticipantsWithoutTeam(participantsState.filter, eventId));
    dispatch(loadParticipantsWithoutTeam(eventId));
    dispatch(setGeneratingTeam(false));
    dispatch(loadPagedTeamList(teamsState.filter));
    dispatch(setNotification({ message: `${result.teamsCreated} Teams Generated successfully` }));
    return result;
  });
}

export function removeTeamRegistration(team: Team, userId: string): APIThunkResult<any> {
  return apiRequest<any>(async (dispatch, getState) => {
    const { participantsState } = getState();
    const result = await apiFetch<any>({
      method: 'DELETE',
      url: `${server}/team/${team.teamId}/registrations/${userId}`
    });
    dispatch(getTeam(team.teamId));
    dispatch(loadPagedParticipants(participantsState.filter, team.eventId));
    dispatch(loadPagedParticipantsWithoutTeam(participantsState.filter, team.eventId));
    dispatch(loadParticipantsWithoutTeam(team.eventId));
    dispatch(setNotification({ message: `Team registration removed successfully` }));
    return result;
  });
}

export function addTeamMate(data: AddOwnTeamMateSchema): APIThunkResult<any> {
  return apiRequest<any>(async dispatch => {
    const result = await apiFetch<any>({
      method: 'POST',
      url: `${server}/team/me/addTeamMate`,
      body: data
    });
    dispatch(getUserRegistrationData());
    dispatch(setNotification({ message: 'Team updated successfully' }));
    return result;
  });
}

export function removeTeamMate(data: RemoveOwnTeamMateSchema): APIThunkResult<any> {
  return apiRequest<any>(async dispatch => {
    const path = data.joinedStatus ? 'removeTeamMate' : 'removePendingTeamMate';
    const result = await apiFetch<any>({
      method: 'DELETE',
      url: `${server}/team/me/${path}/${data.email}`
    });
    dispatch(getUserRegistrationData());
    dispatch(setNotification({ message: 'Team updated successfully' }));
    return result;
  });
}
