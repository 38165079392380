import styled from 'styled-components/macro';
import { TableText } from '../../../../../../components/UI/TeamTable/Styles';

//  Selected Participant View
export const TableRow = styled.div`
  display: table-row;
  background-color: #f7f7f7;
  height: auto;
  border-radius: 6rem;
  vertical-align: middle;
  padding-right: 1.5rem;
  width: max-content;
`;

export const TableCell = styled(TableText)`
  display: table-cell;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  min-width: fit-content;

  &:first-child {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    padding: 0 0 0 1.5rem;
  }
  &:nth-child(2) {
    padding: 0 0.5rem;
  }
`;
