import React, { FC, useRef, useEffect, useState } from 'react';
import loadImage from 'blueimp-load-image';

import { ImageContainer } from './Styles';

interface ImageViewerProps {
  src: string;
  alt: string;
}

const ImageViewer: FC<ImageViewerProps> = ({ src, alt }) => {
  const imageCanvas = useRef(null);
  const [portrait, setPortrait] = useState<boolean>(false);

  useEffect(() => {
    loadImage(
      src,
      img => {
        if (!imageCanvas?.current) return;
        imageCanvas?.current?.firstChild &&
          imageCanvas.current.removeChild(imageCanvas.current.firstChild);
        if (img instanceof HTMLImageElement) {
          setPortrait(img.width < img.height);
          img.setAttribute('alt', alt);
        }
        if (!(img instanceof Event)) {
          img.setAttribute('style', 'width: 100%; height: 100%; object-fit: contain;');
        }
        imageCanvas.current.appendChild(img);
      },
      {
        orientation: true
      }
    );
  }, [src, alt]);

  return <ImageContainer height={portrait ? '50vh' : 'auto'} ref={imageCanvas} />;
};

export default ImageViewer;
