import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { getCompanyEventPayments } from '../../lib/Api/companyAdmin/Company';
import { centsToDollars } from '../../lib/Utils';

import { PageWrapper, NoRecordsFound } from '../../components/UI';
import PaginatedTable from '../../components/UI/PaginatedTable';
import LoadingIndicator from '../../components/UI/LoadingIndicator';

import { eventGuy } from '../../assets/icons/index';

import { defaultCompanyEventPaymentsFilter } from '../../lib/Store/contexts/companyAdmin/company/types';
import { APIThunkDispatch, OrderDirection, TableHeaderItem } from '../../lib/Types';
import { ApplicationState } from '../../lib/Store';

export interface EventPaymentsProps {}

const dateTransformer = (datum: any) => <>{dayjs(datum.createdAt).format('DD/MM/YYYY')}</>;
const amountTransformer = (datum: any): React.ReactNode => <>{centsToDollars(datum.amount)} AUD</>;

const tableHeaderItems: TableHeaderItem[] = [
  { display: 'Date', mapProp: 'createdAt', render: dateTransformer },
  { display: 'Inv. No.', mapProp: 'eventPaymentId' },
  {
    display: 'Event',
    mapProp: '',
    mapDeep: { flatField: 'event', deepField: 'name' }
  },
  {
    display: 'Payment Type',
    mapProp: 'manualPayment',
    mapBool: { true: 'Invoiced', false: 'Credit Card' }
  },
  { display: 'Participants', mapProp: 'quantity' },
  {
    display: 'Amount Paid',
    mapProp: 'amount',
    render: amountTransformer
  }
];

const EventPayments: React.FC<EventPaymentsProps> = () => {
  const Dispatch: APIThunkDispatch = useDispatch();
  const { eventPayments, loading } = useSelector(
    ({ companyAdminCompanyState }: ApplicationState) => ({
      eventPayments: companyAdminCompanyState.eventPayments,
      loading: companyAdminCompanyState.eventPaymentsLoading
    })
  );

  const [filter, setFilter] = useState(defaultCompanyEventPaymentsFilter);

  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    setFilter({ ...filter, orderColumn, orderDirection });
  const setPage = (page: number) => setFilter({ ...filter, page });

  useEffect(() => {
    (async () => await Dispatch(getCompanyEventPayments(filter)))();
  }, [filter]);

  return (
    <PageWrapper event={false} company={false}>
      {eventPayments.count ? (
        <PaginatedTable
          idProp="eventPaymentId"
          headers={tableHeaderItems}
          filter={filter}
          data={eventPayments}
          loading={eventPayments.count < 1}
          alignNumberedCellsCenter={false}
          onSort={setSort}
          onSetPage={setPage}
        />
      ) : loading ? (
        <LoadingIndicator />
      ) : (
        <NoRecordsFound icon={eventGuy} message="There are currently no payment records." />
      )}
    </PageWrapper>
  );
};

export default EventPayments;
