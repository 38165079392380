import React, { FC } from 'react';
import { TabsContainer } from './Styles';
import Tab from './components/Tab';

interface TabsProps {
  tabs: Array<string>;
  activeTab: string;
  setActiveTab?: any;
  standAlone?: boolean;
}

const Tabs: FC<TabsProps> = ({ tabs, activeTab, setActiveTab, standAlone = false }) => {
  if (!activeTab || !tabs) return <></>;

  return (
    <TabsContainer>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          title={tab}
          activeTab={activeTab}
          setActiveTab={() => setActiveTab(tab)}
          standAlone={standAlone}
        />
      ))}
    </TabsContainer>
  );
};

export default Tabs;
