import styled from 'styled-components/macro';
import theme from '../../../../theme';
import { DarkBlueHeaderL } from '../../../../Themes';

export const PageHeader = styled(DarkBlueHeaderL)`
  margin-bottom: 1.5rem;

  @media (max-width: ${theme.device.tablet}px) {
    margin-top: 1.5rem;
  }
`;
