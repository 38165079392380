import { APIThunkResult, ActivityType } from '../Types';
import { apiRequest, apiFetch } from './Utils';
import { server } from '../../CONSTANTS';
import { setActivityTypes } from '../Store/contexts/activityType/actions';

export function loadActivityTypes(): APIThunkResult<ActivityType[]> {
  return apiRequest<ActivityType[]>(async dispatch => {
    const results = await apiFetch<ActivityType[]>({
      method: 'GET',
      url: `${server}/activity-types/options`
    });
    dispatch(setActivityTypes(results));
    return results;
  });
}
