import styled from 'styled-components/macro';
import theme from '../../../theme';

interface UserInfoProps {
  mobileNav?: boolean;
  isAdmin?: boolean;
  color?: string;
}

export const P = styled.div<UserInfoProps>`
  color: ${props => (props.color ? props.color : theme.primary.main)};
  line-height: calc(${({ theme }) => theme.fontScale.sm} * 1.5);
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-right: ${({ theme }) => theme.padding * 2}px;
  margin-top: auto;
  text-align: left;
  font-size: 1.3rem;
  margin-bottom: ${props => (props.mobileNav ? '1.5rem' : 'auto')};
`;

export const Span = styled.span<UserInfoProps>`
  color: ${props => (props.color ? props.color : theme.primary.dark)};
`;

export const StatsHeaderBar = styled.div<UserInfoProps>`
  width: 100%;
  display: ${props => (props.isAdmin ? 'none' : 'flex')};
  flex-direction: ${props => (props.mobileNav ? 'column' : 'flex')};
  justify-content: space-between;
  text-transform: capitalize;
  margin-left: ${props => (props.mobileNav ? '2.4rem' : '0')};
  margin-top: ${props => (props.mobileNav ? '1rem' : '0')};
`;

export const OrgImage = styled.img`
  height: 50px;
  max-width: 150px;
  object-fit: contain;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: none;
  }
`;
