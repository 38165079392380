import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SyncServiceCode, FitnessDevice } from '../../../lib/Types';
import { ApplicationState } from '../../../lib/Store';
import { reSyncDevice } from '../../../lib/Api/SyncService';

import { fitbitBtn, googleFitLogoBadge, garminLogoBadge } from '../../../assets/icons';
import {
  Wrapper,
  SyncButton,
  SyncContainer,
  SyncDeviceScrollView,
  SyncDeviceLabelContainer,
  SyncDeviceButton,
  Column,
  DeviceImage,
  SyncLabel
} from './Styles';

interface SyncDeviceBasicProps extends RouteComponentProps {
  syncActivities?: Function;
  usersDevices: FitnessDevice[];
  reSyncDevice: (deviceName: SyncServiceCode) => any;
  setConnecting: (connecting: boolean) => void;
}

const SyncDeviceBasic: FC<SyncDeviceBasicProps> = ({
  usersDevices,
  reSyncDevice,
  setConnecting
}) => {
  return (
    <Wrapper>
      {usersDevices.length > 0 ? (
        <SyncContainer>
          <Column>
            <SyncDeviceLabelContainer>
              <SyncLabel>Sync with your</SyncLabel>
              <SyncDeviceScrollView>
                {usersDevices.map(userDevice => {
                  let imageAlt: string, imageSrc: string;
                  switch (userDevice.service.code) {
                    case SyncServiceCode.fitbit:
                      imageAlt = 'fitbit';
                      imageSrc = fitbitBtn;
                      break;
                    case SyncServiceCode.google_fit:
                      imageAlt = 'google fit';
                      imageSrc = googleFitLogoBadge;
                      break;
                    case SyncServiceCode.garmin:
                      imageAlt = 'garmin';
                      imageSrc = garminLogoBadge;
                      break;
                  }

                  if (userDevice.service.code === SyncServiceCode.apple_health) return <></>;
                  return (
                    <SyncDeviceButton
                      key={userDevice.deviceId}
                      onClick={(): void => reSyncDevice(SyncServiceCode[userDevice.service.code])}
                    >
                      <DeviceImage alt={imageAlt} src={imageSrc} />
                    </SyncDeviceButton>
                  );
                })}
              </SyncDeviceScrollView>
            </SyncDeviceLabelContainer>
            <SyncButton onClick={() => setConnecting(true)}>+ Sync a new device </SyncButton>
          </Column>
        </SyncContainer>
      ) : (
        <SyncButton onClick={() => setConnecting(true)}>+ Sync a new device </SyncButton>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.loadingState.apiCallsInProgress > 0,
  usersDevices: state.userState.userDevices
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      reSyncDevice: (deviceName: SyncServiceCode) => reSyncDevice(deviceName)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SyncDeviceBasic));
