import styled from 'styled-components/macro';

/* ----------------------- Modal Styles ----------------------- */

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.padding * 4}px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormColumn = styled.div`
  flex: 1;
  &:nth-child(1) {
    padding-right: ${({ theme }) => theme.padding * 8}px;
  }
  &:nth-child(2) {
    padding-right: ${({ theme }) => theme.padding * 3}px;
  }
`;

export const Container = styled.div`
  width: 50%;
  overflow-y: scroll;
  height: auto;
  display: flex;
  ::-webkit-scrollbar {
    display: none;
  }
`;
