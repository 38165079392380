import styled from 'styled-components/macro';

import { getBoxShadow } from '../../../../../lib/Utils';

import { ContainerProps } from '../../../../../lib/Types';

interface ImageProps {
  image: string;
}

export const Container = styled.div`
  align-items: left;
  display: flex;
  flex-direction: row;
  transform: translateX(-50%);
`;

export const StyledTooltip = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.backgrounds.white};
  box-shadow: ${({ elevation = 1, theme }) => getBoxShadow(elevation, theme)};

  padding: 0.5rem 1rem;
  text-align: center;
  min-width: 60px;
`;

export const Icon = styled.img`
  width: 28px;
  height: 28px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`;

export const TooltipText = styled.span`
  color: '#000000';
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 12px;
`;

export const TooltipSubtext = styled.span`
  color: #aeaeae;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 12px;
`;

export const Image = styled.div<ImageProps>`
  height: 29px;
  width: 29px;
  border-radius: 50%;
  background: center / cover ${props => `url('${props.image}') no-repeat`};
`;
