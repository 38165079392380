import styled from 'styled-components/macro';

export const Description = styled.p`
  width: 455px;
  height: 48px;
  color: #366696;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.13125px;
`;

export const FormContainer = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;

  @media screen and (min-width: ${({ theme }) => theme.device.tablet}px) {
    padding-right: ${({ theme }) => theme.padding * 4}px;
  }
`;
