import React, { FC, useState, useEffect } from 'react';

import { DEFAULT_PIC } from '../../../../CONSTANTS';

import { BarGraphContainer, BarWrapper, Bar, BarImage, BarName, BarTotal } from './Styles';

import { TeamMembersPoints } from '../../../../lib/Types';

interface BarGraphProps {
  data: TeamMembersPoints[];
  pointType: 'CONNECTION' | 'MOVEMENT';
}

interface Bar {
  value: number;
  fullName: string;
  profilePictureURL: string;
}

const barColors = ['#FFF8DD', '#FFECF1', '#E0F7FF', '#F0FFDB', '#F1F1FF'];

const BarGraph: FC<BarGraphProps> = ({ data, pointType }) => {
  const [maxValue, setMaxValue] = useState(0);
  const [bars, setBars] = useState<Bar[]>([]);

  useEffect(() => {
    if (data) {
      const filteredData = data
        .sort((a, b) => {
          if (pointType === 'CONNECTION') return a.connectionPoints < b.connectionPoints ? 1 : 0;
          if (pointType === 'MOVEMENT') return a.movementPoints < b.movementPoints ? 1 : 0;
        })
        .filter(
          row =>
            (pointType === 'CONNECTION' && row.connectionPoints > 0) ||
            (pointType === 'MOVEMENT' && row.movementPoints > 0)
        );

      if (filteredData.length) {
        setBars(
          filteredData.map(row => {
            const nameValue = `${row.user.firstName.charAt(0).toUpperCase() +
              row.user.firstName.slice(1)} ${row.user.lastName.charAt(0).toUpperCase()}`;
            return {
              value: pointType === 'CONNECTION' ? row.connectionPoints : row.movementPoints,
              fullName: nameValue,
              profilePictureURL: row.user.profileImage
            };
          })
        );
      } else setBars([]);
    }
  }, [data]);

  useEffect(() => {
    bars.length && setMaxValue(bars.map(r => r.value).reduce((a, b) => (a > b ? a : b)));
  }, [bars]);

  return (
    <BarGraphContainer>
      {bars.length ? (
        bars.map((bar, index) => {
          const width = (bar.value / maxValue) * 100;
          return (
            <BarWrapper key={index}>
              <Bar
                width={`${width}%`}
                color={barColors[index % barColors.length]} // cycle through colors
              >
                <BarImage image={bar.profilePictureURL || DEFAULT_PIC} />
                <BarName>{bar.fullName}</BarName>
              </Bar>
              <BarTotal>{bar.value}</BarTotal>
            </BarWrapper>
          );
        })
      ) : (
        <BarName>{`Your team doesn't have any ${
          pointType === 'CONNECTION' ? 'connection' : 'movement'
        } points yet!`}</BarName>
      )}
    </BarGraphContainer>
  );
};

export default BarGraph;
