import moment from 'moment';

import Backend from '../Backend';
import { apiRequest, apiFetch } from './Utils';
import { uploadMedia } from './Common';
import { loadMorePosts } from './Posts';
import { getCurrentEvent as getCurrentCompanyEvent } from './companyAdmin/Event';
import { getMyCompanyDemographics, getMyUserDemographics } from './participant/Event';
import { isAfter } from '../TimeUtils';
import { AuthUser } from '../Backend/Authentication';
import { server, DEFAULT_PIC } from '../../CONSTANTS';

import {
  APIThunkResult,
  APIResult,
  User,
  Activity,
  FitnessDevice,
  CompanyDemographic,
  AcceptTermsAndConditionsResponse,
  ActivityTypesResponse,
  ProgressCallback,
  ApiStatus,
  RelativePercentTopFiveActivity,
  ConnectionPointsByPostType,
  TeamMembersPoints,
  RoleCode,
  CompanyEventStatus
} from '../Types';

import { RegisterCredentials } from '../../pages/UserOnboarding/validators/registerCredentialsSchema';
import { getTermsAndConditionsNotAcceptedError } from '../Errors/TermsAndConditionsError';
import { setAuthLoading } from '../Store/contexts/loading/actions';
import { setNotification } from '../Store/contexts/notification/actions';
import {
  setUser,
  updateUser,
  removeUser,
  setUserCompany,
  setUserEvent,
  setUserEventActivityTypes,
  setUserEventActivities,
  setUserCompanyEventId,
  setUserDevices
} from '../Store/contexts/user/actions';
import { setTermsAccepted } from '../Store/contexts/termsAndConditions/actions';
import { updateCompany } from '../Store/contexts/company/actions';
import {
  setTeamMembersPoints,
  setTopActivities,
  setTopConnection
} from '../Store/contexts/participant/dashboard/actions';
import { getUserRegistrationData } from './participant/Team';

export function getEventActivityTypes(eventId: number): APIThunkResult<ActivityTypesResponse> {
  return apiRequest<ActivityTypesResponse>(async dispatch => {
    const result = await apiFetch<ActivityTypesResponse>({
      method: 'GET',
      url: `${server}/activity-types/${eventId}`
    });
    const { allTypes, favoriteTypes } = result;
    dispatch(setUserEventActivityTypes(allTypes, favoriteTypes));
    return result;
  });
}

export function getEventActivities(eventId: number): APIThunkResult<Array<Activity>> {
  return apiRequest<Array<Activity>>(async dispatch => {
    const activities = await apiFetch<Array<Activity>>({
      method: 'GET',
      url: `${server}/activities/${eventId}`
    });
    dispatch(setUserEventActivities(activities));
    return activities;
  });
}

export function getUserEvent(): APIThunkResult<CompanyEventStatus> {
  return apiRequest<CompanyEventStatus>(async (dispatch, getState) => {
    const { userState } = getState();
    let userEvent: CompanyEventStatus;

    if (!userState.userEvent.eventId) {
      const userEventRequest = await apiFetch<CompanyEventStatus>({
        method: 'GET',
        url: `${server}/users/me/event`
      });
      // May not be part of an event
      if (userEventRequest) {
        dispatch(setUserEvent(userEventRequest));
        const { eventId, startDate } = userEventRequest;
        if (eventId && isAfter(moment().valueOf(), startDate)) {
          dispatch(getEventActivityTypes(eventId));
          dispatch(getEventActivities(eventId));
        }
        userEvent = userEventRequest;
      }
    } else {
      userEvent = userState.userEvent;
    }
    return userEvent;
  });
}

export function getUserDevices(): APIThunkResult<FitnessDevice[]> {
  return apiRequest<FitnessDevice[]>(async dispatch => {
    const devices = await apiFetch<FitnessDevice[]>({
      method: 'GET',
      url: `${server}/users/me/fitness-devices/`
    });

    dispatch(setUserDevices(devices));
    return devices;
  });
}

export function getUserCompanyEventId(companyId: number): APIThunkResult<number> {
  return apiRequest<number>(async dispatch => {
    const results = await apiFetch<number>({
      method: 'GET',
      url: `${server}/users/me/${companyId}/event`
    });
    dispatch(setUserCompanyEventId(results));
    return results;
  });
}

export type UserResult = Promise<APIResult<User>>;
export function getUser(code: RoleCode): APIThunkResult<User> {
  return apiRequest<User>(async dispatch => {
    dispatch(setAuthLoading());
    const authUser = Backend.getAuthUser();

    // ALL USERS
    // Fetch db user, company, and terms accepted
    const { company, ...existingUser } = await apiFetch<User>({
      method: 'GET',
      url: `${server}/users/${authUser?.uid}`
    });
    dispatch(setUser(existingUser));
    if (company) dispatch(setUserCompany(company)); // MA have no company

    // PARTICIPANT USERS
    if (code === RoleCode.Employee) {
      const { data: userEvent } = await dispatch(getUserEvent());

      if (userEvent) {
        dispatch(getUserRegistrationData());
        dispatch(getMyCompanyDemographics());
        dispatch(getMyUserDemographics());

        if (isAfter(moment().valueOf(), userEvent.startDate)) {
          dispatch(getUserDevices());
        }
      }
    }

    // CA USERS
    if (code === RoleCode.CompanyAdmin) {
      await dispatch(getCurrentCompanyEvent(existingUser.companyId));
      if (existingUser.termsAccepted) dispatch(setTermsAccepted());
    }

    return existingUser;
  });
}

export type SignInResult = Promise<APIResult<AuthUser>>;
export function signIn(
  email: string,
  password: string,
  rememberMe: boolean
): APIThunkResult<AuthUser> {
  return apiRequest<AuthUser>(async dispatch => {
    dispatch(setAuthLoading());
    const authUser = await Backend.signIn(email, password, rememberMe);
    return authUser;
  });
}

export type RegisterResult = Promise<APIResult<AuthUser>>;
export type SignUp = (values: RegisterCredentials, token?: string) => RegisterResult;
export function signUp(
  { email, password, rememberMe, firstName, lastName, passCode }: RegisterCredentials,
  token?: string
): APIThunkResult<AuthUser> {
  return apiRequest<AuthUser>(async dispatch => {
    dispatch(setAuthLoading());
    const loweredEmail = email.toLowerCase();

    const userData = {
      firstName,
      lastName,
      email: loweredEmail,
      password,
      passCode: !token ? passCode : null
    };
    const url = token ? `signupWithToken?token=${token}` : 'signup';
    const newUser = await apiFetch<User>({
      method: 'POST',
      url: `${server}/auth/${url}`,
      body: userData
    });

    const authUser = await Backend.signIn(loweredEmail, password, rememberMe);
    dispatch(setUser(newUser));
    dispatch(getUserRegistrationData());
    return authUser;
  });
}

export type UploadProfilePic = (
  image: File | null,
  userId: string,
  onProgress?: ProgressCallback
) => Promise<APIResult<{ profileImage: string }>>;
export const uploadProfilePicture = (
  image: File,
  userId: string,
  onProgress?: ProgressCallback
): APIThunkResult<{ profileImage: string }> => {
  return apiRequest<{ profileImage: string }>(async (dispatch, getState) => {
    const { posts } = getState().postsState;
    let profileImage = DEFAULT_PIC;
    if (image) {
      const { status, error, data } = await dispatch(
        uploadMedia(image, onProgress, 'profilePictures', null, false)
      );
      if (status === ApiStatus.FAILED) throw error;
      else profileImage = data;
    }

    await apiFetch<{ userId: string; profileImage: string }>({
      method: 'PUT',
      url: `${server}/users/${userId}/updateImage`,
      body: { profileImage }
    });

    if (posts.length > 0) dispatch(loadMorePosts(true));
    dispatch(updateUser({ profileImage }));
    dispatch(getUserRegistrationData());
    return { profileImage };
  });
};

export type UploadCompanyProfilePic = (
  image: File | null,
  onProgress?: ProgressCallback
) => Promise<APIResult<{ profileImage: string }>>;
export const uploadCompanyProfilePicture = (
  image: File,
  onProgress?: ProgressCallback
): APIThunkResult<{ profileImage: string }> => {
  return apiRequest<{ profileImage: string }>(async dispatch => {
    let profileImage = DEFAULT_PIC;
    if (image) {
      const { status, error, data } = await dispatch(
        uploadMedia(image, onProgress, 'companyLogos', null, false)
      );
      if (status === ApiStatus.FAILED) throw error;
      else profileImage = data;
    }

    dispatch(updateCompany({ profileImage }));
    return { profileImage };
  });
};

interface ProfileUpdateInput {
  firstName: string;
  lastName: string;
  email: string;
  profileImage: string;
  userDemographics: CompanyDemographic;
  teamName: string;
}

export type UpdateUserProfile = (data: ProfileUpdateInput) => Promise<APIResult<User>>;
export function updateUserProfile(data: ProfileUpdateInput): APIThunkResult<User> {
  return apiRequest<User>(async dispatch => {
    const result = await apiFetch<User>({
      method: 'PUT',
      url: `${server}/users/me/profile`,
      body: data
    });
    dispatch(setUser(result));
    dispatch(getMyUserDemographics());
    dispatch(setNotification({ message: 'Successfully updated profile' }));
    return result;
  });
}

export function signOut(passwordUpdated = false): APIThunkResult<void> {
  return apiRequest<void>(async dispatch => {
    await Backend.signOut();
    dispatch(removeUser());
    if (passwordUpdated) {
      dispatch(
        setNotification({
          message: 'Password updated, you can now login with your new password.',
          variant: 'success'
        })
      );
    } else {
      dispatch(setNotification({ message: 'Logged out successfully' }));
    }
  });
}

export function acceptTermsAndConditions(
  accepted: boolean
): APIThunkResult<AcceptTermsAndConditionsResponse> {
  return apiRequest<AcceptTermsAndConditionsResponse>(async dispatch => {
    if (!accepted) throw getTermsAndConditionsNotAcceptedError();
    const result = await apiFetch<AcceptTermsAndConditionsResponse>({
      method: 'POST',
      url: `${server}/users/me/acceptTerms`
    });
    dispatch(setTermsAccepted());
    return result;
  });
}

export interface DashboardStatsData {
  topActivities: RelativePercentTopFiveActivity[];
  topConnection: ConnectionPointsByPostType[];
  teamMembersPoints: TeamMembersPoints[];
}
export type GetDashboardStatsData = () => Promise<APIResult<DashboardStatsData>>;
export function getDashboardStatsData(): APIThunkResult<DashboardStatsData> {
  return apiRequest<DashboardStatsData>(async dispatch => {
    const data = await apiFetch<DashboardStatsData>({
      method: 'GET',
      url: `${server}/users/me/stats`
    });
    dispatch(setTopActivities(data.topActivities));
    dispatch(setTeamMembersPoints(data.teamMembersPoints));
    dispatch(setTopConnection(data.topConnection));
    return data;
  });
}
