import { EventPayment, PagedResponse, PaginationFilter } from '../../../../Types';

export const SET_COMPANY_EVENT_PAYMENTS = 'SET_COMPANY_EVENT_PAYMENTS';
export const SET_COMPANY_EVENT_PAYMENTS_LOADING = 'SET_COMPANY_EVENT_PAYMENTS_LOADING';

export interface CompanyState {
  eventPayments: PagedResponse<EventPayment>;
  eventPaymentsLoading: boolean;
}

export const defaultCompanyAdminFilter: PaginationFilter = {
  page: 1,
  rowsPerPage: 10,
  orderColumn: 'firstName',
  orderDirection: 'ASC',
  keyword: ''
};

export const defaultCompanyEventPaymentsFilter: PaginationFilter = {
  page: 1,
  rowsPerPage: 30,
  orderColumn: 'eventPaymentId',
  orderDirection: 'ASC',
  keyword: ''
};

export interface SetCompanyEventPaymentsAction {
  type: typeof SET_COMPANY_EVENT_PAYMENTS;
  companyEventPayments: PagedResponse<EventPayment>;
}

export interface SetCompanyEventPaymentsLoadingAction {
  type: typeof SET_COMPANY_EVENT_PAYMENTS_LOADING;
  loading: boolean;
}

export type CompanyActionTypes =
  | SetCompanyEventPaymentsAction
  | SetCompanyEventPaymentsLoadingAction;
