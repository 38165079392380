import styled from 'styled-components/macro';
import { backArrow } from '../../../../assets/img';

export const StyledButton = styled.button`
  background: center / contain no-repeat url(${backArrow});
  height: 1.6rem;
  width: 5rem;
  border: none;
  margin: 0.5rem 0 0.5rem 0;
  transition: all 0.6s cubic-bezier(0.4, 0.01, 0, 1.38);
  cursor: pointer;
  position: relative;
  top: -3px;

  :hover {
    transform: scale(1.125);
  }

  :active {
    transform: scale(1.125);
  }
`;
