import React, { FC } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import RemoveTeamMateModal from './RemoveTeamMateModal';
import validationSchema, {
  RemoveOwnTeamMateSchema
} from '../../../../../lib/validators/removeOwnTeamMateSchema';
import { ApplicationState } from '../../../../../lib/Store';
import { removeTeamMate } from '../../../../../lib/Api/Team';
import { ParticipantListView } from '../../../../../lib/Types';

interface RemoveTeamMateProps {
  loading: boolean;
  member: ParticipantListView;
  removeOwnTeamMate: (data: RemoveOwnTeamMateSchema) => any;
  cancel: () => void;
}

interface RemoveTeamMateState {
  email: string;
  joinedStatus: boolean;
}

const formikEnhancer = withFormik<RemoveTeamMateProps, RemoveTeamMateState>({
  validationSchema,
  validateOnChange: false,
  handleSubmit: async (data: RemoveOwnTeamMateSchema, { props, setErrors }): Promise<void> => {
    const { error } = await props.removeOwnTeamMate(data);
    // TODO: Provide user feedback if there is request failures
    if (error) setErrors({ [error.context]: error.message });
    else props.cancel();
  }
});

const RemoveTeamMate: FC<RemoveTeamMateProps & FormikProps<RemoveTeamMateState>> = ({
  cancel,
  setFieldValue,
  loading,
  member,
  ...props
}) => {
  return (
    <RemoveTeamMateModal
      {...props}
      loading={loading}
      cancel={cancel}
      member={member}
      setFieldValue={setFieldValue}
    />
  );
};

const mapStateToProps = ({ loadingState }: ApplicationState) => ({
  loading: loadingState.apiCallsInProgress > 0
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      removeOwnTeamMate: (data: RemoveOwnTeamMateSchema) => removeTeamMate(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(formikEnhancer(RemoveTeamMate));
