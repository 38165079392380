import * as yup from 'yup';

const createTeamMemberSchema = yup.object({
  teamCaptain: yup.boolean(),
  userId: yup.string(),
  joinedStatus: yup.boolean(),
  firstName: yup
    .string()
    .max(15, 'Must be 15 characters or less')
    .required(),
  lastName: yup
    .string()
    .max(15, 'Must be 20 characters or less')
    .required(),
  email: yup.string().required()
});

export type CreateTeamMemberSchema = yup.InferType<typeof createTeamMemberSchema>;

export default createTeamMemberSchema;
