import {
  PagedResponse,
  PaginationFilter,
  LeaderBoardTab,
  TeamRankView,
  ParticipantRankView
} from '../../../../Types';

// LeaderBoard team state
export const SET_PAGED_TEAM_RANKS = 'SET_PAGED_TEAM_RANKS';
export const SET_MY_TEAM_RANK = 'SET_MY_TEAM_RANK';
export const SET_PAGED_TEAM_RANK_FILTER = 'SET_PAGED_TEAM_RANK_FILTER';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_FILTER_BY_COMPANY = 'SET_FILTER_BY_COMPANY';

// LeaderBoard individual state
export const SET_INDIVIDUAL_RANKINGS = 'SET_INDIVIDUAL_RANKINGS';
export const SET_OWN_RANKING = 'SET_OWN_RANKING';
export const SET_INDIVIDUAL_FILTER = 'SET_INDIVIDUAL_FILTER';

// WaterCooler LeaderBoard individual state
export const SET_WATER_COOLER_LEADER_BOARD_FILTER = 'SET_RANKING_FILTER';

export interface LeaderBoardState {
  // LeaderBoard team state
  pagedTeamRanks: PagedResponse<TeamRankView>;
  myTeamRank: TeamRankView;
  pagedTeamRankFilter: PaginationFilter;
  activeTab: LeaderBoardTab;
  filterByCompany: boolean;

  // LeaderBoard individual state
  individualRankings: PagedResponse<ParticipantRankView>;
  ownRanking: ParticipantRankView;
  individualFilter: PaginationFilter;

  // WaterCooler LeaderBoard individual state
  waterCoolerLeaderBoardFilter: PaginationFilter;
}

export interface SetPagedTeamRanksAction {
  type: typeof SET_PAGED_TEAM_RANKS;
  pagedTeamRanks: PagedResponse<TeamRankView>;
}

export interface SetMyTeamRankAction {
  type: typeof SET_MY_TEAM_RANK;
  myTeamRank: TeamRankView;
}

export interface SetPagedTeamRankFilterAction {
  type: typeof SET_PAGED_TEAM_RANK_FILTER;
  pagedTeamRankFilter: PaginationFilter;
}

export interface SetActiveTabAction {
  type: typeof SET_ACTIVE_TAB;
  activeTab: LeaderBoardTab;
}

export interface SetFilterByCompanyAction {
  type: typeof SET_FILTER_BY_COMPANY;
  filterByCompany: boolean;
}

export interface SetIndividualRankingsAction {
  type: typeof SET_INDIVIDUAL_RANKINGS;
  individualRankings: PagedResponse<ParticipantRankView>;
}

export interface SetOwnRankingAction {
  type: typeof SET_OWN_RANKING;
  ownRanking: ParticipantRankView;
}

export interface SetIndividualFilterAction {
  type: typeof SET_INDIVIDUAL_FILTER;
  individualFilter: PaginationFilter;
}

export interface SetWaterCoolerLeaderBoardFilterAction {
  type: typeof SET_WATER_COOLER_LEADER_BOARD_FILTER;
  waterCoolerLeaderBoardFilter: PaginationFilter;
}

export type LeaderBoardActionTypes =
  | SetPagedTeamRankFilterAction
  | SetMyTeamRankAction
  | SetPagedTeamRanksAction
  | SetActiveTabAction
  | SetFilterByCompanyAction
  | SetIndividualRankingsAction
  | SetOwnRankingAction
  | SetIndividualFilterAction
  | SetWaterCoolerLeaderBoardFilterAction;
