import { useHistory } from 'react-router';
import React, { FC, useState } from 'react';
import { Formik } from 'formik';

import {
  Input,
  PasswordInput,
  Checkbox,
  Modal,
  Tooltip,
  ErrorLabel,
  Button,
  FormControl
} from '../../../../components/UI';

import {
  Container,
  LoginButton,
  StyledIframe,
  IframeContainer,
  Header,
  SubHeader,
  CompanyPassCodeContainer,
  FormWrapper,
  InputGroup
} from './Styles';

import registerCredentials, {
  RegisterCredentials
} from '../../validators/registerCredentialsSchema';
import { TokenPayload, ErrorResponse } from '../../../../lib/Types';

export interface RegisterFormProps {
  token: TokenPayload;
  onSignUp: (values: RegisterCredentials) => void;
  signUpError: ErrorResponse;
  loading?: boolean;
}

const RegisterForm: FC<RegisterFormProps> = ({ token, onSignUp, signUpError, loading }) => {
  const [tosVisible, setTosVisible] = useState(false);
  const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);
  const [passCodeError, setPassCodeError] = useState<string>();

  const history = useHistory();

  return (
    <>
      <Container>
        <Header>Welcome, already have an account?</Header>
        <Button
          value={'Login'}
          variant="secondary"
          width={'40%'}
          margin="0 0 5rem 0"
          onClick={() => history.push('/Login')}
        >
          Login
        </Button>
        <Header>Create your account to get started.</Header>
        {!!signUpError && !signUpError.data && <ErrorLabel error={signUpError.message} />}
        <Formik
          initialValues={{
            email: (token && token.email) || '',
            password: '',
            firstName: (token && token.firstName) || '',
            lastName: (token && token.lastName) || '',
            passCode: '',
            rememberMe: false,
            agreeToS: false
          }}
          validationSchema={registerCredentials}
          validateOnChange={false}
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            if (passCodeError) return;
            setSubmitting(true);
            onSignUp(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            setErrors
          }) => (
            <FormWrapper>
              <InputGroup>
                <FormControl>
                  <Input
                    required
                    id="email"
                    name="email"
                    value={values.email}
                    label="Email Address"
                    error={
                      signUpError?.data?.field === 'email' ? signUpError.message : errors.email
                    }
                    onChange={handleChange}
                    onBlur={() => handleBlur('email')}
                  />
                </FormControl>
                <FormControl>
                  <PasswordInput
                    required
                    id="password"
                    name="password"
                    value={values.password}
                    label="Password"
                    error={
                      signUpError?.data?.field === 'password'
                        ? signUpError.message
                        : errors.password
                    }
                    onChange={handleChange}
                    onBlur={() => handleBlur('password')}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    required
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    label="First Name"
                    error={
                      signUpError?.data?.field === 'firstName'
                        ? signUpError.message
                        : errors.firstName
                    }
                    onChange={handleChange}
                    onBlur={() => handleBlur('firstName')}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    required
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    label="Last Name"
                    error={
                      signUpError?.data?.field === 'lastName'
                        ? signUpError.message
                        : errors.lastName
                    }
                    onChange={handleChange}
                    onBlur={() => handleBlur('lastName')}
                  />
                </FormControl>
              </InputGroup>
              {!token && (
                <InputGroup>
                  <CompanyPassCodeContainer>
                    <SubHeader>
                      Please enter your company pass code
                      <Tooltip
                        innerText="What's This?"
                        breakpoint={{
                          location: 'LEFT',
                          size: 1350
                        }}
                        tooltipText="You can find the pass code for your company either on your invite email or ask your company administrator if they haven't provided it to you. It should be a 4 digit code e.g. L772."
                      />
                    </SubHeader>
                    <FormControl>
                      <PasswordInput
                        required
                        id="passCode"
                        name="passCode"
                        value={values.passCode}
                        label="Company Passcode"
                        error={
                          passCodeError ||
                          (signUpError?.data?.field === 'passCode'
                            ? signUpError.message
                            : errors.passCode)
                        }
                        onChange={e => {
                          if (e.target) setPassCodeError('');
                          handleChange(e);
                        }}
                        onBlur={() => handleBlur('passCode')}
                      />
                    </FormControl>
                  </CompanyPassCodeContainer>
                </InputGroup>
              )}
              <InputGroup>
                <FormControl>
                  <Checkbox
                    name="rememberMe"
                    label="Remember me on this computer, so I don’t have to log in each time."
                    checked={values.rememberMe}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <Checkbox
                    name="agreeToS"
                    checked={values.agreeToS}
                    error={errors.agreeToS}
                    onChange={e => {
                      handleChange(e);
                      setErrors({ agreeToS: '' });
                    }}
                  >
                    <div>
                      Yes, I agree to Moving Mindz{' '}
                      <a href={'#tou'} onClick={() => setTosVisible(true)}>
                        terms of use
                      </a>{' '}
                      and{' '}
                      <a href={'#privacy'} onClick={() => setPrivacyPolicyVisible(true)}>
                        privacy policy
                      </a>
                      .
                    </div>
                  </Checkbox>
                </FormControl>
                <FormControl>
                  <LoginButton
                    variant="primary"
                    disabled={isSubmitting}
                    loading={isSubmitting || loading}
                    onClick={() => {
                      if (!token && !values.passCode) {
                        setPassCodeError('Pass code is a required field');
                      }
                      handleSubmit();
                    }}
                  >
                    Create My Account
                  </LoginButton>
                </FormControl>
              </InputGroup>
            </FormWrapper>
          )}
        </Formik>
      </Container>
      {tosVisible && (
        <Modal
          headerPadding={'0'}
          titlePadding={'0.3rem 0'}
          title={'Terms of Use'}
          padding={'0 4rem 2rem'}
          onClose={() => setTosVisible(false)}
        >
          <IframeContainer>
            <StyledIframe src={'https://movingmindz.io/terms/'} scrolling={'no'}>
              {' '}
            </StyledIframe>
          </IframeContainer>
        </Modal>
      )}
      {privacyPolicyVisible && (
        <Modal
          headerPadding={'0'}
          titlePadding={'0.3rem 0'}
          title={'Privacy Policy'}
          padding={'0 4rem 2rem'}
          onClose={() => setPrivacyPolicyVisible(false)}
        >
          <IframeContainer>
            <StyledIframe src={'https://movingmindz.io/privacy/'} scrolling={'no'}>
              {' '}
            </StyledIframe>
          </IframeContainer>
        </Modal>
      )}
    </>
  );
};

export default RegisterForm;
