/* eslint-disable @typescript-eslint/camelcase */
import React, { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { loadMyTeamStats } from '../../lib/Api/Stats';
import { getDashboardStatsData } from '../../lib/Api/User';
import { getMyDashboardData } from '../../lib/Api/participant/Dashboard';
import { isAfter } from '../../lib/TimeUtils';

import { SurveyContainer } from '../../components/UI';

import { Shine_preEventSurveyURL } from '../../CONSTANTS';
import { StyledPageHd } from '../../Themes';
import { ApplicationState } from '../../lib/Store';
import { APIThunkDispatch } from '../../lib/Types';

export interface PreSurveyProps {}

/*
Note: This is hardcoded for each particular event.
This means that only one event can run at one time.
If you want multiple suveys going at one time, I guess you could
alter this component to return multiple possible surveys based on event ID
*/

// SHINE PRE EVENT SURVEY VARS
const timeToCompleteShine = 13;
const startDateShine = dayjs('2023-08-26T00:00:00+11:00');
const endDateShine = dayjs('2023-09-18T23:59:00+11:00');
const daysLeftShine = endDateShine.diff(dayjs(), 'day') + 1;
const showSurveyContainerShine =
  dayjs().isBefore(endDateShine) && dayjs().isAfter(startDateShine);

const PreSurvey: FC<PreSurveyProps> = () => {
  const dispatch: APIThunkDispatch = useDispatch();
  const {
    userState: { userEvent }
  } = useSelector((state: ApplicationState) => state);

  const beforeStartDate = isAfter(userEvent?.startDate, moment().valueOf());

  useEffect(() => {
    if (!userEvent.eventId || beforeStartDate) return;
    dispatch(getMyDashboardData());
  }, [dispatch, getMyDashboardData, userEvent]);

  useEffect(() => {
    if (!userEvent.eventId || beforeStartDate) return;
    dispatch(getDashboardStatsData());
  }, [dispatch, getDashboardStatsData, userEvent]);

  if (showSurveyContainerShine && userEvent.eventId === 6) {
    return (
      <>
        <StyledPageHd>The Pre Event Survey</StyledPageHd>
        <SurveyContainer
          surveyName="Pre Event Survey"
          surveyNamePrefix="Time to complete the"
          surveyNameColor="#FF5E86"
          surveyDescription=""
          surveyCompleted={false}
          middleContainerFlex={4.5}
          showSurveyTimeToComplete
          showSurveyDaysLeft
          timeToComplete={timeToCompleteShine}
          daysLeft={daysLeftShine}
          onClick={() => window.open(Shine_preEventSurveyURL, '_blank')}
        />
      </>
    );
  }

  return <></>;
};

export default PreSurvey;
