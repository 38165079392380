import React, { FC, ReactElement } from 'react';
import Dropzone from 'react-dropzone';
import { FormikForm, FilePreview } from '../../../lib/Types';
import RewardsFormInput from './RewardsFormInput';

import {
  AddImage,
  AddPoints,
  RewardImage,
  UploadButton,
  ButtonWrapper,
  TitleWrapper,
  CameraImage,
  ModalTitle,
  BodyText,
  BodyHeader,
  Container,
  PictureWrapper,
  Picture,
  SaveBtn,
  CancelBtn,
  BackBtn
} from './Styles';
import { ErrorLabel } from '../../../components/UI';
import { commentImage } from '../../../assets/icons';
import { backArrow } from '../../../assets/icons';
import { MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_LABEL } from '../../../CONSTANTS';

export interface CreateRewardFormProps extends FormikForm<any> {
  setFieldError: (field: string, message: string) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  onCancel: () => void;
  error?: boolean;
}
const CreateRewardForm: FC<CreateRewardFormProps> = ({
  handleChange,
  handleSubmit,
  setFieldError,
  setFieldValue,
  onCancel,
  values,
  errors
}) => (
  <>
    <TitleWrapper>
      <BackBtn alt="back" src={backArrow} onClick={onCancel} />
      <ModalTitle>Create a Reward</ModalTitle>
    </TitleWrapper>
    <Container width="75%">
      <form onSubmit={handleSubmit}>
        <AddImage>
          <Dropzone
            noClick
            noKeyboard
            multiple={false}
            accept="image/jpeg,image/png"
            maxSize={MAX_IMAGE_SIZE}
            onDrop={async (files: File[]) => {
              if (files.length <= 0) return setFieldError('image', 'Image is too big');
              const acceptedFile: FilePreview = files[0];
              /** TODO: Load file and check dimensions requirement */
              acceptedFile.preview = URL.createObjectURL(acceptedFile);
              setFieldValue('image', acceptedFile);
            }}
          >
            {({ getRootProps, getInputProps, open }): ReactElement => (
              <>
                <BodyHeader>Add an Image</BodyHeader>
                <BodyText>
                  The rewards badge image needs to be set at the correct size <br /> and saved out
                  as a transparent PNG file at 512px x 512px{' '}
                  <i> &nbsp; (&lt; {MAX_IMAGE_SIZE_LABEL})</i>
                </BodyText>
                <PictureWrapper>
                  <Picture src={values.image?.preview} />
                </PictureWrapper>
                <RewardImage {...getRootProps({ className: 'dropzone' })}>
                  <UploadButton variant="primary" type="button" onClick={open} width={'auto'}>
                    <input {...getInputProps()} />
                    <CameraImage role="button" src={commentImage} alt="Upload image" />
                    {!values.image ? 'Add Image' : 'Replace Image'}
                  </UploadButton>
                  {errors.image ? <ErrorLabel error={errors.image} /> : ''}
                </RewardImage>
              </>
            )}
          </Dropzone>
        </AddImage>
        <RewardsFormInput
          required
          id="name"
          name="name"
          type="text"
          value={values.name}
          error={errors.name}
          onChange={handleChange}
          placeholder="Reward title"
          width="75%"
          margin="0.75rem 0"
        />
        <>
          <AddPoints>
            The participant should receive
            <RewardsFormInput
              required
              id="points"
              name="points"
              type="number"
              value={values.points}
              error={errors.points}
              width="10%"
              onChange={handleChange}
              margin="0 1rem"
              placeholder="eg. 10"
            />
            points for earning this reward.
          </AddPoints>
        </>
        <ButtonWrapper>
          <CancelBtn onClick={onCancel} type="button" variant={'cancel'}>
            Cancel
          </CancelBtn>
          {/*TODO: increase opacity/enable when form is validated*/}
          <SaveBtn type="submit" variant={'primary'}>
            Save Reward
          </SaveBtn>
        </ButtonWrapper>
      </form>
    </Container>
  </>
);

export default CreateRewardForm;
