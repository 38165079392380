// App Wide Utility Functions
import { keyframes } from 'styled-components/macro';
import moment from 'moment';
import ImageResizer from 'react-image-file-resizer';
import {
  postLaugh,
  postGratitude,
  postSleep,
  postMindfulness,
  postExercise,
  postNutrition,
  postConnection
} from '../assets/icons';
import themeTemplate, { Theme } from '../theme';
import { PostType, YourSteps, User } from './Types';
import { ToSIPrefix } from '@cognativ/utilities';
import { getClientTimezone } from './TimeUtils';

export const postTypeColour = (type: string, theme: typeof themeTemplate): string => {
  switch (type) {
    case PostType.Laugh:
      return theme.primary.purple;
    case PostType.Gratitude:
      return theme.primary.lightGreen;
    case PostType.Connection:
      return theme.primary.connectionRed;
    case PostType.Sleep:
      return theme.primary.sleepBlue;
    case PostType.Mindfulness:
      return theme.primary.mindfulnessOrange;
    case PostType.Exercise:
      return theme.primary.exerciseTurquoise;
    case PostType.Nutrition:
      return theme.primary.green;
    default:
      return null;
  }
};

export const postTypeIcon = (type: string) => {
  switch (type) {
    case PostType.Laugh:
      return postLaugh;
    case PostType.Gratitude:
      return postGratitude;
    case PostType.Connection:
      return postConnection;
    case PostType.Sleep:
      return postSleep;
    case PostType.Mindfulness:
      return postMindfulness;
    case PostType.Exercise:
      return postExercise;
    case PostType.Nutrition:
      return postNutrition;
    default:
      return null;
  }
};

export const isIosDevice = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const hasBeenSetInStorage = (key: string): boolean => {
  const value = window.localStorage.getItem(key);
  return value !== null && value !== 'false';
};

/** Parses Vimeo URI into Vimeo ID */
export const parseUri = (vimeoUri: string): string =>
  vimeoUri.split('/')[vimeoUri.split('/').length - 1];

/** Check is given string is a Vimeo URI */
export const isVimeoUri = (uri: string): boolean => uri.split('/')[1] === 'videos';

// Get Step Data
export const getToday = (yourSteps: Array<YourSteps>) => {
  const today = moment.tz(moment(), getClientTimezone()).startOf('day');

  const findDate = yourSteps.find(date => {
    const d = moment.tz(date.completionDate, getClientTimezone()).startOf('day');
    if (today.valueOf() === d.valueOf()) {
      return date;
    }
  });

  if (findDate) {
    const today = findDate.value;
    if (today === 0) {
      return 'none';
    }
    return ToSIPrefix(today);
  }
  return 'none';
};

export const getYesterday = (yourSteps: Array<YourSteps>) => {
  const yesterday = moment
    .tz(moment(), getClientTimezone())
    .subtract(1, 'day')
    .startOf('day');

  const findDate = yourSteps.find(date => {
    const d = moment.tz(date.completionDate, getClientTimezone()).startOf('day');
    if (yesterday.valueOf() === d.valueOf()) {
      return date;
    }
  });

  if (findDate) {
    const yesterday = findDate.value;
    if (yesterday === 0) {
      return 'none';
    }
    return ToSIPrefix(yesterday);
  }
  return 'none';
};

export const getWeek = (yourSteps: Array<YourSteps>) => {
  // This week's Sunday
  const startOfWeek = moment()
    .startOf('isoWeek')
    .subtract(1, 'day');

  const week = yourSteps
    .filter(({ completionDate }) => moment(completionDate).isSame(startOfWeek, 'week'))
    .reduce((acc: number, { value }) => acc + value, 0);

  if (week === 0) {
    return 'none';
  }

  return ToSIPrefix(week);
};

export const getAllSteps = (yourSteps: Array<YourSteps>) => {
  let all = 0;
  yourSteps.map(day => {
    all += day.value;
  });

  if (all === 0) {
    return 'none';
  }

  return all;
};

export const generateTempAdminPassword = (length = 8) => {
  if (length < 8) return;
  let pass = '';
  const PotentialChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  while (pass.length < length) {
    const randomIndex = Math.floor(Math.random() * PotentialChars.length);
    pass += PotentialChars[randomIndex];
  }

  return pass;
};

export const _checkEventStartDate = (
  eventStartDate: string,
  setEventStarted: (eventStarted: boolean) => void
) => {
  const currentDate = new Date();
  const userEventStartDate = new Date(`${eventStartDate}T00:00:00.000`);

  const eventStarted = currentDate > userEventStartDate;
  setEventStarted(eventStarted);
};

export const getDaySuffix = (dayString: string) => {
  const reversedDigits = dayString.split('').reverse();
  if (reversedDigits[1] !== '1') {
    // Number is in the teens
    switch (reversedDigits[0]) {
      case '1':
        return `${dayString}st`;
      case '2':
        return `${dayString}nd`;
      case '3':
        return `${dayString}rd`;
    }
  }
  return `${dayString}th`;
};

export const convertStringToMonth = (monthString: string) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  return months[parseInt(monthString, 10) - 1];
};

// Opacity required to allow pop in animation on elements containing SVG's
// Setting these elements to scale(0) before running animation causes text
// rendering issues
export const popinEnlarge = keyframes`
  from {
    opacity: 1;
    transform: scale3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

export const expandHorizontal = keyframes`
  from {
    max-width: 0;
  }

  to {
    max-width: 100%
  }
`;

export const expandVertical = keyframes`
  from {
    max-height: 0
  }

  to {
    max-height: 100%
  }
`;

export const popinRight = keyframes`
  from {
    opacity: 0;
    right: 20px
  }

  to {
    opacity: 1;
    right: 0
  }
`;

export const shift = keyframes`
  from {
    margin-left: 0;
  }

  to {
    margin-left: -20px;
  }
`;

export const fadeUp = keyframes`
  from {
    opacity: 0;
    top: 5px;
  }

  to {
    opacity: 1;
    top: 0px;
  }
`;

export const getBoxShadow = (elevation: number, { shadows }: Theme): string | undefined => {
  switch (elevation) {
    case 1:
      return shadows.lighter;
    case 2:
      return shadows.light;
    case 3:
      return shadows.medium;
    case 4:
      return shadows.heavy;
    case 5:
      return shadows.heavier;
  }
};

export const resizeImage = (file: File, maxWidth = 1920, maxHeight = 1080) => {
  return new Promise<File>(resolve => {
    ImageResizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      80,
      0,
      (image: File) => {
        resolve(image);
      },
      'file'
    );
  });
};

export const centsToDollars = (cents: number, withCurrencySymbol = true) => {
  if (cents === undefined) return '';
  const dollars = cents / 100;
  let dollarString = '';
  if (withCurrencySymbol) {
    dollarString = dollars.toLocaleString('en-AU', {
      style: 'currency',
      currency: 'AUD'
    });
  } else {
    dollarString = dollars.toLocaleString('en-AU', {
      minimumFractionDigits: 2
    });
  }

  return dollarString;
};

export const capitaliseUserFullName = (user: Partial<User>) => {
  if (!user.firstName || !user.lastName) return null;
  const firstName = `${user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)}`;
  const lastName = `${user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)}`;
  return `${firstName} ${lastName}`;
};

export default {
  postTypeColour,
  postTypeIcon,
  resizeImage,

  getDaySuffix,
  convertStringToMonth,

  isIosDevice,

  hasBeenSetInStorage,

  parseUri,
  isVimeoUri,

  getToday,
  getYesterday,
  getWeek,
  getAllSteps,
  _checkEventStartDate,

  generateTempAdminPassword
};
