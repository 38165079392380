import React, { FC } from 'react';
import { Group } from '../Styles';
import { Checkbox } from '../../../../../components/UI';
import { FormikFormComponent } from '../../../../../lib/Types';
import { ParticipantSchema } from '../../../validators/participantSchema';

interface SDAExemptionProps extends FormikFormComponent<ParticipantSchema> {
  handleCheckboxChange: (name: string, value: any) => void;
}

const SDAExemption: FC<SDAExemptionProps> = ({ values, handleCheckboxChange }) => (
  <Group>
    <Checkbox
      checked={values.sdaExemption}
      label={'Suspect Acitivty Check Exemption'}
      name={'sdaExemption'}
      onChange={val => handleCheckboxChange('sdaExemption', !values.sdaExemption)}
    />
  </Group>
);

export default SDAExemption;
