import styled from 'styled-components/macro';
import { theme } from '../../../theme';

interface TooltipProps {
  tooltipLocation?: string;
}

export const TooltipContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: flex-end;
  position: relative;
  font-style: italic;
  font-weight: normal;
  cursor: help;
  font-size: 80%;
  white-space: nowrap;
  color: ${theme.primary.oceanGreen};
`;

export const TooltipDiv = styled.div<TooltipProps>`
  position: absolute;
  display: flex;
  flex: 1;
  min-width: 20rem;
  font-style: normal;
  z-index: 5;
  padding: 1.2rem;
  font-size: 1.5rem;
  white-space: normal;
  border-radius: ${theme.borderRadius.lg}px;
  color: ${theme.primary.white};
  cursor: default;
  background-color: ${theme.primary.oceanGreen};

  @media (max-width: ${theme.device.mobileL}px) {
    font-size: 1.2rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 1.3rem; left: 0;
    width: 0; height: 0;
    border-style: solid;
    border-width: 7.5px 15px 7.5px 0;
    border-color: transparent #3EC5B8 transparent transparent;
  }

  ${({ tooltipLocation }) =>
    tooltipLocation === 'LEFT' &&
    `
      right: calc(100% + 1.5rem);
      top: -1rem;
      &:before {
        left: auto; right: -1.4rem;
        transform: rotateZ(180deg);
      }
    `}
  ${({ tooltipLocation }) =>
    tooltipLocation === 'TOP' &&
    `
      left: 50%;
      transform: translateX(-50%);
      bottom: calc(100% + 1.5rem);
      &:before {
        left: 50%; top: auto;
        bottom: -1.4rem;
        transform: rotateZ(-90deg);
      }
    `}
  ${({ tooltipLocation }) =>
    tooltipLocation === 'BOTTOM' &&
    `
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% + 1.5rem);
      &:before {
        left: 50%; top: -1.4rem;
        transform: rotateZ(90deg);
      }
    `}
  ${({ tooltipLocation }) =>
    (tooltipLocation === 'RIGHT' || !tooltipLocation) &&
    `
      margin-top: -1rem;
      left: calc(100% + 2rem);
      &:before {
        left: -1.4rem;
      }
    `}
`;
