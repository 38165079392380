import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  flex: 1;
  margin-top: 0;

  & ~ & {
    margin-top: '1rem; margin-bottom: 1rem';
  }
`;

interface LabelProps {
  value?: string;
}

export const Label = styled.label<LabelProps>`
  background-color: white;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  position: absolute;
  transition: all 0.1s;
  left: 1.5rem;
  cursor: text;
  display: 'initial';
  top: ${({ value }) => (value ? '-1.2rem' : '1.3rem')};
  color: ${({ value, theme }) => (value ? theme.primary.xlight : '#D0DCF2')};
  font-style: 'italic';
`;

export const StyledInput = styled.input<{}>`
  color: ${({ theme }) => theme.primary.dark};
  padding: 0.3rem 0.3rem 0.3rem 1.5rem;
  font-size: calc(${({ theme }) => theme.fontScale.sm} * 1.1);
  width: ${({ width }) => (width ? width : '100%')};
  height: 4.35rem;
  border: none;

  ::placeholder {
    color: #ffffff;
  }

  :focus ~ ${Label} {
    color: ${({ theme }) => theme.primary.light};
    top: -0.9rem;
  }

  :not(:focus) &::placeholder {
    color: ${({ theme }) => theme.primary.xlight};
  }

  :not(:placeholder-shown) ~ ${Label} {
    color: ${({ theme }) => theme.primary.light};
    top: -1rem;
  }
`;
