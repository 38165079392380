import {
  SET_TEAMS_FILTER,
  SET_TEAM,
  SET_PAGED_TEAMS,
  TeamActionTypes,
  TeamsState,
  SET_ADDING_TEAM_MEMBER,
  SET_GENERATING_TEAMS
} from './types';

export const initialState: TeamsState = {
  pagedTeamList: {
    results: [],
    count: 0
  },
  team: {
    active: false,
    teamId: null,
    name: '',
    approved: false,
    activities: [],
    companyId: null,
    eventId: null,
    pendingRegistrations: [],
    registrations: []
  },
  addingTeamMember: false,
  generatingTeams: false,
  filter: {
    page: 1,
    rowsPerPage: 20,
    orderColumn: 'teamId',
    orderDirection: 'ASC',
    keyword: '',
    companyId: null,
    eventId: null
  }
};

const teamReducer = (state: TeamsState = initialState, action: TeamActionTypes): TeamsState => {
  switch (action.type) {
    case SET_PAGED_TEAMS:
      return {
        ...state,
        pagedTeamList: action.pagedTeamList
      };
    case SET_TEAM:
      return {
        ...state,
        team: action.team
      };
    case SET_ADDING_TEAM_MEMBER:
      return {
        ...state,
        addingTeamMember: action.addingTeamMember
      };
    case SET_GENERATING_TEAMS:
      return {
        ...state,
        generatingTeams: action.generatingTeams
      };
    case SET_TEAMS_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    default:
      return state;
  }
};

export default teamReducer;
