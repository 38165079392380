import React from 'react';
import ActivityIndicator from 'react-spinners/PulseLoader';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../lib/Store';
import { LoaderSizeMarginProps } from 'react-spinners/interfaces';
import { LoaderWrapper } from './Styles';
import theme from '../../../theme';

// Global loader shown at top level of app
// Connects to redux loading state
export interface GlobalLoadingIndicatorProps extends LoaderSizeMarginProps {
  progress: number;
}

const GlobalLoadingIndicator: React.FC<GlobalLoadingIndicatorProps> = ({
  progress,
  loading,
  color = theme.primary.dark,
  ...props
}) =>
  loading ? (
    <LoaderWrapper>
      <ActivityIndicator loading color={color} {...props} />
      <p>{`${progress.toFixed(2)}%`}</p>
    </LoaderWrapper>
  ) : null;

export default connect(({ loadingState }: ApplicationState) => ({
  loading: loadingState.loadingSpinner,
  progress: loadingState.loadingPercent
}))(GlobalLoadingIndicator);
