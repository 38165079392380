import styled from 'styled-components/macro';

import { getBoxShadow, popinEnlarge } from '../../../lib/Utils';

import { ContainerProps } from '../../../lib/Types';

interface ImageProps {
  image: string;
}

interface CellHighlightProps {
  highlight?: boolean;
  dropable?: boolean;
  none?: boolean;
}

export const Header = styled.div`
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.8rem;
  font-style: oblique;
  padding-left: 1rem;
`;

export const Table = styled.div`
  display: table;
  font-size: calc(${({ theme }) => theme.fonts.input}rem + 0.42vw);
  width: 100%;
  border-collapse: separate;
  margin-top: -3rem;

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    margin-top: -1.5rem;
  }
`;

export const TableHeaders = styled.div`
  display: table-header-group;
`;

export const TableHeader = styled.div<CellHighlightProps>`
  display: table-cell;
  text-align: center;
  color: ${({ theme }) => theme.primary.main};
  font-family: 'Sarabun', sans-serif;
  font-size: calc(0.9rem + 0.3vw);
  font-weight: ${({ theme }) => theme.fontWeights.light};
  line-height: calc((0.9rem + 0.5vw) * 1.3);
  padding: 1vw 0 0.75vw 0;
  /* border-bottom: 1px solid ${({ theme }) => theme.borders.xlight}; */
  text-transform: capitalize;

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    display: ${props => (props.dropable ? 'none' : 'table-cell')};
  }
`;

export const TableBody = styled.div`
  color: #515151;
  display: table-row-group;
`;

export const TableRow = styled.div<CellHighlightProps>`
  display: table-row;
  background: ${props => (props.highlight ? props.theme.backgrounds.light : 'white')};
`;

export const TableCell = styled.div<CellHighlightProps>`
  display: table-cell;
  text-align: center;
  font-size: 88%;
  min-width: 6rem;
  vertical-align: middle;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${props => (props.none ? '#FF5E86' : '#515151')};

  &:first-child {
    border-top-left-radius: ${props => (props.highlight ? '100rem' : 0)};
    border-bottom-left-radius: ${props => (props.highlight ? '100rem' : 0)};
    padding-left: 1rem;
  }

  &:last-child {
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    border-top-right-radius: ${props => (props.highlight ? '100rem' : 0)};
    border-bottom-right-radius: ${props => (props.highlight ? '100rem' : 0)};
  }

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    display: ${props => (props.dropable ? 'none' : 'table-cell')};
  }
`;

export const ProfileCell = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileName = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-family: 'Sarabun', sans-serif;
  white-space: nowrap;
`;

export const ProfilePic = styled.div<ImageProps>`
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0.5rem 1rem 0.5rem 0.5rem;
  background: center / cover ${props => `url('${props.image}') no-repeat`};
`;

export const YourStatsContainer = styled.div<ContainerProps>`
  border-radius: 1.5rem;
  width: 100%;
  box-shadow: ${({ elevation = 1, theme }) => getBoxShadow(elevation, theme)};
  padding: 2rem;
  background: white;
  z-index: 20;
  position: relative;
  transform: scale(0);
  animation: ${popinEnlarge} 0.8s cubic-bezier(0.4, 0.01, 0, 1.08)
    ${props => (props.delay ? props.delay : 1.1)}s forwards;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    margin-left: 0;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    border-radius: 0;
  }
`;
