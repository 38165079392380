import { TermsAndConditionsErrorContext } from '../Types';

export class TermsAndConditionsError extends Error {
  context: TermsAndConditionsErrorContext;

  constructor(m: string, context: TermsAndConditionsErrorContext) {
    super(m);
    this.context = context;
    Object.setPrototypeOf(this, TermsAndConditionsError.prototype);
  }
}

export const getTermsAndConditionsNotAcceptedError = (): TermsAndConditionsError => {
  return new TermsAndConditionsError('You must accept the Terms and Conditions.', 'accepted');
};
