import styled from 'styled-components/macro';

export interface StyledDropdownProps {
  open: boolean;
  selected: boolean;
}

export interface IconProps {
  open: boolean;
}

export interface OptionProps {
  selected?: boolean;
  tickSelected?: boolean;
  linePadding?: string;
}

export const StyledDropdown = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  position: relative;
  & ~ & {
    margin: 5px 0px;
  }
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

export const SelectedWrapper = styled.div<StyledDropdownProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin: ${({ theme }) => theme.pad.xxsm / 2}rem 0 0 0;
  width: 100%;
  padding: 15px;
  font-size: ${({ theme }) => theme.fonts.subBody + 0.2}rem;
  color: ${({ theme, selected }) => (selected ? theme.primary.light : theme.backgrounds.grey)};

  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.primary.light : theme.backgrounds.grey)};
  border-radius: 6px;
  border-bottom-left-radius: ${({ open }) => (open ? '0px' : '6px')};
  border-bottom-right-radius: ${({ open }) => (open ? '0px' : '6px')};

  cursor: pointer;
`;

export const Selected = styled.div<{ searchable?: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;

  display: ${({ searchable }) => (searchable ? 'block' : 'flex')};
  margin-right: ${({ searchable }) => (searchable ? 'auto' : '0px')};
  margin-left: ${({ searchable }) => (searchable ? '1rem' : '0px')};
`;

export const OptionsWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid
    ${({ theme, selected }) => (selected ? theme.primary.light : theme.backgrounds.grey)};
  border-left: 1px solid
    ${({ theme, selected }) => (selected ? theme.primary.light : theme.backgrounds.grey)};
  border-right: 1px solid
    ${({ theme, selected }) => (selected ? theme.primary.light : theme.backgrounds.grey)};
  background-color: ${({ theme }) => theme.backgrounds.white};
  position: absolute;
  z-index: 80;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;

  &:last-child {
    border-bottom-left-radius: 0.35rem;
    border-bottom-right-radius: 0.35rem;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: transparent;
    padding: 2px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.backgrounds.darkGrey};
    border: 2px solid #ffffff;
  }
`;

export const Option = styled.div<OptionProps>`
  display: ${({ tickSelected }) => tickSelected && 'flex'};
  flex-direction: ${({ tickSelected }) => tickSelected && 'row'};
  align-items: ${({ tickSelected }) => tickSelected && 'center'};

  padding: ${({ linePadding }) => (linePadding ? `${linePadding} 15px` : '15px 15px')};
  text-align: left;
  font-size: ${({ theme }) => theme.fonts.subBody * 1.15}rem;
  color: ${({ theme, selected }) => (selected ? theme.primary.light : theme.primary.dark)};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  cursor: pointer;
  &:hover {
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    color: ${({ theme, selected }) => !selected && theme.primary.dark};
    font-size: ${({ theme }) => theme.fonts.subBody * 1.18}rem;
  }
`;

export const ToggleIcon = styled.img<IconProps>`
  transform: ${({ open }) => (open ? 'scaleY(-1)' : '')};
  width: 0.75rem;
`;

export const Icon = styled.img``;

export const NoSearchResults = styled.div`
  padding: 15px;
`;
