import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SummaryRow = styled.div`
  display: flex;
  padding: 0.5rem 2rem;
  min-height: 40px;
  align-items: center;
`;

export const Amount = styled.div`
  display: flex;
  flex: 1;
`;

export const Title = styled.div`
  display: flex;
  flex: 3;
`;

export const Value = styled.div`
  display: flex;
  flex: 2;
`;

export const Divider = styled.div<{ margin?: string }>`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.primary.lightGrey}50;
  margin: ${({ margin = '0' }) => margin};
`;
