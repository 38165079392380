import { SyncService } from '../../../Types';

export const SET_AVAILABLE_SERVICES = 'SET_AVAILABLE_SERVICES';

export interface SyncServiceState {
  availableServices: SyncService[];
}

export interface SetAvailableServicesAction {
  type: typeof SET_AVAILABLE_SERVICES;
  availableServices: SyncService[];
}

export type SyncServiceActionTypes = SetAvailableServicesAction;
