import React, { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ResponsiveContainer, BarChart, YAxis, Bar, XAxis, CartesianGrid } from 'recharts';

import { getDashboardData, GetDashboardData } from '../../lib/Api/masterAdmin/Dashboard';

import PageWrapper from '../../components/UI/PageWrapper';
import StatCard from '../../components/UI/StatCard';
import HoldingPage from '../../components/UI/HoldingPage';
import StatSquare from './StatSquare';

import { whiteCross, whiteArrow, noEventsGraphic } from '../../assets/img';
import { unapproved, teamIc, cursor, noTeam } from '../../assets/icons';
import {
  Heading,
  SubHeading,
  DbGrid,
  StepApprovals,
  TeamApprovals,
  MakeSocialPost,
  Registrations,
  TotalTeams,
  UnapprovedTeams,
  WithoutTeam,
  RegistrationsBargraph,
  ChartHeaderWrap,
  ChartHeader,
  RegistrationsCard
} from './Styles';
import theme from '../../theme';

import { ApplicationState } from '../../lib/Store';
import { setParticipantsFilter } from '../../lib/Store/contexts/participants/actions';
import { setTeamListFilter } from '../../lib/Store/contexts/team/actions';
import { APIThunkDispatch, RoleCode } from '../../lib/Types';

interface DashboardProps {
  getDashboardData: GetDashboardData;
}

const Dashboard: React.FC<DashboardProps> = ({ getDashboardData }) => {
  const Dispatch: APIThunkDispatch = useDispatch();
  const {
    companyAdminEvent,
    currentEventLoading,
    participantsFilter,
    dashBoardData,
    teamsFilter,
    code,
    userCompany
  } = useSelector(
    ({
      companyAdminEventState,
      userState,
      participantsState,
      masterAdminDashboardState,
      teamsState,
      permissionsState
    }: ApplicationState) => ({
      companyAdminEvent: companyAdminEventState.currentEvent,
      currentEventLoading: companyAdminEventState.currentEventLoading,
      eventLinkingInfo: companyAdminEventState.eventLinkingInfo,
      participantsFilter: participantsState.filter,
      dashBoardData: masterAdminDashboardState,
      teamsFilter: teamsState.filter,
      code: permissionsState.code,
      userCompany: userState.userCompany
    })
  );

  const [graphData, setGraphData] = useState({
    barChartData1: [],
    barChartData2: [],
    barChartLabels: []
  });

  useEffect(() => {
    let companyId, eventId;
    // If company admin, use their company + current event iff exists
    if (code === RoleCode.CompanyAdmin) {
      if (!companyAdminEvent || !userCompany) return;
      companyId = userCompany.companyId;
      eventId = companyAdminEvent.eventId;
    } else {
      // Else master admin, use filter.
      companyId = participantsFilter.companyId;
      eventId = participantsFilter.eventId;
    }
    (async () => await getDashboardData(eventId, companyId))();
  }, [participantsFilter, companyAdminEvent, userCompany]);

  useEffect(() => {
    if (!dashBoardData.companyRegistrations) return;
    const barChartData1: number[] = [];
    const barChartData2: number[] = [];
    const barChartLabels: string[] = [];
    const serializedData = dashBoardData.companyRegistrations.map((item: any): any => {
      return {
        name: item.companyName,
        d1: item.approvedParticipants,
        d2: item.unapprovedParticipants
      };
    });
    serializedData.map((item: any): any => {
      barChartData1.push(item.d1);
      barChartData2.push(item.d2);
      barChartLabels.push(item.name);
    });
    setGraphData({ barChartData1, barChartData2, barChartLabels });
  }, [dashBoardData]);

  const handleDropdownClick = (dataType: string, props: any) => {
    let id;
    if (props?.id === 'all') id = null;
    else id = typeof props?.id === 'number' ? props?.id : parseInt(props?.id, 10);

    if (dataType === 'company') {
      Dispatch(setParticipantsFilter({ ...participantsFilter, companyId: id, page: 1 }));
      Dispatch(setTeamListFilter({ ...teamsFilter, companyId: id, page: 1 }));
    } else if (dataType === 'event') {
      Dispatch(setParticipantsFilter({ ...participantsFilter, eventId: id, page: 1 }));
      Dispatch(setTeamListFilter({ ...teamsFilter, eventId: id, page: 1 }));
    }
  };
  const data = dashBoardData.companyRegistrations
    .filter(reg => reg.approvedParticipants !== 0 || reg.unapprovedParticipants !== 0)
    .slice(0, 8);

  const renderStats = () => {
    return (
      <>
        <Heading>Quick Links</Heading>
        <DbGrid>
          <StepApprovals>
            <StatCard
              gradient={theme.gradients.blue}
              color={theme.primary.light}
              value={dashBoardData.stats && dashBoardData.stats.totalRegistrations}
              title="Participants"
              badgeBackground={''}
              iconImg={whiteArrow}
              linkTo="/Participants"
            />
          </StepApprovals>
          <TeamApprovals>
            <StatCard
              gradient={theme.gradients.turquoise}
              color={theme.status.success}
              value={dashBoardData.stats && dashBoardData.stats.teamApprovals}
              title="Team Approvals"
              iconImg={whiteArrow}
              linkTo="/Teams"
            />
          </TeamApprovals>
          <MakeSocialPost>
            <StatCard
              gradient={theme.gradients.orange}
              color={theme.status.warning}
              value="#"
              title="Create a Social Post"
              iconImg={whiteCross}
              linkTo="/CreatePost"
            />
          </MakeSocialPost>
          <Registrations>
            <StatSquare
              title="Registrations"
              icon={cursor}
              color={'#FFC24D'}
              body="Total number of registrations"
              value={dashBoardData.stats && dashBoardData.stats.totalRegistrations}
            />
          </Registrations>
          <TotalTeams>
            <StatSquare
              title="Total Teams"
              icon={teamIc}
              color={theme.status.info}
              body="Total number of teams"
              value={dashBoardData.stats && dashBoardData.stats.totalTeams}
            />
          </TotalTeams>
          <UnapprovedTeams>
            <StatSquare
              title="Unapproved Teams"
              icon={unapproved}
              color={theme.status.success}
              body="Total number of unapproved teams"
              value={dashBoardData.stats && dashBoardData.stats.teamApprovals}
            />
          </UnapprovedTeams>
          <WithoutTeam>
            <StatSquare
              title="Without a team"
              icon={noTeam}
              color={theme.status.danger}
              body="Total number of participants without a team"
              value={dashBoardData.stats && dashBoardData.stats.participantsWithoutTeam}
            />
          </WithoutTeam>
          {dashBoardData?.stats?.totalRegistrations > 0 && (
            <RegistrationsCard gridRowEnd={code === RoleCode.CompanyAdmin}>
              <RegistrationsBargraph>
                <ChartHeaderWrap>
                  <ChartHeader>Registrations</ChartHeader>
                  <SubHeading>
                    <span style={{ color: theme.status.success }}>Approved</span> vs{' '}
                    <span style={{ color: theme.status.danger }}>Unapproved</span> Participants
                  </SubHeading>
                </ChartHeaderWrap>
                {graphData && (
                  <ResponsiveContainer width="95%" height="100%">
                    <BarChart data={data} layout="vertical" margin={{ left: 30, top: 20 }}>
                      <defs>
                        <linearGradient id="ApprovedGradient" x1="0%" x2="100%" y1="100%" y2="100%">
                          <stop className="start" offset="0%" stopColor="#57D9D1" stopOpacity="1" />
                          <stop className="end" offset="100%" stopColor="#E0F07E" stopOpacity="1" />
                        </linearGradient>
                        <linearGradient
                          id="UnapprovedGradient"
                          x1="0%"
                          x2="100%"
                          y1="100%"
                          y2="100%"
                        >
                          <stop className="start" offset="0%" stopColor="#FF5E86" stopOpacity="1" />
                          <stop className="end" offset="100%" stopColor="#FFB982" stopOpacity="1" />
                        </linearGradient>
                      </defs>
                      <YAxis
                        dataKey="companyName"
                        type="category"
                        tickLine={false}
                        fontSize={12}
                        fontWeight={800}
                        fontStyle="italic"
                        tick={{ fill: '#366696' }}
                        stroke="#00000010"
                      />
                      <XAxis
                        type="number"
                        axisLine={false}
                        tickLine={false}
                        fontWeight={400}
                        fontSize={14}
                        tick={{ fill: '#366696' }}
                      />
                      <Bar
                        dataKey="unapprovedParticipants"
                        fill="url(#UnapprovedGradient)"
                        stackId="a"
                        barSize={13}
                      />
                      <Bar
                        dataKey="approvedParticipants"
                        fill="url(#ApprovedGradient)"
                        stackId="a"
                        barSize={13}
                      />
                      <CartesianGrid horizontal={false} stroke="#00000010" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </RegistrationsBargraph>
            </RegistrationsCard>
          )}
        </DbGrid>
      </>
    );
  };

  if (currentEventLoading) {
    return <PageWrapper handleDropdownClick={handleDropdownClick}></PageWrapper>;
  }

  // TODO: implement loading state on current event request to avoid flash of registrations screen
  return (
    <PageWrapper handleDropdownClick={handleDropdownClick}>
      {/** Master admin */}
      {code === RoleCode.MasterAdmin && renderStats()}
      {/** Company admin and no current event */}
      {code === RoleCode.CompanyAdmin && !companyAdminEvent && (
        <HoldingPage
          noEvent
          pageTitle="Dashboard"
          backgroundImage={noEventsGraphic}
          backgroundImageMob={noEventsGraphic}
        />
      )}
      {/** Company admin and current event either paid/unpaid */}
      {code === RoleCode.CompanyAdmin && companyAdminEvent && renderStats()}
    </PageWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDashboardData: getDashboardData as any
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Dashboard);
