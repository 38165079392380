import React, { FC } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import validationSchema, { ReplaceSchema } from '../../../validators/replaceSchema';
import { ApplicationState } from '../../../../../lib/Store';
import { addExistingToTeam } from '../../../../../lib/Api/Team';
import { Team, ErrorResponse, ParticipantListView } from '../../../../../lib/Types';
import ReplaceParticipant from './ReplaceParticipant';
import { replaceIndividualParticipant } from '../../../../../lib/Api/Participants';

export interface ReplaceParticipantContainerProps {
  team: Team;
  participant: ParticipantListView;
  individualParticipants: ParticipantListView[];
  loading: boolean;
  replaceIndividual: (team: Team, participantId: string, replaceParticipantId: string) => any;
  addIndividual: (team: Team, userId: string) => any;
  adding: boolean;
  cancel: () => void;
}

const formikEnhancer = withFormik<ReplaceParticipantContainerProps, ReplaceSchema>({
  validationSchema,
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: ({ team, participant }) => ({
    //replace participant values
    teamId: team.teamId,
    participantId: participant.participantId || '',
    replaceParticipantId: ''
  }),
  handleSubmit: async (
    { participantId, replaceParticipantId }: ReplaceSchema,
    { props, setErrors }
  ) => {
    const { error }: { error: ErrorResponse } = props.adding
      ? await props.addIndividual(props.team, replaceParticipantId)
      : await props.replaceIndividual(props.team, participantId, replaceParticipantId);
    if (error.data.field) setErrors({ [error.data.field]: error.message });
  }
});

const ReplaceParticipantContainer: FC<ReplaceParticipantContainerProps &
  FormikProps<ReplaceSchema>> = ({ setFieldValue, cancel, ...props }) => {
  const changeCustom = (name: string, data: any) => setFieldValue(name, data);
  return (
    <ReplaceParticipant
      {...props}
      handleDropdownChange={changeCustom}
      handleChangeCustom={changeCustom}
      onCancel={cancel}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  team: state.teamsState.team,
  adding: state.teamsState.addingTeamMember,
  individualParticipants: state.participantsState.participantsWithoutTeam,
  participant: state.participantsState.participant,
  loading: state.loadingState.apiCallsInProgress > 0
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      replaceIndividual: (team: Team, participantId: string, replaceParticipantId: string) =>
        replaceIndividualParticipant(team, participantId, replaceParticipantId),
      addIndividual: (team: Team, userId: string) => addExistingToTeam(team, userId),
      cancel: () => null
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formikEnhancer(ReplaceParticipantContainer));
