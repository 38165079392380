import React, { FC } from 'react';

import { Input } from '../../../../../components/UI';

import { Group, Heading } from '../Styles';
import theme from '../../../../../theme';

import { FormikFormComponent } from '../../../../../lib/Types';
import { EventSchema } from '../../../validators/eventSchema';

interface EventDetailsProps extends FormikFormComponent<EventSchema> {}

const EventDetails: FC<EventDetailsProps> = ({ values, errors, handleChange }) => (
  <Group>
    <Heading>Event Details</Heading>
    <Input
      required
      id="name"
      value={values.name}
      error={errors.name}
      label="Event Name"
      name="name"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
  </Group>
);

export default EventDetails;
