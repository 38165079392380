import styled from 'styled-components/macro';

import { expandHorizontal } from '../../../../lib/Utils';

interface BarProps {
  width: string;
  gradient: string;
}

export const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;

  margin-top: 1.5rem;
`;

export const Bar = styled.div<BarProps>`
  display: flex;
  flex-direction: row;
  width: ${props => props.width};
  height: 25px;
  border-radius: 12.5px;

  background-image: ${props => props.gradient};

  animation: ${expandHorizontal} 1.2s cubic-bezier(0.4, 0.01, 0, 1.38) 1.6s forwards;
  transition: all 0.6s cubic-bezier(0.4, 0.01, 0, 1.38);
`;

export const BarInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const BarName = styled.div`
  color: #000000;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 12px;
  line-height: 16.9px;
  margin-bottom: 0.5rem;
`;

export const BarTotal = styled.div`
  width: fit-content;

  margin-left: 1.5rem;

  color: #aeaeae;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 12px;
  line-height: 16.9px;
  white-space: nowrap;
`;

export const BarGraphContainer = styled.div`
  padding-right: 1rem;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    margin-left: 0;
  }
`;
