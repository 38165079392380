import styled from 'styled-components/macro';

import { getBoxShadow, popinEnlarge } from '../../../lib/Utils';

import { ContainerProps } from '../../../lib/Types';

export interface ToggleButtonProps {
  isLeft?: boolean;
  isActive?: boolean;
}

export const LineChartContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 2rem 2rem 0;
  background: ${({ theme }) => theme.backgrounds.white};
  border-radius: 1.5rem;
  box-shadow: ${({ elevation = 1, theme }) => getBoxShadow(elevation, theme)};

  // IMPORTANT: Opacity required to allow pop in animation on elements containing SVGs
  // Setting these elements to scale(0) before running animation causes text
  // rendering issues. We set to opacity: 0 and animation handles showing
  opacity: 0;

  animation: ${popinEnlarge} 0.8s cubic-bezier(0.4, 0.01, 0, 1.08)
    ${props => (props.delay ? props.delay : 1.2)}s forwards;

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    border-radius: 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ChartWrapper = styled.div`
  height: 300px;
  margin-top: 2.5rem;
  z-index: 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  color: #366696;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: 17px;
  text-align: left;
  line-height: 14px;
`;

export const SubTitle = styled.div`
  color: ${({ theme }) => theme.primary.lightBlue};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: 14px;
  text-align: left;
  line-height: 14px;
  margin-top: 1rem;
`;

export const LeftArrowIcon = styled.img<{ disabled: boolean }>`
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 1;
  transform: rotate(180deg);
  bottom: 9%;
  left: 5%;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const RightArrowIcon = styled.img<{ disabled: boolean }>`
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 1;
  bottom: 9%;
  right: 5%;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const ToggleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 235px;
  font-size: 14px;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    margin-top: 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.device.laptop}px) {
    width: 150px;
    font-size: 10px;
  }
`;

export const ToggleButton = styled.div<ToggleButtonProps>`
  display: flex;
  width: 120px;
  height: 25px;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: ${({ theme, isActive }) => (isActive ? '#4FB9DE' : theme.backgrounds.white)};
  border-radius: ${({ isLeft }) => (isLeft ? '50px 0 0 50px' : '0 50px 50px 0')};
  color: ${({ theme, isActive }) => (!isActive ? '#4FB9DE' : theme.backgrounds.white)};
  border: ${({ isActive }) => (!isActive ? '1px solid #4FB9DE' : 'none')};

  cursor: pointer;
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    margin-top: 1.5rem;
    margin-left: 0.5;
  }
`;

export const LegendKey = styled.div<{ isPersonal: boolean }>`
  width: 40px;
  height: 2px;
  background-color: ${({ isPersonal }) => (isPersonal ? '#a9a8ff' : '#FF5E86')};
`;

export const LegendText = styled.div<{ isPersonal: boolean }>`
  width: 100px;
  color: ${({ isPersonal }) => (isPersonal ? '#a9a8ff' : '#FF5E86')};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 12px;
  text-align: left;
  line-height: 14px;
  margin-left: 1rem;
`;
