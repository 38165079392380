import React, { FC } from 'react';
import { capitaliseUserFullName } from '../../lib/Utils';
import { DEFAULT_PIC } from '../../CONSTANTS';

import { Modal, Button } from '../../components/UI';

import { ParticipantCapModalContainer, ContactProfile, ImageWrap, ContactImage } from './Styles';
import { DarkBlueBodyS, DarkBlueHeaderL, DarkBlueHeaderM } from '../../Themes';

import { CompanyAdmin } from '../../lib/Types';

export interface ParticipantCapModalProps {
  onConfirm: () => void;
  contactData?: CompanyAdmin;
}

const ParticipantCapModal: FC<ParticipantCapModalProps> = ({ onConfirm, contactData }) => (
  <Modal onClose={onConfirm} padding="40px" top={10} titlePadding="0px" showHeader={false}>
    <ParticipantCapModalContainer>
      <DarkBlueHeaderL>Sorry… Your company has reached its cap.</DarkBlueHeaderL>
      <DarkBlueBodyS textAlign="center" padding={'1rem 0 3rem 0'}>
        Please contact your company program manager to increase your company’s allotment.
      </DarkBlueBodyS>
      <ContactProfile>
        <ImageWrap>
          <ContactImage src={contactData?.user?.profileImage || DEFAULT_PIC} />
        </ImageWrap>
        <DarkBlueHeaderM fontStyle="normal" whiteSpace="noWrap">
          {contactData?.user ? capitaliseUserFullName(contactData?.user) : ''}
        </DarkBlueHeaderM>
      </ContactProfile>
      <Button onClick={() => onConfirm()} variant="primary">
        Got it
      </Button>
    </ParticipantCapModalContainer>
  </Modal>
);

export default ParticipantCapModal;
