import React from 'react';

import {
  Container,
  StyledTooltip,
  TextWrapper,
  TooltipSubtext
} from '../DashboardTopConnectionActivities/PieChart/Tooltip/Styles';

import { LineToolTipData } from './DashboardStepsLineChart';

export interface LineTooltipProps {
  payload: LineToolTipData;
}

const LineToolTip: React.FC<LineTooltipProps> = ({ payload }) => {
  return (
    <Container>
      <StyledTooltip>
        <TextWrapper>
          <TooltipSubtext>{`${Math.ceil(payload.value)} Steps`}</TooltipSubtext>
        </TextWrapper>
      </StyledTooltip>
    </Container>
  );
};

export default LineToolTip;