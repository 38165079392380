import React, { FC, useRef, useState, useEffect } from 'react';
import { ErrorLabel, RequiredLabel } from '../';
import { dropdownArrow } from '../../../assets/icons/index';

import {
  Container,
  DropdownArrow,
  Label,
  Option,
  OptionLabel,
  OptionsWrapper,
  Select,
  SelectedOption,
  Wrapper,
  Icon
} from './Styles';

export interface SelectDropdownProps {
  label?: string;
  data: any[];
  value: number | string;
  error?: any;
  required?: boolean;
  valueProp: string;
  displayProp: string;
  placeholder: string;
  emailLabel?: boolean;
  onChange: (value: any) => void;
  border?: string;
  borderRadius?: string;
  color?: string;
  width?: string;
  margin?: string;
  icon?: string;
}

const SelectDropdown: FC<SelectDropdownProps> = ({
  data,
  value,
  error,
  label,
  required,
  valueProp,
  displayProp,
  placeholder,
  border = null,
  borderRadius = null,
  color = null,
  width = null,
  margin = null,
  emailLabel = false,
  icon,
  onChange
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [optionsOpen, setOptionsOpen] = useState(false);

  const getSelected = (): string => {
    const item = data?.find(x => x[valueProp] === value);
    return item ? item[displayProp] : placeholder;
  };

  const handleClickOutside = (event: Event): void => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setOptionsOpen(false);
    }
  };

  useEffect(() => {
    if (optionsOpen) document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [optionsOpen]);

  const GenerateOptions = (data: any) => {
    return data.map((d: any, i: number) => (
      <Option key={i} data-id={d[valueProp]} onClick={() => onChange(d[valueProp])}>
        <OptionLabel>{emailLabel ? `${d[displayProp]} - ${d.email}` : d[displayProp]}</OptionLabel>
      </Option>
    ));
  };

  return (
    <Container width={width} ref={dropdownRef}>
      {error ? <ErrorLabel>{error}</ErrorLabel> : ''}
      <Wrapper>
        {required ? <RequiredLabel /> : ''}
        {label && (
          <Label active={getSelected() !== placeholder} valid={!error}>
            {label}
          </Label>
        )}
        <Select
          active={getSelected() !== placeholder}
          onClick={() => setOptionsOpen(!optionsOpen)}
          valid={!error}
          border={border}
          borderRadius={borderRadius}
          margin={margin}
        >
          <SelectedOption color={color} selected={!!value}>
            {getSelected()}
          </SelectedOption>
          {icon ? <Icon src={icon} /> : <DropdownArrow alt="downArrow" src={dropdownArrow} />}
          {optionsOpen ? (
            <OptionsWrapper>
              <>
                <Option data-id={null} onClick={() => onChange(null)}>
                  <OptionLabel>{placeholder}</OptionLabel>
                </Option>
                {GenerateOptions(data)}
              </>
            </OptionsWrapper>
          ) : null}
        </Select>
      </Wrapper>
    </Container>
  );
};

export default SelectDropdown;
