import {
  EventListView,
  PagedResponse,
  CompanyRegistrationData,
  PaginationFilter
} from '../../../../Types';

export const SET_EVENTS = 'SET_EVENTS';
export const SET_PAGED_COMPANIES_IN_EVENT = 'SET_PAGED_COMPANIES_IN_EVENT';
export const SET_COMPANIES_IN_EVENT_FILTER = 'SET_COMPANIES_IN_EVENT_FILTER';

export interface EventState {
  events: PagedResponse<EventListView>;
  pagedCompaniesInEvent: PagedResponse<CompanyRegistrationData>;
  companiesInEventFilter: PaginationFilter;
}

export interface SetEventsAction {
  type: typeof SET_EVENTS;
  events: PagedResponse<EventListView>;
}

export interface SetPagedCompaniesInEventAction {
  type: typeof SET_PAGED_COMPANIES_IN_EVENT;
  pagedCompaniesInEvent: PagedResponse<CompanyRegistrationData>;
}

export interface SetCompaniesInEventFilterAction {
  type: typeof SET_COMPANIES_IN_EVENT_FILTER;
  companiesInEventFilter: PaginationFilter;
}

export type EventActionTypes =
  | SetEventsAction
  | SetPagedCompaniesInEventAction
  | SetCompaniesInEventFilterAction;
