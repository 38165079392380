import styled from 'styled-components/macro';
import { DarkBlueBodyS, DarkBlueHeaderL } from '../../../../Themes';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0;
  line-height: 3rem;
`;

export const BackBtn = styled.img`
  cursor: pointer;
  margin-right: 2rem;
  transform: scale(0.96);
  padding-top: 0.2rem;
`;

export const GenerateInfo = styled(DarkBlueBodyS)`
  line-height: 2rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  margin: 2rem 0 2.5rem 0;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormColumn = styled.div`
  flex: 1;
  &:nth-child(1) {
    padding-right: ${({ theme }) => theme.padding * 8}px;
  }
  &:nth-child(2) {
    padding-right: ${({ theme }) => theme.padding * 3}px;
  }
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Group = styled.div`
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const Heading = styled.h4`
  font-size: ${({ theme }) => theme.fonts.subHeading / 1.25}rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.primary.dark};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const Body = styled.p`
  font-size: ${({ theme }) => theme.fonts.body / 1.25}rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.primary.dark};
  margin-bottom: ${({ theme }) => theme.padding * 1.5}px;
`;

export const InlineInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InlineInput = styled.div`
  flex: 1;
  &:nth-child(1) {
    padding-right: ${({ theme }) => theme.padding / 2}px;
  }
  &:nth-child(2) {
    padding-left: ${({ theme }) => theme.padding / 2}px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.padding * 4}px;
`;

export const ModalTitle = styled(DarkBlueHeaderL)`
  font-size: 2.1rem;
`;
