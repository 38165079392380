import styled from 'styled-components/macro';

export const StyledLabel = styled.p`
  position: absolute;
  top: -1.8rem;
  right: 0.5rem;
  font-size: 1.1rem;
  font-style: italic;
  color: ${({ theme }) => theme.backgrounds.grey};
`;
