import { Reducer } from 'redux';
import {
  EventState,
  EventActionTypes,
  SET_EVENTS,
  SET_PAGED_COMPANIES_IN_EVENT,
  SET_COMPANIES_IN_EVENT_FILTER
} from './types';

export const initialEventState: EventState = {
  events: {
    results: [],
    count: 0
  },
  pagedCompaniesInEvent: {
    results: [],
    count: 0
  },
  companiesInEventFilter: {
    page: 1,
    rowsPerPage: 30,
    orderColumn: 'name',
    orderDirection: 'ASC'
  }
};

const EventReducer: Reducer<EventState, EventActionTypes> = (state = initialEventState, action) => {
  switch (action.type) {
    case SET_EVENTS:
      return {
        ...state,
        events: action.events
      };
    case SET_PAGED_COMPANIES_IN_EVENT:
      return {
        ...state,
        pagedCompaniesInEvent: action.pagedCompaniesInEvent
      };
    case SET_COMPANIES_IN_EVENT_FILTER:
      return {
        ...state,
        companiesInEventFilter: action.companiesInEventFilter
      };
    default:
      return state;
  }
};

export default EventReducer;
