import moment from 'moment-timezone';

import { timeOptions } from '../CONSTANTS';

export const getClientTimezone = () => moment.tz.guess();
export const getClientTimezoneOffset = () => new Date().getTimezoneOffset();

/**
 * Milliseconds from API response, converted to date for display
 * @param {number | string} millis
 * @returns string
 */
export const millisToDisplayDate = (millis: number | string, displayFormat = 'DD/MM/YYYY') => {
  const displayDate =
    typeof millis === 'string'
      ? moment
          .utc(parseInt(millis, 10))
          .local(true)
          .format(displayFormat)
      : moment
          .utc(millis)
          .local(true)
          .format(displayFormat);
  return displayDate;
};

/**
 * Converts a UTC ISO string to a date string for display
 * @param {string} utcDate
 * @param {string} displayFormat
 * @returns string
 */
export const utcDateToDisplayDate = (utcDate: string, displayFormat = 'DD/MM/YYYY') => {
  const displayDate = moment
    .utc(utcDate)
    .local()
    .format(displayFormat);

  return displayDate;
};

/**
 * Display date from UI, converted to milliseconds for API
 * @param {string} displayDate
 * @param {string} conversionFormat
 * @returns number
 */

export const displayDateToMillis = (displayDate: string, conversionFormat = 'DD-MM-YYYY') => {
  const millis = moment.utc(displayDate, conversionFormat).valueOf();
  return millis;
};

/**
 * Can be used to check isBefore, using boolean logic
 * @param {number | string} millis
 * @param {string} afterMillis
 * @param {string} granularity
 * @returns boolean
 */

export const isAfter = (
  millis: number | string,
  afterMillis: number | string,
  granularity: moment.unitOfTime.StartOf = 'second'
) => {
  const afterAsMoment =
    typeof afterMillis === 'string'
      ? moment(afterMillis, 'x').local()
      : moment(afterMillis).local();

  return typeof millis === 'string'
    ? moment(parseInt(millis, 10))
        .local()
        .isAfter(afterAsMoment, granularity)
    : moment(millis)
        .local()
        .isAfter(afterAsMoment, granularity);
};

/**
 * Converts UTC milliseconds to day & hour, and compares based on local time
 * @param {number | string} millis
 * @param {number} afterMillis
 * @param {string} granularity
 * @returns
 */

export const isAfterLocal = (
  millis: number | string,
  afterMillis: string,
  granularity: moment.unitOfTime.StartOf = 'second'
) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const afterLocalAsMoment = moment.utc(afterMillis).tz(userTimezone, true);

  const after =
    typeof millis === 'string'
      ? moment.tz(parseInt(millis, 10), userTimezone).isAfter(afterLocalAsMoment, granularity)
      : moment.tz(millis, userTimezone).isAfter(afterLocalAsMoment, granularity);
  return after;
};

/**
 * Inclusive of min/max dates
 * @param {number | string} millis
 * @param {string} dateMin
 * @param {string} dateMax
 * @param {string} granularity
 * @returns {boolean} boolean
 */

export const isBetween = (
  millis: number | string,
  dateMin: string,
  dateMax: string,
  granularity: moment.unitOfTime.StartOf = 'second'
) => {
  const dateMinAsMoment = moment(dateMin).local();
  const dateMaxAsMoment = moment(dateMax).local();
  const between =
    typeof millis === 'string'
      ? moment(parseInt(millis, 10))
          .local()
          .isBetween(dateMinAsMoment, dateMaxAsMoment, granularity, '[]')
      : moment(millis)
          .local()
          .isBetween(dateMinAsMoment, dateMaxAsMoment, granularity, '[]');
  return between;
};

/**
 * Returns time since millis given
 * @param {number | string} millis
 * @returns string
 */
export const timeFromNow = (millis: string) => {
  const timeAgo =
    typeof millis === 'string'
      ? moment(parseInt(millis, 10))
          .local()
          .fromNow()
      : moment(millis)
          .local()
          .fromNow();
  return timeAgo;
};

/**
 * @param {string} date - millis string
 * @param {string} time - HH:mm string
 * @param {string} timezone - timezone string
 * @returns {string} milliseconds string
 */
export const buildTimezoneMoment = (date: string, time: string, timezone: string) => {
  const directEventTZOffset = moment.tz(moment(), timezone).utcOffset();
  const newMoment = `${moment(date, 'x')
    .add(getClientTimezoneOffset(), 'minutes') // now the formatted value will = UTC YYYY-MM-DD
    .add(directEventTZOffset, 'minutes') // now the formatted value will = EventTZ YYYY-MM-DD
    .format('YYYY-MM-DD')}T${time}`;

  return moment
    .tz(newMoment, timezone || 'Etc/GMT')
    .valueOf()
    .toString();
};

// Used for time selector dropdown
export const findTimeOptionIndex = (timeValue: string) => {
  const foundItem = timeOptions.find(option => option.value === timeValue);
  return foundItem;
};
