import { apiRequest, apiFetch, objectToQueryString } from './Utils';

import { server } from '../../CONSTANTS';
import { APIResult, APIThunkResult, Event } from '../Types';

import { setEvent, setEventOptions } from '../Store/contexts/event/actions';

export function loadEvent(eventId: number): APIThunkResult<Event> {
  return apiRequest<Event>(async dispatch => {
    const result = await apiFetch<Event>({
      method: 'GET',
      url: `${server}/events/${eventId}`
    });
    dispatch(setEvent(result));
    return result;
  });
}
export type LoadEventFilter = {
  startDateLessThanOrEqual?: string;
  endDateLessThan?: string;
  startDateMoreThanOrEqual?: string;
  endDateMoreThan?: string;
};
export type LoadEventOptions = (filter?: LoadEventFilter) => Promise<APIResult<Event>>;
export function loadEventOptions(filter?: LoadEventFilter): APIThunkResult<Event[]> {
  return apiRequest<Event[]>(async dispatch => {
    const query = objectToQueryString(filter);
    const results = await apiFetch<Event[]>({
      method: 'GET',
      url: query ? `${server}/events?${query}` : `${server}/events`
    });
    dispatch(setEventOptions(results));
    return results;
  });
}
