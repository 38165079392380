import { PagedResponse, SDAFilter, SuspectDailyActivity } from '../../../Types';

export const SET_PAGED_SDA_LIST = 'SET_PAGED_SDA_LIST';
export const SET_SDA = 'SET_SDA';
export const SET_SDA_FILTER = 'SET_SDA_FILTER';

export interface SDAState {
  pagedSDAList: PagedResponse<SuspectDailyActivity>;
  SDA: SuspectDailyActivity;
  filter: SDAFilter;
}

export interface SetSDAListAction {
  type: typeof SET_PAGED_SDA_LIST;
  pagedSDAList: PagedResponse<SuspectDailyActivity>;
}

export interface SetSDAAction {
  type: typeof SET_SDA;
  SDA: SuspectDailyActivity;
}

export interface SetSDAFilter {
  type: typeof SET_SDA_FILTER;
  filter: SDAFilter;
}

export type SDAActionTypes = SetSDAListAction | SetSDAAction | SetSDAFilter;
