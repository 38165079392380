import React, { FC } from 'react';
import { Container, Wrapper, Icon, Text } from './Styles';

export interface NoRecordsFoundProps {
  icon: string;
  message: string;
}

const NoRecordsFound: FC<NoRecordsFoundProps> = ({ icon, message }) => {
  return (
    <Container>
      <Wrapper>
        <Icon src={icon} />
        <Text>{message}</Text>
      </Wrapper>
    </Container>
  );
};

export default NoRecordsFound;
