import React, { FC } from 'react';
import { alert } from '../../../assets/icons';
import { Wrapper, Icon, Text } from './Styles';

interface ErrorLabelProps {
  error?: any;
}

const ErrorLabel: FC<ErrorLabelProps> = ({ error = 'Sorry, this field is required.' }) => (
  <>
    <Wrapper>
      <Icon src={alert} />
      <Text>{error}</Text>
    </Wrapper>
  </>
);

export default ErrorLabel;
