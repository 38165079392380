import styled from 'styled-components/macro';
import { DarkBlueBodyS, DarkBlueHeaderM } from '../../../Themes';

export const CompanyName = styled(DarkBlueHeaderM)`
  text-align: right;
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: ${({ theme }) => theme.shadows.light};
`;

export const CompanyInfoWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.padding}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 5rem;
  height: 70%;
  margin-left: auto;
`;

export const BodyCopy = styled(DarkBlueBodyS)`
  font-size: calc(0.676rem + 0.3vw);
  line-height: calc(0.676rem + 0.7vw);
  white-space: nowrap;
`;

export const CompanyImage = styled.img`
  border-right: 1px solid ${({ theme }) => theme.borders.light};
  padding: 0 ${({ theme }) => theme.padding}px;
  height: 2.8rem;
  width: auto;
`;
