import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadOwnRanking,
  loadOwnTeamRank,
  loadPagedTeamRanks,
  loadParticipantRankings
} from '../../lib/Api/participant/LeaderBoard';

import { PageContent } from '../../Themes';
import { EmployeePageWrapper } from '../../components/UI';

import LeaderboardTable from './components/LeaderboardTable';

import { Title } from './Styles';
import { ApplicationState } from '../../lib/Store';
import { APIThunkDispatch } from '../../lib/Types';

const Leaderboard: FC = () => {
  const dispatch: APIThunkDispatch = useDispatch();
  const {
    pagedTeamRankFilter,
    activeTab,
    filterByCompany,
    individualFilter,
    userTeam
  } = useSelector(({ participantLeaderBoardState, participantTeamState }: ApplicationState) => ({
    pagedTeamRankFilter: participantLeaderBoardState.pagedTeamRankFilter,
    activeTab: participantLeaderBoardState.activeTab,
    filterByCompany: participantLeaderBoardState.filterByCompany,
    individualFilter: participantLeaderBoardState.individualFilter,
    userTeam: participantTeamState.team
  }));

  useEffect(() => {
    (async () => {
      if (activeTab !== 'Individual') {
        await dispatch(loadPagedTeamRanks(pagedTeamRankFilter, filterByCompany, false));
      }
    })();
  }, [activeTab, pagedTeamRankFilter, filterByCompany]);

  useEffect(() => {
    (async () => {
      if (activeTab !== 'Individual' && !!userTeam) {
        await dispatch(loadOwnTeamRank(filterByCompany, false));
      }
    })();
  }, [activeTab, filterByCompany, userTeam]);

  useEffect(() => {
    (async () => {
      if (activeTab !== 'Individual') return;
      await dispatch(loadParticipantRankings(individualFilter, filterByCompany, false));
    })();
  }, [activeTab, individualFilter, filterByCompany]);

  useEffect(() => {
    (async () => {
      if (activeTab !== 'Individual') return;
      await dispatch(loadOwnRanking(filterByCompany, false));
    })();
  }, [activeTab, filterByCompany]);

  return (
    <EmployeePageWrapper holdingPageTitle="Leaderboard">
      <PageContent>
        <Title>Leaderboards</Title>
        <LeaderboardTable />
      </PageContent>
    </EmployeePageWrapper>
  );
};

export default Leaderboard;
