import { apiRequest, apiFetch } from '../Utils';
import { updatePassword } from '../../Backend/Authentication';

import { server } from '../../../CONSTANTS';
import { APIResult, APIThunkResult, User } from '../../Types';
import { setNotification } from '../../../lib/Store/contexts/notification/actions';
import { setPermissions } from '../../../lib/Store/contexts/permissions/actions';

export type SetPassword = (
  currentPassword: string,
  newPassword: string
) => Promise<APIResult<User>>;
export const setPassword = (currentPassword: string, newPassword: string): APIThunkResult<User> => {
  return apiRequest<User>(async (dispatch, getState) => {
    try {
      await updatePassword(currentPassword, newPassword);
    } catch (e) {
      if (e.code === 'auth/wrong-password') {
        dispatch(
          setNotification({
            message: 'Incorrect password, please try again.',
            variant: 'warning'
          })
        );
      }
      throw e;
    }

    const result = await apiFetch<User>({
      method: 'POST',
      url: `${server}/auth/update-user-meta`,
      body: {
        mustUpdatePassword: false
      }
    });

    if (result) {
      const { code, permissions } = getState().permissionsState;
      dispatch(setPermissions(code, permissions, false));
    }

    return result;
  });
};
