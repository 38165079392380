import React, { FC, ChangeEvent, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import { remove } from '../../../../../assets/icons';
import {
  Wrapper,
  Row,
  Name,
  RemoveButton,
  RemoveIcon,
  RemoveText,
  Description,
  Count,
} from './Styles';

import { ActivityType } from '../../../../../lib/Types';
import { ApplicationState } from '../../../../../lib/Store';

interface AddSingleActivityProps {
  activityTypeId: number;
  activityType?: ActivityType;
  repetitions: string;
  updateActivity?: (e: ChangeEvent<HTMLInputElement>) => void;
  removeActivity?: (e: MouseEvent<HTMLDivElement>) => void;
}

const AddSingleActivity: FC<AddSingleActivityProps> = ({
  activityTypeId,
  updateActivity,
  removeActivity,
  repetitions,
}) => {
  const { activityTypes } = useSelector((state: ApplicationState) => state.userState.userEvent);
  const activityType = activityTypes.find(item => item.activityTypeId === activityTypeId);

  return (
    <>
      <Wrapper>
        <Row>
          <Name>{activityType?.name}</Name>
          <RemoveButton onClick={removeActivity}>
            <RemoveIcon src={remove} /> <RemoveText>Remove</RemoveText>
          </RemoveButton>
        </Row>
        <Row>
          <Description>{activityType?.description} (only use numbers).</Description>
          <Count
            name="repetitions"
            type="number"
            min="0"
            placeholder="0"
            onChange={updateActivity}
            value={repetitions || ''}
          />
        </Row>
      </Wrapper>
    </>
  );
};

export default AddSingleActivity;
