import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  color: black;
`;

export const Services = styled.div`
  text-align: center;
`;

export const Service = styled.button`
  padding: 10px 14px;
  background: ${({ theme }) => theme.backgrounds.white};
  box-shadow: 1px 1px 18px 0 rgba(152, 152, 152, 0.24);
  border-radius: 14px;
  width: 438px;
  height: 93px;
  text-align: center;
  margin-top: 19px;
  font-size: 20pt;
  border: none;
  max-width: 100%;
  cursor: pointer;
`;

export const Description = styled.p`
  font-family: Helvetica;
  font-size: 14px;
  color: ${({ theme }) => theme.primary.dark};
  letter-spacing: 0;
  text-align: center;
`;
