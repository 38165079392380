import { Reward, PaginationFilter, PagedResponse } from '../../../Types';

export const SET_REWARDS = 'SET_REWARDS';
export const SET_REWARD_OPTIONS = 'SET_REWARD_OPTIONS';
export const ADD_REWARD = 'ADD_REWARD';
export const UPDATE_REWARD = 'UPDATE_REWARD';
export const SET_REWARDS_FILTER = 'SET_REWARDS_FILTER';
export const SET_DELETING_REWARD = 'SET_DELETING_REWARD';
export const SET_EDITING_REWARD = 'SET_EDITING_REWARD';

export interface RewardsState {
  rewards: PagedResponse<Reward>;
  rewardsFilter: PaginationFilter;
  rewardOptions: Reward[];
  rewardIDForDeleting: number;
  deletingReward: boolean;
  rewardIDForEditing: number;
}

export interface SetRewardsFilter {
  type: typeof SET_REWARDS_FILTER;
  filter: PaginationFilter;
}

export interface SetRewardsAction {
  type: typeof SET_REWARDS;
  rewards: PagedResponse<Reward>;
}

export interface SetRewardOptionsAction {
  type: typeof SET_REWARD_OPTIONS;
  rewardOptions: Reward[];
}

export interface AddRewardAction {
  type: typeof ADD_REWARD;
  reward: Reward;
}

export interface UpdateRewardAction {
  type: typeof UPDATE_REWARD;
  rewardData: Partial<Reward>;
}

export interface SetDeletingRewardAction {
  type: typeof SET_DELETING_REWARD;
  deletingReward: boolean;
  rewardIDForDeleting: number;
}

export interface SetEditingRewardAction {
  type: typeof SET_EDITING_REWARD;
  rewardIDForEditing: number;
}

export type RewardsActionTypes =
  | SetRewardsAction
  | AddRewardAction
  | SetRewardOptionsAction
  | UpdateRewardAction
  | SetDeletingRewardAction
  | SetEditingRewardAction
  | SetRewardsFilter;
