import React, { FC } from 'react';
import moment from 'moment-timezone';

import { Group, Heading, InlineInputWrapper, InlineInput } from '../Styles';
import { DatePicker, SelectDropdown } from '../../../../../components/UI';
import { FormikFormComponent } from '../../../../../lib/Types';
import { EventSchema } from '../../../validators/eventSchema';
import { timeOptions } from '../../../../../CONSTANTS';
import { findTimeOptionIndex, getClientTimezoneOffset } from '../../../../../lib/TimeUtils';
import { clockIcon } from '../../../../../assets/icons';

interface EventDatesProps extends FormikFormComponent<EventSchema> {
  handleChangeTime: (nane: string, data: any) => void;
  onChangeDate: (name: string, value: Date | null) => void;
}

const EventDates: FC<EventDatesProps> = ({ values, errors, onChangeDate, handleChangeTime }) => {
  const clientTimezone = getClientTimezoneOffset();
  const eventTimezoneOffset = moment.tz(moment(), values.timezone).utcOffset();
  return (
    <Group>
      <Heading>Event Dates {values.timezone && `(${values.timezone})`}</Heading>
      <InlineInputWrapper>
        <InlineInput>
          <DatePicker
            required
            id="startDate"
            margin={'margin-top: 0.75rem'}
            date={
              values.startDate
                ? moment(values.startDate, 'x')
                    .add('minutes', clientTimezone)
                    .add('minutes', eventTimezoneOffset)
                    .toDate()
                : null
            }
            datePlaceholder="Start Date"
            error={errors.startDate}
            onChange={onChangeDate}
          />
          <DatePicker
            required
            id="endDate"
            margin={'margin-top: 2.5rem'}
            date={
              values.endDate
                ? moment(values.endDate, 'x')
                    .add('minutes', clientTimezone)
                    .add('minutes', eventTimezoneOffset)
                    .toDate()
                : null
            }
            datePlaceholder="End Date"
            error={errors.endDate}
            onChange={onChangeDate}
          />
        </InlineInput>
        <InlineInput>
          <SelectDropdown
            required
            margin={'margin-top: 0.75rem'}
            data={timeOptions}
            value={values.startDateTime && findTimeOptionIndex(values.startDateTime).id}
            valueProp={'id'}
            displayProp={'value'}
            placeholder={'Start Time'}
            error={errors.startDateTime}
            icon={clockIcon}
            onChange={val => handleChangeTime('startDateTime', val)}
          />
          <SelectDropdown
            required
            margin={'margin-top: 2.5rem'}
            data={timeOptions}
            value={values.endDateTime && findTimeOptionIndex(values.endDateTime).id}
            valueProp={'id'}
            displayProp={'value'}
            placeholder={'End Time'}
            error={errors.endDateTime}
            icon={clockIcon}
            onChange={val => handleChangeTime('endDateTime', val)}
          />
        </InlineInput>
      </InlineInputWrapper>
    </Group>
  );
};

export default EventDates;
