import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { Modal } from '../../../components/UI';
import ModalButton from '../../../components/UI/Button/ModalButton';
import { eventGuy } from '../../../assets/icons';
import { ApplicationState } from '../../../lib/Store';
import { RewardsState } from '../../../lib/Store/contexts/rewards/types';

import { ButtonWrapper, ConfirmationText } from './Styles';
import { DarkBlueHeaderL, RedText } from '../../../Themes/TextThemes';

interface DeleteRewardProps {
  onCancel: () => void;
  onDelete: (rewardIdToDelete: number) => void;
}

const DeleteReward: FC<DeleteRewardProps> = ({ onCancel, onDelete }) => {
  const { rewards, rewardIDForDeleting } = useSelector<ApplicationState, RewardsState>(
    state => state.rewardsState
  );

  const reward = rewards.results.find(x => x.rewardId === rewardIDForDeleting);

  return (
    <Modal top={5} title="Delete Reward" icon={eventGuy} width={75} left={12.5}>
      <DarkBlueHeaderL>
        <RedText>Warning! This action will remove records.</RedText>
      </DarkBlueHeaderL>

      <ConfirmationText>
        Are you sure you wish to delete &apos;{reward.name}&apos;?
      </ConfirmationText>

      <ButtonWrapper>
        <ModalButton onClick={onCancel} type="button" variant={'secondary'}>
          Cancel
        </ModalButton>
        <ModalButton
          type="submit"
          variant={'primary'}
          onClick={() => onDelete(rewardIDForDeleting)}
        >
          Delete
        </ModalButton>
      </ButtonWrapper>
    </Modal>
  );
};

export default DeleteReward;
