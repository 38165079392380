import React, { FC } from 'react';

import { ImageContainer, StyledImage } from './Styles';

const PostImage: FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <ImageContainer>
    <StyledImage src={src} alt={alt} />
  </ImageContainer>
);

export default PostImage;
