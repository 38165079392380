import styled from 'styled-components/macro';

interface CountProps {
  completed?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 490px) {
    flex-direction: column;
  }
`;

export const MascotIcon = styled.img`
  min-width: fit-content;
  margin-left: 1.5rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 1.5rem;

  @media (max-width: 490px) {
    margin-left: 0;
  }
`;

export const CountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  padding: 0 1rem;
`;

export const Count = styled.div<CountProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50px;
  border-radius: 11px;
  background-color: #f5f5f5;
  border: 1px solid #d6d9e0;

  -webkit-box-shadow: inset 0px 1px 10px 0px rgba(163, 163, 163, 0.5);
  box-shadow: inset 0px 1px 10px 0px rgba(163, 163, 163, 0.5);

  &:nth-child(1) {
    margin-right: 1rem;
  }

  color: ${({ theme, completed }) => (completed ? theme.status.danger : theme.primary.lightBlue)};
  font-weight: ${({ theme }) => theme.fontWeights.lightBold};
  font-size: 20px;
  text-align: center;

  @media (max-width: 1225px) and (min-width: 1025px) {
    font-size: 14px;
  }

  @media (max-width: 505px) {
    font-size: 12px;
  }
`;
