import {
  RelativePercentTopFiveActivity,
  ConnectionPointsByPostType,
  TeamMembersPoints,
  DashBoardData
} from '../../../../Types';

export const SET_TOP_ACTIVITIES = 'SET_TOP_ACTIVITIES';
export const SET_TEAM_MEMBERS_POINTS = 'SET_TEAM_MEMBERS_POINTS';
export const SET_TOP_CONNECTION = 'SET_TOP_CONNECTION';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';

export interface DashboardState {
  topActivities: RelativePercentTopFiveActivity[];
  topConnection: ConnectionPointsByPostType[];
  teamMembersPoints: TeamMembersPoints[];
  dashBoardData: DashBoardData;
}

export interface SetTopActivitiesAction {
  type: typeof SET_TOP_ACTIVITIES;
  topActivities: RelativePercentTopFiveActivity[];
}

export interface SetTeamMembersPointsAction {
  type: typeof SET_TEAM_MEMBERS_POINTS;
  teamMembersPoints: TeamMembersPoints[];
}

export interface SetTopConnectionAction {
  type: typeof SET_TOP_CONNECTION;
  topConnection: ConnectionPointsByPostType[];
}

export interface SetDashBoardDataAction {
  type: typeof SET_DASHBOARD_DATA;
  dashBoardData: DashBoardData;
}

export type DashboardActionTypes =
  | SetTopActivitiesAction
  | SetTeamMembersPointsAction
  | SetTopConnectionAction
  | SetDashBoardDataAction;
