import React, { FC } from 'react';
import { ReplaceSchema } from '../../../validators/replaceSchema';
import { FormikForm, ParticipantListView } from '../../../../../lib/Types';
import NewUser from './formComponents/NewUser';
import { StyledForm } from '../ModalFormContainer/Styles';

interface ReplaceNewUserFormProps extends FormikForm<ReplaceSchema> {
  participant: ParticipantListView;
  handleDropdownChange: (name: string, value: any) => void;
  onCancel: () => void;
}

const ReplaceNewUserForm: FC<ReplaceNewUserFormProps> = ({ onCancel, handleSubmit, ...props }) => (
  <>
    <StyledForm onSubmit={handleSubmit}>
      <NewUser {...props} />
    </StyledForm>
  </>
);

export default ReplaceNewUserForm;
