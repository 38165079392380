import { YourSteps, TeamStats } from '../../../Types';

export const SET_YOUR_STEPS = 'SET_YOUR_STEPS';
export const SET_TEAM_STATS = 'SET_TEAM_STATS';

export interface StatsState {
  yourSteps: YourSteps[];
  teamStats: TeamStats[];
  myTeamStats: TeamStats[];
}

export interface SetYourStepsAction {
  type: typeof SET_YOUR_STEPS;
  yourSteps: YourSteps[];
}

export interface SetTeamStatsAction {
  type: typeof SET_TEAM_STATS;
  teamStats: TeamStats[];
}

export type StatsActionTypes = SetYourStepsAction | SetTeamStatsAction;
