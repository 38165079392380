export const SET_TERMS_ACCEPTED = 'SET_TERMS_ACCEPTED';
export const SET_TERMS_NOT_ACCEPTED = 'SET_TERMS_NOT_ACCEPTED';

export interface TermsAndConditionsState {
  accepted: boolean;
}

export interface SetTermsAcceptedAction {
  type: typeof SET_TERMS_ACCEPTED;
}

export interface SetTermsNotAcceptedAction {
  type: typeof SET_TERMS_NOT_ACCEPTED;
}

export type TermsAndConditionsActionTypes = SetTermsAcceptedAction | SetTermsNotAcceptedAction;
