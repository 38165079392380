import { NotificationActionTypes, SET_NOTIFICATION, DISMISS_NOTIFICATION } from './types';
import { NotificationOpts } from '../../../Types';

export const setNotification = (options: NotificationOpts): NotificationActionTypes => ({
  type: SET_NOTIFICATION,
  options
});

export const dismissNotification = (): NotificationActionTypes => ({
  type: DISMISS_NOTIFICATION
});
