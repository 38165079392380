import React, { FC } from 'react';
import { Group, Heading, ColumnWrapper, Column, TeamDataHeading, TeamDataValue } from '../Styles';
import { FormikFormComponent, Team, ParticipantListView } from '../../../../../lib/Types';
import { ParticipantSchema } from '../../../validators/participantSchema';

interface TeamDetailsProps extends FormikFormComponent<ParticipantSchema> {
  participantTeam: Team;
  participant: ParticipantListView;
}

const TeamDetails: FC<TeamDetailsProps> = ({ participant, participantTeam }) => (
  <Group>
    <Heading>Team Details</Heading>
    <ColumnWrapper>
      <Column>
        <TeamDataHeading>Team Joined</TeamDataHeading>
        <TeamDataValue>{participantTeam && participantTeam.name}</TeamDataValue>
      </Column>
      <Column>
        <TeamDataHeading>Team Captain</TeamDataHeading>
        <TeamDataValue>{participant.teamCaptain ? 'Yes' : 'No'}</TeamDataValue>
      </Column>
    </ColumnWrapper>
  </Group>
);

export default TeamDetails;
