import styled, { keyframes } from 'styled-components/macro';

interface VideoProps {
  paused?: boolean;
}

export const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0.5
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  min-height: 28rem;
  max-height: 60rem;
  position: relative;
  background-color: ${({ theme }) => theme.backgrounds.white};
  border-radius: 2rem;

  div {
    line-height: 0;
    width: 100%;
    min-height: 28rem;
    max-height: 60rem;

    div {
      max-height: 60rem;
      padding-top: 70% !important;
      iframe {
        width: max-content;
        min-height: inherit;
        max-height: 60rem;
      }
    }
  }
`;

export const PlayButtonAnimation = styled.div<VideoProps>`
  content: '';
  position: absolute;
  width: 6rem !important;
  height: 6rem !important;
  min-width: 6rem !important;
  min-height: 6rem !important;
  background: grey;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
  transform: scale(1);
  animation: ${fadeOut} 0.6s ease 0s forwards;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 14px solid white;
    top: 0;
    bottom: 0;
    left: 4px;
    right: 0;
    margin: auto;
  }
`;

export const PauseButtonAnimation = styled.div<VideoProps>`
  content: '';
  position: absolute;
  width: 6rem !important;
  height: 6rem !important;
  min-width: 6rem !important;
  min-height: 6rem !important;
  background: grey;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
  transform: scale(1);
  animation: ${fadeOut} 0.6s ease 0s forwards;

  &::after {
    content: '';
    position: absolute;
    width: 7px;
    height: 35%;
    background: white;
    top: 0;
    bottom: 0;
    left: 12px;
    right: 0;
    margin: auto;
  }

  &::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 35%;
    background: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 12px;
    margin: auto;
  }
`;

export const PlayButton = styled.div<VideoProps>`
  content: '';
  position: absolute;
  width: 6rem !important;
  height: 6rem !important;
  min-width: 6rem !important;
  min-height: 6rem !important;
  background: ${({ theme }) => theme.gradients.blue};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  border-radius: 50%;
  cursor: pointer;
  display: ${props => (props.paused ? 'block' : 'none')};

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 14px solid white;
    top: 0;
    bottom: 0;
    left: 4px;
    right: 0;
    margin: auto;
  }
`;
export const ProcessingVideoContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.padding * 1.5}px;
  border: ${({ theme }) => theme.borders.input.focused};
  border-radius: 0.35rem;
  padding: 0.3rem 0.3rem 0.3rem 1.5rem;
  font-size: calc(${({ theme }) => theme.fontScale.sm} * 1.1);
  color: ${({ theme }) => theme.primary.dark};
`;

export const ProcessingVideoIcon = styled.img`
  width: 5rem;
  height: 5rem;
  margin: ${({ theme }) => theme.padding}px;
`;
