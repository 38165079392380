import styled from 'styled-components/macro';

export const GlobalWrap = styled.div`
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  display: flex;
  overflow-x: hidden;
  padding: 3rem ${({ theme }) => theme.pad.xsm * 1.5}% 0;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    padding: ${({ theme }) => theme.pad.sm}%;
    z-index: -1;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    padding: 0;
  }
`;

export const PageWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.device.tablet}px) {
    flex-direction: row;
  }
`;

export const SideBarWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: min-content;
  margin-right: 3%;
`;

export const MainWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: calc(100% - 20rem);

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    padding-left: 0;
    width: auto;
    padding-right: 0;
  }
`;

export const HeaderWrap = styled.div`
  width: 100%;
  align-items: flex-start;
  top: 0;
  display: flex;
  padding-bottom: 1.5rem;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    height: 6rem;
  }
`;

export const ContentWrap = styled.div`
  width: 100%;
  height: auto;
`;

export const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  justify-content: center;
  padding: ${({ theme }) => theme.pad.xsm}%;
  bottom: 0;
`;

export const CopyrightText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fonts.body}rem;
  color: ${({ theme }) => theme.primary.dark};
  opacity: ${({ theme }) => theme.opacity.half};
`;
