import { StatsActionTypes, SET_YOUR_STEPS, SET_TEAM_STATS } from './types';
import { YourSteps, TeamStats } from '../../../Types';

export const setYourSteps = (yourSteps: YourSteps[]): StatsActionTypes => ({
  type: SET_YOUR_STEPS,
  yourSteps
});

export const setTeamStats = (teamStats: TeamStats[]): StatsActionTypes => ({
  type: SET_TEAM_STATS,
  teamStats
});
