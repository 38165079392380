import React, { FC } from 'react';

import { ExternalPageWrapper } from '../../components/UI/';
import PictureForm from '../UserOnboarding/components/PictureForm';

export interface OnboardingPictureFormProps {}

const OnboardingPictureForm: FC<OnboardingPictureFormProps> = () => (
  <ExternalPageWrapper header={'Thanks, your account has been created'}>
    <PictureForm />
  </ExternalPageWrapper>
);

export default OnboardingPictureForm;
