import React from 'react';
import styled from 'styled-components/macro';
import { pfpPlaceholder } from '../../../../assets/img';

interface ImageProps {
  image: string;
}

const StyledProfileImage = styled.div<ImageProps>`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 2rem;
  box-shadow: none;
  border: none;
  background: ${props => `url('${props.image}') no-repeat`};
  background-position: center;
  background-size: cover;

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
    position: absolute;
  }
`;

export const CommentProfileImageContainer = ({ src, alt }) => {
  const fallbackSource = src || pfpPlaceholder;
  return <StyledProfileImage image={fallbackSource} />;
};
