import styled from 'styled-components/macro';
import { theme } from '../../../../../theme';

interface IconProps {
  flex?: number;
  height?: string;
}

interface SubmitButtonProps {
  disabled?: boolean;
}

export const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  color: ${theme.primary.white};
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 101;
  right: 0;
  width: 40rem;
  height: 100%;
  background: rgb(255, 73, 127);
  background: -moz-linear-gradient(
    183deg,
    rgba(255, 73, 127, 1) 0%,
    rgba(255, 109, 125, 1) 33%,
    rgba(255, 181, 120, 1) 100%
  );
  background: -webkit-linear-gradient(
    183deg,
    rgba(255, 73, 127, 1) 0%,
    rgba(255, 109, 125, 1) 33%,
    rgba(255, 181, 120, 1) 100%
  );
  background: linear-gradient(
    183deg,
    rgba(255, 73, 127, 1) 0%,
    rgba(255, 109, 125, 1) 33%,
    rgba(255, 181, 120, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff497f",endColorstr="#ffb578",GradientType=1);

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    position: fixed;
    width: 100%;
  }
`;

export const FormControl = styled.div`
  &:not(:last-child) {
    border-bottom: solid 1px rgba(234, 58, 82, 0.7);
    padding: 10px 0;
  }
  &:last-child {
    padding: 40px 0 30px;
  }
  &:first-child {
    border-top: solid 1px rgba(234, 58, 82, 0.7);
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5%;
`;

export const PlusActivityHeading = styled.p`
  font-family: ${theme.fontFamily.heading};
  font-size: 20px;
  color: ${theme.primary.white};
  letter-spacing: 0.23px;
  text-align: center;
  margin-bottom: 15px;
  width: 70%;
`;

export const PlusIcon = styled.div`
  background-image: url(${require('../../../../../assets/icons/plus.svg')});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 1.3rem;
  height: 1.5rem;
  cursor: pointer;
  margin: 0 2rem 0 0;
`;

export const PlusActivity = styled.button`
  border: 1px solid ${theme.primary.white};
  border-radius: 25px;
  font-family: ${theme.fontFamily.heading};
  font-weight: ${theme.fontWeights.light};
  font-size: 18px;
  color: ${theme.primary.white};
  letter-spacing: 0.2px;
  background: transparent;
  padding: 12px 35px 14px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  &:not(:disabled) {
    cursor: pointer;
    &:hover > ${PlusIcon} {
      background-image: url(${require('../../../../../assets/icons/plus-org.svg')});
      font-weight: ${theme.fontWeights.regular};
    }
    &:hover {
      background-color: #ffffff;
      color: #f08e83;
    }
  }
`;

export const Icon = styled.img<IconProps>`
  flex: ${({ flex }) => flex};
  height: ${({ height }) => height};
  cursor: pointer;
`;

export const PlusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 22px 0;
`;

export const SubmitButton = styled.button<SubmitButtonProps>`
  background: ${theme.gradients.blue};
  border-radius: 78px;
  font-family: ${theme.fontFamily.heading};
  border: none;
  font-size: 16px;
  color: ${({ theme }) => theme.backgrounds.white};
  letter-spacing: 0.2px;
  text-align: center;
  margin: 0 auto;
  display: block;
  line-height: 14px;
  width: 90%;
  padding: 13px 0;
  border: 0;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 0 0 0 1.3rem;
  margin: 0;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 6.5rem 1.8rem 2.4rem;
  white-space: nowrap;
  display: flex;
  background: transparent;

  @media (max-width: ${theme.device.tablet}px) {
    padding: 2rem 4rem 1.8rem 2.4rem;
  }
  @media (max-width: ${theme.device.tablet}px) {
    padding: 2rem 3rem 1.8rem 2.4rem;
  }
`;

export const StopWatchIcon = styled.img`
  height: calc(40px);
  object-fit: contain;
  margin: 0 1rem 0 auto;
`;

export const UpCarat = styled.img`
  height: 0.95rem;
  object-fit: contain;
  margin: 0 2rem 0 0;
  transform: rotate(180deg);
`;

export const ActivityHeader = styled.div`
  font-family: ${theme.fontFamily.heading};
  font-weight: ${theme.fontWeights.semibold};
  font-size: calc(1.3rem + 0.3vw);
  font-style: italic;
  color: ${theme.primary.white};
  cursor: pointer;
  margin-right: auto;
`;

export const DaySliderWrap = styled.div`
  padding: 1.5rem 0;
`;

export const Spacer = styled.div`
  background-color: rgba(234, 58, 82, 0.7);
  width: 100%;
  height: 1px;
  margin: 1.5rem 0;
`;

export const AwaitingApprovalBanner = styled.div`
  background-color: ${({ theme }) => theme.primary.yellow};
  color: black;
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 0 2.5rem;
  border-radius: 8px;
`;
