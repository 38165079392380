import React, { FC, useState } from 'react';
import { TooltipContainer, TooltipDiv } from './Styles';
import useWindowSize from '../../../hooks/useWindowSize';

interface TooltipProps {
  innerText: string;
  tooltipText: string;
  tooltipLocation?: 'TOP' | 'LEFT' | 'BOTTOM' | 'RIGHT';
  breakpoint?: {
    location: 'TOP' | 'LEFT' | 'BOTTOM' | 'RIGHT';
    size: number;
  };
}

const Tooltip: FC<TooltipProps> = ({ innerText, tooltipText, tooltipLocation, breakpoint }) => {
  const [showTooltip, setShowTooltip] = useState(false),
    [windowWidth] = useWindowSize();

  return (
    <TooltipContainer onClick={() => setShowTooltip(!showTooltip)}>
      {innerText}
      {showTooltip && (
        <TooltipDiv
          tooltipLocation={windowWidth < breakpoint.size ? breakpoint.location : tooltipLocation}
        >
          {tooltipText}
        </TooltipDiv>
      )}
    </TooltipContainer>
  );
};

export default Tooltip;
