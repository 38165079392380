import React, { FC, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import Dropzone from 'react-dropzone';
import { dateSingleInputPhrases } from '@datepicker-react/styled';
import moment from 'moment-timezone';

import { postTypeIcon } from '../../../lib/Utils';
import { buildTimezoneMoment, getClientTimezoneOffset } from '../../../lib/TimeUtils';
import { findTimeOptionIndex } from '../../../lib/TimeUtils';
import { resizeImage } from '../../../lib/Utils';

import { SelectDropdown } from '../../../components/UI';

import {
  commentImage,
  commentVideo,
  pencilWhite,
  closeWhite,
  audioFileIcon
} from '../../../assets/icons';
import {
  SendingToCard,
  SendingToHeader,
  WritePostCard,
  WritePostMessageInput,
  WritePostTitleInput,
  WritePostSubmitButton,
  WritePostMedia,
  WritePostImage,
  WritePostProfileImage,
  WritePostIconContainer,
  WritePostBody,
  WritePostRadioGroup,
  WritePostChallengeTextInput,
  WritePostLinkInput,
  WritePostEventDayInput,
  WritePostEventDatePicker,
  WritePostHeader,
  WritePostSubmitArea,
  WritePostOptions,
  WritePostRewardWrapper,
  WritePostChallengeTypeWrapper,
  WritePostMediaError,
  WritePostMediaButton,
  WritePostVideoContainer,
  WritePostVideoRow,
  WritePostVideoIcon,
  WritePostMediaControl,
  WritePostImageRow,
  WritePostImageContainer,
  PinPostLabel,
  PinnedDate,
  SubText
} from './Styles';

import {
  MAX_IMAGE_SIZE_LABEL,
  MAX_VIDEO_SIZE_LABEL,
  MAX_VIDEO_SIZE,
  MAX_IMAGE_SIZE,
  timeOptions
} from '../../../CONSTANTS';

import { SpecialPostMetaData } from '../CreatePost';
import { Post, PostType, SocialChallengeType, Reward, Event } from '../../../lib/Types';
import { ApplicationState } from '../../../lib/Store';

interface WritePostProps {
  postMeta: SpecialPostMetaData;
  rewardOptions: Reward[];
  companyAdminPost: boolean;
  currentCompanyEvent: Event;
  onSubmit: (data: Partial<Post>) => Promise<boolean>;
  back: () => void;
}

enum SendDate {
  EventDay = 0,
  Date = 1,
  Now = 2
}

const challengeTypeOptions: { value: SocialChallengeType; label: string }[] = [
  { value: SocialChallengeType.Comment, label: 'Post a comment' },
  { value: SocialChallengeType.Image, label: 'Post a comment with an image' },
  { value: SocialChallengeType.Video, label: 'Post a comment with a video' }
];

const WritePost: React.FC<WritePostProps> = ({
  postMeta,
  rewardOptions,
  companyAdminPost,
  currentCompanyEvent,
  onSubmit,
  back
}) => {
  const { user } = useSelector(({ userState }: ApplicationState) => ({
    user: userState.userData
  }));
  const [challengePost, setChallengePost] = useState(!companyAdminPost);
  const [sendAt, setSendAt] = useState(SendDate.Now);
  const [loading, setLoading] = useState(false);
  let openDropZone = useMemo(() => null, []);

  const clientTimezone = getClientTimezoneOffset();
  const eventTimezoneOffset = moment
    .tz(moment(), companyAdminPost ? currentCompanyEvent.timezone : postMeta.event.timezone)
    .utcOffset();

  const postHeader = (type: PostType) =>
    type === 'General' || !type ? `${user.firstName} ${user.lastName}` : type;

  return (
    <Formik
      initialValues={{
        title: '',
        message: '',
        link: '',
        challengeText: '',
        date: moment()
          .valueOf()
          .toString(),
        dateTime: '00:00',
        eventDay: 1,
        pinUntil: null,
        pinUntilTime: '00:00',
        rewardId: null,
        socialChallengeType: SocialChallengeType.Comment,
        type: postMeta?.type || null,
        image: null,
        video: null,
        audio: null
      }}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const filteredValues = Object.keys(values)
          .map(key => (values[key] ? { [key]: values[key] } : {}))
          .reduce((a, b) => ({ ...a, ...b }), {});
        if (values.type === PostType.General) {
          delete filteredValues.eventDay;
          delete filteredValues.date;
          delete filteredValues.challengeText;
          delete filteredValues.rewardId;
          delete filteredValues.socialChallengeType;
        } else {
          delete filteredValues.title;
          switch (sendAt) {
            case SendDate.EventDay:
              delete filteredValues.date;
              delete filteredValues.dateTime;
              delete filteredValues.pinUntil;
              delete filteredValues.pinUntilTime;
              break;
            case SendDate.Date:
              delete filteredValues.eventDay;
              delete filteredValues.pinUntil;
              delete filteredValues.pinUntilTime;
              break;
            case SendDate.Now:
              delete filteredValues.date;
              delete filteredValues.dateTime;
              delete filteredValues.eventDay;
              break;
            default:
              break;
          }
          if (!challengePost) {
            delete filteredValues.challengeText;
            delete filteredValues.rewardId;
            delete filteredValues.socialChallengeType;
          }
        }
        !filteredValues.image && delete filteredValues.image;

        if (filteredValues.pinUntil) {
          filteredValues.pinUntil = buildTimezoneMoment(
            filteredValues.pinUntil,
            filteredValues.pinUntilTime,
            postMeta.event.timezone
          );
          delete filteredValues.pinUntilTime;
        }

        if (filteredValues.date) {
          filteredValues.date = buildTimezoneMoment(
            filteredValues.date,
            filteredValues.dateTime,
            postMeta.event.timezone
          );
          delete filteredValues.dateTime;
        }

        const success = await onSubmit(filteredValues);
        setLoading(false);
        if (success) resetForm();
      }}
      validate={({
        title,
        message,
        challengeText,
        socialChallengeType,
        rewardId,
        date,
        eventDay
      }) => {
        const errors: { [key: string]: string } = {};
        if (!message) errors.message = 'Message required';
        if (postMeta?.type === PostType.General || !postMeta?.type) {
          if (!title) errors.title = 'Title required';
        } else {
          if (challengePost && !challengeText) errors.challengeText = 'Challenge text required';
          if (challengePost && !socialChallengeType)
            errors.socialChallengeType = 'Challenge type required';
          if (challengePost && !rewardId) errors.rewardId = 'Reward required';
          if (sendAt === SendDate.Date && !date) errors.date = 'Date required';
          if (sendAt === SendDate.EventDay) {
            if (!eventDay) errors.eventDay = 'Event day required';
            if (eventDay < 1) errors.eventDay = 'Invalid value';
          }
        }
        return errors;
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
    >
      {({ handleSubmit, handleChange, setFieldError, setFieldValue, values, errors }) => (
        <Form onSubmit={handleSubmit}>
          {!companyAdminPost && (
            <SendingToCard>
              <SendingToHeader>
                SENDING TO:
                <span>
                  <b>{postMeta.companyIds.length}</b>{' '}
                  {postMeta.companyIds.length > 1 ? 'companies' : 'company'} in{' '}
                  <b>{postMeta.event.name} </b>
                  will receive this post.
                </span>
              </SendingToHeader>
            </SendingToCard>
          )}
          <WritePostCard>
            <WritePostIconContainer>
              <WritePostProfileImage
                image={postMeta?.type ? postTypeIcon(postMeta?.type) : user.profileImage}
              />
            </WritePostIconContainer>
            <WritePostBody>
              <WritePostHeader type={postMeta?.type}>{postHeader(postMeta?.type)}</WritePostHeader>
              {(postMeta?.type === PostType.General || companyAdminPost) && (
                <WritePostTitleInput
                  required
                  hideLabel
                  id="title"
                  name="title"
                  type="text"
                  label="Add a title"
                  value={values.title}
                  error={errors.title}
                  onChange={handleChange}
                  margin={'0 0 1.5rem 0'}
                />
              )}
              {values.image && (
                <WritePostImageContainer>
                  <WritePostImage src={values.image} alt="Post image" />
                  <WritePostImageRow reverse>
                    <WritePostMediaControl onClick={openDropZone}>
                      <img src={pencilWhite} />
                    </WritePostMediaControl>
                    <WritePostMediaControl onClick={() => setFieldValue('image', null)}>
                      <img src={closeWhite} />
                    </WritePostMediaControl>
                  </WritePostImageRow>
                </WritePostImageContainer>
              )}
              {values.video && (
                <WritePostVideoContainer>
                  <WritePostVideoRow>
                    <div>
                      <WritePostVideoIcon src={commentVideo} />
                    </div>
                    <div>
                      <p>{(values.video as File).name}</p>
                      <p>{((values.video as File).size / 1000000).toFixed(2)}MB</p>
                    </div>
                  </WritePostVideoRow>
                  <WritePostVideoRow reverse>
                    <WritePostMediaControl onClick={openDropZone}>
                      <img src={pencilWhite} />
                    </WritePostMediaControl>
                    <WritePostMediaControl onClick={() => setFieldValue('video', null)}>
                      <img src={closeWhite} />
                    </WritePostMediaControl>
                  </WritePostVideoRow>
                </WritePostVideoContainer>
              )}
              {values.audio && (
                <WritePostVideoContainer>
                  <WritePostVideoRow>
                    <div>
                      <WritePostVideoIcon src={audioFileIcon} />
                    </div>
                    <div>
                      <p>{(values.audio as File).name}</p>
                      <p>{((values.audio as File).size / 1000000).toFixed(2)}MB</p>
                    </div>
                  </WritePostVideoRow>
                  <WritePostVideoRow reverse>
                    <WritePostMediaControl onClick={openDropZone}>
                      <img src={pencilWhite} />
                    </WritePostMediaControl>
                    <WritePostMediaControl onClick={() => setFieldValue('video', null)}>
                      <img src={closeWhite} />
                    </WritePostMediaControl>
                  </WritePostVideoRow>
                </WritePostVideoContainer>
              )}
              <WritePostMessageInput
                required
                textArea
                hideLabel
                id="message"
                name="message"
                label="What would you like to say?"
                value={values.message}
                error={errors.message}
                onChange={handleChange}
                margin={'0rem 0 0rem'}
              />
              <WritePostLinkInput
                hideLabel
                id="link"
                name="link"
                type="url"
                label="Add a web link (optional)"
                value={values.link}
                onChange={handleChange}
                margin={'0rem 0 0.5rem'}
              />
              <Dropzone
                noClick
                noKeyboard
                multiple={false}
                accept="image/jpeg,image/png,video/*,audio/*"
                maxSize={MAX_VIDEO_SIZE}
                onDropAccepted={async (acceptedFiles: File[]) => {
                  const file = acceptedFiles[0];
                  const type = file.type.split('/')[0];
                  const isVideo = type === 'video';
                  const isImage = type === 'image';
                  const isAudio = type === 'audio';
                  if (isImage && file.size > MAX_IMAGE_SIZE)
                    return setFieldError('image', `Image must be JPG/PNG <${MAX_IMAGE_SIZE_LABEL}`);
                  if (!isImage && !isVideo && !isAudio)
                    return setFieldError('image', 'File must be jpg/png, video or audio');
                  if (file) {
                    if (isVideo) {
                      setFieldValue('video', file);
                      setFieldValue('image', null);
                      setFieldValue('audio', null);
                    } else if (isAudio) {
                      setFieldValue('audio', file);
                      setFieldValue('video', null);
                      setFieldValue('image', null);
                    } else {
                      const image = await resizeImage(file);
                      setFieldValue('image', image);
                      setFieldValue('video', null);
                      setFieldValue('audio', null);
                    }
                  }
                }}
                onDropRejected={(rejectedFiles: File[]) => {
                  const file = rejectedFiles[0];
                  const isVideo = file.type.split('/')[0] === 'video';
                  if (isVideo) setFieldError('video', `Video must be <${MAX_VIDEO_SIZE_LABEL}`);
                  else setFieldError('image', `Image must be JPG/PNG <${MAX_IMAGE_SIZE_LABEL}`);
                }}
              >
                {({ getRootProps, getInputProps, open }) => {
                  if (!openDropZone && !!open) openDropZone = open;
                  return (
                    <>
                      {errors.image && <WritePostMediaError>{errors.image}</WritePostMediaError>}
                      {errors.video && <WritePostMediaError>{errors.video}</WritePostMediaError>}
                      <WritePostMedia {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <WritePostMediaButton
                          role="button"
                          disabled={!!values.video}
                          src={commentImage}
                          alt="Upload image"
                          onClick={open}
                        />
                        <WritePostMediaButton
                          role="button"
                          disabled={!!values.image}
                          src={commentVideo}
                          alt="Upload Video"
                          onClick={open}
                        />
                        <WritePostMediaButton
                          role="button"
                          disabled={!!values.audio}
                          src={audioFileIcon}
                          alt="Upload Audio"
                          onClick={open}
                        />
                      </WritePostMedia>
                    </>
                  );
                }}
              </Dropzone>
              {postMeta?.type !== PostType.General && !companyAdminPost && (
                <>
                  <WritePostOptions>
                    <WritePostRadioGroup
                      horizontal
                      valueKey="challengePost"
                      options={[
                        {
                          label: 'Lifehack (With Challenge & Reward)',
                          value: 'y'
                        },
                        { label: 'Lifehack (Informative Only - No Challenge)', value: '' }
                      ]}
                      handleOptionClick={(field, value) => setChallengePost(!!value)}
                    />
                    {challengePost && (
                      <WritePostChallengeTextInput
                        id="challengeText"
                        name="challengeText"
                        label="Add some challenge button text"
                        value={values.challengeText}
                        error={errors.challengeText}
                        onChange={handleChange}
                      />
                    )}
                    {challengePost && (
                      <WritePostRewardWrapper>
                        <SelectDropdown
                          data={rewardOptions}
                          value={values.rewardId}
                          error={errors.rewardId}
                          valueProp="rewardId"
                          displayProp="name"
                          placeholder="No Reward Given (Mandatory - Points are given based on the reward badge given)"
                          onChange={value => setFieldValue('rewardId', value)}
                        />
                      </WritePostRewardWrapper>
                    )}
                    {challengePost && (
                      <WritePostChallengeTypeWrapper>
                        <SelectDropdown
                          data={challengeTypeOptions}
                          value={values.socialChallengeType}
                          error={errors.socialChallengeType}
                          valueProp="value"
                          displayProp="label"
                          placeholder="Select a method of completing the challenge"
                          onChange={value => setFieldValue('socialChallengeType', value)}
                        />
                      </WritePostChallengeTypeWrapper>
                    )}
                  </WritePostOptions>
                  <WritePostOptions>
                    <WritePostRadioGroup
                      horizontal
                      initialValue={'Send right now'}
                      valueKey="sendAt"
                      options={[
                        { label: 'Send on event day', value: SendDate.EventDay },
                        { label: 'A specific scheduled date', value: SendDate.Date },
                        { label: 'Send right now', value: SendDate.Now }
                      ]}
                      handleOptionClick={(field, value) => setSendAt(value)}
                    />
                    {sendAt === SendDate.EventDay && (
                      <div>
                        <span>
                          Users will receive this post on day
                          <WritePostEventDayInput
                            id="eventDay"
                            name="eventDay"
                            label="e.g. 1"
                            value={values.eventDay}
                            error={errors.eventDay}
                            onChange={handleChange}
                          />
                          of every event
                        </span>
                      </div>
                    )}
                    {sendAt === SendDate.Date && (
                      <div>
                        <span>
                          <PinPostLabel>
                            Users will receive post on
                            {` (${postMeta.event.timezone} ${eventTimezoneOffset / 60}Hrs GMT)`}
                          </PinPostLabel>
                          <WritePostEventDatePicker
                            id="date"
                            date={
                              values.date
                                ? moment(values.date, 'x')
                                    .add('minutes', clientTimezone)
                                    .add('minutes', eventTimezoneOffset)
                                    .toDate()
                                : null
                            }
                            onChange={(field, value) => {
                              setFieldValue(
                                field,
                                moment(value)
                                  .add('minutes', eventTimezoneOffset * -1)
                                  .add('minutes', clientTimezone * -1)
                                  .valueOf()
                                  .toString()
                              );
                            }}
                            minBookingDate={new Date()}
                          />
                          <SelectDropdown
                            margin={'margin-top: 0.5rem'}
                            data={timeOptions}
                            value={findTimeOptionIndex(values.dateTime).id}
                            valueProp={'id'}
                            displayProp={'value'}
                            placeholder={'24Hr Time'}
                            onChange={(optionId: number) => {
                              if (typeof optionId === 'number') {
                                const timeVal = timeOptions[optionId - 1];
                                setFieldValue('dateTime', timeVal.value);
                              }
                            }}
                          />
                        </span>
                      </div>
                    )}
                    {sendAt === SendDate.Now && (
                      <PinnedDate>
                        <PinPostLabel>
                          <SubText>(optional)</SubText>
                          <br />
                          Pin Post until{' '}
                          {`(${postMeta.event.timezone} ${eventTimezoneOffset / 60}Hrs GMT)`}:
                        </PinPostLabel>
                        <WritePostEventDatePicker
                          id="pinUntil"
                          date={
                            values.pinUntil
                              ? moment(values.pinUntil, 'x')
                                  .add('minutes', clientTimezone)
                                  .add('minutes', eventTimezoneOffset)
                                  .toDate()
                              : null
                          }
                          onChange={(field, value) => {
                            setFieldValue(
                              field,
                              moment(value)
                                .add('minutes', eventTimezoneOffset * -1)
                                .add('minutes', clientTimezone * -1)
                                .valueOf()
                                .toString()
                            );
                          }}
                          minBookingDate={new Date()}
                          phrases={{ ...dateSingleInputPhrases, datePlaceholder: 'Not Pinned' }}
                        />
                        <SelectDropdown
                          margin={'margin-top: 0.5rem'}
                          data={timeOptions}
                          value={findTimeOptionIndex(values.pinUntilTime).id}
                          valueProp={'id'}
                          displayProp={'value'}
                          placeholder={'24Hr Time'}
                          onChange={(optionId: number) => {
                            if (typeof optionId === 'number') {
                              const timeVal = timeOptions[optionId - 1];
                              setFieldValue('pinUntilTime', timeVal.value);
                            }
                          }}
                        />
                      </PinnedDate>
                    )}
                  </WritePostOptions>
                </>
              )}
              <WritePostSubmitArea>
                {!companyAdminPost && (
                  <WritePostSubmitButton
                    type="button"
                    variant="admin-cancel"
                    onClick={back}
                    minWidth={'auto'}
                    disabled={loading}
                  >
                    CANCEL
                  </WritePostSubmitButton>
                )}
                <WritePostSubmitButton
                  type="submit"
                  variant="admin-primary"
                  minWidth={'auto'}
                  disabled={loading}
                >
                  SUBMIT
                </WritePostSubmitButton>
              </WritePostSubmitArea>
            </WritePostBody>
          </WritePostCard>
        </Form>
      )}
    </Formik>
  );
};

export default WritePost;
