import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import FirebaseAuthTypes from '@firebase/auth-types';
import firebaseConfig from './Config';
import { User, Unsubscribe } from '../Types';
import { getSigninError, getSignupError } from '../Errors/AuthError';

/** Initialization */
if (!firebase.apps.length) {
  // Check that it's not already initialized
  firebase.initializeApp(firebaseConfig[process.env.REACT_APP_NODE_ENV || 'development']);
  firebase.analytics();
}

const Auth = firebase.auth();

/** Authentication */

export type AuthUser = FirebaseAuthTypes.User | null;

export const getAuthUser = (): AuthUser => Auth.currentUser;

export const signUp = async (email: User['email'], password: string, rememberMe: boolean) => {
  if (rememberMe) await Auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  else await Auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

  try {
    const credential = await Auth.createUserWithEmailAndPassword(email, password);
    return credential.user;
  } catch ({ code }) {
    throw getSignupError(code);
  }
};

export const signIn = async (email: User['email'], password: string, rememberMe: boolean) => {
  if (rememberMe) Auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  else Auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
  try {
    const user = (await Auth.signInWithEmailAndPassword(email, password)).user;
    return user;
  } catch ({ code }) {
    throw getSigninError(code);
  }
};

export const signOut = () => Auth.signOut();

export const onIdTokenChanged = (onChange: (user: AuthUser) => void): Unsubscribe =>
  Auth.onIdTokenChanged(onChange);

export const reAuthenticate = async (email: User['email'], password: string) =>
  (
    await Auth.currentUser!.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(email, password)
    )
  ).user;

export const sendPasswordReset = (email: User['email']) => Auth.sendPasswordResetEmail(email);

export const updatePassword = async (
  currentPassword: string,
  newPassword: string
): Promise<void> => {
  const authUser = getAuthUser();
  return authUser && authUser.email
    ? authUser
        .reauthenticateWithCredential(
          firebase.auth.EmailAuthProvider.credential(authUser.email, currentPassword)
        )
        .then(() => authUser.updatePassword(newPassword))
        .catch(error => {
          throw error;
        })
    : undefined;
};

export default {
  getAuthUser,
  signIn,
  signOut,
  onIdTokenChanged,
  reAuthenticate,
  sendPasswordReset,
  updatePassword
};
