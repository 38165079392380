import styled from 'styled-components/macro';

export const Container = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  width: 100%;
  background-color: ${({ theme }) => theme.backgrounds.white};
  margin-top: ${({ theme }) => theme.pad.xsm}%;
  padding: ${({ theme }) => theme.pad.sm}% ${({ theme }) => theme.pad.lg}%
    ${({ theme }) => theme.pad.sm}% ${({ theme }) => theme.pad.sm}%;
`;

export const Column1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: flex-start;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
`;

export const CreateTeamFormWrap = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.pad.xsm * 1.5}% ${({ theme }) => theme.pad.lg * 2}% 0 0;
`;

export const Wrapper = styled.div`
  height: 80%;
  background-color: ${({ theme }) => theme.backgrounds.white};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.lighter};
`;

export const TopHd = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`;

export const Header = styled.div`
  font-size: ${({ theme }) => theme.fontScale.md};
  font-style: italic;
  color: ${({ theme }) => theme.primary.dark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  line-height: calc(${({ theme }) => theme.fontScale.lg} * 1.5);
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  white-space: nowrap;
`;
