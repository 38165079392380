import styled, { css } from 'styled-components/macro';
import { Theme } from '../../../../theme';

interface CheckboxProps {
  checked: boolean;
}

export const Label = styled.label`
  font-size: 1.4rem;
  cursor: pointer;
  color: ${({ theme }) => theme.primary.dark};
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  width: 1.4rem;
  position: relative;
  top: calc(50% - 0.9rem);
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: ${({ theme }) => theme.padding}px;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 0.1rem;
  margin-left: 1rem;
`;

const getCheckedStyles = (checked: boolean, { borders }: Theme) =>
  css`
    background: ${checked ? '#44DEFF' : 'none'};
    border: ${checked ? borders.input.focused : borders.input.default};
  `;

export const StyledCheckbox = styled.div<CheckboxProps>`
  ${({ checked, theme }) => getCheckedStyles(checked, theme)};
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.4rem;
  transition: all 150ms;
  text-align: center;

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;
