import {
  PagedResponse,
  Company,
  CompanyDemographicsView,
  CompanyLeaderboardView,
  PaginationFilter
} from '../../../Types';

export const SET_COMPANY = 'SET_COMPANY';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANY_OPTIONS = 'SET_COMPANY_OPTIONS';
export const SET_COMPANY_FILTER = 'SET_COMPANY_FILTER';
export const SET_COMPANY_DEMOGRAPHICS = 'SET_COMPANY_DEMOGRAPHICS';
export const SET_COMPANY_LEADERBOARD = 'SET_COMPANY_LEADERBOARD';

export const defaultCompanyListFilter: PaginationFilter = {
  page: 1,
  rowsPerPage: 10,
  orderColumn: 'name',
  orderDirection: 'ASC',
  keyword: ''
};
export interface CompanyState {
  companies: PagedResponse<Company>;
  companyOptions: Company[];
  company: Company;
  filter: PaginationFilter;
  companyDemographics: CompanyDemographicsView[];
  companyLeaderboard: CompanyLeaderboardView[];
}

export interface SetCompaniesActions {
  type: typeof SET_COMPANIES;
  companies: PagedResponse<Company>;
}

export interface UpdateCompanyAction {
  type: typeof UPDATE_COMPANY;
  companyUpdates: Partial<Company>;
}

export interface SetCompanyLeaderboardAction {
  type: typeof SET_COMPANY_LEADERBOARD;
  companyLeaderboard: CompanyLeaderboardView[];
}

export interface SetCompanyDemographicsAction {
  type: typeof SET_COMPANY_DEMOGRAPHICS;
  companyDemographics: CompanyDemographicsView[];
}

export interface SetCompanyOptionsAction {
  type: typeof SET_COMPANY_OPTIONS;
  companyOptions: Company[];
}

export interface SetCompanyAction {
  type: typeof SET_COMPANY;
  company: Company;
}

export interface RemoveCompanyAction {
  type: typeof REMOVE_COMPANY;
}

export interface SetCompanyFilterAction {
  type: typeof SET_COMPANY_FILTER;
  filter: PaginationFilter;
}

export type CompanyActionTypes =
  | SetCompaniesActions
  | SetCompanyOptionsAction
  | UpdateCompanyAction
  | SetCompanyAction
  | RemoveCompanyAction
  | SetCompanyFilterAction
  | SetCompanyDemographicsAction
  | SetCompanyLeaderboardAction;
