import React, { FC } from 'react';
import { useDispatch, connect } from 'react-redux';
import moment from 'moment';
import { ToSIPrefix } from '@cognativ/utilities';

import {
  ActionHeader,
  RowActions,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  PassCodeWrapper,
  PassCodeCopyWrapper,
  PassCodeCopy,
  ProfileImage,
  StatusPill,
  LoaderContainer
} from './Styles';

import { Paper } from '..';
import Pagination from './Pagination';
import LoadingIndicator from '../LoadingIndicator';
import {
  ApprovalStatus,
  OrderDirection,
  PagedResponse,
  PaginationFilter,
  TableAction,
  TableHeaderItem
} from '../../../lib/Types';
import TableHeader from './TableHeader';
import TableActions from './TableActions';
import { setNotification } from '../../../lib/Store/contexts/notification/actions';

export interface PaginatedTableProps {
  idProp: string;
  headers: TableHeaderItem[];
  filter: PaginationFilter;
  data: PagedResponse<any>;
  actions?: TableAction[];
  loading: boolean;
  pageInput?: boolean;
  onSort: (column: string, direction: OrderDirection) => void;
  onSetPage: (page: number) => void;
  onEdit?: (id: number | string) => void;
  onView?: (id: number | string) => void;
  onDelete?: (id: number | string) => void;
  onApprove?: (id: number) => void;
  onReject?: (id: number) => void;
  alignNumberedCellsCenter?: boolean;
  highlightFirstRow?: boolean;
  margin?: string;
  noTopLeftRadius?: boolean;
}

const PaginatedTable: FC<PaginatedTableProps> = ({
  idProp,
  headers,
  filter,
  data,
  actions,
  loading,
  pageInput = true,
  onSort,
  onSetPage,
  onEdit,
  onView,
  onDelete,
  onApprove,
  onReject,
  alignNumberedCellsCenter = true,
  highlightFirstRow = false,
  margin,
  noTopLeftRadius = false
}) => {
  const dispatch = useDispatch();
  const { results } = data;
  const { count } = data;

  const { page, rowsPerPage, orderColumn, orderDirection } = filter;

  const copyText = (textToCopy: string) => {
    if (!textToCopy)
      return dispatch(
        setNotification({
          message: 'There was an error copying the text. Please try again later.',
          variant: 'warning'
        })
      );

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => dispatch(setNotification({ message: 'Text copied!' })));
  };

  const doSort = (column: string) => {
    if (loading) return;

    if (orderColumn === column) {
      const direction: OrderDirection = orderDirection === 'ASC' ? 'DESC' : 'ASC';
      return onSort(column, direction);
    }
    onSort(column, 'ASC');
  };

  const getCellValue = (
    result: any,
    { mapProp, mapBool, mapCombine, mapDeep, millis, render }: TableHeaderItem
  ) => {
    if (render) {
      return render(result);
    }
    let value = mapDeep
      ? mapDeep.depth === 2
        ? result[`${mapDeep.flatField}`][`${mapDeep.deepField}`][`${mapDeep.deepField2}`]
        : result[`${mapDeep.flatField}`][`${mapDeep.deepField}`]
      : result[mapProp];

    if (mapCombine) {
      if (mapDeep) {
        return `${result[`${mapDeep.flatField}`][`${mapCombine.firstField}`]}
        ${result[`${mapDeep.flatField}`][`${mapCombine.secondField}`]}`;
      }
      return `${result[mapCombine.firstField]} ${result[mapCombine.secondField]}`;
    }

    if (value === ApprovalStatus.NotApproved) value = 'Rejected';
    if (millis) value = moment(value, 'x').format('YYYY-MM-DD hh:mm a');

    if (mapProp === 'active') {
      // Here we want some more advanced status logic for events

      // Inactive - false (time before registration)
      // Registrations - true (time after registration)
      // Registrations Closed - true (time between registration close and event start, usually doesn't happen though)
      // Active - true (time after event start)
      // Grace Period - true (time after event end but still in grace)
      // Completed - false (time after event completed)

      const statusNameToActiveMap = {
        Inactive: false,
        Registrations: true,
        'Soon Active': true,
        Active: true,
        'Grace Period': true,
        Completed: false
      };

      // Default Inactive
      let finalStatus = 'Inactive';

      const now = Date.now();

      // Grace end date is the event end date + "seconds per day" * 1000 (milis) * grace period (measured in days)
      const graceEndDate = parseInt(result.endDate) + 86400 * 1000 * result.gracePeriod;

      if (now > graceEndDate) {
        finalStatus = 'Completed';
      } else if (now > parseInt(result.endDate)) {
        finalStatus = 'Grace Period';
      } else if (now > parseInt(result.startDate)) {
        finalStatus = 'Active';
      } else if (now > parseInt(result.registrationsEndDate) && now < parseInt(result.startDate)) {
        finalStatus = 'Soon Active';
      } else if (now > parseInt(result.registrationsStartDate)) {
        finalStatus = 'Registrations';
      }

      // If we don't have a registrationsStartDate, we are doing the statusPill for the company view
      if (result.registrationsStartDate === undefined) {
        finalStatus = result.active ? 'Active' : 'Inactive';
      }

      return <StatusPill status={statusNameToActiveMap[finalStatus]}>{finalStatus}</StatusPill>;
    }

    if (typeof value === 'boolean' && mapBool) return mapBool[`${value}`];

    if (mapProp === 'profileImage') {
      return <ProfileImage src={value} />;
    }

    if (mapProp === 'passCode') {
      return (
        <PassCodeWrapper
          onClick={() => {
            copyText(result[mapProp]);
          }}
        >
          <span>{result[mapProp]}</span>
          <PassCodeCopyWrapper>
            <PassCodeCopy />
          </PassCodeCopyWrapper>
        </PassCodeWrapper>
      );
    }
    return value;
  };

  const onPrevPage = () => onSetPage(page - 1);
  const onNextPage = () => onSetPage(page + 1);

  const tableBody = () => {
    return loading ? (
      <LoaderContainer>
        <LoadingIndicator />
      </LoaderContainer>
    ) : (
      results
        .filter(result => result !== null)
        .map((result, indexj) => (
          <TableRow key={indexj} highlight={highlightFirstRow && indexj === 0}>
            {headers.map((header, indexi) => (
              <TableCell
                highlight={highlightFirstRow && indexj === 0}
                numbered={
                  alignNumberedCellsCenter
                    ? typeof result[header.mapProp] === 'number' ||
                      header.mapProp === 'profileImage'
                    : false
                }
                dropable={header.dropable}
                key={indexi}
                wrap={header?.wrap}
              >
                {typeof getCellValue(result, header) === 'number' && !header.rawNumber
                  ? ToSIPrefix(getCellValue(result, header))
                  : getCellValue(result, header)}
              </TableCell>
            ))}
            {actions ? (
              <RowActions
                highlight={highlightFirstRow && indexj === 0}
                leaderboard={idProp === 'teamId'}
              >
                <TableActions
                  actions={actions}
                  onEdit={() => onEdit && onEdit(result[idProp])}
                  onView={() => onView && onView(result[idProp])}
                  onDelete={() => onDelete && onDelete(result[idProp])}
                  onApprove={() => onApprove && onApprove(result[idProp])}
                  onReject={() => onReject && onReject(result[idProp])}
                  approved={
                    (result ? result.approvalStatus === ApprovalStatus.Approved : false) ||
                    result?.approved
                  }
                  rejected={result ? result.approvalStatus === ApprovalStatus.NotApproved : true}
                />
              </RowActions>
            ) : null}
          </TableRow>
        ))
    );
  };

  return (
    <Paper margin={margin} noTopLeftRadius={noTopLeftRadius}>
      <TableContainer>
        <Table>
          <TableHead>
            {headers.map(({ display, mapProp, numbered, dropable }, index) => (
              <TableHeader
                key={index}
                dropable={dropable}
                numbered={numbered || mapProp === 'passCode'}
                mapProp={mapProp}
                orderColumn={orderColumn}
                orderDirection={orderDirection}
                onSort={doSort}
              >
                {display}
              </TableHeader>
            ))}
            {actions && <ActionHeader />}
          </TableHead>
          {!!results.length && <TableBody>{tableBody()}</TableBody>}
        </Table>
      </TableContainer>
      <Pagination
        page={page}
        count={count}
        loading={loading}
        pageInput={pageInput}
        rowsPerPage={rowsPerPage}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        onSetPage={onSetPage}
      />
    </Paper>
  );
};

export default connect(null, {})(PaginatedTable);
