import styled from 'styled-components/macro';

const FontScale = {
  xxs: '0.676rem',
  xs: '0.9rem',
  s: '1.2rem',
  m: '1.6rem',
  l: '2.133rem',
  xl: '2.843rem',
  xxl: '3.79rem'
};

// --------------------------- HEADER STYLES --------------------------- //
export interface TextProps {
  padding?: string;
  fontStyle?: string;
  whiteSpace?: string;
  width?: string;
  wrap?: boolean;
}

// Dark Blue Header
export const DarkBlueHeaderL = styled.div<TextProps>`
  color: ${({ theme }) => theme.primary.dark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: calc(${FontScale.m} + 0.6vw);
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : 'italic')};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: calc((${FontScale.l} + 0.5vw) * 1.3);
  padding-bottom: ${({ theme }) => theme.pad.xsm}%;
  padding: ${({ padding }) => (padding ? padding : '0px')};
  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : 'normal')};

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.m} + 0.45vw);
    line-height: calc((${FontScale.l} + 0.5vw) * 1.3);
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(${FontScale.m} + 1.2vw);
    line-height: calc((${FontScale.m} + 1vw) * 1.3);
    padding: 0 0 0 0;
  }
`;

export const DarkBlueHeaderM = styled(DarkBlueHeaderL)<{ flex?: number; align?: string }>`
  font-size: calc(${FontScale.xs} + 0.4vw);
  line-height: calc((${FontScale.xs} + 0.47vw) * 1.25);
  padding: ${({ padding }) => (padding ? padding : '0px')};
  ${({ flex = undefined }) => flex && `flex: ${flex};`}
  ${({ align = 'left' }) => `text-align: ${align};`}

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.xs} + 0.5vw);
    line-height: calc((${FontScale.xs} + 0.7vw) * 1.3);
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(${FontScale.xs} + 1vw);
    line-height: calc((${FontScale.xs} + 1vw) * 1.5);
  }
`;

export const DarkBlueHeaderS = styled(DarkBlueHeaderL)`
  font-size: calc(${FontScale.xxs} + 0.4vw);
  padding: 0;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.xxs} + 0.45vw);
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(${FontScale.xs} + 2vw);
  }
`;

export const DarkBlueHeaderXS = styled(DarkBlueHeaderL)`
  font-size: calc(${FontScale.xxs} + 0.2vw);
  padding: 0;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.xxs} + 0.3vw);
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(${FontScale.xs} + 2vw);
  }
`;

// Bright Blue Header
export const BrightBlueHeaderL = styled.div<TextProps>`
  color: ${({ theme }) => theme.primary.main};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: calc(${FontScale.m} + 0.5vw);
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: calc((${FontScale.m} + 0.5vw) * 1.3);
  padding: ${({ padding }) => (padding ? padding : '2rem 0 1rem')};

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.m} + 0.75vw);
    line-height: calc((${FontScale.m} + 0.75vw) * 1.3);
  }
`;

export const BrightBlueHeaderM = styled(BrightBlueHeaderL)`
  font-size: calc(${FontScale.s} + 0.5vw);
  line-height: calc((${FontScale.s} + 0.5vw) * 1.3);

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.s} + 0.75vw);
    line-height: calc((${FontScale.s} + 0.75vw) * 1.3);
  }
`;

export const BrightBlueHeaderS = styled(BrightBlueHeaderL)`
  font-size: calc(${FontScale.xs} + 0.5vw);
  line-height: calc((${FontScale.xs} + 0.5vw) * 1.3);

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.xs} + 0.75vw);
    line-height: calc((${FontScale.xs} + 0.75vw) * 1.3);
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(${FontScale.xs} + 1.5vw);
    line-height: calc((${FontScale.xs} + 1.5vw) * 1);
    padding: 1.5rem 0 0;
  }
`;

export const BrightBlueHeaderXS = styled(BrightBlueHeaderL)`
  font-size: calc(${FontScale.xxs} + 0.5vw);
  line-height: calc((${FontScale.xxs} + 0.5vw) * 1.3);

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.xxs} + 0.75vw);
    line-height: calc((${FontScale.xxs} + 0.75vw) * 1.3);
  }
`;

// Red Header
export const RedHeaderL = styled.div<TextProps>`
  color: ${({ theme }) => theme.primary.red};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: calc(${FontScale.m} + 0.5vw);
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: calc((${FontScale.m} + 0.5vw) * 1.3);
  padding: ${({ padding }) => (padding ? padding : '2rem 0 1rem')};

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.m} + 0.75vw);
    line-height: calc((${FontScale.m} + 0.75vw) * 1.3);
  }
`;

export const RedHeaderM = styled(RedHeaderL)`
  font-size: calc(${FontScale.s} + 0.5vw);
  line-height: calc((${FontScale.s} + 0.5vw) * 1.3);

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.s} + 0.75vw);
    line-height: calc((${FontScale.s} + 0.75vw) * 1.3);
  }
`;

export const RedHeaderS = styled(RedHeaderL)`
  font-size: calc(${FontScale.xs} + 0.5vw);
  line-height: calc((${FontScale.xs} + 0.5vw) * 1.3);

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.xs} + 0.75vw);
    line-height: calc((${FontScale.xs} + 0.75vw) * 1.3);
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(${FontScale.xs} + 1.5vw);
    line-height: calc((${FontScale.xs} + 1.5vw) * 1);
    padding: 1.5rem 0 0;
  }
`;

export const RedHeaderXS = styled(RedHeaderL)`
  font-size: calc(${FontScale.xxs} + 0.5vw);
  line-height: calc((${FontScale.xxs} + 0.5vw) * 1.3);

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.xxs} + 0.75vw);
    line-height: calc((${FontScale.xxs} + 0.75vw) * 1.3);
  }
`;

export const StyledPageHd = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: calc(${({ theme }) => theme.fonts.subHeading}rem + 0.4vw);
  font-style: oblique;
  color: ${({ theme }) => theme.primary.dark};
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 0 ${({ theme }) => theme.pad.xsm / 2}%;
  margin-top: 0.5rem;
  margin-bottom: 0.4rem;
`;

// --------------------------- BODY STYLES --------------------------- //

// Dark Blue Body
export const DarkBlueBodyL = styled.div<TextProps>`
  color: ${({ theme }) => theme.primary.dark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: calc(${FontScale.s} + 0.4vw);
  font-weight: ${({ theme }) => theme.fontWeights.reg};
  line-height: calc((${FontScale.s} + 0.5vw) * 1.8);
  padding: ${({ padding }) => (padding ? padding : '0')};
  width: ${({ width }) => (width ? width : '100%')};

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.s} + 0.75vw);
    line-height: calc((${FontScale.xxs} + 0.75vw) * 1.8);
  }
`;

export const DarkBlueBodyM = styled(DarkBlueBodyL)`
  font-size: calc(${FontScale.xs} + 0.4vw);
  line-height: calc((${FontScale.xs} + 0.5vw) * 1.8);
  padding: ${({ padding }) => (padding ? padding : '0')};

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.xs} + 0.75vw);
    line-height: calc((${FontScale.xxs} + 0.75vw) * 1.8);
  }
`;

export const DarkBlueBodyS = styled(DarkBlueBodyL)<{ textAlign?: string }>`
  font-size: calc(${FontScale.xxs} + 0.5vw);
  line-height: calc((${FontScale.xxs} + 0.5vw) * 2);
  padding: ${({ padding }) => (padding ? padding : '0')};

  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.xxs} + 0.75vw);
    line-height: calc((${FontScale.xxs} + 0.75vw) * 2);
  }
  margin-bottom: 10px;

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(${FontScale.xxs} + 1.8vw);
    line-height: calc((${FontScale.xxs} + 2vw) * 1.5);
    padding: 0.6rem 0;
  }
`;

export const DarkBlueBodyXS = styled(DarkBlueBodyL)`
  font-size: calc(${FontScale.xxs} + 0.1vw);
  line-height: calc((${FontScale.xxs} + 0.2vw) * 1.5);
  padding: ${({ padding }) => (padding ? padding : '0')};
  white-space: ${({ wrap }) => (wrap ? 'normal' : 'nowrap')};

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.xxs} + 0.35vw);
    line-height: calc((${FontScale.xxs} + 0.35vw) * 1.5);
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(${FontScale.xxs} + 1.8vw);
    line-height: calc((${FontScale.xxs} + 2vw) * 1.5);
    padding: 0.6rem 0;
  }
`;

// Light Blue Body
export const LightBlueBodyL = styled.div<TextProps>`
  color: ${({ theme }) => theme.primary.xlight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: calc(${FontScale.s} + 0.5vw);
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : 'italic')};
  line-height: calc((${FontScale.s} + 0.5vw) * 1.3);
  padding: ${({ padding }) => (padding ? padding : '0px')};
`;

export const LightBlueBodyM = styled(LightBlueBodyL)`
  font-size: calc(${FontScale.xs} + 0.5vw);
  line-height: calc((${FontScale.xs} + 0.5vw) * 1.3);
`;

export const LightBlueBodyS = styled(LightBlueBodyL)`
  font-size: calc(${FontScale.xxs} + 0.5vw);
  line-height: calc((${FontScale.xxs} + 0.5vw) * 1.8);
  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(${FontScale.xxs} + 1.7vw);
    line-height: calc((${FontScale.xxs} + 1.5vw) * 1.8);
  }
`;

// Grey Body
export const GreyBodyL = styled.div<TextProps>`
  color: ${({ theme }) => theme.backgrounds.dark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: calc(${FontScale.s} + 0.5vw);
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-style: italic;
  line-height: calc((${FontScale.s} + 0.5vw) * 1.3);
`;

export const GreyBodyM = styled(GreyBodyL)`
  font-size: calc(${FontScale.xs} + 0.5vw);
  line-height: calc((${FontScale.xs} + 0.5vw) * 1.3);
`;

export const GreyBodyS = styled(GreyBodyL)`
  font-size: calc(${FontScale.xxs} + 0.5vw);
  line-height: calc((${FontScale.xxs} + 0.5vw) * 1.5);
`;

// Black Body
export const BlackBodyS = styled(DarkBlueBodyL)`
  font-size: calc(${FontScale.xxs} + 0.5vw);
  line-height: calc((${FontScale.xxs} + 0.5vw) * 2);
  padding: ${({ padding }) => (padding ? padding : '0')};
  color: ${({ theme }) => theme.backgrounds.black};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeights.reg};

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(${FontScale.xxs} + 0.75vw);
    line-height: calc((${FontScale.xxs} + 0.75vw) * 2);
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(${FontScale.xxs} + 1vw);
    line-height: calc((${FontScale.xxs} + 1vw) * 2);
  }
`;

// --------------------------- INLINE STYLES --------------------------- //

// Weight
export const BoldText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const SemiBoldText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const RegText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const LightText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.light};
`;

// Colour
export const TurqText = styled.span`
  color: ${({ theme }) => theme.primary.turquoise};
`;

export const RedText = styled.span`
  color: ${({ theme }) => theme.primary.red};
`;

export const OrangeText = styled.span`
  color: ${({ theme }) => theme.primary.orange};
`;

export const LightGreyText = styled.span`
  color: #c1c1c1;
`;
