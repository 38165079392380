import styled from 'styled-components/macro';

import { DarkBlueHeaderM } from '../../Themes';

export const Container = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;

  @media screen and (min-width: ${({ theme }) => theme.device.tablet}px) {
    padding-right: ${({ theme }) => theme.padding * 4}px;
  }
`;

export const Header = styled(DarkBlueHeaderM)`
  font-size: calc(1rem + 0.8vw);
  margin: 0.5rem 0 1.8rem;
  padding: 0;
`;
