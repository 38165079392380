import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const HeaderTextBold = styled.div`
  color: ${({ theme }) => theme.primary.dark};
  font-family: ${({ theme }) => theme.fontFamily.body};
  margin-bottom: ${({ theme }) => theme.padding}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-style: italic;
  width: 100%;
`;

export const InputWrap = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.pad.xsm}%;
`;

export const Header = styled.div`
  font-size: ${({ theme }) => theme.fontScale.md};
  font-style: italic;
  color: ${({ theme }) => theme.primary.dark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  line-height: calc(${({ theme }) => theme.fontScale.lg} * 1.5);
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  white-space: nowrap;
`;

export const SubHeader = styled(Header)`
  font-size: calc(${({ theme }) => theme.fontScale.lg} * 0.8);
  line-height: calc(${({ theme }) => theme.fontScale.lg});
  width: auto;
`;

export const TeamNameField = styled.div`
  padding-bottom: ${({ theme }) => theme.pad.xsm}%;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-items: flex-start;
  align-items: center;
`;

export const TeamMembers = styled.div`
  padding-bottom: ${({ theme }) => theme.pad.xsm}%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TeamMemberRow = styled.div`
  padding-bottom: ${({ theme }) => theme.pad.xsm}%;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: stretch;
`;

export const TeamMemberInputs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-items: stretch;
  justify-content: space-between;
  column-gap: 1rem;
`;

export const TeamMemberInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FlexRowBlue = styled.div`
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  justify-content: space-between;
  background-color: #edfaff;
  max-width: 900px;
  padding: ${({ theme }) => theme.padding}px;
  border-radius: ${({ theme }) => theme.borderRadius.sm}rem;
  margin-bottom: ${({ theme }) => theme.pad.sm}%;
`;

export const LightText = styled.p`
  color: ${({ theme }) => theme.primary.dark};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin: 0 auto 0 12px;
`;

export const LoaderContainer = styled.div`
  height: 50rem;
  width: 125%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
