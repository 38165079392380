import {
  ParticipantActionTypes,
  SET_PARTICIPANTS,
  SET_DELETING_PARTICIPANT,
  SET_PARTICIPANTS_WITHOUT_TEAM,
  SET_PARTICIPANT,
  SET_PAGED_PARTICIPANTS,
  SET_PARTICIPANTS_FILTER,
  SET_PAGED_PARTICIPANTS_WITHOUT_TEAM,
  SET_PARTICIPANT_TEAM,
  SET_PARTICIPANT_DEMOGRAPHICS,
  SET_PENDING_PARTICIPANT,
  SET_PAGED_PARTICIPANTS_NOT_REGISTERED
} from './types';
import {
  PagedResponse,
  ParticipantListView,
  UserDemographic,
  Team,
  PendingParticipant,
  ParticipantFilter
} from '../../../Types';

export const setParticipants = (participants: ParticipantListView[]): ParticipantActionTypes => ({
  type: SET_PARTICIPANTS,
  participants
});

export const setParticipantsWithoutTeam = (
  participantsWithoutTeam: ParticipantListView[]
): ParticipantActionTypes => ({
  type: SET_PARTICIPANTS_WITHOUT_TEAM,
  participantsWithoutTeam
});

export const setParticipant = (participant: ParticipantListView): ParticipantActionTypes => ({
  type: SET_PARTICIPANT,
  participant
});

export const setPendingParticipant = (
  pendingParticipant: PendingParticipant
): ParticipantActionTypes => ({
  type: SET_PENDING_PARTICIPANT,
  pendingParticipant
});

export const setDeletingParticipant = (deletingParticipant: boolean): ParticipantActionTypes => ({
  type: SET_DELETING_PARTICIPANT,
  deletingParticipant
});

export const setParticipantTeam = (participantTeam: Team): ParticipantActionTypes => ({
  type: SET_PARTICIPANT_TEAM,
  participantTeam
});

export const setParticipantDemographics = (
  participantDemographics: UserDemographic[]
): ParticipantActionTypes => ({
  type: SET_PARTICIPANT_DEMOGRAPHICS,
  participantDemographics
});

export const setPagedParticipants = (
  pagedParticipants: PagedResponse<ParticipantListView>
): ParticipantActionTypes => ({
  type: SET_PAGED_PARTICIPANTS,
  pagedParticipants
});

export const setPagedParticipantsWithoutTeam = (
  pagedParticipantsWithoutTeam: PagedResponse<ParticipantListView>
): ParticipantActionTypes => ({
  type: SET_PAGED_PARTICIPANTS_WITHOUT_TEAM,
  pagedParticipantsWithoutTeam
});

export const setPagedParticipantsNotRegistered = (
  pagedParticipantsNotRegistered: PagedResponse<ParticipantListView>
): ParticipantActionTypes => ({
  type: SET_PAGED_PARTICIPANTS_NOT_REGISTERED,
  pagedParticipantsNotRegistered
});

export const setParticipantsFilter = (filter: ParticipantFilter): ParticipantActionTypes => ({
  type: SET_PARTICIPANTS_FILTER,
  filter
});
