import React, { FC } from 'react';
import {
  FormColumn,
  Group,
  ColumnWrapper,
  ButtonWrapper,
  Container,
  LinkText,
  TermsBody,
  TermsHeader,
  CheckboxContainer,
  List,
  ListItem
} from './Styles';
import { Checkbox, ModalButton } from '../../../components/UI';
import { FormikForm } from 'src/lib/Types';
import { TermsAndConditions } from '../validators/termsAndConditionsSchema';
import { COMPANY_SITE_URL } from '../../../CONSTANTS';

export interface TermsAndConditionFormProps extends FormikForm<TermsAndConditions> {
  disabled: boolean;
}
const TermsAndConditionsForm: FC<TermsAndConditionFormProps> = ({
  disabled,
  handleChange,
  handleSubmit,
  values,
  errors
}) => {
  const redirectToCompanySite = () => {
    window.location.href = COMPANY_SITE_URL;
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <ColumnWrapper>
            <FormColumn>
              <TermsBody>
                Welcome to MovingMindz.co, which is brought to you by Moving Mindz Pty Ltd
                (collectively, our “Company”) and made available to you at
                <LinkText onClick={redirectToCompanySite}> www.movingmindz.co</LinkText>,
                respectively, or through our mobile applications, accessible through tablets, cell
                phones, personal digital assistants, connected televisions, and other devices
                (collectively, the “Site”). Your use of the information, materials, text, images and
                other content on the Site is subject to the terms and conditions below, together
                with our Privacy Policy that is a part hereof and herein incorporated by reference
                in its entirety, each of which we may be revise from time to time without notice.
                Please read these terms and conditions and that Privacy Policy carefully.
                <br></br>
                <TermsHeader>
                  BY ACCESSING THE SITE, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ, UNDERSTAND
                  AND AGREE TO BE BOUND BY THESE GENERAL WEBSITE TERMS AND CONDITIONS (THESE “TERMS
                  AND CONDITIONS”).
                </TermsHeader>
                <br></br>
                <TermsHeader>Services:</TermsHeader>
                Unless expressly stated otherwise, the service provided by our Company is limited
                to: [physical activity tracking platform for you to monitor your exercise and
                non-exercise related physical activities on your life through gamification and
                community interaction] (the “Service” or “Services”). OUR COMPANY DOES NOT PROVIDE
                MEDICAL EVALUATION, DIAGNOSIS, OR TREATMENT. The content provided on the Site is
                provided AS-IS, for informational and educational purposes only, and is not intended
                to be a substitute for professional medical advice, diagnosis or treatment. You
                should consult your physician or other qualified health provider if you have
                questions about a medical condition. If you think you have a medical emergency, call
                your doctor or 000 immediately. Reliance on any information provided on the Service
                is entirely at your own risk. To be clear, our Company also does not: process
                payments, store credit card data, negotiate terms of licenses or settle disputes
                between users of the Site (collectively, “Excluded Services”). If any Excluded
                Services are provided to you, it is by third-parties who are neither our agents nor
                acting in our behalf and we have no responsibility whatsoever regarding the safety
                of persons or property found through use of the Site.
                <br></br>
                <TermsHeader>
                  Disclaimer: YOU SHOULD CONSULT YOUR PHYSICIAN BEFORE STARTING ANY EXERCISE
                  PROGRAM. THIS IS PARTICULARLY TRUE IF ANY OF THE FOLLOWING APPLY TO YOU:
                </TermsHeader>
                <List>
                  <ListItem> CHEST PAIN OR PAIN IN THE NECK AND/OR ARM</ListItem>
                  <ListItem> SHORTNESS OF BREATH</ListItem>
                  <ListItem> DIAGNOSED HEART CONDITION</ListItem>
                  <ListItem> JOINT AND/OR BONE PROBLEMS</ListItem>
                  <ListItem>
                    IF YOU’RE TAKING ANY MEDICATIONS, ESPECIALLY CARDIAC AND/OR BLOOD PRESSURE
                    MEDICATIONS
                  </ListItem>
                  <ListItem> HAVE NOT PREVIOUSLY BEEN PHYSICALLY ACTIVE</ListItem>
                  <ListItem> OR DIZZINESS</ListItem>
                </List>
                <br></br>
                IF NONE OF THESE APPLY TO YOU, YOU SHOULD NONETHELESS START ANY EXERCISE PROGRAM
                GRADUALLY AND SENSIBLY. IF YOU FEEL ANY OF THE PHYSICAL SYMPTOMS LISTED ABOVE WHEN
                YOU START YOUR EXERCISE PROGRAM, YOU SHOULD CONTACT YOUR PHYSICIAN IMMEDIATELY. THE
                INFORMATION AND MATERIALS CONTAINED ON THE SITE ARE PROVIDED FOR EDUCATIONAL AND
                INFORMATIONAL PURPOSES ONLY. THIS SITE IS NOT A SUBSTITUTE FOR PROFESSIONAL MEDICAL
                ADVICE AND TREATMENT OR YOUR CONSULTATION WITH QUALIFIED PHYSICIANS AND OTHER HEALTH
                CARE PROFESSIONALS REGARDING YOUR INDIVIDUAL HEALTH NEEDS.
                <br></br>
                <TermsHeader>User Accounts:</TermsHeader> In order to access certain areas of the
                Site, you will create a user account and login (a “User Account”). You may never use
                another party’s User Account without such party’s express written authorization. You
                agree that all information which you provide through the Site in connection with
                creating your User Account or otherwise is current, accurate and complete. You are
                solely responsible for all activity that occurs with respect to your User Account.
                You are solely responsible for maintaining the confidentiality of your User Account
                and keeping the username and password to your User Account secure. You agree to
                notify us immediately of any security breach or unauthorized use of your User
                Account. We will not be liable for any costs, losses, claims or damages that you or
                any third party incur which are directly or indirectly caused by any unauthorized
                use of your User Account.
                <br></br>
                You must be 18 years or older or at least the age of majority in the jurisdiction
                where you reside or from which you use this Service.
                <br></br>
                <TermsHeader>Inactive Status:</TermsHeader> You must use your account on a regular
                basis in order to keep it active. We reserve the right to permanently deactivate any
                account that has been inactive for more than ninety (90) days. You must use your
                account regularly by logging in and out of the Service in order to keep your
                registration active. If we deactivate an account, we have the right to reassign the
                username to another user.
                <br></br>
                <TermsHeader>Eligibility:</TermsHeader> In the event that you are using the Site in
                connection with the use of the Site by your company on an enterprise basis (such
                company, an “Enterprise User”), your eligibility to be a User of the Site and to
                create a User Account is governed by our relationship with your company that will be
                validated at the beginning of the registration process. We are not responsible in
                any way for such eligibility determination at such time and, in the event that your
                eligibility changes, you agree that we have no liability in connection with the
                cessation of the Service to you as a result thereof.
                <br></br>
                <TermsHeader>Waivers:</TermsHeader> In the event that you are using the Site in
                connection with an Enterprise User, such Enterprise User may request or require a
                waiver. You agree that our Company has no liability to you in connection with any
                such waiver, decisions or determinations made on the basis thereof or any other
                matters arising from or related thereto. In the event of a waiver request or
                requirement, if you have questions, please contact or inquire about such waiver from
                the applicable representatives from your sponsoring company.
                <br></br>
                <TermsHeader>Use of Content:</TermsHeader> The text, images, data, illustrations,
                files, audio and video clips, designs, documents, and other materials and content
                (collectively, the “Content”) on the Site is our property and may be protected by
                copyright and other restrictions. Copyrights and other proprietary rights in the
                Content may also be owned by parties other than us. You may not copy, modify,
                distribute or otherwise use any of the Content, except for your personal and non-
                commercial use. Please note that we may withdraw any Content from the Site at any
                time in our sole discretion.
                <br></br>
                <TermsHeader>User Warranty:</TermsHeader> By downloading, printing, or otherwise
                using any of the Content, you agree that you will (i) restrict your use of such
                Content to personal and non-commercial use, (ii) comply with all of these Terms and
                Conditions, and (iii) not violate the our rights or the rights of any other person
                or entity. We do not warrant that use of any of the Content will not infringe the
                rights of third parties.
                <br></br>
                <TermsHeader>Commercial Use is Restricted:</TermsHeader> Unauthorized commercial
                publication or exploitation of text, images, documents, materials or any other
                Content is prohibited without our express written consent. If you wish to use any of
                the Content for commercial use, publication, or any purpose other than personal use,
                you must obtain our written permission prior to such use. We may, in our sole
                discretion, grant permission for such use on a case-by-case basis and we may charge
                a usage fee for such use.
                <br></br>
                <TermsHeader>Fees and Payments:</TermsHeader> You agree to pay to our Company any
                fees for the services you purchase or use (including any overage fees), in
                accordance with the pricing and payment terms presented to you for that service.
                Where applicable, you will be billed using the billing method you select through
                your account management page. Fees paid by you are non-refundable, except as
                provided in these Terms and Conditions or when required by law. In the event that
                any of our services are billed on a subscription basis (we call these
                “Subscriptions”), you will be billed in advance on a recurring, periodic basis (each
                period is called a “billing cycle”). Billing cycles are typically monthly or annual,
                depending on what subscription plan you select when purchasing a Subscription. Your
                Subscription will automatically renew at the end of each billing cycle unless you
                cancel auto-renewal through your online account management page, or by contacting
                our customer support team. While we will be sad to see you go, you may cancel
                auto-renewal on your Subscription at any time, in which case your Subscription will
                continue until the end of that billing cycle before terminating. You may cancel
                auto-renewal on your Subscription immediately after the Subscription starts if you
                do not want it to renew. Our Company may change the fees charged for the services at
                any time, provided that, for services billed on a subscription basis, the change
                will become effective only at the end of the then-current billing cycle of your
                Subscription. Our Company will provide you with reasonable prior written notice of
                any change in fees to give you an opportunity to cancel your Subscription before the
                change becomes effective.
                <br></br>
                <TermsHeader>Taxes and Overage Fees:</TermsHeader> Unless otherwise stated, you are
                responsible for any taxes (other than our Company’s income tax) or duties associated
                with the sale of the services, including any related penalties or interest
                (collectively, “Taxes”). You will pay our Company for the services without any
                reduction for Taxes. If our Company is obliged to collect or pay Taxes, the Taxes
                will be invoiced to you, unless you provide our Company with a valid tax exemption
                certificate authorized by the appropriate taxing authority or other documentation
                providing evidence that no tax should be charged. If you are required by law to
                withhold any Taxes from your payments to our Company, you must provide our Company
                with an official tax receipt or other appropriate documentation to support such
                payments. Unless otherwise stated, any overage fees incurred by you will be billed
                in arrears on a monthly basis. Overage fees which remain unpaid for 30 days after
                being billed are considered overdue. Failure to pay overage fees when due may result
                in the applicable service being limited, suspended, or terminated (subject to
                applicable legal requirements), which may result in a loss of your data associated
                with that service.
                <br></br>
                <TermsHeader>Promo Codes &amp; Referrals:</TermsHeader> Our Company reserves the
                right to deactivate any promo code for any reason, including suspicion of fraud, or
                abuse of promotional credit. If a user has already entered the promo code into
                his/her account, the code will be awarded. If a customer has yet to enter the code,
                credit may not be awarded for promo codes that have been deactivated. If you choose
                to use our referral service to tell a friend about our site, we will ask you for
                your friend&#39;s name, email address and other information. We will automatically
                send your friend a one-time email inviting him or her to visit the Site. We store
                this information for the purpose of sending this email and tracking the success of
                our referral program. Your friend may contact us to request that we remove this
                information from our database. There may be limits for referring friends to our
                Company. Once a limit has been reached, your account will no longer receive credit
                for each referral.
                <br></br>
                <TermsHeader>Trademarks:</TermsHeader> Names, titles, trademarks, service marks, and
                logos (collectively, the “Trademarks”) displayed on the Site are our registered
                and/or unregistered common law trademarks or those of third parties. Nothing
                contained in the Site should be construed as granting, by implication or otherwise,
                any license or right to use any Trademark displayed on the Site without our express
                written permission or that of the appropriate third party that owns the Trademark.
                Except as permitted by these Terms and Conditions, any unauthorized use of the
                Trademarks is prohibited.
                <br></br>
                <TermsHeader>Digital Millennium Copyright Agent:</TermsHeader> For purposes of the
                Digital Millennium Copyright Act (“DMCA”), we have designated an agent for notices
                of claimed infringement. If you have any objections governed by the DMCA, please
                contact the agent listed under the Section entitled “Contact Information” below. We
                provide this contact information for purposes of the DMCA only and reserve the right
                to respond to communication that is relevant for this purpose.
                <br></br>
                <TermsHeader>FTC Disclosure Policy:</TermsHeader> The Federal Trade Commission
                requires us to disclose any relationship we may have with any person or entity that
                provides something of value in connection with any endorsements made on the Site. No
                such advertising, sponsorship, paid insertions or other forms of compensation for
                endorsements are made to us in connection with the Site. We are not compensated to
                provide an opinion or an endorsement on products, services, websites and various
                other topics. The views and opinions expressed on the Site are purely our views and
                opinions or those of the users of the Site who maintain User Accounts (as defined
                below). If we claim or appear to be an expert on a certain topic or product or
                service area, we will only endorse such products or services that we believe, based
                on our expertise, are worthy of such endorsement, and we shall not be compensated
                for such endorsement.
                <br></br>
                <TermsHeader>
                  Links to Third Party Websites and Employee E-Mail Addresses:
                </TermsHeader>
                The Site may contain links to third party websites. The linked sites are not under
                our control and we are not responsible for the contents of any linked website. These
                links are provided as a convenience only and shall not be construed as our
                endorsement of, sponsorship of, or affiliation with a linked website. The Site may
                also contain links to the e-mail addresses of various employees for purposes of
                feedback and communication regarding the Site and our products and services. By
                clicking on a link to an employee’s e-mail address, you leave the Site and enter
                into a restricted access area which these terms and conditions do not govern. We
                assume no responsibility or liability with respect to your entering such restricted
                access areas.
                <br></br>
                <TermsHeader>Links to the Site:</TermsHeader> Links to our Site are prohibited
                unless you obtain our prior written consent. If you arrive at the Site from a third
                party link or as a result of any of the prohibited solicitations described in this
                paragraph below, please note that such link to or solicitation for the Site does not
                imply a relationship with, or endorsement of, the third party website or its
                content, purpose, policies or practices. You will not engage in solicitation
                activities on our behalf that refer potential customers to us including, but not
                limited to: distributing flyers, coupons and other printed promotional materials, or
                electronic equivalents; verbal solicitation (e.g., in-person referrals); initiating
                telephone calls, and sending e-mails.
                <br></br>
                <TermsHeader>Agent Administration; APIs.</TermsHeader> If you are a Site user as
                part of any group participation or through an Enterprise User, your administrator
                may be an administrator of your use of the Site (“Administrator”) who may have the
                ability to access and control your User Account. More than one person may be able to
                access an Enterprise User account as the Administrator and each such Administrator
                may have the ability to access and control your User Account. As such, your
                Administrator may have access to certain information about your User Account,
                information that you provide to the Site and may input information into the Site and
                your User Account for your access. You agree that by using the Site with an
                Administrator, we will have no liability for the data, itineraries or other
                information provided by Agent Administrator or for any actions, non- actions or
                omissions of such Administrator. You can also give third parties access to your
                information and account via application programming interfaces. Just remember that
                their use of your information will be governed by their privacy policies and terms.
                <br></br>
                <TermsHeader>Interactive Services:</TermsHeader> The Service may include interactive
                features and services, including social networking functionality, forums, message
                boards, ratings or review functionality, and similar services, in which you or third
                parties may send messages to Service users, and create, post, or store profile data,
                photographs, ratings or reviews, and other content on the Service (&quot;Interactive
                Services&quot;). While using the Service, your username will be available for
                viewing (together with public ranking, scoring information, point lists, averages
                information and/or activity outcome information, from time to time) by other users
                of the Site. We take no responsibility and assume no liability for any material
                posted, stored, or uploaded by you or any third party, or for any loss or damage to
                any of that material. Although we have no obligation to screen, edit, or monitor any
                material posted on or transmitted through the Service, we reserve the right, and
                have absolute discretion, to remove, screen, and edit any material posted, stored,
                or transmitted on or through the Service at any time and for any reason without
                notice. If you post material on or through the Service, then, unless we indicate
                otherwise, you (a) grant us and our subsidiaries and affiliates a nonexclusive,
                royalty-free, perpetual, irrevocable, and fully sublicensable right to use,
                reproduce, modify, adapt, publish, translate, create derivative works from,
                distribute, perform, and display such material throughout the world in any media;
                (b) grant us and our affiliates, subsidiaries, and sublicensees the right to use the
                name that you submit in connection with such material, if we choose; and (c)
                represent and warrant that you own and control all of the rights to the material
                that you post, or you otherwise have the right to post such material to the Service;
                and the use and posting of material you supply does not violate these Terms and
                Conditions, will not violate any rights of or cause injury to any person or entity,
                and will not otherwise create any harm or liability of any type for us or for third
                parties. At our Company’s sole discretion, we may terminate your use of the Site as
                a whole, or in part via disabling of the Interactive Services.
                <br></br>
                <TermsHeader>Non-Malicious Use:</TermsHeader> You agree not to use or launch any
                automated system, including without limitation, “robots,” “spiders,” or “offline
                readers,” that accesses the Site in a manner that sends more request messages to the
                Site’s servers in a given period of time than a human can reasonably produce in the
                same period by using a conventional on-line web browser. Notwithstanding the
                foregoing, we grant the operators of public search engines permission to use spiders
                to copy materials from the Site for the sole purpose of and solely to the extent
                necessary for creating publicly available searchable indices of the materials, but
                not caches or archives of such materials. We reserve the right to revoke these
                exceptions either generally or in specific cases. You agree not to collect or
                harvest any personally identifiable information, including account names, from the
                Site, nor to use the communication systems provided by the Site, for any commercial
                solicitation purposes. You agree not to circumvent, disable or otherwise interfere
                with security-related features of the Site or features that prevent or restrict use
                or copying of any Content or enforce limitations on use of the Site or the Content.
                <br></br>
                <TermsHeader>Law &amp; Order.</TermsHeader> We may disclose your information to
                third parties if we determine that such disclosure is reasonably necessary to (a)
                comply with the law; (b) protect any person from death or serious bodily injury; (c)
                prevent fraud or abuse of our Company or our users; or (d) protect our property
                rights.
                <br></br>
                <TermsHeader>Notice Required:</TermsHeader> We are not required, and do not collect
                Oklahoma, South Dakota and Vermont sales or use tax. Any purchase made is subject to
                Oklahoma, South Dakota and Vermont use tax unless it is specifically exempt from
                taxation. The purchase is not exempt merely because it is made over the internet, by
                catalogue or other remote means. The States of Oklahoma, South Dakota and Vermont,
                require all resident purchasers to report all purchases that were not taxed and pay
                tax on those purchases. The tax may be reported and paid on the individual income
                tax return for each state or by filing a consumer use tax return. All use tax forms
                and corresponding instructions are available on the website of each state’s
                Department of Revenue: for Oklahoma, www.tax.ok.gov; for South Dakota,
                www.state.sd.us; and for Vermont, www.state.vt.us/tax.
                <br></br>
                <TermsHeader>User Submissions:</TermsHeader> You agree that any information you
                provide through the Site will be truthful, accurate and complete. All information
                that you submit to us through the Site shall be deemed and remain our property and
                we may use, for any purpose, any ideas, concepts, know-how or techniques contained
                in such information. We have no obligations regarding such information or otherwise
                specifically agreed to in a separate writing or required by law. You acknowledge and
                agree that you will not provide any information or material to us or the Site that
                is defamatory, threatening, obscene, harassing, or otherwise unlawful, or that
                incorporates the proprietary material of another person or entity.
                <br></br>
                <TermsHeader>Receiving E-mail:</TermsHeader> We may, from time to time, send you
                e-mail about your User Account, orders or pricing changes. We may also send you
                e-mail with general information or special offers about products and services that
                may be of interest to you. In most cases, we will give you an opportunity to accept
                or decline the receipt of promotional e-mail.
                <br></br>
                <TermsHeader>Limited Access:</TermsHeader> From time to time, the Site may be
                inaccessible or inoperable for any reason, including equipment malfunctions, Site
                updates, maintenance and repairs and causes beyond our control or those that are not
                reasonably foreseeable.
                <br></br>
                <TermsHeader>Security:</TermsHeader> Transmissions over the Internet are never 100%
                secure or error-free. We take reasonable steps to protect your personal information
                from loss, misuse, and unauthorized access, disclosure, alteration and destruction.
                Our Company may use certain trusted third parties to help us provide, improve,
                protect, and promote our services. These third parties will access your information
                only to perform tasks on our behalf and in compliance with these Terms and
                Conditions.
                <br></br>
                <TermsHeader>WARRANTY DISCLAIMERS:</TermsHeader> THE INFORMATION AND DESCRIPTIONS
                CONTAINED ON THE SITE ARE NOT INTENDED TO BE COMPLETE DESCRIPTIONS OF ALL TERMS,
                EXCLUSIONS AND CONDITIONS APPLICABLE TO EVERY PRODUCT OR SERVICE OUR COMPANY OFFERS,
                BUT ARE PROVIDED FOR GENERAL INFORMATIONAL PURPOSES. OUR COMPANY MAKES NO
                REPRESENTATIONS OR WARRANTIES THAT THE CONTENT ON THE SITE IS SUITABLE FOR YOUR
                NEEDS, IS COMPLETE, TIMELY OR RELIABLE. ALL TEXT, IMAGES AND OTHER CONTENT ON THE
                SITE ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                FOR A PARTICULAR USE, AND/OR NON-INFRINGEMENT. OUR COMPANY DOES NOT WARRANT THAT THE
                SITE WILL BE UNINTERRUPTED OR FREE OF ERRORS, VIRUSES OR OTHER HARMFUL CODE, OR THAT
                ANY ERRORS OR DEFECTS WILL BE CORRECTED.
                <br></br>
                <TermsHeader>GENERAL DISCLAIMERS:</TermsHeader> OUR COMPANY ASSUMES NO
                RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR (I) ANY ERRORS OR OMISSIONS IN THE
                CONTENT ON THE SITE, (II) DAMAGE TO OR VIRUSES THAT MAY INFECT YOUR COMPUTER
                EQUIPMENT OR OTHER PROPERTY ON ACCOUNT OF YOUR USE OF THE SITE OR YOUR DOWNLOADING
                OF ANY TEXT, IMAGES OR OTHER CONTENT FROM THE SITE, OR (III) ANY DAMAGE ARISING IN
                CONNECTION WITH ANY FAILURE OF PERFORMANCE, INTERRUPTION, DEFECT, DELAY IN OPERATION
                OR TRANSMISSION, OR LINE OR SYSTEM FAILURE.
                <br></br>
                <TermsHeader>LIMITATION OF LIABILITY:</TermsHeader> OUR COMPANY, ITS AFFILIATES,
                MEMBERS, MANAGERS, OFFICERS, EMPLOYEES, AGENTS AND REPRESENTATIVES WILL HAVE NO
                LIABILITY FOR ANY DAMAGES, INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT,
                CONSEQUENTIAL, COMPENSATORY, SPECIAL, PUNITIVE, OR INCIDENTAL DAMAGES (EVEN IF OUR
                COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) ARISING OUT OF OR
                RELATING TO THE USE OF, RELIANCE ON OR INABILITY TO USE THE SITE OR THE CONTENT OR
                SERVICES PROVIDED ON OR RECEIVED FROM THE SITE. PLEASE NOTE THAT YOUR USE OF THE
                SITE IS AT YOUR SOLE RISK.
                <br></br>
                <TermsHeader>Indemnity:</TermsHeader> You agree to indemnify and hold us, our
                managers, members, officers, employees, agents and representatives harmless from any
                loss, liability, claim or demand, including reasonable attorneys’ fees and costs,
                due to or arising out of (i) your use of the Site; (ii) your breach of these Terms
                and Conditions; or (iii) your violation of any third party right.
                <br></br>
                <TermsHeader>Release:</TermsHeader> You release us and our manager(s), officers,
                member(s), employees, agents, representatives and licensors from any and all claims,
                demands, losses and damages of every kind and nature, whether known or unknown,
                arising out of or in any way relating to the Site, your use of the Site, other
                users’ use of the Site and any dispute or defence you have or claim to have against
                us or one or more users of the Site.
                <br></br>
                <TermsHeader>Reservation of Rights:</TermsHeader> We specifically reserve all rights
                that we do not expressly grant in these Terms and Conditions. Nothing on the Site or
                in these Terms and Conditions grants any right or license to use any property of any
                third party.
                <br></br>
                <TermsHeader>Contact Information:</TermsHeader> If you wish to contact us regarding
                (i) information on our products and services, (ii) permission to reproduce or use
                any Content on the Site, (iii) notices of claimed infringement under the DMCA, or
                (iv) any other reason, please contact:
                <br></br>
                <List>
                  <ListItem> Moving Mindz Pty Ltd </ListItem>
                  <ListItem> Shane Bilsborough </ListItem>
                  <ListItem> Phone: +61 415-842-057 </ListItem>
                  <ListItem> E-mail: contactus@movingmindz.co</ListItem>
                </List>
                <TermsHeader>Severance and Waiver:</TermsHeader> You acknowledge and agree that in
                the event any provision of these Terms and Conditions is held by a court to be
                invalid, unlawful, or unenforceable, the validity, legality, and/or enforceability
                of the remaining provisions contained in these Terms and Conditions will not in any
                way be affected or impaired. Our failure to exercise or enforce any right or
                provision under these Terms and Conditions will not constitute a waiver of such
                right or provision by us.
                <br></br>
                <TermsHeader>Venue and Choice of Law:</TermsHeader> These Terms and Conditions are
                governed by the laws of the State of Victoria, Australia without regard to its
                conflicts of laws principals. Any case, controversy, suit, action, or proceeding
                arising out of, in connection with, or related to these Terms and Conditions or the
                Site shall be brought in a federal or state court located in Melbourne, Australia,
                and you hereby waive any objection to the exclusive jurisdiction of such courts.
                <br></br>
                <TermsHeader>Entire Agreement:</TermsHeader> You agree that these Terms and
                Conditions, together with the Privacy Policy that is herein incorporated by
                reference in its entirety, constitute the entire agreement between you and us with
                respect to your use of the Site. You agree that these Terms and Conditions supersede
                any other agreements between you and us with respect to your use of the Site.
              </TermsBody>
              <Group>
                <CheckboxContainer>
                  <Checkbox
                    name="accepted"
                    label="I have read and accept the terms and conditions"
                    checked={values.accepted}
                    disabled={disabled}
                    onChange={handleChange}
                    error={errors.accepted}
                  />
                </CheckboxContainer>
                <ButtonWrapper>
                  <ModalButton
                    disabled={disabled}
                    type="submit"
                    variant={values.accepted ? 'primary' : 'secondary'}
                  >
                    Accept
                  </ModalButton>
                </ButtonWrapper>
              </Group>
            </FormColumn>
          </ColumnWrapper>
        </form>
      </Container>
    </>
  );
};

export default TermsAndConditionsForm;
