import React, { FC, useEffect, ChangeEvent } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import moment from 'moment-timezone';

import validationSchema, { EventSchema } from '../../validators/eventSchema';
import CreateEvent from './CreateEvent';
import { ApplicationState } from '../../../../lib/Store';
import { createEvent } from '../../../../lib/Api/masterAdmin/Event';
import { loadActivityTypes } from '../../../../lib/Api/ActivityTypes';
import { ActivityType, ErrorResponse } from '../../../../lib/Types';
import { timeOptions } from '../../../../CONSTANTS';
import { buildTimezoneMoment, getClientTimezoneOffset } from '../../../../lib/TimeUtils';

export interface CreateEventContainerProps {
  activityTypes: ActivityType[];
  loading: boolean;
  createEvent: (data: EventSchema) => any;
  loadActivityTypes: () => any;
  cancel: () => void;
  onSuccess: () => void;
}

const formikEnhancer = withFormik<CreateEventContainerProps, EventSchema>({
  validationSchema,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: () => validationSchema.cast(),
  handleSubmit: async (values: EventSchema, { props, setErrors }) => {
    const submitValues = { ...values };
    submitValues.startDate = buildTimezoneMoment(
      submitValues.startDate,
      submitValues.startDateTime,
      submitValues.timezone
    );
    submitValues.endDate = buildTimezoneMoment(
      submitValues.endDate,
      submitValues.endDateTime,
      submitValues.timezone
    );
    submitValues.registrationsStartDate = buildTimezoneMoment(
      submitValues.registrationsStartDate,
      submitValues.registrationsStartDateTime,
      submitValues.timezone
    );
    submitValues.registrationsEndDate = buildTimezoneMoment(
      submitValues.registrationsEndDate,
      submitValues.registrationsEndDateTime,
      submitValues.timezone
    );

    // convert to cents before sending
    submitValues.costPerParticipant = submitValues.costPerParticipant * 100;

    const { error }: { error: ErrorResponse } = await props.createEvent(submitValues);
    if (!error) props.onSuccess();
    if (error.data.field) setErrors({ [error.data.field]: error.message });
  }
});

const CreateEventContainer: FC<CreateEventContainerProps & FormikProps<EventSchema>> = ({
  setFieldValue,
  setValues,
  cancel,
  onSuccess,
  loadActivityTypes,
  ...props
}) => {
  const initActivityTypes = async () => {
    const { data } = await loadActivityTypes();

    if (data) {
      const ids = data.map((item: ActivityType) => item.activityTypeId);
      setFieldValue('activitiesToInclude', ids);
    }
  };

  useEffect(() => {
    initActivityTypes();
  }, []);

  const changeCustom = (name: string, data: any) => setFieldValue(name, data);
  const changeDateCustom = (name: string, date: Date) => {
    const clientTimezone = getClientTimezoneOffset();
    const eventTimezoneOffset = moment.tz(moment(), props.values.timezone).utcOffset();
    setFieldValue(
      name,
      moment(date)
        .add('minutes', eventTimezoneOffset * -1)
        .add('minutes', clientTimezone * -1)
        .valueOf()
        .toString()
    );
  };

  const changeTimeCustom = (timeKey: string, timeOptionId: number) => {
    if (typeof timeOptionId === 'number') {
      const timeVal = timeOptions[timeOptionId - 1];

      setValues({
        ...props.values,
        [timeKey]: timeVal.value
      });
    }
  };

  const handleSetCost = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('costPerParticipant', parseFloat(e.target.value).toFixed(2));
  };

  return (
    <CreateEvent
      {...props}
      onCancel={cancel}
      onSuccess={onSuccess}
      handleChangeCustom={changeCustom}
      handleDateChangeCustom={changeDateCustom}
      handleTimeChangeCustom={changeTimeCustom}
      handleSetCost={handleSetCost}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  activityTypes: state.activityTypeState.activityTypes,
  loading: state.loadingState.apiCallsInProgress > 0
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createEvent: (data: EventSchema) => createEvent(data),
      loadActivityTypes: () => loadActivityTypes()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(formikEnhancer(CreateEventContainer));
