import React, { FC, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import EditTeamContainer from '../Teams/components/EditTeam/EditTeamContainer';
import { Button, PageWrapper } from '../../components/UI';
import { MainContentContainerPadded, DarkBlueHeaderL, TurqText } from '../../Themes';

import { initialState as intialTeamState } from '../../lib/Store/contexts/team/reducers';
import { setTeam } from '../../lib/Store/contexts/team/actions';
import { createEmptyTeam } from '../../lib/Api/Team';
import { LoadEventFilter, loadEventOptions, LoadEventOptions } from '../../lib/Api/Event';

import { ApplicationState } from '../../lib/Store';
import { Company, Event, Team } from '../../lib/Types';
import { Column1, TopHd } from '../TeamEmployeeDashboard/components/CreateTeam/Styles';

export interface AdminTeamBuilderProps {
  createEmptyTeam: (data: { companyId: number; eventId: number }) => void;
  setTeam: (team: Team) => void;
  onLoadEventOptions: LoadEventOptions;
  companyOptions: Company[];
  eventList: Event[];
  selectedEventId: number;
  selectedCompanyIds: number[];
  team: Team;
  CPMCompanyId: number;
  CPMEventId: number;
}

export interface TeamBuilderSelectedEvent {
  name: string;
  value: number;
  numberOfMembers: number;
}

export interface TeamBuilderSelectedCompany {
  name: string;
  companyId: number;
}

const AdminTeamBuilder: FC<AdminTeamBuilderProps> = ({
  team,
  companyOptions,
  eventList,
  createEmptyTeam,
  setTeam,
  onLoadEventOptions,
  CPMCompanyId,
  CPMEventId,
  selectedEventId,
  selectedCompanyIds
}) => {
  // Company already offers "Options" type from redux store, Events just has list of
  // flat event objects, so i'm restructuring events into an options type using the list from redux
  const eventOptions =
    eventList?.map(event => ({
      name: event.name,
      value: event.eventId,
      numberOfMembers: event.teamSize
    })) || [];

  const selectedEvent = eventOptions?.length
    ? eventOptions.find(opt => opt.value === selectedEventId)
    : null;

  const selectedCompany = selectedCompanyIds?.length
    ? companyOptions?.find(opt => selectedCompanyIds[0] === opt?.companyId)
    : null;

  const isCompanyAdmin = CPMCompanyId && CPMEventId;

  useEffect(() => {
    return () => setTeam(intialTeamState.team);
  }, []);

  useEffect(() => {
    if (isCompanyAdmin) return;
    (async () => {
      // Load event options - filter by current or future events
      await onLoadEventOptions({
        endDateMoreThan: moment()
          .valueOf()
          .toString()
      });
    })();
  }, []);

  if (!eventOptions?.length) {
    return (
      <PageWrapper>
        <MainContentContainerPadded>
          <DarkBlueHeaderL>No valid events to generate teams for.</DarkBlueHeaderL>
        </MainContentContainerPadded>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      {/* If a team has not yet been generated (no team.eventId), show Empty Team Creator View
          otherwise show the edit team view for the Empty Team just created */}
      {!team.eventId && (
        <>
          <MainContentContainerPadded>
            {/* If not company admin, show selectors */}
            {!isCompanyAdmin && (
              <TopHd>
                <Column1>
                  <DarkBlueHeaderL>
                    {selectedEventId ? (
                      <>
                        Create a team for the <TurqText>{selectedEvent?.name}</TurqText> Event
                      </>
                    ) : (
                      <>Select an event above.</>
                    )}
                  </DarkBlueHeaderL>
                </Column1>
                <div style={{ margin: '0 1rem' }}></div>
                <Column1>
                  <DarkBlueHeaderL>
                    {selectedCompany ? (
                      <>
                        for <TurqText>{selectedCompany?.name}</TurqText>
                      </>
                    ) : (
                      <>Select a company above.</>
                    )}
                  </DarkBlueHeaderL>
                </Column1>
              </TopHd>
            )}

            {/* Master Admin View */}
            {!isCompanyAdmin &&
              selectedCompany?.companyId &&
              selectedEvent?.value &&
              !team.eventId && (
                <>
                  <br />
                  <Button
                    variant="admin-primary"
                    onClick={() =>
                      createEmptyTeam({
                        companyId: selectedCompany?.companyId,
                        eventId: selectedEvent?.value
                      })
                    }
                  >
                    Create Empty Team
                  </Button>
                </>
              )}

            {/* CompanyAdmin View */}
            {isCompanyAdmin && CPMCompanyId && CPMEventId && !team.eventId && (
              <>
                <br />
                <Button
                  variant="admin-primary"
                  onClick={() =>
                    createEmptyTeam({
                      companyId: CPMCompanyId,
                      eventId: CPMEventId
                    })
                  }
                >
                  Create Empty Team
                </Button>
              </>
            )}
          </MainContentContainerPadded>
        </>
      )}

      {/* If either Company or Master Admin & a Team EventId is present, show the builder */}
      {((selectedCompany?.companyId && selectedEvent?.value) || (CPMCompanyId && CPMEventId)) &&
        team.eventId && (
          <>
            <EditTeamContainer />
          </>
        )}
    </PageWrapper>
  );
};

const mapStateToProps = ({
  eventCompanyPageFilterState,
  permissionsState,
  teamsState,
  userState
}: ApplicationState) => ({
  selectedEventId: eventCompanyPageFilterState.selectedEventId,
  selectedCompanyIds: eventCompanyPageFilterState.selectedCompanyIds,
  companyOptions: eventCompanyPageFilterState.companyOptions,
  eventList: eventCompanyPageFilterState.eventOptions,
  permissions: permissionsState.permissions,
  team: teamsState.team,
  CPMCompanyId: userState.userCompany.companyId,
  CPMEventId: userState.userEvent.eventId
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createEmptyTeam: (data: { companyId: number; eventId: number }) => createEmptyTeam(data),
      setTeam: (team: Team) => setTeam(team),
      onLoadEventOptions: (filter?: LoadEventFilter) => loadEventOptions(filter) as any
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdminTeamBuilder);
