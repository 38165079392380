import React, { FC, useState, useCallback } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { DisplayState } from './PictureFormContainer';
import { Container, CropTitle, UploadButton } from './Styles';
import { FilePreview } from '../../../../lib/Types';

export interface PictureCropProps {
  file: File;
  updateContainerState: (image: FilePreview) => void;
  setDisplayState: (displayState: DisplayState) => void;
}

const getCroppedImgBlob = (image: HTMLImageElement, crop: Crop): Promise<Blob> => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }
      resolve(blob);
    }, 'image/png');
  });
};

const PictureCrop: FC<PictureCropProps> = ({ file, updateContainerState, setDisplayState }) => {
  const [imgRef, setImgRef] = useState<HTMLImageElement>(null);
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState<Crop>({ aspect: 1 });
  const onLoad = useCallback(img => setImgRef(img), [setImgRef]);

  const completeCrop = React.useCallback(async () => {
    let fp: FilePreview;
    if (crop.width && crop.height && imgRef) {
      URL.revokeObjectURL(imgRef.src);
      const croppedImgBlob = await getCroppedImgBlob(imgRef, crop);
      const url = URL.createObjectURL(croppedImgBlob);
      fp = new File([croppedImgBlob], file.name, { type: file.type });
      fp.preview = url;
    } else {
      // Empty crop, generate new object URL,
      // as the previous one has been revoked by the effect hook in PictureForm
      fp = file;
      fp.preview = URL.createObjectURL(file);
    }
    updateContainerState(fp);
    setDisplayState(DisplayState.UPLOAD);
  }, [crop, file, updateContainerState, setDisplayState]);

  React.useEffect(() => {
    const reader = new FileReader(); // Load file data so canvas can work with it
    reader.addEventListener('load', () => setImgSrc(reader.result as string));
    reader.readAsDataURL(file);
  }, [file]);

  return (
    <Container>
      <CropTitle>Crop your photo</CropTitle>
      <ReactCrop
        src={imgSrc}
        crop={crop}
        onChange={setCrop}
        onImageLoaded={onLoad}
        circularCrop={true}
        imageStyle={{ background: 'white' }}
      />
      <UploadButton variant="primary" type="button" onClick={completeCrop}>
        Set
      </UploadButton>
    </Container>
  );
};

export default PictureCrop;
