import React from 'react';
import { useHistory } from 'react-router';

import { ExternalPageWrapper, Button } from '../../components/UI';
import CreateOrEditCompanyForm from '../Companies/CreateOrEditCompany/CreateOrEditCompanyForm';

import { Container, Header } from './Styles';

interface UserOnboardingProps {}

const CompanyOnboarding: React.FC<UserOnboardingProps> = () => {
  const history = useHistory();

  const handleSuccess = async () => {
    history.push('/Onboarding/ProfilePicture');
  };

  return (
    <ExternalPageWrapper header="Welcome! Create your account to get started.">
      <Container>
        <Header>Welcome, already have an account?</Header>
        <Button
          value={'Login'}
          variant="secondary"
          width={'40%'}
          margin="0 0 5rem 0"
          onClick={() => history.push('/Login')}
        >
          Login
        </Button>
        <Header>Or, Create your account to get started.</Header>
        <CreateOrEditCompanyForm onSuccess={handleSuccess} publicSignUp />
      </Container>
    </ExternalPageWrapper>
  );
};

export default CompanyOnboarding;
