/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'Sorry, ${path} is required.'
  },
  string: {
    email: 'You must provide a valid email address.',
    min: 'The ${path} provided must be at least ${min} characters.'
  }
});
