import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PaddedContainer = styled.div`
  padding-left: ${({ theme }) => theme.padding * 2}px;
`;

export const ListInputWrapper = styled.div`
  margin-top: 4rem;
`;
