import React, { FC } from 'react';
import Lottie from 'lottie-react-web';
import logoJSON from '../../../assets/animations/logo.json';
import { Wrapper } from './Styles';

const options = {
  animationData: logoJSON,
  loop: true,
  autoplay: true,
  renderer: 'svg'
};

const AnimatedLogo: FC = () => {
  return (
    <Wrapper>
      <Lottie options={options} />
    </Wrapper>
  );
};

export default AnimatedLogo;
