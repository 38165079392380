import React, { FC, useState } from 'react';
import {
  OptionButtonWrapper,
  OptionInfoWrapper,
  RadioOptionBody,
  RadioOptionLabel,
  RadioOptionWrapper,
  RadioOptionIconWrapper,
  Wrapper,
  RadioOptionIcon
} from './Styles';
import { radioOn, radioOff } from '../../../assets/icons/index';

interface RadioOptionProps {
  selected: boolean;
  label: string;
  icon?: string;
  image?: string;
  body: string;
  inline?: boolean;
  margin?: boolean;
  onClick: (e: any) => void;
}

export const RadioOption: FC<RadioOptionProps> = ({
  selected,
  label,
  icon,
  image,
  inline,
  body,
  onClick,
  margin = true
}) => {
  const radioIcon = selected ? radioOn : radioOff;
  return (
    <RadioOptionWrapper onClick={onClick} margin={margin} inline={inline}>
      <OptionButtonWrapper selected={selected}>
        <img alt="radio option" src={radioIcon} />
      </OptionButtonWrapper>
      {icon && (
        <RadioOptionIconWrapper>
          <RadioOptionIcon image={icon} />
        </RadioOptionIconWrapper>
      )}
      {image && (
        <RadioOptionIconWrapper>
          <img src={icon} alt={label} />
        </RadioOptionIconWrapper>
      )}
      <OptionInfoWrapper inline={inline}>
        <RadioOptionLabel inline={inline}>{label}</RadioOptionLabel>
        {body && <RadioOptionBody inline={inline}>{body}</RadioOptionBody>}
      </OptionInfoWrapper>
    </RadioOptionWrapper>
  );
};

export interface RadioGroupOptions {
  label: string;
  value?: number | string;
  body?: string;
  icon?: string;
}

export interface RadioGroupProps {
  horizontal?: boolean;
  columns?: number;
  inline?: boolean;
  options: RadioGroupOptions[];
  valueKey: string;
  initialValue?: string;
  handleOptionClick: (name: string, value: any) => void;
}

const RadioGroup: FC<RadioGroupProps> = ({
  options,
  handleOptionClick,
  valueKey,
  inline,
  initialValue,
  horizontal,
  columns
}) => {
  const [optionSelected, setOptionSelected] = useState<string>(initialValue ?? options[0].label);

  const OptionsJSX = options.map((d, i) => {
    return (
      <RadioOption
        key={`${i}-${d.label}`}
        label={d.label}
        icon={d.icon}
        body={d.body}
        inline={inline}
        selected={optionSelected === d.label}
        onClick={() => {
          setOptionSelected(d.label);
          handleOptionClick(valueKey, d.value);
        }}
      />
    );
  });

  return (
    <Wrapper horizontal={horizontal} columns={columns} inline={inline}>
      {OptionsJSX}
    </Wrapper>
  );
};

export default RadioGroup;
