import React, { FC } from 'react';

import {
  SubHeading,
  InnerContainer,
  StatSquareWrapper,
  HeroCount,
  CountContainer,
  BodyCopy
} from './Styles';

interface StatSquare {
  title: string;
  value: number;
  color: string;
  body?: string;
  icon?: string;
}

const StatSquare: FC<StatSquare> = ({ title, value, body = '', icon = '', color }) => {
  return (
    <StatSquareWrapper>
      <InnerContainer>
        <SubHeading>{title}</SubHeading>
        <CountContainer>
          <img src={icon} alt={''} />
          <HeroCount color={color}>{value}</HeroCount>
        </CountContainer>
        <BodyCopy>{body}</BodyCopy>
      </InnerContainer>
    </StatSquareWrapper>
  );
};
export default StatSquare;
