import * as yup from 'yup';

const replaceSchema = yup.object({
  teamId: yup.number().required(),
  participantId: yup.string(),
  replaceParticipantId: yup.string().required()
});

export type ReplaceSchema = yup.InferType<typeof replaceSchema>;

export default replaceSchema;
