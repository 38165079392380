import React, { FC } from 'react';
import moment from 'moment-timezone';

import { Group, Heading, InlineInputWrapper, InlineInput } from '../Styles';
import { DatePicker, SelectDropdown } from '../../../../../components/UI';
import { FormikFormComponent } from '../../../../../lib/Types';
import { EventSchema } from '../../../validators/eventSchema';
import { timeOptions } from '../../../../../CONSTANTS';
import { findTimeOptionIndex, getClientTimezoneOffset } from '../../../../../lib/TimeUtils';
import { clockIcon } from '../../../../../assets/icons';

interface RegistrationDatesProps extends FormikFormComponent<EventSchema> {
  onChangeDate: (name: string, value: Date | null) => void;
  handleChangeTime: (nane: string, data: any) => void;
}

const RegistrationDates: FC<RegistrationDatesProps> = ({
  values,
  errors,
  onChangeDate,
  handleChangeTime
}) => {
  const clientTimezone = getClientTimezoneOffset();
  const eventTimezoneOffset = moment.tz(moment(), values.timezone).utcOffset();
  return (
    <Group>
      <Heading>Registration {values.timezone && `(${values.timezone})`}</Heading>
      <InlineInputWrapper>
        <InlineInput>
          <DatePicker
            required
            id="registrationsStartDate"
            margin={'margin-top: 0.75rem'}
            date={
              values.registrationsStartDate
                ? moment(values.registrationsStartDate, 'x')
                    .add('minutes', clientTimezone)
                    .add('minutes', eventTimezoneOffset)
                    .toDate()
                : null
            }
            datePlaceholder="Start Date"
            error={errors.registrationsStartDate}
            onChange={onChangeDate}
          />
          <DatePicker
            required
            id="registrationsEndDate"
            margin={'margin-top: 2.5rem'}
            date={
              values.registrationsEndDate
                ? moment(values.registrationsEndDate, 'x')
                    .add('minutes', clientTimezone)
                    .add('minutes', eventTimezoneOffset)
                    .toDate()
                : null
            }
            datePlaceholder="End Date"
            error={errors.registrationsEndDate}
            onChange={onChangeDate}
          />
        </InlineInput>
        <InlineInput>
          <SelectDropdown
            required
            margin={'margin-top: 0.75rem'}
            data={timeOptions}
            value={
              values.registrationsStartDateTime &&
              findTimeOptionIndex(values.registrationsStartDateTime).id
            }
            valueProp={'id'}
            displayProp={'value'}
            placeholder={'Start Time'}
            error={errors.registrationsStartDateTime}
            icon={clockIcon}
            onChange={val => {
              handleChangeTime('registrationsStartDateTime', val);
            }}
          />
          <SelectDropdown
            required
            margin={'margin-top: 2.5rem'}
            data={timeOptions}
            value={
              values.registrationsEndDateTime &&
              findTimeOptionIndex(values.registrationsEndDateTime).id
            }
            valueProp={'id'}
            displayProp={'value'}
            placeholder={'End Time'}
            error={errors.registrationsEndDateTime}
            icon={clockIcon}
            onChange={val => handleChangeTime('registrationsEndDateTime', val)}
          />
        </InlineInput>
      </InlineInputWrapper>
    </Group>
  );
};

export default RegistrationDates;
