import React, { FC } from 'react';
import Button from '../../../../../../components/UI/Button';
import { Group, Heading } from '../../ModalFormContainer/Styles';
import theme from '../../../../../../theme';
import { ReplaceNewUserSchema } from '../../../../validators/replaceNewUserSchema';
import { FormikFormComponent, ParticipantListView } from '../../../../../../lib/Types';
import { Input } from '../../../../../../components/UI';

interface ReplaceNewUserProps extends FormikFormComponent<ReplaceNewUserSchema> {
  participant: ParticipantListView;
  handleDropdownChange: (name: string, value: any) => void;
}

const ReplaceNewUser: FC<ReplaceNewUserProps> = ({ values, errors, handleChange }) => (
  <Group>
    <Heading>Invite a new user</Heading>
    <Input
      required
      id="firstName"
      name="firstName"
      value={values.firstName}
      error={errors.firstName}
      label="First Name"
      type="text"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
    <Input
      required
      id="lastName"
      name="lastName"
      value={values.lastName}
      error={errors.lastName}
      label="Last Name"
      type="lastName"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
    <Input
      required
      id="email"
      name="email"
      value={values.email}
      error={errors.email}
      label="Email"
      type="email"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
    <Button type="submit" variant={'admin-primary'} width={'130px'} minWidth={'130px'}>
      Invite User
    </Button>
  </Group>
);

export default ReplaceNewUser;
