import React, { FC, CSSProperties } from 'react';

import {
  CloseButton,
  CloseButtonContainer,
  Icon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Wrapper
} from './Styles';

interface ModalProps {
  width?: number;
  tabletWidth?: number;
  top?: number;
  left?: number;
  tabletLeft?: number;
  alpha?: number;
  style?: CSSProperties;
  title?: string | JSX.Element;
  icon?: string;
  onClose?: () => void;
  buttons?: JSX.Element[];
  padding?: string;
  titlePadding?: string;
  headerPadding?: string;
  showHeader?: boolean;
  showFooter?: boolean;
  minWidth?: string;
  overflowVisible?: boolean;
}

const Modal: FC<ModalProps> = ({
  width = 50,
  tabletWidth = 50,
  top = 25,
  left = 25,
  tabletLeft = 25,
  alpha = 0.75,
  children,
  style,
  title,
  icon,
  buttons,
  onClose,
  padding,
  titlePadding,
  headerPadding,
  showHeader = true,
  showFooter = true,
  minWidth,
  overflowVisible
}) => {
  return (
    <Wrapper alpha={alpha} minWidth={minWidth}>
      <ModalContainer
        padding={padding}
        width={width}
        tabletWidth={tabletWidth}
        top={top}
        left={left}
        tabletLeft={tabletLeft}
        alpha={alpha}
        style={style}
        minWidth={minWidth}
        overflowVisible={overflowVisible}
      >
        {onClose && showHeader && (
          <CloseButtonContainer>
            <CloseButton onClick={onClose} />
          </CloseButtonContainer>
        )}
        <ModalHeader headerPadding={headerPadding}>
          <ModalTitle titlePadding={titlePadding}>{title}</ModalTitle>
          {icon && <Icon src={icon} />}
        </ModalHeader>
        {children}
        {showFooter && <ModalFooter>{buttons}</ModalFooter>}
      </ModalContainer>
    </Wrapper>
  );
};
export default Modal;
