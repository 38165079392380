import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

import { DEFAULT_PIC } from '../../../CONSTANTS';

import {
  LeaderBoardWrapper,
  Title,
  ButtonWrapper,
  Button,
  LeaderBoardEntry,
  EntryMain,
  EntryDetails,
  EntryIndex,
  EntryName,
  EntryAssociation,
  EntryCount,
  ProfileImage
} from './Styles';

import { ApplicationState } from '../../../lib/Store';
import { User, ParticipantRankView, PaginationFilter } from '../../../lib/Types';
import {
  setWaterCoolerLeaderBoardFilter,
  setIndividualRankings
} from '../../../lib/Store/contexts/participant/leaderBoard/actions';

export interface TopLeaderBoardProps {
  user: User;
}

const TopLeaderBoard: React.FC<TopLeaderBoardProps> = ({ user }) => {
  const Dispatch = useDispatch();
  const { individualRankings, ownRanking, waterCoolerLeaderBoardFilter: filter } = useSelector(
    ({ participantLeaderBoardState }: ApplicationState) => ({
      individualRankings: participantLeaderBoardState.individualRankings,
      ownRanking: participantLeaderBoardState.ownRanking,
      waterCoolerLeaderBoardFilter: participantLeaderBoardState.waterCoolerLeaderBoardFilter
    })
  );

  const updateRankFilter = (newFilter: PaginationFilter) => {
    Dispatch(setIndividualRankings({ results: [], count: 0 }));
    Dispatch(setWaterCoolerLeaderBoardFilter(newFilter));
  };

  const getPointsValue = (user: ParticipantRankView) => {
    if (filter.orderColumn === 'totalPoints') return user.totalPoints;
    if (filter.orderColumn === 'movementPoints') return user.movementPoints;
    if (filter.orderColumn === 'connectionPoints') return user.connectionPoints;
  };

  const getRank = (user: ParticipantRankView) => {
    if (filter.orderColumn === 'totalPoints') return user.totalPointsRank;
    if (filter.orderColumn === 'movementPoints') return user.movementPointsRank;
    if (filter.orderColumn === 'connectionPoints') return user.connectionPointsRank;
  };

  const rankingsList = () => {
    if (!individualRankings.results.length || !ownRanking) return;

    const data = individualRankings.results
      .filter(e => e.userId !== user.userId)
      .map(user => {
        return (
          <LeaderBoardEntry key={user.userId}>
            <EntryMain>
              <ProfileImage image={user.profileImage || DEFAULT_PIC} />
              <EntryDetails>
                <EntryIndex>
                  {`${getRank(user)}.`}
                  <EntryName>{user.fullName}</EntryName>
                </EntryIndex>
                <EntryAssociation>
                  {`${user?.teamName && `${user.teamName} /`} ${user.companyName}`}
                </EntryAssociation>
              </EntryDetails>
            </EntryMain>
            <EntryCount>{getPointsValue(user)}</EntryCount>
          </LeaderBoardEntry>
        );
      });

    return (
      <>
        <LeaderBoardEntry>
          <EntryMain highlighted>
            <ProfileImage image={ownRanking.profileImage || DEFAULT_PIC} />
            <EntryDetails>
              <EntryIndex>
                {`${getRank(ownRanking)}.`}
                <EntryName>{ownRanking.fullName}</EntryName>
              </EntryIndex>
              <EntryAssociation>
                {ownRanking?.teamName
                  ? `${ownRanking.teamName} / ${ownRanking.companyName}`
                  : `${ownRanking.companyName}`}
              </EntryAssociation>
            </EntryDetails>
          </EntryMain>
          <EntryCount>{getPointsValue(ownRanking)}</EntryCount>
        </LeaderBoardEntry>
        {data}
      </>
    );
  };

  return (
    <LeaderBoardWrapper>
      <Title>Leaderboard</Title>
      <ButtonWrapper>
        <Button
          selected={filter.orderColumn === 'totalPoints'}
          onClick={() => updateRankFilter({ ...filter, orderColumn: 'totalPoints' })}
        >
          Overall
        </Button>
        <Button
          selected={filter.orderColumn === 'connectionPoints'}
          onClick={() => updateRankFilter({ ...filter, orderColumn: 'connectionPoints' })}
        >
          Connection
        </Button>
        <Button
          selected={filter.orderColumn === 'movementPoints'}
          onClick={() => updateRankFilter({ ...filter, orderColumn: 'movementPoints' })}
        >
          Movement
        </Button>
      </ButtonWrapper>
      {rankingsList()}
    </LeaderBoardWrapper>
  );
};

export default connect(({ userState }: ApplicationState) => ({
  user: userState.userData
}))(TopLeaderBoard);
