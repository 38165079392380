import styled from 'styled-components/macro';
import { DarkBlueBodyS } from '../../../../Themes';

export const ApprovalStatusWrap = styled(DarkBlueBodyS)`
  width: 80%;
`;

/* ----------------------- Modal Styles ----------------------- */

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.padding * 4}px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormColumn = styled.div`
  flex: 1;
  &:nth-child(1) {
    padding-right: ${({ theme }) => theme.padding * 8}px;
  }
  &:nth-child(2) {
    padding-right: ${({ theme }) => theme.padding * 3}px;
  }
`;

export const Container = styled.div`
  width: 50%;
  overflow-y: scroll;
  height: auto;
  display: flex;
  ::-webkit-scrollbar {
    display: none;
  }
`;

/* ----------------------- Table Text Styles ----------------------- */

// Default
export const TableText = styled.div`
  color: #515151;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: clamp(1.15rem, 1vw, 1.35rem);
  line-height: ${({ theme }) => theme.fonts.subBody * 1.5}rem;
  text-align: left;
`;

export const Joined = styled(TableText)`
  color: #55dad1;
  font-style: italic;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const NotJoined = styled(Joined)`
  color: #ff5e86;
`;

export const ResendText = styled(TableText)`
  color: #658cd7;
  font-style: italic;
  cursor: pointer;
  text-align: left;
`;

export const RemoveText = styled(ResendText)`
  color: ${({ theme }) => theme.primary.red};
`;

/* ----------------------- Table Structure ----------------------- */

export const Row = styled.div<RowProps>`
  display: table-row;
  width: 100%;
  border-radius: 6rem;
  margin: 1px 0;
  padding: 3px 5px;
  background-color: ${({ theme }) => theme.backgrounds.white};
  height: 36px;

  &:nth-child(odd) {
    background-color: #f7f7f7;
  }
`;

export const TableCell = styled(TableText)`
  display: table-cell;
  text-align: left;
  white-space: nowrap;
  padding: 1px calc(0.3rem + 0.3vw) 1px calc(0.4rem + 0.4vw);
  vertical-align: middle;
  min-width: fit-content;

  &:first-child {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    max-width: 36px;
    min-width: 36px;
    height: 36px;
    padding: 0 0 0 2rem;
  }
  &:nth-child(2) {
    max-width: 36px;
    min-width: 36px;
    height: 36px;
    padding: 0 0.1rem;
  }
  &:nth-child(3) {
    padding: 0 0 0 0.1rem;
  }
  &:nth-child(6) {
    padding-left: 0;
  }
  &:last-child {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    max-width: 46px;
    min-width: 46px;
    line-height: unset;
  }
`;

/* --------------------- Add Participant ----------------------- */

interface RowProps {
  odd: boolean;
}

export const AddMemberRow = styled.div<RowProps>`
  display: flex;
  border-radius: 6rem;
  padding: 4px 0 0 0;
  background-color: ${({ odd }) => (odd ? '#ffffff' : '#f7f7f7')};
  width: 100%;
  margin-bottom: 1rem;
  height: 36px;
  vertical-align: middle;
`;

export const AddText = styled(ResendText)`
  white-space: nowrap;
`;

/* ------------------- Table Components ---------------------- */

// Wraps the Table Header and Table
export const TableContainer = styled.div`
  width: 95%;
`;

// Wraps the TeamTable and the Add Participant Row
export const TableWrap = styled.div`
  width: 100%;
  display: table;
`;
