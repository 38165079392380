import styled from 'styled-components/macro';

import { Button } from '../../components/UI';

import { participantRegisterForEvent } from '../../assets/img';
import { MainContentContainer, DarkBlueBodyS } from '../../Themes';

export const ParticipantRegistration = styled(MainContentContainer)`
  background: no-repeat right bottom url(${participantRegisterForEvent});
  background-color: ${({ theme }) => theme.primary.white};
  background-size: 100%;
  flex-direction: column;
  margin-top: 2rem;
  min-height: 550px;

  @media (max-width: ${({ theme }) => theme.device.laptopL}px) {
    min-height: 450px;
  }
  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    min-height: 330px;
  }
  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    border-radius: 0;
    margin-top: 4rem;
  }
`;

export const Submit = styled(Button)`
  margin-top: 2rem;
  width: 100%;
  border: none;
  color: #658cd7;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-image: linear-gradient(
    90deg,
    rgba(117, 219, 255, 0.4) 0%,
    rgba(169, 168, 255, 0.4) 100%
  );

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    min-width: 10rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.padding}px;
  @media (max-width: ${({ theme }) => theme.device.laptop + 120}px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Cancel = styled(Button)`
  margin: 2rem 1rem 0 0;
  width: 100%;
  border: none;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  opacity: 0.7;
`;

export const RegisterModalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ParticipantCapModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalGraphic = styled.img`
  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    display: none;
  }
  height: 179px;
  margin-right: 3rem;
`;

export const ModalContent = styled.div`
overflow-y: scroll
`;

export const ContactProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
`;

export const ImageWrap = styled.div`
  width: 100%;
  margin-right: 2rem;
`;

export const ContactImage = styled.div<{ src: string }>`
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background: center / cover ${props => `url('${props.src}') no-repeat`};
`;
