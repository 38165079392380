import {
  AdminDashBoardData,
  AdminDashboardStats,
  RegistrationsByCompanyView
} from '../../../../Types';

export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';

export interface DashboardState {
  stats: AdminDashboardStats;
  companyRegistrations: RegistrationsByCompanyView[];
}

export interface SetDashBoardDataAction {
  type: typeof SET_DASHBOARD_DATA;
  dashBoardData: AdminDashBoardData;
}

export type DashboardActionTypes = SetDashBoardDataAction;
