import React, { FC } from 'react';
import { Group, Heading, Body } from '../Styles';
import { Input } from '../../../../../components/UI';
import theme from '../../../../../theme';
import { FormikFormComponent } from '../../../../../lib/Types';
import { EventSchema } from '../../../validators/eventSchema';

const GracePeriod: FC<FormikFormComponent<EventSchema>> = ({ values, errors, handleChange }) => (
  <Group>
    <Heading>Grace Period</Heading>
    <Body>This allows participants to continue to add in activity data after the event ends.</Body>
    <Input
      id="gracePeriod"
      name="gracePeriod"
      value={values.gracePeriod}
      error={errors.gracePeriod}
      label="No. of Days"
      type="gracePeriod"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
  </Group>
);

export default GracePeriod;
