import React, { useEffect, FC } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../lib/Store';

import {
  loadPagedParticipants,
  loadParticipant,
  deleteTeamParticipant,
  deleteIndividualParticipant
} from '../../../../lib/Api/Participants';

import { NoRecordsFound, PaginatedTable } from '../../../../components/UI';
import PaginatedTableSearch from '../../../../components/UI/PaginatedTableSearch';
import EditParticipant from '../EditParticipant';
import DeleteParticipant from '../DeleteParticipant';

import { eventGuy } from '../../../../assets/icons';

import {
  ParticipantListView,
  PaginationFilter,
  PagedResponse,
  TableHeaderItem,
  OrderDirection,
  Permission,
  ParticipantFilter,
  RoleCode
} from '../../../../lib/Types';
import {
  setParticipantsFilter,
  setDeletingParticipant,
  setParticipant
} from '../../../../lib/Store/contexts/participants/actions';
import { initialState } from '../../../../lib/Store/contexts/participants/reducers';

export interface ParticipantsListViewProps {
  pagedParticipants: PagedResponse<ParticipantListView>;
  participant: ParticipantListView;
  deletingParticipant: boolean;
  permissions: Permission[];
  loading: boolean;
  loadParticipants: (filter: PaginationFilter, eventId: number) => void;
  editParticipant: (participantId: string) => void;
  loadParticipant: (participantId: string) => void;
  setDeleting: (deletingParticipant: boolean) => any;
  deleteParticipant?: (teamId: number, participantId: string, eventId: number) => any;
  setParticipant: (participant: ParticipantListView) => any;
  setFilter: (filter: ParticipantFilter) => void;
}

const ParticipantsListView: FC<ParticipantsListViewProps> = ({
  setFilter,
  setDeleting,
  loading,
  participant,
  deletingParticipant,
  pagedParticipants,
  loadParticipants,
  loadParticipant,
  editParticipant,
  setParticipant,
  permissions
}) => {
  const dispatch = useDispatch();
  const { filter, selectedEventId, code } = useSelector(
    ({ participantsState, eventCompanyPageFilterState, permissionsState }: ApplicationState) => ({
      filter: participantsState.filter,
      selectedEventId: eventCompanyPageFilterState.selectedEventId,
      code: permissionsState.code
    })
  );
  const { count } = pagedParticipants;
  const headers: TableHeaderItem[] = [
    { display: 'Name', mapProp: 'fullName' },
    { display: 'Email', mapProp: 'email' },
    { display: 'Team Name', mapProp: 'teamName' },
    { display: 'Captain', mapProp: 'teamCaptain', mapBool: { true: 'Y', false: 'N' } },
    { display: 'Organisation', mapProp: 'companyName' },
    { display: 'Status', mapProp: 'joinedStatus', mapBool: { true: 'Active', false: 'Inactive' } }
  ];

  const onEdit = (participantId: string) => editParticipant(participantId);
  const onDelete = (participantId: string) => {
    // When you hit delete, we only have the participant ID
    // We then force re-request the participant data and set our participant object
    // THEN the delete is possible.

    loadParticipant(participantId);
    setDeleting(true);
  };
  const onDeleteCancel = () => {
    setParticipant(initialState.participant);
    setDeleting(false);
  };
  const setPage = (page: number) => setFilter({ ...filter, page });
  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    setFilter({ ...filter, orderColumn, orderDirection });

  const setKeyword = (keyword: string) => setFilter({ ...filter, keyword });

  useEffect(() => {
    loadParticipants(filter, selectedEventId);
  }, [filter]);

  const ParticipantListJSX = count ? (
    <>
      <PaginatedTable
        headers={headers}
        filter={filter}
        idProp={'participantId'}
        data={pagedParticipants}
        loading={loading}
        onSetPage={setPage}
        onSort={setSort}
        onEdit={onEdit}
        onDelete={onDelete}
        actions={[
          { type: 'edit', show: permissions.includes(Permission.EDIT_EVENT_PARTICIPANTS) },
          code === RoleCode.MasterAdmin && {
            type: 'delete',
            show: permissions.includes(Permission.REMOVE_EVENT_PARTICIPANTS)
          }
        ]}
      />
    </>
  ) : (
    <>
      {loading ? (
        ''
      ) : (
        <>
          <NoRecordsFound icon={eventGuy} message="There are currently no participants" />
        </>
      )}
    </>
  );

  return (
    <>
      {participant.participantId && !deletingParticipant ? (
        <EditParticipant />
      ) : (
        <>
          {deletingParticipant ? (
            <DeleteParticipant
              onCancel={onDeleteCancel}
              onDeleteTeamMember={(teamId: number, participantId: string, eventId: number) =>
                dispatch(deleteTeamParticipant(teamId, participantId, eventId))
              }
              onDeleteIndividual={(eventId: number, participantId: string) =>
                dispatch(deleteIndividualParticipant(eventId, participantId))
              }
              participant={participant}
              participantType={participant.teamId ? 'TEAM' : 'INDIVIDUAL'}
              loading={loading}
            />
          ) : null}
          <>
            <PaginatedTableSearch onKeywordFilter={setKeyword} />
            {ParticipantListJSX}
          </>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({
  participantsState,
  loadingState,
  permissionsState
}: ApplicationState) => ({
  participant: participantsState.participant,
  deletingParticipant: participantsState.deletingParticipant,
  pagedParticipants: participantsState.pagedParticipants,
  loading: loadingState.apiCallsInProgress > 0,
  permissions: permissionsState.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setFilter: (filter: ParticipantFilter) => setParticipantsFilter(filter),
      loadParticipants: (filter: ParticipantFilter, eventId: number) =>
        loadPagedParticipants(filter, eventId),
      loadParticipant: (participantId: string) => loadParticipant(participantId),
      editParticipant: (participantId: string) => loadParticipant(participantId),
      setDeleting: (deletingParticipant: boolean) => setDeletingParticipant(deletingParticipant),
      setParticipant: (participant: ParticipantListView) => setParticipant(participant)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsListView);
