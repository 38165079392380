import React, { FC } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { isAfter } from '../../lib/TimeUtils';

import RegisterEmployeeDashboard from '../RegisterEmployeeDashboard';
import EmployeeDashboard from '../EmployeeDashboard';
import TeamEmployeeDashboard from '../TeamEmployeeDashboard';

import { ApplicationState } from '../../lib/Store';

const EmployeeDashboardSwitch: FC = () => {
  const {
    userState: { userEvent },
    participantTeamState: { team: userTeam, individualEventRegistration }
  } = useSelector((state: ApplicationState) => state);

  const beforeRegStart = !isAfter(moment().valueOf(), userEvent.registrationsStartDate);
  const beforeEventStart = !isAfter(moment().valueOf(), userEvent.startDate);
  const beforeRegEnd = !isAfter(moment().valueOf(), userEvent.registrationsEndDate);
  const afterRegStart = isAfter(moment().valueOf(), userEvent.registrationsStartDate);
  const afterEventStart = isAfter(moment().valueOf(), userEvent.startDate);
  const afterRegEnd = isAfter(moment().valueOf(), userEvent.registrationsEndDate);
  const afterEventEnd = isAfter(moment().valueOf(), userEvent.endDate);
  const hasNotRegistered = !individualEventRegistration && !userTeam;

  if (beforeRegStart) {
    // No Events
    return <EmployeeDashboard />;
  }

  // Overlap
  if (afterRegStart && beforeRegEnd && afterEventStart) {
    if (hasNotRegistered) {
      // Registration w/ demographic questions
      return <RegisterEmployeeDashboard event={userEvent} />;
    }
    // Dashboard w/ access to MiniChallenges
    return <EmployeeDashboard />;
  }

  // No Overlap
  if (afterRegStart && beforeRegEnd && beforeEventStart) {
    if (hasNotRegistered) {
      // Registration w/ demographic questions
      return <RegisterEmployeeDashboard event={userEvent} />;
    }
    // Holding Page with Event Start Details
    return <TeamEmployeeDashboard />;
  }

  if (afterRegEnd && beforeEventStart) {
    if (hasNotRegistered) {
      // No Events
      return <EmployeeDashboard />;
    }
    if (userTeam) {
      // Lock Screen
      return <EmployeeDashboard />;
    }
    // Holding Page with Event Start Details
    return <TeamEmployeeDashboard />;
  }

  if (afterRegEnd && afterEventStart) {
    if (hasNotRegistered) {
      // No Events
      return <TeamEmployeeDashboard />;
    }
    // Dashboard w/ access to MiniChallenges
    return <EmployeeDashboard />;
  }

  if (afterEventEnd) {
    // Dashboard w/ access to MiniChallenges OR No Events after grace period ends
    return <EmployeeDashboard />;
  }
};

export default EmployeeDashboardSwitch;
