import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import { LinkText } from './Styles';
import { ExternalPageWrapper } from '../../components/UI';
import { LightBlueBodyM } from '../../Themes';

const LoginPage: FC = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace('/Login');
  }, []);

  return (
    <ExternalPageWrapper header="Sign in" tabletAlign="center">
      <LoginForm />
      <LightBlueBodyM fontStyle={'none'}>
        <LinkText to="forgotPassword">Forgot your password?</LinkText>
      </LightBlueBodyM>

    </ExternalPageWrapper>
  );
};

export default LoginPage;
