import React, { FC, useState, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { loadCompany, LoadCompany } from '../../../lib/Api/Company';

import BackButton from '../../../components/UI/Button/BackButton';
import CreateOrEditCompanyForm from './CreateOrEditCompanyForm';
import LoadingIndicator from '../../../components/UI/LoadingIndicator';

import { PageHeader } from '../../Events/components/CreateEvent/Styles';

import { Company, CompanyAdmin } from '../../../lib/Types';

export interface CreateOrEditCompanyProps {
  companyId?: number;
  onLoadCompany: LoadCompany;
  onCancel: () => void;
  onSuccess: () => void;
}

const CreateOrEditCompany: FC<CreateOrEditCompanyProps> = ({
  companyId,
  onLoadCompany,
  onCancel,
  onSuccess
}) => {
  const [company, setCompany] = useState<Company>();
  const [companyContact, setCompanyContact] = useState<CompanyAdmin>(); // For easy load in form;

  useEffect(() => {
    (async () => {
      if (!companyId) return;
      const { data } = await onLoadCompany(companyId);
      if (data) {
        setCompany(data);
        const contact = data.admins.find(a => a.isContact);
        if (contact) setCompanyContact(contact);
      }
    })();
  }, []);

  return (
    <>
      {companyId && (!company || !companyContact) ? (
        <LoadingIndicator />
      ) : (
        <>
          <PageHeader>
            <BackButton onClick={onCancel} />
            {!companyId ? 'Create a Company' : 'Edit Company'}
          </PageHeader>
          <CreateOrEditCompanyForm
            onCancel={onCancel}
            onSuccess={onSuccess}
            company={company}
            companyContact={companyContact}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onLoadCompany: (companyId: number) => loadCompany(companyId) as any
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CreateOrEditCompany);
