import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TabText, TabFilter, Tab } from './Styles';

import { PrimaryPostTypeFilter } from '../../../lib/Store/contexts/posts/types';
import { ApplicationState } from '../../../lib/Store';
import {
  updatePostsFilter,
  setPosts,
  setAllLoaded
} from '../../../lib/Store/contexts/posts/actions';

export interface FeedPrimaryFilterProps {
  onFilterChange: () => void;
}

const FeedPrimaryFilter: React.FC<FeedPrimaryFilterProps> = ({ onFilterChange }) => {
  const dispatch = useDispatch();
  const { postsState } = useSelector((state: ApplicationState) => state);

  const primaryFilter = postsState.filter.primaryPostTypeFilter;

  const handleTabClick = (filter: PrimaryPostTypeFilter) => {
    if (filter === primaryFilter) return; // Do nothing if no filter change.
    dispatch(
      updatePostsFilter({
        ...postsState.filter,
        primaryPostTypeFilter: filter,
        secondaryPostTypeFilter: 'ALL'
      })
    );
    dispatch(setPosts([]));
    dispatch(setAllLoaded(false));
    onFilterChange();
  };

  return (
    <TabFilter>
      <Tab
        isLeft
        selected={primaryFilter === 'MINI_CHALLENGES'}
        onClick={() => handleTabClick('MINI_CHALLENGES')}
      >
        <TabText>Mini Challenges</TabText>
      </Tab>
      <Tab
        selected={primaryFilter === 'DAILY_ACTIVITIES'}
        onClick={() => handleTabClick('DAILY_ACTIVITIES')}
      >
        <TabText>Daily Activity</TabText>
      </Tab>
      <Tab
        selected={primaryFilter === 'GENERAL_ACTIVITIES'}
        onClick={() => handleTabClick('GENERAL_ACTIVITIES')}
        isRight
      >
        <TabText>General Activity</TabText>
      </Tab>
    </TabFilter>
  );
};

export default FeedPrimaryFilter;
