import React from 'react';

import { DEFAULT_PIC } from '../../../CONSTANTS';

import {
  Container,
  Image,
  StyledTooltip,
  TextWrapper,
  TooltipText,
  TooltipSubtext
} from '../DashboardTopConnectionActivities/PieChart/Tooltip/Styles';

import { BarToolTipData } from './DashboardStepsLineChart';

export interface BarToolTipProps {
  payload: BarToolTipData;
}

const BarToolTip: React.FC<BarToolTipProps> = ({ payload }) => {
  return (
    <Container>
      <StyledTooltip>
        <Image image={payload.profilePictureURL || DEFAULT_PIC} />
        <TextWrapper>
          <TooltipText>{payload.fullName}</TooltipText>
          <TooltipSubtext>{`${payload.value} Steps`}</TooltipSubtext>
        </TextWrapper>
      </StyledTooltip>
    </Container>
  );
};

export default BarToolTip;
