import styled from 'styled-components/macro';
import { theme } from '../../../theme';

export const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  cursor: pointer;
  background: rgb(255, 73, 127);
  background: -moz-linear-gradient(110deg, rgba(255, 73, 127, 1) 0%, rgba(255, 181, 120, 1) 100%);
  background: -webkit-linear-gradient(
    110deg,
    rgba(255, 73, 127, 1) 0%,
    rgba(255, 181, 120, 1) 100%
  );
  background: linear-gradient(110deg, rgba(255, 73, 127, 1) 0%, rgba(255, 181, 120, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff497f",endColorstr="#ffb578",GradientType=1);
  border-radius: 0 0 0 1.3rem;
  margin: 0;
  width: 26vw;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 3rem 1.8rem 2.4rem;
  white-space: nowrap;
  display: flex;

  @media (max-width: ${theme.device.tablet}px) {
    width: 40vw;
  }
  @media (max-width: ${theme.device.mobileL}px) {
    width: 20vw;
  }
`;

export const StopWatchIcon = styled.img`
  height: calc(40px);
  object-fit: contain;
  margin: 0 1rem 0 auto;
`;

export const DownCarat = styled.img`
  height: 0.95rem;
  object-fit: contain;
  margin: 0 2rem 0 0;
  @media (max-width: ${theme.device.mobileL}px) {
    display: none;
  }
`;

export const ActivityHeader = styled.div`
  font-family: ${theme.fontFamily.heading};
  font-weight: ${theme.fontWeights.semibold};
  font-size: calc(1.3rem + 0.3vw);
  font-style: italic;
  color: ${theme.primary.white};
  cursor: pointer;
  margin-right: auto;
  white-space: initial;

  @media (max-width: ${theme.device.mobileL}px) {
    display: none;
  }
`;
