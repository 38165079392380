import styled from 'styled-components/macro';
import { theme } from '../../../theme';

interface IconProps {
  rightAlign?: boolean;
}

export const SearchInputContainer = styled.div`
  border-radius: 1.7rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
  box-shadow: ${theme.shadows.lighter};
  @media (max-width: ${theme.device.tablet}px) {
    margin-top: 1rem;
  }
`;

export const TableSearchInput = styled.input`
  width: 100%;
  color: ${theme.primary.dark};
  padding: 1rem 1.2rem 1rem 3.5rem;
  border: none;
  font-size: 1.2rem;
  &::placeholder {
    color: ${theme.backgrounds.grey};
    font-style: italic;
  }
`;

export const SearchInputIcon = styled.img<IconProps>`
  position: absolute;
  width: 1.5rem;
  top: 50%;
  ${({ rightAlign }) => !rightAlign && 'left: 1.2rem;'}
  ${({ rightAlign }) =>
    rightAlign &&
    `right: 1.2rem;
    cursor: pointer;
    width: 1rem;`}
  transform: translateY(-50%);
`;
