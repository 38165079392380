import {
  CompanyActionTypes,
  SET_COMPANIES,
  SET_COMPANY_OPTIONS,
  SET_COMPANY,
  SET_COMPANY_FILTER,
  SET_COMPANY_DEMOGRAPHICS,
  SetCompanyLeaderboardAction,
  SET_COMPANY_LEADERBOARD,
  UPDATE_COMPANY,
  REMOVE_COMPANY
} from './types';
import {
  PagedResponse,
  Company,
  CompanyDemographicsView,
  CompanyLeaderboardView,
  PaginationFilter
} from '../../../Types';

export const setCompanies = (companies: PagedResponse<Company>): CompanyActionTypes => ({
  type: SET_COMPANIES,
  companies
});

export const updateCompany = (companyUpdates: Partial<Company>): CompanyActionTypes => ({
  type: UPDATE_COMPANY,
  companyUpdates
});

export const setCompanyOptions = (companyOptions: Company[]): CompanyActionTypes => ({
  type: SET_COMPANY_OPTIONS,
  companyOptions
});

export const setCompany = (company: Company): CompanyActionTypes => ({
  type: SET_COMPANY,
  company
});

export const removeCompany = (): CompanyActionTypes => ({
  type: REMOVE_COMPANY
});

export const setCompanyDemographics = (
  companyDemographics: CompanyDemographicsView[]
): CompanyActionTypes => ({
  type: SET_COMPANY_DEMOGRAPHICS,
  companyDemographics
});

export const setCompanyLeaderboard = (
  companyLeaderboard: CompanyLeaderboardView[]
): SetCompanyLeaderboardAction => ({
  type: SET_COMPANY_LEADERBOARD,
  companyLeaderboard
});

export const setCompanyFilter = (filter: PaginationFilter): CompanyActionTypes => ({
  type: SET_COMPANY_FILTER,
  filter
});
