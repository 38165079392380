import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Button from '../Button';

import { walkingDogGraphic } from '../../../assets/icons';
import { DashboardPanelWrapper, Header } from '../DashboardTeamConnectionPoints/Styles';
import { Container, MascotIcon, ContentWrapper, CountWrapper, Count } from './Styles';

import { updatePostsFilter } from '../../../lib/Store/contexts/posts/actions';
import { initialPostsStateFilter } from '../../../lib/Store/contexts/posts/reducers';
import { APIThunkDispatch } from '../../../lib/Types';
import { ApplicationState } from '../../../lib/Store';

interface DashboardMiniChallengesCountProps {}

const DashboardMiniChallengesCount: FC<DashboardMiniChallengesCountProps> = () => {
  const dispatch: APIThunkDispatch = useDispatch();
  const history = useHistory();
  const {
    participantDashboardState: { dashBoardData }
  } = useSelector((state: ApplicationState) => state);

  const completed =
    dashBoardData &&
    dashBoardData.miniChallenges.filter(challenge => challenge.participated).length;
  const toDo = dashBoardData && dashBoardData.miniChallenges.length - completed;

  // Set filter then navigate
  const handleViewAllMiniChallenges = () => {
    dispatch(
      updatePostsFilter({
        ...initialPostsStateFilter,
        primaryPostTypeFilter: 'MINI_CHALLENGES',
        secondaryPostTypeFilter: 'ALL'
      })
    );
    history.push('/WaterCooler');
  };

  return (
    <DashboardPanelWrapper as={Container}>
      <ContentWrapper>
        <Header>Mini Challenges</Header>
        <CountWrapper>
          <Count completed>{`Completed (${completed})`}</Count>
          <Count>{`To Do (${toDo})`}</Count>
        </CountWrapper>
        <Button
          variant={'primary-purple'}
          width={'100%'}
          margin={'2rem 0'}
          onClick={handleViewAllMiniChallenges}
        >
          View All Mini Challenges
        </Button>
      </ContentWrapper>
      <MascotIcon src={walkingDogGraphic} />
    </DashboardPanelWrapper>
  );
};

export default DashboardMiniChallengesCount;
