import React, { FC, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';

import PaginatedTable from '../../../../components/UI/PaginatedTable';
import SDASingleView from '../SDASingleView';
import { NoRecordsFound } from '../../../../components/UI';
import {
  loadPagedSDAList,
  approveSDA,
  rejectSDA,
  getSDA
} from '../../../../lib/Api/SuspectDailyActivity';
import {
  setSDA as setSDAAction,
  setSDAFilter
} from '../../../../lib/Store/contexts/suspectDailyActivity/actions';

import { initialState as SDAInitialState } from '../../../../lib/Store/contexts/suspectDailyActivity/reducers';
import { eventGuy } from '../../../../assets/icons';

import {
  OrderDirection,
  PagedResponse,
  Permission,
  TableHeaderItem,
  SuspectDailyActivity,
  SDAFilter
} from '../../../../lib/Types';
import { ApplicationState } from '../../../../lib/Store';
import PaginatedTableSearch from 'src/components/UI/PaginatedTableSearch';

export interface SDAListProps {
  pagedSDAList: PagedResponse<SuspectDailyActivity>;
  SDA: SuspectDailyActivity;
  permissions: Permission[];
  filter: SDAFilter;
  loading: boolean;
  setFilter: (filter: SDAFilter) => void;
  loadSDAs: (filter: SDAFilter) => void;
  setSDA: (suspectDailyActivityId: number) => any;
  approveSDA: (suspectDailyActivityId: number) => any;
  rejectSDA: (suspectDailyActivityId: number) => any;
}

const SDAListView: FC<SDAListProps> = ({
  filter,
  setFilter,
  loading,
  loadSDAs,
  setSDA,
  pagedSDAList,
  approveSDA,
  rejectSDA,
  SDA,
  permissions
}) => {
  const { count } = pagedSDAList;
  const headers: TableHeaderItem[] = [
    {
      display: 'Name',
      mapProp: 'userFullName'
    },
    {
      display: 'Event',
      mapProp: 'eventName'
    },
    { display: 'Date', mapProp: 'completionDate', millis: true },
    { display: 'Total Steps', mapProp: 'totalSteps' },
    { display: 'Reason', mapProp: 'reason', wrap: true },
    {
      display: 'Status',
      mapProp: 'approvalStatus'
    }
  ];

  const setPage = (page: number) => setFilter({ ...filter, page });
  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    setFilter({ ...filter, orderColumn, orderDirection });
  const Dispatch = useDispatch();

  const setKeyword = (keyword: string) => setFilter({ ...filter, keyword });

  useEffect(() => {
    loadSDAs(filter);
  }, [filter]);

  useEffect(() => {
    loadSDAs(filter);
    return () => {
      Dispatch(setSDAAction({} as SuspectDailyActivity));
      Dispatch(setSDAFilter(SDAInitialState.filter));
    };
  }, []);

  const onView = (id: React.ReactText) => setSDA(+id);

  const onApprove = async (suspectDailyActivityId: number) => {
    const res = await approveSDA(suspectDailyActivityId);
    const { data } = res;
    return data;
  };

  const onReject = async (suspectDailyActivityId: number) => {
    const res = await rejectSDA(suspectDailyActivityId);
    const { data } = res;
    return data;
  };

  const SDAListJSX = count ? (
    <>
      <PaginatedTable
        headers={headers}
        filter={filter}
        idProp={'suspectDailyActivityId'}
        data={pagedSDAList}
        loading={loading}
        onSetPage={setPage}
        onSort={setSort}
        onView={onView}
        onApprove={onApprove}
        onReject={onReject}
        actions={[
          { type: 'view', show: permissions.includes(Permission.SDA_ADMIN) },
          { type: 'approve', show: permissions.includes(Permission.SDA_ADMIN) },
          { type: 'reject', show: permissions.includes(Permission.SDA_ADMIN) }
        ]}
      />
    </>
  ) : (
    <>
      {loading ? (
        ''
      ) : (
        <>
          <NoRecordsFound
            icon={eventGuy}
            message="There are currently no suspect daily activities"
          />
        </>
      )}
    </>
  );

  return (
    <>
      {SDA.suspectDailyActivityId ? (
        <SDASingleView />
      ) : (
        <>
          <PaginatedTableSearch onKeywordFilter={setKeyword} />
          {SDAListJSX}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ SDAState, loadingState, permissionsState }: ApplicationState) => ({
  pagedSDAList: SDAState.pagedSDAList,
  SDA: SDAState.SDA,
  filter: SDAState.filter,
  loading: loadingState.apiCallsInProgress > 0,
  permissions: permissionsState.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setFilter: (filter: SDAFilter) => setSDAFilter(filter),
      loadSDAs: (filter: SDAFilter) => loadPagedSDAList(filter),
      setSDA: (suspectDailyActivityId: number) => getSDA(suspectDailyActivityId),
      approveSDA: (suspectDailyActivityId: number) => approveSDA(suspectDailyActivityId),
      rejectSDA: (suspectDailyActivityId: number) => rejectSDA(suspectDailyActivityId)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SDAListView);
