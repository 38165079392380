import { APIResult, APIThunkResult, Post, AbuseReport, ProgressCallback, RoleCode } from '../Types';
import { apiRequest, objectToQueryString, apiFetch } from './Utils';
import {
  addPosts,
  updatePostsFilter,
  setAllLoaded,
  setPosts,
  updatePost,
  setMiniChallenges,
  updatePostRepliesFilter,
  setAllRepliesLoaded
} from '../Store/contexts/posts/actions';
import { CreatePostSchema } from '../validators/createPostSchema';
import { CreateReplySchema } from '../validators/createReplySchema';
import { CreateSpecialPostSchema } from '../validators/createSpecialPostSchema';
import { initialPostsState } from '../Store/contexts/posts/reducers';
import { server } from '../../CONSTANTS';
import { setNotification } from '../Store/contexts/notification/actions';
import { uploadMedia } from './Common';

const POSTS_URL = `${server}/posts`;

// reset == reset pagination. not filters
export type LoadPosts = (resetPagination: boolean) => Promise<APIResult<Post[]>>;
export function loadMorePosts(resetPagination = false): APIThunkResult<Post[]> {
  return apiRequest<Post[]>(async (dispatch, getState) => {
    const { filter, posts: existingPosts, allLoaded } = getState().postsState;

    if (allLoaded && !resetPagination) return [];

    // Reset pagination filters but preserve others
    const resetPaginationFilter = {
      ...initialPostsState.filter,
      primaryPostTypeFilter: filter.primaryPostTypeFilter,
      secondaryPostTypeFilter: filter.secondaryPostTypeFilter
    };

    if (resetPagination) {
      dispatch(updatePostsFilter(resetPaginationFilter));
    }

    const queryParams = objectToQueryString(resetPagination ? resetPaginationFilter : filter);
    const { count, results: posts } = await apiFetch<{
      count: number;
      results: Post[];
      pinnedPosts: Post[];
    }>({
      method: 'GET',
      url: `${POSTS_URL}/view?${queryParams}`
    });

    // Only update filter to next lot, if there is more
    if ((resetPagination ? 0 : existingPosts.length) + posts.length !== count) {
      // Update Pagination filter so that next load will fetch the next 10 posts
      const newFilter = resetPagination ? resetPaginationFilter : filter;
      dispatch(updatePostsFilter({ ...newFilter, page: newFilter.page + 1 }));
    } else dispatch(setAllLoaded());

    if (resetPagination) dispatch(setPosts(posts));
    else dispatch(addPosts(posts)); // Add new Posts
    return posts;
  });
}

export type LoadReplies = () => Promise<APIResult<Post[]>>;
export function loadMoreReplies(
  postIdFromURL: string,
  parentPost: Post,
  reset = false
): APIThunkResult<Post[]> {
  return apiRequest<Post[]>(async (dispatch, getState) => {
    const postId = parseInt(postIdFromURL, 10);
    const { repliesFilter, allRepliesLoaded } = getState().postsState;

    const { replies: existingReplies } = parentPost;

    if (allRepliesLoaded && !reset) return [];

    if (reset) dispatch(updatePostRepliesFilter(initialPostsState.repliesFilter));

    const queryParams = objectToQueryString(
      reset ? initialPostsState.repliesFilter : repliesFilter
    );

    const { results: replies } = await apiFetch<{
      results: Post[];
    }>({
      method: 'GET',
      url: `${POSTS_URL}/view/${postId}/replies?${queryParams}`
    });

    // Only update filter to next lot, if there is more
    if ((reset ? 0 : existingReplies.length) + replies.length !== parentPost.comments) {
      // Update Pagination filter so that next load will fetch the next 10 posts
      dispatch(updatePostRepliesFilter({ ...repliesFilter, page: repliesFilter.page + 1 }));
    } else dispatch(setAllRepliesLoaded());

    return replies;
  });
}

export type LoadSinglePostResult = Promise<APIResult<Post>>;
export function loadSinglePost(postId: number | string): APIThunkResult<Post> {
  return apiRequest<Post>(async (dispatch, getState) => {
    const { repliesFilter } = getState().postsState;

    const queryParams = objectToQueryString(initialPostsState.repliesFilter);

    const { post } = await apiFetch<{ post: Post }>({
      method: 'GET',
      url: `${POSTS_URL}/view/${postId}?${queryParams}`
    });

    if (post.replies.length === post.comments) {
      dispatch(setAllRepliesLoaded());
    } else {
      dispatch(setAllRepliesLoaded(false));
      dispatch(updatePostRepliesFilter({ ...repliesFilter, page: repliesFilter.page + 1 }));
    }

    return post;
  });
}

export type CreatePost = (data: CreatePostSchema) => Promise<APIResult<Post>>;
export function createPost(data: CreatePostSchema): APIThunkResult<Post> {
  return apiRequest<Post>(async (dispatch, getState) => {
    const { userId } = getState().userState.userData;
    const { code } = getState().permissionsState;

    try {
      const { post } = await apiFetch<{ post: Post }>({
        method: 'POST',
        url: `${POSTS_URL}/create`,
        body: { ...data, userId } // Override to always be current user
      });
      dispatch(setNotification({ message: 'Post created!' }));
      if (code === RoleCode.Employee) dispatch(loadMorePosts(true));
      return post;
    } catch (error) {
      dispatch(
        setNotification({ message: 'Something went wrong, try again later', variant: 'danger' })
      );
      throw error;
    }
  });
}

export type CreateReply = (data: CreateReplySchema) => Promise<APIResult<Post>>;
export function createReply(data: CreateReplySchema): APIThunkResult<Post> {
  return apiRequest<Post>(async (dispatch, getState) => {
    const { userState, postsState } = getState();
    const { userId } = userState.userData;
    const { posts: existingPosts } = postsState;

    try {
      const { post } = await apiFetch<{ post: Post }>({
        method: 'POST',
        url: `${POSTS_URL}/reply`,
        body: { ...data, userId } // Override to always be current user
      });

      dispatch(setNotification({ message: 'Posted reply!' }));
      dispatch(loadMorePosts(true));

      // Update the Parent Post is it exist in the WaterCooler/local data
      if (existingPosts.length > 0) {
        const parentPost = existingPosts.find(
          existingPost => existingPost.postId === data.parentPostId
        );
        if (parentPost)
          dispatch(updatePost({ ...parentPost, replies: [...parentPost.replies, post] }));
      }

      return post;
    } catch (error) {
      dispatch(
        setNotification({
          message: error?.message || 'Something went wrong, try again later',
          variant: 'danger',
          duration: 5
        })
      );
      throw error;
    }
  });
}

export type CreateSpecialPost = (data: CreateSpecialPostSchema) => Promise<APIResult<Post>>;
export function createSpecialPost(data: CreateSpecialPostSchema): APIThunkResult<Post> {
  return apiRequest<Post>(async (dispatch, getState) => {
    const { userId } = getState().userState.userData;
    const { code } = getState().permissionsState;

    try {
      const { post } = await apiFetch<{ post: Post }>({
        method: 'POST',
        url: `${POSTS_URL}/createSpecial`,
        body: { ...data, userId } // Override to always be current user
      });

      dispatch(setNotification({ message: 'Post created!' }));
      if (code === RoleCode.Employee) dispatch(loadMorePosts(true)); // Reset Posts

      return post;
    } catch (error) {
      dispatch(
        setNotification({ message: 'Something went wrong, try again later', variant: 'danger' })
      );
      throw error;
    }
  });
}

export type UploadPostMedia = (
  media: File,
  onProgress?: ProgressCallback
) => Promise<APIResult<string | null>>;
export const uploadPostMedia = (
  media: File,
  onProgress?: ProgressCallback
): APIThunkResult<string | null> => uploadMedia(media, onProgress, 'posts/media');

export type LikePost = (postId: number) => Promise<APIResult<boolean>>;
export function likePost(postId: number): APIThunkResult<boolean> {
  return apiRequest<boolean>(async (_, getState) => {
    const { userId } = getState().userState.userData;

    const { liked } = await apiFetch<{ liked: boolean }>({
      method: 'POST',
      url: `${POSTS_URL}/like`,
      body: { userId, postId }
    });
    return liked;
  });
}

export type ReportPost = (postId: number, reason: string) => Promise<APIResult<AbuseReport>>;
export function reportPost(postId: number, reason: string): APIThunkResult<AbuseReport> {
  return apiRequest<AbuseReport>(async (dispatch, getState) => {
    const { userId } = getState().userState.userData;

    const { abuseReport } = await apiFetch<{ abuseReport: AbuseReport }>({
      method: 'POST',
      url: `${POSTS_URL}/report`,
      body: { userId, postId, reason }
    });

    dispatch(loadMorePosts(true)); // Reset Posts

    return abuseReport;
  });
}

export type DeletePost = (postId: number) => Promise<APIResult<boolean>>;
export function deleteMyPost(postId: number): APIThunkResult<boolean> {
  return apiRequest<boolean>(async (dispatch, getState) => {
    const { userId } = getState().userState.userData;

    const { success } = await apiFetch<{ success: boolean }>({
      method: 'PUT',
      url: `${POSTS_URL}/delete`,
      body: { userId, postId }
    });

    dispatch(loadMorePosts(true)); // Reset Posts

    return success;
  });
}

export function loadMiniChallenges(limit: number): APIThunkResult<Post[]> {
  return apiRequest<Post[]>(async dispatch => {
    const result = await apiFetch<Post[]>({
      method: 'GET',
      url: `${server}/posts/mini-challenges/latest?limit=${limit}`
    });
    dispatch(setMiniChallenges(result));
    return result;
  });
}

export default {
  loadMorePosts,
  createPost,
  createReply,
  createSpecialPost,
  likePost,
  reportPost,
  deleteMyPost
};
