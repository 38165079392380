import { apiRequest, apiFetch } from '../Utils';
import { server } from '../../../CONSTANTS';

import { APIResult, APIThunkResult, RecaptchaVerificationResponse } from '../../Types';
import { setNotification } from '../../../lib/Store/contexts/notification/actions';

export type VerifyCaptcha = (token: string) => Promise<APIResult<RecaptchaVerificationResponse>>;
export function verifyCaptcha(token: string): APIThunkResult<RecaptchaVerificationResponse> {
  return apiRequest<RecaptchaVerificationResponse>(async dispatch => {
    try {
      const data = await apiFetch<RecaptchaVerificationResponse>({
        url: `${server}/auth/verify-recaptcha`,
        method: 'POST',
        body: {
          token
        }
      });
      if (data['error-codes']) {
        dispatch(
          setNotification({
            message: 'Could not verify captcha, please try again.',
            variant: 'warning'
          })
        );
      }
      return data;
    } catch (e) {
      dispatch(
        setNotification({
          message: 'Could not verify captcha, please try again.',
          variant: 'warning'
        })
      );
    }
  });
}
