import React, { FC } from 'react';
import {
  Card,
  CountBadge,
  CountHeading,
  CountHeadingContainer,
  IconWrapper,
  LeftImage,
  StatIcon
} from './Styles';
import { useHistory } from 'react-router';

interface StatCard {
  title: string;
  value?: number | string;
  color: string;
  gradient: string;
  margin?: number;
  noMargin?: boolean;
  leftImg?: string;
  iconWidth?: number;
  iconBackground?: string;
  borderRadius?: string;
  arrow?: boolean;
  minHeight?: number | string;
  badgeBackground?: string;
  iconImg?: string;
  disabled?: boolean;
  linkTo: string;
}

const StatCard: FC<StatCard> = ({
  title,
  value,
  gradient,
  color,
  leftImg,
  iconWidth = 32,
  iconBackground = 'transparent',
  minHeight = 'auto',
  arrow = true,
  iconImg,
  borderRadius,
  disabled = false,
  linkTo
}) => {
  const history = useHistory();

  const onClick = () => disabled || history.push(linkTo);

  return (
    <Card
      minHeight={minHeight}
      gradient={gradient}
      borderRadius={borderRadius}
      onClick={onClick}
      disabled={disabled}
    >
      <CountHeadingContainer>
        {leftImg ? (
          <>
            <IconWrapper iconBackground={iconBackground}>
              <LeftImage alt="" src={leftImg} width={iconWidth} />
            </IconWrapper>
            <CountHeading color={color}>{title}</CountHeading>
          </>
        ) : (
          <>
            <CountBadge color={color}>{value}</CountBadge>
            <CountHeading>{title}</CountHeading>
          </>
        )}
      </CountHeadingContainer>
      {arrow && <StatIcon src={iconImg} alt={''} />}
    </Card>
  );
};
export default StatCard;
