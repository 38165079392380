import { Reducer } from 'redux';
import {
  DashboardState,
  DashboardActionTypes,
  SET_TOP_ACTIVITIES,
  SET_TEAM_MEMBERS_POINTS,
  SET_TOP_CONNECTION,
  SET_DASHBOARD_DATA
} from './types';

export const initialDashboardState: DashboardState = {
  topActivities: [],
  topConnection: [],
  teamMembersPoints: [],
  dashBoardData: null
};

const DashboardReducer: Reducer<DashboardState, DashboardActionTypes> = (
  state = initialDashboardState,
  action
) => {
  switch (action.type) {
    case SET_TOP_ACTIVITIES:
      return { ...state, topActivities: action.topActivities };
    case SET_TEAM_MEMBERS_POINTS:
      return { ...state, teamMembersPoints: action.teamMembersPoints };
    case SET_TOP_CONNECTION:
      return { ...state, topConnection: action.topConnection };
    case SET_DASHBOARD_DATA:
      return { ...state, dashBoardData: action.dashBoardData };
    default:
      return state;
  }
};

export default DashboardReducer;
