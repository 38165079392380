import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ToastNotification } from '../UI';
import { NotificationOpts } from '../../lib/Types';
import { ApplicationState } from '../../lib/Store';
import { dismissNotification } from '../../lib/Store/contexts/notification/actions';

export interface NotificationContainerProps {
  visible: boolean;
  options: NotificationOpts;
  dismissNotification: () => void;
}

const NotificationContainer: React.FC<NotificationContainerProps> = ({
  visible,
  options,
  dismissNotification
}) => <>{visible ? <ToastNotification {...options} onDismiss={dismissNotification} /> : ''}</>;

const mapStateToProps = ({ notificationState }: ApplicationState) => ({
  visible: notificationState.visible,
  options: notificationState.options
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      dismissNotification: () => dismissNotification()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
