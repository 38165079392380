import React from 'react';
import moment from 'moment';

import { TeamStats, User, YourSteps } from '../../../lib/Types';
import { ChartData, UserValue } from './DashboardStepsLineChart';

const AVERAGE_WINDOW_SIZE = 7; // Number of days
const CHART_TICKS_AMOUNT = 4;

/**
 * Given two dates, return an array of dates between + including said dates.
 * @param fromDate
 * @param toDate
 * @returns
 */
export const getDateRangeAsArray = (fromDate: moment.Moment, toDate: moment.Moment): Date[] => {
  const dates = [];
  const numberOfDays = moment(toDate).diff(fromDate, 'days');
  const date = moment(fromDate);
  dates.push(date.toDate());
  [...Array(numberOfDays)].forEach(_ => {
    date.add(1, 'days');
    dates.push(date.toDate());
  });
  return dates;
};

// Given an array, calculate an average value for the past movingAverageWindowSize days.
const calculateMovingAverage = (steps: YourSteps[], lastDate: Date): number => {
  let average = 0;
  const firstDate = moment(lastDate).subtract(AVERAGE_WINDOW_SIZE, 'days');
  steps.forEach(({ value, completionDate }) => {
    if (
      moment(completionDate).isBefore(lastDate) &&
      moment(completionDate).isSameOrAfter(firstDate)
    ) {
      average += value;
    }
  });
  return average / AVERAGE_WINDOW_SIZE;
};

// Calculates an average value for each given day, across a set of values
const calculateFixedAverage = (values: UserValue[]): number =>
  values.map(e => e.value).reduce((a, b) => a + b) / values.length;

/**
 * Maps given data into format required by recharts library
 * @param userData info for currently logged in user
 * @param teamStats data for users team
 * @param fromDate
 * @param toDate
 * @returns an array of mapped data
 */
export const mapData = (
  userData: User,
  teamStats: TeamStats[],
  fromDate: moment.Moment,
  toDate: moment.Moment
): ChartData[] => {
  const dates = getDateRangeAsArray(fromDate, toDate);
  if (!userData || !teamStats.length || !dates.length) return [];

  return dates.map(date => {
    const formattedDate = moment(date).format('YYYY-MM-DD');

    const teamValues: UserValue[] = teamStats.map(teamMember => {
      const hasEntry = teamMember.steps.find(entry => entry.completionDate === formattedDate);
      return {
        value: hasEntry ? hasEntry.value : 0,
        userId: teamMember.userId
      };
    });

    const myData = teamStats.find(entry => entry.userId === userData.userId);
    const personalAverage = myData ? calculateMovingAverage(myData.steps, date) : 0;

    return {
      day: formattedDate,
      teamValues,
      teamAverage: calculateFixedAverage(teamValues),
      personalAverage
    };
  });
};

/**
 * Determines if two given days are in the same week
 * @param firstDay
 * @param secondDay
 * @param offset range
 * @returns true iff dates are in same week
 */
export const isSameWeek = (
  firstDay: moment.Moment,
  secondDay: moment.Moment,
  offset: number
): boolean => {
  const firstMoment = moment(firstDay);
  const secondMoment = moment(secondDay);

  const startOfWeek = (_moment, _offset) => {
    return _moment.add(
      'days',
      _moment.weekday() * -1 + (_moment.weekday() >= 7 + _offset ? 7 + _offset : _offset)
    );
  };

  return startOfWeek(firstMoment, offset).isSame(startOfWeek(secondMoment, offset), 'day');
};

export const abbreviateNumber = (n: number) => {
  return n / 1000 + 'k';
};

// Override function to render recharts axis ticks
export const renderCustomXAxisTick = tickProps => {
  const { x, y, payload } = tickProps;
  const { value } = payload;
  const date = moment(value).format('DD-MMM');
  const weekday = moment(value).format('dddd');

  const textStyles = {
    fontSize: '10px',
    fontFamily: 'Sarabun',
    fill: isSameWeek(moment(value), moment(), 0) ? '#366696' : '#AEAEAE',
    fontWeight: 500
  };

  return (
    <>
      <text x={x} y={y + 12} textAnchor="middle" style={textStyles}>
        {`${weekday.charAt(0).toUpperCase()}`}
      </text>
      <text x={x} y={y + 26} textAnchor="middle" style={textStyles}>
        {`${date}`}
      </text>
    </>
  );
};

// Override function to render recharts axis ticks
export const renderCustomYAxisTick = (data: ChartData[]) => {
  let maxValue = -1;
  let maxPersonalAverage = -1;
  data.forEach(d => {
    d.teamValues.forEach(t => (maxValue = Math.max(maxValue, t.value)));
    maxPersonalAverage = Math.max(maxPersonalAverage, d.personalAverage);
  });

  if (maxValue === 0 && maxPersonalAverage === 0) {
    return [0, 1000, 2000, 3000, 4000];
  }

  const upperLimit = Math.ceil((maxValue || maxPersonalAverage) / 4000) * 4000;
  const tickIncrement = upperLimit / CHART_TICKS_AMOUNT;
  const ticks = Array.from(
    { length: CHART_TICKS_AMOUNT },
    (_, i) => i * tickIncrement + tickIncrement
  );
  ticks.unshift(0);
  return ticks;
};
