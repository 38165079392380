import React, { FC } from 'react';
import { withFormik, FormikProps } from 'formik';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import CreateRewardForm from './CreateRewardForm';
import {
  uploadRewardImage,
  createReward,
  UploadImage,
  CreateReward
} from '../../../lib/Api/Rewards';
import { ApiStatus, FilePreview } from '../../../lib/Types';

interface CreateRewardFormContainerProps extends RouteComponentProps {
  onUploadRewardImage: UploadImage;
  onCreateReward: CreateReward;
  onCancel: (setCreate: boolean) => void;
}

interface CreateRewardFormState {
  name: string;
  points: number;
  image: FilePreview | string | null;
}

const formikEnhancer = withFormik<CreateRewardFormContainerProps, CreateRewardFormState>({
  mapPropsToValues: () => ({
    name: '',
    points: 0,
    image: null
  }),
  handleSubmit: async (
    { name, points, image }: CreateRewardFormState,
    { props: { onUploadRewardImage, onCreateReward }, resetForm, setFieldError }
  ): Promise<void> => {
    const { status, data: imageURL } = await onUploadRewardImage(image as File);
    if (status === ApiStatus.SUCCESS) {
      const data = { name, points, image: imageURL };
      const { status } = await onCreateReward(data);
      if (status === ApiStatus.SUCCESS) {
        URL.revokeObjectURL((image as FilePreview)?.preview);
        resetForm();
      }
    } else setFieldError('image', 'Image upload failed');
  },
  validateOnChange: false,
  validate: ({ name, points, image }) => {
    const errors: { [key: string]: string } = {};
    if (!name) errors.name = 'name required.';
    if (!points && points !== 0) errors.points = 'Number of points required.';
    if (points < 0) errors.points = 'Number of points must be greater than zero.';
    if (!image) errors.image = 'Reward image required.';
    return errors;
  }
});

const CreateRewardFormContainer: FC<CreateRewardFormContainerProps &
  FormikProps<CreateRewardFormState>> = ({
  handleChange,
  handleSubmit,
  setFieldError,
  setFieldValue,
  values,
  errors,
  onCancel
}) => {
  return (
    <CreateRewardForm
      values={values}
      errors={errors}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      setFieldError={setFieldError}
      setFieldValue={setFieldValue}
      onCancel={() => onCancel(false)}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onUploadRewardImage: uploadRewardImage as any,
      onCreateReward: createReward as any
    },
    dispatch
  );

export default withRouter(
  connect(null, mapDispatchToProps)(formikEnhancer(CreateRewardFormContainer))
);
