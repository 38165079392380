import styled from 'styled-components/macro';
import { DarkBlueHeaderL } from '../../Themes';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 0 1.5rem 0;
  line-height: 3rem;
`;

export const BackBtn = styled.img`
  cursor: pointer;
  margin-right: 10px;
  transform: scale(0.96);
  padding-top: 0.2rem;
`;

export const ModalTitle = styled(DarkBlueHeaderL)`
  font-size: 2.1rem;
`;
