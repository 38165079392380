import { Reducer } from 'redux';
import { DashboardState, DashboardActionTypes, SET_DASHBOARD_DATA } from './types';

export const initialDashboardState: DashboardState = {
  stats: null,
  companyRegistrations: []
};

const DashboardReducer: Reducer<DashboardState, DashboardActionTypes> = (
  state = initialDashboardState,
  action
) => {
  switch (action.type) {
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        stats: action.dashBoardData.stats,
        companyRegistrations: action.dashBoardData.companyRegistrations
      };
    default:
      return state;
  }
};

export default DashboardReducer;
