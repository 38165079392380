import styled from 'styled-components/macro';

export const LeaderboardTableContainer = styled.div`
  z-index: 20;
  background: white;
  border-radius: 1.5rem;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    margin-left: 0;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    border-radius: 0;
  }
`;
