import React from 'react';
import { Payload, NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import {
  connectionBadge,
  gratitudeBadge,
  exerciseBadge,
  laughterBadge,
  sleepBadge,
  nutritionBadge,
  mindfulnessBadge
} from '../../../../../assets/icons';
import { Container, Icon, StyledTooltip, TextWrapper, TooltipText, TooltipSubtext } from './Styles';

import { PostType } from '../../../../../lib/Types';

export interface TooltipProps {
  payload: Payload<ValueType, NameType>[];
}

const Tooltip: React.FC<TooltipProps> = ({ payload }) => {
  if (!payload) return;
  let badge;
  switch (payload[0].name) {
    case PostType.Connection:
      badge = connectionBadge;
      break;
    case PostType.Gratitude:
      badge = gratitudeBadge;
      break;
    case PostType.Exercise:
      badge = exerciseBadge;
      break;
    case PostType.Laugh:
      badge = laughterBadge;
      break;
    case PostType.Sleep:
      badge = sleepBadge;
      break;
    case PostType.Nutrition:
      badge = nutritionBadge;
      break;
    case PostType.Mindfulness:
      badge = mindfulnessBadge;
      break;
  }

  return (
    <Container>
      <StyledTooltip>
        <Icon src={badge} />
        <TextWrapper>
          <TooltipText>{`${payload[0].name as number}`}</TooltipText>
          <TooltipSubtext>{payload[0].payload.formattedValue}</TooltipSubtext>
        </TextWrapper>
      </StyledTooltip>
    </Container>
  );
};

export default Tooltip;
