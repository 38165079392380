import React, { useState } from 'react';
import VimeoPlayer, { VimeoProps } from '@cognativ/react-vimeo';
import { VideoContainer, ProcessingVideoContainer, ProcessingVideoIcon } from './Styles';
import { parseUri, isVimeoUri } from '../../../lib/Utils';
import { commentVideo } from '../../../assets/icons';

interface VideoPlayerProps extends VimeoProps {
  video: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  video,
  onError,
  showTitle = false,
  showByline = false,
  showPortrait = false,
  ...props
}) => {
  const [paused, setPaused] = useState(true);
  const [started, setStarted] = useState(false);
  // const [ended, setEnded] = useState(false);

  const startVideo = () => {
    setPaused(false);
    setStarted(true);
  };
  const togglePause = () => {
    if (started) setPaused(!paused);
  };
  /*
  const renderAnimation = () => {
    if (started) {
      return paused ? <PauseButtonAnimation /> : <PlayButtonAnimation />;
    } else {
      return null;
    }
  };
  */

  const isProcessed = isVimeoUri(video);

  if (isProcessed)
    return (
      <VideoContainer onMouseDown={togglePause}>
        {/* TODO: add animation back in */}
        {/* {!started && <PlayButton paused={paused} onMouseDown={startVideo} />} */}
        {/* {renderAnimation()} */}
        <VimeoPlayer
          responsive
          paused={paused}
          video={parseUri(video)}
          showTitle={showTitle}
          showByline={showByline}
          showPortrait={showPortrait}
          onPlay={() => startVideo()}
          loop
          // onEnd={() => setEnded(true)}
          onError={error => {
            console.warn(error);
            if (onError) onError(error);
          }}
          {...props}
        />
      </VideoContainer>
    );
  return (
    <ProcessingVideoContainer>
      <div>
        <ProcessingVideoIcon src={commentVideo} />
      </div>
      <div>
        <p>Video is Processing</p>
      </div>
    </ProcessingVideoContainer>
  );
};

export default VideoPlayer;
