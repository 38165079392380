import styled from 'styled-components/macro';
import { Field, Form } from 'formik';
import Button from '../../Button';

export const OptionsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
`;

export const HeaderTextWrapper = styled.span`
  display: flex;
  flex-direction: row;
  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    flex-direction: column;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LeftContent = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 4;
  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    overflow: hidden;
  }
`;

export const RadioOption = styled(Field)`
  margin-right: 10px;
`;

export const RadioLabel = styled.label`
  font-family: 'Sarabun', 'sans serif';
  color: #21679a;
`;

export const ButtonsWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    flex-direction: column;
  }
`;

export const ReportLadyImg = styled.img`
  flex: 1;
  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    display: none;
  }
`;

export const ReportButton = styled(Button)`
  width: 40%;
  margin: 0px 20px 0px 0px;
  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    width: 80vw;
    margin: 0px 0px 20px 0px;
  }
`;

export const CancelButton = styled(Button)`
  width: 40%;
  color: ${({ theme }) => theme.backgrounds.darkGrey};
  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    width: 80vw;
  }
`;

export const GotItButton = styled(Button)`
  width: 35%;
  margin: 20px 0 0 0;
  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    width: 80vw;
  }
`;
