import React, { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../lib/Store';
import { Permission, RoleCode } from '../../../lib/Types';

export interface AuthRouteProps extends RouteProps {
  permissions: Permission[];
  requiredPermission?: Permission;
  code: RoleCode;
  requiresOneOfCode?: RoleCode[];
  children: any;
  termsAccepted: boolean;
  mustUpdatePassword: boolean;
  profileImage: string;
}

const AuthRoute: FC<AuthRouteProps> = ({
  path,
  permissions,
  requiredPermission,
  children,
  termsAccepted,
  code,
  requiresOneOfCode,
  mustUpdatePassword,
  profileImage,
  ...props
}) => {
  const loggedIn = permissions.length > 0;
  const denyAccess =
    (requiresOneOfCode && requiresOneOfCode.length && !requiresOneOfCode.includes(code)) ||
    (!!requiredPermission && !permissions.includes(requiredPermission));
  const mustAcceptTerms = code === RoleCode.CompanyAdmin && !termsAccepted;

  const checkRedirect = () => {
    if (!loggedIn || denyAccess) return <Redirect to="/Login" />;
    if (mustAcceptTerms) return <Redirect to="/TermsAndConditions" />;
    if (mustUpdatePassword) return <Redirect to="/UpdatePassword" />;
    if (!profileImage) return <Redirect to="/Onboarding/ProfilePicture" />;
    return children;
  };

  return (
    <Route {...props} to={path}>
      {checkRedirect()}
    </Route>
  );
};

const mapStateToProps = ({
  permissionsState,
  termsAndConditionsState,
  userState
}: ApplicationState) => ({
  permissions: permissionsState.permissions,
  code: permissionsState.code,
  termsAccepted: termsAndConditionsState.accepted,
  mustUpdatePassword: permissionsState.mustUpdatePassword,
  profileImage: userState.userData.profileImage
});

export default connect(mapStateToProps)(AuthRoute);
