import React, { FC } from 'react';
import Select from 'react-select';

import { Group, Heading, SelectWrapper, SelectIcon } from './Styles';
import { ErrorLabel, RequiredLabel } from '../';

import { tzStrings } from '../../../CONSTANTS';
import { dropdownArrow } from '../../../assets/icons/index';

export type DropdownOption = {
  label: string;
  value: string;
};

interface TimezoneSelectorProps {
  title: string;
  value: string;
  error?: string;
  handleChangeTimezone: (label: string, value: string) => void;
  required?: boolean;
}

// This component uses react-select to make use of search functionality for improved UX when selecting a timezone
// The main SelectDropdown component should be updated to use react select, but I've only added it here due to amound of work and testing required to update all dropdowns
const TimezoneSelector: FC<TimezoneSelectorProps> = ({
  title,
  value,
  error,
  handleChangeTimezone,
  required = false
}) => {
  const mappedSelectedOption: DropdownOption = tzStrings.find(item => item.value === value);

  const Icon = () => <SelectIcon src={dropdownArrow} />;

  return (
    <Group>
      <Heading>{title}</Heading>
      <SelectWrapper active={!!value} valid={!error}>
        {required && <RequiredLabel />}
        {error && <ErrorLabel>{error}</ErrorLabel>}
        <Select
          classNamePrefix="react-select"
          value={mappedSelectedOption}
          options={tzStrings}
          placeholder="Select a timezone..."
          onChange={({ value }) => handleChangeTimezone('timezone', value)}
          components={{ DropdownIndicator: Icon }}
        />
      </SelectWrapper>
    </Group>
  );
};

export default TimezoneSelector;
