import styled from 'styled-components/macro';
import { Button } from '../../components/UI';

interface ImageProps {
  image: string;
}

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.padding * 4}px;
`;

export const InputGroup = styled.div`
  padding: ${({ theme }) => theme.padding}px 0;
  width: 100%;
`;

export const InputGroupHeading = styled.h4`
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.padding * 1.5}px;
`;

export const Section = styled.div`
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const PhotoHeading = styled.h4`
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 1.4rem;
`;

export const PhotoNote = styled.p<{ light?: boolean }>`
  color: #366696;
  font-style: italic;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  opacity: 0.5;
`;

export const UploadButton = styled(Button)`
  margin-top: ${({ theme }) => theme.padding}px;
  max-width: 12rem;
  border: 1px #75dbff solid;
  color: #658cd7;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PhotoSection = styled.div`
  display: flex;
  margin: 24px 0px;
`;

export const UserPhoto = styled.div<ImageProps>`
  height: 10rem;
  width: 14rem;
  margin-right: 2rem;
  border-radius: 10px;
  margin-right: ${({ theme }) => theme.padding * 2}px;
  text-align: center;
  background: center / cover ${props => `url('${props.image}') no-repeat`};
`;

export const PhotoPlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 14rem;
  margin-right: 2rem;
  border-radius: 10px;
  border: dashed 1px rgba(101, 140, 215, 0.5);
`;

export const PhotoPlaceholder = styled.img``;

export const LoaderContainer = styled.div`
  height: 10rem;
  width: 14rem;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
