import * as yup from 'yup';

const replaceNewUserSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required()
});

export type ReplaceNewUserSchema = yup.InferType<typeof replaceNewUserSchema>;

export default replaceNewUserSchema;
