import React, { FC } from 'react';
import { StyledHeader, SortIcon } from './Styles';
import { caretUp, caretDown } from '../../../../assets/icons';

export interface TableHeaderProps {
  numbered?: boolean;
  dropable?: boolean;
  mapProp: string;
  orderColumn: string;
  orderDirection: 'ASC' | 'DESC';
  onSort: (column: string) => void;
}

const TableHeader: FC<TableHeaderProps> = ({
  mapProp,
  orderColumn = 'name',
  dropable,
  orderDirection,
  onSort,
  numbered,
  children
}) => {
  const getCaret: string = orderDirection === 'ASC' ? caretUp : caretDown;

  return (
    <>
      <StyledHeader numbered={numbered} dropable={dropable} onClick={() => onSort(mapProp)}>
        <span title="Click to sort">{children}</span>
        {orderColumn === mapProp ? <SortIcon src={getCaret} /> : ''}
      </StyledHeader>
    </>
  );
};

export default TableHeader;
