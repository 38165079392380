import React, { FC } from 'react';

import InputCopy from '../InputCopy';

import { DarkBlueHeaderL, FlexRow } from '../../../Themes';
import { Wrapper } from './Styles';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'src/lib/Store';

const CompanyInvite: FC = () => {
  const { company } = useSelector((state: ApplicationState) => ({
    company: state.userState.userCompany
  }));

  const inviteTitle = 'Invite employees to join your company with the link below:';
  const passCodeTitle = "They'll also need this passcode";

  return (
    <Wrapper>
      <DarkBlueHeaderL>Invite Your Employees to Join</DarkBlueHeaderL>
      <FlexRow height="auto" flex={1}>
        <FlexRow flex={2}>
          <InputCopy
            flexValues={[2, 1]}
            title={inviteTitle}
            copyLabel="Copy Link"
            inputValue={`${window.location.origin.toString()}/Register`}
          />
        </FlexRow>
        &nbsp;&nbsp;&nbsp;
        <FlexRow flex={1}>
          <InputCopy
            flexValues={[1, 1]}
            title={passCodeTitle}
            copyLabel="Copy Passcode"
            inputValue={company.passCode}
          />
        </FlexRow>
      </FlexRow>
    </Wrapper>
  );
};

export default CompanyInvite;
