import { ActivityType } from '../../../Types';

export const SET_ACTIVITY_TYPES = 'SET_ACTIVITY_TYPES';

export interface ActivityTypeState {
  activityTypes: ActivityType[];
}

export interface SetActivityTypesAction {
  type: typeof SET_ACTIVITY_TYPES;
  activityTypes: ActivityType[];
}

export type ActivityTypeActionTypes = SetActivityTypesAction;
