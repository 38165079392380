import React, { FC, MouseEvent } from 'react';
import {} from './Styles';
import { StyledButton, Icon } from './Styles';
import { InputVariant } from '../../../../lib/Types';

export interface ToolbarButtonProps {
  variant: InputVariant;
  icon?: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ToolbarButton: FC<ToolbarButtonProps> = ({ icon, children, ...props }) => {
  return (
    <StyledButton {...props}>
      {children}
      {icon ? <Icon src={icon} /> : ''}
    </StyledButton>
  );
};

export default ToolbarButton;
