import { StatsState, StatsActionTypes, SET_YOUR_STEPS, SET_TEAM_STATS } from './types';

const initialState: StatsState = {
  yourSteps: [],
  teamStats: [],
  myTeamStats: []
};

const statsReducer = (state: StatsState = initialState, action: StatsActionTypes): StatsState => {
  switch (action.type) {
    case SET_YOUR_STEPS:
      return { ...state, yourSteps: action.yourSteps };
    case SET_TEAM_STATS:
      return { ...state, teamStats: action.teamStats };
    default:
      return state;
  }
};

export default statsReducer;
