/* eslint-disable @typescript-eslint/camelcase */
import * as QueryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from '../../lib/Store';
import { getDeviceAccessToken, getGarminAccessToken } from '../../lib/Api/SyncService';
import { SyncServiceCode } from '../../lib/Types';
import { EmployeePageWrapper, Paper } from '../../components/UI';
import { ModalTitle } from '../../components/UI/Modal/Styles';

export interface SyncDeviceProps extends RouteComponentProps {
  getAuthToken: (serviceCode: string, authCode: string, state: string) => any;
  getGarminAuthToken: (
    serviceCode: string,
    oauth_token: string,
    oauth_verifier: string,
    state: string
  ) => any;
}

const SyncDevice: FC<SyncDeviceProps> = ({
  history,
  location,
  getAuthToken,
  getGarminAuthToken
}) => {
  const [step, setStep] = useState(0);

  const { code, state, oauth_token, oauth_verifier } = QueryString.parse(location.search); // State is encoded here
  const authCode: string = code as string;

  let decodedState;
  let encodedState;
  if (state) {
    const decoded = window.atob(state as string);
    decodedState = JSON.parse(decoded);
    decodedState.syncDevice = true;
    encodedState = window.btoa(JSON.stringify(decodedState));
  }

  let heading = 'Synchronizing Device...';
  switch (step) {
    case 0:
      heading = 'Synchronizing Device...';
      break;
    case 1:
      heading = 'Whoops, something went wrong.';
      break;
    default:
      break;
  }

  const getToken = async () => {
    const serviceCode = SyncServiceCode[decodedState.serviceCode];

    const { data, error } =
      serviceCode === SyncServiceCode.garmin
        ? await getGarminAuthToken(
            serviceCode,
            oauth_token as string,
            oauth_verifier as string,
            encodedState
          )
        : await getAuthToken(serviceCode, authCode, encodedState);

    if (error || !data) {
      setStep(1);
      history.push(location.pathname);
    }

    if (data && data.state) {
      const decodedState = JSON.parse(window.atob(data.state as string));
      const { location, syncDevice } = decodedState;
      history.push(`${location}?syncDevice=${syncDevice}`);
    }
  };

  useEffect(() => {
    const { code, state, oauth_token, oauth_verifier } = QueryString.parse(location.search);
    let decodedState;

    if (state) {
      const decoded = window.atob(state as string);
      decodedState = JSON.parse(decoded);
      const { syncDevice } = decodedState;

      if (syncDevice) {
        setStep(0);
      }
    }

    const authCode = code;
    if (authCode || (oauth_token && oauth_verifier)) {
      getToken();
    }
  }, [location.search]);
  return (
    <EmployeePageWrapper>
      <Paper>
        <ModalTitle>{heading}</ModalTitle>
        {step === 1 ? (
          <>
            <br />
            <p>
              Something went wrong whilst attempting to synchronize your device. Please contact your
              administrator.
            </p>
          </>
        ) : null}
      </Paper>
    </EmployeePageWrapper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.loadingState.apiCallsInProgress > 0
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAuthToken: (serviceCode: string, authCode: string, state: string) =>
        getDeviceAccessToken(serviceCode, authCode, state),
      getGarminAuthToken: (
        serviceCode: string,
        oauth_token: string,
        oauth_verifier: string,
        state: string
      ) => getGarminAccessToken(serviceCode, oauth_token, oauth_verifier, state)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SyncDevice));
