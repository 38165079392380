import styled from 'styled-components/macro';

import theme from '../../theme';
import { DarkBlueHeaderM } from '../../Themes';
import { eventRegistrationGraphic } from '../../assets/img';

export interface GridProps {
  gridRowEnd?: boolean;
}

export const Heading = styled.h2<{ margin?: string }>`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-style: italic;
  font-size: ${({ theme }) => theme.fonts.subHeading}rem;
  color: ${({ theme }) => theme.primary.dark};
  margin: ${({ theme, margin }) => margin || `0 0 0 ${theme.padding}px`};
`;

export const SubHeading = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-style: italic;
  font-size: ${({ theme }) => theme.fonts.subHeading * 0.75}rem;
  color: ${props => (props.color ? props.color : theme.primary.dark)};
`;

export const DbGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-template-areas:
    'stAp stAp stAp stAp tmAp tmAp tmAp tmAp scPs scPs scPs scPs'
    'regNo regNo regNo tottm tottm tottm unAp unAp unAp wtTm wtTm wtTm'
    'regNo regNo regNo tottm tottm tottm unAp unAp unAp wtTm wtTm wtTm'
    'blnCh blnCh blnCh blnCh  blnCh blnCh blnCh blnCh blnCh blnCh blnCh blnCh'
    'blnCh blnCh blnCh blnCh  blnCh blnCh blnCh blnCh blnCh blnCh blnCh blnCh'
    'blnCh blnCh blnCh blnCh  blnCh blnCh blnCh blnCh blnCh blnCh blnCh blnCh'
    'blnCh blnCh blnCh blnCh  blnCh blnCh blnCh blnCh blnCh blnCh blnCh blnCh'
    'blnCh blnCh blnCh blnCh  blnCh blnCh blnCh blnCh blnCh blnCh blnCh blnCh'
    'blnCh blnCh blnCh blnCh  blnCh blnCh blnCh blnCh blnCh blnCh blnCh blnCh'
    'blnCh blnCh blnCh blnCh  blnCh blnCh blnCh blnCh blnCh blnCh blnCh blnCh'
    'blnCh blnCh blnCh blnCh  blnCh blnCh blnCh blnCh blnCh blnCh blnCh blnCh'
    'blnCh blnCh blnCh blnCh  blnCh blnCh blnCh blnCh blnCh blnCh blnCh blnCh';
  grid-gap: 2rem;
  place-items: center center;
  place-content: center center;
  width: auto;
  flex: 1;
  margin-top: 1rem;
  margin-bottom: 5rem;
`;

export const DbCard = styled.div`
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  height: 100%;
  padding: 3rem;
  border-radius: 1.3rem;
  @media (min-width: ${theme.device.tablet}px) and (max-width: ${theme.device.laptop}px) {
    padding: 3rem 2rem;
  }
`;

const QuickStatsItem = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const StepApprovals = styled(QuickStatsItem)`
  grid-area: stAp;
`;

export const TeamApprovals = styled(QuickStatsItem)`
  grid-area: tmAp;
`;

export const MakeSocialPost = styled(QuickStatsItem)`
  grid-area: scPs;
`;

export const Registrations = styled(DbCard)`
  display: flex;
  grid-area: regNo;
`;

export const TotalTeams = styled(DbCard)`
  display: flex;
  grid-area: tottm;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  height: 100%;
`;

export const UnapprovedTeams = styled(DbCard)`
  display: flex;
  grid-area: unAp;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  height: 100%;
`;

export const WithoutTeam = styled(DbCard)`
  display: flex;
  grid-area: wtTm;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  height: 100%;
`;

export const RegistrationsCard = styled(DbCard)<GridProps>`
  display: flex;
  grid-row-start: 4;
  grid-column-start: 1;
  grid-column-end: span 12;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  flex-direction: column;
  font-weight: 400;
  z-index: 0;
  grid-row-end: ${props => (props.gridRowEnd === true ? 9 : 8)};
`;

export const RegistrationsBargraph = styled.div`
  display: flex;
  grid-area: blnCh;
  width: 100%;
  flex-direction: column;
  font-weight: 400;
  z-index: 1;
  min-height: 400px;
`;

export const ChartHeaderWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const ChartHeader = styled(DarkBlueHeaderM)`
  padding-right: 3rem;
  font-size: calc(1.1rem + 0.4vw);
`;

export const RegisterForEvent = styled.div`
  width: 100%;
  height: 372px;
  background-image: url(${eventRegistrationGraphic});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100%;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
`;

export const RegisterForm = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 3rem 0 0 3rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;
