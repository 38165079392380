import React, { FC } from 'react';
import { brand } from '../../../assets/img/index';
import { Image } from './Styles';

interface LogoProps {
  size?: string;
  source?: any;
  borderRadius?: string;
  verticalAlign?: string;
  actionButton?: boolean;
  padding?: string;
  onClick?: () => any;
}

const Logo: FC<LogoProps> = ({
  borderRadius = '0%',
  size = '90%',
  source = brand,
  onClick = () => null,
  actionButton = false,
  verticalAlign,
  padding
}) => {
  return (
    <>
      <Image
        actionButton={actionButton}
        borderRadius={borderRadius}
        verticalAlign={verticalAlign}
        onClick={onClick}
        alt="Logo"
        width={size}
        height={size}
        padding={padding}
        src={source}
      />
    </>
  );
};

export default Logo;
