import * as yup from 'yup';

const participantSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  companyId: yup.number().required(),
  password: yup
    .string()
    .trim()
    .matches(/^.*[0-9].*$/, 'Password must contain 1 number.')
    .matches(/^.*[A-Z].*$/, 'Password must contain 1 capital letter.')
    .min(8, 'Password must be greater than 8 characters.')
    .max(16, 'Password must be less than 16 characters.'),
  active: yup.boolean().required(),
  sdaExemption: yup.boolean().required(),
  userDemographics: yup.array().of(
    yup.object({
      userDemographicId: yup.number().nullable(true),
      childDemographicId: yup.number().when('childDemographicAnswer', {
        is: val => val !== null, // alternatively: (val) => val == true
        then: yup.number().nullable(true),
        otherwise: yup.number().required('This Demographic is required.')
      }),
      childDemographicAnswer: yup.string().nullable(true)
    })
  )
});

export type ParticipantSchema = yup.InferType<typeof participantSchema>;

export default participantSchema;
