import { Reducer } from 'redux';
import {
  RewardsState,
  RewardsActionTypes,
  SET_REWARDS,
  ADD_REWARD,
  UPDATE_REWARD,
  SET_REWARDS_FILTER,
  SET_DELETING_REWARD,
  SET_EDITING_REWARD,
  SET_REWARD_OPTIONS
} from './types';

export const initialRewardsState: RewardsState = {
  rewards: {
    results: [],
    count: 0
  },
  rewardsFilter: {
    page: 1,
    rowsPerPage: 10,
    orderColumn: 'rewardId',
    orderDirection: 'ASC'
  },
  rewardOptions: [],
  rewardIDForDeleting: null,
  deletingReward: false,
  rewardIDForEditing: null
};

const RewardsReducer: Reducer<RewardsState, RewardsActionTypes> = (
  state = initialRewardsState,
  action
) => {
  switch (action.type) {
    case SET_REWARDS:
      return {
        ...state,
        rewards: action.rewards,
        rewardIDForEditing: null,
        deletingReward: false,
        rewardIDForDeleting: null
      };
    case SET_REWARD_OPTIONS:
      return {
        ...state,
        rewardOptions: action.rewardOptions,
        rewardIDForEditing: null,
        deletingReward: false,
        rewardIDForDeleting: null
      };
    case ADD_REWARD:
      return {
        ...state,
        rewards: {
          results: [action.reward, ...state.rewards.results],
          count: state.rewards.count
        },
        rewardIDForEditing: null,
        deletingReward: false,
        rewardIDForDeleting: null
      };
    case UPDATE_REWARD:
      return {
        ...state,
        rewards: {
          results: state.rewards.results.map(reward =>
            reward.rewardId === action.rewardData.rewardId
              ? { ...reward, ...action.rewardData }
              : reward
          ),
          count: state.rewards.count
        },
        rewardIDForEditing: null,
        deletingReward: false,
        rewardIDForDeleting: null
      };
    case SET_REWARDS_FILTER:
      return {
        ...state,
        rewardsFilter: action.filter,
        rewardIDForEditing: null
      };
    case SET_DELETING_REWARD:
      return {
        ...state,
        rewardIDForDeleting: action.rewardIDForDeleting,
        deletingReward: action.deletingReward,
        rewardIDForEditing: null
      };
    case SET_EDITING_REWARD:
      return {
        ...state,
        rewardIDForEditing: action.rewardIDForEditing,
        deletingReward: false,
        rewardIDForDeleting: null
      };
    default:
      return state;
  }
};

export default RewardsReducer;
