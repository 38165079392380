import { AdminDashBoardData } from '../../../../Types';
import { SetDashBoardDataAction, SET_DASHBOARD_DATA } from './types';

export const setDashBoardData = (dashBoardData: AdminDashBoardData): SetDashBoardDataAction => ({
  type: SET_DASHBOARD_DATA,
  dashBoardData
});

export default {
  setDashBoardData
};
