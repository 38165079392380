import React, { FC } from 'react';
import Lottie from 'lottie-react-web';
import logoJSON from '../../../../../assets/animations/logo.json';
import { BrandContainer } from './Styles';

const options = {
  animationData: logoJSON,
  loop: true,
  autoplay: true,
  renderer: 'svg'
};

const Brand: FC = () => (
  <BrandContainer>
    <Lottie options={options} />
  </BrandContainer>
);

export default Brand;
