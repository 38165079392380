import styled from 'styled-components/macro';

export const Image = styled.img<{
  borderRadius?: string;
  verticalAlign?: string;
  actionButton?: boolean;
  padding?: string;
}>`
  border-radius: ${({ borderRadius }) => (borderRadius === '0%' ? '0%' : `${borderRadius}`)};
  vertical-align: ${({ verticalAlign }) => (verticalAlign ? verticalAlign : ``)};
  ${({ actionButton }) => (actionButton ? `cursor: pointer;` : '')};
  padding: ${({ padding }) => (padding ? padding : `0`)};
`;
