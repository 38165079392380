import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { FormikProps } from 'formik';
import moment from 'moment-timezone';

import {
  hasActivity,
  clearExistingValues,
  getActivitiesByDate
} from './services/Utils.AddActivities';
import { formikEnhancer } from './services/Utils.AddActivities';

import Form from './components/Form';

import { ApplicationState } from '../../../lib/Store';
import { AddActivitiesProps, AddActivityFormState } from './types';
import { AddActivityValidationSchema } from './services/Config.AddActivities';
import { addActivity, editActivity, deleteActivity } from '../../../lib/Api/Activity';

const AddActivityForm: FC<AddActivitiesProps & FormikProps<AddActivityFormState>> = ({
  values,
  status,
  errors,
  handleSubmit,
  handleChange,
  setFieldValue,
  setStatus,
  setCollapsed,
  apiLoading,
  eventStartDate,
  eventEndDate,
  activityTypes,
  favoriteTypes,
  allActivities,
  deleteActivity,
  stepApprovalThreshold,
  isSubmitting
}) => {
  const disabled =
    apiLoading || (values.repetitions === null && values.previousActivities.length === 0);

  useEffect(() => {
    setFieldValue(
      'previousActivities',
      getActivitiesByDate(allActivities, moment(values.completionDate))
    );
  }, [allActivities, values.completionDate]);

  return (
    <Form
      {...values}
      {...status}
      stepApprovalThreshold={stepApprovalThreshold}
      activityTypes={activityTypes}
      favoriteTypes={favoriteTypes}
      disabled={disabled}
      formErrors={errors}
      onChange={handleChange}
      setFieldValue={setFieldValue}
      setStatus={setStatus}
      setCollapsed={setCollapsed}
      onSubmit={handleSubmit}
      startDate={moment(eventStartDate, 'x')}
      endDate={moment(eventEndDate, 'x')}
      deleteActivity={(activityId, windowLocation) =>
        deleteActivity(activityId, windowLocation.pathname)
      }
      hasActivity={hasActivity(allActivities)}
      clearExistingValues={() => clearExistingValues(setFieldValue, setStatus)}
      isSubmitting={isSubmitting}
    />
  );
};

const mapStateToProps = ({ loadingState, userState }: ApplicationState) => ({
  apiLoading: loadingState.apiCallsInProgress > 0,
  user: userState.userData,
  eventId: userState.userEvent.eventId,
  timezone: userState.userEvent.timezone,
  eventStartDate: userState.userEvent.startDate,
  eventEndDate: userState.userEvent.endDate,
  activityTypes: userState.userEvent.activityTypes,
  favoriteTypes: userState.userEvent.favoriteTypes,
  allActivities: userState.userEvent.activities,
  stepApprovalThreshold: userState.userEvent.stepApprovalThreshold
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addActivity: (activityEntry: AddActivityValidationSchema, updateRanks?: string) =>
        addActivity(activityEntry, updateRanks),
      editActivity: (activityEntry: AddActivityValidationSchema, updateRanks?: string) =>
        editActivity(activityEntry, updateRanks),
      deleteActivity: (activityId: number, updateRanks?: string) =>
        deleteActivity(activityId, updateRanks)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(formikEnhancer(AddActivityForm));
