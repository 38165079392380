import styled from 'styled-components/macro';

import { eventRegistrationGraphic } from '../../../assets/img';

interface ColouredContainerProps {
  textColor?: string;
  color?: string;
}

export const ColouredContainer = styled.div<ColouredContainerProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme, color }) => (color ? color : theme.backgrounds.lightGrey)};
  color: ${({ theme, textColor }) => (textColor ? textColor : theme.primary.dark)};
  margin-top: 1.5rem;
  padding: 1.5rem;
  border-radius: 7px;
  text-align: left;
`;

export const ClickableText = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

export const Body = styled.p<{ margin?: string }>`
  font-style: italic;
  font-size: ${({ theme }) => theme.fonts.subBody}rem;
  color: ${({ theme }) => theme.primary.dark};
  margin: ${({ theme, margin }) => margin || `0 0 0 ${theme.padding}px`};
`;

export const TwoColumnWrapper = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: 3rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
`;

export const ColumnSection = styled.div<{ autoMarginBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2rem;
`;

export const RegisterForEvent = styled.div<{ step: number }>`
  width: 100%;
  height: ${({ step }) => step === 1 && '320px'};
  min-height: ${({ step }) => step === 1 && '320px'};
  background-image: ${({ step }) => step === 1 && `url(${eventRegistrationGraphic})`};
  background-repeat: no-repeat;
  background-position: '80% top';
  background-size: 'auto 100%';
  background-color: ${({ theme }) => theme.backgrounds.white};
  border-radius: 14px;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  padding-bottom: ${({ step }) => step === 1 && '12rem'};

  @media (min-width: ${({ theme }) => theme.device.laptopL}px) {
    background-position: 'right top';
  }

  @media (min-width: ${({ theme }) => theme.device.laptopL}px) {
    background-size: cover;
  }
`;

export const RegisterForm = styled.div<{ step: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ step }) => (step === 1 ? 'fit-content' : '100%')};
  padding: 2rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
`;
