import React, { FC } from 'react';
import { withFormik, FormikProps } from 'formik';
import LoginForm from './LoginForm';
import validationSchema, { LoginCredentials } from '../../validators/loginCredentialsSchema';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../lib/Store';
import { signIn } from '../../../../lib/Api/User';

interface LoginFormContainerProps {
  apiLoading: boolean;
  onSignIn: (email: string, password: string, rememberMe: boolean) => any;
}

interface LoginFormState {
  email: string;
  password: string;
  rememberMe: boolean;
}

const formikEnhancer = withFormik<LoginFormContainerProps, LoginFormState>({
  mapPropsToValues: () => ({
    email: '',
    password: '',
    rememberMe: false
  }),
  mapPropsToTouched: () => ({
    email: false,
    password: false
  }),
  validationSchema,
  handleSubmit: async (
    { email, password, rememberMe }: LoginCredentials,
    { props, setErrors }
  ): Promise<void> => {
    const { error } = await props.onSignIn(email.toLowerCase(), password, rememberMe);
    if (error) setErrors({ [error.context]: error.message });
  },
  validateOnBlur: false,
  validateOnChange: false
});

const LoginFormContainer: FC<LoginFormContainerProps & FormikProps<LoginFormState>> = ({
  values,
  handleSubmit,
  handleChange,
  errors,
  apiLoading,
  touched,
  setFieldTouched,
  isSubmitting
}) => (
  <LoginForm
    {...values}
    disabled={isSubmitting || apiLoading}
    formErrors={errors}
    onChange={handleChange}
    onSubmit={handleSubmit}
    formTouched={touched}
    setFieldTouched={setFieldTouched}
    isSubmitting={isSubmitting || apiLoading}
  />
);

const mapStateToProps = ({ loadingState }: ApplicationState) => ({
  apiLoading: loadingState.apiCallsInProgress > 0
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onSignIn: (email: string, password: string, rememberMe: boolean) =>
        signIn(email, password, rememberMe)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(formikEnhancer(LoginFormContainer));
