import { EventActionTypes, SET_EVENT, SET_EVENT_OPTIONS, SET_EVENT_FILTER } from './types';
import { Event, PaginationFilter } from '../../../Types';

export const setEvent = (event: Event): EventActionTypes => ({
  type: SET_EVENT,
  event
});

export const setEventOptions = (eventOptions: Event[]): EventActionTypes => ({
  type: SET_EVENT_OPTIONS,
  eventOptions
});

export const setEventFilter = (filter: PaginationFilter): EventActionTypes => ({
  type: SET_EVENT_FILTER,
  filter
});
