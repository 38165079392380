import {
  SetUserAction,
  SET_USER,
  UpdateUserAction,
  UPDATE_USER,
  RemoveUserAction,
  REMOVE_USER,
  SetCompanyAction,
  SET_COMPANY,
  SetEventAction,
  SET_EVENT,
  SetEventActivityTypesAction,
  SET_EVENT_ACTIVITY_TYPES,
  SetEventActivitiesAction,
  SET_EVENT_ACTIVITIES,
  AddEventActivityAction,
  ADD_EVENT_ACTIVITY,
  EditEventActivityAction,
  EDIT_EVENT_ACTIVITY,
  DeleteEventActivityAction,
  DELETE_EVENT_ACTIVITY,
  SET_USER_COMPANY_EVENT_ID,
  SetUserCompanyEventIdAction,
  SetRewardsAction,
  SET_REWARDS,
  SetDevicesAction,
  SET_DEVICES,
  SetRewardsToCompleteAction,
  SET_REWARDS_TO_COMPLETE
} from './types';
import {
  User,
  Company,
  Event,
  Activity,
  ActivityType,
  FavoriteActivityType,
  Reward,
  FitnessDevice,
  CompanyEventStatus
} from '../../../Types';

export const setUser = (user: User): SetUserAction => ({
  type: SET_USER,
  user
});

export const setUserCompanyEventId = (userCompanyEventId: number): SetUserCompanyEventIdAction => ({
  type: SET_USER_COMPANY_EVENT_ID,
  userCompanyEventId
});

export const updateUser = (userUpdates: Partial<User>): UpdateUserAction => ({
  type: UPDATE_USER,
  userUpdates
});

export const removeUser = (): RemoveUserAction => ({
  type: REMOVE_USER
});

export const setUserCompany = (company: Company): SetCompanyAction => ({
  type: SET_COMPANY,
  company
});

export const setUserEvent = (event: CompanyEventStatus): SetEventAction => ({
  type: SET_EVENT,
  event
});

export const setUserRewards = (rewards: Reward[]): SetRewardsAction => ({
  type: SET_REWARDS,
  rewards
});

export const setRewardsToComplete = (rewards: Reward[]): SetRewardsToCompleteAction => ({
  type: SET_REWARDS_TO_COMPLETE,
  rewards
});

export const setUserEventActivityTypes = (
  allTypes: Array<ActivityType>,
  favoriteTypes: Array<FavoriteActivityType>
): SetEventActivityTypesAction => ({
  type: SET_EVENT_ACTIVITY_TYPES,
  allTypes,
  favoriteTypes
});

export const setUserEventActivities = (activities: Array<Activity>): SetEventActivitiesAction => ({
  type: SET_EVENT_ACTIVITIES,
  activities
});

export const setUserDevices = (devices: FitnessDevice[]): SetDevicesAction => ({
  type: SET_DEVICES,
  devices
});

export const addEventActivity = (activity: Activity): AddEventActivityAction => ({
  type: ADD_EVENT_ACTIVITY,
  activity
});

export const editEventActivity = (activity: Activity): EditEventActivityAction => ({
  type: EDIT_EVENT_ACTIVITY,
  activity
});

export const deleteEventActivity = (activityId: number): DeleteEventActivityAction => ({
  type: DELETE_EVENT_ACTIVITY,
  activityId
});
