import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPastEvents } from '../../lib/Api/companyAdmin/Event';

import { PageWrapper, NoRecordsFound } from '../../components/UI';
import PaginatedTable from '../../components/UI/PaginatedTable';
import LoadingIndicator from '../../components/UI/LoadingIndicator';

import { eventGuy } from '../../assets/icons/index';

import { defaultCompanyEventPaymentsFilter } from '../../lib/Store/contexts/companyAdmin/company/types';
import { APIThunkDispatch, OrderDirection, TableHeaderItem } from '../../lib/Types';
import { ApplicationState } from '../../lib/Store';

const tableHeaderItems: TableHeaderItem[] = [
  { display: 'Event Dates', mapProp: 'eventDates' },
  { display: 'Event', mapProp: 'name' },
  { display: 'Participants', mapProp: 'paidParticipants' },
  { display: 'Status', mapProp: 'active', mapBool: { true: 'Active', false: 'Ended' } }
];

const PastEvents: React.FC = () => {
  const Dispatch: APIThunkDispatch = useDispatch();
  const {
    companyAdminEventState: { pastEvents, currentEventLoading, pastEventsLoading },
    userState: {
      userCompany: { companyId }
    }
  } = useSelector((state: ApplicationState) => state);
  const loading = pastEventsLoading || currentEventLoading;

  const [filter, setFilter] = useState(defaultCompanyEventPaymentsFilter);

  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    setFilter({ ...filter, orderColumn, orderDirection });
  const setPage = (page: number) => setFilter({ ...filter, page });

  useEffect(() => {
    (async () => await Dispatch(getPastEvents(companyId, filter)))();
  }, [filter]);

  return (
    <PageWrapper event={false} company={false}>
      {pastEvents.count ? (
        <PaginatedTable
          idProp="eventPaymentId"
          headers={tableHeaderItems}
          filter={filter}
          data={pastEvents}
          loading={pastEvents.count < 1}
          alignNumberedCellsCenter={false}
          onSort={setSort}
          onSetPage={setPage}
        />
      ) : loading ? (
        <LoadingIndicator />
      ) : (
        <NoRecordsFound icon={eventGuy} message="You do not have any finished events." />
      )}
    </PageWrapper>
  );
};

export default PastEvents;
