import styled, { css } from 'styled-components/macro';

import { Theme } from '../../../theme';
import { InputVariant } from '../../../lib/Types';
import { ButtonProps } from './Button';

const getVariantStyles = (variant: InputVariant, { gradients, backgrounds }: Theme) => {
  switch (variant) {
    case 'primary':
      return css`
        color: white;
        border: none;
        background: ${gradients.blue};
      `;
    case 'secondary':
      return css`
        color: white;
        border: none;
        background: ${gradients.green};
      `;
    case 'cancel':
      return css`
        color: #505a66;
        border: none;
        background: #d5d9e1;
      `;
    case 'disabled':
      return css`
        color: ${backgrounds.disabled};
        border: none;
        background: ${backgrounds.grey};
      `;
    case 'clear':
      return css`
        color: #a3bae7;
        background: ${backgrounds.white};
        font-style: normal;
        border: 2px solid #a3bae7;
      `;
    case 'report':
      return css`
        color: white;
        border: none;
        background: ${gradients.red};
      `;
    case 'admin-primary':
      return css`
        color: #658cd7;
        border: none;
        background: ${gradients.ltBlue};
        text-transform: uppercase;
        text-decoration: none;
        :hover {
          filter: brightness(105%) !important;
          transform: translateY(-1px) scale(1.03, 1.03);
        }
      `;
    case 'admin-cancel':
      return css`
        color: #b9bbc0;
        background-color: #eff0f3;
        border: none;
        text-transform: uppercase;
        :hover {
          color: #aeb0b5;
          background-color: #e9eaed;
          filter: brightness(100%) !important;
          transform: translateY(-1px) scale(1.03, 1.03);
        }
      `;
    case 'primary-purple':
      return css`
        color: white;
        border: none;
        background: ${gradients.blueToPurple};
      `;
    case 'purple':
      return css`
        color: white;
        border: none;
        background: ${gradients.purple};
      `;
  }
};

const getButtonLoaderBG = (variant: InputVariant, { gradients, backgrounds }: Theme) => {
  switch (variant) {
    case 'primary':
      return css`
        background: ${gradients.blue};
      `;
    case 'secondary':
      return css`
        background: ${gradients.green};
      `;
    case 'cancel':
      return css`
        background: #d5d9e1;
      `;
    case 'disabled':
      return css`
        background: ${backgrounds.grey};
      `;
    case 'clear':
      return css`
        background: ${backgrounds.white};
      `;
    case 'report':
      return css`
        background: ${gradients.red};
      `;
    case 'admin-primary':
      return css`
        background: ${gradients.ltBlue};
      `;
    case 'admin-cancel':
      return css`
        background: #eff0f3;
      `;
    case 'primary-purple':
      return css`
        background: ${gradients.blueToPurple};
      `;
    case 'purple':
      return css`
        background: ${gradients.purple};
      `;
  }
};

export const StyledButton = styled.button<ButtonProps>`
  ${({ variant = 'default', theme }) => getVariantStyles(variant, theme)};
  ${({ buttonFlex }) => (buttonFlex ? `flex: ${buttonFlex}` : '')};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : 'italic')};
  font-size: 1.3rem;
  font-weight: ${props => props.fontWeight || 700};
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth || '100%'};
  min-width: ${props => props.minWidth || '30%'};
  margin: ${props => (props.margin ? props.margin : 0)};
  border-radius: 2em;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  padding: ${props => props.padding || '1.5rem'};
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  letter-spacing: 0.03rem;
  line-height: ${props => props.lineHeight || 'normal'};
  height: ${props => props.height || 'auto'};
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  position: relative;
  overflow: hidden;
  transition: all, 0.2s linear;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.4;
    `};

  &:hover {
    /* transform: ${({ disabled }) => (disabled ? '' : 'translateY(-0.2rem)')};
    box-shadow: ${({ disabled }) => (disabled ? '' : '0 0.3rem 2rem rgba(0, 0, 0, 0.2)')}; */
    ${({ variant, scaleHover, disabled }) => {
      if (scaleHover && !disabled) {
        return css`
          transform: scale(1.08);
        `;
      }
      if (variant !== 'disabled' && !disabled) {
        return css`
          filter: brightness(110%);
        `;
      }
    }}
  }

  :active {
    /* transform: ${({ disabled }) => (disabled ? '' : 'translateY(-0.1rem)')};
    box-shadow: ${({ disabled }) => (disabled ? '' : '0 0.2rem 1rem rgba(0, 0, 0, 0.2)')}; */
  }

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(1.2rem + 0.7vw);
  }
`;

export const LoaderWrapper = styled.div<{ background: string; variant: InputVariant }>`
  ${({ background, variant, theme }) =>
    background ? `background: ${background}` : getButtonLoaderBG(variant, theme)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
