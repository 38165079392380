import React, { FC } from 'react';

import { Button, Paper, AdminFormHeader } from '../../../../components/UI';
import TeamDetails from './formComponents/TeamDetails';
import TeamMembers from './formComponents/TeamMembers';
import ModalFormContainer from './ModalFormContainer';
import DeleteTeamParticipant from './DeleteTeamParticipant';
import ActionButton from '../../../../components/UI/PaginatedTable/TableActions/ActionButton';
import { TeamContainer, ButtonWrapper, ApproveButtonWrap } from './Styles';

import { TeamSchema } from '../../validators/teamSchema';
import { FormikForm, Team, ParticipantListView, PendingParticipant } from '../../../../lib/Types';
import { thumbsUp, tickGreen } from '../../../../assets/icons';
import { theme } from '../../../../theme';

interface TeamFormProps extends FormikForm<TeamSchema> {
  team: Team;
  participant: ParticipantListView;
  pendingParticipant: PendingParticipant;
  removeRegistration: (team: Team, userId: string) => void;
  resendInvitationEmail: (email: string) => void;
  approveTeam: (teamId: number) => void;
  loadParticipant: (participantId: string) => void;
  loadPendingParticipant: (data: PendingParticipant) => void;
  onCancel: () => void;
  onReplaceCancel: () => void;
  getTeam: (teamId: number) => any;
  setTeam: (team: Team) => any;
  setAddingTeamMember: (addingTeamMember: boolean) => any;
  deletePendingParticipant: (teamId: number, email: string) => any;
  setDeleting: (deletingParticipant: boolean) => any;
  deletingParticipant: boolean;
  adding: boolean;
  loading: boolean;
}

const TeamForm: FC<TeamFormProps> = ({
  handleSubmit,
  onCancel,
  getTeam,
  setTeam,
  handleChangeCustom,
  approveTeam,
  deletePendingParticipant,
  setDeleting,
  ...props
}) => {
  const onCancelDeleting = () => setDeleting(false);
  const onDeletePendingTeamMember = (teamId: number, email: string) =>
    deletePendingParticipant(teamId, email);

  return (
    <Paper padding={'3rem 4.5rem'} minWidth={'690px'}>
      <AdminFormHeader title={'Edit Team'} onClick={onCancel} backBtn={true} />
      <br />
      <form onSubmit={handleSubmit}>
        <TeamContainer>
          <TeamDetails {...props} />
          <TeamMembers
            handleRadioChange={handleChangeCustom}
            setDeleting={setDeleting}
            {...props}
          />
        </TeamContainer>
        <ApproveButtonWrap>
          <ActionButton
            onClick={() => {
              approveTeam(props.team.teamId);
              setTeam(getTeam(props.team.teamId));
            }}
            backgroundColour={!props.team.approved && theme.status.danger}
            disabled={props.team.approved}
            icon={props.team.approved ? tickGreen : thumbsUp}
            border={props.team.approved && `1px solid ${theme.primary.mediumGreen}`}
            textColourOverride={props.team.approved && theme.primary.mediumGreen}
          >
            {props.team.approved ? 'Approved' : 'Approve'}
          </ActionButton>
        </ApproveButtonWrap>
        <ButtonWrapper>
          <Button
            onClick={onCancel}
            type="button"
            variant={'admin-cancel'}
            width={'15%'}
            minWidth={'95px'}
            margin={'0 1.5rem 0 0'}
          >
            Cancel
          </Button>
          <Button type="submit" variant={'admin-primary'} width={'15%'} minWidth={'95px'}>
            Save
          </Button>
        </ButtonWrapper>
      </form>
      {props.participant.teamId !== null || props.adding ? <ModalFormContainer {...props} /> : null}
      {props.deletingParticipant ? (
        <DeleteTeamParticipant
          onCancel={onCancelDeleting}
          onDelete={onDeletePendingTeamMember}
          {...props}
        />
      ) : null}
    </Paper>
  );
};

export default TeamForm;
