import styled from 'styled-components/macro';
import { ReplaceText } from '../../../../components/UI/TeamTable/Styles';

interface RowProps {
  odd: boolean;
}

// Wraps the Team Details and Table
export const TeamContainer = styled.div``;

/* ------------------- Table Components ---------------------- */

// Wraps the Table Header and Table
export const TableContainer = styled.div`
  width: 100%;
  max-width: 740px;

  @media (min-width: ${({ theme }) => theme.device.laptop}px) {
    width: 85%;
  }
  @media (min-width: ${({ theme }) => theme.device.laptopL}px) {
    width: 70%;
  }
`;

// Wraps the TeamTable and the Add Participant Row
export const TableWrap = styled.div`
  width: 100%;
  display: table;
`;

export const TableHeader = styled.div`
  display: flex;
  width: 99%;
  margin: 0 0 0.5rem 0;
`;

export const CaptainHeader = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.subbody};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.primary.dark};
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.subBody * 1.1}rem;
  font-style: italic;
  margin: auto 5px auto auto;
  max-width: 45px;
  width: 45px;
  min-width: 45px;
  content: 'Captain';
  display: block;
`;

/* ----------------- Add Participant ----------------------- */
export const AddMemberRow = styled.div<RowProps>`
  display: flex;
  border-radius: 6rem;
  padding: 4px 0 0 0;
  background-color: ${({ odd }) => (odd ? '#ffffff' : '#f7f7f7')};
  width: 100%;
  margin-bottom: 1rem;
  height: 36px;
  vertical-align: middle;
`;

export const AddText = styled(ReplaceText)`
  white-space: nowrap;
`;

/* ----------------------- Buttons ------------------------- */
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 6rem;
  width: 100%;
  margin: 2rem 0 0 0;
`;

export const ApproveButtonWrap = styled.div`
  margin: 1.5rem auto auto 0.3rem;
  height: 5rem;
  width: 10.8rem;
`;
