import { Reducer } from 'redux';
import {
  LeaderBoardState,
  LeaderBoardActionTypes,
  SET_PAGED_TEAM_RANKS,
  SET_MY_TEAM_RANK,
  SET_PAGED_TEAM_RANK_FILTER,
  SET_ACTIVE_TAB,
  SET_FILTER_BY_COMPANY,
  SET_INDIVIDUAL_RANKINGS,
  SET_OWN_RANKING,
  SET_INDIVIDUAL_FILTER,
  SET_WATER_COOLER_LEADER_BOARD_FILTER
} from './types';

export const initialLeaderBoardState: LeaderBoardState = {
  pagedTeamRanks: {
    results: [],
    count: 0
  },
  myTeamRank: null,
  pagedTeamRankFilter: {
    page: 1,
    rowsPerPage: 30,
    orderColumn: 'totalPointsRank',
    orderDirection: 'ASC'
  },
  activeTab: 'Overall',
  filterByCompany: true,
  individualRankings: {
    results: [],
    count: 0
  },
  ownRanking: null,
  individualFilter: {
    page: 1,
    rowsPerPage: 30,
    orderColumn: 'totalPoints',
    orderDirection: 'DESC'
  },
  waterCoolerLeaderBoardFilter: {
    page: 1,
    rowsPerPage: 20,
    orderColumn: 'totalPoints',
    orderDirection: 'DESC'
  }
};

const DashboardReducer: Reducer<LeaderBoardState, LeaderBoardActionTypes> = (
  state = initialLeaderBoardState,
  action
) => {
  switch (action.type) {
    case SET_PAGED_TEAM_RANKS:
      return {
        ...state,
        pagedTeamRanks: action.pagedTeamRanks
      };
    case SET_MY_TEAM_RANK:
      return {
        ...state,
        myTeamRank: action.myTeamRank
      };
    case SET_PAGED_TEAM_RANK_FILTER:
      return {
        ...state,
        pagedTeamRankFilter: action.pagedTeamRankFilter
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      };
    case SET_FILTER_BY_COMPANY:
      return {
        ...state,
        filterByCompany: action.filterByCompany
      };
    case SET_INDIVIDUAL_RANKINGS:
      return {
        ...state,
        individualRankings: action.individualRankings
      };
    case SET_OWN_RANKING:
      return {
        ...state,
        ownRanking: action.ownRanking
      };
    case SET_INDIVIDUAL_FILTER:
      return {
        ...state,
        individualFilter: action.individualFilter
      };
    case SET_WATER_COOLER_LEADER_BOARD_FILTER:
      return {
        ...state,
        waterCoolerLeaderBoardFilter: action.waterCoolerLeaderBoardFilter
      };
    default:
      return state;
  }
};

export default DashboardReducer;
