import React, { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useSelector } from 'react-redux';

import { loadCompanies, deleteCompany, LoadCompanies, DeleteCompany } from '../../lib/Api/Company';

import { PageWrapper } from '../../components/UI';
import ToolbarButton from '../../components/UI/Button/ToolbarButton';
import PaginatedTable from '../../components/UI/PaginatedTable';
import PaginatedTableSearch from '../../components/UI/PaginatedTableSearch';
import Modal from '../../components/UI/Modal';
import CreateOrEditCompany from './CreateOrEditCompany';
import { Paper, ModalButton, NoRecordsFound } from '../../components/UI';
import LoadingIndicator from '../../components/UI/LoadingIndicator';

import { eventGuy, plus } from '../../assets/icons/index';
import { RedText, DarkBlueHeaderL, DarkBlueBodyM } from '../../Themes';
import { ButtonWrapper } from './Styles';

import { defaultCompanyListFilter } from '../../lib/Store/contexts/company/types';
import { PaginationFilter, OrderDirection, Permission, TableHeaderItem } from '../../lib/Types';
import { ApplicationState } from '../../lib/Store';

export interface CompaniesProps {
  onLoadCompanies: LoadCompanies;
  onDeleteCompany: DeleteCompany;
}

const tableHeaderItem: TableHeaderItem[] = [
  { display: 'Name', mapProp: 'name' },
  { display: 'Active', mapProp: 'active', mapBool: { true: 'Active', false: 'Inactive' } }
];

const Companies: React.FC<CompaniesProps> = ({ onLoadCompanies, onDeleteCompany }) => {
  const {
    companyState: { companies: companiesList },
    permissions,
    loading
  } = useSelector((state: ApplicationState) => ({
    companyState: state.companyState,
    permissions: state.permissionsState.permissions,
    loading: state.loadingState.apiCallsInProgress > 0
  }));

  const [filter, setFilter] = useState(defaultCompanyListFilter);
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState<number>();
  const [deleting, setDeleting] = useState<number>();

  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    setFilter({ ...filter, orderColumn, orderDirection });
  const setPage = (page: number) => setFilter({ ...filter, page });
  const setKeyword = (keyword: string) => setFilter({ ...filter, keyword });

  useEffect(() => {
    (async () => await onLoadCompanies(filter))();
  }, [filter]);

  const modalOpener = permissions.includes(Permission.CREATE_COMPANY) && !creating && !editing && (
    <ToolbarButton variant="primary" icon={plus} onClick={() => setCreating(true)}>
      Create a Company
    </ToolbarButton>
  );

  const handleDelete = async () => {
    await onDeleteCompany(deleting);
    setDeleting(null);
  };

  const renderContent = () => {
    if (creating) {
      return (
        <Paper>
          <CreateOrEditCompany
            onSuccess={() => setCreating(false)}
            onCancel={() => setCreating(false)}
          />
        </Paper>
      );
    }
    if (editing) {
      return (
        <Paper>
          <CreateOrEditCompany
            companyId={editing}
            onSuccess={() => setEditing(null)}
            onCancel={() => setEditing(null)}
          />
        </Paper>
      );
    }
    if (deleting) {
      return (
        <Modal
          top={5}
          title={'Are you sure you want to delete this company?'}
          icon={eventGuy}
          width={40}
          left={30}
          padding={'20px 40px'}
        >
          <DarkBlueHeaderL>
            <RedText>Warning! This action will remove records.</RedText>
          </DarkBlueHeaderL>
          <br />
          <DarkBlueBodyM>
            Are you sure you wish to delete this Company? This action cannot be undone and will
            remove all records associated with this Company, including Users, Activity Records,
            Demographic Answers, Posts, Rewards, etc.
          </DarkBlueBodyM>
          <br />
          <ButtonWrapper>
            <ModalButton type="submit" variant={'remove'} onClick={handleDelete}>
              Yes, Delete
            </ModalButton>
            <ModalButton onClick={() => setDeleting(null)} type="button" variant={'primary'}>
              No, Keep
            </ModalButton>
          </ButtonWrapper>
        </Modal>
      );
    }
    return (
      <>
        <PaginatedTableSearch onKeywordFilter={setKeyword} />
        {companiesList.count ? (
          <PaginatedTable
            idProp="companyId"
            headers={tableHeaderItem}
            filter={filter}
            data={companiesList}
            loading={companiesList.count < 1}
            onSort={setSort}
            onSetPage={setPage}
            onDelete={(id: number) => setDeleting(id)}
            onEdit={(id: number) => setEditing(id)}
            actions={[
              { type: 'edit', show: permissions.includes(Permission.EDIT_COMPANY) },
              { type: 'delete', show: permissions.includes(Permission.DELETE_COMPANY) }
            ]}
          />
        ) : loading ? (
          <LoadingIndicator />
        ) : (
          <NoRecordsFound icon={eventGuy} message="There are currently no companies" />
        )}
      </>
    );
  };

  return (
    <PageWrapper event={false} company={false} formOpener={modalOpener}>
      {renderContent()}
    </PageWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onLoadCompanies: (filter: PaginationFilter) => loadCompanies(filter) as any,
      onDeleteCompany: (companyId: number) => deleteCompany(companyId) as any
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Companies);
