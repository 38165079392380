import {
  SET_CURRENT_EVENT,
  SET_EVENT_LINKING_INFO,
  SET_EVENT_LINKING_LOADING,
  EventActionTypes,
  EventState,
  SET_CURRENT_EVENT_LOADING,
  SET_PAST_EVENTS,
  SET_PAST_EVENTS_LOADING
} from './types';

export const initialState: EventState = {
  currentEvent: null,
  currentEventLoading: false,
  pastEvents: {
    results: [],
    count: 0
  },
  pastEventsLoading: false,
  eventLinkingInfo: {
    eventId: null,
    name: null,
    costPerParticipant: 0,
    passCode: null,
    registrationsStartDate: null,
    registrationsEndDate: null,
    startDate: null,
    endDate: null,
    timezone: null
  },
  eventLinkingLoading: false
};

const eventReducer = (state: EventState = initialState, action: EventActionTypes): EventState => {
  switch (action.type) {
    case SET_CURRENT_EVENT:
      return {
        ...state,
        currentEvent: action.currentEvent
      };
    case SET_CURRENT_EVENT_LOADING:
      return {
        ...state,
        currentEventLoading: action.loading
      };
    case SET_EVENT_LINKING_INFO:
      return {
        ...state,
        eventLinkingInfo: { ...action.payload }
      };
    case SET_EVENT_LINKING_LOADING:
      return {
        ...state,
        eventLinkingLoading: action.loading
      };
    case SET_PAST_EVENTS:
      return {
        ...state,
        pastEvents: action.pastEvents
      };
    case SET_PAST_EVENTS_LOADING:
      return {
        ...state,
        pastEventsLoading: action.loading
      };
    default:
      return state;
  }
};

export default eventReducer;
