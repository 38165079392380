import {
  CompanyActionTypes,
  CompanyState,
  SET_COMPANY_EVENT_PAYMENTS,
  SET_COMPANY_EVENT_PAYMENTS_LOADING
} from './types';

export const initialState: CompanyState = {
  eventPayments: {
    results: [],
    count: 0
  },
  eventPaymentsLoading: false
};

const companyReducer = (
  state: CompanyState = initialState,
  action: CompanyActionTypes
): CompanyState => {
  switch (action.type) {
    case SET_COMPANY_EVENT_PAYMENTS:
      return {
        ...state,
        eventPayments: {
          ...action.companyEventPayments
        }
      };
    case SET_COMPANY_EVENT_PAYMENTS_LOADING:
      return {
        ...state,
        eventPaymentsLoading: action.loading
      };
    default:
      return state;
  }
};

export default companyReducer;
