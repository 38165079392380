import React, { FC } from 'react';
import { brand } from '../../../assets/img/index';
import { Image } from './Styles';

interface LinkProps {
  size?: string;
  source?: any;
  borderRadius?: string;
  verticalAlign?: string;
  actionButton?: boolean;
  margin?: string;
  onClick?: () => any;
  color?: string;
}

const TableLink: FC<LinkProps> = ({
  borderRadius = '0%',
  size = '90%',
  source = brand,
  onClick = () => null,
  actionButton = false,
  verticalAlign,
  margin,
  color
}) => {
  return (
    <>
      <Image
        actionButton={actionButton}
        borderRadius={borderRadius}
        verticalAlign={verticalAlign}
        onClick={onClick}
        alt="Logo"
        size={size}
        margin={margin}
        src={source}
        color={color}
        width="24px"
      />
    </>
  );
};

export default TableLink;
