import React, { FC, useState, useRef, useCallback } from 'react';
import { TableSearchInput, SearchInputContainer, SearchInputIcon } from './Styles';
import { searchBarIcon, searchBarCloseIcon } from '../../../assets/img/index';
import { debounce } from 'lodash';

interface PaginatedTableSearchProps {
  onKeywordFilter: (keyword: string) => void;
}

export const PaginatedTableSearch: FC<PaginatedTableSearchProps> = ({ onKeywordFilter }) => {
  const [search, setSearch] = useState(''),
    inputRef = useRef(null);

  const makeSearch = (searchVal: string) => {
    onKeywordFilter(searchVal);
    setSearch(searchVal);
  };

  // Debounce so that we don't request for every letter - only submit search after no changes for 400ms
  const makeDebouncedSearch = useCallback(debounce((text) => makeSearch(text), 400), []);

  const clearSearch = () => {
    setSearch('');
    onKeywordFilter('');
    inputRef.current.value = '';
  };

  return (
    <SearchInputContainer>
      <SearchInputIcon src={searchBarIcon} />
      <TableSearchInput
        onChange={e => makeDebouncedSearch(e.currentTarget.value)}
        ref={inputRef}
        placeholder="Search&hellip;"
      />
      {search !== '' && (
        <SearchInputIcon
          src={searchBarCloseIcon}
          title="Clear search"
          rightAlign
          onClick={() => clearSearch()}
        />
      )}
    </SearchInputContainer>
  );
};
