import { apiFetch, apiRequest, objectToQueryString } from './Utils';
import { setPagedSDAList, setSDA } from '../Store/contexts/suspectDailyActivity/actions';

import { server } from '../../CONSTANTS';
import { APIThunkResult, PagedResponse, SuspectDailyActivity, PaginationFilter } from '../Types';

export function getSDA(suspectDailyActivityId: number): APIThunkResult<SuspectDailyActivity> {
  return apiRequest<SuspectDailyActivity>(async dispatch => {
    const result = await apiFetch<SuspectDailyActivity>({
      method: 'GET',
      url: `${server}/suspect-daily-activities/${suspectDailyActivityId}`
    });
    dispatch(setSDA(result));
    return result;
  });
}

export function loadPagedSDAList(
  filter: PaginationFilter
): APIThunkResult<PagedResponse<SuspectDailyActivity>> {
  return apiRequest<PagedResponse<SuspectDailyActivity>>(async dispatch => {
    const query = objectToQueryString(filter);
    const url = `${server}/suspect-daily-activities/paged?${query}`;

    const results = await apiFetch<PagedResponse<SuspectDailyActivity>>({
      method: 'GET',
      url
    });
    dispatch(setPagedSDAList(results));

    return results;
  });
}

export function approveSDA(suspectDailyActivityId: number): APIThunkResult<SuspectDailyActivity> {
  return apiRequest<SuspectDailyActivity>(async (dispatch, getState) => {
    const { SDAState } = getState();
    const result = await apiFetch<SuspectDailyActivity>({
      method: 'GET',
      url: `${server}/suspect-daily-activities/approve/${suspectDailyActivityId}`
    });
    dispatch(loadPagedSDAList(SDAState.filter));
    return result;
  });
}

export function rejectSDA(suspectDailyActivityId: number): APIThunkResult<SuspectDailyActivity> {
  return apiRequest<SuspectDailyActivity>(async (dispatch, getState) => {
    const { SDAState } = getState();
    const result = await apiFetch<SuspectDailyActivity>({
      method: 'GET',
      url: `${server}/suspect-daily-activities/reject/${suspectDailyActivityId}`
    });
    dispatch(loadPagedSDAList(SDAState.filter));
    return result;
  });
}
