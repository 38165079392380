import React from 'react';
import styled from 'styled-components/macro';
import { pfpPlaceholder } from '../../../assets/img';

interface ImageProps {
  image: string;
}

const StyledProfileImage = styled.div<ImageProps>`
  border-radius: 50%;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadows.light};
  display: flex;
  flex: 0 0 4rem;
  height: 4rem;
  background: center / cover ${props => `url('${props.image}') no-repeat`};

  /* Using this because borders warp the image for some reason */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    border: 3px solid white;
  }
`;

export const ProfileImage = ({ src }) => {
  const fallbackSource = src || pfpPlaceholder;
  return <StyledProfileImage image={fallbackSource} />;
};
