import React, { useState, useEffect, useRef, FC } from 'react';

import Checkbox from './Checkbox';
import Input from './Input';

import { dropdownArrow } from '../../../assets/icons/index';
import { searchIcon } from '../../../assets/img';

import {
  StyledDropdown,
  SelectedWrapper,
  Selected,
  OptionsWrapper,
  Option,
  ToggleIcon,
  Icon,
  NoSearchResults
} from './Styles';

export type DropdownOption = {
  value: string;
  label: string;
  selected?: boolean;
};

export interface MultiDropdownProps {
  id: string;
  onLoadOption?: DropdownOption;
  onLoadOptions?: DropdownOption[];
  options: DropdownOption[];
  placeholder: string;
  overrideLabel?: string | null;
  multi?: boolean;
  searchable?: boolean;
  tickSelected?: boolean;
  linePadding?: string;
  onOptionClick: (option: DropdownOption | DropdownOption[]) => void;
}

const MultiDropdown: FC<MultiDropdownProps> = ({
  id,
  onLoadOption,
  onLoadOptions,
  options = [],
  placeholder,
  multi,
  searchable,
  tickSelected,
  linePadding,
  onOptionClick
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<DropdownOption | DropdownOption[] | undefined>(
    onLoadOption || onLoadOptions
  );
  const [searchTerm, setSearchTerm] = useState('');

  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: Event): void => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  const handleOptionClick = (option: DropdownOption) => {
    if (multi) {
      const selectedList: DropdownOption[] = (selected as DropdownOption[]) || [];
      // removing from selected array
      const index = selectedList.findIndex(opts => opts.value === option.value);
      if (index > -1) {
        selectedList.splice(index, 1);
      } else {
        // Adding to selected array
        selectedList.push(option);
      }
      onOptionClick(selectedList);
      setSelected(selectedList);
    } else {
      // Non multi
      setOpen(false);
      setSelected(option);
      onOptionClick(option);
    }
  };

  const handleDropDownClick = e => {
    if (searchable) {
      if (e.target.type === 'text') return;
      if (e.target.id === 'searchIcon' && open) return;
    }
    setOpen(!open);
  };

  const getLabel = () => {
    if (!selected) return placeholder;
    if (searchable && open) {
      return (
        <Input
          id={id}
          label="Search..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          inputRef={searchRef}
        />
      );
    }
    if (multi) {
      if ((selected as DropdownOption[]).length > 1) return 'Multiple Selected';
      return (selected as DropdownOption[])[0]?.label || placeholder;
    }
    return (selected as DropdownOption).label;
  };

  const checkOptionSelected = (option: DropdownOption): boolean => {
    if (multi) {
      const castSelected = selected as DropdownOption[];
      return castSelected && castSelected.findIndex(opt => opt.value === option.value) !== -1;
    }
    const castSelected = selected as DropdownOption;
    return castSelected && option.value === castSelected.value;
  };

  const searchSensitiveOptions =
    searchable && searchTerm
      ? options.filter(option => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
      : options;

  return (
    <StyledDropdown ref={dropdownRef}>
      <SelectedWrapper
        open={open}
        onClick={handleDropDownClick}
        selected={
          multi
            ? selected && !!(selected as DropdownOption[]).length
            : selected && !!(selected as DropdownOption)
        }
      >
        {searchable && (
          <Icon
            id="searchIcon"
            src={searchIcon}
            onClick={() => {
              searchRef.current && searchRef.current.focus();
            }}
          />
        )}
        <Selected searchable={searchable}>{getLabel()}</Selected>
        <ToggleIcon open={open} src={dropdownArrow} />
      </SelectedWrapper>
      {open && (
        <OptionsWrapper
          selected={
            multi
              ? selected && !!(selected as DropdownOption[]).length
              : selected && !!(selected as DropdownOption)
          }
        >
          {searchable && searchTerm && !searchSensitiveOptions.length ? (
            <NoSearchResults>Try adjusting your search</NoSearchResults>
          ) : (
            searchSensitiveOptions.map(option => (
              <Option
                key={option.value}
                selected={checkOptionSelected(option)}
                onClick={() => handleOptionClick(option)}
                tickSelected={tickSelected}
                linePadding={linePadding}
              >
                {tickSelected && (
                  <Checkbox
                    checked={checkOptionSelected(option)}
                    onChange={() => handleOptionClick(option)}
                  />
                )}
                <Selected>{option.label}</Selected>
              </Option>
            ))
          )}
        </OptionsWrapper>
      )}
    </StyledDropdown>
  );
};

export default MultiDropdown;
