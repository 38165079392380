import React, { FC, ChangeEvent, FocusEvent, useState } from 'react';
import { StyledInput } from './Styles';
import ErrorLabel from '../../../../components/UI/ErrorLabel';

interface InputProps {
  id: string;
  name?: string;
  type?: string;
  placeholder?: string;
  error?: any;
  required?: boolean;
  value: string | number;
  onChange: (e: ChangeEvent<any>) => void;
  onBlur?: (e: FocusEvent<any>) => void;
  width?: string;
  margin?: string;
}

const RewardsFormInput: FC<InputProps> = ({
  id,
  name,
  type = 'text',
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  width,
  margin
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <>
      {error ? <ErrorLabel error={error} /> : ''}
      <StyledInput
        required
        id={id}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        valid={!error}
        autoComplete="off"
        onChange={onChange}
        onClick={e => {
          e.preventDefault();
          setFocused(true);
          onChange(e);
        }}
        onBlur={e => {
          e.preventDefault();
          setFocused(false);
          onBlur && onBlur(e);
        }}
        width={width}
        margin={margin}
      />
    </>
  );
};

export default RewardsFormInput;
