import React from 'react';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { ApplicationState, AppActions } from './Store';
import { FormikErrors } from 'formik';

// firebase

export type FirebaseAuthErrors =
  | 'auth/user-not-found'
  | 'auth/wrong-password'
  | 'auth/user-disabled'
  | 'auth/too-many-requests'
  | 'auth/email-already-in-use'
  | 'auth/invalid-email'
  | 'auth/operation-not-allowed'
  | 'auth/weak-password'
  | 'auth/internal-error';

export type AuthErrorContext = 'email' | 'password' | 'internal';

export type DemographicsErrorContext = 'demographics';

export type TermsAndConditionsErrorContext = 'accepted';

export type Unsubscribe = () => void;
export interface CustomClaims {
  permissions: Permission[];
  code: RoleCode;
  mustUpdatePassword: boolean;
}

// thunk

export type APIResult<T = undefined> = {
  status: ApiStatus;
  error?: ErrorResponse;
  data?: T;
};

export type APIThunkResult<T = undefined> = ThunkAction<
  Promise<APIResult<T>>,
  ApplicationState,
  undefined,
  AppActions
>;

export type APIThunkDispatch = ThunkDispatch<ApplicationState, undefined, AppActions>;

export type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE';

// enum

export enum ApiStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  UNCHANGED = 'UNCHANGED'
}

export type LeaderBoardTab = 'Overall' | 'Movement' | 'Connection' | 'Individual';

// interfaces

export interface FormikForm<T> {
  values: T;
  errors: FormikErrors<T>;
  handleChange: (e: any) => void;
  handleChangeCustom?: (name: string, value: any) => void;
  handleSubmit: () => void;
}

export interface FormikFormComponent<T> {
  values: T;
  errors: FormikErrors<T>;
  disabled?: boolean;
  handleChange: (e: any) => void;
}

export interface FilePreview extends File {
  preview?: string | undefined;
}

export enum PointsType {
  Connection = 'Connection',
  Movement = 'Movement',
  Bonus = 'Bonus'
}

export interface UserDemographic {
  userDemographicId: number;
  companyDemographicId: number;
  userId: string;
  childDemographicId: number;
  childDemographicAnswer: string;
}

export interface NewUserDemographic {
  companyDemographicId: number;
  companyId: number;
  childDemographicId: number;
  childDemographicAnswer: string;
}

export interface Role {
  roleId: number;
  name: string;
  code: RoleCode;
  description: string;
  default: boolean;
  active: boolean;
}

export interface User {
  userId: string;
  email: string;
  companyId: number;
  company?: Company;
  firstName: string;
  lastName: string;
  active: boolean;
  roleId: number;
  role?: Role;
  profileImage?: string;
  createdAt?: Date;
  updatedAt?: Date;
  termsAccepted: boolean;
  sdaExemption: boolean;
}

export interface CreateUserResponse {
  user: User;
}

export interface AcceptTermsAndConditionsResponse {
  userId: string | null;
}

export interface CheckTermsAndConditionsAcceptedResponse {
  hasAccepted: boolean | null;
}

export interface PagedResponse<T> {
  results: T[];
  count: number;
}

export interface ChooseDemographicResponse {
  success: boolean;
}

export interface CreateEventResponse {
  eventId: number;
}

export interface EditEventResponse {
  eventId: number;
}

export interface PaginationFilter {
  page: number;
  rowsPerPage: number;
  orderColumn: string;
  orderDirection: OrderDirection;
  keyword?: string;
}

export interface SDAFilter extends PaginationFilter {
  companyId: number;
  eventId: number;
}

export interface ParticipantFilter extends PaginationFilter {
  keyword: string;
  companyId: number;
  eventId: number;
}

export interface TeamListFilter extends PaginationFilter {
  keyword: string;
  companyId: number;
  eventId: number;
}

export interface TableHeaderItem {
  dropable?: boolean;
  numbered?: boolean;
  render?: (datum: any) => React.ReactNode;
  display: string;
  mapProp: string;
  mapBool?: {
    true: string;
    false: string;
  };
  mapCombine?: {
    firstField: string;
    secondField: string;
  };
  mapDeep?: {
    depth?: number;
    flatField: string;
    deepField: string;
    deepField2?: string;
  };
  rawNumber?: boolean;
  wrap?: boolean;
  millis?: boolean;
}

export interface EventActivityType {
  activityTypeId: number;
  activityType: ActivityType;
  createdAt: Date;
  eventId: number;
  updatedAt: Date;
}

export interface ConnectionPointsByPostType {
  postId: number;
  postType: PostType;
  rewardName: string;
  rewardPoints: number;
}

export interface TopActivitiesByType {
  userId: string;
  firstName: string;
  lastName: string;
  name: string;
  steps: number;
  repetitions: number;
  conversion: number;
}

export interface RelativePercentTopFiveActivity {
  percentOfTopFive: number;
  userId: string;
  firstName: string;
  lastName: string;
  name: string;
  steps: number;
  repetitions: number;
  conversion: number;
}

export interface TeamMembersPoints {
  user: User;
  connectionPoints: number;
  movementPoints: number;
  totalPoints: number;
}

export interface DailyActivityPost {
  postId: number;
  participated: boolean;
}

export interface MiniChallengePost extends DailyActivityPost {}

export interface RewardWithEarnedStatus {
  name: string;
  image: string;
  points: number;
  earned: boolean;
  type: PointsType;
}

export interface ActivityType {
  activityTypeId: number;
  name: string;
  description: string;
  measurementType?: string;
  conversion: number;
}

export interface DashBoardData {
  dailyActivities: DailyActivityPost[];
  miniChallenges: MiniChallengePost[];
  rewards: RewardWithEarnedStatus[];
  participantRankView: ParticipantRankView;
}

export interface AdminDashboardStats {
  stepApprovals: number;
  teamApprovals: number;
  totalRegistrations: number;
  totalTeams: number;
  unapprovedTeams: number;
  participantsWithoutTeam: number;
}

export interface AdminDashBoardData {
  stats: AdminDashboardStats;
  companyRegistrations: RegistrationsByCompanyView[];
}

export interface YourSteps {
  completionDate: string;
  value: number;
}

export interface TeamStats {
  userId: string;
  profileImage: string;
  name: string;
  teamCaptain: boolean;
  steps: YourSteps[];
  connectionPoints: number;
  movementPoints: number;
}

export interface FavoriteActivityType {
  activityTypeId: number;
  frequency: string; // TODO: Not sure if this should be a number?
}

export interface ActivityTypesResponse {
  allTypes: Array<ActivityType>;
  favoriteTypes: Array<FavoriteActivityType>;
}

export interface Activity {
  activityId?: number;
  activityTypeId: number;
  completionDate: string;
  repetitions: number;
  approvalStatus: ApprovalStatus;
  eventActivityType: EventActivityType;
}

export interface SuspectDailyActivity {
  suspectDailyActivityId: number;
  name: string;
  totalSteps: number;
  reason: string;
  completionDate: string;
  approvalStatus: ApprovalStatus;
  user?: User;
  suspectActivities?: Activity[];
  event?: Event;
}

export interface CompanyAdmin {
  createdAt: Date;
  companyAdminId: number;
  companyId: number;
  company: Company;
  user: User;
  userId: string;
  phone: string;
  termsAcceptedDate?: string;
  isContact: boolean;
}
export class CompanyAdminListView {
  companyAdminId: number;
  companyId: number;
  phone: string;
  termsAcceptedDate: string;
  isContact: boolean;
  createdAt: string;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  timezone: string;
}

export interface Company {
  createdAt: Date;
  updatedAt: Date;
  companyId: number;
  name: string;
  active: boolean;
  profileImage: string;
  passCode: string;
  registeredEmployees?: number;
  admins: CompanyAdmin[];
  allowManualPayment: boolean;
}

export interface EventPayment {
  eventPaymentId: number;
  stripePaymentId: string;
  companyId: number;
  eventId: number;
  event: Event;
  amount: number;
  quantity: number;
  manualPayment: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface CompanyDemographicsView {
  companyId: number;
  companyDemographicId: number;
  answer: string;
  movementPoints: number;
  connectionPoints: number;
}
export interface CompanyLeaderboardView {
  userId: string;
  companyId: number;
  eventId: number;
  profileImage: string;
  name: string;
  points: number;
}

export interface CompanyDemographic {
  createdAt: Date;
  updatedAt: Date;
  companyDemographicId: number;
  companyId: number;
  eventId: number;
  name: string;
  parentId?: number;
  options?: CompanyDemographic[];
}

export interface Nav {
  name: string;
  path: string;
}

export interface UserTeamRegistration {
  user: User;
  userId: string;
  team: Team;
  teamId: number;
  active: boolean;
  joinedStatus: boolean;
  teamCaptain: boolean;
}

export class EventListView {
  createdAt: string;
  eventId: number;
  name: string;
  startDate: string;
  endDate: string;
  registrationsStartDate: string;
  registrationsEndDate: string;
  timezone: string;
  gracePeriod: number;
  passCode: string;
  eventDates: string;
  totalCompanyRegistrationCount?: string;
}

export interface Event {
  createdAt?: Date;
  updatedAt?: Date;
  eventId: number;
  name: string;
  registrationsStartDate: string;
  registrationsEndDate: string;
  startDate: string;
  endDate: string;
  timezone: string;
  gracePeriod: number;
  stepApproval: boolean;
  stepApprovalThreshold: number;
  averageSteps: number;
  active: boolean;
  teamSize: number;
  costPerParticipant: number;
  paidParticipants: number;
  passCode: string;
  companies: CompanyEventAssignment[];
  activityTypes: ActivityType[];
  favoriteTypes?: FavoriteActivityType[];
  activities: Activity[];
  eventDates?: string;
  greeting?: string;
}
export interface CompanyEventStatus extends Event {
  finalDateLive: string; // millis
  maxParticipants: number;
  status: CompanyEventLinkStatus;
  greeting: string;
  companyDemographics: Partial<CompanyDemographic>[];
  registrationCount: number;
  checkoutUrl?: string;
  companyIdsInEvent?: number[];
}

export interface RegistrationData {
  individualEventRegistration?: IndividualEventRegistration;
  teamMemberRegistrations?: UserTeamRegistration[];
  team?: Team;
}

export type EventLinkingInfo = Pick<
  Event,
  | 'eventId'
  | 'startDate'
  | 'endDate'
  | 'registrationsStartDate'
  | 'registrationsEndDate'
  | 'name'
  | 'costPerParticipant'
  | 'passCode'
  | 'timezone'
>;

export interface UserDemographic {
  childDemographicAnswer: string;
  childDemographicId: number;
  companyDemographicId: number;
  userDemographicId: number;
}

export interface CompanyEventAssignment {
  companyId: number;
  company: Company;
  eventId: number;
  maxParticipants: number;
  greeting: string;
  status: CompanyEventLinkStatus;
  stripeProductPriceId: string;
  checkoutSessionId: string;
  event: Event;
  eventPayments: EventPayment[];
}

export interface JoinedTeamMembers {
  companyId: number;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  firstName: string;
  lastName: string;
  active: boolean;
  roleId: number;
  profileImage: string;
}

export interface PendingTeamMembers {
  createdAt: Date;
  updatedAt: Date;
  pendingRegId: number;
  teamId: number;
  firstName: string;
  lastName: string;
  email: string;
  signUpLinkSent: boolean;
}

export interface TeamMembers {
  joinedMembers: JoinedTeamMembers[];
  pendingMembers: PendingTeamMembers[];
}

export interface Team {
  teamId: number;
  company?: Company;
  companyId: number;
  event?: Event;
  eventId: number;
  name: string;
  approved: boolean;
  activities: any[];
  registrations: any[];
  pendingRegistrations: any[];
  active: boolean;
}

export interface IndividualEventRegistration {
  userId: string;
  eventId: number;
}

export interface NotificationOpts {
  message: string;
  variant?: StatusVariant;
  duration?: number;
}

export interface ErrorResponse {
  name: string;
  message: string;
  data?: { field: string };
}

export interface TableAction {
  type: TableActionType;
  show: boolean;
}

export interface TeamToken {
  email: string;
  firstName: string;
  lastName: string;
  companyId: number;
}

export interface CompanyEventToken {
  companyId: number;
  eventId: number;
}

export interface RegisterUserForEventResponse {
  individualEventRegistration?: IndividualEventRegistration;
  userTeamRegistration?: UserTeamRegistration;
  team?: Team;
}

export interface CheckEventAvailabilityResponse {
  isEventAvailability: boolean;
}

export interface CreateTeamResponse {
  team: Team;
}

export interface GenerateTeamsResponse {
  teamsCreated: number;
}

export interface ParticipantListView {
  participantId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  profileImage: string;
  email: string;
  teamId: number;
  teamName: string;
  teamCaptain: boolean;
  companyId: number;
  eventId: number;
  companyName: string;
  joinedStatus: boolean;
  userActive: boolean;
  sdaExemption: boolean;
}

export interface ParticipantRankView {
  userId: string;
  fullName: string;
  profileImage: string;
  teamName: string;
  eventId: number;
  companyName: string;
  totalPoints: number;
  connectionPoints: number;
  movementPoints: number;
  totalPointsRank: number;
  movementPointsRank: number;
  connectionPointsRank: number;
}

export interface PendingParticipant {
  firstName: string;
  lastName: string;
  email: string;
  teamId: number;
}

export interface ParticipantDemographicsView {
  companyId: number;
  eventId: number;
  demographicName: string;
  answer: string;
  count: number;
}

export interface TeamListView {
  teamId: number;
  rank: number;
  teamName: string;
  members: number;
  createdDate: string;
  companyName: string;
  eventName: string;
  approved: boolean;
}

export interface TeamRankView {
  teamId: number;
  teamName: string;
  teamCaptain: string;
  companyId: number;
  companyName: string;
  steps: number;
  movementPoints: number;
  connectionPoints: number;
  totalPoints: number;
  totalPointsRank: number;
  movementPointsRank: number;
  connectionPointsRank: number;
}

export interface RegistrationsByCompanyView {
  eventId: number;
  companyId: number;
  companyName: string;
  approvedParticipants: number;
  unapprovedParticipants: number;
}
export interface Reward {
  rewardId?: number;
  name: string;
  points: number;
  image: string;
  type: PointsType;
}

export enum PostType {
  General = 'General',
  Laugh = 'Laugh',
  Gratitude = 'Gratitude',
  Connection = 'Connection',
  Sleep = 'Sleep',
  Mindfulness = 'Mindfulness',
  Exercise = 'Exercise',
  Nutrition = 'Nutrition'
}
// General post is of General category. All others are either Mini challenge or daily activity.
export enum PostCategory {
  GENERAL = 'GENERAL',
  MINI_CHALLENGE = 'MINI_CHALLENGE',
  DAILY_ACTIVITY = 'DAILY_ACTIVITY'
}

export enum SocialChallengeType {
  Comment = 'Comment',
  Image = 'Image',
  Video = 'Video'
}

export enum AbuseReportStatus {
  Open = 'Open',
  Ignored = 'Ignored',
  Deleted = 'Deleted'
}

/** Use RoleCode to identify / equate roles. */
export enum RoleCode {
  MasterAdmin = 'masteradmin',
  CompanyAdmin = 'companyadmin',
  Employee = 'employee'
}

export enum CompanyEventLinkStatus {
  PendingStripe = 'PendingStripe',
  PendingManual = 'PendingManual',
  Paid = 'Paid'
}

export interface Post {
  postId: number;
  type: PostType;
  category: PostCategory;
  socialChallengeType: SocialChallengeType;
  rewardId?: number;
  reward?: Reward;
  eventId?: number;
  parentPostId?: number;
  parentPost?: Post;
  replies: Post[];
  date: string;
  likes: number;
  liked?: boolean;
  image?: string;
  video?: string;
  audio?: string;
  title: string;
  message: string;
  challengeText?: string;
  eventDay?: number;
  pinUntil?: string;
  createdAt: string;
  updatedAt: string;
  user: User;
  comments?: number;
  link: string;
}

export interface AbuseReport {
  userId: string;
  user?: User;
  postId: number;
  post?: Post;
  reason: string;
  status: AbuseReportStatus;
  createdAt: string;
  updatedAt: string;
}

export interface EditParticipantResponse {
  participantId: string;
}

export interface SyncService {
  serviceId: number;
  name: string;
  code: SyncServiceCode;
  active: boolean;
  devices: FitnessDevice[];
}

export interface FitnessDevice {
  deviceId: number;
  userId: string;
  authToken: string;
  refreshToken: string;
  authTokenExpiry: number;
  serviceUserId: string;
  serviceId: number;
  service: SyncService;
}

export interface CompanyEventStatus extends Event {
  finalDateLive: string; // millis
  maxParticipants: number;
  paidParticipants: number;
  status: CompanyEventLinkStatus;
  greeting: string;
  registrationCount: number;
  checkoutUrl?: string;
}

export interface FitnessDeviceSyncResponse extends FitnessDevice {
  addedActivities: Activity[];
  state: string;
  message?: string;
}

export enum ApprovalStatus {
  Approved = 'Approved',
  AwaitingApproval = 'AwaitingApproval',
  NotApproved = 'NotApproved'
}

export enum TeamApprovalStatus {
  Approved = 'Approved',
  AwaitingApproval = 'AwaitingApproval',
  AwaitingAssignment = 'AwaitingAssignment'
}

// Misc Types

export interface TokenPayload {
  tokenType: 'teamToken';
  firstName?: string;
  lastName?: string;
  email?: string;
  companyId: number;
  eventId: number;
  iat: number;
}

export type OrderDirection = 'ASC' | 'DESC';

export type ProgressCallback = (progress: number, uploaded: number, total: number) => void;

// UI prop types

export type InputVariant =
  | 'primary'
  | 'default'
  | 'secondary'
  | 'clear'
  | 'cancel'
  | 'disabled'
  | 'report'
  | 'remove'
  | 'admin-primary'
  | 'admin-cancel'
  | 'primary-purple'
  | 'purple';
export type StatusVariant = 'success' | 'danger' | 'warning' | 'info';
export type TableActionType = 'view' | 'edit' | 'delete' | 'approve' | 'reject';

export enum SyncServiceCode {
  fitbit = 'fitbit',
  // eslint-disable-next-line @typescript-eslint/camelcase
  google_fit = 'google_fit',
  garmin = 'garmin',
  // eslint-disable-next-line @typescript-eslint/camelcase
  apple_health = 'apple_health'
}

export interface ReportType {
  reportTypeId: number;
  name: string;
}

export enum Permission {
  VIEW_USERS = 'view:users',
  CREATE_USER = 'create:user',
  EDIT_USER = 'edit:user',
  EDIT_USER_ALL = 'edit:user_all',

  VIEW_COMPANIES = 'view:companies',
  CREATE_COMPANY = 'create:company',
  EDIT_COMPANY = 'edit:company',
  DELETE_COMPANY = 'delete:company',
  CREATE_COMPANY_ADMIN = 'create:company_admin',
  VIEW_COMPANY_ADMINS = 'view:company_admins',

  VIEW_EVENTS = 'view:events',
  CREATE_EVENT = 'create:event',
  EDIT_EVENT = 'edit:event',
  DELETE_EVENT = 'delete:event',

  CREATE_EVENT_REGISTRATION = 'create:event_registration',
  EDIT_EVENT_REGISTRATION = 'edit:event_registration',
  VIEW_EVENT_REGISTRATION = 'view:event_registration',
  VIEW_EVENT_REGISTRATIONS = 'view:event_registrations',

  CREATE_POSTS = 'create:posts',
  CREATE_REPLY = 'create:reply',
  CREATE_SPECIAL_POSTS = 'create:special_posts',

  VIEW_EVENT_PARTICIPANTS = 'view:event_participants',
  VIEW_ALL_EVENT_PARTICIPANTS = 'view:all_event_participants',
  VIEW_ALL_EVENT_PARTICIPANTS_WITHOUT_TEAM = 'view:all_event_participants_without_team',
  VIEW_ALL_COMPANY_EVENT_PARTICIPANTS = 'view:all_company_event_participants',
  VIEW_ALL_COMPANY_EVENT_PARTICIPANTS_WITHOUT_TEAM = 'view:all_company_event_participants_without_team',
  ADD_EVENT_PARTICIPANTS = 'create:event_participants',
  EDIT_EVENT_PARTICIPANTS = 'edit:event_participants',
  REMOVE_EVENT_PARTICIPANTS = 'delete:event_participants',
  REPLACE_EVENT_PARTICIPANTS = 'replace:event_participants',
  RESEND_EVENT_PARTICIPANT_INVITATION = 'resend:event_participant_invitation',

  VIEW_TEAMS = 'view:teams',
  VIEW_ALL_TEAMS = 'view:all_teams',
  VIEW_ALL_COMPANY_TEAMS = 'view:all_company_teams',
  CREATE_TEAM = 'create:team',
  EDIT_TEAM = 'edit:team',
  APPROVE_TEAM = 'approve:team',
  GENERATE_TEAM = 'generate:team',
  CREATE_EMPTY_TEAM = 'create:empty_team',

  SDA_ADMIN = 'admin:sda',

  VIEW_COMPANY_DASHBOARD = 'view:company:dashboard',
  VIEW_COMPANY_DEMOGRAPHICS = 'view:company:demographics',

  VIEW_REPORTS = 'view:report',

  EDIT_REWARD = 'edit:reward'
}

// Styled component props
export interface AnimationDelayProps {
  delay?: number;
}
export interface ContainerProps extends AnimationDelayProps {
  elevation?: 1 | 2 | 3 | 4 | 5;
  leftColumn?: boolean;
}

// Used for EmployeePageWrapper
export enum IgnoreHoldingStateCondition {
  ALWAYS = 'ALWAYS',
  REG_OPEN = 'REG_OPEN'
}

export interface RecaptchaVerificationResponse {
  success: true | false; // whether this request was a valid reCAPTCHA token
  challenge_ts?: string; // timestamp of the challenge load (ISO format yyyy-MM-dd'T'HH:mm:ssZZ)
  hostname?: string; // the hostname of the site where the reCAPTCHA was solved
  'error-codes'?: []; // optional ie ['missing-input-secret']
}

export interface CompanyRegistrationData extends Company {
  registrationCount: number;
  maxParticipants: number;
  paidParticipants?: number;
  eventName: string;
  costPerParticipant: number;
  status: CompanyEventLinkStatus;
  // amount: number;
}
