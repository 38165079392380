import styled from 'styled-components/macro';
import { Button } from '../../../../components/UI/index';

export const BodyText = styled.p`
  width: 75%;
  font-size: ${({ theme }) => theme.fonts.body}rem;
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin: 0 0 ${({ theme }) => theme.padding * 3}px 0;
  line-height: 2.5rem;
`;

export const Form = styled.form`
  width: 43.5rem;
`;
export const Submit = styled(Button)`
  margin-top: ${({ theme }) => theme.padding}px;
`;
