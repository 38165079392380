import styled from 'styled-components/macro';
import { Button, Input } from '../../../../components/UI/index';
import { CommentProfileImageContainer } from '../Comment/CommentProfileImageContainer';
import { FlexRow } from '../../../../Themes';

interface CommentProps {
  isComment?: boolean;
}

export const Form = styled.form`
  position: relative;
  margin-top: ${({ theme }) => theme.padding}px;
`;

export const CommentFormContainer = styled.div`
  display: flex;
  width: 100%;
  /* position: relative; */
  h4 {
    color: #5fe2ff;
    text-transform: uppercase;
  }
`;

export const CommentFormTitle = styled.h4<CommentProps>`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  letter-spacing: 0.15em;

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    margin-left: 5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

export const CommentFormBody = styled.div`
  flex: 9;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CommentFormProfileImage = styled(CommentProfileImageContainer)``;

export const CommentFormImageContainer = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.padding * 2}px;
  /* margin-right: ${({ theme }) => theme.padding}px; */
`;

export const CommentFormVideoContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.padding * 1.5}px;
  border: ${({ theme }) => theme.borders.input.focused};
  border-radius: 0.35rem;
  padding: 0.3rem 0.3rem 0.3rem 1.5rem;
  font-size: calc(${({ theme }) => theme.fontScale.sm} * 1.1);
  color: ${({ theme }) => theme.primary.dark};
`;

export const CommentFormVideoIcon = styled.img`
  width: 5rem;
  height: 5rem;
  margin: ${({ theme }) => theme.padding}px;
`;

export const CommentFormMediaControl = styled.div`
  height: 3rem;
  width: 3rem;
  border-radius: calc(3rem / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.padding}px;
  background-color: ${({ theme }) => theme.primary.dark};
  cursor: pointer;
`;

export interface CommentFormVideoRowProps {
  reverse?: boolean;
}
export const CommentFormVideoRow = styled(FlexRow)<CommentFormVideoRowProps>`
  justify-content: ${({ reverse }) => (reverse ? 'flex-end' : 'flex-start')};
`;

export interface CommentFormImageRowProps {
  reverse?: boolean;
}
export const CommentFormImageRow = styled(FlexRow)<CommentFormImageRowProps>`
  position: absolute;
  height: auto;
  width: auto;
  top: ${({ theme }) => theme.padding}px;
  right: ${({ theme }) => theme.padding}px;
  justify-content: ${({ reverse }) => (reverse ? 'flex-end' : 'flex-start')};
`;

export const CommentFormInput = styled(Input)``;

export const CommentFormControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: ${({ theme }) => theme.padding}px;
`;

export const CommentFormMedia = styled.div`
  cursor: pointer;

  img {
    height: 2rem;
    margin-right: ${({ theme }) => theme.padding}px;
  }
`;

export const CommentFormMediaError = styled.span`
  color: ${({ theme }) => theme.status.danger};
`;
export const Submit = styled(Button)`
  max-width: 50vw;
  width: max-content;
  height: 4.5rem;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  cursor: pointer;
  color: ${({ theme }) => theme.backgrounds.white};
  @media (min-width: ${({ theme }) => theme.device.mobileL}px) {
    max-width: 50%;
  }
  @media (min-width: ${({ theme }) => theme.device.tablet}px) {
    max-width: 35%;
  }
`;
