import {
  TermsAndConditionsState,
  SET_TERMS_ACCEPTED,
  SET_TERMS_NOT_ACCEPTED,
  TermsAndConditionsActionTypes
} from './types';

const initialState: TermsAndConditionsState = {
  accepted: false
};

const termsAndConditionsReducer = (
  state: TermsAndConditionsState = initialState,
  action: TermsAndConditionsActionTypes
): TermsAndConditionsState => {
  switch (action.type) {
    case SET_TERMS_ACCEPTED:
      return {
        accepted: true
      };
    case SET_TERMS_NOT_ACCEPTED:
      return {
        accepted: false
      };
    default:
      return state;
  }
};

export default termsAndConditionsReducer;
