import styled from 'styled-components/macro';
import { GreyBodyS, DarkBlueBodyS, BlackBodyS } from '../../../Themes/TextThemes';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 40px;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form``;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormColumn = styled.div`
  flex: 1;

  &:nth-child(1) {
    padding-right: ${({ theme }) => theme.padding}px;
  }
  &:nth-child(2) {
    padding-right: ${({ theme }) => theme.padding}px;
  }

  ${({ theme }) => theme.device.tablet} {
    &:nth-child(1) {
      padding-right: ${({ theme }) => theme.padding * 6}px;
    }
    &:nth-child(2) {
      padding-right: ${({ theme }) => theme.padding * 2}px;
    }
  }

  ${({ theme }) => theme.device.laptop} {
    &:nth-child(1) {
      padding-right: ${({ theme }) => theme.padding * 8}px;
    }
    &:nth-child(2) {
      padding-right: ${({ theme }) => theme.padding * 3}px;
    }
  }
`;

export const Group = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const Heading = styled.h4`
  font-size: ${({ theme }) => theme.fonts.subHeading / 1.25}rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.primary.dark};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const Body = styled.p`
  font-size: ${({ theme }) => theme.fonts.body / 1.25}rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.primary.dark};
  margin-bottom: ${({ theme }) => theme.padding * 1.5}px;
`;

export const InlineInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InlineInput = styled.div`
  flex: 1;
  &:nth-child(1) {
    padding-right: ${({ theme }) => theme.padding / 2}px;
  }
  &:nth-child(2) {
    padding-left: ${({ theme }) => theme.padding / 2}px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.padding * 4}px;
`;

export const Container = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: auto;
  display: flex;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const LinkText = styled(DarkBlueBodyS)`
  cursor: pointer;
  display: inline;
  line-height: calc((0.676rem + 0.5vw) * 1.5) !important;
`;

export const TermsBody = styled(GreyBodyS)`
  display: inline-block;
  line-height: calc((0.676rem + 0.5vw) * 1.5) !important;
`;

export const TermsHeader = styled(BlackBodyS)`
  display: inline-block;
  margin-top: ${({ theme }) => theme.padding}px;
  line-height: calc((0.676rem + 0.5vw) * 1.5) !important;
`;

export const List = styled.ul`
  padding-left: ${({ theme }) => theme.padding * 2}px;
`;

export const ListItem = styled.li`
  display: list-item;
`;
