import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import BarGraph from './BarGraph';

import { socialsGraphic } from '../../../assets/icons';
import {
  DashboardPanelWrapper,
  HeaderContainer,
  HeaderImage,
  TitleWrapper,
  Header,
  SubHeader,
  SubText
} from './Styles';

import { ApplicationState } from '../../../lib/Store';

const DashboardTeamConnectionPoints: FC = () => {
  const { teamMembersPoints } = useSelector(
    (state: ApplicationState) => state.participantDashboardState
  );

  return (
    <DashboardPanelWrapper>
      <HeaderContainer>
        <TitleWrapper>
          <Header>Connection Points</Header>
          <SubHeader>Your Team</SubHeader>
          <SubText>Make sure to encourage them to participate!</SubText>
        </TitleWrapper>
        <HeaderImage src={socialsGraphic} />
      </HeaderContainer>
      <BarGraph data={teamMembersPoints} pointType="CONNECTION" />
    </DashboardPanelWrapper>
  );
};

export default DashboardTeamConnectionPoints;
