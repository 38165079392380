import styled from 'styled-components/macro';
import theme from '../../../../theme';

export const TopSectionWrapper = styled.div`
  padding: 0;
  margin: 0 0 ${({ theme }) => theme.padding}px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: ${theme.device.tablet}px) {
    display: none;
  }
`;

export const ProfileWrapper = styled.div`
  flex: 1;
`;
