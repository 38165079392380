import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Routes from '../Routes';
import NotificationContainer from '../NotificationContainer';
import useAuth from '../../hooks/useAuth';
import { setPermissions } from '../../lib/Store/contexts/permissions/actions';
import { Permission, RoleCode } from '../../lib/Types';
import GlobalLoadingIndicator from '../UI/GlobalLoadingIndicator';

interface AppProps {
  setPermissions: (code: RoleCode, permissions: Permission[], mustUpdatePassword: boolean) => void;
}

const App: React.FC<AppProps> = ({ setPermissions }) => {
  const { pending, code, permissions, mustUpdatePassword } = useAuth();

  if (pending) return <></>;

  setPermissions(code, permissions, mustUpdatePassword);

  return (
    <div className="App">
      <GlobalLoadingIndicator />
      <Routes />
      <NotificationContainer />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setPermissions: (code: RoleCode, permissions: Permission[], mustUpdatePassword: boolean) =>
        setPermissions(code, permissions, mustUpdatePassword)
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(App);
