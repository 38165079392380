import { FieldArray } from 'formik';
import React, { FC } from 'react';
import SelectDropdown from '../../../../../components/UI/SelectDropdown';
import { FormikFormComponent, UserDemographic, CompanyDemographic } from '../../../../../lib/Types';
import { ParticipantSchema } from '../../../validators/participantSchema';
import { Group, Heading } from '../Styles';
import { DarkBlueHeaderM } from 'src/Themes';
import { Input } from 'src/components/UI';

interface ParticipantDemographicsProps extends FormikFormComponent<ParticipantSchema> {
  companyDemographics: CompanyDemographic[];
  userDemographics: UserDemographic[];
}

const ParticipantDemographics: FC<ParticipantDemographicsProps> = ({
  values,
  errors,
  companyDemographics,
  userDemographics
}) => {
  const DemoQuestions = companyDemographics
    .filter(demo => demo.parentId === null)
    .sort((demo1, demo2) => demo1.companyDemographicId - demo2.companyDemographicId);

  return (
    <Group>
      <Heading>Organisation Demographic Questions</Heading>
      <FieldArray
        name="userDemographics"
        render={arrayHelper => {
          return DemoQuestions.map((data, index) => {
            return data.options.length > 0 ? (
              <React.Fragment key={index}>
                <br></br>
                <DarkBlueHeaderM>{data?.name || `Demographic ${index}`}</DarkBlueHeaderM>
                <br></br>
                <SelectDropdown
                  key={index}
                  error={errors.userDemographics && errors.userDemographics[index]}
                  data={DemoQuestions[index].options}
                  value={values.userDemographics[index]?.childDemographicId}
                  valueProp="companyDemographicId"
                  displayProp="name"
                  placeholder={`${data.name}`}
                  onChange={value =>
                    arrayHelper.replace(index, {
                      companyDemographicId: data.companyDemographicId,
                      userDemographicId: values.userDemographics[index]?.userDemographicId || null,
                      childDemographicId: value
                    })
                  }
                />
              </React.Fragment>
            ) : (
              <React.Fragment key={index}>
                <br></br>
                <DarkBlueHeaderM>{data?.name || `Demographic ${index}`}</DarkBlueHeaderM>
                <br></br>
                <Input
                  required
                  id={`userDemographics[index].childDemographicAnswer`}
                  name={`userDemographics[index].childDemographicAnswer`}
                  value={values.userDemographics[index]?.childDemographicAnswer || ''}
                  label={data.name}
                  error={null}
                  disabled={false}
                  onChange={e => {
                    arrayHelper.replace(index, {
                      ...userDemographics[index],
                      companyDemographicId: data.companyDemographicId,
                      childDemographicAnswer: e.currentTarget.value
                    });
                  }}
                  onBlur={() => null}
                />
              </React.Fragment>
            );
          });
        }}
      />
    </Group>
  );
};

export default ParticipantDemographics;
