import React, { FC } from 'react';
import { CSVLink } from 'react-csv';
import { Paper, AdminFormHeader } from '../../../components/UI';
import { DarkBlueBodyL, DarkBlueBodyS, RedHeaderS } from '../../../Themes';
import { ButtonWrapper, ReportIcon, FileData } from '../Styles';
import { Button } from '../../../components/UI';
import { reports } from '../../../assets/icons/index';

interface DownloadReportProps {
  onCancel: () => void;
  data: any;
}
const DownloadReport: FC<DownloadReportProps> = ({ onCancel, data }) => {
  const fileName = data.reportName + '_' + data.dateString + '(' + data.timezone + ')';
  const headers = data.headers;
  const rows = data.rows;

  const noData = rows === undefined || rows.length === 0;

  return (
    <>
      <AdminFormHeader title={'Download your report'} onClick={onCancel} backBtn={true} />
      <Paper width={'60%'}>
        <DarkBlueBodyS>
          {noData
            ? ''
            : 'Your report has been generated as a CSV file and is available for download below'}
        </DarkBlueBodyS>
        <ReportIcon src={reports} />
        <FileData>
          <DarkBlueBodyL>{data.reportName}</DarkBlueBodyL>
          <DarkBlueBodyS>{`${data.dateString} (${data.timezone})`}</DarkBlueBodyS>
        </FileData>
        {noData ? (
          <RedHeaderS>No data available</RedHeaderS>
        ) : (
          <ButtonWrapper>
            <Button variant={'admin-primary'}>
              <CSVLink headers={headers} data={rows} filename={fileName + '.csv'}>
                Download Report
              </CSVLink>
            </Button>
          </ButtonWrapper>
        )}
      </Paper>
    </>
  );
};

export default DownloadReport;

// headers = [
//   { label: "First Name", key: "firstname" },
//   { label: "Last Name", key: "lastname" },
//   { label: "Email", key: "email" }
// ];
