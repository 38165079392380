import styled from 'styled-components/macro';
import { Paper, Input, Button, RadioGroup, DatePicker, ImageViewer } from '../../../components/UI';

import { PostType } from '../../../lib/Types';
import { postTypeColour } from '../../../lib/Utils';
import { FlexRow } from '../../../Themes';

interface ImageProps {
  image: string;
}

export interface WritePostMediaButtonProps {
  disabled?: boolean;
}

export interface WritePostVideoRowProps {
  reverse?: boolean;
}

export interface WritePostImageRowProps {
  reverse?: boolean;
}

export const SendingToCard = styled(Paper)`
  max-width: calc(100% - 3% - 229px);
  width: 80%;
  @media (max-width: ${({ theme }) => theme.device.laptopL}px) {
    max-width: 100%;
  }
`;

export const SendingToHeader = styled.h4`
  font-style: italic;

  & > span {
    font-weight: normal;
    font-style: normal;
    display: block;
    margin-top: 1rem;
  }
`;

export const WritePostCard = styled(Paper)`
  max-width: calc(100% - 3% - 229px);
  width: 80%;

  @media (max-width: ${({ theme }) => theme.device.laptopL}px) {
    max-width: 100%;
  }
  display: flex;
  position: relative;
`;

export const WritePostIconContainer = styled.div`
  margin-right: ${({ theme }) => theme.padding}px;
`;

export const WritePostBody = styled.div`
  width: 80%;
`;

export const WritePostHeader = styled.h4<{ type: PostType }>`
  color: ${({ type, theme }) => postTypeColour(type, theme)};
  margin: 1.5rem 0 1rem;
`;

export const WritePostTitleInput = styled(Input)`
  margin-bottom: ${({ theme }) => theme.padding * 2}px !important;
`;

export const WritePostMessageInput = styled(Input)``;

export const WritePostLinkInput = styled(Input)``;

export const WritePostChallengeTextInput = styled(Input)``;

export const WritePostRewardWrapper = styled.div`
  margin-top: ${({ theme }) => theme.padding}px;
`;

export const WritePostChallengeTypeWrapper = styled.div`
  margin-top: ${({ theme }) => theme.padding}px;
`;

export const WritePostEventDayInput = styled(Input)`
  display: inline;
`;

export const WritePostEventDatePicker = styled(DatePicker)`
  width: 345px;
`;

export const WritePostMediaError = styled.span`
  color: ${({ theme }) => theme.status.danger};
`;

export const WritePostMedia = styled.div`
  margin-bottom: 1.5rem;
`;

export const WritePostOptions = styled.div`
  margin-bottom: ${({ theme }) => theme.padding * 1.5}px;
  align-items: flex-start;
`;

export const WritePostRadioGroup = styled(RadioGroup)`
  align-items: flex-start;
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: calc(0.8rem + 0.3vw) !important;
  > p {
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }
`;

export const WritePostSubmitArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const WritePostSubmitButton = styled(Button)`
  width: 20%;
  margin-left: ${({ theme }) => theme.padding}px;
`;

export const WritePostProfileImage = styled.div<ImageProps>`
  border-radius: 50%;
  position: relative;
  width: 7.8rem;
  height: 7.8rem;
  background: center / cover ${props => `url('${props.image}') no-repeat`};
`;

export const WritePostImage = styled(ImageViewer)`
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const WritePostMediaButton = styled.img<WritePostMediaButtonProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  margin-right: ${({ theme }) => theme.padding * 1.5}px;
`;

export const WritePostVideoContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.padding * 1.5}px;
  border: ${({ theme }) => theme.borders.input.focused};
  border-radius: 0.35rem;
  padding: 0.3rem 0.3rem 0.3rem 1.5rem;
  font-size: calc(${({ theme }) => theme.fontScale.sm} * 1.1);
  color: ${({ theme }) => theme.primary.dark};
`;

export const WritePostVideoIcon = styled.img`
  width: 5rem;
  height: 5rem;
  margin: ${({ theme }) => theme.padding}px;
`;

export const WritePostVideoRow = styled(FlexRow)<WritePostVideoRowProps>`
  justify-content: ${({ reverse }) => (reverse ? 'flex-end' : 'flex-start')};
`;

export const WritePostImageContainer = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.padding * 2}px;
`;

export const WritePostImageRow = styled(FlexRow)<WritePostImageRowProps>`
  position: absolute;
  height: auto;
  width: auto;
  top: ${({ theme }) => theme.padding}px;
  right: ${({ theme }) => theme.padding}px;
  justify-content: ${({ reverse }) => (reverse ? 'flex-end' : 'flex-start')};
`;

export const WritePostMediaControl = styled.div`
  height: 3rem;
  width: 3rem;
  border-radius: calc(3rem / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.padding}px;
  background-color: ${({ theme }) => theme.primary.dark};
  cursor: pointer;
`;

export const PinPostLabel = styled.h4`
  font-size: 92%;
  font-style: italic;
  margin: 1rem 0 0.5rem 0.2rem;
`;

export const PinnedDate = styled.div`
  width: 346px;
  margin: 0 0 5rem;
`;

export const SubText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: calc(0.8rem + 0.3vw);
`;
