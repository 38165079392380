import styled, { css } from 'styled-components/macro';
import Button from '../Button';
import theme from '../../../../theme';

const getVariantStyle = (variant: string | undefined): any => {
  switch (variant) {
    case 'primary':
      return css`
        color: #ffffff;
        background: ${theme.gradients.blue};
        border: none;
        :hover {
          transform: translateY(-1px) scale(1.03, 1.03);
        }
      `;
    case 'secondary':
      return css`
        color: #b9bbc0;
        background: #eff0f3;
        border: none;
      `;
    case 'remove':
      return css`
        color: #ffffff;
        background: ${theme.gradients.red};
        border: none;
        :hover {
          transform: translateY(-1px) scale(1.03, 1.03);
        }
      `;
    default:
      return css``;
  }
};

export const StyledButton = styled(Button)`
  box-shadow: none;
  border-radius: 25px;
  text-align: center;
  padding: 13px 0;
  text-transform: uppercase;
  width: 125px;
  font-size: 1.4rem;
  font-weight: 600;
  background: ${theme.gradients.blue};
  &:not(:first-child) {
    margin-left: 4px;
  }
  &:not(:last-child) {
    margin-right: 4px;
  }
  &:hover {
    transform: none;
  }
  ${({ variant = 'primary' }): any => getVariantStyle(variant)};
`;
