import React, { FC, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  TooltipProps,
  Tooltip as TooltipOverride
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { AxisInterval } from 'recharts/types/util/types';

import BarToolTip from './BarToolTip';
import LineToolTip from './LineToolTip';

import {
  mapData,
  renderCustomXAxisTick,
  renderCustomYAxisTick,
  isSameWeek,
  abbreviateNumber
} from './utils';
import useWindowSize from '../../../hooks/useWindowSize';

import { rightIconArrow, rightIconArrowDisabled } from '../../../assets/icons';
import {
  LineChartContainer,
  HeaderWrapper,
  ChartWrapper,
  TitleWrapper,
  Title,
  SubTitle,
  LeftArrowIcon,
  RightArrowIcon,
  ToggleButtonWrapper,
  ToggleButton,
  LegendWrapper,
  LegendKey,
  LegendText
} from './Styles';

import { ApplicationState } from '../../../lib/Store';

export type UserValue = {
  userId: string;
  value: number;
};

export type ChartData = {
  day: string; // YYYY-MM-DD
  teamValues: UserValue[]; // Including current user
  teamAverage?: number;
  personalAverage?: number;
};

export type DataSet = 'MY_ACTIVITY' | 'MY_TEAM';

export interface BarToolTipData {
  value: number;
  fullName: string;
  profilePictureURL: string;
}
export interface LineToolTipData {
  value: number;
}

const initialFromDate = moment().subtract(1, 'weeks');
const initialToDate = moment().add(1, 'weeks');

const DashboardStepsLineChart: FC = () => {
  const [windowWidth] = useWindowSize();

  const {
    userState: { userEvent, userData },
    statsState: { teamStats }
  } = useSelector((state: ApplicationState) => state);

  // Date range displayed on graph
  const [dateRange, setDateRange] = useState({
    fromDate: initialFromDate,
    toDate: initialToDate
  });
  // Disable date range being shifted where required
  const [disableDateShift, setDisableDateShift] = useState({
    back: initialFromDate.isSameOrBefore(moment(userEvent.startDate, 'x')),
    forward: initialToDate.isSameOrAfter(moment(userEvent.endDate, 'x'))
  });
  // Toggle between personal and team data
  const [dataSet, setDataSet] = useState<DataSet>('MY_ACTIVITY');
  // Set in Bar chart on hover event, content for custom tooltip
  const [toolTipData, setToolTipData] = useState<BarToolTipData | null>(null);
  // Toggle show line chart tooltip, unlike bar tooltip, data is handled by tooltip function
  const [showLineToolTip, setShowLineToolTip] = useState(false);
  // Number of intervals on graph
  const [intervals, setIntervals] = useState<AxisInterval>(0);

  // Update date range and disables if appropriate
  const shiftDatesBack = (i: number) => {
    if (disableDateShift.back) return;
    const newFromDate = dateRange.fromDate.subtract(i, 'weeks');
    setDateRange({
      fromDate: newFromDate,
      toDate: dateRange.toDate.subtract(i, 'weeks')
    });
    const disableBack = newFromDate.isSameOrBefore(moment(userEvent.startDate, 'x'));
    setDisableDateShift({ forward: false, back: disableBack }); // reset opposite disable
  };

  // Update date range and disables if appropriate
  const shiftDatesForward = (i: number) => {
    if (disableDateShift.forward) return;
    const newToDate = dateRange.toDate.add(i, 'weeks');
    setDateRange({
      fromDate: dateRange.fromDate.add(i, 'weeks'),
      toDate: newToDate
    });
    const disableForward = newToDate.isSameOrAfter(moment(userEvent.endDate, 'x'));
    setDisableDateShift({ forward: disableForward, back: false }); // reset opposite disable
  };

  const data: ChartData[] = useMemo(
    () => mapData(userData, teamStats, dateRange.fromDate, dateRange.toDate),
    [userData, teamStats, dateRange]
  );

  const myData = useMemo(
    () =>
      data.map(item => {
        const filterTeamValues = item.teamValues.filter(
          teamValue => teamValue.userId === userData.userId
        );
        return {
          ...item,
          // Set to 0 to ensure team average values dont mess with personal data chart
          teamAverage: 0,
          teamValues: filterTeamValues
        };
      }),
    [data]
  );

  // On window resize, update number of intervals displayed on chart.
  useEffect(() => {
    if (windowWidth <= 440) {
      setIntervals('preserveStartEnd');
    } else if (windowWidth <= 880) {
      setIntervals(1);
    } else {
      setIntervals(0);
    }
  }, [windowWidth]);

  // Handle both mouse enter + leave events for individually rendered bars.
  const updateToolTipData = (data: any | null) => {
    if (!data) {
      setToolTipData(null);
      return;
    }
    const { tooltipPayload } = data;
    if (tooltipPayload) {
      const { name: userId } = tooltipPayload[0];
      const { value } = data;
      const teamMember = teamStats.find(member => member.userId === userId);
      if (teamMember && value !== 0) {
        setToolTipData({
          fullName: teamMember.name,
          profilePictureURL: teamMember.profileImage,
          value
        });
      }
    }
  };

  // Helper function to render tooltip instance
  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    let linePayload: number;
    if (!payload) return <></>;
    payload.forEach(graph => {
      if (graph.name === 'average') {
        const { payload } = graph;
        linePayload = dataSet === 'MY_ACTIVITY' ? payload.personalAverage : payload.teamAverage;
      }
    });
    if ((linePayload || linePayload === 0) && showLineToolTip) {
      return <LineToolTip payload={{ value: linePayload }} />;
    }
    return (
      active && payload && payload.length && toolTipData && <BarToolTip payload={toolTipData} />
    );
  };

  // Helper function to iterate data sets and render bars depending on data set.
  const renderBars = () => {
    const dataKeyAccessor = (e: Partial<ChartData>, uid: string) => {
      const keyRef = e.teamValues.find(entry => entry.userId === uid);
      return keyRef ? keyRef.value : null;
    };
    if (dataSet === 'MY_ACTIVITY') {
      return (
        <Bar
          dataKey={e => dataKeyAccessor(e, userData.userId)}
          name={userData.userId}
          barSize={10}
          isAnimationActive
          onMouseOver={updateToolTipData}
          onMouseLeave={() => updateToolTipData(null)}
        >
          {data &&
            data.map((entry, index) => {
              const gradientName = isSameWeek(moment(entry.day), moment(), 0)
                ? 'thisWeekGradient'
                : 'otherWeekGradient';
              return <Cell key={`cell-${index}`} fill={`url(#${gradientName})`} radius={14} />;
            })}
        </Bar>
      );
    } else
      return teamStats.map((teamMember, t) => {
        const userId = teamMember.userId;
        return (
          <Bar
            key={teamMember.userId}
            name={teamMember.userId}
            dataKey={e => dataKeyAccessor(e, userId)}
            barSize={5}
            isAnimationActive
            onMouseOver={updateToolTipData}
            onMouseLeave={() => updateToolTipData(null)}
          >
            {data &&
              data.map((_entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={`url(#teamMateGradient${t % 5})`} // Cycle through 'teamMateGradientX'
                    radius={14}
                  />
                );
              })}
          </Bar>
        );
      });
  };

  return (
    <LineChartContainer>
      <HeaderWrapper>
        <TitleWrapper>
          <Title>{dataSet === 'MY_ACTIVITY' ? 'My Activity' : 'My Team'}</Title>
          <SubTitle>Steps over the last 2 weeks</SubTitle>
        </TitleWrapper>
        <ToggleButtonWrapper>
          <ToggleButton
            isLeft
            isActive={dataSet === 'MY_ACTIVITY'}
            onClick={() => setDataSet('MY_ACTIVITY')}
          >
            My Activity
          </ToggleButton>
          <ToggleButton isActive={dataSet === 'MY_TEAM'} onClick={() => setDataSet('MY_TEAM')}>
            My Team
          </ToggleButton>
        </ToggleButtonWrapper>
        <LegendWrapper>
          <LegendKey isPersonal={dataSet === 'MY_ACTIVITY'} />
          <LegendText isPersonal={dataSet === 'MY_ACTIVITY'}>
            {dataSet === 'MY_ACTIVITY' ? 'Personal Average' : 'Team Average'}
          </LegendText>
        </LegendWrapper>
      </HeaderWrapper>
      <LeftArrowIcon
        src={disableDateShift.back ? rightIconArrowDisabled : rightIconArrow}
        onClick={() => shiftDatesBack(1)}
        disabled={disableDateShift.back}
      />
      <RightArrowIcon
        src={disableDateShift.forward ? rightIconArrowDisabled : rightIconArrow}
        onClick={() => shiftDatesForward(1)}
        disabled={disableDateShift.forward}
      />
      <ChartWrapper>
        <ResponsiveContainer width="95%" height="90%">
          <ComposedChart width={1000} height={300} data={data}>
            <CartesianGrid vertical={false} height={2} />
            <XAxis
              dataKey="day"
              tick={renderCustomXAxisTick}
              interval={intervals}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              ticks={renderCustomYAxisTick(dataSet === 'MY_ACTIVITY' ? myData : data)}
              tickFormatter={abbreviateNumber}
              style={{
                fontSize: '12px',
                fontFamily: 'Sarabun',
                fill: '#AEAEAE',
                fontWeight: 500
              }}
              domain={[0, 5]}
            />

            <TooltipOverride content={<CustomTooltip />} cursor={false} />
            <defs>
              {/** Define our gradients for bars */}
              <linearGradient id="otherWeekGradient" gradientTransform="rotate(90)">
                <stop offset="0%" stopColor="#57D9D1" />
                <stop offset="100%" stopColor="#DCE6A2" />
              </linearGradient>
              <linearGradient id="thisWeekGradient" gradientTransform="rotate(180)">
                <stop offset="0%" stopColor="#75DBFF" />
                <stop offset="100%" stopColor="#A9A8FF" />
              </linearGradient>
              <linearGradient id="teamMateGradient0" gradientTransform="rotate(90)">
                <stop offset="0%" stopColor="#57D9D1" />
                <stop offset="100%" stopColor="#DCE6A2" />
              </linearGradient>
              <linearGradient id="teamMateGradient1" gradientTransform="rotate(90)">
                <stop offset="0%" stopColor="#FF7D8F" />
                <stop offset="100%" stopColor="#DCE6A2" />
              </linearGradient>
              <linearGradient id="teamMateGradient2" gradientTransform="rotate(90)">
                <stop offset="0%" stopColor="#A88DFF" />
                <stop offset="100%" stopColor="#DCE6A2" />
              </linearGradient>
              <linearGradient id="teamMateGradient3" gradientTransform="rotate(90)">
                <stop offset="0%" stopColor="#8DC4FF" />
                <stop offset="100%" stopColor="#DCE6A2" />
              </linearGradient>
              <linearGradient id="teamMateGradient4" gradientTransform="rotate(90)">
                <stop offset="0%" stopColor="#E9EB75" />
                <stop offset="100%" stopColor="#DCE6A2" />
              </linearGradient>
            </defs>
            {renderBars()}
            <Line
              type="monotone"
              dataKey={dataSet === 'MY_ACTIVITY' ? 'personalAverage' : 'teamAverage'}
              stroke={dataSet === 'MY_ACTIVITY' ? '#A9A8FF' : '#FF5E86'}
              legendType="none"
              strokeWidth="2"
              dot={false}
              activeDot={false}
              isAnimationActive
              name="average"
              onMouseOver={() => setShowLineToolTip(true)}
              onMouseLeave={() => setShowLineToolTip(false)}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </LineChartContainer>
  );
};

export default DashboardStepsLineChart;
