import { CompanyEventStatus, EventLinkingInfo, Event, PagedResponse } from '../../../../Types';
import {
  SetCurrentEventAction,
  SetEventLinkingInfoAction,
  SetEventLinkingLoadingAction,
  SetCurrentEventLoadingAction,
  SetPastEventsAction,
  SetPastEventsLoadingAction,
  SET_CURRENT_EVENT,
  SET_EVENT_LINKING_INFO,
  SET_EVENT_LINKING_LOADING,
  SET_CURRENT_EVENT_LOADING,
  SET_PAST_EVENTS,
  SET_PAST_EVENTS_LOADING
} from './types';

export const setCurrentEvent = (currentEvent: CompanyEventStatus): SetCurrentEventAction => ({
  type: SET_CURRENT_EVENT,
  currentEvent
});

export const setPastEvents = (pastEvents: PagedResponse<Event>): SetPastEventsAction => ({
  type: SET_PAST_EVENTS,
  pastEvents
});

export const setCurrentEventLoading = (loading: boolean): SetCurrentEventLoadingAction => ({
  type: SET_CURRENT_EVENT_LOADING,
  loading
});

export const setEventLinkingInfo = (payload: EventLinkingInfo): SetEventLinkingInfoAction => ({
  type: SET_EVENT_LINKING_INFO,
  payload
});

export const setEventLinkingLoading = (loading: boolean): SetEventLinkingLoadingAction => ({
  type: SET_EVENT_LINKING_LOADING,
  loading
});

export const setPastEventsLoading = (loading: boolean): SetPastEventsLoadingAction => ({
  type: SET_PAST_EVENTS_LOADING,
  loading
});

export default {
  setCurrentEvent,
  setPastEvents,
  setEventLinkingInfo,
  setEventLinkingLoading,
  setPastEventsLoading
};
