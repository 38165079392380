import {
  UserState,
  UserActionTypes,
  SET_USER,
  SET_USER_COMPANY_EVENT_ID,
  UPDATE_USER,
  REMOVE_USER,
  SET_COMPANY,
  SET_EVENT,
  SET_EVENT_ACTIVITY_TYPES,
  SET_EVENT_ACTIVITIES,
  ADD_EVENT_ACTIVITY,
  EDIT_EVENT_ACTIVITY,
  DELETE_EVENT_ACTIVITY,
  SET_REWARDS,
  SET_DEVICES,
  SET_REWARDS_TO_COMPLETE
} from './types';
import { Reducer } from 'redux';

const initialState: UserState = {
  userData: {
    userId: null,
    companyId: null,
    roleId: null,
    email: '',
    firstName: '',
    lastName: '',
    sdaExemption: false,
    active: false,
    termsAccepted: false
  },
  userCompany: {
    companyId: null,
    name: '',
    createdAt: null,
    updatedAt: null,
    active: false,
    profileImage: '',
    passCode: '',
    admins: [],
    allowManualPayment: false
  },
  userEvent: {
    eventId: null,
    name: '',
    companies: [],
    timezone: '',
    registrationsStartDate: null,
    registrationsEndDate: null,
    startDate: null,
    endDate: null,
    gracePeriod: 7,
    stepApprovalThreshold: 0,
    stepApproval: true,
    averageSteps: 0,
    teamSize: 4,
    active: false,
    costPerParticipant: 0,
    paidParticipants: 0,
    passCode: '',
    activityTypes: [],
    favoriteTypes: [],
    activities: [],
    finalDateLive: '',
    maxParticipants: NaN,
    status: null,
    greeting: '',
    registrationCount: NaN,
    companyDemographics: []
  },
  userRewards: [],
  rewardsToComplete: [],
  userDevices: [],
  userCompanyEventId: null
};

export const userReducer: Reducer<UserState, UserActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, userData: action.user };
    case SET_USER_COMPANY_EVENT_ID:
      return { ...state, userCompanyEventId: action.userCompanyEventId };
    case UPDATE_USER:
      return { ...state, userData: { ...state.userData, ...action.userUpdates } };
    case SET_COMPANY:
      return { ...state, userCompany: action.company };
    case SET_EVENT:
      return { ...state, userEvent: { ...state.userEvent, ...action.event } };
    case SET_REWARDS:
      return { ...state, userRewards: action.rewards };
    case SET_REWARDS_TO_COMPLETE:
      return { ...state, rewardsToComplete: action.rewards };
    case SET_DEVICES:
      return { ...state, userDevices: action.devices };
    case SET_EVENT_ACTIVITY_TYPES:
      return {
        ...state,
        userEvent: {
          ...state.userEvent,
          activityTypes: action.allTypes,
          favoriteTypes: action.favoriteTypes
        }
      };
    case SET_EVENT_ACTIVITIES:
      return { ...state, userEvent: { ...state.userEvent, activities: action.activities } };
    case ADD_EVENT_ACTIVITY:
      return {
        ...state,
        userEvent: {
          ...state.userEvent,
          activities: state.userEvent.activities.concat(action.activity)
        }
      };
    case EDIT_EVENT_ACTIVITY:
      return {
        ...state,
        userEvent: {
          ...state.userEvent,
          activities: state.userEvent.activities.map(activity =>
            activity.activityId === action.activity.activityId ? action.activity : activity
          )
        }
      };
    case DELETE_EVENT_ACTIVITY:
      return {
        ...state,
        userEvent: {
          ...state.userEvent,
          activities: state.userEvent.activities.filter(
            activity => activity.activityId !== action.activityId
          )
        }
      };
    case REMOVE_USER:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
