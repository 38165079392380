import { SDAActionTypes, SET_SDA, SET_SDA_FILTER, SET_PAGED_SDA_LIST } from './types';
import { PagedResponse, SDAFilter, SuspectDailyActivity } from '../../../Types';

export const setPagedSDAList = (
  pagedSDAList: PagedResponse<SuspectDailyActivity>
): SDAActionTypes => ({
  type: SET_PAGED_SDA_LIST,
  pagedSDAList
});

export const setSDA = (SDA: SuspectDailyActivity): SDAActionTypes => ({
  type: SET_SDA,
  SDA
});

export const setSDAFilter = (filter: SDAFilter): SDAActionTypes => ({
  type: SET_SDA_FILTER,
  filter
});
