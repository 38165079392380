import styled from 'styled-components/macro';
import { Paper } from '../../components/UI';

interface CommentFormWrapper {
  hidden: boolean;
}

interface FeedProps {
  offsetHeight?: number;
  commentFormHidden?: boolean;
}

export const PostFeedWrapper = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
  width: 80%;
  margin-right: 4rem;

  .title {
    color: #5fe2ff;
    text-transform: uppercase;
  }

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    margin: 5rem 0 0;
    padding-left: 0;
    width: 100%;
  }
`;

export const Feed = styled.div<FeedProps>`
  // Show shadow in overflow
  margin: 0 -1rem;
  padding: 0 1rem;
  overflow-y: scroll;
  height: ${({ commentFormHidden, offsetHeight }) =>
    !commentFormHidden && offsetHeight
      ? `calc(1204px - 1rem - ${offsetHeight}px)`
      : 'calc(1204px - 1rem)'};
`;

export const CommentFormWrapper = styled(Paper)<CommentFormWrapper>`
  display: ${({ hidden }) => (hidden ? 'none' : 'auto')};
  @media (max-width: ${({ theme }) => theme.device.laptopL}px) {
    max-width: 100%;
  }
`;

export const WaterCoolerTitle = styled.h3`
  color: #366696;
  font-size: ${({ theme }) => theme.fontScale.lg};
  font-style: italic;
  margin-bottom: 1.5rem;

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    margin: 0 0 1.5rem 1.5rem;
    font-size: calc(1.2em + 1vw);
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActionBar = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const CreatePostButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  width: fit-content;
  height: 42px;
  box-shadow: 1px 1px 12px 0px rgb(0 0 0 / 10%);
  border-radius: 50px;
  margin-left: auto;
  transition: all, 0.1s linear;

  cursor: pointer;

  :hover {
    transform: scale(1.075);
  }

  @media (max-width: 580px) {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.device.tablet + 1}px) and (max-width: 875px) {
    font-size: ${({ theme }) => theme.fontScale.sm};
    padding: 0.5rem;
    width: 70px;
  }
`;

export const CreatePostIcon = styled.img`
  width: 27px;
  height: 21px;
  border: 1px solid #00bdff;
  border-radius: 50px;
  padding: 0.5rem;
`;

export const CreatePostText = styled.div`
  color: #00bdff;
  font-size: calc(0.5em + 0.4vw);
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  border-radius: 21px;
  margin-left: 1rem;
  text-transform: capitalize;

  @media (min-width: ${({ theme }) => theme.device.tablet + 1}px) and (max-width: 875px) {
    display: none;
  }
`;

export const MobileCreatePostButton = styled.div`
  position: absolute;
  right: 1rem;
  transform: translate(0, -0.75rem);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 74px;
  height: 42px;
  box-shadow: 1px 1px 12px 0px rgb(0 0 0 / 10%);
  border-radius: 50px;
  margin-left: auto;

  cursor: pointer;

  @media (min-width: 581px) {
    display: none;
  }
`;
