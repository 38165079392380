import React, { FC } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import AddTeamMateModal from './AddTeamMateModal';
import validationSchema, {
  AddOwnTeamMateSchema
} from '../../../../../lib/validators/addOwnTeamMateSchema';
import { ApplicationState } from '../../../../../lib/Store';
import { addTeamMate } from '../../../../../lib/Api/Team';

interface AddTeamMateProps {
  loading: boolean;
  addOwnTeamMate: (data: AddOwnTeamMateSchema) => any;
  cancel: () => void;
}

interface AddTeamMateState {
  firstName: string;
  lastName: string;
  email: string;
}

const formikEnhancer = withFormik<AddTeamMateProps, AddTeamMateState>({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    email: ''
  }),
  validationSchema,
  validateOnChange: false,
  handleSubmit: async (data: AddOwnTeamMateSchema, { props, setErrors }): Promise<void> => {
    const { error } = await props.addOwnTeamMate(data);
    // TODO: Provide user feedback if there is request failures
    //if (error.name === 'ConflictError') setFieldError('request', 'This user is already in a team');
    if (error) setErrors({ [error.context]: error.message });
    else props.cancel();
  }
});

const AddTeamMate: FC<AddTeamMateProps & FormikProps<AddTeamMateState>> = ({
  cancel,
  loading,
  ...props
}) => {
  return <AddTeamMateModal {...props} loading={loading} cancel={cancel} />;
};

const mapStateToProps = ({ loadingState }: ApplicationState) => ({
  loading: loadingState.apiCallsInProgress > 0
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addOwnTeamMate: (data: AddOwnTeamMateSchema) => addTeamMate(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(formikEnhancer(AddTeamMate));
