import styled from 'styled-components/macro';

export const Image = styled.img<{
  borderRadius?: string;
  verticalAlign?: string;
  actionButton?: boolean;
  margin?: string;
  color?: string;
  size?: string;
  width?: string;
}>`
  border-radius: ${({ borderRadius }) => (borderRadius === '0%' ? '0%' : `${borderRadius}`)};
  vertical-align: ${({ verticalAlign }) => (verticalAlign ? verticalAlign : ``)};
  ${({ actionButton }) => (actionButton ? `cursor: pointer;` : '')};
  margin: ${({ margin }) => (margin ? margin : `0 0.8rem 0 0`)};
  color: ${({ color }) => (color ? color : `#658CD7`)};
  height: ${({ size }) => (size ? size : `1.5rem`)};
  width: ${({ width }) => (width ? width : `auto`)};
`;
