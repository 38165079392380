import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import Input from '../Input';
import Button from '../Button';

import { Container, Header, InputGroup } from './Styles';

import { setNotification } from '../../../lib/Store/contexts/notification/actions';

interface InputCopyProps {
  title?: string;
  copyLabel?: string;
  flexValues?: number[];
  inputValue: string;
}

const InputCopy: FC<InputCopyProps> = ({
  inputValue,
  title,
  copyLabel = 'Copy',
  flexValues = [2, 1]
}) => {
  const dispatch = useDispatch();

  const _copyText = (textToCopy: string) => {
    if (!textToCopy)
      return dispatch(
        setNotification({
          message: 'There was an error copying the text. Please try again later.',
          variant: 'warning'
        })
      );

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => dispatch(setNotification({ message: 'Text copied!' })));
  };

  return (
    <Container>
      {title && <Header>{title}</Header>}
      {/* <InputGrid> */}
      <InputGroup>
        <Input
          id={`${title}-copyInput`}
          value={inputValue}
          flex={flexValues[0]}
          hideLabel
          readonly
          label=""
          onChange={() => {
            return;
          }}
        />
        <Button
          buttonFlex={flexValues[1]}
          variant="primary"
          margin="0 0 0 1rem"
          height="4.35rem"
          lineHeight="0.6rem"
          padding="0"
          onClick={() => _copyText(inputValue)}
        >
          {copyLabel}
        </Button>
      </InputGroup>
      {/* </InputGrid> */}
    </Container>
  );
};

export default InputCopy;
