import styled from 'styled-components/macro';
import { StyledPageHd } from '../../Themes';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 5rem 0 0;
`;

export const Title = styled(StyledPageHd)`
  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    margin: 0 0 1.5rem 1.5rem;
    font-size: calc(1.2em + 1vw);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.padding}px;
`;

export const InputGroup = styled.div`
  padding: ${({ theme }) => theme.padding}px 0;
  width: 100%;
`;
