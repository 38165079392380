import React, { FC, ChangeEvent, Ref } from 'react';
import { Moment } from 'moment';

import { checkPink } from '../../../assets/icons';
import { Container, Marker, Wrapper, Day, Month, Today, Icon, Radio, Label } from './Styles';

interface DayBoxProps {
  date: Moment;
  today: Moment;
  checked?: boolean;
  onChange?: (e: ChangeEvent<any>) => void;
  selected?: boolean;
  todayRef?: Ref<HTMLDivElement>;
}

const DayBox: FC<DayBoxProps> = ({ date, today, checked, selected, onChange, todayRef }) => {
  let StatusValue = <>&nbsp;</>;
  if (checked) StatusValue = <Icon src={checkPink} />;
  else if (date.isSame(today, 'd')) StatusValue = <>Today</>;

  const id = `DayBox-${date.format('YYYY-MM-DD')}-${Math.random() * 1000}`;
  const future = date.isAfter(today);
  return (
    <Container ref={todayRef}>
      <Wrapper future={future} complete={checked}>
        <Radio
          onChange={onChange}
          id={id}
          type="radio"
          checked={selected}
          name="completionDate"
          disabled={date.isAfter(today, 'd')}
          value={date.format('YYYY-MM-DD')}
          future={future}
        />
        <Label htmlFor={id} future={future} complete={checked}>
          <Day>{date.format('D')}</Day>
          <Month>{date.format('MMM')}</Month>
          <Today>{StatusValue}</Today>
        </Label>
      </Wrapper>
      <Marker checked={selected} />
    </Container>
  );
};

export default DayBox;
