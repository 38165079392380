import { Reducer } from 'redux';
import {
  PostsState,
  PostsActionTypes,
  SET_POSTS,
  UPDATE_POSTS_FILTER,
  ADD_POSTS,
  ADD_POST,
  SET_ALL_LOADED,
  UPDATE_POST,
  SET_MINI_CHALLENGES,
  UPDATE_MINI_CHALLENGE,
  SET_ALL_REPLIES_LOADED,
  UPDATE_REPLIES_FILTER,
  ExtendedPostsFilter
} from './types';
import { uniquePosts } from './utils';

export const initialPostsStateFilter: ExtendedPostsFilter = {
  page: 1,
  rowsPerPage: 10,
  orderColumn: 'date',
  orderDirection: 'DESC',
  primaryPostTypeFilter: 'GENERAL_ACTIVITIES',
  secondaryPostTypeFilter: 'ALL'
};

export const initialPostsState: PostsState = {
  posts: [],
  filter: initialPostsStateFilter,
  repliesFilter: {
    page: 1,
    rowsPerPage: 10,
    orderColumn: 'date',
    orderDirection: 'DESC'
  },
  allLoaded: false,
  allRepliesLoaded: false,
  miniChallenges: []
};

const PostsReducer: Reducer<PostsState, PostsActionTypes> = (state = initialPostsState, action) => {
  switch (action.type) {
    case SET_POSTS:
      return { ...state, posts: uniquePosts(action.posts) };
    case ADD_POST:
      return { ...state, posts: uniquePosts([...state.posts, action.post]) };
    case UPDATE_POST:
      const { postId, ...postData } = action.postData;
      return {
        ...state,
        posts: state.posts.map(post => (post.postId === postId ? { ...post, ...postData } : post))
      };
    case ADD_POSTS:
      return { ...state, posts: uniquePosts([...state.posts, ...action.posts]) };
    case UPDATE_POSTS_FILTER:
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case UPDATE_REPLIES_FILTER:
      return { ...state, repliesFilter: { ...state.repliesFilter, ...action.repliesFilter } };
    case SET_ALL_LOADED:
      return { ...state, allLoaded: action.allLoaded };
    case SET_ALL_REPLIES_LOADED:
      return { ...state, allRepliesLoaded: action.allRepliesLoaded };
    case SET_MINI_CHALLENGES:
      return { ...state, miniChallenges: action.miniChallenges };
    case UPDATE_MINI_CHALLENGE:
      const miniChallengeId = action.miniChallengeData.postId;
      const miniChallengeData = action.miniChallengeData;
      return {
        ...state,
        miniChallenges: state.miniChallenges.map(miniChallenge =>
          miniChallenge.postId === miniChallengeId
            ? { ...miniChallenge, ...miniChallengeData }
            : miniChallenge
        )
      };
    default:
      return state;
  }
};

export default PostsReducer;
