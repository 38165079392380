import styled from 'styled-components/macro';
import { Button } from '../../../../components/UI/index';

interface ImageProps {
  image: string;
}

export const Section = styled.div`
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 4rem;

  &:first-child {
    margin-left: 0;
  }

  @media (max-width: ${({ theme }) => theme.device.laptop + 120}px) {
    margin-left: 0;
  }
`;

export const SectionHeading = styled.h3`
  margin-top: ${({ theme }) => theme.padding * 2}px;
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.6rem;
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.padding * 2}px;
`;

export const TeamHeading = styled.h4`
  color: ${({ theme }) => theme.primary.light};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: 1.4rem;
  margin-bottom: 0.2rem;
`;

export const TeamInfo = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 30rem;
`;

export const PhotoSection = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const BodyText = styled.p`
  font-size: ${({ theme }) => theme.fonts.body}rem;
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin: 0 0 ${({ theme }) => theme.padding * 3}px 0;
  line-height: 2.5rem;
`;

export const TeamCapNote = styled.p`
  font-style: italic;
  color: ${({ theme }) => theme.primary.dark};
  font-size: 1.3rem;
  margin-top: -1rem;
  margin-bottom: 2rem;
`;

export const Field = styled.div`
  margin-bottom: 2.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.padding}px;
  @media (max-width: ${({ theme }) => theme.device.laptop + 120}px) {
    flex-direction: column;
  }
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.padding}px;
  @media (max-width: ${({ theme }) => theme.device.laptop + 120}px) {
    justify-content: center;
  }
`;

export const Form = styled.form`
  position: relative;
`;

export const UploadButton = styled(Button)`
  margin-top: ${({ theme }) => theme.padding}px;
  max-width: 12rem;
  border: 1px #75dbff solid;
  color: #658cd7;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Cancel = styled(Button)`
  margin-top: ${({ theme }) => theme.padding}px;
  width: 6rem;
  min-width: 14rem;
  border: none;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  opacity: 0.7;
`;

export const Submit = styled(Button)`
  margin-top: ${({ theme }) => theme.padding}px;
  width: 6rem;
  min-width: 14rem;
  border: none;
  color: #658cd7;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  text-transform: uppercase;
  background-image: linear-gradient(
    90deg,
    rgba(117, 219, 255, 0.4) 0%,
    rgba(169, 168, 255, 0.4) 100%
  );
`;

export const LoaderContainer = styled.div`
  height: 10rem;
  width: 10rem;
  margin-right: ${({ theme }) => theme.padding * 2}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PictureInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PicturePlaceholder = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  cursor: pointer;
  border: dashed 1px rgba(101, 140, 215, 0.5);
  background: #ffffff;
  display: inline-block;
  position: relative;
`;

export const PictureHelper = styled.div`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`;

export const PictureText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 17px;
`;

export const PictureTitle = styled.h3`
  color: #366696;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.13125px;
`;

export const PictureDescription = styled.p`
  opacity: 0.5;
  color: #366696;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.1125px;
`;

export const PictureDefault = styled.img`
  width: 45%;
  height: 45%;
  text-align: center;
  vertical-align: middle;
  object-fit: cover;
  margin-left: 29%;
  margin-top: 35%;
`;

export const PictureWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
`;

export const EditIcon = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  border-radius: 100%;
  padding: 5px;
  border: solid 1px rgba(101, 140, 215, 0.5);
`;

export const Picture = styled.img`
  text-align: center;
  vertical-align: middle;
  width: 98px;
  height: 98px;
  object-fit: cover;
  border-radius: 100%;
  background: white;
`;
