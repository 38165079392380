import React, { FC, useState, ChangeEvent, FocusEvent } from 'react';
import { Input } from '../../UI';
import { eye, eyeAlt } from '../../../assets/icons';

interface PasswordInputProps {
  id: string;
  name?: string;
  label: string;
  valid?: boolean;
  error?: any;
  required?: boolean;
  value: string | number | undefined;
  disabled?: boolean;
  onChange: (e: ChangeEvent<any>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

const PasswordInput: FC<PasswordInputProps> = props => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <Input
        {...props}
        type={showPassword ? 'text' : 'password'}
        icon={showPassword ? eye : eyeAlt}
        onClickIcon={toggleShowPassword}
      />
    </>
  );
};

export default PasswordInput;
