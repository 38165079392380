import styled, { keyframes } from 'styled-components/macro';
import { closeBlue } from '../../../assets/icons/index';

interface ModalWrapperProps {
  width?: number;
  tabletWidth?: number;
  top?: number;
  left?: number;
  tabletLeft?: number;
  alpha?: number;
  padding?: string;
  headerPadding?: string;
  titlePadding?: string;
  minWidth?: string;
  overflowVisible?: boolean;
}

const moveInBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%)
  }

  to {
    opacity: 1;
  }
`;

const fadeToBlack = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div<ModalWrapperProps>`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  ${props => `background-color: rgba(0, 0, 0, ${props.alpha});`};
  z-index: 105;
  position: fixed;
  animation: ${fadeToBlack} 0.2s;
  // min-width: ${({ minWidth }) => (minWidth ? minWidth : '400px')};
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div<ModalWrapperProps>`
  // width: ${props => props.width}%;
  position: absolute;
  background-color: white;
  padding: ${props => (props.padding ? props.padding : '40px 40px 120px 88px')};
  box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  animation: ${moveInBottom} 0.3s;
  // min-width: ${({ minWidth }) => (minWidth ? minWidth : '400px')};
  overflow: ${({ overflowVisible }) => (overflowVisible ? 'visible' : 'scroll')};
  position: relative;
`;

export const ModalHeader = styled.div<ModalWrapperProps>`
  position: relative;
  ${props => (props.headerPadding ? props.headerPadding : '40px 0 0 0')};
`;

export const ModalTitle = styled.h2<ModalWrapperProps>`
  font-family: ${({ theme }) => theme.fontFamily.heading}px;
  font-size: 24px;
  color: #366696;
  letter-spacing: -0.06px;
  line-height: 28px;
  padding: ${({ titlePadding }) => (titlePadding ? titlePadding : '15px 0')};
  font-style: italic;
`;

export const Icon = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  left: auto;
`;

export const ModalFooter = styled.div`
  text-align: right;
  padding-top: 24px;
`;

export const CloseButtonContainer = styled.div`
  text-align: right;
  position: absolute;
  right: 40px;
  top: 25px;
  cursor: pointer;
  z-index: 10;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  margin: 0;
  background-image: url(${closeBlue});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  width: 16px;
  border: none;
  background-color: #ffffff;
  height: 25px;
`;
