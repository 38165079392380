import styled from 'styled-components/macro';

export const ImageContainer = styled.div<{ height: string }>`
  width: 100%;
  position: relative;
  height: ${({ height = 'auto' }) => height};
  border-radius: 2rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.padding}px;
  background-color: ${({ theme }) => theme.backgrounds.white};
`;
