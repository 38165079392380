import { RoleCode } from 'src/lib/Types';
import {
  connections,
  users,
  mail,
  cog,
  logout,
  hash,
  dashboard,
  invoices,
  bargraph,
  radar,
  team,
  reports,
  trophyIcon,
  calendarIcon
} from '../../../assets/icons/index';

// Menu Options for Sidebar

export interface MenuOption {
  label: string;
  icon: string;
  path: string;
  paths: { label: string; path: string }[];
  requiresOneOfRole: RoleCode[];
}

export const Dashboard: MenuOption = {
  label: 'Dashboard',
  icon: dashboard,
  path: '/Dashboard',
  paths: [],
  requiresOneOfRole: [RoleCode.Employee, RoleCode.CompanyAdmin, RoleCode.MasterAdmin]
};

export const CompanyAdmins: MenuOption = {
  label: 'Company Admins',
  icon: users,
  path: '/CompanyAdmins',
  paths: [],
  requiresOneOfRole: [RoleCode.CompanyAdmin]
};

export const EventRegistration: MenuOption = {
  label: 'Event Registration',
  icon: dashboard,
  path: '/EventRegistrationDashboard',
  paths: [],
  requiresOneOfRole: [RoleCode.CompanyAdmin]
};

export const EventPayments: MenuOption = {
  label: 'Invoices',
  icon: invoices,
  path: '/Invoices',
  paths: [],
  requiresOneOfRole: [RoleCode.CompanyAdmin]
};

export const Management: MenuOption = {
  label: 'Management',
  icon: connections,
  path: '',
  paths: [
    { label: 'Companies', path: '/Companies' },
    { label: 'Events', path: '/Events' }
  ],
  requiresOneOfRole: [RoleCode.MasterAdmin]
};

export const Teams: MenuOption = {
  label: 'Teams & Participants',
  icon: users,
  path: '',
  paths: [
    { label: 'Participants', path: '/Participants' },
    { label: 'Teams', path: '/Teams' },
    { label: 'Without Teams', path: '/Participants/WithoutTeams' },
    { label: 'Not Registered', path: '/Participants/NotRegistered' }
  ],
  requiresOneOfRole: [RoleCode.CompanyAdmin, RoleCode.MasterAdmin]
};

export const CAPosts: MenuOption = {
  label: 'Posts',
  icon: mail,
  path: '',
  paths: [{ label: 'Create a Post', path: '/CreatePost' }],
  requiresOneOfRole: [RoleCode.CompanyAdmin]
};

export const SAPosts: MenuOption = {
  label: 'Posts',
  icon: mail,
  path: '',
  paths: [
    { label: 'Create a Post', path: '/CreatePost' },
    { label: 'Rewards', path: '/Rewards' }
  ],
  requiresOneOfRole: [RoleCode.MasterAdmin]
};

export const Reports: MenuOption = {
  label: 'Reports',
  icon: reports,
  path: '/Reports',
  paths: [],
  requiresOneOfRole: [RoleCode.MasterAdmin]
};

export const EmployeeSettings: MenuOption = {
  label: 'Account Settings',
  icon: cog,
  path: '/EditProfile',
  paths: [],
  requiresOneOfRole: [RoleCode.Employee]
};

export const Logout: MenuOption = {
  label: 'Logout',
  icon: logout,
  path: '/Login',
  paths: [],
  requiresOneOfRole: [RoleCode.Employee, RoleCode.CompanyAdmin, RoleCode.MasterAdmin]
};

export const Leaderboard: MenuOption = {
  label: 'Leaderboard',
  icon: bargraph,
  path: '/leaderboard',
  paths: [],
  requiresOneOfRole: [RoleCode.Employee]
};

export const MyStats: MenuOption = {
  label: 'My Stats',
  icon: radar,
  path: '/MyStats',
  paths: [],
  requiresOneOfRole: [RoleCode.Employee]
};

export const SuspectDailyActivities: MenuOption = {
  label: 'Suspect Activities',
  icon: radar,
  path: '/SuspectDailyActivities',
  paths: [],
  requiresOneOfRole: [RoleCode.MasterAdmin]
};

export const WaterCooler: MenuOption = {
  label: 'Water Cooler',
  icon: hash,
  path: '/WaterCooler',
  paths: [],
  requiresOneOfRole: [RoleCode.Employee]
};

export const TrophyCabinet: MenuOption = {
  label: 'Trophy Cabinet',
  icon: trophyIcon,
  path: '/TrophyCabinet',
  paths: [],
  requiresOneOfRole: [RoleCode.Employee]
};

export const TeamBuilder: MenuOption = {
  label: 'Team Builder',
  icon: team,
  path: '/TeamBuilder',
  paths: [],
  requiresOneOfRole: [RoleCode.MasterAdmin]
};

export const PastEvents: MenuOption = {
  label: 'Past Events',
  icon: calendarIcon,
  path: '/PastEvents',
  paths: [],
  requiresOneOfRole: [RoleCode.CompanyAdmin]
};

export default {
  list: [
    Dashboard,
    CompanyAdmins,
    EventRegistration,
    PastEvents,
    EventPayments,
    Management,
    Teams,
    TeamBuilder,
    SuspectDailyActivities,
    Reports,
    SAPosts,
    WaterCooler,
    CAPosts,
    Leaderboard,
    MyStats,
    TrophyCabinet,
    EmployeeSettings,
    Logout
  ]
};
