import styled from 'styled-components/macro';

export const TabsContainer = styled.div`
  display: flex;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    margin-left: 0;
  }
  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    overflow: scroll;
  }
`;
