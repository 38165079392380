import { Company, Event } from 'src/lib/Types';

export const SET_SELECTED_COMPANY_IDS = 'SET_SELECTED_COMPANY_IDS';
export const SET_SELECTED_EVENT_ID = 'SET_SELECTED_EVENT_ID';
export const SET_COMPANY_OPTIONS = 'SET_COMPANY_OPTIONS';
export const SET_EVENT_OPTIONS = 'SET_EVENT_OPTIONS';

export interface SetSelectedEventIdAction {
  type: typeof SET_SELECTED_EVENT_ID;
  eventId: number;
}

export interface SetSelectedCompanyIdsAction {
  type: typeof SET_SELECTED_COMPANY_IDS;
  companyIds: number[];
}

export interface SetEventOptionsAction {
  type: typeof SET_EVENT_OPTIONS;
  eventOptions: Event[];
}

export interface SetCompanyOptionsAction {
  type: typeof SET_COMPANY_OPTIONS;
  companies: Company[];
}

export type EventCompanyPageFilterActionTypes =
  | SetSelectedCompanyIdsAction
  | SetSelectedEventIdAction
  | SetEventOptionsAction
  | SetCompanyOptionsAction;
