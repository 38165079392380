import React from 'react';
import ActivityIndicator from 'react-spinners/PulseLoader';

import { theme } from '../../../theme';

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ color, size }) => (
  <ActivityIndicator color={color || theme.primary.dark} size={size} />
);

interface LoadingIndicatorProps {
  color?: string;
  size?: string;
}

export default LoadingIndicator;
