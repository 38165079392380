import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { isAfter } from '../../../../lib/TimeUtils';

import UserInfo from '../../UserInfo';
import HeaderNavDropdown from '../../HeaderNavDropdown';
import ProfileWidget from '../../ProfileWidget';
import { DropdownWrapper, ProfileWrapper, TopSectionWrapper } from './Styles';

import { APIThunkDispatch, RoleCode } from '../../../../lib/Types';
import { ApplicationState } from '../../../../lib/Store';
import {
  setCompanyIds,
  setEventIdAndLoadCompanyOptions
} from '../../../../lib/Api/common/EventCompanyPageFilter';
import { DROPDOWN_MENU } from '../../../../CONSTANTS';
import { loadEventOptions } from '../../../../lib/Api/Event';

export interface PageContentHeaderProps {
  handleDropdownClick?: () => void;
  onNavClick: (dataType: string, data: any) => void;
  nav?: boolean;
  navLocation?: string;
  event?: boolean;
  company?: boolean;
  formOpener?: JSX.Element;
  code: RoleCode;
  width?: any;
}

const PageContentHeader: FC<PageContentHeaderProps> = ({
  handleDropdownClick = () => null,
  onNavClick,
  nav = true,
  navLocation = 'aNonExistentPath',
  event = true,
  company = true,
  formOpener = <></>,
  code
}) => {
  const Dispatch: APIThunkDispatch = useDispatch();
  const {
    eventCompanyPageFilterState: {
      companyOptions,
      eventOptions,
      selectedEventId,
      selectedCompanyIds
    },
    companyAdminEventState: { currentEvent: companyEvent },
    userState: { userEvent }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    (async () => {
      // Only load top page filter options if master admin
      if (code !== RoleCode.MasterAdmin) return;
      await Dispatch(loadEventOptions()); // Do not filer nav events by any date
    })();
  }, []);

  const navData =
    code === RoleCode.CompanyAdmin ? DROPDOWN_MENU.companyadmin : DROPDOWN_MENU.systemadmin;
  let navLabel: string;
  if (nav && navData.nav.length) {
    const findNavLabel = navData.nav.find(navItem => navItem.path === navLocation);
    navLabel = findNavLabel ? findNavLabel.name : undefined;
  }

  const selectedCompany = companyOptions?.find(c => selectedCompanyIds.includes(c.companyId));
  const allCompanyIds = companyOptions?.map(c => c.companyId);
  const selectedEvent = eventOptions?.find(e => e.eventId === selectedEventId);
  const beforeCompantEventStart = isAfter(companyEvent?.startDate, moment().valueOf());
  const beforeUserEventStart = isAfter(userEvent?.startDate, moment().valueOf());

  const Dropdowns =
    code === RoleCode.CompanyAdmin ? (
      <>
        {nav && !beforeCompantEventStart && (
          <HeaderNavDropdown
            data={navData}
            dataType="nav"
            onOptionClick={onNavClick}
            defaultLabel={navLabel || 'Dashboard'}
          />
        )}
      </>
    ) : (
      <>
        {nav && !beforeUserEventStart && (
          <HeaderNavDropdown
            data={navData}
            dataType="nav"
            onOptionClick={onNavClick}
            defaultLabel={navLabel || 'Dashboard'}
          />
        )}

        {event && (
          <HeaderNavDropdown
            data={eventOptions || []}
            dataType="event"
            onOptionClick={async (e, props: { id }) => {
              handleDropdownClick();
              const selectedEventId = props?.id === 'all' ? null : +props?.id;
              await Dispatch(setEventIdAndLoadCompanyOptions(selectedEventId));
            }}
            listLoadFunction={() => null}
            defaultLabel={selectedEvent?.name || 'Event'}
          />
        )}

        {company && companyOptions?.length && selectedEventId !== null ? (
          <HeaderNavDropdown
            dataType="company"
            onOptionClick={(e, props: { id }) => {
              const newSelectedCompanyIds = props?.id === 'all' ? allCompanyIds : [+props?.id];
              handleDropdownClick();
              Dispatch(setCompanyIds(newSelectedCompanyIds));
            }}
            listLoadFunction={() => null}
            data={companyOptions || []}
            defaultLabel={selectedCompany?.name || 'Organisation'}
          />
        ) : (
          <></>
        )}
      </>
    );

  return (
    <TopSectionWrapper>
      {code === RoleCode.Employee ? (
        <UserInfo />
      ) : (
        <>
          <DropdownWrapper>
            {Dropdowns}
            {formOpener}
          </DropdownWrapper>
          <ProfileWrapper>
            <ProfileWidget />
          </ProfileWrapper>
        </>
      )}
    </TopSectionWrapper>
  );
};

export default PageContentHeader;
