import React, { FC } from 'react';
import { connect } from 'react-redux';

import EmployeeProfileWidget from '../EmployeeProfileWidget';
import { StatsHeaderBar, OrgImage } from './Styles';
import { ApplicationState } from '../../../lib/Store';

export interface UserInfoProps {
  mobileNav?: boolean;
  isAdmin?: boolean;
  companyImg?: string;
}

const UserInfo: FC<UserInfoProps> = ({ mobileNav, isAdmin, companyImg }) => {
  return (
    <StatsHeaderBar mobileNav={mobileNav} isAdmin={isAdmin}>
      <EmployeeProfileWidget mobileNav={mobileNav} />
      {companyImg && <OrgImage src={companyImg} />}

      {/* <P mobileNav={mobileNav}>
        Team Rank: <Span> NA</Span>
      </P>

      <P mobileNav={mobileNav}>
        Personal Rank: <Span> NA</Span>
      </P> */}
    </StatsHeaderBar>
  );
};

export default connect(({ userState }: ApplicationState) => ({ user: userState.userData }))(
  UserInfo
);
