/* eslint-disable @typescript-eslint/camelcase */
import moment from 'moment';
import { APIThunkResult, FitnessDeviceSyncResponse, SyncServiceCode, SyncService } from '../Types';
import { apiFetch, objectToQueryString, apiRequest } from './Utils';
import { server } from '../../CONSTANTS';
import { getEventActivityTypes, getEventActivities, getUserDevices } from './User';
import { setNotification } from '../Store/contexts/notification/actions';
import { setAvailableServices } from '../Store/contexts/syncService/actions';
import { getClientTimezoneOffset } from '../TimeUtils';

export function getAvailableServices(): APIThunkResult<SyncService[]> {
  const url = `${server}/sync-services/`;

  return apiRequest<SyncService[]>(async dispatch => {
    const res = await apiFetch<SyncService[]>({
      method: 'GET',
      url
    });

    dispatch(setAvailableServices(res));
    return res;
  });
}

export function getAuthCodeURL(serviceCode: string, state: string): APIThunkResult<any> {
  return apiRequest<any>(async () => {
    const data = { state };
    const queryStr = objectToQueryString(data);
    const url = `${server}/sync-services/${serviceCode}/authorize?${queryStr}`;
    const results = await apiFetch<any>({
      method: 'GET',
      url
    });
    return results;
  });
}

export function getDeviceAccessToken(
  serviceCode: string,
  authCode: string,
  state: string
): APIThunkResult<any> {
  return apiRequest<any>(async (dispatch, getState) => {
    const { userState } = getState();
    const { eventId } = userState.userEvent;

    const fromDateMillis = moment(userState.userEvent.startDate, 'x')
      .startOf('day')
      .valueOf()
      .toString();

    const toDateMillis = moment()
      .endOf('day')
      .valueOf()
      .toString();

    const utcOffset = getClientTimezoneOffset();

    const data = {
      authCode,
      state,
      fromDateMillis,
      toDateMillis,
      utcOffset
    };
    const queryStr = objectToQueryString(data);

    const url = `${server}/sync-services/${serviceCode}/authorize/callback?${queryStr}`;

    try {
      const results = await apiFetch<FitnessDeviceSyncResponse>({
        method: 'GET',
        url
      });

      if (results.deviceId !== null) {
        dispatch(setNotification({ message: 'Device Synchronized successfully.' }));
      }

      dispatch(getUserDevices());

      if (eventId) {
        dispatch(getEventActivityTypes(eventId));
        dispatch(getEventActivities(eventId));
      }
      return results;
    } catch (e) {
      if (e.message === 'This device is already registered for a different user.') {
        dispatch(
          setNotification({
            message: e.message,
            variant: 'warning'
          })
        );
      }
    }
  });
}

export function getGarminAccessToken(
  serviceCode: string,
  oauth_token: string,
  oauth_verifier: string,
  state: string
): APIThunkResult<any> {
  return apiRequest<any>(async dispatch => {
    const data = { oauth_token, oauth_verifier, state };
    const queryStr = objectToQueryString(data);

    const url = `${server}/sync-services/${serviceCode}/authorize/callback?${queryStr}`;

    try {
      const results = await apiFetch<any>({
        method: 'GET',
        url
      });
      dispatch(getUserDevices());
      return results;
    } catch (e) {
      if (e.message === 'This device is already registered for a different user.') {
        dispatch(
          setNotification({
            message: e.message,
            variant: 'warning'
          })
        );
      }
    }
  });
}

export function reSyncDevice(deviceName: SyncServiceCode): APIThunkResult<any> {
  return apiRequest<any>(async (dispatch, getState) => {
    try {
      const { userState } = getState();
      const { eventId } = userState.userEvent;

      const fromDateMillis = moment(userState.userEvent.startDate, 'x')
        .startOf('day')
        .valueOf()
        .toString();

      const toDateMillis = moment()
        .endOf('day')
        .valueOf()
        .toString();

      const utcOffset = getClientTimezoneOffset();

      const url = `${server}/fitness-devices/autoAddActivities/${eventId}/${deviceName}/${fromDateMillis}/${toDateMillis}/${utcOffset}`;

      const body = {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };

      const results = await apiFetch<any>({
        method: 'POST',
        url,
        body
      });

      if (eventId) {
        dispatch(getEventActivityTypes(eventId));
        dispatch(getEventActivities(eventId));
      }

      const { garminStatus } = results;

      const message =
        garminStatus === 202
          ? 'Garmin Device Synced, Activities will appear shortly.'
          : 'Device synchronized successfully';

      dispatch(setNotification({ message }));

      return results;
    } catch (err) {
      dispatch(
        setNotification({
          message: 'Something went wrong synchronizing your device. Try again later.',
          variant: 'danger',
          duration: 5
        })
      );
    }
  });
}
