import styled from 'styled-components/macro';

import { getBoxShadow } from '../../../lib/Utils';

interface PaperProps {
  elevation?: 1 | 2 | 3 | 4 | 5;
  width?: string;
  padding?: string;
  minWidth?: string;
  margin?: string;
  noTopLeftRadius?: boolean;
}

const Paper = styled.div<PaperProps>`
  border-radius: 1.5rem;
  border-top-left-radius: ${({ noTopLeftRadius }) => (noTopLeftRadius ? 0 : '1.5rem')};
  box-shadow: ${({ elevation = 1, theme }) => getBoxShadow(elevation, theme)};
  padding: ${({ padding }) => (padding ? padding : '2rem 2.75rem')};
  margin: ${({ margin }) => (margin ? margin : '0 0 2rem 0')};
  width: ${({ width }) => (width ? width : '')};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '')};
  background-color: ${({ theme }) => theme.primary.white};

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    padding: ${({ padding }) => (padding ? padding : '1rem')};
  }
`;

export default Paper;
