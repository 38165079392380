import styled from 'styled-components/macro';
import { DarkBlueHeaderXS } from '../../../Themes';

export const DropdownMenuHd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 18px 0 18px;
`;

export const DropdownHd = styled(DarkBlueHeaderXS)`
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: #21679a;
  font-size: 1.3rem;
`;

export const DropdownArr = styled.img`
  margin-left: auto;
`;

export const DropdownOptionsWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.md * 0.9}px;
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.md * 0.9}px;
  user-select: none;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

export const DropdownOption = styled(DarkBlueHeaderXS)`
  padding: 0 0 0 28px;
  line-height: 3rem;
  user-select: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  opacity: 48%;
  font-size: 1.1rem;

  &:hover {
    opacity: 100%;
  }
`;

export const DropdownParentOption = styled(DarkBlueHeaderXS)`
  padding: 0 0 0 18px;
  user-select: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  opacity: 48%;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.3rem;

  &:hover {
    opacity: 100%;
  }
`;

export const DropdownContainer = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.white};
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.md * 0.9}px;
  margin-right: ${({ theme }) => theme.padding}px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex: 1;
  user-select: none;
  height: 40px;
  max-width: calc(33.33% - ${({ theme }) => theme.padding}px);
`;

export const DropdownContainerOpen = styled(DropdownContainer)`
  overflow: scroll;
  height: 100%;
`;
