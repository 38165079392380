import styled from 'styled-components/macro';

interface MainContentContainerProps {
  height?: string;
  flexDirection?: string;
  marginBottom?: string;
  marginTop?: string;
}

export const MainContentContainer = styled.div<MainContentContainerProps>`
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  background-color: ${({ theme }) => theme.backgrounds.white};
  display: flex;
  width: 100%;
  flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'column')};
  //flex:1;
  height: ${({ height }) => height};
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${({ theme }) => theme.pad.xsm * 1.5}%;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const MainContentContainerPadded = styled.div<MainContentContainerProps>`
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  background-color: ${({ theme }) => theme.backgrounds.white};
  display: flex;
  width: 100%;
  flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'column')};
  //flex:1;
  height: ${({ height }) => height};
  padding: ${({ theme }) => theme.pad.xsm * 1.5}%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const PageContent = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  bottom: 0;
  margin-top: -1rem;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    margin-top: 2rem;
  }
`;
