import React, { FC } from 'react';
import {
  Heading,
  DetailsContainer,
  Details,
  TeamDataHeading,
  TeamDataValue,
  DetailsRow
} from './Styles';
import { Input } from '../../../../../components/UI';
import { FormikFormComponent, Team } from '../../../../../lib/Types';
import { TeamSchema } from '../../../validators/teamSchema';
import theme from '../../../../../theme';

interface TeamDetailsProps extends FormikFormComponent<TeamSchema> {
  team: Team;
}

const TeamDetails: FC<TeamDetailsProps> = ({ values, errors, handleChange, team }) => (
  <DetailsContainer>
    <Heading margin={'0 0 1.5rem 0'}>Team Details</Heading>
    <Input
      required
      id="name"
      name="name"
      value={values.name}
      error={errors.name}
      label="Team Name"
      type="name"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
    <DetailsRow>
      <Details>
        <TeamDataHeading>Organisation</TeamDataHeading>
        <TeamDataValue>{team.company.name}</TeamDataValue>
      </Details>
      <Details>
        <TeamDataHeading>Event</TeamDataHeading>
        <TeamDataValue>{team.event.name}</TeamDataValue>
      </Details>
      <Details>
        <TeamDataHeading>Status</TeamDataHeading>
        <TeamDataValue>{team.approved ? 'Approved' : 'Awaiting Approval'}</TeamDataValue>
      </Details>
    </DetailsRow>
  </DetailsContainer>
);

export default TeamDetails;
