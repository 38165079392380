import React, { FC } from 'react';
import { connect } from 'react-redux';
import { ProfileContainer, WidgetProfileImage, UserInfoWrapper, UserName } from './Styles';
import { User } from '../../../lib/Types';
import { ApplicationState } from '../../../lib/Store';
import { DEFAULT_PIC } from '../../../CONSTANTS';

interface EmployeeProfileProps {
  notificationCount?: number;
  user: User;
  mobileNav?: boolean;
}

const EmployeeProfileWidget: FC<EmployeeProfileProps> = ({ user, mobileNav }) => {
  return (
    <ProfileContainer mobileNav={mobileNav}>
      <WidgetProfileImage src={user.profileImage !== '' ? user.profileImage : DEFAULT_PIC} />
      <UserInfoWrapper mobileNav={mobileNav}>
        <UserName>
          {user.firstName} {user.lastName}
        </UserName>
      </UserInfoWrapper>
    </ProfileContainer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({ user: state.userState.userData });

export default connect(mapStateToProps, null)(EmployeeProfileWidget);
