import { apiRequest, apiFetch } from '../Utils';
import { server } from '../../../CONSTANTS';

import { APIThunkResult, APIResult, RegistrationData } from '../../Types';
import { setTeamData } from '../../Store/contexts/participant/team/actions';

export type GetUserRegistrationData = () => Promise<APIResult<RegistrationData>>;
export function getUserRegistrationData(): APIThunkResult<RegistrationData> {
  return apiRequest<RegistrationData>(async dispatch => {
    const { team, individualEventRegistration, teamMemberRegistrations } = await apiFetch<
      RegistrationData
    >({
      method: 'GET',
      url: `${server}/users/me/registration-data`
    });
    dispatch(setTeamData(team, teamMemberRegistrations, individualEventRegistration));

    return { team, individualEventRegistration, teamMemberRegistrations };
  });
}
