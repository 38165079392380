import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { registerForEvent } from '../../lib/Api/participant/Event';
import { getUserEvent } from '../../lib/Api/User';

import { SelectDropdown, Modal } from '../../components/UI';

import { participantRegisterDemographics } from '../../assets/img';
import {
  RegisterModalContainer,
  ModalGraphic,
  ModalContent,
  ButtonWrapper,
  Submit,
  Cancel
} from './Styles';
import { DarkBlueBodyS, BoldText, DarkBlueHeaderL } from '../../Themes';

import { ApplicationState } from '../../lib/Store';
import { CompanyDemographic, APIThunkDispatch } from 'src/lib/Types';

export interface RegisterModalProps {
  onCancel: () => void;
  onParticipantCapError: () => void;
}

const RegisterModal: FC<RegisterModalProps> = ({ onCancel, onParticipantCapError }) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const { companyDemographics, event } = useSelector(
    ({ participantEventState, userState }: ApplicationState) => ({
      companyDemographics: participantEventState.companyDemographics,
      event: userState.userEvent
    })
  );

  return (
    <Formik
      initialValues={{
        demographics: companyDemographics.map(cd => ({
          companyDemographicId: cd.companyDemographicId,
          childDemographicAnswer: null,
          childDemographicId: null
        }))
      }}
      validateOnBlur={false}
      validateOnChange
      validateOnMount={false}
      validate={({ demographics }) => {
        const errors: {
          demographics: {
            [key: string]: { error: string };
          };
        } = {
          demographics: {}
        };
        if (demographics.length > 0) {
          demographics.map((demo, index) => {
            if (!demo.childDemographicAnswer && !demo.childDemographicId)
              errors.demographics[index] = { error: 'Each demographic must be answered' };
          });
        }
        if (Object.keys(errors.demographics).length > 0) return errors;
        return {};
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const { data, error } = await dispatch(
          registerForEvent({
            eventId: event.eventId,
            demographics: values.demographics
          })
        );
        if (
          error &&
          error.message ===
            'Paid participants for this event has been reached for your company. Please contact your company administrator'
        ) {
          onParticipantCapError();
        } else if (data) {
          await dispatch(getUserEvent());
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, handleSubmit, setFieldValue, isSubmitting }) => {
        const getValue = (demoField: CompanyDemographic): number => {
          const currentAnswer = values.demographics.find(
            ud => ud.companyDemographicId === demoField.companyDemographicId
          );
          if (!currentAnswer) return;
          const value = demoField.options.find(
            o => o.companyDemographicId === currentAnswer.childDemographicId
          );
          return value?.companyDemographicId;
        };
        return (
          <Modal
            overflowVisible
            onClose={onCancel}
            padding="40px"
            top={10}
            titlePadding="0px"
            showHeader={false}
          >
            <RegisterModalContainer>
              <ModalGraphic src={participantRegisterDemographics} />
              <ModalContent>
                <DarkBlueHeaderL>Complete your registration</DarkBlueHeaderL>
                {values.demographics.length > 0 ? (
                  <DarkBlueBodyS padding={'0 20% 3rem 0'}>
                    {
                      'Answering the questions below will help place you on the correct leaderboards for the '
                    }
                    <BoldText>{event.name}</BoldText>
                    {' event. '}
                  </DarkBlueBodyS>
                ) : (
                  <DarkBlueBodyS padding={'0 20% 3rem 0'}>
                    {'Please click Complete to finish registering for '}
                    <BoldText>{event.name}</BoldText>
                    {' or click Cancel to finish your registration at another time.'}
                  </DarkBlueBodyS>
                )}
                {companyDemographics.map((demoField, index) => (
                  <div key={index}>
                    <SelectDropdown
                      required
                      data={demoField.options}
                      value={getValue(demoField)}
                      error={errors && errors.demographics && errors.demographics[index]}
                      valueProp="companyDemographicId"
                      displayProp="name"
                      placeholder={'Select a ' + demoField.name}
                      onChange={value => {
                        const currentAnswerIndex = values.demographics.findIndex(
                          ud => ud.companyDemographicId === demoField.companyDemographicId
                        );
                        setFieldValue(`demographics[${currentAnswerIndex}]`, {
                          companyDemographicId: demoField.companyDemographicId,
                          childDemographicAnswer: demoField.options.find(
                            o => o.companyDemographicId === value
                          )?.name,
                          childDemographicId: demoField.options.find(
                            o => o.companyDemographicId === value
                          )?.companyDemographicId
                        });
                      }}
                    />
                    <br></br>
                  </div>
                ))}
                <ButtonWrapper>
                  <Cancel onClick={onCancel} type="button" variant="cancel">
                    Cancel
                  </Cancel>
                  <Submit
                    type="button"
                    onClick={() => handleSubmit()}
                    variant="report"
                    disabled={isSubmitting}
                  >
                    Complete
                  </Submit>
                </ButtonWrapper>
              </ModalContent>
            </RegisterModalContainer>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default RegisterModal;
