import React, { FC, useEffect } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setEventFilter } from '../../../../lib/Store/contexts/event/actions';
import { ApplicationState } from '../../../../lib/Store';
import {
  PagedResponse,
  OrderDirection,
  Permission,
  EventListView,
  PaginationFilter
} from '../../../../lib/Types';
import EventList from './EventList';
import { loadEvent } from '../../../../lib/Api/Event';
import { loadEvents, LoadEvents } from '../../../../lib/Api/masterAdmin/Event';

export interface EventListContainerProps {
  events: PagedResponse<EventListView>;
  filter: PaginationFilter;
  loading: boolean;
  permissions: Permission[];
  onLoadEvents: LoadEvents;
  setFilter: (filter: PaginationFilter) => void;
  editEvent: (eventId: number) => void;
}

const EventListContainer: FC<EventListContainerProps> = ({
  events,
  filter,
  loading,
  permissions,
  onLoadEvents,
  setFilter,
  editEvent
}) => {
  useEffect(() => {
    (async () => {
      await onLoadEvents(filter);
    })();
  }, [filter]);

  const onEdit = (eventId: number) => editEvent(eventId);
  const setPage = (page: number) => setFilter({ ...filter, page });
  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    setFilter({ ...filter, orderColumn, orderDirection });

  return (
    <EventList
      permissions={permissions}
      loading={loading}
      filter={filter}
      events={events}
      setFilter={setFilter}
      onSetPage={setPage}
      onSort={setSort}
      onEdit={onEdit}
    />
  );
};

const mapStateToProps = ({
  eventState,
  loadingState,
  permissionsState,
  masterAdminEventState
}: ApplicationState) => ({
  events: masterAdminEventState.events,
  filter: eventState.filter,
  loading: loadingState.apiCallsInProgress > 0,
  permissions: permissionsState.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onLoadEvents: (filter: PaginationFilter) => loadEvents(filter) as any,
      setFilter: (filter: PaginationFilter) => setEventFilter(filter),
      editEvent: (eventId: number) => loadEvent(eventId)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventListContainer);
