import styled from 'styled-components/macro';
import { theme } from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: ${theme.backgrounds.lightBlue};
  padding: 2.5rem;
  border-radius: ${theme.borderRadius.lg}px;
  z-index: 1;
  margin: 2.5rem 0 0;
`;
// grid-area: regLnk;

export const Header = styled.h4`
  font-size: calc(1rem + 0.4vw);
  font-style: italic;
  display: flex;
  flex: 0 0 100%;
  margin-bottom: 1.5rem;
  color: ${theme.primary.main};
  @media (min-width: ${theme.device.tablet}px) and (max-width: ${theme.device.laptop}px) {
    font-size: calc(1.2rem + 0.4vw);
    margin: 0 0 1rem 0;
  }
`;

export const InputGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.5rem;
  grid-template-areas: 'RegA RegA RegA RegA  RegB RegB';

  @media (min-width: ${theme.device.tablet}px) and (max-width: ${theme.device.laptop}px) {
    grid-template-areas: 'RegA RegA RegA  RegA RegB RegB';
  }
  @media (min-width: ${theme.device.mobileL + 50}px) and (max-width: ${theme.device.tablet}px) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: 'RegA RegA RegA RegB RegB';
  }
  @media (max-width: ${theme.device.mobileL + 50}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'RegA'
      'RegB';
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex: 1;
`;

// grid-area: RegA;

// & + & {
//   grid-area: RegB;
// }
