import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withFormik, FormikProps } from 'formik';
import { Input, ExternalPageWrapper } from '../../../../components/UI';
import validationSchema from '../../validators/forgotPasswordSchema';
import Auth from '../../../../lib/Backend/Authentication';

import { BodyText, Submit, Form } from './Styles';

interface ResetPasswordState {
  email: string;
}

interface ForgotPasswordFormProps extends RouteComponentProps {
  setPasswordReset?: (data: boolean) => void;
}

const formikEnhancer = withFormik<ForgotPasswordFormProps, ResetPasswordState>({
  mapPropsToValues: () => ({ email: '' }),
  validationSchema,
  handleSubmit: ({ email }, { props, setErrors }) => {
    Auth.sendPasswordReset(email)
      .then(() => props.setPasswordReset(true))
      .catch(err =>
        err.code === 'auth/user-not-found'
          ? setErrors({ email: 'Email not registered.' })
          : setErrors({ email: 'Unexpected error.' })
      );
  }
});

const ForgotPasswordForm: FC<RouteComponentProps & FormikProps<ResetPasswordState>> = ({
  handleChange,
  handleSubmit,
  values,
  errors
}) => {
  return (
    <ExternalPageWrapper header="Forgot your password" backLink="/Login">
      <BodyText>
        Please enter your account&apos;s email address below and we will send you details on how to
        reset your password
      </BodyText>
      <Form onSubmit={handleSubmit}>
        <Input
          required
          id="email"
          name="email"
          type="text"
          label="Email Address"
          value={values.email}
          error={errors.email}
          onChange={handleChange}
        />
        <Submit type="submit" variant="primary">
          Send password request
        </Submit>
      </Form>
    </ExternalPageWrapper>
  );
};

export default withRouter(formikEnhancer(ForgotPasswordForm));
