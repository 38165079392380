import { apiRequest, apiFetch, objectToQueryString } from '../Utils';
import { server } from '../../../CONSTANTS';

import { APIThunkResult, APIResult, AdminDashBoardData } from '../../Types';
import { setDashBoardData } from '../../Store/contexts/masterAdmin/dashboard/actions';

export type GetDashboardData = (
  eventId?: number,
  companyId?: number
) => Promise<APIResult<AdminDashBoardData>>;
export function getDashboardData(
  eventId: number,
  companyId: number
): APIThunkResult<AdminDashBoardData> {
  return apiRequest<any>(async dispatch => {
    const query = objectToQueryString({ eventId, companyId });
    const data = await apiFetch<AdminDashBoardData>({
      method: 'GET',
      url: `${server}/dashboard?${query}`
    });
    dispatch(setDashBoardData(data));
    return data;
  });
}
