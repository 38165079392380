import styled from 'styled-components/macro';

export const EmbedDiv = styled.div`
  height: 100%;
  flex: 7;
`;

export const SideImage = styled.img`
  flex: 1;
`;
