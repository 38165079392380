import React, { FC } from 'react';
import {
  FormikForm,
  Team,
  UserDemographic,
  Company,
  ParticipantListView,
  CompanyDemographic
} from '../../../../lib/Types';
import { ParticipantSchema } from '../../validators/participantSchema';
import ParticipantForm from '../ParticipantForm/ParticipantForm';

interface EditParticipantProps extends FormikForm<ParticipantSchema> {
  participant: ParticipantListView;
  participantTeam: Team;
  userDemographics: UserDemographic[];
  companyOptions: Company[];
  companyDemographics: CompanyDemographic[];
  loading: boolean;
  onCancel: () => void;
}

const EditParticipant: FC<EditParticipantProps> = ({ loading, ...props }) => (
  <>{loading ? 'Loading' : <ParticipantForm {...props} />}</>
);

export default EditParticipant;
