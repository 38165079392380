import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { loadMorePosts, likePost, deleteMyPost } from '../../lib/Api/Posts';
import { getMyDashboardData } from '../../lib/Api/participant/Dashboard';
import { isAfter } from '../../lib/TimeUtils';

import { EmployeePageWrapper } from '../../components/UI';
import PostComponent from '../../components/UI/Post';
import CommentForm from '../../components/UI/Post/CommentForm';
import ReportModal from '../../components/UI/Modal/ReportModal';
import FeedPrimaryFilter from './FeedPrimaryFilter/FeedPrimaryFilter';
import FeedSecondaryFilter from './FeedSecondaryFilter/FeedSecondaryFilter';
import TopLeaderBoard from './TopLeaderBoard';

import { bluePlus } from '../../assets/icons';
import {
  ContentWrapper,
  PostFeedWrapper,
  Feed,
  CommentFormWrapper,
  WaterCoolerTitle,
  ActionBar,
  CreatePostButton,
  CreatePostIcon,
  CreatePostText,
  MobileCreatePostButton
} from './Styles';

import { ApplicationState } from '../../lib/Store';
import { Post, ApiStatus, DashBoardData, APIThunkDispatch } from '../../lib/Types';
import {
  updatePost,
  updateMiniChallenge,
  updatePostRepliesFilter,
  updatePostsFilter,
  setPosts,
  setAllLoaded
} from '../../lib/Store/contexts/posts/actions';
import { comparePosts } from '../../lib/Store/contexts/posts/utils';
import { initialPostsState } from '../../lib/Store/contexts/posts/reducers';
import { loadOwnRanking, loadParticipantRankings } from 'src/lib/Api/participant/LeaderBoard';

interface PostsFeedProps {}

const PostsFeed: React.FC<PostsFeedProps> = () => {
  const dispatch: APIThunkDispatch = useDispatch();

  const {
    userState: { userEvent, userData: user },
    postsState: { filter: postsFilter, posts, allLoaded },
    participantLeaderBoardState: { waterCoolerLeaderBoardFilter: leaderboardFilter }
  } = useSelector((state: ApplicationState) => state);

  const [fetching, setFetching] = useState(false);
  // Posts currently having their liked status updated(loading). Mitigates latency issues
  const [likingPosts, setLikingPosts] = useState<number[]>([]);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [reportingPostId, setReportingPostId] = useState(0);

  // Filter state
  const [postEngagementData, setPostEngagementData] = useState<DashBoardData>();

  const [inputHidden, setInputHidden] = useState(true);

  // Used to calculate feed height
  const commentFormRef = useRef(null);
  const [commentFormHeight, setCommentFormHeight] = useState(NaN);

  const fetchMorePosts = useCallback(
    async (overrideFetch = false) => {
      if (allLoaded && !overrideFetch) return;
      setFetching(true);
      // await new Promise(resolve => setTimeout(resolve, 1000)); // You want this whenever developing
      await dispatch(loadMorePosts(overrideFetch));
      setFetching(false);
    },
    [loadMorePosts, allLoaded]
  );

  const handleOpenInput = () => {
    if (inputHidden) {
      // Ensure when clicking creat a post from another tab, we force posts refectch
      // TODO: The logic that handles where and when posts are refected prob needs a refactor and cleanup
      if (postsFilter.primaryPostTypeFilter !== 'GENERAL_ACTIVITIES') {
        dispatch(setAllLoaded(false));
        dispatch(setPosts([]));
      }
      setInputHidden(false);
      dispatch(
        updatePostsFilter({
          ...postsFilter,
          primaryPostTypeFilter: 'GENERAL_ACTIVITIES',
          secondaryPostTypeFilter: 'MY_POSTS'
        })
      );
    } else setInputHidden(true);
  };

  const onScroll = useCallback(() => {
    if (!userEvent.eventId || !isAfter(moment().valueOf(), userEvent.startDate)) return;
    const { body, documentElement } = document;
    const { scrollTop, scrollHeight, clientHeight } = documentElement;
    const winScroll = body.scrollTop || scrollTop;
    const height = scrollHeight - clientHeight;
    const scrolled = winScroll / height; // 0.0 - 1.0

    if (scrolled >= 0.6 && !fetching && !allLoaded) fetchMorePosts();
  }, [fetching, fetchMorePosts, userEvent]);

  const handleLikePost = async (post: Post) => {
    const { postId, likes, liked } = post;
    if (likingPosts.includes(postId)) return; // Currently in progress, ignore

    setLikingPosts([...likingPosts, postId]);
    dispatch(updatePost({ postId, liked: !liked, likes: likes + (liked ? -1 : 1) })); // Predictive
    dispatch(updateMiniChallenge({ postId, liked: !liked, likes: likes + (liked ? -1 : 1) })); // Predictive

    const { status } = await dispatch(likePost(postId));
    if (status !== ApiStatus.SUCCESS) {
      dispatch(updatePost(post)); // Liking failed, revert predictive update
      dispatch(updateMiniChallenge(post));
    }

    setLikingPosts(likingPosts.filter(id => id !== postId));
  };

  const onUpdateFilter = () => {
    setInputHidden(true);
    fetchMorePosts(true);
  };

  useEffect(() => {
    if (!userEvent.eventId || !isAfter(moment().valueOf(), userEvent.startDate)) return;
    fetchMorePosts();
    dispatch(updatePostRepliesFilter(initialPostsState.repliesFilter));
  }, [dispatch, fetchMorePosts, userEvent]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [fetchMorePosts, fetching]);

  useEffect(() => {
    if (!userEvent.eventId || !isAfter(moment().valueOf(), userEvent.startDate)) return;
    (async () => {
      const { data } = await dispatch(getMyDashboardData());
      setPostEngagementData(data);
    })();
  }, [userEvent, getMyDashboardData, setPostEngagementData]);

  useEffect(() => {
    if (commentFormRef.current) setCommentFormHeight(commentFormRef.current.offsetHeight);
  }, [commentFormRef.current]);

  // Top Leaderboard data loading
  useEffect(() => {
    (async () => await dispatch(loadParticipantRankings(leaderboardFilter, false)))();
  }, [leaderboardFilter]);

  useEffect(() => {
    (async () => await dispatch(loadOwnRanking(false)))();
  }, []);

  useEffect(() => {
    dispatch(setPosts([]));
    dispatch(setAllLoaded(false));
    setInputHidden(true);
    fetchMorePosts(true);
  }, []);

  return (
    <EmployeePageWrapper fixHeight holdingPageTitle="The Water Cooler">
      <ContentWrapper>
        <PostFeedWrapper>
          <WaterCoolerTitle>The Water Cooler</WaterCoolerTitle>
          <MobileCreatePostButton onClick={() => handleOpenInput()}>
            <CreatePostIcon src={bluePlus} />
          </MobileCreatePostButton>
          <ActionBar>
            <FeedPrimaryFilter onFilterChange={onUpdateFilter} />
            <CreatePostButton onClick={() => handleOpenInput()}>
              <CreatePostIcon src={bluePlus} />
              <CreatePostText>{inputHidden ? 'CREATE A POST' : 'CANCEL/CLOSE'}</CreatePostText>
            </CreatePostButton>
          </ActionBar>
          <FeedSecondaryFilter
            postEngagementData={postEngagementData}
            onFilterChange={onUpdateFilter}
          />
          <CommentFormWrapper hidden={inputHidden} ref={commentFormRef}>
            <CommentForm
              profileImage={user.profileImage}
              userId={user.userId}
              companyIds={[user.companyId]}
              buttonLabel="Post"
              title="Create a Post"
              isReply={false}
              onSuccess={() => setInputHidden(true)}
              eventId={userEvent.eventId}
            />
          </CommentFormWrapper>
          <Feed commentFormHidden={inputHidden} offsetHeight={commentFormHeight}>
            {posts.length
              ? posts
                  .sort(comparePosts)
                  .map(post => (
                    <PostComponent
                      user={user}
                      key={post.postId}
                      post={post}
                      onLike={() => handleLikePost(post)}
                      onDelete={
                        post.user.userId === user.userId
                          ? () => dispatch(deleteMyPost(post.postId))
                          : null
                      }
                      setReportModalVisible={setReportModalVisible}
                      setReportingPostId={setReportingPostId}
                    />
                  ))
              : !fetching && inputHidden && <h4 className="title">There are no posts here!</h4>}
            {fetching && <h4 className="title">Loading...</h4>}
          </Feed>
        </PostFeedWrapper>
        <TopLeaderBoard />
      </ContentWrapper>

      {reportModalVisible && (
        <ReportModal postId={reportingPostId} closeModal={() => setReportModalVisible(false)} />
      )}
    </EmployeePageWrapper>
  );
};

export default PostsFeed;
