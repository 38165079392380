import styled from 'styled-components/macro';
import { Paper } from '../../../components/UI';
import { DarkBlueHeaderM, DarkBlueBodyM } from '../../../Themes';

export const PostMetaWrapper = styled(Paper)`
  max-width: 80%;
  width: 80%;
`;

export const Title = styled(DarkBlueBodyM)`
  line-height: 2.1rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  margin: 1rem 0 2.5rem 0;
`;

export const PostTypeWrapper = styled.div`
  margin-bottom: 4rem;
`;

export const InputLabel = styled(DarkBlueHeaderM)``;

export const InfoText = styled(DarkBlueHeaderM)``;

export const DropdownWrapper = styled.div`
  width: 75%;
  margin-bottom: 2rem;
`;

export const ButtonWrapper = styled.div`
  margin-top: 5rem;
  display: flex;
  justify-content: flex-end;
`;
