import { CompanyDemographic, UserDemographic } from '../../../../Types';

export const SET_COMPANY_DEMOGRAPHICS = 'SET_COMPANY_DEMOGRAPHICS';
export const SET_USER_DEMOGRAPHICS = 'SET_USER_DEMOGRAPHICS';

export interface EventState {
  companyDemographics: CompanyDemographic[];
  userDemographics: UserDemographic[];
}

export interface SetCompanyDemographicsAction {
  type: typeof SET_COMPANY_DEMOGRAPHICS;
  companyDemographics: CompanyDemographic[];
}

export interface SetUserDemographicsAction {
  type: typeof SET_USER_DEMOGRAPHICS;
  userDemographics: UserDemographic[];
}

export type EventActionTypes = SetCompanyDemographicsAction | SetUserDemographicsAction;
