import React, { ChangeEvent, FC, useState, useEffect, useCallback } from 'react';
import {
  PaginationContainer,
  PageInput,
  PageButton,
  PaginationArrowLeft,
  PaginationArrowLeftWrap,
  PaginationArrowRight,
  PaginationArrowRightWrap
} from './Styles';

export interface PaginationProps {
  page: number;
  count: number;
  rowsPerPage: number;
  loading: boolean;
  pageInput: boolean;
  onPrevPage: () => void;
  onNextPage: () => void;
  onSetPage: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  page,
  count,
  rowsPerPage,
  loading,
  pageInput,
  onPrevPage,
  onNextPage,
  onSetPage
}) => {
  const pageCount = Math.ceil(count / rowsPerPage);

  const [pageInputValue, setPageInputValue] = useState(NaN);

  const handlePageInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    if (value) {
      setPageInputValue(parseInt(value, 10));
    } else setPageInputValue(NaN);
  };

  useEffect(() => {
    setPageInputValue(page);
  }, [page]);

  const keyDown = (event: KeyboardEvent) => {
    let value = pageInputValue;
    if (!value) return;
    if (value < 1) value = 1;
    else if (value > pageCount) value = pageCount;
    if (event.key.toString() === 'Enter') {
      onSetPage(value);
    }
  };

  useEffect(() => {
    if (pageInput) {
      window.addEventListener('keydown', keyDown);
      return () => window.removeEventListener('keydown', keyDown);
    }
  }, [page, keyDown]);

  return (
    <PaginationContainer>
      <PaginationArrowLeftWrap>
        <PaginationArrowLeft onClick={onPrevPage} disabled={page === 1 || loading} />
      </PaginationArrowLeftWrap>
      {pageInput ? (
        <>
          <PageInput
            active
            name="page"
            type="number"
            onChange={handlePageInputChange}
            value={pageInputValue || ''}
          />
          <PageButton active>{` of ${pageCount}`}</PageButton>
        </>
      ) : (
        <PageButton active>{`${page} of ${pageCount}`}</PageButton>
      )}

      <PaginationArrowRightWrap>
        <PaginationArrowRight onClick={onNextPage} disabled={page === pageCount || loading} />
      </PaginationArrowRightWrap>
    </PaginationContainer>
  );
};

export default Pagination;
