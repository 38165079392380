import React, { FC } from 'react';
import { BackArrow, Container, LeftColumn, RightColumn, LinkText } from './Styles';
import { Link } from 'react-router-dom';
import Brand from './components/Brand';
import { backArrow } from '../../../assets/icons';
import teamGraphic from '../../../assets/img/login-graphic.svg';

interface ExternalPageWrapperProps {
  header?: string;
  backLink?: string;
  tabletAlign?: string;
}

const ExternalPageWrapper: FC<ExternalPageWrapperProps> = ({ children, backLink, tabletAlign }) => (
  <Container>
    <LeftColumn bg={teamGraphic} />
    <RightColumn tabletAlign={tabletAlign}>
      {backLink ? (
        <Link to={backLink}>
          <BackArrow alt="back" src={backArrow} />
        </Link>
      ) : null}
      <Brand />
      {children}
      <div style={{ flexGrow: 1, minHeight: '20px' }} />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '22px' }}>
        <LinkText to={{ pathname: "https://movingmindz.io/privacy/" }} target="_blank">Privacy Policy</LinkText>
        <LinkText to={{ pathname: "https://movingmindz.io/terms/" }} target="_blank">Terms of Use</LinkText>
      </div>
    </RightColumn>
  </Container>
);

export default ExternalPageWrapper;
