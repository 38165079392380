import styled from 'styled-components/macro';
import { DarkBlueHeaderL } from '../../../Themes';

interface HeaderProps {
  margin?: string;
}

export const TitleWrapper = styled.div<HeaderProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: ${({ margin }) => (margin ? margin : '0 0 1.5rem 0')};
`;

export const BackBtn = styled.img`
  cursor: pointer;
  margin-right: 2rem;
  transform: scale(0.96);
  padding-top: 0.2rem;
`;

export const FormTitle = styled(DarkBlueHeaderL)`
  font-size: 2.2rem;
`;
