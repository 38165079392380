import React, { FC } from 'react';
import { Modal } from '../../../../components/UI';
import ModalButton from '../../../../components/UI/Button/ModalButton';
import { ParticipantListView } from '../../../../lib/Types';
import { ColumnWrapper, FormColumn, ButtonWrapper } from './Styles';
import { DarkBlueBodyM, DarkBlueBodyL, DarkBlueHeaderL, RedText } from '../../../../Themes';
import { eventGuy } from '../../../../assets/icons';

interface DeleteParticipantProps {
  participant: ParticipantListView;
  loading: boolean;
  onCancel: () => void;
  onDeleteTeamMember?: (teamId: number, participantId: string, eventId: number) => void;
  onDeleteIndividual?: (eventId: number, participantId: string) => void;
  participantType: 'TEAM' | 'INDIVIDUAL';
}

const DeleteParticipant: FC<DeleteParticipantProps> = ({
  loading,
  participant,
  participantType,
  onCancel,
  onDeleteTeamMember,
  onDeleteIndividual
}) => (
  <Modal top={5} title="Delete Participant" icon={eventGuy} width={75} left={12.5}>
    {loading || participant.participantId === null ? (
      <>
        <br />
        <ColumnWrapper>
          <FormColumn>
            <DarkBlueBodyL>Loading...</DarkBlueBodyL>
            <br />
          </FormColumn>
        </ColumnWrapper>

        <ButtonWrapper>
          <ModalButton onClick={onCancel} type="button" variant={'secondary'}>
            Cancel
          </ModalButton>
        </ButtonWrapper>
      </>
    ) : (
      <>
        <br />
        <ColumnWrapper>
          <FormColumn>
            <DarkBlueHeaderL>
              <RedText>Warning! This action will remove records.</RedText>
            </DarkBlueHeaderL>
            <br />
            <DarkBlueBodyL>
              {participant.fullName} - {participant.email}
            </DarkBlueBodyL>
            <br />
            <DarkBlueBodyM>
              Are you sure you wish to delete this participant? This action cannot be undone and
              will remove all records associated with this user.
            </DarkBlueBodyM>
            <br />
          </FormColumn>
        </ColumnWrapper>

        <ButtonWrapper>
          <ModalButton onClick={onCancel} type="button" variant={'secondary'}>
            Cancel
          </ModalButton>
          <ModalButton
            type="submit"
            variant={'primary'}
            disabled={!participant || loading}
            onClick={() =>
              participantType !== 'INDIVIDUAL'
                ? onDeleteTeamMember(
                    participant.teamId,
                    participant.participantId,
                    participant.eventId
                  )
                : onDeleteIndividual(participant.eventId, participant.participantId)
            }
          >
            Delete
          </ModalButton>
        </ButtonWrapper>
      </>
    )}
  </Modal>
);

export default DeleteParticipant;
