import styled from 'styled-components/macro';

export const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin-left: 20px;
  }

  > *:first-child {
    margin-left: 0;
  }
`;
