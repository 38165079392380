import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../../../lib/Store';
import { UserState } from '../../../lib/Store/contexts/user/types';

import {
  CompanyName,
  CompanyInfoWrapper,
  ProfileContainer,
  BodyCopy,
  ProfileImage,
  CompanyImage
} from './Styles';
import { DEFAULT_PIC } from '../../../CONSTANTS';

const ProfileWidget: FC = () => {
  const {
    userState
  }: { userState: UserState } = useSelector(({ userState }: ApplicationState) => ({ userState }));

  return (
    <ProfileContainer>
      {userState.userCompany?.profileImage && (
        <CompanyImage alt="companyImage" width="90%" src={userState.userCompany?.profileImage} />
      )}
      <CompanyInfoWrapper>
        <CompanyName>{userState.userCompany?.name || 'Moving Mindz'}</CompanyName>
        <BodyCopy>{userState.userData.email}</BodyCopy>
      </CompanyInfoWrapper>
      {userState.userData.profileImage && (
        <ProfileImage
          alt=""
          width="95%"
          src={userState.userData.profileImage || DEFAULT_PIC}
          onError={() => DEFAULT_PIC}
        />
      )}
    </ProfileContainer>
  );
};

export default ProfileWidget;
