import React, { FC, MouseEvent } from 'react';
import { StyledButton } from './Styles';

export interface BackButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ViewButton: FC<BackButtonProps> = ({ onClick }) => {
  return <StyledButton onClick={onClick} />;
};

export default ViewButton;
