import React, { useEffect, FC } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../lib/Store';
import { NoRecordsFound, PaginatedTable } from '../../../../components/UI';
import {
  ParticipantListView,
  PaginationFilter,
  PagedResponse,
  TableHeaderItem,
  OrderDirection,
  Permission,
  ParticipantFilter,
  RoleCode
} from '../../../../lib/Types';
import {
  loadPagedParticipantsNotRegistered,
  deleteIndividualParticipant,
  loadParticipant
} from '../../../../lib/Api/Participants';
import {
  setParticipantsFilter,
  setDeletingParticipant,
  setParticipant
} from '../../../../lib/Store/contexts/participants/actions';
import { initialState } from '../../../../lib/Store/contexts/participants/reducers';
import DeleteParticipant from '../DeleteParticipant';
import EditParticipant from '../EditParticipant';
import { eventGuy } from 'src/assets/icons';
import PaginatedTableSearch from 'src/components/UI/PaginatedTableSearch';

export interface ParticipantsNotRegisteredProps {
  pagedParticipantsNotRegistered: PagedResponse<ParticipantListView>;
  participant: ParticipantListView;
  permissions: Permission[];
  loading: boolean;
  deletingParticipant: boolean;
  filter: ParticipantFilter;
  editParticipant: (participantId: string) => void;
  setDeleting: (deletingParticipant: boolean) => void;
  setParticipant: (participant: ParticipantListView) => void;
  deleteParticipant: (eventId: number, participantId: string) => void;
  loadParticipantsNotRegistered: (filter: PaginationFilter, eventId: number) => void;
  loadParticipant: (participantId: string) => void;
  setFilter: (filter: ParticipantFilter) => void;
}

const ParticipantsNotRegistered: FC<ParticipantsNotRegisteredProps> = ({
  setFilter,
  filter,
  deletingParticipant,
  deleteParticipant,
  editParticipant,
  setDeleting,
  setParticipant,
  participant,
  loading,
  loadParticipant,
  pagedParticipantsNotRegistered,
  loadParticipantsNotRegistered,
  permissions
}) => {
  const { participantsFilter, companyAdminEvent, code } = useSelector(
    ({ participantsState, companyAdminEventState, permissionsState }: ApplicationState) => ({
      participantsFilter: participantsState.filter,
      companyAdminEvent: companyAdminEventState.currentEvent,
      code: permissionsState.code
    })
  );
  const { count } = pagedParticipantsNotRegistered;
  const headers: TableHeaderItem[] = [
    { display: 'Name', mapProp: 'fullName' },
    { display: 'Email', mapProp: 'email' },
    { display: 'Company', mapProp: 'companyName' }
  ];

  const onDelete = (participantId: string) => {
    loadParticipant(participantId);
    setDeleting(true);
  };

  const onDeleteCancel = () => {
    setParticipant(initialState.participant);
    setDeleting(false);
  };

  const onEdit = (participantId: string) => editParticipant(participantId);

  const setPage = (page: number) => setFilter({ ...filter, page });

  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    setFilter({ ...filter, orderColumn, orderDirection });

  const setKeyword = (keyword: string) => setFilter({ ...filter, keyword });

  useEffect(() => {
    if (code === RoleCode.CompanyAdmin && companyAdminEvent) {
      loadParticipantsNotRegistered(filter, companyAdminEvent.eventId);
    } else if (participantsFilter) {
      loadParticipantsNotRegistered(filter, participantsFilter.eventId);
    }
  }, [filter, code, companyAdminEvent, participantsFilter, loadParticipantsNotRegistered]);

  const ParticipantNotRegisteredListJSX = count ? (
    <>
      <PaginatedTable
        headers={headers}
        filter={filter}
        idProp={'participantId'}
        data={pagedParticipantsNotRegistered}
        loading={loading}
        onSetPage={setPage}
        onSort={setSort}
        onEdit={onEdit}
        onDelete={onDelete}
        actions={[
          { type: 'edit', show: permissions.includes(Permission.EDIT_EVENT_PARTICIPANTS) },
          code === RoleCode.MasterAdmin && {
            type: 'delete',
            show: permissions.includes(Permission.REMOVE_EVENT_PARTICIPANTS)
          }
        ]}
      />
    </>
  ) : (
    <>
      {loading ? (
        ''
      ) : (
        <>
          <NoRecordsFound
            icon={eventGuy}
            message="There are currently no participants not registered"
          />
        </>
      )}
    </>
  );

  return (
    <>
      {participant.participantId && !deletingParticipant ? (
        <EditParticipant />
      ) : (
        <>
          {deletingParticipant ? (
            <DeleteParticipant
              onCancel={onDeleteCancel}
              onDeleteIndividual={deleteParticipant}
              participant={participant}
              participantType={'INDIVIDUAL'}
              loading={loading}
            />
          ) : null}
          <PaginatedTableSearch onKeywordFilter={setKeyword} />
          {ParticipantNotRegisteredListJSX}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({
  participantsState,
  loadingState,
  permissionsState
}: ApplicationState) => ({
  participant: participantsState.participant,
  pagedParticipantsNotRegistered: participantsState.pagedParticipantsNotRegistered,
  deletingParticipant: participantsState.deletingParticipant,
  filter: participantsState.filter,
  loading: loadingState.apiCallsInProgress > 0,
  permissions: permissionsState.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setFilter: (filter: ParticipantFilter) => setParticipantsFilter(filter),
      editParticipant: (participantId: string) => loadParticipant(participantId),
      loadParticipant: (participantId: string) => loadParticipant(participantId),
      loadParticipantsNotRegistered: (filter: PaginationFilter) =>
        loadPagedParticipantsNotRegistered(filter),
      setDeleting: (deletingParticipant: boolean) => setDeletingParticipant(deletingParticipant),
      deleteParticipant: (eventId: number, participantId: string) =>
        deleteIndividualParticipant(eventId, participantId),
      setParticipant: (participant: ParticipantListView) => setParticipant(participant)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsNotRegistered);
