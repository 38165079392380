import React, { FC, useState, useEffect, useMemo } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import Tabs from '../../../../components/UI/Tabs';
import { PaginatedTable } from '../../../../components/UI';

import { LeaderboardTableContainer } from './Styles';

import { ApplicationState } from '../../../../lib/Store';
import {
  OrderDirection,
  TableHeaderItem,
  PaginationFilter,
  LeaderBoardTab
} from '../../../../lib/Types';
import {
  setPagedTeamRankFilter,
  setIndividualFilter,
  setActiveTab,
  setFilterByCompany
} from '../../../../lib/Store/contexts/participant/leaderBoard/actions';

interface LeaderboardTableProps extends RouteComponentProps {
  updateTeamFilter: (filter: PaginationFilter) => void;
  updateIndividualFilter: (filter: PaginationFilter) => void;
  updateActiveTab: (activeTab: LeaderBoardTab) => void;
  updateFilterByCompany: (filterByCompany: boolean) => void;
}

const LeaderboardTable: FC<LeaderboardTableProps> = ({
  history,
  updateTeamFilter,
  updateIndividualFilter,
  updateActiveTab,
  updateFilterByCompany
}) => {
  const Dispatch = useDispatch();
  const {
    pagedTeamRanks,
    filterByCompany,
    myTeamRank,
    individualRankings,
    ownRanking,
    pagedTeamRankFilter,
    activeTab,
    individualFilter,
    user,
    company,
    event,
    team,
    loading
  } = useSelector(
    ({
      participantLeaderBoardState,
      userState,
      participantTeamState,
      loadingState
    }: ApplicationState) => ({
      pagedTeamRanks: participantLeaderBoardState.pagedTeamRanks,
      myTeamRank: participantLeaderBoardState.myTeamRank,
      individualRankings: participantLeaderBoardState.individualRankings,
      ownRanking: participantLeaderBoardState.ownRanking,
      pagedTeamRankFilter: participantLeaderBoardState.pagedTeamRankFilter,
      activeTab: participantLeaderBoardState.activeTab,
      individualFilter: participantLeaderBoardState.individualFilter,
      filterByCompany: participantLeaderBoardState.filterByCompany,
      user: userState.userData,
      company: userState.userCompany,
      event: userState.userEvent,
      team: participantTeamState.team,
      loading: loadingState.apiCallsInProgress > 0
    })
  );
  const tabs: LeaderBoardTab[] = ['Overall', 'Movement', 'Connection', 'Individual'];
  const [headers, setHeaders] = useState<TableHeaderItem[]>([]);

  const primaryTabs = [company.name, event.name] as const; // declare as const context -> immutable literal type
  type PrimaryTab = typeof primaryTabs[number]; // primary filter can now only be one of primary tabs

  useEffect(() => {
    switch (activeTab) {
      case 'Overall':
        setHeaders([
          { display: 'Rank', mapProp: 'totalPointsRank', numbered: true },
          { display: 'Team Name', mapProp: 'teamName' },
          { display: 'Captain', mapProp: 'teamCaptain', dropable: true },
          { display: 'Organisation', mapProp: 'companyName', dropable: true },
          { display: 'Total Points', mapProp: 'totalPoints', numbered: true }
        ]);
        break;
      case 'Connection':
        setHeaders([
          { display: 'Rank', mapProp: 'connectionPointsRank', numbered: true },
          { display: 'Team Name', mapProp: 'teamName' },
          { display: 'Captain', mapProp: 'teamCaptain', dropable: true },
          { display: 'Organisation', mapProp: 'companyName', dropable: true },
          { display: 'Connection Points', mapProp: 'connectionPoints', numbered: true }
        ]);
        break;
      case 'Movement':
        setHeaders([
          { display: 'Rank', mapProp: 'movementPointsRank', numbered: true },
          { display: 'Team Name', mapProp: 'teamName' },
          { display: 'Captain', mapProp: 'teamCaptain', dropable: true },
          { display: 'Organisation', mapProp: 'companyName', dropable: true },
          { display: 'Points', mapProp: 'movementPoints', numbered: true },
          { display: 'Steps', mapProp: 'steps', numbered: true }
        ]);
        break;
      case 'Individual':
        setHeaders([
          { display: 'Rank', mapProp: 'totalPointsRank', numbered: true },
          { display: 'Full Name', mapProp: 'fullName' },
          { display: 'Team Name', mapProp: 'teamName', dropable: true },
          { display: 'Organisation', mapProp: 'companyName', dropable: true },
          { display: 'Total Points', mapProp: 'totalPoints' },
          {
            display: 'Connection Points',
            mapProp: 'connectionPoints',
            dropable: true
          },
          { display: 'Movement Points', mapProp: 'movementPoints', dropable: true }
        ]);
        break;
      default:
        setHeaders([
          { display: 'Rank', mapProp: 'totalPointsRank', numbered: true },
          { display: 'Team Name', mapProp: 'teamName' },
          { display: 'Captain', mapProp: 'teamCaptain', dropable: true },
          { display: 'Organisation', mapProp: 'companyName', dropable: true },
          { display: 'Total Points', mapProp: 'totalPoints', numbered: true }
        ]);
    }
  }, [activeTab]);

  const setPage = (page: number) => {
    if (activeTab !== 'Individual') Dispatch(updateTeamFilter({ ...pagedTeamRankFilter, page }));
    else Dispatch(updateIndividualFilter({ ...individualFilter, page }));
  };
  const setSort = (orderColumn: string, orderDirection: OrderDirection) => {
    if (activeTab !== 'Individual')
      Dispatch(updateTeamFilter({ ...pagedTeamRankFilter, orderColumn, orderDirection }));
    else Dispatch(updateIndividualFilter({ ...individualFilter, orderColumn, orderDirection }));
  };
  const onView = (teamId: string) => {
    history.push({
      pathname: '/Leaderboard/TeamLeaderboard',
      state: { teamId }
    });
  };

  const teamData = useMemo(() => {
    if (!pagedTeamRanks) return { results: [], count: 0 };
    const { results, count } = pagedTeamRanks;
    let data = results;
    if (team) {
      data = results.filter(e => e.teamId !== team.teamId);
      data.unshift(myTeamRank);
    }
    return { results: data, count };
  }, [pagedTeamRanks, myTeamRank, team]);

  const individualData = useMemo(() => {
    if (!individualRankings) return { results: [], count: 0 };
    const { results, count } = individualRankings;
    const data = results.filter(e => e.userId !== user.userId);
    data.unshift(ownRanking);
    return ownRanking ? { results: data, count } : { results: [], count: 0 };
  }, [individualRankings, ownRanking, user]);

  const handleSetPrimaryTab = (tab: PrimaryTab) => {
    updateFilterByCompany(tab === company.name);
  };

  const handleSetActiveTab = (tab: LeaderBoardTab) => {
    updateActiveTab(tab);
  };

  const multiCompanyEvent = event?.companyIdsInEvent?.length > 1;

  return (
    <>
      {multiCompanyEvent && (
        <Tabs
          tabs={primaryTabs.flat()}
          activeTab={filterByCompany ? primaryTabs[0] : primaryTabs[1]}
          setActiveTab={handleSetPrimaryTab}
          standAlone
        />
      )}
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={handleSetActiveTab} />
      <LeaderboardTableContainer>
        <PaginatedTable
          noTopLeftRadius
          headers={headers}
          filter={activeTab !== 'Individual' ? pagedTeamRankFilter : individualFilter}
          idProp="teamId"
          loading={loading}
          data={activeTab !== 'Individual' ? teamData : individualData}
          onView={onView}
          onSetPage={setPage}
          onSort={setSort}
          actions={activeTab !== 'Individual' ? [{ type: 'view', show: true }] : []}
          highlightFirstRow
          margin="0"
        />
      </LeaderboardTableContainer>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateTeamFilter: (filter: PaginationFilter) => setPagedTeamRankFilter(filter) as any,
      updateIndividualFilter: (filter: PaginationFilter) => setIndividualFilter(filter) as any,
      updateActiveTab: (activeTab: LeaderBoardTab) => setActiveTab(activeTab) as any,
      updateFilterByCompany: (filterByCompany: boolean) =>
        setFilterByCompany(filterByCompany) as any
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(LeaderboardTable));
