import React, { FC } from 'react';
import { ParticipantListView, FormikForm } from '../../../../../lib/Types';
import { ReplaceNewUserSchema } from '../../../validators/replaceNewUserSchema';
import ReplaceNewUserForm from '../ReplaceNewUserForm/ReplaceNewUserForm';
import LoadingIndicator from '../../../../../components/UI/LoadingIndicator';

interface ReplaceNewUserProps extends FormikForm<ReplaceNewUserSchema> {
  participant: ParticipantListView;
  loading: boolean;
  handleDropdownChange: (name: string, value: any) => void;
  onCancel: () => void;
}

const ReplaceNewUser: FC<ReplaceNewUserProps> = ({ loading, ...props }) => (
  <> {loading ? <LoadingIndicator /> : <ReplaceNewUserForm {...props} />}</>
);

export default ReplaceNewUser;
