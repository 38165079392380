import React, { FC } from 'react';
import { Column1, CreateTeamFormWrap, TopHd } from './Styles';

import {
  MainContentContainerPadded,
  DarkBlueHeaderL,
  DarkBlueBodyS,
  TurqText
} from '../../../../Themes';

import CreateTeamForm from './CreateTeamForm';
import { Company, CreateTeamResponse, Event, User } from '../../../../lib/Types';
import { CreateTeamSchema } from '../validators/CreateTeamSchema';

export interface CreateTeamProps {
  setCurrentStep: (num: number) => void;
  createTeam: (data: CreateTeamSchema) => CreateTeamResponse;
  user: User;
  company: Company;
  event: Event;
}

const CreateTeam: FC<CreateTeamProps> = ({ user, company, event, createTeam, setCurrentStep }) => {
  return (
    <>
      <MainContentContainerPadded>
        <TopHd>
          <Column1>
            <DarkBlueHeaderL>
              Create my team for <TurqText>{event.name}</TurqText>
            </DarkBlueHeaderL>
            <DarkBlueBodyS>
              {`Please add your team members' details below and we will send them an invite to join
                the platform and your team. Once submitted, you'll need to be approved by admin
                before you can compete.`}
            </DarkBlueBodyS>
          </Column1>
        </TopHd>

        <CreateTeamFormWrap>
          <CreateTeamForm
            user={user}
            company={company}
            createTeam={createTeam}
            setCurrentStep={setCurrentStep}
          />
        </CreateTeamFormWrap>
      </MainContentContainerPadded>
    </>
  );
};

export default CreateTeam;
