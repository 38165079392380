/* eslint-disable @typescript-eslint/camelcase */
import { PostCategory } from './lib/Types';

export const server = process.env.REACT_APP_API_URL;
export const PARTICIPANT_SIGNUP_URL = '/Register';
export const COMPANY_SITE_URL = 'https://www.movingmindz.co';
export const DEFAULT_PIC =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://firebasestorage.googleapis.com/v0/b/movingmindz-prod.appspot.com/o/profilePictures%2Fplaceholder_profilepic.png?alt=media&token=faf7a3c7-637f-4be4-9941-af34d5ae4705'
    : 'https://firebasestorage.googleapis.com/v0/b/movingmindz-uat.appspot.com/o/profilePictures%2Fplaceholder_profilepic.png?alt=media&token=75eb39ea-5149-4cfb-98ac-ba242536eecf';

export const Pearson_preEventSurveyURL = 'https://movingmindz.typeform.com/to/UOvecay9';
export const Shine_preEventSurveyURL = 'https://form.typeform.com/to/zv7LI3v0';

// Media Sizes
export const MAX_IMAGE_SIZE = 10000000; // 10MB
export const MAX_IMAGE_SIZE_LABEL = '10MB';
export const MAX_VIDEO_SIZE = 100000000; // 100MB
export const MAX_VIDEO_SIZE_LABEL = '100MB';

export const DROPDOWN_MENU = {
  systemadmin: {
    nav: [
      { name: 'Dashboard', path: '/Dashboard' },
      { name: 'Companies', path: '/Companies' },
      { name: 'Participants', path: '/Participants' },
      { name: 'Teams', path: '/Teams' },
      { name: 'Create Posts', path: '/CreatePost' },
      { name: 'Rewards', path: '/Rewards' },
      { name: 'Events', path: '/Events' },
      { name: 'Reports', path: '/Reports' }
    ]
  },
  companyadmin: {
    nav: [
      { name: 'Dashboard', path: '/Dashboard' },
      { name: 'Participants', path: '/Participants' },
      { name: 'Teams', path: '/Teams' },
      { name: 'Create Posts', path: '/CreatePost' }
    ]
  }
};

export interface TimeOption {
  id: number;
  value: string;
}

export const timeOptions: TimeOption[] = [
  { id: 1, value: '00:00' },
  { id: 2, value: '00:30' },
  { id: 3, value: '01:00' },
  { id: 4, value: '01:30' },
  { id: 5, value: '02:00' },
  { id: 6, value: '02:30' },
  { id: 7, value: '03:00' },
  { id: 8, value: '03:30' },
  { id: 9, value: '04:00' },
  { id: 10, value: '04:30' },
  { id: 11, value: '05:00' },
  { id: 12, value: '05:30' },
  { id: 13, value: '06:00' },
  { id: 14, value: '06:30' },
  { id: 15, value: '07:00' },
  { id: 16, value: '07:30' },
  { id: 17, value: '08:00' },
  { id: 18, value: '08:30' },
  { id: 19, value: '09:00' },
  { id: 20, value: '09:30' },
  { id: 21, value: '10:00' },
  { id: 22, value: '10:30' },
  { id: 23, value: '11:00' },
  { id: 24, value: '11:30' },
  { id: 25, value: '12:00' },
  { id: 26, value: '12:30' },
  { id: 27, value: '13:00' },
  { id: 28, value: '13:30' },
  { id: 29, value: '14:00' },
  { id: 30, value: '14:30' },
  { id: 31, value: '15:00' },
  { id: 32, value: '15:30' },
  { id: 33, value: '16:00' },
  { id: 34, value: '16:30' },
  { id: 35, value: '17:00' },
  { id: 36, value: '17:30' },
  { id: 37, value: '18:00' },
  { id: 38, value: '18:30' },
  { id: 39, value: '19:00' },
  { id: 40, value: '19:30' },
  { id: 41, value: '20:00' },
  { id: 42, value: '20:30' },
  { id: 43, value: '21:00' },
  { id: 44, value: '21:30' },
  { id: 45, value: '22:00' },
  { id: 46, value: '22:30' },
  { id: 47, value: '23:00' },
  { id: 48, value: '23:30' }
];

export const tzStrings = [
  { label: 'GMT - (GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
  { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
  { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
  { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
  { label: '(GMT-08:00) Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
  { label: '(GMT-08:00) Tijuana, Baja California', value: 'America/Tijuana' },
  { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
  { label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', value: 'America/Chihuahua' },
  { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain' },
  { label: '(GMT-06:00) Central America', value: 'America/Managua' },
  { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
  { label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey', value: 'America/Mexico_City' },
  { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
  { label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: 'America/Bogota' },
  { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
  { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
  { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
  { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
  { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
  { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
  { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
  { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
  { label: '(GMT-03:00) Buenos Aires, Georgetown', value: 'America/Argentina/Buenos_Aires' },
  { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
  { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
  { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
  { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
  { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
  { label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', value: 'Africa/Casablanca' },
  {
    label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
    value: 'Etc/Greenwich'
  },
  {
    label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: 'Europe/Amsterdam'
  },
  {
    label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    value: 'Europe/Belgrade'
  },
  { label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', value: 'Europe/Brussels' },
  { label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: 'Europe/Sarajevo' },
  { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
  { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
  { label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: 'Europe/Athens' },
  { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
  { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
  { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
  { label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', value: 'Europe/Helsinki' },
  { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
  { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
  { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
  { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
  { label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: 'Europe/Moscow' },
  { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
  { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
  { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
  { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
  { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
  { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
  { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
  { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
  { label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: 'Asia/Karachi' },
  { label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: 'Asia/Calcutta' },
  { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
  { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
  { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
  { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
  { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
  { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
  { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
  { label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: 'Asia/Hong_Kong' },
  { label: '(GMT+08:00) Kuala Lumpur, Singapore', value: 'Asia/Kuala_Lumpur' },
  { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
  { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
  { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
  { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
  { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
  { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
  { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
  { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
  { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
  { label: '(GMT+10:00) Canberra, Melbourne, Sydney', value: 'Australia/Canberra' },
  { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
  { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
  { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
  { label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: 'Asia/Magadan' },
  { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
  { label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: 'Pacific/Fiji' },
  { label: "(GMT+13:00) Nuku'alof", value: 'Pacific/Tongatapu' }
];

export const POST_CATEGORY_DISPLAY: { [key: string]: string } = {
  [PostCategory.GENERAL]: 'General',
  [PostCategory.DAILY_ACTIVITY]: 'Daily Activity',
  [PostCategory.MINI_CHALLENGE]: 'Mini Challenge'
};
