import React, { FC } from 'react';

import EditProfileForm from './components/EditProfileForm';
import { EmployeePageWrapper } from '../../components/UI';

import { PageContent } from '../../Themes';
import { EditProfileWrapper, Title } from './Styles';

import { IgnoreHoldingStateCondition } from '../../lib/Types';

export interface EditProfileProps {}

const EditProfile: FC<EditProfileProps> = () => (
  <EmployeePageWrapper ignoreHoldingState={IgnoreHoldingStateCondition.ALWAYS}>
    <PageContent>
      <Title>My Details</Title>
      <EditProfileWrapper>
        <EditProfileForm />
      </EditProfileWrapper>
    </PageContent>
  </EmployeePageWrapper>
);

export default EditProfile;
