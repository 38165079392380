import React, { FC, MouseEvent } from 'react';

import ActionButton from './ActionButton';

import { TableAction } from '../../../../lib/Types';
import { Container } from './Styles';
import { theme } from '../../../../theme';
import { trashCan, thumbsUp, tickGreen, pencil, searchIcon } from '../../../../assets/icons';

export interface TableActionsProps {
  actions: TableAction[];
  onEdit: (e: MouseEvent) => void;
  onView: (e: MouseEvent) => void;
  onDelete: (e: MouseEvent) => void;
  onApprove: (e: MouseEvent) => any;
  onReject: (e: MouseEvent) => any;
  approved?: boolean;
  rejected?: boolean;
}

const TableActions: FC<TableActionsProps> = ({
  actions,
  onEdit,
  onView,
  onDelete,
  onApprove,
  onReject,
  approved,
  rejected
}) => {
  const renderButton = ({ type, show }: TableAction, key: number) => {
    if (!show) return null;

    switch (type) {
      case 'edit':
        return (
          <ActionButton
            key={key}
            onClick={onEdit}
            icon={pencil}
            backgroundColour={theme.primary.paleBlue}
            darkText
          >
            Edit
          </ActionButton>
        );
      case 'view':
        return (
          <ActionButton
            key={key}
            onClick={onView}
            backgroundColour={theme.primary.paleBlue}
            icon={searchIcon}
            darkText
          >
            View
          </ActionButton>
        );
      case 'delete':
        return <ActionButton key={key} onClick={onDelete} icon={trashCan} />;
      case 'approve':
        // handle approved state
        return (
          <ActionButton
            key={key}
            onClick={onApprove}
            backgroundColour={!approved && theme.status.danger}
            icon={approved ? tickGreen : thumbsUp}
            border={approved && `1px solid ${theme.primary.mediumGreen}`}
            textColourOverride={approved && theme.primary.mediumGreen}
            maxWidth="110px"
            disabled={approved}
          >
            {approved ? 'Approved' : 'Approve'}
          </ActionButton>
        );
      case 'reject':
        // handle rejected state
        return (
          <ActionButton
            key={key}
            onClick={onReject}
            backgroundColour={theme.status.danger}
            icon={thumbsUp}
            disabled={rejected}
            opaque={rejected}
            iconInverted
            maxWidth="110px"
          >
            {rejected ? 'Rejected' : 'Reject'}
          </ActionButton>
        );
    }
  };

  return <Container>{actions.map((action, key) => renderButton(action, key))}</Container>;
};

export default TableActions;
