import * as yup from 'yup';

const signupCompanyAdminSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  phone: yup
    .string()
    .trim()
    .required('Contact Phone is required.'),
  password: yup
    .string()
    .trim()
    .matches(/^.*[0-9].*$/, 'Password must contain 1 number.')
    .matches(/^.*[A-Z].*$/, 'Password must contain 1 capital letter.')
    .min(8, 'Password must be greater than 8 characters.')
    .max(16, 'Password must be less than 16 characters.')
    .notRequired(),
  companyId: yup.number().required()
});

export type SignupCompanyAdminSchema = yup.InferType<typeof signupCompanyAdminSchema>;

export default signupCompanyAdminSchema;
