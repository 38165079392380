import React, { useEffect, useRef, useMemo } from 'react';
import moment from 'moment-timezone';

import Comment from './Comment';
import ContextMenu from './ContextMenu';
import CommentForm from './CommentForm';
import AudioPlayer from '../AudioPlayer';
import VideoPlayer from '../VideoPlayer';
import { isPinned as checkPinned } from '../../../lib/Store/contexts/posts/utils';

import { postTypeIcon } from '../../../lib/Utils';
import { commentOutline, heartOutline, heartFilled, pin } from '../../../assets/icons/index';

import {
  PostBody,
  PostCard,
  PostCommentButton,
  PostContent,
  PostDetails,
  PostHeaderText,
  PostMedia,
  PostMediaImage,
  PostMessage,
  PostOptionsColumn,
  PostSocial,
  PostTimestamp,
  PostTitle,
  PostProfileImage,
  PostSocialLabel,
  PostChallengeText,
  PostLink,
  PinImage,
  WebLink
} from './Styles';

import {
  Post,
  RoleCode,
  User
} from '../../../lib/Types';

interface PostProps {
  user: User;
  post: Post;
  onLike: () => void;
  onReplyLike?: (replyPostId: number) => void;
  onDelete?: () => void;
  onReplyDelete?: (replyPostId?: number) => void;
  onNewReply?: (reply: Post) => void;
  showComments?: boolean;
  setReportModalVisible?: (visible: boolean) => void;
  setReportingPostId?: (postId: number) => void;
  maxWidth?: boolean;
  hidePin?: boolean;
}

const PostComponent: React.FC<PostProps> = ({
  user: currentUser,
  post,
  onLike,
  onReplyLike,
  onDelete,
  onReplyDelete,
  onNewReply,
  showComments,
  setReportModalVisible,
  setReportingPostId,
  maxWidth = false,
  hidePin = false
}) => {
  const pageBottom = useRef<HTMLDivElement>(null);

  const {
    user,
    comments,
    date,
    eventDay,
    title,
    message,
    likes,
    liked,
    type,
    challengeText,
    image,
    video,
    audio,
    replies,
    postId,
    link,
    eventId
  } = post;
  const isPinned = useMemo(() => checkPinned(post), [post]);

  const handleReport = (postId: number) => {
    setReportModalVisible(true);
    setReportingPostId(postId);
  };

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: pageBottom?.current?.offsetTop,
      behavior: 'smooth'
    });
  }, [comments]);

  const LikeButton = <img onClick={onLike} src={liked ? heartFilled : heartOutline} alt="Likes" />;

  const postTimeStamp = (
    <PostTimestamp>
      <h4>{moment(date, 'x').fromNow()}</h4>
    </PostTimestamp>
  );

  const getPostTitle = () => {
    if (type === 'General') {
      if (post.user.role.code === RoleCode.MasterAdmin) return user.firstName;
      return `${user.firstName} ${user.lastName}`;
    }
    return type;
  }

  return (
    <PostCard maxWidth>
      <PostProfileImage
        src={type === 'General' ? user.profileImage : postTypeIcon(type)}
        alt={type === 'General' ? `${user.firstName} ${user.lastName}` : type}
      />
      <PostBody>
        <PostDetails>
          <PostHeaderText type={type}>
            {getPostTitle()}
          </PostHeaderText>
          {!hidePin && !isPinned && !eventDay
            ? postTimeStamp
            : hidePin && isPinned && !eventDay
            ? postTimeStamp
            : hidePin && !isPinned
            ? postTimeStamp
            : null}{' '}
          {/* This Post component is being used in two locations, Pinned post data is only required in one. This multi level ternary decides whether to display the postTimeStamp*/}
          {!hidePin && isPinned && <PinImage src={pin} alt="Pin" />}
        </PostDetails>
        <PostContent>
          <PostMedia>
            {image && <PostMediaImage src={image} alt={title} />}
            {video && <VideoPlayer video={video} />}
            {audio && <AudioPlayer audioURI={audio} />}
          </PostMedia>
          <PostTitle>{title}</PostTitle>
          <PostMessage>{message}</PostMessage>
          <WebLink href={link} target="_blank">
            {link}
          </WebLink>
          {!showComments && (
            <PostLink to={`/WaterCooler/Post/${postId}`}>
              <PostCommentButton variant="clear" postType={type}>
                {challengeText ? (
                  <span>
                    <b>Bonus reward:</b> {challengeText}
                  </span>
                ) : (
                  'Add a comment'
                )}
              </PostCommentButton>
            </PostLink>
          )}
        </PostContent>
        {showComments ? (
          <>
            <PostChallengeText postType={type}>
              <span>
                <b>Bonus reward:</b> {challengeText}
              </span>
            </PostChallengeText>
            <PostSocial>
              {LikeButton}
              <span>{`${likes} Like${likes !== 1 ? 's' : ''}`}</span>
            </PostSocial>
            <h4>{`${comments || 0} Comment${comments !== 1 ? 's' : ''}`}</h4>
          </>
        ) : (
          <PostSocial>
            <PostSocialLabel>
              {LikeButton}
              <span>{`${likes} Like${likes !== 1 ? 's' : ''}`}</span>
            </PostSocialLabel>
            <PostLink to={`/WaterCooler/Post/${postId}`}>
              <PostSocialLabel>
                <img src={commentOutline} alt="Comments" />
                <span>{`${comments || 0} Replies`}</span>
              </PostSocialLabel>
            </PostLink>
          </PostSocial>
        )}
        {showComments && (
          <>
            <CommentForm
              profileImage={currentUser.profileImage}
              title="Add your comment"
              parentPostId={postId}
              userId={user.userId}
              onSuccess={onNewReply}
              isReply
              eventId={eventId}
            />
            <div>
              {replies.map(reply => (
                <Comment
                  key={reply.postId}
                  post={reply}
                  onLike={() => onReplyLike(reply.postId)}
                  onDelete={() => {
                    reply.user.userId === currentUser.userId && onReplyDelete(reply.postId);
                  }}
                  handleReport={handleReport}
                />
              ))}
            </div>
            <div ref={pageBottom} />
          </>
        )}
      </PostBody>
      {!maxWidth && (
        <PostOptionsColumn>
          <ContextMenu handleDelete={onDelete} handleReport={() => handleReport(postId)} />
        </PostOptionsColumn>
      )}
    </PostCard>
  );
};

export default PostComponent;
