import * as yup from 'yup';

const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email()
    .required()
});

export default forgotPasswordSchema;
