import React, { FC } from 'react';

import { centsToDollars } from '../../../lib/Utils';

import { DarkBlueBodyM, DarkBlueHeaderM } from '../../../Themes';
import { Wrapper, SummaryRow, Amount, Title, Value, Divider } from './Styles';

export interface PaymentSummaryProps {
  eventTitle: string;
  eventPrice: number;
  participantAllotment: number;
  paidForCurrent: number;
}

const PaymentSummary: FC<PaymentSummaryProps> = ({
  eventPrice,
  eventTitle,
  participantAllotment,
  paidForCurrent
}) => {
  const participantDelta = Math.max(0, participantAllotment - paidForCurrent);
  const itemsToPurchase = paidForCurrent ? participantDelta : participantAllotment;
  const totalValue = Math.max(0, eventPrice * itemsToPurchase);

  return (
    <Wrapper>
      <DarkBlueHeaderM>Payment Summary</DarkBlueHeaderM>
      <Divider margin="1rem 0 0 0" />
      {/*This renders a row which shows the number of already purchased participant slots*/}
      {paidForCurrent > 0 && (
        <SummaryRow>
          <Amount>
            <DarkBlueBodyM>{paidForCurrent}x</DarkBlueBodyM>
          </Amount>
          <Title>
            <DarkBlueBodyM>{eventTitle}</DarkBlueBodyM>
          </Title>
          <Value>
            <DarkBlueBodyM>(Paid)</DarkBlueBodyM>
          </Value>
        </SummaryRow>
      )}
      {/*This renders a row which shows the number of participant slots which will be purchased, and the cost per registration*/}
      <SummaryRow>
        <Amount>
          <DarkBlueBodyM>{itemsToPurchase}x</DarkBlueBodyM>
        </Amount>
        <Title>
          <DarkBlueBodyM>{eventTitle}</DarkBlueBodyM>
        </Title>
        <Value>
          <DarkBlueBodyM>{centsToDollars(eventPrice)} AUD</DarkBlueBodyM>
        </Value>
      </SummaryRow>
      <Divider />
      {/*This renders a row which shows the total cost of the new participant slots */}
      <SummaryRow>
        <Amount>
          <DarkBlueHeaderM fontStyle="none">Total</DarkBlueHeaderM>
        </Amount>
        <Title>
          <DarkBlueHeaderM />
        </Title>
        <Value>
          <DarkBlueHeaderM fontStyle="none">{centsToDollars(totalValue)} AUD</DarkBlueHeaderM>
        </Value>
      </SummaryRow>
      <Divider margin="0 0 3rem 0" />
    </Wrapper>
  );
};

export default PaymentSummary;
