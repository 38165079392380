import * as yup from 'yup';

const userProfileSchema = yup.object({
  profileImage: yup.string(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  teamName: yup.string().nullable(true),
  userDemographics: yup.array().of(
    yup.object({
      companyDemographicId: yup.number().required(),
      userDemographicId: yup.number().nullable(true),
      childDemographicId: yup.number().when('childDemographicAnswer', {
        is: val => val !== null, // alternatively: (val) => val == true
        then: yup.number().nullable(true),
        otherwise: yup.number().required('This Demographic is required.')
      }),
      childDemographicAnswer: yup.string().nullable(true)
    })
  ),
  oldPassword: yup.string(),
  newPassword: yup
    .string()
    .trim()
    .matches(/^.*[0-9].*$/, 'Password must contain 1 number.')
    .matches(/^.*[A-Z].*$/, 'Password must contain 1 capital letter.')
    .min(8, 'Password must be greater than 8 characters.')
    .max(16, 'Password must be less than 16 characters.')
});

export type UserProfileSchema = yup.InferType<typeof userProfileSchema>;

export default userProfileSchema;
