import * as yup from 'yup';

const abuseReportSchema = yup.object({
  postId: yup.number().required(),
  reason: yup.string().required('You must select a reason!')
});

export type AbuseReportSchema = yup.InferType<typeof abuseReportSchema>;

export default abuseReportSchema;
