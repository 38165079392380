import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmployeePageWrapper } from '../../components/UI';
import YourStats from '../../components/UI/YourStats';
import DashboardStepsLineChart from '../../components/UI/DashboardStepsLineChart';
import MyTeam from '../../components/UI/MyTeam';

import { PageContent } from '../../Themes';
import { MyStatsContainer, Title, CardRow } from './Styles';
import { loadMyTeamStats } from 'src/lib/Api/Stats';
import { ApplicationState } from 'src/lib/Store';
import { APIThunkDispatch } from '../../lib/Types';

const MyStats = () => {
  const dispatch: APIThunkDispatch = useDispatch();
  const {
    participantTeamState: { team },
    userState: {
      userEvent: { eventId }
    }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    dispatch(loadMyTeamStats());
  }, [team, eventId, loadMyTeamStats]);

  return (
    <EmployeePageWrapper holdingPageTitle="My Stats">
      <PageContent>
        <Title>My Stats</Title>
        <MyStatsContainer>
          <CardRow>
            <YourStats animationDelay={0.7} />
          </CardRow>
          <CardRow>
            <DashboardStepsLineChart />
          </CardRow>
          <CardRow>
            <MyTeam />
          </CardRow>
        </MyStatsContainer>
      </PageContent>
    </EmployeePageWrapper>
  );
};

export default MyStats;
