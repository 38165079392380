import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';

import { loadCompanyOptions, LoadCompanyOptions } from '../../../lib/Api/Company';

import { RadioGroup, MultiDropdown } from '../../../components/UI';

import { InputLabel, DropdownWrapper, InfoText } from './Styles';

import { DropdownOption } from '../../../components/UI/MultiDropdown/MultiDropdown';
import { ApplicationState } from '../../../lib/Store';

interface ChooseCompaniesProps {
  eventId: number;
  onLoadCompanyIds: number[];
  onChange: (companyIds: number[]) => void;
  fetchCompanyOptions: LoadCompanyOptions;
}

const ChooseCompanies: React.FC<ChooseCompaniesProps> = ({
  eventId,
  onLoadCompanyIds,
  onChange,
  fetchCompanyOptions
}) => {
  const { companyOptions, loading } = useSelector(
    ({ companyState, loadingState }: ApplicationState) => ({
      companyOptions: companyState.companyOptions,
      loading: loadingState.apiCallsInProgress > 0
    })
  );

  const [selectingCompanies, setSelectingCompanies] = useState(
    onLoadCompanyIds?.length !== companyOptions?.length
  );

  useEffect(() => {
    if (onLoadCompanyIds?.length) return;
    (async () => {
      const { data } = await fetchCompanyOptions(eventId);
      if (data && data.length) {
        if (selectingCompanies) {
          onChange([]);
        } else {
          onChange(data.map(c => c.companyId)); // Default set all companies
        }
      }
    })();
  }, [eventId]);

  if (loading) return <InfoText>Loading ...</InfoText>;

  if (companyOptions.length) {
    return (
      <>
        <RadioGroup
          initialValue={selectingCompanies ? 'SPECIFIC ORGANISATIONS' : 'ALL ORGANISATIONS'}
          columns={1}
          valueKey="value"
          options={[
            {
              label: 'ALL ORGANISATIONS',
              value: 'All'
            },
            {
              label: 'SPECIFIC ORGANISATIONS',
              value: 'Specific'
            }
          ]}
          handleOptionClick={(field, value) => {
            setSelectingCompanies(value !== 'All');
            if (value === 'All') {
              onChange(companyOptions.map(v => v.companyId));
            } else {
              onChange([]);
            }
          }}
        />
        {selectingCompanies && (
          <DropdownWrapper>
            <InputLabel>
              Which Organisations in this event would you like to see this post?
            </InputLabel>
            <MultiDropdown
              id="companyIds"
              multi
              tickSelected
              searchable
              linePadding="5px"
              options={companyOptions.map(co => {
                return { value: co.companyId.toString(), label: co.name };
              })}
              onLoadOptions={
                onLoadCompanyIds.length
                  ? companyOptions
                      .filter(c => onLoadCompanyIds.includes(c.companyId))
                      .map(co => {
                        return { value: co.companyId.toString(), label: co.name };
                      })
                  : []
              }
              placeholder="Companies"
              onOptionClick={(values: DropdownOption[]) => onChange(values.map(v => +v.value))}
            />
          </DropdownWrapper>
        )}
      </>
    );
  }

  return <InfoText>There are no companies in this event</InfoText>;
};

export default connect(null, dispatch =>
  bindActionCreators(
    {
      fetchCompanyOptions: (eventId: number) => loadCompanyOptions(eventId) as any
    },
    dispatch
  )
)(ChooseCompanies);
