import styled from 'styled-components/macro';

export const Container = styled.div`
  width: calc(100% - 10vw);
  margin-right: 10vw;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    width: 100%;
    margin-right: 0;
    padding: 0 2rem 0;
  }
`;

export const LoaderContainer = styled.div`
  height: 25rem;
  width: calc(100% - 10vw);
  display: flex;
  align-items: center;
  justify-content: center;
`;
