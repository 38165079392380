import styled from 'styled-components/macro';
import theme from '../../../theme';

export const GlobalWrap = styled.div`
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  display: flex;
  padding: ${({ theme }) => theme.pad.xsm}% ${({ theme }) => theme.pad.xsm * 1.5}% 0;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    padding: 0;
  }
`;

export const PageWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const SideBarWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: min-content;
  margin-right: 3%;
  @media (max-width: ${theme.device.tablet}px) {
    margin-right: 0;
  }
`;

export const MainWrap = styled.div`
  height: auto;
  width: calc(100% - 50rem);
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const HeaderWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-bottom: calc(${({ theme }) => theme.pad.xsm}%);
  z-index: 3;
`;

export const ContentWrap = styled.div`
  width: 100%;
  height: auto;
  z-index: -1;
`;

export const FooterWrap = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  justify-content: center;
  padding: ${({ theme }) => theme.pad.xsm}%;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const CopyrightText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1rem;
  margin-top: 10px;
  color: ${({ theme }) => theme.primary.dark};
  opacity: ${({ theme }) => theme.opacity.half};
`;
