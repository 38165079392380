import { combineReducers } from 'redux';

// Master Admin
import {
  DashboardState as MasterAdminDashboardState,
  DashboardActionTypes as MasterAdminDashboardActionTypes
} from './contexts/masterAdmin/dashboard/types';
import masterAdminDashboardReducer from './contexts/masterAdmin/dashboard/reducers';
import {
  EventState as MasterAdminEventState,
  EventActionTypes as MasterAdminEventActionTypes
} from './contexts/masterAdmin/event/types';
import masterAdminEventReducer from './contexts/masterAdmin/event/reducers';

// Company Admin
import {
  EventState as CompanyAdminEventState,
  EventActionTypes as CompanyAdminActionTypes
} from './contexts/companyAdmin/event/types';
import companyAdminEventReducer from './contexts/companyAdmin/event/reducers';
import {
  CompanyState as CompanyAdminCompanyState,
  CompanyActionTypes as CompanyAdminCompanyActionTypes
} from './contexts/companyAdmin/company/types';
import companyAdminCompanyReducer from './contexts/companyAdmin/company/reducers';

// Participant
import {
  DashboardState as ParticipantDashboardState,
  DashboardActionTypes as ParticipantDashboardActionTypes
} from './contexts/participant/dashboard/types';
import participantDashboardReducer from './contexts/participant/dashboard/reducers';
import {
  EventState as ParticipantEventState,
  EventActionTypes as ParticipantEventActionTypes
} from './contexts/participant/event/types';
import participantEventReducer from './contexts/participant/event/reducers';
import {
  TeamState as ParticipantTeamState,
  TeamActionTypes as ParticipantTeamActionTypes
} from './contexts/participant/team/types';
import participantTeamReducer from './contexts/participant/team/reducers';
import {
  LeaderBoardState as ParticipantLeaderBoardState,
  LeaderBoardActionTypes as ParticipantLeaderBoardActionTypes
} from './contexts/participant/leaderBoard/types';
import participantLeaderBoardReducer from './contexts/participant/leaderBoard/reducers';

import { LoadingState, LoadingActionTypes } from './contexts/loading/types';
import loadingReducer from './contexts/loading/reducers';
import { CompanyState, CompanyActionTypes } from './contexts/company/types';
import companyReducer from './contexts/company/reducers';
import { NotificationState, NotificationActionTypes } from './contexts/notification/types';
import notificationReducer from './contexts/notification/reducers';
import { PermissionsState, PermissionsActionTypes } from './contexts/permissions/types';
import permissionReducer from './contexts/permissions/reducers';
import { EventState, EventActionTypes } from './contexts/event/types';
import eventReducer from './contexts/event/reducers';
import { ActivityTypeState, ActivityTypeActionTypes } from './contexts/activityType/types';
import activityTypeReducer from './contexts/activityType/reducers';
import { StatsState, StatsActionTypes } from './contexts/stats/types';
import statsReducer from './contexts/stats/reducers';

// old participants store (contains data for all 3 user types -> TODO: separate)
import { ParticipantsState, ParticipantActionTypes } from './contexts/participants/types';
import participantReducer from './contexts/participants/reducers';

import { TeamsState, TeamActionTypes } from './contexts/team/types';
import teamReducer from './contexts/team/reducers';
import { PostsState, PostsActionTypes } from './contexts/posts/types';
import postsReducer from './contexts/posts/reducers';
import { UserState, UserActionTypes } from './contexts/user/types';
import userReducer from './contexts/user/reducers';
import {
  TermsAndConditionsState,
  TermsAndConditionsActionTypes
} from './contexts/termsAndConditions/types';
import termsAndConditionsReducer from './contexts/termsAndConditions/reducers';
import { RewardsState, RewardsActionTypes } from './contexts/rewards/types';
import rewardsReducer from './contexts/rewards/reducers';
import { SyncServiceState, SyncServiceActionTypes } from './contexts/syncService/types';
import syncServiceReducer from './contexts/syncService/reducers';
import { ReportState, ReportActionTypes } from './contexts/report/types';
import reportReducer from './contexts/report/reducers';
import { SDAState, SDAActionTypes } from './contexts/suspectDailyActivity/types';
import SDAReducer from './contexts/suspectDailyActivity/reducers';
import { EventCompanyPageFilterState } from './contexts/eventCompanyPageFilter/actions';
import eventCompanyPageFilterReducer from './contexts/eventCompanyPageFilter/reducers';
import { EventCompanyPageFilterActionTypes } from './contexts/eventCompanyPageFilter/types';

export interface ApplicationState {
  // Mixed Admins (Used by both)
  eventCompanyPageFilterState: EventCompanyPageFilterState;

  // Master Admin
  masterAdminDashboardState: MasterAdminDashboardState;
  masterAdminEventState: MasterAdminEventState;

  // Company Admin
  companyAdminEventState: CompanyAdminEventState;
  companyAdminCompanyState: CompanyAdminCompanyState;

  // Participant
  participantDashboardState: ParticipantDashboardState;
  participantEventState: ParticipantEventState;
  participantTeamState: ParticipantTeamState;
  participantLeaderBoardState: ParticipantLeaderBoardState;

  teamsState: TeamsState;
  SDAState: SDAState;
  loadingState: LoadingState;
  participantsState: ParticipantsState;
  companyState: CompanyState;
  notificationState: NotificationState;
  permissionsState: PermissionsState;
  eventState: EventState;
  activityTypeState: ActivityTypeState;
  postsState: PostsState;
  userState: UserState;
  statsState: StatsState;
  syncServiceState: SyncServiceState;
  termsAndConditionsState: TermsAndConditionsState;
  rewardsState: RewardsState;
  reportState: ReportState;
}

const appReducer = combineReducers<ApplicationState>({
  // Mixed Admin (Used by both)
  eventCompanyPageFilterState: eventCompanyPageFilterReducer,

  // Master Admin
  masterAdminDashboardState: masterAdminDashboardReducer,
  masterAdminEventState: masterAdminEventReducer,

  // Company Admin
  companyAdminEventState: companyAdminEventReducer,
  companyAdminCompanyState: companyAdminCompanyReducer,

  // Participant
  participantDashboardState: participantDashboardReducer,
  participantEventState: participantEventReducer,
  participantTeamState: participantTeamReducer,
  participantLeaderBoardState: participantLeaderBoardReducer,

  loadingState: loadingReducer,
  teamsState: teamReducer,
  SDAState: SDAReducer,
  participantsState: participantReducer,
  companyState: companyReducer,
  notificationState: notificationReducer,
  permissionsState: permissionReducer,
  eventState: eventReducer,
  activityTypeState: activityTypeReducer,
  userState: userReducer,
  termsAndConditionsState: termsAndConditionsReducer,
  postsState: postsReducer,
  rewardsState: rewardsReducer,
  syncServiceState: syncServiceReducer,
  statsState: statsReducer,
  reportState: reportReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'REMOVE_USER') {
    window.localStorage.clear();
    state = undefined;
  }

  return appReducer(state, action);
};

export type AppActions =
  // Mixed Admin (used by both)
  | EventCompanyPageFilterActionTypes

  // Master Admin
  | MasterAdminDashboardActionTypes
  | MasterAdminEventActionTypes

  // Company Admin
  | CompanyAdminActionTypes
  | CompanyAdminCompanyActionTypes

  // Participant
  | ParticipantDashboardActionTypes
  | ParticipantEventActionTypes
  | ParticipantTeamActionTypes
  | ParticipantLeaderBoardActionTypes
  | TeamActionTypes
  | SDAActionTypes
  | ParticipantActionTypes
  | LoadingActionTypes
  | CompanyActionTypes
  | NotificationActionTypes
  | PermissionsActionTypes
  | EventActionTypes
  | ActivityTypeActionTypes
  | UserActionTypes
  | TermsAndConditionsActionTypes
  | StatsActionTypes
  | PostsActionTypes
  | RewardsActionTypes
  | SyncServiceActionTypes
  | ReportActionTypes;

export default rootReducer;
