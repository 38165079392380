import React, { FC } from 'react';
import { Group, Heading, Body } from '../Styles';
import { Input } from '../../../../../components/UI';
import theme from '../../../../../theme';
import { FormikFormComponent } from '../../../../../lib/Types';
import { EventSchema } from '../../../validators/eventSchema';

const TeamSize: FC<FormikFormComponent<EventSchema>> = ({ values, errors, handleChange }) => (
  <Group>
    <Heading>Team Size</Heading>
    <Body>
      This will set a standard team size across the whole event for all companies involved.
    </Body>
    <Input
      required
      id="teamSize"
      name="teamSize"
      value={values.teamSize}
      error={errors.teamSize}
      label="Team Size"
      type="teamSize"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
  </Group>
);

export default TeamSize;
