import {
  CompanyState,
  CompanyActionTypes,
  SET_COMPANIES,
  SET_COMPANY_OPTIONS,
  SET_COMPANY,
  SET_COMPANY_FILTER,
  SET_COMPANY_DEMOGRAPHICS,
  SET_COMPANY_LEADERBOARD,
  UPDATE_COMPANY,
  REMOVE_COMPANY,
  defaultCompanyListFilter
} from './types';

export const initialState: CompanyState = {
  companies: {
    results: [],
    count: 0
  },
  companyOptions: [],
  companyDemographics: [],
  company: {
    createdAt: null,
    updatedAt: null,
    companyId: null,
    name: '',
    active: false,
    profileImage: '',
    passCode: '',
    admins: [],
    allowManualPayment: false
  },
  filter: defaultCompanyListFilter,
  companyLeaderboard: []
};

const companyReducer = (
  state: CompanyState = initialState,
  action: CompanyActionTypes
): CompanyState => {
  switch (action.type) {
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.companies
      };
    case UPDATE_COMPANY:
      return { ...state, company: { ...state.company, ...action.companyUpdates } };
    case SET_COMPANY_DEMOGRAPHICS:
      return {
        ...state,
        companyDemographics: action.companyDemographics
      };
    case SET_COMPANY_OPTIONS:
      return {
        ...state,
        companyOptions: action.companyOptions
      };
    case SET_COMPANY_LEADERBOARD:
      return {
        ...state,
        companyLeaderboard: action.companyLeaderboard
      };
    case SET_COMPANY:
      return {
        ...state,
        company: action.company
      };
    case REMOVE_COMPANY:
      return {
        ...state,
        company: initialState.company
      };
    case SET_COMPANY_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    default:
      return state;
  }
};

export default companyReducer;
