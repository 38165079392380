import styled from 'styled-components/macro';
import { DarkBlueHeaderM, LightGreyText } from '../../../../../Themes';

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ORText = styled(LightGreyText)`
  font-size: 2.5rem;
  text-align: center;
  font-style: italic;
  margin: 3% 8% auto;
  letter-spacing: 0.1rem;
  white-space: nowrap;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.padding * 4}px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  padding: 1% 12% 1% 0;
`;

export const Group = styled.div`
  margin-bottom: ${({ theme }) => theme.padding}px;
  width: 100%;
`;

export const Heading = styled(DarkBlueHeaderM)`
  font-size: 1.7rem;
  margin: 2.5rem 0;
`;

export const StyledForm = styled.form`
  width: 100%;
`;
