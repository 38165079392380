import styled from 'styled-components/macro';
import { BrightBlueHeaderS, DarkBlueBodyS } from '../../../../Themes';
import { Paper } from '../../../../components/UI';

export const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 16px 6px 16px 6px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 6px 8px 6px;
`;

export const InnerCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  padding: 0 6px 0 6px;
  width: 55%;

  &:nth-child(1) {
    border-right: 1px solid lightgray;
    width: 45%;
  }
`;

export const BrightBlueHeader = styled(BrightBlueHeaderS)`
  padding: 0 0 0.8rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
`;

export const GenTeamsText = styled(DarkBlueBodyS)`
  font-size: 1.3rem;
  padding: 0 0 0.8rem;
  line-height: 1.6rem;
`;
