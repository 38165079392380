import {
  PagedResponse,
  TeamRankView,
  PaginationFilter,
  LeaderBoardTab,
  ParticipantRankView
} from '../../../../Types';

import {
  SetPagedTeamRankFilterAction,
  SetMyTeamRankAction,
  SetPagedTeamRanksAction,
  SetActiveTabAction,
  SetFilterByCompanyAction,
  SetIndividualRankingsAction,
  SetOwnRankingAction,
  SetIndividualFilterAction,
  SetWaterCoolerLeaderBoardFilterAction,
  SET_PAGED_TEAM_RANKS,
  SET_MY_TEAM_RANK,
  SET_PAGED_TEAM_RANK_FILTER,
  SET_ACTIVE_TAB,
  SET_FILTER_BY_COMPANY,
  SET_INDIVIDUAL_RANKINGS,
  SET_OWN_RANKING,
  SET_INDIVIDUAL_FILTER,
  SET_WATER_COOLER_LEADER_BOARD_FILTER
} from './types';

export const setPagedTeamRanks = (
  pagedTeamRanks: PagedResponse<TeamRankView>
): SetPagedTeamRanksAction => ({
  type: SET_PAGED_TEAM_RANKS,
  pagedTeamRanks
});

export const setMyTeamRank = (myTeamRank: TeamRankView): SetMyTeamRankAction => ({
  type: SET_MY_TEAM_RANK,
  myTeamRank
});

export const setPagedTeamRankFilter = (
  pagedTeamRankFilter: PaginationFilter
): SetPagedTeamRankFilterAction => ({
  type: SET_PAGED_TEAM_RANK_FILTER,
  pagedTeamRankFilter
});

export const setActiveTab = (activeTab: LeaderBoardTab): SetActiveTabAction => ({
  type: SET_ACTIVE_TAB,
  activeTab
});

export const setFilterByCompany = (filterByCompany: boolean): SetFilterByCompanyAction => ({
  type: SET_FILTER_BY_COMPANY,
  filterByCompany
});

export const setIndividualRankings = (
  individualRankings: PagedResponse<ParticipantRankView>
): SetIndividualRankingsAction => ({
  type: SET_INDIVIDUAL_RANKINGS,
  individualRankings
});

export const setOwnRanking = (ownRanking: ParticipantRankView): SetOwnRankingAction => ({
  type: SET_OWN_RANKING,
  ownRanking
});

export const setIndividualFilter = (
  individualFilter: PaginationFilter
): SetIndividualFilterAction => ({
  type: SET_INDIVIDUAL_FILTER,
  individualFilter
});

export const setWaterCoolerLeaderBoardFilter = (
  waterCoolerLeaderBoardFilter: PaginationFilter
): SetWaterCoolerLeaderBoardFilterAction => ({
  type: SET_WATER_COOLER_LEADER_BOARD_FILTER,
  waterCoolerLeaderBoardFilter
});

export default {
  setPagedTeamRanks,
  setMyTeamRank,
  setPagedTeamRankFilter,
  setActiveTab,
  setFilterByCompany,
  setIndividualRankings,
  setOwnRanking,
  setIndividualFilter,
  setWaterCoolerLeaderBoardFilter
};
