import React, { FC } from 'react';
import {
  MainContentContainerPadded,
  FlexRow,
  DarkBlueHeaderL,
  DarkBlueBodyS,
  LightGreyText
} from '../../../Themes';
import {
  SurveyLeft,
  SurveyMiddle,
  SurveyMiddleTextWrapper,
  SurveyBrightBlueHeaderL,
  SurveyRight,
  RightTextWrapper,
  Icon,
  UnfinishedText,
  FinishedText
} from './Styles';
import { measureIllustration } from '../../../assets/img';
import { icFlag, icTick } from '../../../assets/icons';
import Button from '../Button';

interface SurveyContainerProps {
  surveyName: string;
  surveyNamePrefix?: string;
  surveyNameColor?: string;
  surveyDescription?: string;
  middleContainerFlex?: number;
  showSurveyTimeToComplete?: boolean;
  showSurveyDaysLeft?: boolean;
  timeToComplete: number;
  surveyCompleted: boolean;
  daysLeft: number;
  onClick: () => void;
}

const DEFAULT_SURVEY_DESCRIPTION =
  'We want to know about you so we can benchmark where you are at the start of your experience with MovingMindz.';

const DEFAULT_SURVEY_NAME_PREFIX = 'Complete the ';

const SurveyContainer: FC<SurveyContainerProps> = ({
  surveyName,
  surveyNamePrefix = DEFAULT_SURVEY_NAME_PREFIX,
  surveyDescription = DEFAULT_SURVEY_DESCRIPTION,
  surveyNameColor = '',
  middleContainerFlex = 3,
  showSurveyTimeToComplete = false,
  showSurveyDaysLeft = false,
  timeToComplete,
  surveyCompleted,
  daysLeft,
  onClick
}) => {
  return (
    <MainContentContainerPadded marginTop={'20px'}>
      <FlexRow>
        <SurveyLeft>
          <img src={measureIllustration} />
        </SurveyLeft>
        <SurveyMiddle flex={middleContainerFlex}>
          <SurveyMiddleTextWrapper>
            <DarkBlueHeaderL>{surveyNamePrefix} &nbsp;</DarkBlueHeaderL>
            <SurveyBrightBlueHeaderL color={surveyNameColor}>{surveyName}</SurveyBrightBlueHeaderL>
          </SurveyMiddleTextWrapper>
          <DarkBlueBodyS padding={'0 0 1rem 0'}>{surveyDescription}</DarkBlueBodyS>
          {/* TODO: Temporarily Removed for 27th May Product Release */}
          {showSurveyTimeToComplete && (
            <LightGreyText>{`TIME TO COMPLETE: ${timeToComplete} min${
              timeToComplete > 1 ? 's' : ''
            }`}</LightGreyText>
          )}
        </SurveyMiddle>
        <SurveyRight>
          <RightTextWrapper>
            {surveyCompleted ? (
              <>
                <Icon src={icTick} />
                <FinishedText>All Done &hellip; Thanks</FinishedText>
              </>
            ) : (
              <>
                {/* TODO: Temporarily Removed for Linfox */}
                {showSurveyDaysLeft && (
                  <>
                    <Icon src={icFlag} />
                    <UnfinishedText>{`${daysLeft} Days left to complete`}</UnfinishedText>
                  </>
                )}
              </>
            )}
          </RightTextWrapper>
          {!surveyCompleted && (
            <Button variant="primary" width="75%" onClick={onClick}>
              Let&apos;s Do It
            </Button>
          )}
        </SurveyRight>
      </FlexRow>
    </MainContentContainerPadded>
  );
};

export default SurveyContainer;
