import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { useHistory } from 'react-router';

import { setPassword, SetPassword } from '../../lib/Api/common/Auth';
import { signOut } from '../../lib/Api/User';

import { ExternalPageWrapper, FormControl, PasswordInput, ModalButton } from '../../components/UI';

import { FormWrapper, Title, InputGroup, ButtonWrapper } from './Styles';

import updatePasswordSchema from '../../lib/validators/updatePasswordSchema';
import { APIResult } from '../../lib/Types';

export interface CompaniesProps {
  onSetPassword: SetPassword;
  onSignOut: (notify?: boolean) => Promise<APIResult<void>>;
}

const UpdatePassword: React.FC<CompaniesProps> = ({ onSetPassword, onSignOut }) => {
  const history = useHistory();
  return (
    <ExternalPageWrapper>
      <Title>Please update your password</Title>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: ''
        }}
        validationSchema={updatePasswordSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={async ({ currentPassword, newPassword }, { setSubmitting }) => {
          setSubmitting(true);
          const { error } = await onSetPassword(currentPassword, newPassword);
          const passwordUpdated = true;
          if (!error) {
            await onSignOut(passwordUpdated);
            history.push('/Login');
          }
        }}
      >
        {({ values, errors, isSubmitting, handleSubmit, handleChange }) => (
          <>
            <FormWrapper>
              <InputGroup>
                <FormControl>
                  <PasswordInput
                    required
                    id="currentPassword"
                    name="currentPassword"
                    label="Current Password"
                    value={values.currentPassword}
                    onChange={handleChange}
                    error={errors.currentPassword}
                  />
                </FormControl>
                <FormControl>
                  <PasswordInput
                    required
                    id="newPassword"
                    name="newPassword"
                    label="New Password"
                    value={values.newPassword}
                    onChange={handleChange}
                    error={errors.newPassword}
                  />
                </FormControl>
              </InputGroup>
              <ButtonWrapper>
                <ModalButton
                  onClick={() => onSignOut(false)}
                  type="button"
                  variant={'secondary'}
                  disabled={isSubmitting}
                >
                  Sign out
                </ModalButton>
                <ModalButton
                  onClick={() => handleSubmit()}
                  type="submit"
                  variant={isSubmitting ? 'disabled' : 'primary'}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Update
                </ModalButton>
              </ButtonWrapper>
            </FormWrapper>
          </>
        )}
      </Formik>
    </ExternalPageWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onSetPassword: (currentPassword: string, newPassword: string) =>
        setPassword(currentPassword, newPassword) as any,
      onSignOut: (passwordUpdated = false) => signOut(passwordUpdated) as any
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(UpdatePassword);
