import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withFormik, FormikProps } from 'formik';
import Modal from '../Modal';
import validationSchema from './validators/abuseReportSchema';
import { DarkBlueBodyS, RegText, RedText } from '../../../../Themes';
import { reportLady } from '../../../../assets/img';
import {
  OptionsWrapper,
  HeaderTextWrapper,
  RadioOption,
  RadioLabel,
  ButtonsWrapper,
  LeftContent,
  FlexRow,
  ReportLadyImg,
  ReportButton,
  CancelButton,
  GotItButton
} from './Styles';
import { reportPost } from '../../../../lib/Api/Posts';

interface ReportModalProps {
  postId: number;
  closeModal: () => void;
  reportPost: (postId: number, reason: string) => any;
}

enum ReportState {
  Reporting = 0,
  Success = 1,
  Error = 2
}

const reasonForReportOptions = [
  'Nudity or sexually explicit content',
  'Racist or derogatory content',
  'Bullying or harmful content',
  'Sales or promotional content not suited for this platform',
  'Content not appropriate for this platform'
];

const formikEnhancer = withFormik<ReportModalProps, any>({
  mapPropsToValues: ({ postId }) => ({
    postId,
    reason: ''
  }),
  mapPropsToStatus: () => ({
    reportState: ReportState.Reporting
  }),
  validationSchema,
  handleSubmit: async ({ postId, reason }, { props: { reportPost }, setStatus }) => {
    const res = await reportPost(postId, reason);
    if (res.error) setStatus({ reportState: ReportState.Error });
    else setStatus({ reportState: ReportState.Success });
  }
});

const ReportModal: FC<ReportModalProps & FormikProps<any>> = ({
  status: { reportState },
  errors,
  closeModal
}) => {
  const [modalStyle, setModalStyle] = useState({});
  let regTextContent = '';
  let redTextContent = '';
  switch (reportState) {
    case ReportState.Reporting:
      regTextContent = 'Report';
      redTextContent = 'Offensive Content';
      break;
    case ReportState.Success:
      regTextContent = 'Done...';
      redTextContent = 'Your report has been sent to admin';
      break;
    case ReportState.Error:
    default:
      redTextContent = 'An unexpected error occurred';
      break;
  }

  useEffect(() => {
    /* Extending Modal using styled-components doesn't work for some reason,
       hence this is needed for modal responsiveness */
    if (window.innerWidth < 425) {
      setModalStyle({
        overflowY: 'hidden',
        top: '4%',
        width: '80vw',
        left: '10vw',
        padding: '0px 20px 0px 20px'
      });
    } else {
      setModalStyle({
        overflowY: 'hidden',
        top: '4%',
        width: '60vw',
        left: '20vw'
      });
    }
  }, []);

  const Title = (
    <HeaderTextWrapper>
      <RegText style={{ marginRight: '5px' }}>{regTextContent}</RegText>
      <RedText>{redTextContent}</RedText>
    </HeaderTextWrapper>
  );

  return (
    <Modal title={Title} onClose={closeModal} style={modalStyle}>
      {reportState === ReportState.Reporting && (
        <FlexRow>
          <LeftContent>
            <DarkBlueBodyS>
              You can report a post if you find it offensive or if you think it should be removed
              from the platform so it will not offend others. Any reported content will be removed
              from your feed and a message sent to your administrator to check the content and raise
              a concern with the person who created it.
            </DarkBlueBodyS>
            {errors?.reason && <RedText>{errors.reason}</RedText>}
            <OptionsWrapper>
              {reasonForReportOptions.map(option => (
                <React.Fragment key={`${option.split(' ')[0]}`}>
                  <RadioLabel>
                    <RadioOption type="radio" name="reason" value={option} />
                    {option}
                  </RadioLabel>
                </React.Fragment>
              ))}
            </OptionsWrapper>
            <ButtonsWrapper>
              <ReportButton variant="report" type="submit">
                Report this content
              </ReportButton>
              <CancelButton variant="cancel" onClick={closeModal}>
                Cancel
              </CancelButton>
            </ButtonsWrapper>
          </LeftContent>
          <ReportLadyImg src={reportLady} />
        </FlexRow>
      )}
      {reportState === ReportState.Success && (
        <>
          <DarkBlueBodyS>
            This content will be removed from your feed and a message has been sent to your
            administrator to check the content and raise a concern with the person who created it.
          </DarkBlueBodyS>
          <GotItButton variant="primary" onClick={closeModal}>
            Got it!
          </GotItButton>
        </>
      )}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      reportPost: (postId: number, reason: string) => reportPost(postId, reason)
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(formikEnhancer(ReportModal));
