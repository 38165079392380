import styled from 'styled-components/macro';
import { PageContent } from '../../Themes';

export const ContentWrapper = styled(PageContent)`
  margin-top: 2rem;
`;

export const CardRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0;

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    flex-direction: column;
  }
`;
