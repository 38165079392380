import { Team, UserTeamRegistration, IndividualEventRegistration } from '../../../../Types';

// My registration
export const SET_TEAM = 'SET_TEAM';
export const SET_TEAM_DATA = 'SET_TEAM_DATA';
export const SET_TEAM_MEMBER_REGISTRATIONS = 'SET_TEAM_MEMBER_REGISTRATIONS';
export const SET_INDIVIDUAL_EVENT_REGISTRATION = 'SET_INDIVIDUAL_EVENT_REGISTRATION';

export interface TeamState {
  team?: Team;
  teamMemberRegistrations?: UserTeamRegistration[];
  individualEventRegistration?: IndividualEventRegistration;
}

export interface SetTeamAction {
  type: typeof SET_TEAM;
  team: Team;
}

export interface SetTeamMemberRegistrationsAction {
  type: typeof SET_TEAM_MEMBER_REGISTRATIONS;
  teamMemberRegistrations: UserTeamRegistration[];
}

export interface SetIndividualEventRegistrationAction {
  type: typeof SET_INDIVIDUAL_EVENT_REGISTRATION;
  individualEventRegistration: IndividualEventRegistration;
}

export interface SetTeamDataAction {
  type: typeof SET_TEAM_DATA;
  team: Team;
  teamMemberRegistrations: UserTeamRegistration[];
  individualEventRegistration: IndividualEventRegistration;
}

export type TeamActionTypes =
  | SetTeamAction
  | SetTeamMemberRegistrationsAction
  | SetIndividualEventRegistrationAction
  | SetTeamDataAction;
