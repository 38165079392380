import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import BarGraph from './BarGraph';

import { HeaderContainer, Header } from './Styles';
import { DashboardPanelWrapper } from '../DashboardTeamConnectionPoints/Styles';

import { ApplicationState } from '../../../lib/Store';
import { DashboardState } from '../../../lib/Store/contexts/participant/dashboard/types';

const DashboardTopMovementActivities: FC = () => {
  const { topActivities } = useSelector<ApplicationState, DashboardState>(
    state => state.participantDashboardState
  );

  return (
    <DashboardPanelWrapper>
      <HeaderContainer>
        <Header>Top Movement Activities</Header>
      </HeaderContainer>
      <BarGraph data={topActivities} />
    </DashboardPanelWrapper>
  );
};

export default DashboardTopMovementActivities;
