import { Reducer } from 'redux';
import {
  EventState,
  EventActionTypes,
  SET_COMPANY_DEMOGRAPHICS,
  SET_USER_DEMOGRAPHICS
} from './types';

export const initialEventState: EventState = {
  companyDemographics: [],
  userDemographics: []
};

const CompanyReducer: Reducer<EventState, EventActionTypes> = (
  state = initialEventState,
  action
) => {
  switch (action.type) {
    case SET_COMPANY_DEMOGRAPHICS:
      return { ...state, companyDemographics: action.companyDemographics };
    case SET_USER_DEMOGRAPHICS:
      return { ...state, userDemographics: action.userDemographics };
    default:
      return state;
  }
};

export default CompanyReducer;
