import { EventPayment, PagedResponse } from '../../../../Types';
import {
  SetCompanyEventPaymentsAction,
  SetCompanyEventPaymentsLoadingAction,
  SET_COMPANY_EVENT_PAYMENTS,
  SET_COMPANY_EVENT_PAYMENTS_LOADING
} from './types';

export const setCompanyEventPayments = (
  companyEventPayments: PagedResponse<EventPayment>
): SetCompanyEventPaymentsAction => ({
  type: SET_COMPANY_EVENT_PAYMENTS,
  companyEventPayments
});

export const setCompanyEventPaymentsLoading = (
  loading: boolean
): SetCompanyEventPaymentsLoadingAction => ({
  type: SET_COMPANY_EVENT_PAYMENTS_LOADING,
  loading
});

export default {
  setCompanyEventPayments
};
