import React, { FC } from 'react';
import moment from 'moment-timezone';
import { MainContentContainerReg, ColA, ColB, OrgImageMob } from '../../Styles';
import {
  BtnWrap,
  CompanyQuote,
  CompanyQuoteMobile,
  QuoteSymbol,
  QuoteSymbolMob,
  RegBtn,
  ORText
} from './Styles';

import {
  BoldText,
  BrightBlueHeaderS,
  DarkBlueBodyS,
  DarkBlueHeaderL,
  FlexRow,
  LightBlueBodyS,
  TurqText,
  RedHeaderS
} from '../../../../Themes';

import theme from '../../../../theme';
import { Company, Event } from '../../../../lib/Types';

const formatTime = (dateString: string, timezone: string): string =>
  `${moment(dateString, 'x')
    .tz(timezone)
    .format('h:mma z')}`;

const formatDate = (dateString: string, timezone: string): string =>
  `${moment(dateString, 'x')
    .tz(timezone)
    .format('Do of MMMM YYYY')}`;

export interface RegisteredProps {
  setCurrentStep: (num: number) => void;
  company: Company;
  event: Event;
  companyLogo: string;
}

const Registered: FC<RegisteredProps> = ({ setCurrentStep, company, event }) => (
  <>
    <MainContentContainerReg>
      <ColA>
        <DarkBlueHeaderL>
          Thanks for joining the <TurqText> {event.name} </TurqText> Event
        </DarkBlueHeaderL>
        <DarkBlueBodyS padding={'0 10% 0 0'}>
          {'From '}
          <BoldText>{formatTime(event.startDate, event.timezone)}</BoldText>
          {' on the '}
          <BoldText>{formatDate(event.startDate, event.timezone)}</BoldText>
          {' until '}
          <BoldText>{formatTime(event.endDate, event.timezone)}</BoldText>
          {' on the '}
          <BoldText>{formatDate(event.endDate, event.timezone)}</BoldText>
          {'.'}
        </DarkBlueBodyS>
        <DarkBlueBodyS>
          {'Registrations close at'}
          <BoldText> {formatTime(event.registrationsEndDate, event.timezone)}</BoldText>
          {' on the '}
          <BoldText>{formatDate(event.registrationsEndDate, event.timezone)}</BoldText>
          {'.'}
          <RedHeaderS>You&#39;re now successfully registered as an individual</RedHeaderS>
          If you don&#39;t create a team you will be assigned to one before the event.
          <ORText>- OR -</ORText>
          <BrightBlueHeaderS>Create your own team</BrightBlueHeaderS>
          <ol style={{ listStylePosition: 'inside' }}>
            <li>Create a team name.</li>
            <li>Add your team members.</li>
            <li>Invites will be sent out to your other team members to join.</li>
            <li>Admins will approve your team.</li>
            <li>You will receive an email confirmation once your team is approved.</li>
            <br />
          </ol>
        </DarkBlueBodyS>

        <BtnWrap>
          <RegBtn
            margin={'1.4rem 0'}
            fontWeight={theme.fontWeights.bold}
            variant={'secondary'}
            onClick={() => setCurrentStep(1)}
          >
            Create My Team
          </RegBtn>
        </BtnWrap>
      </ColA>
      <ColB>
        <CompanyQuote>
          <QuoteSymbol />
          <LightBlueBodyS>{event.greeting}</LightBlueBodyS>
        </CompanyQuote>
      </ColB>
    </MainContentContainerReg>
    <CompanyQuoteMobile>
      <FlexRow>
        <QuoteSymbolMob />
        <OrgImageMob src={company.profileImage} />
      </FlexRow>
      <LightBlueBodyS padding={'0.5rem 0 0 0'}> {event.greeting}</LightBlueBodyS>
    </CompanyQuoteMobile>
  </>
);

export default Registered;
