import React, { FC, useState } from 'react';
import { Option, Wrapper, ImageContainer, Menu } from './Styles';
import { threeDots } from '../../../../assets/icons/index';

interface ContextMenuProps {
  handleReport: () => void;
  handleDelete: () => void;
}

const ContextMenu: FC<ContextMenuProps> = ({ handleReport, handleDelete }) => {
  const [open, setOpen] = useState(false);
  return (
    <Wrapper
      open={open}
      onClick={event => {
        event.preventDefault();
        setOpen(!open);
      }}
    >
      <ImageContainer>
        <img src={threeDots} alt="Options" />
      </ImageContainer>
      <Menu>
        <Option
          onClick={() => {
            setOpen(!open);
            handleReport();
          }}
        >
          Report
        </Option>
        {handleDelete && (
          <Option
            onClick={() => {
              setOpen(!open);
              handleDelete();
            }}
          >
            Delete
          </Option>
        )}
      </Menu>
    </Wrapper>
  );
};

export default ContextMenu;
