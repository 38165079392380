import moment from 'moment';

import { apiRequest, apiFetch, objectToQueryString } from '../Utils';
import { server } from '../../../CONSTANTS';

import {
  APIThunkResult,
  APIResult,
  PaginationFilter,
  PagedResponse,
  EventListView,
  CreateEventResponse,
  EditEventResponse,
  CompanyEventAssignment,
  CompanyRegistrationData,
  CompanyEventLinkStatus
} from '../../Types';
import {
  setEvents,
  setPagedCompaniesInEvent
} from '../../../lib/Store/contexts/masterAdmin/event/actions';
import { setNotification } from '../../../lib/Store/contexts/notification/actions';
import { EventSchema } from '../../../pages/Events/validators/eventSchema';

export type LoadEvents = (
  filter: PaginationFilter
) => Promise<APIResult<PagedResponse<EventListView>>>;
export function loadEvents(filter: PaginationFilter): APIThunkResult<PagedResponse<EventListView>> {
  return apiRequest<PagedResponse<EventListView>>(async dispatch => {
    const query = objectToQueryString(filter);
    const response = await apiFetch<PagedResponse<EventListView>>({
      method: 'GET',
      url: `${server}/events/paged?${query}`
    });

    const eventsWithDates = response.results.map(event => {
      const startDateWTimezone = moment(event.startDate, 'x')
        .tz(event.timezone)
        .format('YYYY/MM/DD HH:mm');
      const endDateWTimezone = moment(event.endDate, 'x')
        .tz(event.timezone)
        .format('YYYY/MM/DD HH:mm');
      event.eventDates = `${startDateWTimezone} - ${endDateWTimezone}`;
      return event;
    });

    const returnObj = { count: response.count, results: eventsWithDates };
    dispatch(setEvents(returnObj));
    return returnObj;
  });
}

export function createEvent(data: EventSchema): APIThunkResult<CreateEventResponse> {
  return apiRequest<CreateEventResponse>(async (dispatch, getState) => {
    const { eventState } = getState();
    const result = await apiFetch<CreateEventResponse>({
      method: 'POST',
      url: `${server}/events/create`,
      body: data
    });
    dispatch(loadEvents(eventState.filter));
    dispatch(setNotification({ message: 'Created Event successfully' }));
    return result;
  });
}

export function editEvent(eventId: number, data: EventSchema): APIThunkResult<EditEventResponse> {
  return apiRequest<EditEventResponse>(async (dispatch, getState) => {
    const { eventState } = getState();
    const result = await apiFetch<CreateEventResponse>({
      method: 'PUT',
      url: `${server}/events/edit/${eventId}`,
      body: data
    });
    dispatch(loadEvents(eventState.filter));
    dispatch(setNotification({ message: 'Saved Event successfully' }));
    return result;
  });
}

export type LoadPagedCompaniesInEvent = (
  filter: PaginationFilter
) => Promise<APIResult<PagedResponse<CompanyRegistrationData>>>;
export function loadPagedCompaniesInEvent(
  filter: PaginationFilter
): APIThunkResult<PagedResponse<CompanyRegistrationData>> {
  return apiRequest<PagedResponse<CompanyRegistrationData>>(async (dispatch, getState) => {
    const { eventId } = getState().eventState.event;
    const query = objectToQueryString(filter);
    const response = await apiFetch<PagedResponse<CompanyRegistrationData>>({
      method: 'GET',
      url: `${server}/events/registered-companies/paged/${eventId}?${query}`
    });

    dispatch(setPagedCompaniesInEvent(response));
    return response;
  });
}

export function markCompanyEventAssignmentPaid(
  companyId: number
): APIThunkResult<CompanyEventAssignment> {
  return apiRequest<CompanyEventAssignment>(async (dispatch, getState) => {
    const companiesInEvent = getState().masterAdminEventState.pagedCompaniesInEvent;
    try {
      const data = await apiFetch<CompanyEventAssignment>({
        url: `${server}/events/mark-manually-paid/${companyId}`,
        method: 'POST'
      });

      // Update redux store with updated status
      // Force it to show paid and set paidParticipants to max participants
      const updatedState: CompanyRegistrationData[] = companiesInEvent.results.map(item => ({
        ...item,
        status: item.companyId === data.companyId ? CompanyEventLinkStatus.Paid : item.status,
        paidParticipants: item.companyId === data.companyId ? data.maxParticipants : item.paidParticipants
      }));

      dispatch(setPagedCompaniesInEvent({ count: companiesInEvent.count, results: updatedState }));

      return data;
    } catch (e) {
      dispatch(setNotification({ message: e?.message, duration: 3, variant: 'danger' }));
    }
  });
}
