import styled from 'styled-components/macro';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.padding * 4}px;
`;

export const ConfirmationText = styled.p`
  margin-top: 1.5rem;
`;
