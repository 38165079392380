import styled from 'styled-components/macro';

interface FlexProps {
  width?: string;
  height?: string;
  align?: string;
  justify?: string;
  padding?: string;
  flex?: number;
}

export const FlexCol = styled.div<FlexProps>`
  display: flex;
  flex-direction: column;
  width: ${props => props.width || '50%'};
  height: ${props => props.height || '100%'};
  align-content: ${props => props.align || 'center'};
  justify-content: ${props => props.justify || 'center'};
  padding: ${props => props.padding || '0'};
`;

export const FlexRow = styled.div<FlexProps>`
  display: flex;
  flex-direction: row;
  flex: ${({ flex }) => (flex ? flex : 1)};
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  align-items: ${props => props.align || 'center'};
  justify-content: ${props => props.justify || 'center'};
  padding: ${props => props.padding || '0'};
`;
