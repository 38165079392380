import React, { FC, useState, useEffect } from 'react';
import {
  PieChart as PieChartOverride,
  Pie,
  Cell,
  Tooltip as TooltipOverride,
  ResponsiveContainer,
  TooltipProps
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import Tooltip from './Tooltip';

import { PieChartWrapper } from './Styles';
import { TooltipText } from './Tooltip/Styles';

import { PointsType } from '../DashboardTopConnectionActivities';

interface PieChartProps {
  data: PointsType;
}

interface Slice {
  value: number;
  formattedValue: string;
  name: string;
}

const PieChart: FC<PieChartProps> = ({ data }) => {
  const [slices, setSlices] = useState<Slice[]>([]);

  useEffect(() => {
    if (data) {
      const filteredData = Object.keys(data)
        .filter(key => data[key] > 0) // Only non zero rows
        .map(key => {
          return {
            value: data[key],
            formattedValue: `${data[key]} Points`,
            name: key
          };
        });

      if (filteredData.length) {
        setSlices(filteredData);
      }
    }
  }, [data]);

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) =>
    active && payload && payload.length && <Tooltip payload={payload} />;

  return (
    <PieChartWrapper>
      {slices.length ? (
        <ResponsiveContainer width={250} height={250}>
          <PieChartOverride>
            <defs>
              {/** Define our gradients for pie chart sections */}
              <linearGradient id="connectionGradient" gradientTransform="rotate(135)">
                <stop offset="0%" stopColor="#FF5E86" />
                <stop offset="100%" stopColor="#FFB982" />
              </linearGradient>
              <linearGradient id="gratitudeGradient" gradientTransform="rotate(137)">
                <stop offset="0%" stopColor="#FFDF62" />
                <stop offset="100%" stopColor="#CBF88C" />
              </linearGradient>
              <linearGradient id="exerciseGradient" gradientTransform="rotate(90)">
                <stop offset="0%" stopColor="#57D9D1" />
                <stop offset="100%" stopColor="#E0F07E" />
              </linearGradient>
              <linearGradient id="laughGradient" gradientTransform="rotate(135)">
                <stop offset="0%" stopColor="#A875FF" />
                <stop offset="100%" stopColor="#A9A8FF" />
              </linearGradient>
              <linearGradient id="sleepGradient" gradientTransform="rotate(90)">
                <stop offset="0%" stopColor="#75DBFF" />
                <stop offset="100%" stopColor="#A9A8FF" />
              </linearGradient>
              <linearGradient id="nutritionGradient" gradientTransform="rotate(135)">
                <stop offset="0%" stopColor="#69D53B" />
                <stop offset="100%" stopColor="#CBF88C" />
              </linearGradient>
              <linearGradient id="mindfulnessGradient" gradientTransform="rotate(135)">
                <stop offset="0%" stopColor="#FFDF62" />
                <stop offset="99.918%" stopColor="#F8AD8C" />
              </linearGradient>
            </defs>
            <TooltipOverride content={<CustomTooltip />} />
            <Pie
              isAnimationActive
              data={slices}
              dataKey="value"
              innerRadius="35%"
              outerRadius="100%"
              width="100%"
              height="100%"
              cx="50%"
              cy="50%"
            >
              {slices.map((slice, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={`url(#${slice.name.toLowerCase()}Gradient)`}
                  fillOpacity={1}
                />
              ))}
            </Pie>
          </PieChartOverride>
        </ResponsiveContainer>
      ) : (
        <TooltipText>{"You haven't received any connection points yet!"}</TooltipText>
      )}
    </PieChartWrapper>
  );
};

export default PieChart;
