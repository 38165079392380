import React, { FC } from 'react';
import { Group, Heading, Body } from '../Styles';
import { Input } from '../../../../../components/UI';
import { FormikFormComponent } from '../../../../../lib/Types';
import { ParticipantSchema } from '../../../validators/participantSchema';
import theme from '../../../../../theme';

const ActiveStatus: FC<FormikFormComponent<ParticipantSchema>> = ({
  values,
  errors,
  handleChange
}) => (
  <Group>
    <Heading>User Password</Heading>
    <Body>
      You can modify this users password here if you need to. Make sure to email them the new
      password once you make the change for them.
    </Body>
    <Input
      required
      id="password"
      name="password"
      value={values.password}
      error={errors.password}
      label="Password"
      type="password"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
  </Group>
);

export default ActiveStatus;
