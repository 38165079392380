import styled from 'styled-components/macro';

export const TopSectionWrapper = styled.div`
  padding: 0;
  margin: 0 0 ${({ theme }) => theme.padding}px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;
  white-space: nowrap;
  margin-right: auto;
  width: 50%;
  position: absolute;
`;

export const ProfileWrapper = styled.div`
  flex: 1;
  right: 0;
  margin: auto auto;
`;
