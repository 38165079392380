import styled from 'styled-components/macro';

export const PieChartWrapper = styled.div`
  flex: 3;
  margin: auto;

  .recharts-responsive-container {
    margin: auto;
  }
`;
