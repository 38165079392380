import React, { FC, MouseEvent } from 'react';
import { Wrapper, StopWatchIcon, DownCarat, ActivityHeader } from './Styles';
import { stopwatch, downCarat } from '../../../assets/icons/index';

interface AddActivityCardProps {
  onClick: (e: MouseEvent) => void;
}

const AddActivityCard: FC<AddActivityCardProps> = ({ onClick }) => (
  <Wrapper onClick={onClick}>
    <DownCarat src={downCarat} />
    <StopWatchIcon src={stopwatch} />
    <ActivityHeader>Add My Activity</ActivityHeader>
  </Wrapper>
);

export default AddActivityCard;
