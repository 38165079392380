import React, { FC, useState, useEffect, useCallback } from 'react';
import { useParams, withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  LoadSinglePostResult,
  loadSinglePost,
  loadMoreReplies,
  likePost,
  LikePost,
  deleteMyPost,
  DeletePost
} from '../../lib/Api/Posts';
import { isAfter } from '../../lib/TimeUtils';

import PostComponent from '../../components/UI/Post';
import { EmployeePageWrapper } from '../../components/UI';
import ReportModal from '../../components/UI/Modal/ReportModal';

import { Post, ApiStatus, User, Event } from '../../lib/Types';
import { ApplicationState } from '../../lib/Store';

interface PostThreadProps extends RouteComponentProps {
  user: User;
  userEvent: Event;
  fetchedAllReplies: boolean;
  loadPost: (postId: string) => LoadSinglePostResult;
  loadMoreReplies: (postId: string, existingReplies: Post) => any;
  onLike: LikePost;
  onDelete: DeletePost;
}

const PostThread: FC<PostThreadProps> = ({
  history,
  user,
  userEvent,
  loadPost,
  loadMoreReplies,
  onLike,
  onDelete,
  fetchedAllReplies
}) => {
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState<Post>(null);
  const [fetching, setFetching] = useState<boolean>(false);
  // Posts (Post or comments) currently having their liked status updated(loading). Mitigates latency issues
  const [likingPosts, setLikingPosts] = React.useState<number[]>([]);
  const [reportModalVisible, setReportModalVisible] = React.useState(false);
  const [reportingPostId, setReportingPostId] = React.useState(0);
  const { id } = useParams();

  useEffect(() => {
    if (!userEvent.eventId || !isAfter(moment().valueOf(), userEvent.startDate)) return;
    if (!postData && !loading) {
      setLoading(true);
      if (!id) {
        /** TODO: Error State */
      }
      loadPost(id)
        .then(postResult => setPostData(postResult.data))
        .finally(() => setLoading(false));
    }
  }, [id, setPostData, setLoading]);

  const likePost = async () => {
    const { postId, likes, liked } = postData;
    if (likingPosts.includes(postId)) return;
    setLikingPosts([...likingPosts, postId]);
    setPostData({ ...postData, postId, liked: !liked, likes: likes + (liked ? -1 : 1) }); // Predictive
    const { status } = await onLike(postId);
    if (status !== ApiStatus.SUCCESS) setPostData({ ...postData, likes, liked });
    setLikingPosts(likingPosts.filter(id => id !== postId));
  };

  const likeReply = async (replyPostId: number) => {
    const { replies } = postData;
    if (likingPosts.includes(replyPostId)) return;
    setLikingPosts([...likingPosts, replyPostId]);
    const newReplies = replies.map(reply =>
      reply.postId === replyPostId
        ? { ...reply, liked: !reply.liked, likes: reply.likes + (reply.liked ? -1 : 1) }
        : reply
    );
    setPostData({ ...postData, replies: newReplies }); // Predictive
    const { status } = await onLike(replyPostId);
    if (status !== ApiStatus.SUCCESS) setPostData({ ...postData, replies });
    setLikingPosts(likingPosts.filter(id => id !== replyPostId));
  };

  const addReply = (reply: Post) =>
    setPostData({
      ...postData,
      replies: [reply, ...postData.replies],
      comments: postData.comments + 1
    });

  const deletePost = () => {
    onDelete(postData.postId);
    history.replace('/WaterCooler');
  };

  const deleteReply = async (replyPostId: number) => {
    const { replies } = postData;
    const { status } = await onDelete(replyPostId);
    if (status === ApiStatus.SUCCESS) {
      const newReplies = replies.filter(reply => reply.postId !== replyPostId);
      setPostData({
        ...postData,
        replies: newReplies,
        comments: postData.comments - 1
      });
    }
  };

  const fetchMoreReplies = useCallback(async () => {
    if (!userEvent.eventId || !isAfter(moment().valueOf(), userEvent.startDate)) return;
    if (postData !== null && !loading) {
      if (fetchedAllReplies) return;
      setFetching(true);
      // await new Promise(resolve => setTimeout(resolve, 1000)); // You want this whenever developing
      const { data: newReplies } = await loadMoreReplies(id, postData);
      if (!newReplies) return;
      const newPostData = { ...postData, replies: [...postData.replies, ...newReplies] };
      setPostData(newPostData);
      setFetching(false);
    }
  }, [postData, loading, loadMoreReplies, fetchedAllReplies]);

  const onScroll = useCallback(() => {
    if (!userEvent.eventId || !isAfter(moment().valueOf(), userEvent.startDate)) return;
    const { body, documentElement } = document;
    const { scrollTop, scrollHeight, clientHeight } = documentElement;
    const winScroll = body.scrollTop || scrollTop;
    const height = scrollHeight - clientHeight;
    const scrolled = winScroll / height; // 0.0 - 1.0

    if (scrolled >= 0.9 && !fetching) fetchMoreReplies();
  }, [fetching, fetchMoreReplies]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [fetchMoreReplies, fetching]);

  return (
    <EmployeePageWrapper>
      {loading && <div>Loading...</div>}
      {!!postData && (
        <PostComponent
          user={user}
          post={postData}
          onLike={likePost}
          onReplyLike={likeReply}
          onDelete={postData.user.userId === user.userId ? deletePost : null}
          onReplyDelete={deleteReply}
          onNewReply={addReply}
          showComments
          setReportModalVisible={setReportModalVisible}
          setReportingPostId={setReportingPostId}
        />
      )}
      {reportModalVisible && (
        <ReportModal postId={reportingPostId} closeModal={() => setReportModalVisible(false)} />
      )}
    </EmployeePageWrapper>
  );
};

export default withRouter(
  connect(
    (state: ApplicationState) => ({
      user: state.userState.userData,
      userEvent: state.userState.userEvent,
      fetchedAllReplies: state.postsState.allRepliesLoaded
    }),
    (dispatch: Dispatch) =>
      bindActionCreators(
        {
          loadPost: loadSinglePost as any,
          loadMoreReplies: loadMoreReplies as any,
          onLike: likePost as any,
          onDelete: deleteMyPost as any
        },
        dispatch
      )
  )(PostThread)
);
