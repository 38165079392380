import {
  EventState,
  EventActionTypes,
  SET_EVENT,
  SET_EVENT_OPTIONS,
  SET_EVENT_FILTER
} from './types';
import { Event } from '../../../Types';

export const initialState: EventState = {
  eventOptions: [],
  event: {} as Event,
  filter: {
    page: 1,
    rowsPerPage: 10,
    orderColumn: 'name',
    orderDirection: 'ASC',
    keyword: ''
  }
};

const eventReducer = (state: EventState = initialState, action: EventActionTypes): EventState => {
  switch (action.type) {
    case SET_EVENT_OPTIONS:
      return {
        ...state,
        eventOptions: action.eventOptions
      };
    case SET_EVENT:
      return {
        ...state,
        event: action.event
      };
    case SET_EVENT_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    default:
      return state;
  }
};

export default eventReducer;
