import * as yup from 'yup';

const registerCredentialsSchema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .trim()
    .matches(/^.*[0-9].*$/, 'Password must contain 1 number.')
    .matches(/^.*[A-Z].*$/, 'Password must contain 1 capital letter.')
    .min(8, 'Password must be greater than 8 characters.')
    .max(16, 'Password must be less than 16 characters.')
    .required('Password is required'),
  firstName: yup.string().required('Sorry, First Name is required.'),
  lastName: yup.string().required('Sorry, Last Name is required.'),
  passCode: yup
    .string()
    .trim()
    .min(4, 'Pass code must be 4 characters.')
    .max(4, 'Pass code must be 4 characters.')
    .nullable(true)
    .notRequired(),
  rememberMe: yup.boolean(),
  agreeToS: yup.boolean().oneOf([true], 'You must agree to the terms and privacy policy.')
});

export type RegisterCredentials = yup.InferType<typeof registerCredentialsSchema>;
export default registerCredentialsSchema;
