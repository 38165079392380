import styled from 'styled-components/macro';

interface ModalProps {
  width?: number;
  top?: number;
  left?: number;
  alpha?: number;
  padding?: string;
  headerPadding?: string;
  titlePadding?: string;
}

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormColumn = styled.div`
  flex: 1;
  &:nth-child(1) {
    padding-right: ${({ theme }) => theme.padding * 8}px;
  }
  &:nth-child(2) {
    padding-right: ${({ theme }) => theme.padding * 3}px;
  }
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Group = styled.div`
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const Heading = styled.h4`
  font-size: ${({ theme }) => theme.fonts.subHeading / 1.25}rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.primary.dark};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const Body = styled.p`
  font-size: ${({ theme }) => theme.fonts.body / 1.25}rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.primary.dark};
  margin-bottom: ${({ theme }) => theme.padding * 1.5}px;
`;

export const InlineInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InlineInput = styled.div`
  flex: 1;
  &:nth-child(1) {
    padding-right: ${({ theme }) => theme.padding / 2}px;
  }
  &:nth-child(2) {
    padding-left: ${({ theme }) => theme.padding / 2}px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.padding * 4}px;
`;

export const ModalTitle = styled.h2<ModalProps>`
  font-family: ${({ theme }) => theme.fontFamily.heading}px;
  font-size: 24px;
  color: #366696;
  letter-spacing: -0.06px;
  line-height: 28px;
  padding: ${props => (props.titlePadding ? props.titlePadding : '15px 0')};
  font-style: italic;
`;
