import styled from 'styled-components/macro';
import { StyledPageHd } from '../../Themes';

import { ContainerProps } from '../../lib/Types';

import { getBoxShadow } from '../../lib/Utils';

export const Title = styled(StyledPageHd)`
  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    margin: 0 0 1.5rem 1.5rem;
    font-size: calc(1.2em + 1vw);
  }
`;

export const EditProfileWrapper = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 2rem 0;
  background: ${({ theme }) => theme.backgrounds.white};
  border-radius: 1.5rem;
  box-shadow: ${({ elevation = 1, theme }) => getBoxShadow(elevation, theme)};
  min-height: 100px;
  z-index: 0;
  position: relative;

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    border-radius: 0;
  }
`;
