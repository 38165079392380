import styled from 'styled-components/macro';
import { DateSingleInput } from '@datepicker-react/styled';
import theme from '../../../theme';

interface DatePickerProps {
  valid: boolean;
  style?: string;
}

interface WrapperProps {
  transform?: string;
  margin?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  ${({ margin }) => (margin ? margin : '0 0 0 auto')};
  justify-content: flex-end;
  input {
    border-radius: 4px;
    color: ${theme.primary.dark};
    font-size: ${({ theme }) => theme.fonts.subBody + 0.2}rem;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-family: 'Sarabun', sans-serif;

    &::placeholder {
      opacity: 1;
      color: ${({ theme }) => theme.primary.xlight};
      ${({ color }) => `color: ${color}`};
    }
  }
`;

export const StyledDatePicker = styled(DateSingleInput)<DatePickerProps>`
  border: ${({ theme, valid }) =>
    valid ? theme.borders.input.default : theme.borders.input.error};
  margin: 3px;
  font-size: ${({ theme }) => theme.fonts.subBody + 0.2}rem;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-family: 'Sarabun', sans-serif;

  :focus {
    border: ${({ valid, theme }) =>
      valid ? theme.borders.input.focused : theme.borders.input.error};
    margin: 3px;
  }
`;
