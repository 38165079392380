import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.8rem;
`;

export const Icon = styled.img`
  margin-right: 1rem;
  width: 1.8rem;
  height: auto;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.status.danger};
  font-size: ${({ theme }) => theme.fonts.subBody + 0.2}rem;
`;
