import { PermissionsState, PermissionsActionTypes, SET_PERMISSIONS } from './types';

const initialState: PermissionsState = {
  code: null,
  permissions: [],
  mustUpdatePassword: false
};

const permissionsReducer = (
  state: PermissionsState = initialState,
  action: PermissionsActionTypes
): PermissionsState => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return {
        code: action.code,
        permissions: action.permissions || [],
        mustUpdatePassword: action.mustUpdatePassword || false
      };
    default:
      return state;
  }
};

export default permissionsReducer;
