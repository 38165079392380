import React, { FC, useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import { RouterProps } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PictureForm from './PictureForm';
import PictureCrop from './PictureCrop';
import LoadingIndicator from '../../../../components/UI/LoadingIndicator';
import { LoaderContainer } from './Styles';

import { ApplicationState } from '../../../../lib/Store';
import { uploadProfilePicture, UploadProfilePic } from '../../../../lib/Api/User';
import { FilePreview, ApiStatus } from '../../../../lib/Types';

interface PictureFormContainerProps extends RouterProps {
  apiLoading: boolean;
  userId: string;
  onUpload: UploadProfilePic;
}

export enum DisplayState {
  UPLOAD = 0,
  CROP = 1
}

export interface PictureFormState {
  image: File | null;
}

const formikEnhancer = withFormik<PictureFormContainerProps, PictureFormState>({
  mapPropsToValues: () => ({
    image: null,
    disabled: false
  }),
  // validationSchema,
  validateOnMount: false,
  validateOnChange: false,
  handleSubmit: async (
    { image }: PictureFormState,
    { props: { history, onUpload, userId } }
  ): Promise<void> => {
    const { status } = await onUpload(image as File, userId);
    if (status === ApiStatus.SUCCESS) {
      // redirect to dashboard
      history.push('/TeamDashboard');
    }
  }
});

const PictureFormContainer: FC<PictureFormContainerProps & FormikProps<PictureFormState>> = ({
  values,
  handleSubmit,
  handleChange,
  errors,
  apiLoading,
  setValues,
  setFieldError,
  setErrors,
  onUpload,
  userId,
  ...props
}) => {
  const [originalImage, setOriginalImage] = useState<FilePreview | undefined>();
  const [localImage, setLocalImage] = useState<FilePreview | undefined>();
  const [displayState, setDisplayState] = useState(DisplayState.UPLOAD);

  const updateContainerState = React.useCallback(
    (file: FilePreview) => {
      setLocalImage(file);
      setValues({ image: file });
    },
    [setLocalImage]
  );

  const skipUpload = async () => {
    const updateResults = await onUpload(null, userId);
    if (updateResults.status === ApiStatus.SUCCESS) {
      //redirect to dashboard
      props.history.push('/TeamDashboard');
    }
  };

  return (
    <>
      {apiLoading ? (
        <LoaderContainer>
          <LoadingIndicator />
        </LoaderContainer>
      ) : (
        <>
          {displayState === DisplayState.UPLOAD ? (
            <PictureForm
              {...values}
              image={localImage}
              disabled={apiLoading}
              formErrors={errors}
              onChange={handleChange}
              onSubmit={handleSubmit}
              setValues={setValues}
              updateContainerState={updateContainerState}
              setDisplayState={setDisplayState}
              skipUpload={skipUpload}
              setOriginalImage={setOriginalImage}
              setFieldError={setFieldError}
              setErrors={setErrors}
            />
          ) : (
            <PictureCrop
              file={originalImage}
              updateContainerState={updateContainerState}
              setDisplayState={setDisplayState}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ loadingState, userState }: ApplicationState) => ({
  apiLoading: loadingState.apiCallsInProgress > 0,
  userId: userState.userData.userId
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onUpload: uploadProfilePicture as any
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(formikEnhancer(PictureFormContainer))
);
