import styled from 'styled-components/macro';
import Paper from '../Paper';

interface NavLinkActiveProps {
  marginTop?: boolean;
}

export const SideBarWrapper = styled(Paper)`
  /* === Large Padding 3em * 2 ===  */
  padding: ${({ theme }) => theme.padding * 2}px 0;
  width: 20%;
  max-width: 229px;
  min-width: 187px;
  text-align: center;
  overflow: hidden;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgrounds.white};
  display: flex;
  font-family: 'Sarabun', sans-serif;
  z-index: 97;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    padding: 0;
    width: 70%;
    position: absolute;
    box-shadow: none;
    top: 1rem;
    left: 0.5rem;
  }
`;

export const CollapsedSideBarWrapper = styled(Paper)`
  /* === Large Padding 3em * 2 ===  */
  padding: ${({ theme }) => theme.padding * 2}px 0;
  min-height: calc(85vh - 24px);
  width: 75px;
  text-align: center;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 1.3rem;
  background-color: ${({ theme }) => theme.backgrounds.white};
  font-family: 'Sarabun', sans-serif;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    padding: 0;
    width: 73%;
  }
`;

export const SideNav = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 2;
  margin: ${({ theme }) => theme.padding}px 0;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: none;
  }
  z-index: 100;
`;

export const CollapsedSideNav = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 10;
  margin: 0 0 ${({ theme }) => theme.padding}px 0;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  flex: 1;
  margin-bottom: ${({ theme }) => theme.padding * 2}px;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: none;
  }
`;

export const LogoWrapperCollapsed = styled.div`
  flex: 1;
  margin-top: 20px;
  padding-top: 35px;
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.padding * 2}px;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: none;
  }
`;

// noinspection CssInvalidPropertyValue
export const NavLink = styled.div`
  flex: 1;
  text-decoration: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.padding * 1.2}px ${({ theme }) => theme.padding / 2}px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  display: flex;
  color: ${({ theme }) => theme.primary.xlight};
  white-space: break-spaces;
  font-family: 'Sarabun', sans-serif;

  &:hover {
    border-left: 2px solid ${({ theme }) => theme.primary.light};
    border-right: 2px solid ${({ theme }) => theme.backgrounds.white};
    background-image: ${({ theme }) => theme.gradients.btw};
    color: ${({ theme }) => theme.primary.dark};
    padding: ${({ theme }) => theme.padding * 1.2}px ${({ theme }) => theme.padding / 2}px;
  }
`;

export const NavLinkActive = styled.div<NavLinkActiveProps>`
  flex: 1;
  text-decoration: none;
  cursor: pointer;
  margin-top: ${({ marginTop }) => (marginTop ? '15px' : '0')};
  padding: ${({ theme }) => theme.padding * 1.2}px ${({ theme }) => theme.padding / 2}px;
  border-right: 2px solid ${({ theme }) => theme.backgrounds.white};
  background-image: ${({ theme }) => theme.gradients.btw};
  display: flex;
  flex-direction: column;
  font-family: 'Sarabun', sans-serif;
`;

export const NavIcon = styled.img`
  flex: 1;
  text-align: left;
  margin-right: 1.1rem;
  margin-left: 1rem;
  opacity: 0.5;
  height: 16px;
  width: 16px;
  object-fit: contain;
`;

export const NavIconActive = styled.img`
  flex: 1;
  text-align: left;
  margin-right: 1.1rem;
  margin-left: 1rem;
  height: 16px;
  width: 16px;
  object-fit: contain;
`;

export const NavText = styled.p`
  flex: 4;
  text-align: left;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.primary.dark};
  opacity: 0.5;
  text-decoration: none;
  font-family: 'Sarabun', sans-serif;
  margin-left: 10px;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(1.2rem + 0.4vw);
  }
`;

export const NavTextActive = styled.p`
  flex: 4;
  text-align: left;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.primary.dark};
  text-decoration: none;
  font-family: 'Sarabun', sans-serif;
  margin-left: 10px;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    font-size: calc(1.2rem + 0.4vw);
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  width: 100%;
`;

export const CollapseArrow = styled.img`
  position: absolute;
  top: calc(3% + 1.5vw);
  left: calc(3% + 1.5vw);
  z-index: 80;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: none;
  }
`;

export const CollapseArrowActive = styled.img`
  position: absolute;
  top: 24px;
  left: calc(50% - 9px);
  z-index: 99;
  transform: rotate(180deg);
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: none;
  }
`;

export const SideBarMob = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.device.tablet + 1}px) {
    display: none;
  }
`;

export const SideBarMobHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: ${({ theme }) => theme.padding}px;
  text-align: right;
  width: 100%;
`;

export const MobLogo = styled.img`
  width: 65%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
`;

interface IconProps {
  iconState: any;
}

export const BurgerIcon = styled.button<IconProps>`
  width: 16%;
  height: 20px;
  margin-left: ${({ theme }) => theme.padding / 2}px;
  background-image: ${props =>
    props.iconState
      ? `url(${require(`../../../assets/img/menu-x-mob.svg`)})`
      : `url(${require(`../../../assets/img/menu-burger-icon-mob.svg`)})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

interface MobLinksProps {
  hidden: any;
}

export const MobSideNav = styled.nav<MobLinksProps>`
  flex-direction: column;
  flex: 2;
  margin: ${({ theme }) => theme.padding}px 0;
  display: ${props => (props.hidden ? 'flex' : 'none')};
  z-index: 100;
`;

export const Icon = styled.img`
  padding: 1rem 1.5rem;

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: none;
  }
`;
