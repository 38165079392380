import React, { FC, ChangeEvent, useRef, useEffect } from 'react';
import moment, { Moment } from 'moment';

import DayBox from '../DayBox';

import { rightIconArrow } from '../../../assets/icons/index';
import { Wrapper, SliderWrap, ScrollButtonWrapper, ScrollLeft, ScrollRight } from './Styles';

interface DaySliderProps {
  startDate?: Moment;
  endDate?: Moment;
  selectedDate?: string;
  hasActivity: (date: string) => boolean;
  onChange?: (e: ChangeEvent<any>) => void;
}

const cleanDate = (date: Moment) =>
  moment(date)
    .millisecond(0)
    .second(0)
    .minute(0)
    .hour(0);

const today = cleanDate(moment());

const DaySlider: FC<DaySliderProps> = ({
  startDate = moment().subtract(3, 'days'),
  endDate = moment().add(3, 'days'),
  selectedDate = cleanDate(moment()),
  hasActivity,
  onChange
}) => {
  const days = [];
  const wrapperRef = useRef(null);
  const todayRef = useRef(null);

  const changeDateAndScroll = (event: any) => {
    onChange(event);
    if (wrapperRef) {
      /* Using offsetParent here, as we're actually passing down the 
      onChange event to an input element in DayBox and we want the container itself */
      const daysVisible = wrapperRef.current.offsetWidth / event.target.offsetParent.offsetWidth;
      const scrollLeftAmount =
        event.target.offsetParent.offsetLeft - // how far along this element is in the list
        event.target.offsetParent.offsetWidth - // the width of the element
        wrapperRef.current.offsetWidth / (daysVisible / 2); // finally, position the element in the center
      wrapperRef.current.scrollTo({
        left: scrollLeftAmount,
        behavior: 'smooth'
      });
    }
  };

  let d = moment(cleanDate(startDate));
  for (d; d.isBefore(cleanDate(endDate).add(1, 'days')); d = d.add(1, 'days')) {
    days.push(
      <DayBox
        key={d.toISOString()}
        date={cleanDate(d)}
        today={today}
        checked={hasActivity(d.toString())}
        onChange={changeDateAndScroll}
        selected={moment(selectedDate).isSame(d, 'day')}
        todayRef={moment(d).isSame(moment(today), 'd') ? todayRef : null}
      />
    );
  }

  useEffect(() => {
    // Scrolls to today's date
    if (wrapperRef.current && todayRef.current) {
      const daysVisible = wrapperRef.current.offsetWidth / todayRef.current.offsetWidth;
      const scrollLeftAmount =
        todayRef.current.offsetLeft -
        todayRef.current.offsetWidth -
        wrapperRef.current.offsetWidth / (daysVisible / 2);
      wrapperRef.current.scrollTo({
        left: scrollLeftAmount,
        behavior: 'auto'
      });
    }
  }, []);

  const scrollLeft = () => {
    wrapperRef.current.scrollBy({
      left: -195,
      behavior: 'smooth'
    });
  };

  const scrollRight = () => {
    wrapperRef.current.scrollBy({
      left: 195,
      behavior: 'smooth'
    });
  };

  return (
    <SliderWrap>
      <Wrapper ref={wrapperRef}>{days}</Wrapper>
      <ScrollButtonWrapper>
        <ScrollLeft src={rightIconArrow} onClick={scrollLeft} />
        <ScrollRight src={rightIconArrow} onClick={scrollRight} />
      </ScrollButtonWrapper>
    </SliderWrap>
  );
};

export default DaySlider;
