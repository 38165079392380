import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button, MultiDropdown, RadioGroup } from '../../../components/UI';
import ChooseCompanies from './ChooseCompanies';

import {
  postConnection,
  postGratitude,
  postExercise,
  postLaugh,
  postMindfulness,
  postNutrition,
  postSleep
} from '../../../assets/icons';
import {
  PostMetaWrapper,
  Title,
  PostTypeWrapper,
  InputLabel,
  DropdownWrapper,
  ButtonWrapper
} from './Styles';

import { POST_CATEGORY_DISPLAY } from '../../../CONSTANTS';
import { SpecialPostMetaData } from '../CreatePost';
import { PostType, PostCategory, Event } from '../../../lib/Types';
import { DropdownOption } from '../../../components/UI/MultiDropdown/MultiDropdown';

interface PostMetaProps {
  events: Event[];
  onLoadValues?: SpecialPostMetaData;
  onSubmit: (data: SpecialPostMetaData) => void;
}

const postTypeOptions = [
  {
    label: 'Nutrition',
    value: PostType.Nutrition,
    icon: postNutrition
  },
  {
    label: 'Exercise',
    value: PostType.Exercise,
    icon: postExercise
  },
  {
    label: 'Mindfulness',
    value: PostType.Mindfulness,
    icon: postMindfulness
  },
  {
    label: 'Sleep',
    value: PostType.Sleep,
    icon: postSleep
  },
  {
    label: 'Connection',
    value: PostType.Connection,
    icon: postConnection
  },
  {
    label: 'Gratitude',
    value: PostType.Gratitude,
    icon: postGratitude
  },
  {
    label: 'Laugh',
    value: PostType.Laugh,
    icon: postLaugh
  },
  {
    label: 'General',
    value: PostType.General
  }
];

const PostMeta: React.FC<PostMetaProps> = ({ events, onLoadValues, onSubmit }) => {
  const [formValues, setFormValues] = useState<SpecialPostMetaData>({
    type: onLoadValues?.type || PostType.General,
    category: onLoadValues?.category || PostCategory.GENERAL,
    event: onLoadValues?.event || null,
    companyIds: onLoadValues?.companyIds || []
  });

  const postCategoryOptions = Object.keys(POST_CATEGORY_DISPLAY)
    .filter(pc => pc !== PostCategory.GENERAL)
    .map(key => ({
      value: key,
      label: POST_CATEGORY_DISPLAY[key]
    }));

  const handleUpdateType = (value: PostType) => {
    let newCategory = PostCategory.GENERAL;
    if (value !== PostType.General) {
      newCategory =
        formValues.category === PostCategory.GENERAL
          ? PostCategory.DAILY_ACTIVITY
          : formValues.category;
    }
    setFormValues({
      ...formValues,
      type: value,
      category: newCategory
    });
  };

  const handleUpdateCompanies = (companyIds: number[]) => {
    setFormValues({
      ...formValues,
      companyIds
    });
  };

  return (
    <PostMetaWrapper>
      <Title>
        This will feature on the users social feeds as a update post, life hack or mini challenge.
      </Title>
      <PostTypeWrapper>
        <RadioGroup
          initialValue={formValues.type}
          columns={2}
          valueKey="type"
          options={postTypeOptions}
          handleOptionClick={(field, value) => handleUpdateType(value)}
        />
      </PostTypeWrapper>
      {formValues.type !== PostType.General && (
        <>
          <InputLabel>Choose a post type.</InputLabel>
          <RadioGroup
            options={postCategoryOptions}
            valueKey="value"
            initialValue={postCategoryOptions.find(o => o.value === formValues.category).label}
            horizontal
            handleOptionClick={(name, value) =>
              setFormValues({ ...formValues, category: PostCategory[value] })
            }
          />
        </>
      )}
      <DropdownWrapper>
        <InputLabel>Which Event would you like to create this for?</InputLabel>
        <MultiDropdown
          id="event"
          options={events.map(e => {
            return { value: e.eventId.toString(), label: e.name };
          })}
          placeholder="Event Name"
          onOptionClick={(value: DropdownOption) => {
            setFormValues({
              ...formValues,
              event: events.find(e => e.eventId === +value.value),
              companyIds: []
            });
          }}
          onLoadOption={
            events
              .filter(e => e.eventId === formValues?.event?.eventId)
              .map(e => {
                return { value: e.eventId.toString(), label: e.name };
              })[0]
          }
        />
      </DropdownWrapper>
      {formValues.event && (
        <ChooseCompanies
          eventId={formValues.event.eventId}
          onChange={handleUpdateCompanies}
          onLoadCompanyIds={formValues.companyIds}
        />
      )}
      <ButtonWrapper>
        <Button
          type="submit"
          variant={formValues.companyIds.length ? 'admin-primary' : 'disabled'}
          margin={'1.5rem 0rem'}
          width={'auto'}
          padding={'1.5rem 2rem'}
          minWidth={'23%'}
          disabled={!formValues.companyIds.length}
          onClick={() => onSubmit(formValues)}
        >
          WRITE POST
        </Button>
      </ButtonWrapper>
    </PostMetaWrapper>
  );
};

export default connect(null, dispatch => bindActionCreators({}, dispatch))(PostMeta);
