import styled from 'styled-components/macro';
import { DarkBlueHeaderL, RedText, TurqText } from '../../../Themes';

interface MiddleContainerProps {
  flex: number;
}

export const SurveyLeft = styled.div`
  flex: 1;
`;

export const SurveyMiddle = styled.div<MiddleContainerProps>`
  flex: ${({ flex }) => flex};
  padding-left: 10px;
`;

export const SurveyMiddleTextWrapper = styled.span`
  display: flex;
  flex-direction: row;
`;

export const SurveyBrightBlueHeaderL = styled(DarkBlueHeaderL)`
  color: ${({ color, theme }) => (color ? color : theme.primary.main)};
`;

export const SurveyRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex: 4;
`;

export const RightTextWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img`
  margin-right: 10px;
  margin-bottom: 1em;
`;

export const UnfinishedText = styled(RedText)`
  margin-bottom: 1em;
`;

export const FinishedText = styled(TurqText)``;
