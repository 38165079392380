import { PagedResponse, TeamListView, Team, TeamListFilter } from '../../../Types';

export const SET_PAGED_TEAMS = 'SET_PAGED_TEAMS';
export const SET_ADDING_TEAM_MEMBER = 'SET_ADDING_TEAM_MEMBER';
export const SET_TEAMS_FILTER = 'SET_TEAMS_FILTER';
export const SET_TEAM = 'SET_TEAM';
export const SET_GENERATING_TEAMS = 'SET_GENERATING_TEAMS';

export interface TeamsState {
  pagedTeamList: PagedResponse<TeamListView>;
  team: Team;
  addingTeamMember: boolean;
  generatingTeams: boolean;
  filter: TeamListFilter;
}

export interface SetPagedTeamsAction {
  type: typeof SET_PAGED_TEAMS;
  pagedTeamList: PagedResponse<TeamListView>;
}

export interface SetAddingTeamMemberAction {
  type: typeof SET_ADDING_TEAM_MEMBER;
  addingTeamMember: boolean;
}

export interface SetGeneratingTeamsAction {
  type: typeof SET_GENERATING_TEAMS;
  generatingTeams: boolean;
}

export interface SetTeamAction {
  type: typeof SET_TEAM;
  team: Team;
}

export interface SetTeamsFilter {
  type: typeof SET_TEAMS_FILTER;
  filter: TeamListFilter;
}

export type TeamActionTypes =
  | SetPagedTeamsAction
  | SetTeamsFilter
  | SetTeamAction
  | SetGeneratingTeamsAction
  | SetAddingTeamMemberAction;
