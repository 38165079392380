import React, { FC } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import validationSchema, { ReplaceNewUserSchema } from '../../../validators/replaceNewUserSchema';
import { ApplicationState } from '../../../../../lib/Store';
import { addNewUserToTeam } from '../../../../../lib/Api/Team';
import { Team, ErrorResponse, ParticipantListView } from '../../../../../lib/Types';
import ReplaceNewUser from './ReplaceNewUser';
import { replaceInviteParticipant } from 'src/lib/Api/Participants';

export interface ReplaceNewUserContainerProps {
  team: Team;
  participant: ParticipantListView;
  loading: boolean;
  adding: boolean;
  replaceNewUser: (team: Team, participantId: string, data: ReplaceNewUserSchema) => any;
  addNewUser: (team: Team, data: ReplaceNewUserSchema) => any;
  cancel: () => void;
}

const formikEnhancer = withFormik<ReplaceNewUserContainerProps, ReplaceNewUserSchema>({
  validationSchema,
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: () => ({
    //replace NewUser values
    firstName: '',
    lastName: '',
    email: ''
  }),
  handleSubmit: async (values: ReplaceNewUserSchema, { props, setErrors }) => {
    const { error }: { error: ErrorResponse } = props.adding
      ? await props.addNewUser(props.team, values)
      : await props.replaceNewUser(props.team, props.participant.participantId, values);
    if (error.data.field) setErrors({ [error.data.field]: error.message });
  }
});

const ReplaceParticipantContainer: FC<ReplaceNewUserContainerProps &
  FormikProps<ReplaceNewUserSchema>> = ({ setFieldValue, cancel, ...props }) => {
  const changeCustom = (name: string, data: any) => setFieldValue(name, data);

  return (
    <ReplaceNewUser
      {...props}
      handleDropdownChange={changeCustom}
      handleChangeCustom={changeCustom}
      onCancel={cancel}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  team: state.teamsState.team,
  adding: state.teamsState.addingTeamMember,
  participant: state.participantsState.participant,
  loading: state.loadingState.apiCallsInProgress > 0
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      replaceNewUser: (team: Team, participantId: string, data: ReplaceNewUserSchema) =>
        replaceInviteParticipant(team, participantId, data),
      cancel: () => null,
      addNewUser: (team: Team, data: ReplaceNewUserSchema) => addNewUserToTeam(team, data)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formikEnhancer(ReplaceParticipantContainer));
