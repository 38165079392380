import styled from 'styled-components/macro';

export const AudioContainer = styled.audio`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  border-color: #2a2a2a;
  top: -30px;

  &::-webkit-media-controls-panel {
    border-color: #2a2a2a;
    background-color: #2a2a2a;
  }
  &::-webkit-media-controls-enclosure {
    background-color: #2a2a2a;
    border-radius: 0;
  }
  &::-webkit-media-controls-play-button,
  &::-webkit-media-controls-current-time-display,
  &::-webkit-media-controls-mute-button,
  &::-webkit-media-controls-time-display,
  &::-webkit-media-controls-time-remaining-display,
  &::-webkit-media-controls-timeline,
  &::-webkit-media-controls-volume-slider {
    filter: invert(100);
  }
`;
