import styled from 'styled-components/macro';
import BlackArrow from '../../../../assets/icons/pagination-arrow-blk.svg';

interface PageProps {
  active?: boolean;
}

export const PageInput = styled.input<PageProps>`
  width: 2vw;
  height: 2.3vw;
  padding: 0 0.25rem;
  vertical-align: center;
  text-align: center;

  font-size: ${({ theme }) => theme.fonts.smButton}vw;
  border: 1px solid ${({ theme }) => theme.borders.light};
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  font-weight: ${({ theme }) => theme.fontWeights.light};

  margin-right: 0.25vw;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const PageButton = styled.button<PageProps>`
  background-color: ${({ active = false, theme }) =>
    active ? theme.primary.light : 'transparent'};
  width: fit-content;
  min-width: 1.5vw;
  height: 2.3vw;
  padding: 0 0.5rem;
  vertical-align: center;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.smButton}vw;
  border: 1px solid ${({ theme }) => theme.borders.light};
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  font-weight: ${({ theme }) => theme.fontWeights.light};

  &:not([disabled]) {
    cursor: pointer;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.padding * 1.5}px;

  ${PageButton}:not(last-child) {
    margin-right: 0.8rem;
  }
`;

export const PaginationArrowLeft = styled.button<PageProps>`
  width: 2.1vw;
  height: 2.3vw;
  margin: auto auto;
  border: none;
  background: url(${BlackArrow}) no-repeat center;
  background-size: 25% auto;

  &:not([disabled]) {
    cursor: pointer;
  }

  :disabled {
    opacity: 30%;
  }
`;

export const PaginationArrowLeftWrap = styled.div`
  width: 2.1vw;
  height: 2.3vw;
  border: 1px solid ${({ theme }) => theme.borders.light};
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  margin-right: 0.8rem;
`;

export const PaginationArrowRight = styled.button<PageProps>`
  width: 2.1vw;
  height: 2.3vw;
  margin: auto auto;
  border: none;
  background: url(${BlackArrow}) no-repeat center;
  background-size: 25% auto;
  transform: rotate(180deg);

  &:not([disabled]) {
    cursor: pointer;
  }

  :disabled {
    opacity: 30%;
  }
`;

export const PaginationArrowRightWrap = styled.div`
  width: 2.1vw;
  height: 2.3vw;
  border: 1px solid ${({ theme }) => theme.borders.light};
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
`;
