import moment, { Moment } from 'moment';
import { FormikErrors, withFormik } from 'formik';

import { Activity, ActivityType } from '../../../../lib/Types';
import { ActivityWithTime, AddActivityFormState, AddActivitiesProps } from '../types';
import { AddActivityValidationSchema, EditActivityValidationSchema } from './Config.AddActivities';

export const getActivitiesByDate = (
  activities: Array<Activity>,
  dateMoment: Moment
): ActivityWithTime[] => {
  const activitiesWithTime = activities.map(act => {
    const activityMoment = moment(act.completionDate, 'x');
    const completionTime = activityMoment.format('HH:mm');
    return {
      ...act,
      completionTime
    };
  });

  return activitiesWithTime.filter(activity => {
    return moment(activity.completionDate, 'x').isSame(moment(dateMoment), 'day');
  });
};

export const hasActivity = (activities: Array<Activity>) => (millis: string): boolean => {
  // Returns true if the user has activity entries for the given date.
  for (let i = 0; i < activities.length; i++) {
    if (moment(activities[i].completionDate, 'x').isSame(moment(millis), 'day')) {
      return true;
    }
  }
  return false;
};

export const checkAndEditActivities = (
  initActivities: Array<ActivityWithTime>,
  modifiedActivities: Array<ActivityWithTime>,
  editActivity: (activity: AddActivityValidationSchema, updateRanks?: string) => any,
  stepApprovalThreshold: number,
  clearForm: () => void,
  setFieldValue: (field: string, value: any) => void,
  setErrors: (errors: FormikErrors<AddActivityFormState>) => void,
  stepApprovalReason: string,
  activityTypes: ActivityType[],
  windowLocation: { pathname: string }
): void => {
  for (let i = 0; i < initActivities.length; i++) {
    if (
      initActivities[i].repetitions !== modifiedActivities[i].repetitions ||
      initActivities[i].completionTime !== modifiedActivities[i].completionTime
    ) {
      const activityEntry: EditActivityValidationSchema = {
        activityId: modifiedActivities[i].activityId,
        repetitions: modifiedActivities[i].repetitions,
        stepApprovalReason,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };

      const otherActivitiesSameDay = modifiedActivities.filter(
        modAct =>
          modAct.completionDate === modifiedActivities[i].completionDate &&
          modAct.activityId !== activityEntry.activityId
      );

      let singleDaySteps = [0];
      if (otherActivitiesSameDay.length > 0) {
        singleDaySteps = [
          ...otherActivitiesSameDay.map(singleDayAct => {
            const { conversion } = activityTypes.find(
              actType => singleDayAct.activityTypeId === actType.activityTypeId
            );
            return singleDayAct.repetitions * conversion;
          })
        ];
      }

      const { conversion, name: actTypeName } = activityTypes.find(
        actType => modifiedActivities[i].activityTypeId === actType.activityTypeId
      );
      const newReps =
        actTypeName === 'Steps'
          ? modifiedActivities[i].repetitions
          : modifiedActivities[i].repetitions * conversion;

      const todaysTotalSteps = singleDaySteps.reduce((a, b) => a + b) + newReps;

      const stepThresholdBreached = todaysTotalSteps >= stepApprovalThreshold;
      if (stepThresholdBreached) {
        setFieldValue('stepLimitBreached', true);
        if (stepApprovalReason === '') {
          setErrors({ stepApprovalReason: 'You must provide a reason' });
        } else {
          editActivity(activityEntry, windowLocation.pathname);
          clearForm();
        }
      } else {
        editActivity(activityEntry, windowLocation.pathname);
        clearForm();
      }
    }
  }
};

// Basically a resetForm() that only resets certain fields
export const clearExistingValues = (
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  setStatus: (status?: any) => void
) => {
  setFieldValue('activityTypeId', -1);
  setFieldValue('repetitions', '');
  setFieldValue('stepLimitBreached', false);
  setFieldValue('stepApprovalReason', '');
  setFieldValue('completionTime', '00:00');
  setStatus({ creatingNewActivity: false });
};

export const formikEnhancer = withFormik<AddActivitiesProps, AddActivityFormState>({
  mapPropsToValues: ({ eventId, allActivities }) => ({
    completionDate: moment().format('YYYY-MM-DD'),
    completionTime: '00:00',
    activityTypeId: -1,
    repetitions: null,
    eventId,
    previousActivities: getActivitiesByDate(allActivities, moment()),
    stepApprovalReason: '',
    stepApprovalThreshold: 0,
    stepLimitBreached: false,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    windowLocation: null
  }),
  mapPropsToStatus: () => ({
    creatingNewActivity: false
  }),
  validate: values => {
    const errors: { [key: string]: string } = {};
    if (values.stepApprovalReason === '' && values.stepLimitBreached) {
      errors.stepApprovalReason = 'You must provide a reason for high step count.';
    }

    return errors;
  },
  handleSubmit: async (
    {
      completionDate,
      activityTypeId,
      repetitions,
      eventId,
      previousActivities,
      stepApprovalReason,
      stepLimitBreached,
      windowLocation
    }: AddActivityFormState,
    { props, setErrors, setFieldValue, setStatus }
  ): Promise<void> => {
    checkAndEditActivities(
      getActivitiesByDate(props.allActivities, moment(completionDate)),
      previousActivities,
      props.editActivity,
      props.stepApprovalThreshold,
      () => clearExistingValues(setFieldValue, setStatus),
      setFieldValue,
      setErrors,
      stepApprovalReason,
      props.activityTypes,
      windowLocation
    );

    if (activityTypeId !== -1 && repetitions !== null) {
      if (!props.user.sdaExemption) {
        if (!stepLimitBreached) {
          const singleDayActivities = previousActivities.filter(singleActivity => {
            const { completionDate: saCompletionDate } = singleActivity;
            return moment(saCompletionDate, 'x').format('YYYY-MM-DD') === completionDate;
          });
          // Multiple Activities on same day
          if (singleDayActivities.length > 0) {
            const singleDaySteps = [
              ...singleDayActivities.map(singleDayAct => {
                const { conversion, name: actTypeName } = props.activityTypes.find(
                  actType => singleDayAct.activityTypeId === actType.activityTypeId
                );
                return actTypeName === 'Steps'
                  ? singleDayAct.repetitions
                  : singleDayAct.repetitions * conversion;
              })
            ];

            const { conversion, name: actTypeName } = props.activityTypes.find(
              actType => activityTypeId === actType.activityTypeId
            );
            const newReps = actTypeName === 'Steps' ? repetitions : repetitions * conversion;

            const singleDayTotalSteps = singleDaySteps.reduce((a, b) => a + b) + newReps;
            if (
              singleDayTotalSteps >= props.stepApprovalThreshold &&
              props.stepApprovalThreshold > 0
            ) {
              setFieldValue('stepLimitBreached', true);
              return;
            }
          } else {
            // Get Activity Type conversion value
            const { conversion } = props.activityTypes.find(
              actType => activityTypeId === actType.activityTypeId
            );
            // Step Threshold Check
            if (
              repetitions * conversion >= props.stepApprovalThreshold &&
              props.stepApprovalThreshold > 0
            ) {
              setFieldValue('stepLimitBreached', true);
              return;
            }
          }
        }

        // validate reason & limit breached
        if (stepApprovalReason === '' && stepLimitBreached) {
          setErrors({ stepApprovalReason: 'You must provide a reason todays high step count' });
          return;
        }
      }

      const selectedLocalDate = moment(completionDate).format('YYYY-MM-DD');

      const formattedEventStartDate = moment(props.eventStartDate, 'x').format('YYYY-MM-DD');
      const eventStartDateMoment = moment(formattedEventStartDate);
      const isStartDate = eventStartDateMoment.isSame(selectedLocalDate, 'day');

      const formattedEventEndDate = moment(props.eventEndDate, 'x').format('YYYY-MM-DD');
      const eventEndDateMoment = moment(formattedEventEndDate);
      const isEndDate = eventEndDateMoment.isSame(selectedLocalDate, 'day');

      let nowLocalTime = moment().format('HH:mm');
      if (isStartDate) nowLocalTime = moment(props.eventStartDate, 'x').format('HH:mm');
      else if (isEndDate) nowLocalTime = moment(props.eventStartDate, 'x').format('HH:mm');

      const nowMilliseconds = moment(`${selectedLocalDate}T${nowLocalTime}`)
        .valueOf()
        .toString();

      // Create a new activity if values are defined
      const activityEntry = {
        completionDate: nowMilliseconds,
        activityTypeId,
        eventId,
        repetitions: repetitions,
        stepApprovalReason,
        stepLimitBreached,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      const res = await props.addActivity(activityEntry, windowLocation.pathname);
      clearExistingValues(setFieldValue, setStatus);
      const { error } = res;
      if (error) setErrors({ [error.context]: error.message });
    }
  }
});
