import { server } from '../../CONSTANTS';
import {
  Reward,
  APIThunkResult,
  APIResult,
  ProgressCallback,
  PaginationFilter,
  PagedResponse
} from '../Types';
import { apiRequest, apiFetch, objectToQueryString } from './Utils';
import {
  setRewards,
  addReward,
  setDeletingReward,
  setRewardOptions
} from '../Store/contexts/rewards/actions';
import { CreateRewardSchema } from '../validators/createRewardSchema';
import { setNotification } from '../Store/contexts/notification/actions';
import { setUserRewards, setRewardsToComplete } from '../Store/contexts/user/actions';
import { uploadMedia } from './Common';

const REWARDS_URL = `${server}/rewards`;

export type FetchRewards = (
  //force?: boolean,
  filter?: PaginationFilter
) => Promise<APIResult<PagedResponse<Reward>>>;
export const fetchRewards = (
  //force = false,
  filter = null
): APIThunkResult<PagedResponse<Reward>> => {
  return apiRequest<PagedResponse<Reward>>(async dispatch => {
    try {
      // if (!force) {
      //   const { rewards: existingRewards } = getState().rewardsState;
      //   if (existingRewards.results.length > 0) return existingRewards;
      // }

      const query = filter ? objectToQueryString(filter) : '';

      const response = await apiFetch<PagedResponse<Reward>>({
        method: 'GET',
        url: `${REWARDS_URL}/paged?${query}`
      });

      dispatch(setRewards(response));
      return response;
    } catch (err) {
      dispatch(
        setNotification({
          message: 'Something went wrong getting a list of rewards. Please try again later.',
          variant: 'danger'
        })
      );
      throw err;
    }
  });
};

export type FetchRewardOptions = () => Promise<APIResult<Reward[]>>;
export const fetchRewardOptions = (): APIThunkResult<Reward[]> => {
  return apiRequest<Reward[]>(async dispatch => {
    try {
      const response = await apiFetch<{ rewards: Reward[] }>({
        method: 'GET',
        url: `${REWARDS_URL}/`
      });

      dispatch(setRewardOptions(response.rewards));
      return response.rewards;
    } catch (err) {
      dispatch(
        setNotification({
          message: 'Something went wrong getting a list of all rewards. Please try again later.',
          variant: 'danger'
        })
      );
      throw err;
    }
  });
};

export type UploadImage = (
  image: File,
  onProgress?: ProgressCallback
) => Promise<APIResult<string | null>>;
export const uploadRewardImage = (
  image: File,
  onProgress?: ProgressCallback
): APIThunkResult<string | null> => uploadMedia(image, onProgress, 'rewards/media');

export type GetMyRewards = () => Promise<APIResult<Reward[]>>;
export const getMyRewards = (): APIThunkResult<Reward[]> => {
  return apiRequest<Reward[]>(async dispatch => {
    const { rewards } = await apiFetch<{ rewards: Reward[] }>({
      method: 'GET',
      url: `${server}/users/me/rewards`
    });

    dispatch(setUserRewards(rewards));
    return rewards;
  });
};

export type LoadRewardsToComplete = () => Promise<APIResult<Reward[]>>;
export const loadRewardsToComplete = (): APIThunkResult<Reward[]> => {
  return apiRequest<Reward[]>(async dispatch => {
    const rewards = await apiFetch<Reward[]>({
      method: 'GET',
      url: `${server}/users/me/rewards/connection/unachieved`
    });

    dispatch(setRewardsToComplete(rewards));
    return rewards;
  });
};

export type CreateReward = (data: CreateRewardSchema) => Promise<APIResult<Reward>>;
export const createReward = (data: CreateRewardSchema): APIThunkResult<Reward> => {
  return apiRequest<Reward>(async dispatch => {
    try {
      const { reward } = await apiFetch<{ reward: Reward }>({
        method: 'POST',
        url: `${REWARDS_URL}/create`,
        body: data
      });

      dispatch(setNotification({ message: 'Reward created!' }));
      dispatch(addReward(reward));
      return reward;
    } catch (error) {
      dispatch(
        setNotification({
          message: 'Something went wrong. Please try again later',
          variant: 'danger'
        })
      );
      throw error;
    }
  });
};

export type UpdateReward = (reward: Reward) => APIThunkResult<Reward>;
export const updateReward = (reward: Reward): APIThunkResult<Reward> => {
  return apiRequest<Reward>(async dispatch => {
    try {
      const { rewardRes } = await apiFetch<{ rewardRes: Reward }>({
        method: 'PUT',
        url: `${REWARDS_URL}/edit`,
        body: reward
      });

      return rewardRes;
    } catch (err) {
      dispatch(
        setNotification({
          message: 'Something went wrong trying to update the reward. Please try again later.',
          variant: 'danger'
        })
      );
      throw err;
    }
  });
};

export type DeleteReward = (rewardId: number) => Promise<APIThunkResult<any>>;
export const deleteReward = (rewardId: number): APIThunkResult<any> => {
  return apiRequest<any>(async (dispatch, getState) => {
    try {
      const res = await apiFetch<APIResult<any>>({
        method: 'DELETE',
        url: `${REWARDS_URL}/delete/${rewardId}`
      });

      const filter = getState().rewardsState.rewardsFilter;

      dispatch(setDeletingReward(false));
      dispatch(fetchRewards(filter));
      dispatch(
        setNotification({
          message: 'Reward successfully deleted.',
          variant: 'success'
        })
      );

      return res;
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          variant: 'danger'
        })
      );
      throw err;
    }
  });
};
