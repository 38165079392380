import React, { FC } from 'react';
import { Modal, ModalButton } from '../../../../../components/UI';
import { PendingParticipant } from '../../../../../lib/Types';
import { ColumnWrapper, FormColumn, ButtonWrapper, Container } from './Styles';
import { DarkBlueBodyM, DarkBlueBodyL } from '../../../../../Themes';
import { eventGuy } from '../../../../../assets/icons';
import LoadingIndicator from '../../../../../components/UI/LoadingIndicator';

interface DeleteParticipantProps {
  pendingParticipant: PendingParticipant;
  loading: boolean;
  onCancel: () => void;
  onDelete: (teamId: number, email: string) => void;
}

const DeleteParticipant: FC<DeleteParticipantProps> = ({
  loading,
  pendingParticipant,
  onCancel,
  onDelete
}) => (
  <Container>
    <Modal
      icon={eventGuy}
      width={55}
      padding={'3rem 5rem'}
      title={'Delete Pending Registration'}
      titlePadding={'0px 100px 15px 0px'}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <ColumnWrapper>
            <FormColumn>
              <DarkBlueBodyL>
                {pendingParticipant.firstName} {pendingParticipant.lastName} -{' '}
                {pendingParticipant.email}
              </DarkBlueBodyL>
              <br />
              <DarkBlueBodyM>
                Are you sure you wish to delete this pending registration? <br /> This action cannot
                be undone and will remove the pending registration for this user for this team.
              </DarkBlueBodyM>
              <br />
            </FormColumn>
          </ColumnWrapper>

          <ButtonWrapper>
            <ModalButton
              type={'submit'}
              variant={'remove'}
              onClick={() => onDelete(pendingParticipant.teamId, pendingParticipant.email)}
            >
              Yes, Remove
            </ModalButton>
            <ModalButton onClick={onCancel} type="button" variant={'primary'}>
              No, Keep
            </ModalButton>
          </ButtonWrapper>
        </>
      )}
    </Modal>
  </Container>
);

export default DeleteParticipant;
