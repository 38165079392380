import React, { FC, useState } from 'react';
import { TableLink } from '..';
import { arrow, removeCircle, replaceParticipantIcon } from '../../../assets/icons';
import { DEFAULT_PIC } from '../../../CONSTANTS';
import { ParticipantListView, PendingParticipant, Team } from '../../../lib/Types';
import { LightGreyText } from '../../../Themes';
import { RadioOption } from '../RadioGroup/RadioGroup';
import {
  Row,
  NotJoined,
  Joined,
  ProfilePic,
  RemoveText,
  ResendText,
  ReplaceText,
  TableCell
} from './Styles';

export interface TeamTableProps {
  members: Array<ParticipantListView>; // List of participants to display in the table
  team: Team;
  teamCaptainView?: boolean; // True iff table is displayed to team captain
  adminView?: boolean; // True iff table is displayed to admin in edit team view
  listOffset?: number; // Number to offset table rows by
  teamHasRegistrations?: boolean; // True iff table is displaying team with non zero registrations
  teamHasPendingRegistrations?: boolean; // True iff table is displaying team with non zero pending registrations
  resendInvitationEmail?: (email: string) => void; // Function to resend an invitation email to pending registration
  removeRegistration?: (team: Team, userId: string) => void; // Function to remove a registered team member
  handleCaptainRadioChange?: (name: string, value: any) => void; // Function to handle captain change
  triggerReplaceParticipant?: (participantId: string) => void; // Function to replace team member
  setDeleting?: (data: PendingParticipant, deletingParticipant: boolean) => void; // Function to set removing a pending team member
  removeTeamMate?: (member: ParticipantListView) => void; // Function to remove own team mate
}

const TeamTable: FC<TeamTableProps> = ({
  members,
  team,
  teamCaptainView,
  adminView,
  listOffset = 0,
  teamHasRegistrations = false,
  teamHasPendingRegistrations = false,
  resendInvitationEmail,
  removeRegistration,
  handleCaptainRadioChange,
  triggerReplaceParticipant,
  setDeleting,
  removeTeamMate
}) => {
  const initCaptainArray = members.map(data => {
    return { teamCaptain: data.teamCaptain, userId: data.participantId };
  });

  const [captainArray, setCaptainArray] = useState(initCaptainArray);

  const handleRadioClick = (index: number) => {
    const cleanedArray = captainArray.map(item => {
      item.teamCaptain = false;
      return item;
    });
    cleanedArray[index].teamCaptain = !cleanedArray[index].teamCaptain;
    setCaptainArray(cleanedArray);
    handleCaptainRadioChange('teamCaptainId', cleanedArray[index].userId);
  };

  const joinedStatusCell = (member: ParticipantListView) => {
    return member.joinedStatus ? <Joined>Joined</Joined> : <NotJoined>Not Yet Joined</NotJoined>;
  };

  const onDelete = (data: PendingParticipant, deletingParticipant: boolean) =>
    setDeleting(data, deletingParticipant);

  // Can only resend invite iff member is not already registered and if current user is team captain
  const resendInviteCell = (member: ParticipantListView) => {
    return !member.joinedStatus ? (
      <ResendText onClick={() => resendInvitationEmail(member.email)}>
        <TableLink verticalAlign={'sub'} size={'20%'} source={arrow} margin={'0 0.2rem 0 0'} />{' '}
        Resend Invite
      </ResendText>
    ) : null;
  };

  // Replace participant iff not team captain and is joined
  const replaceParticipantCell = (member: ParticipantListView) => {
    return !member.teamCaptain && member.joinedStatus ? (
      <ReplaceText onClick={() => triggerReplaceParticipant(member.participantId)}>
        <TableLink
          verticalAlign={'middle'}
          size={'13%'}
          margin={'0 0.5rem 0 0'}
          source={replaceParticipantIcon}
        />
        {'Replace Participant'}
      </ReplaceText>
    ) : null;
  };

  const changeCaptainCell = (member: ParticipantListView, index: number) => {
    if (!member.joinedStatus) return;
    return captainArray[index].teamCaptain ? (
      <RadioOption
        margin={false}
        body=""
        label=""
        selected={true}
        onClick={() => handleRadioClick(index)}
        inline={false}
      />
    ) : (
      <RadioOption
        margin={false}
        body=""
        label=""
        selected={false}
        onClick={() => handleRadioClick(index)}
        inline={false}
      />
    );
  };

  // TODO: can definitely be improved but not at 11pm on a sunday night
  // Team member can be removed if is not team captain, current user is captain or admin.
  const removeMemberCell = (member: ParticipantListView) => {
    if ((teamCaptainView || (adminView && member.joinedStatus)) && !member.teamCaptain) {
      return (
        <RemoveText
          onClick={() =>
            teamCaptainView
              ? removeTeamMate(member)
              : removeRegistration(team, member.participantId)
          }
        >
          <TableLink
            actionButton
            size={'26%'}
            source={removeCircle}
            margin={'0 3px 2px 0'}
            verticalAlign={'middle'}
          />
          Remove
        </RemoveText>
      );
    } else if (!member.teamCaptain && adminView) {
      return (
        <RemoveText
          onClick={() =>
            onDelete(
              {
                firstName: member.firstName,
                lastName: member.lastName,
                email: member.email,
                teamId: member.teamId
              },
              true
            )
          }
        >
          <TableLink
            actionButton
            size={'26%'}
            source={removeCircle}
            margin={'0 3px 2px 0'}
            verticalAlign={'middle'}
          />
          Remove
        </RemoveText>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {members.map((member, index) => {
        return (
          <Row key={index}>
            <TableCell>{index + 1 + listOffset}</TableCell>
            <TableCell>
              <ProfilePic image={member.profileImage ? member.profileImage : DEFAULT_PIC} />
            </TableCell>
            <TableCell>
              {member.firstName} {member.lastName}
              {member.teamCaptain && <LightGreyText> (Captain)</LightGreyText>}
            </TableCell>
            <TableCell> {joinedStatusCell(member)} </TableCell>
            {/** If adminView, replaceParticipant, resendInvite else null**/}
            {adminView ? (
              <TableCell>
                {teamHasRegistrations
                  ? replaceParticipantCell(member)
                  : teamHasPendingRegistrations
                  ? resendInviteCell(member)
                  : null}
              </TableCell>
            ) : !adminView ? (
              <TableCell> {teamCaptainView ? resendInviteCell(member) : null}</TableCell>
            ) : null}
            <TableCell>{removeMemberCell(member)}</TableCell>
            {adminView ? <TableCell> {changeCaptainCell(member, index)} </TableCell> : null}
          </Row>
        );
      })}
    </>
  );
};

export default TeamTable;
