import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 14px;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  padding: 2.5rem;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.backgrounds.white};
`;
