import React, { FC, useEffect } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import { ParticipantSchema } from '../../validators/participantSchema';
import { ApplicationState } from '../../../../lib/Store';
import { setParticipant } from '../../../../lib/Store/contexts/participants/actions';
import {
  loadParticipant,
  loadParticipantDemographics,
  loadParticipantTeam,
  editParticipant,
  editUser
} from '../../../../lib/Api/Participants';
import { loadCompanyOptions, loadCompany } from '../../../../lib/Api/Company';
import {
  ParticipantListView,
  Company,
  ErrorResponse,
  Team,
  UserDemographic,
  Permission,
  CompanyDemographic
} from '../../../../lib/Types';
import EditParticipant from './EditParticipant';
import validationSchema from '../../validators/participantSchema';

export interface EditParticipantContainerProps {
  participant: ParticipantListView;
  participantTeam: Team;
  permissions: Permission[];
  userDemographics: UserDemographic[];
  companyOptions: Company[];
  companyDemographics: CompanyDemographic[];
  loading: boolean;
  editParticipant: (
    participantId: string,
    eventId: number,
    teamId: number,
    data: ParticipantSchema
  ) => any;
  loadParticipant: (participantId: string) => any;
  loadParticipantTeam: (teamId: number) => any;
  loadParticipantDemographics: (participantId: string) => any;
  loadCompanyOptions: (eventId: number) => void;
  loadCompanyDemographics: (companyId: number) => void;
  cancel: () => void;
}

const formikEnhancer = withFormik<EditParticipantContainerProps, ParticipantSchema>({
  validationSchema,
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: ({ participant, userDemographics }) => ({
    firstName: participant.firstName,
    lastName: participant.lastName,
    companyId: participant.companyId,
    email: participant.email,
    password: '',
    sdaExemption: participant.sdaExemption,
    active: participant.userActive,
    userDemographics: userDemographics.map(data => {
      return {
        userDemographicId: data.userDemographicId,
        childDemographicId: data.childDemographicId,
        childDemographicAnswer: data.childDemographicAnswer ? data.childDemographicAnswer : null
      };
    })
  }),
  handleSubmit: async (values: ParticipantSchema, { props, setErrors }) => {
    const eventId = props.participantTeam.eventId || props.participant.eventId;
    const { error }: { error: ErrorResponse } = await props.editParticipant(
      props.participant.participantId,
      eventId,
      props.participant.teamId,
      values
    );
    if (error.data.field) setErrors({ [error.data.field]: error.message });
  }
});

const EditParticipantContainer: FC<EditParticipantContainerProps &
  FormikProps<ParticipantSchema>> = ({
  setFieldValue,
  cancel,
  participant,
  permissions,
  loadParticipant,
  loadParticipantTeam,
  loadParticipantDemographics,
  loadCompanyOptions,
  loadCompanyDemographics,
  ...props
}) => {
  useEffect(() => {
    if (permissions.includes(Permission.VIEW_COMPANIES)) {
      loadCompanyOptions(participant.eventId);
    }
    loadParticipant(participant.participantId);
    loadParticipantTeam(participant.teamId);
    loadParticipantDemographics(participant.participantId);
    loadCompanyDemographics(participant.companyId);
  }, []);

  const changeCustom = (name: string, data: any) => setFieldValue(name, data);

  return (
    <EditParticipant
      {...props}
      participant={participant}
      handleChangeCustom={changeCustom}
      onCancel={cancel}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => {
  const companyOptions = state.permissionsState.permissions.includes(Permission.VIEW_COMPANIES)
    ? state.companyState.companyOptions
    : [
        { companyId: state.userState.userCompany.companyId, name: state.userState.userCompany.name }
      ];

  return {
    participant: state.participantsState.participant,
    companyOptions,
    companyDemographics: state.participantEventState.companyDemographics,
    participantTeam: state.participantsState.participantTeam,
    userDemographics: state.participantEventState.userDemographics.sort(
      (demo1, demo2) => demo1.companyDemographicId - demo2.companyDemographicId
    ),
    loading: state.loadingState.apiCallsInProgress > 0,
    permissions: state.permissionsState.permissions
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editParticipant: (
        participantId: string,
        eventId: number,
        teamId: number,
        data: ParticipantSchema
      ) =>
        eventId || teamId
          ? editParticipant(participantId, eventId, teamId, data)
          : editUser(participantId, data),
      loadParticipant: (participantId: string) => loadParticipant(participantId),
      loadParticipantTeam: (teamId: number) => loadParticipantTeam(teamId),
      loadParticipantDemographics: (participantId: string) =>
        loadParticipantDemographics(participantId),
      loadCompanyOptions: (eventId: number) => loadCompanyOptions(eventId),
      loadCompanyDemographics: (companyId: number) => loadCompany(companyId),
      cancel: () => setParticipant({} as ParticipantListView)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formikEnhancer(EditParticipantContainer));
