import styled from 'styled-components/macro';
import theme from '../../../../../theme';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  width: 96%;
  align-items: center;
  justify-content: center;
  padding: 20px;
  & + & {
    padding-top: 0;
  }
`;

export const Name = styled.div`
  background: ${theme.backgrounds.white};
  border: 1px solid ${theme.primary.white};
  border-radius: 40px;
  font-family: ${theme.fontFamily.heading};
  font-size: 16px;
  font-style: italic;
  color: #ef7d84;
  letter-spacing: 0.2px;
  cursor: default;
  padding: 4px 22px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RemoveButton = styled.div`
  display: flex;
  cursor: pointer;
  margin: 0 0 0 auto;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const RemoveIcon = styled.img`
  vertical-align: middle;
  display: flex;
  width: 1.8rem;
  margin: 0 1rem 0 auto;
`;

export const RemoveText = styled.span`
  font-family: ${theme.fontFamily.heading};
  font-size: 14px;
  font-weight: ${theme.fontWeights.light};
  color: ${theme.backgrounds.white};
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
`;

export const Description = styled.div`
  font-family: ${theme.fontFamily.heading};
  font-size: 16px;
  color: ${theme.backgrounds.white};
  letter-spacing: 0.2px;
  cursor: default;
  margin-right: auto;
`;

export const Count = styled.input`
  border: 1px solid ${theme.backgrounds.white};
  border-radius: 8px;
  font-family: ${theme.fontFamily.heading};
  font-size: 16px;
  color: ${theme.backgrounds.white};
  letter-spacing: 0.21px;
  padding: 3px 10px 5px;
  text-align: right;
  background: transparent;
  margin: 0 0 0 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 92px;
  &::placeholder {
    color: white;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;
