import styled, { keyframes } from 'styled-components/macro';
import { darken } from 'polished';
import { StatusVariant } from '../../../lib/Types';

interface ToastProps {
  variant: StatusVariant;
}

const fromTop = keyframes`
  from {
    transform: translate(-50%, -100%);
  }

  to {
    transform: translate(-50%, 0);
  }
`;

const toTop = keyframes`
  from {
    transform: translate(-50%, 0);
  }

  to {
    transform: translate(-50%, -100%);
  }
`;

export const Toast = styled.div<ToastProps>`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 110;
  background-color: ${({ theme, variant }) => theme.status[variant]};
  width: 36rem;
  height: 7rem;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  top: 0;
  cursor: pointer;

  &.show {
    animation: ${fromTop} 0.5s;
  }

  &.hide {
    animation: ${toTop} 0.5s;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const IconContainer = styled.div<ToastProps>`
  position: relative;
  height: 28px;
  width: 50px;
  margin: 0 1.4rem;
  background-color: ${({ theme, variant }) => darken(0.2, theme.status[variant])};
  border-radius: 10rem;
  align-self: center;
`;

export const Icon = styled.img`
  position: absolute;
  width: 1.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Label = styled.p`
  align-self: center;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: white;
`;
