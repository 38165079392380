import React, { FC } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { updateCompanyDetails } from '../../../lib/Api/companyAdmin/Company';

import { DarkBlueHeaderL, DarkBlueHeaderM, FlexRow, DarkBlueBodyM } from '../../../Themes';
import CompanyLogoUpload from '../../../pages/Companies/CreateOrEditCompany/CompanyLogoUpload';
import ModalButton from '../Button/ModalButton';
import Input from '../Input';

import editCompanyDetailsSchema from '../../../lib/validators/editCompanyDetailsSchema';
import { ApplicationState } from '../../../lib/Store';
import { APIThunkDispatch } from '../../../lib/Types';

import { Wrapper, ContentRow, DetailsColumn, LogoColumn } from './Styles';

export interface CompanyDetailsEditCardProps {}

const CompanyDetailsEditCard: FC<CompanyDetailsEditCardProps> = ({}) => {
  const Dispatch: APIThunkDispatch = useDispatch();
  const { company } = useSelector(({ userState }: ApplicationState) => ({
    company: userState.userCompany
  }));

  return (
    <Formik
      validationSchema={editCompanyDetailsSchema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      enableReinitialize
      initialValues={{
        name: company.name || '',
        profileImage: company.profileImage || ''
      }}
      onSubmit={async values => {
        await Dispatch(updateCompanyDetails(company, values));
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleChange,
        setFieldValue,
        setFieldError,
        handleSubmit,
        resetForm
      }) => {
        return (
          <Wrapper>
            <DarkBlueHeaderL>Edit Your Company Details</DarkBlueHeaderL>
            <FlexRow padding="2rem 0" height="auto">
              <DarkBlueHeaderM flex={1}>Organisation Details</DarkBlueHeaderM>
            </FlexRow>
            <ContentRow>
              <DetailsColumn>
                <Input
                  required
                  id="name"
                  name="name"
                  label="Company Name"
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name}
                />
                <br />
                <DarkBlueBodyM>
                  Number of employee accounts:&nbsp;
                  {company?.registeredEmployees || 0}
                </DarkBlueBodyM>
                <DarkBlueBodyM>
                  This is the number of participants that will pre-fill all event forms when
                  registering for events
                </DarkBlueBodyM>
              </DetailsColumn>
              <LogoColumn>
                <CompanyLogoUpload
                  value={values.profileImage}
                  setFieldError={setFieldError}
                  setValue={val => setFieldValue('profileImage', val)}
                />
                <FlexRow justify="flex-end" style={{ marginTop: '2rem' }}>
                  <ModalButton
                    onClick={() => resetForm()}
                    type="button"
                    variant={'secondary'}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </ModalButton>
                  <ModalButton
                    onClick={() => handleSubmit()}
                    type="button"
                    variant={'primary'}
                    disabled={isSubmitting}
                  >
                    Save
                  </ModalButton>
                </FlexRow>
              </LogoColumn>
            </ContentRow>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

export default CompanyDetailsEditCard;
