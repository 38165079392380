import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import PieChart from './PieChart';

import {
  pumpingIron,
  connectionBadge,
  gratitudeBadge,
  exerciseBadge,
  laughterBadge,
  sleepBadge,
  nutritionBadge,
  mindfulnessBadge
} from '../../../assets/icons';
import {
  HeaderWrapper,
  Header,
  ContentWrapper,
  LegendWrapper,
  MascotIcon,
  KeyWrapper,
  KeyIcon,
  KeyName,
  KeyValue
} from './Styles';
import { DashboardPanelWrapper } from '../DashboardTeamConnectionPoints/Styles';

import { PostType } from '../../../lib/Types';

import { ApplicationState } from '../../../lib/Store';
import { DashboardState } from '../../../lib/Store/contexts/participant/dashboard/types';

const pointsInit = {
  [PostType.Connection]: 0,
  [PostType.Gratitude]: 0,
  [PostType.Exercise]: 0,
  [PostType.Laugh]: 0,
  [PostType.Sleep]: 0,
  [PostType.Nutrition]: 0,
  [PostType.Mindfulness]: 0
};

export type PointsType = typeof pointsInit;

const DashboardTopConnectionActivities: FC = () => {
  const { topConnection } = useSelector<ApplicationState, DashboardState>(
    state => state.participantDashboardState
  );

  const getPointsData = () => {
    const totalPoints = Object.assign({}, pointsInit);
    if (!topConnection) return totalPoints;
    topConnection.forEach(row => (totalPoints[row.postType] += row.rewardPoints));
    return totalPoints;
  };

  const buildLegend = () => {
    return Object.keys(PostType).map(type => {
      if (type === PostType.General) return;
      let badge;
      switch (type) {
        case PostType.Connection:
          badge = connectionBadge;
          break;
        case PostType.Gratitude:
          badge = gratitudeBadge;
          break;
        case PostType.Exercise:
          badge = exerciseBadge;
          break;
        case PostType.Laugh:
          badge = laughterBadge;
          break;
        case PostType.Sleep:
          badge = sleepBadge;
          break;
        case PostType.Nutrition:
          badge = nutritionBadge;
          break;
        case PostType.Mindfulness:
          badge = mindfulnessBadge;
          break;
      }
      return (
        <KeyWrapper key={type}>
          <KeyIcon src={badge} />
          <KeyName>{PostType[type]}</KeyName>
          <KeyValue>{getPointsData()[type]}</KeyValue>
        </KeyWrapper>
      );
    });
  };

  return (
    <DashboardPanelWrapper>
      <HeaderWrapper>
        <Header>Top Connection Activities</Header>
      </HeaderWrapper>
      <ContentWrapper>
        <LegendWrapper>{buildLegend()}</LegendWrapper>
        <MascotIcon src={pumpingIron} />
        <PieChart data={getPointsData()} />
      </ContentWrapper>
    </DashboardPanelWrapper>
  );
};

export default DashboardTopConnectionActivities;
