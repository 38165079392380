import {
  EventListView,
  PagedResponse,
  CompanyRegistrationData,
  PaginationFilter
} from '../../../../Types';
import {
  SetEventsAction,
  SetPagedCompaniesInEventAction,
  SetCompaniesInEventFilterAction,
  SET_EVENTS,
  SET_PAGED_COMPANIES_IN_EVENT,
  SET_COMPANIES_IN_EVENT_FILTER
} from './types';

export const setEvents = (events: PagedResponse<EventListView>): SetEventsAction => ({
  type: SET_EVENTS,
  events
});

export const setPagedCompaniesInEvent = (
  pagedCompaniesInEvent: PagedResponse<CompanyRegistrationData>
): SetPagedCompaniesInEventAction => ({
  type: SET_PAGED_COMPANIES_IN_EVENT,
  pagedCompaniesInEvent
});

export const setCompaniesInEventFilter = (
  companiesInEventFilter: PaginationFilter
): SetCompaniesInEventFilterAction => ({
  type: SET_COMPANIES_IN_EVENT_FILTER,
  companiesInEventFilter
});

export default {
  setEvents,
  setPagedCompaniesInEvent,
  setCompaniesInEventFilter
};
