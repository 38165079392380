import { Reducer } from 'redux';
import {
  TeamState,
  TeamActionTypes,
  SET_TEAM,
  SET_TEAM_MEMBER_REGISTRATIONS,
  SET_INDIVIDUAL_EVENT_REGISTRATION,
  SET_TEAM_DATA
} from './types';

export const initialTeamState: TeamState = {
  team: null,
  teamMemberRegistrations: [],
  individualEventRegistration: null
};

const DashboardReducer: Reducer<TeamState, TeamActionTypes> = (
  state = initialTeamState,
  action
) => {
  switch (action.type) {
    case SET_TEAM:
      return { ...state, team: action.team };
    case SET_TEAM_MEMBER_REGISTRATIONS:
      return { ...state, teamMemberRegistrations: action.teamMemberRegistrations };
    case SET_INDIVIDUAL_EVENT_REGISTRATION:
      return { ...state, individualEventRegistration: action.individualEventRegistration };
    case SET_TEAM_DATA:
      return {
        ...state,
        team: action.team,
        teamMemberRegistrations: action.teamMemberRegistrations,
        individualEventRegistration: action.individualEventRegistration
      };
    default:
      return state;
  }
};

export default DashboardReducer;
