import * as yup from 'yup';

const editCompanyDetailsSchema = yup.object({
  name: yup.string().required(),
  profileImage: yup.string().required()
});

export type EditCompanyDetailsSchema = yup.InferType<typeof editCompanyDetailsSchema>;

export default editCompanyDetailsSchema;
