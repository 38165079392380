import React, { FC } from 'react';
import { Formik } from 'formik';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { createAdmin, editAdmin, CreateAdmin, EditAdmin } from '../../lib/Api/companyAdmin/Company';

import { FormControl, Input, ModalButton } from '../../components/UI';
import BackButton from '../../components/UI/Button/BackButton';

import { FormWrapper, InputGroupHeading, InputGroup, ButtonWrapper } from './Styles';
import { PageHeader } from '../Events/components/CreateEvent/Styles';

import { ApiStatus, CompanyAdminListView, Company } from '../../lib/Types';

import signupCompanyAdminSchema, {
  SignupCompanyAdminSchema
} from '../../lib/validators/signupCompanyAdminSchema';

export interface CreateOrEditCompanyAdminProps {
  companyAdmin?: CompanyAdminListView; // infer editing from presence
  company: Company;
  onCancel?: () => void;
  onSuccess?: () => void;
  onCreateAdmin: CreateAdmin;
  onEditAdmin: EditAdmin;
}

const CreateOrEditCompanyAdmin: FC<CreateOrEditCompanyAdminProps> = ({
  companyAdmin,
  company,
  onCancel,
  onSuccess,
  onCreateAdmin,
  onEditAdmin
}) => {
  return (
    <>
      <PageHeader>
        <BackButton onClick={onCancel} />
        {!companyAdmin ? 'Create an Admin' : 'Edit Admin'}
      </PageHeader>
      <Formik
        initialValues={{
          firstName: companyAdmin?.firstName || '',
          lastName: companyAdmin?.lastName || '',
          email: companyAdmin?.email || '',
          phone: companyAdmin?.phone || '',
          password: '',
          companyId: company.companyId
        }}
        validationSchema={signupCompanyAdminSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        enableReinitialize
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          const { status, error } = companyAdmin
            ? await onEditAdmin(companyAdmin.companyAdminId, values)
            : await onCreateAdmin(values);
          if (status === ApiStatus.SUCCESS) onSuccess();
          if (error.data.field) setErrors({ [error.data.field]: error.message });
          setSubmitting(false);
        }}
      >
        {({ values, errors, isSubmitting, handleSubmit, handleChange }) => {
          return (
            <>
              <FormWrapper>
                <InputGroup>
                  <FormControl>
                    <InputGroupHeading>Admin Details</InputGroupHeading>
                    <Input
                      required
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      value={values.firstName}
                      onChange={handleChange}
                      error={errors.firstName}
                    />
                  </FormControl>
                  <FormControl>
                    <Input
                      required
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      value={values.lastName}
                      onChange={handleChange}
                      error={errors.lastName}
                    />
                  </FormControl>
                  <FormControl>
                    <Input
                      required
                      id="phone"
                      name="phone"
                      label="Phone"
                      value={values.phone}
                      onChange={handleChange}
                      error={errors.phone}
                    />
                  </FormControl>
                  <FormControl>
                    <Input
                      required
                      id="email"
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      error={errors.email}
                    />
                  </FormControl>
                </InputGroup>
                <ButtonWrapper>
                  <ModalButton
                    onClick={onCancel}
                    type="button"
                    variant={'secondary'}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </ModalButton>
                  <ModalButton
                    onClick={() => handleSubmit()}
                    type="submit"
                    variant={isSubmitting ? 'disabled' : 'primary'}
                    disabled={isSubmitting}
                  >
                    {companyAdmin ? 'Save' : 'Create'}
                  </ModalButton>
                </ButtonWrapper>
              </FormWrapper>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onCreateAdmin: (data: SignupCompanyAdminSchema) => createAdmin(data) as any,
      onEditAdmin: (companyAdminId: number, data: SignupCompanyAdminSchema) =>
        editAdmin(companyAdminId, data) as any
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CreateOrEditCompanyAdmin);
