import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ExternalPageWrapper, Button } from '../../../../components/UI/index';
import { BodyText } from '../ForgotPasswordForm/Styles';
import theme from '../../../../theme';

export interface InstructionsSentProps {}

const InstructionsSent: FC<InstructionsSentProps> = () => {
  return (
    <ExternalPageWrapper header="Instruction sent" backLink="/Login">
      <BodyText>
        We have sent reset details for your password to the email address you entered.
      </BodyText>
      <Link to="/Login">
        <Button maxWidth={'440px'} margin={`${theme.padding * 2}px 0px`} variant={'primary'}>
          Back to log in
        </Button>
      </Link>
    </ExternalPageWrapper>
  );
};

export default InstructionsSent;
