export default {
  development: {
    apiKey: 'AIzaSyD12wb4RaOQyg3E_SsCbcHzn4WRUwW8-ZA',
    authDomain: 'movingmindz-uat-350005.firebaseapp.com',
    projectId: 'movingmindz-uat-350005',
    storageBucket: 'movingmindz-uat-350005.appspot.com',
    messagingSenderId: '223616032964',
    appId: '1:223616032964:web:63dd71d13ff565d029e991',
    measurementId: 'G-LYSQMRM6ZJ'
  },
  production: {
    apiKey: 'AIzaSyAxRUwPHATc5we-t_PHs7YBvM_oeKGtMLA',
    authDomain: 'movingmindz-prod-350100.firebaseapp.com',
    projectId: 'movingmindz-prod-350100',
    storageBucket: 'movingmindz-prod-350100.appspot.com',
    messagingSenderId: '1061866308361',
    appId: '1:1061866308361:web:40cf5571391810ca4889d8',
    measurementId: 'G-ZX8SP48Z0B'
  }
};
