import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 14px;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  padding: 2.5rem;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.backgrounds.white};
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: center;
  justify-content: space-between;
`;

export const DetailsColumn = styled.div`
  width: 45%;
`;

export const LogoColumn = styled.div`
  width: 49%;
`;
