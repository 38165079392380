import { getClientTimezoneOffset } from '../TimeUtils';
import { getDashboardStatsData } from './User';
import { loadMyTeamStats } from './Stats';
import { getMyDashboardData } from './participant/Dashboard';
import { apiRequest, apiFetch } from './Utils';
import { server } from '../../CONSTANTS';

import { APIThunkResult, Activity } from '../Types';
import {
  AddActivityValidationSchema,
  EditActivityValidationSchema
} from '../../components/UI/AddActivities/services/Config.AddActivities';
import {
  addEventActivity,
  editEventActivity,
  deleteEventActivity
} from '../Store/contexts/user/actions';
import { setNotification } from '../Store/contexts/notification/actions';
import { checkUpdateLeaderBoards } from './participant/LeaderBoard';

export function addActivity(
  activityEntry: AddActivityValidationSchema,
  updateRanks?: string // If adding an activity on table showing ranks, update state.
): APIThunkResult<Activity> {
  return apiRequest<Activity>(
    async (dispatch, getState) => {
      const res = await apiFetch<Activity>({
        method: 'POST',
        url: `${server}/activities/add/${getClientTimezoneOffset()}`,
        body: activityEntry
      });

      dispatch(addEventActivity(res));
      dispatch(setNotification({ message: 'Successfully added activity entry' }));
      dispatch(getDashboardStatsData());
      dispatch(loadMyTeamStats());
      dispatch(getMyDashboardData());

      await checkUpdateLeaderBoards(updateRanks, dispatch, getState);
      return res;
    },
    true,
    true
  );
}

export function editActivity(
  activityEntry: EditActivityValidationSchema,
  updateRanks?: string // If editing an activity on table showing ranks, update state.
): APIThunkResult<Activity> {
  return apiRequest<Activity>(
    async (dispatch, getState) => {
      const res = await apiFetch<Activity>({
        method: 'PUT',
        url: `${server}/activities/edit/${getClientTimezoneOffset()}`,
        body: activityEntry
      });

      dispatch(editEventActivity(res));
      dispatch(setNotification({ message: 'Successfully edited activity entries' }));
      dispatch(getDashboardStatsData());
      dispatch(getMyDashboardData());

      await checkUpdateLeaderBoards(updateRanks, dispatch, getState);
      return res;
    },
    true,
    true
  );
}

export function deleteActivity(
  activityId: number,
  updateRanks?: string // If deleting an activity on table showing ranks, update state.
): APIThunkResult<any> {
  return apiRequest<APIThunkResult<any>>(async (dispatch, getState) => {
    const res = await apiFetch<APIThunkResult<any>>({
      method: 'DELETE',
      url: `${server}/activities/remove/${activityId}`
    });

    dispatch(deleteEventActivity(activityId));
    dispatch(setNotification({ message: 'Successfully removed activity entry' }));
    dispatch(getDashboardStatsData());
    dispatch(loadMyTeamStats());
    await checkUpdateLeaderBoards(updateRanks, dispatch, getState);
    return res;
  });
}
