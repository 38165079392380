/* eslint-disable @typescript-eslint/camelcase */
import React, { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { loadMyTeamStats } from '../../lib/Api/Stats';
import { getDashboardStatsData } from '../../lib/Api/User';
import { getMyDashboardData } from '../../lib/Api/participant/Dashboard';
import { isAfter } from '../../lib/TimeUtils';

import { EmployeePageWrapper, SurveyContainer } from '../../components/UI';
import DashboardTeamConnectionPoints from '../../components/UI/DashboardTeamConnectionPoints';
import DashboardTeamMovementPoints from '../../components/UI/DashboardTeamMovementPoints';
import DashboardTopMovementActivities from '../../components/UI/DashboardTopMovementActivities';
import DashboardTopConnectionActivities from '../../components/UI/DashboardTopConnectionActivities';
import DashboardMiniChallengesCount from '../../components/UI/DashboardMiniChallengesCount';
import DashboardDailyActivitiesCount from '../../components/UI/DashboardDailyActivitiesCount';
import DashboardStepsLineChart from '../../components/UI/DashboardStepsLineChart';
import { ContentWrapper, CardRow } from './Styles';

import { Shine_preEventSurveyURL } from '../../CONSTANTS';
import { StyledPageHd } from '../../Themes';
import { ApplicationState } from '../../lib/Store';
import { APIThunkDispatch } from '../../lib/Types';

import PreSurvey from './PreSurvey';

export interface EmployeeDashboardProps {}

const EmployeeDashboard: FC<EmployeeDashboardProps> = () => {
  const dispatch: APIThunkDispatch = useDispatch();
  const {
    participantTeamState: { team },
    userState: { userEvent }
  } = useSelector((state: ApplicationState) => state);

  const beforeStartDate = isAfter(userEvent?.startDate, moment().valueOf());

  useEffect(() => {
    if (!userEvent.eventId || beforeStartDate) return;
    dispatch(loadMyTeamStats());
  }, [team, userEvent, loadMyTeamStats]);

  useEffect(() => {
    if (!userEvent.eventId || beforeStartDate) return;
    dispatch(getMyDashboardData());
  }, [dispatch, getMyDashboardData, userEvent]);

  useEffect(() => {
    if (!userEvent.eventId || beforeStartDate) return;
    dispatch(getDashboardStatsData());
  }, [dispatch, getDashboardStatsData, userEvent]);

  return (
    <EmployeePageWrapper userType="employee">
      <ContentWrapper>
        <CardRow>
          <DashboardMiniChallengesCount />
          <DashboardDailyActivitiesCount />
        </CardRow>
        <CardRow>
          <DashboardStepsLineChart />
        </CardRow>
        <CardRow>
          <DashboardTopMovementActivities />
          <DashboardTopConnectionActivities />
        </CardRow>
        <CardRow>
          <DashboardTeamConnectionPoints />
          <DashboardTeamMovementPoints />
        </CardRow>

        <PreSurvey />
      </ContentWrapper>
    </EmployeePageWrapper>
  );
};

export default EmployeeDashboard;
