import React, { FC } from 'react';
import { eventGuy } from '../../../../../assets/icons';
import { Modal, Input } from '../../../../../components/UI';
import ModalButton from '../../../../../components/UI/Button/ModalButton';
import { AddOwnTeamMateSchema } from '../../../../../lib/validators/addOwnTeamMateSchema';
import { FormikForm } from '../../../../../lib/Types';
import theme from '../../../../../theme';
import { ButtonWrapper, Container, ColumnWrapper, FormColumn } from '../Styles';

export interface AddTeamMateModalProps extends FormikForm<AddOwnTeamMateSchema> {
  loading: boolean;
  cancel: () => void;
}

const AddTeamMateModal: FC<AddTeamMateModalProps> = ({
  values,
  loading,
  errors,
  cancel,
  handleSubmit,
  ...props
}) => {
  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <ColumnWrapper>
          <FormColumn>
            <Modal
              icon={eventGuy}
              width={50}
              padding={'3rem 5rem'}
              title={'Add a team member'}
              titlePadding={'0px 100px 15px 0px'}
            >
              {loading ? (
                'Loading'
              ) : (
                <>
                  <FormColumn>
                    <div style={{ width: '90%' }}>
                      <br />
                      <Input
                        required
                        id="firstName"
                        name="firstName"
                        value={values.firstName}
                        error={errors.firstName}
                        label="First Name"
                        type="text"
                        margin={`0px 0px ${theme.padding * 1.5}px 0px`}
                        onChange={props.handleChange}
                      />
                      <Input
                        required
                        id="lastName"
                        name="lastName"
                        value={values.lastName}
                        error={errors.lastName}
                        label="Last Name"
                        type="lastName"
                        margin={`0px 0px ${theme.padding * 1.5}px 0px`}
                        onChange={props.handleChange}
                      />
                      <Input
                        required
                        id="email"
                        name="email"
                        value={values.email}
                        error={errors.email}
                        label="Email"
                        type="email"
                        margin={`0px 0px ${theme.padding * 1.5}px 0px`}
                        onChange={props.handleChange}
                      />
                      <br />
                    </div>
                  </FormColumn>
                  <ButtonWrapper>
                    <ModalButton type="submit" variant={'primary'}>
                      INVITE USER
                    </ModalButton>
                    <ModalButton onClick={cancel} type="button" variant={'remove'}>
                      CANCEL
                    </ModalButton>
                  </ButtonWrapper>
                </>
              )}
            </Modal>
          </FormColumn>
        </ColumnWrapper>
      </form>
    </Container>
  );
};

export default AddTeamMateModal;
