import React, { FC, ChangeEvent } from 'react';
import { StyledInput, Wrapper, Label } from './Styles';

interface InputProps {
  id: string;
  label: string;
  value?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement>;
  onChange: (e: ChangeEvent<any>) => void;
}

const Input: FC<InputProps> = ({ id, label, value, onChange, inputRef }) => {
  return (
    <>
      <Wrapper>
        <StyledInput
          id={id}
          value={value}
          onChange={onChange}
          onClick={e => {
            e.preventDefault();
            onChange(e);
          }}
          placeholder={label}
          autoFocus
          ref={inputRef}
        />
        <Label htmlFor={id} value={value}>
          {label}
        </Label>
      </Wrapper>
    </>
  );
};

export default Input;
