import React, { FC, useState } from 'react';
import {
  FormColumn,
  ColumnWrapper,
  ButtonWrapper,
  ModalTitle,
  TitleWrapper,
  BackBtn,
  GenerateInfo
} from './Styles';
import { Button, Paper, SelectDropdown } from '../../../../components/UI';
import { DarkBlueHeaderM, RedText } from '../../../../Themes';
import { CompanyDemographic } from '../../../../lib/Types';
import { backArrow } from '../../../../assets/icons';

interface GenerateTeamsProps {
  generateCancel: () => void;
  companyDemographics: CompanyDemographic[];
  userCompanyId: number;
  userEventId: number;
  generateTeams: (companyId: number, eventID: number, data: any) => any;
  loading: boolean;
  onCancel: () => void;
}

const GenerateTeams: FC<GenerateTeamsProps> = ({
  generateCancel,
  generateTeams,
  companyDemographics,
  userCompanyId,
  userEventId,
  loading,
  onCancel
}) => {
  const [selectedDemo, setSelectedDemo] = useState(null);
  const [error, setError] = useState(false);

  return (
    <Paper>
      <TitleWrapper>
        <BackBtn alt="back" src={backArrow} onClick={onCancel} />
        <ModalTitle>Generate Teams</ModalTitle>
      </TitleWrapper>
      <ColumnWrapper>
        <FormColumn>
          {!loading ? (
            <>
              {companyDemographics && !!companyDemographics.length && (
                <GenerateInfo padding={'0 0 0 0.2rem'}>
                  You can auto-generate teams based on how you order the below. The script will
                  assign the most appropriate members first and then anyone else until the teams are
                  all full.
                </GenerateInfo>
              )}
              {error && (
                <>
                  <br />
                  <DarkBlueHeaderM>
                    <RedText>{error}</RedText>
                  </DarkBlueHeaderM>
                  <br />
                </>
              )}
              {companyDemographics && !!companyDemographics.length && (
                <>
                  <DarkBlueHeaderM padding={'0 0 1rem 0.2rem'}>
                    Generate Teams by Demographic
                  </DarkBlueHeaderM>
                  <SelectDropdown
                    data={companyDemographics}
                    value={selectedDemo}
                    valueProp={'companyDemographicId'}
                    displayProp={'name'}
                    placeholder={'Select a Demographic...'}
                    onChange={value => setSelectedDemo(value)}
                  />
                </>
              )}
            </>
          ) : (
            <DarkBlueHeaderM>Generating teams, please wait...</DarkBlueHeaderM>
          )}
        </FormColumn>
        <FormColumn />
      </ColumnWrapper>

      {!loading ? (
        <ButtonWrapper>
          <Button
            onClick={generateCancel}
            type="button"
            variant={'admin-cancel'}
            width={'10%'}
            minWidth={'auto'}
            margin={'0 1rem 0 0'}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant={'admin-primary'}
            width={'10%'}
            minWidth={'auto'}
            onClick={async () => {
              const { error } = await generateTeams(userCompanyId, userEventId, selectedDemo);

              error ? setError(error.message) : setError(false);
            }}
          >
            Generate
          </Button>
        </ButtonWrapper>
      ) : null}
    </Paper>
  );
};
export default GenerateTeams;
