import styled from 'styled-components/macro';

import { expandHorizontal } from '../../../../lib/Utils';

interface BarProps {
  width: string;
  color: string;
}

interface ImageProps {
  image: string;
}
export const BarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin-top: 0.2rem;
`;

export const Bar = styled.div<BarProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${props => props.width};
  height: 45px;
  border-radius: 22.5px;

  margin-right: auto;
  background-color: ${props => props.color};

  animation: ${expandHorizontal} 1.2s cubic-bezier(0.4, 0.01, 0, 1.38) 1.6s forwards;
  transition: all 0.6s cubic-bezier(0.4, 0.01, 0, 1.38);
`;

export const BarImage = styled.div<ImageProps>`
  min-width: 24px;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  margin-left: 1rem;
  margin-right: 1rem;
  background: center / cover ${props => `url('${props.image}') no-repeat`};
`;

export const BarName = styled.div`
  min-width: fit-content;
  white-space: nowrap;
  color: #515151;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 13px;
  line-height: 16.9px;
`;

export const BarTotal = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 2rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 1rem;
  z-index: 10;
  color: #515151;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 15px;
  line-height: 16.9px;
  text-align: right;
`;

export const BarGraphContainer = styled.div`
  padding-right: 1rem;
  margin-bottom: 1rem;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    margin-left: 0;
  }
`;
