import React, { FC, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
  linkToEvent,
  updateCompanyEventRegistration,
  validateEventPassCode
} from '../../../lib/Api/companyAdmin/Event';
import { centsToDollars } from '../../../lib/Utils';

import CompanyEventDemographics from '../CompanyEventDemographics';
import PaymentSummary from '../PaymentSummary/PaymentSummary';
import CompanyInvite from '../CompanyInvite';
import CompanyDetailsEditCard from '../CompanyDetailsEditCard';
import ModalButton from '../Button/ModalButton';
import Input from '../Input';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';

import {
  RegisterForEvent,
  RegisterForm,
  Body,
  InputWrapper,
  TwoColumnWrapper,
  Column,
  ColumnSection,
  ColouredContainer,
  ClickableText
} from './Styles';
import { Amount, SummaryRow, Title, Value } from '../PaymentSummary/Styles';

import theme from '../../../theme';
import {
  DarkBlueHeaderM,
  DarkBlueHeaderL,
  DarkBlueBodyM,
  BrightBlueHeaderS,
  BrightBlueHeaderXS,
  TurqText,
  FlexRow
} from '../../../Themes';
import { CompanyEventLinkStatus } from '../../../lib/Types';
import { ApplicationState } from '../../../lib/Store';
import linkCompanyEventSchema, {
  LinkCompanyEventSchema
} from '../../../lib/validators/linkCompanyEventSchema';

const formatDate = (dateString: string, timezone: string): string =>
  `${moment(dateString, 'x')
    .tz(timezone)
    .format('Do of MMMM YYYY')}`;

const CompanyEventRegistration: FC = () => {
  const Dispatch = useDispatch();

  const [updatingAllotment, setUpdatingAllotment] = useState(false);
  const { eventLinkingInfo, eventLinkingLoading, userCompany, currentEvent } = useSelector(
    ({ companyAdminEventState, userState }: ApplicationState) => ({
      eventLinkingInfo: companyAdminEventState.eventLinkingInfo,
      eventLinkingLoading: companyAdminEventState.eventLinkingLoading,
      currentEvent: companyAdminEventState.currentEvent,
      userCompany: userState.userCompany
    })
  );

  const hasRegistrations = currentEvent?.registrationCount > 0;

  const handleSubmitForm = (values: any) => {
    const {
      passCode,
      participantAllotment: maxParticipants,
      welcomeMessage: greeting,
      demographics: companyDemographics
    } = values;

    if (currentEvent) {
      Dispatch(
        updateCompanyEventRegistration(
          userCompany.companyId,
          currentEvent.eventId,
          maxParticipants,
          greeting,
          companyDemographics
        )
      );
    } else {
      Dispatch(
        linkToEvent(userCompany.companyId, passCode, maxParticipants, greeting, companyDemographics)
      );
    }
    setUpdatingAllotment(false);
  };

  const formHeading = useMemo(() => {
    let innerText = <>Register for an event</>;

    if (currentEvent) {
      innerText = (
        <>
          Thanks for joining the
          <TurqText>&nbsp;{currentEvent.name}&nbsp;</TurqText>
          Event!
        </>
      );
    } else if (eventLinkingInfo.name) {
      innerText = (
        <>
          Join the
          <TurqText>&nbsp;{eventLinkingInfo.name}&nbsp;</TurqText>
          Event!
        </>
      );
    }

    return <DarkBlueHeaderL>{innerText}</DarkBlueHeaderL>;
  }, [eventLinkingInfo, currentEvent]);

  const formStep = useMemo(() => {
    // No event + no linking info == step 1
    if (!currentEvent && !eventLinkingInfo.name) return 1;
    // No event + linking info == step 2
    if (!currentEvent && eventLinkingInfo.name) return 2;
    // Event == event display
    return 3;
  }, [eventLinkingInfo, currentEvent]);

  const eventStartText = useMemo(() => {
    if (!currentEvent) return '';
    const seconds = dayjs(Number(currentEvent.startDate)).diff(dayjs(), 'millisecond') / 1000;
    const daysTillEvent = dayjs(Number(currentEvent.startDate)).diff(dayjs(), 'day');
    const hrsTillEvent =
      dayjs(Number(currentEvent.startDate)).diff(dayjs(), 'hour') - daysTillEvent * 24;
    const minsTillEvent =
      dayjs(Number(currentEvent.startDate)).diff(dayjs(), 'minute') -
      (hrsTillEvent * 60 || daysTillEvent * 24 * 60);
    const eventStarted = seconds < 0;

    return eventStarted ? (
      <>Event started.</>
    ) : (
      <>
        Event starts in
        <b>
          {daysTillEvent > 0 && (
            <>
              &nbsp;{daysTillEvent} Day{daysTillEvent > 1 ? 's' : ''},
            </>
          )}
          {hrsTillEvent > 0 && (
            <>
              &nbsp;{hrsTillEvent}&nbsp;hour{hrsTillEvent > 1 ? 's' : ''}.
            </>
          )}
          {minsTillEvent > 0 && minsTillEvent < 60 && (
            <>
              &nbsp;{minsTillEvent}&nbsp;min{minsTillEvent > 1 ? 's' : ''}.
            </>
          )}
        </b>
      </>
    );
  }, [currentEvent]);

  const [paymentStatusText, paymentStatusColors] = useMemo(() => {
    if (!currentEvent) return [null, null];
    return [
      currentEvent.status === CompanyEventLinkStatus.Paid ? 'Fully Paid.' : 'Payment Pending.',
      {
        color:
          currentEvent.status === CompanyEventLinkStatus.Paid
            ? `${theme.status.success}25`
            : `${theme.status.danger}25`,
        textColor:
          currentEvent.status === CompanyEventLinkStatus.Paid
            ? theme.primary.darkGreen
            : theme.status.danger
      }
    ];
  }, [currentEvent]);

  const [registrationStatusText, registrationStatusColors] = useMemo(() => {
    if (!currentEvent) return [null, null];
    const seconds =
      dayjs(Number(currentEvent.registrationsStartDate)).diff(dayjs(), 'millisecond') / 1000;
    const daysTillRego = dayjs(Number(currentEvent.registrationsStartDate)).diff(dayjs(), 'day');
    const hrsTillRego =
      dayjs(Number(currentEvent.registrationsStartDate)).diff(dayjs(), 'hour') - daysTillRego * 24;
    const minsTillRego =
      dayjs(Number(currentEvent.registrationsStartDate)).diff(dayjs(), 'minute') -
      (hrsTillRego * 60 || daysTillRego * 24 * 60);
    const registrationsOpen = seconds < 0;

    const registrationStatuText = registrationsOpen ? (
      <>Registrations Open.</>
    ) : (
      <>
        Registrations open in
        <b>
          {daysTillRego > 0 && (
            <>
              &nbsp;{daysTillRego} Day{daysTillRego > 1 ? 's' : ''},
            </>
          )}
          {hrsTillRego > 0 && (
            <>
              &nbsp;{hrsTillRego}&nbsp;hour{hrsTillRego > 1 ? 's' : ''}.
            </>
          )}
          {minsTillRego > 0 && minsTillRego < 60 && (
            <>
              &nbsp;{minsTillRego}&nbsp;min{minsTillRego > 1 ? 's' : ''}.
            </>
          )}
        </b>
      </>
    );

    return [
      registrationStatuText,
      {
        color: registrationsOpen ? `${theme.status.success}25` : `${theme.status.warning}25`,
        textColor: registrationsOpen ? theme.primary.darkGreen : theme.status.warning
      }
    ];
  }, [currentEvent]);

  const initFormValues = useMemo(() => {
    if (currentEvent) {
      return {
        passCode: currentEvent.passCode,
        participantAllotment: currentEvent.maxParticipants,
        welcomeMessage: currentEvent.greeting,
        demographics: [
          ...currentEvent.companyDemographics.map(demo => ({
            name: demo.name,
            options: demo.options
          }))
        ]
      };
    }
    return {
      passCode: '',
      participantAllotment: 1,
      welcomeMessage: '',
      demographics: []
    };
  }, [currentEvent]);

  return (
    <Formik<LinkCompanyEventSchema>
      initialValues={initFormValues}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnMount={false}
      validateOnChange={false}
      validationSchema={linkCompanyEventSchema}
      onSubmit={handleSubmitForm}
    >
      {({ values, errors, validateForm, handleChange, handleSubmit, setFieldError }) => {
        const hasEditedRego =
          values.demographics !== initFormValues.demographics ||
          values.participantAllotment !== initFormValues.participantAllotment ||
          values.welcomeMessage !== initFormValues.welcomeMessage;

        const isLinkedToEvent = !!currentEvent;
        return (
          <>
            <RegisterForEvent step={formStep}>
              <RegisterForm step={formStep}>
                {formHeading}
                {(eventLinkingInfo.name || currentEvent?.name) && (
                  <DarkBlueBodyM style={{ marginBottom: '4rem' }}>
                    From&nbsp;
                    <b>
                      {`${formatDate(
                        eventLinkingInfo.startDate || currentEvent?.startDate,
                        eventLinkingInfo.timezone || currentEvent?.timezone
                      )}`}
                    </b>
                    &nbsp;until&nbsp;
                    <b>
                      {formatDate(
                        eventLinkingInfo.endDate || currentEvent?.endDate,
                        eventLinkingInfo.timezone || currentEvent?.timezone
                      )}
                    </b>
                    &nbsp;Registrations start on the&nbsp;
                    <b>
                      {formatDate(
                        eventLinkingInfo.registrationsStartDate ||
                          currentEvent?.registrationsStartDate,
                        eventLinkingInfo.timezone || currentEvent?.timezone
                      )}
                    </b>
                    . Team Registrations close on the&nbsp;
                    <b>
                      {formatDate(
                        eventLinkingInfo.registrationsEndDate || currentEvent?.registrationsEndDate,
                        eventLinkingInfo.timezone || currentEvent?.timezone
                      )}
                    </b>
                  </DarkBlueBodyM>
                )}
                {formStep === 1 && (
                  <>
                    Have an event code?
                    <InputWrapper>
                      <Input
                        id="passCode"
                        name="passCode"
                        error={errors.passCode}
                        label="Enter 4-digit code"
                        value={values.passCode}
                        onChange={handleChange}
                        onBlur={validateForm}
                      />
                      <ModalButton
                        disabled={eventLinkingLoading}
                        variant="primary"
                        type="button"
                        onClick={() => {
                          setFieldError('participantAllotment', null);
                          setFieldError('welcomeMessage', null);
                          // validateEventPassCode will set eventLinkingInfo in redux in turn progressing the form state
                          Dispatch(validateEventPassCode(values.passCode));
                        }}
                      >
                        Apply
                      </ModalButton>
                    </InputWrapper>
                  </>
                )}
                {formStep !== 1 && (
                  <TwoColumnWrapper>
                    <Column>
                      <ColumnSection>
                        <DarkBlueHeaderM>Welcome Message</DarkBlueHeaderM>
                        <Body margin="0 0 2rem 0">
                          Create a welcome message for participants to see once they join the event.
                        </Body>
                        <Input
                          id="welcomeMessage"
                          textArea
                          error={errors.welcomeMessage}
                          name="welcomeMessage"
                          label="Welcome to the event..."
                          value={values.welcomeMessage}
                          onChange={handleChange}
                        />
                      </ColumnSection>
                      {!hasRegistrations && (
                        <ColumnSection>
                          <DarkBlueHeaderM>Demographic Questions</DarkBlueHeaderM>
                          <Body margin="0">
                            Add demographic data fields to collect information at registration time
                            so you can group participants together for statistical purposes.
                            <br />
                            eg. Add &quot;Department&quot; and then add the options for the user to
                            pick from.
                            <br />
                            eg. IT, HR, Design, Accounting.
                          </Body>
                          <CompanyEventDemographics
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                          />
                        </ColumnSection>
                      )}
                    </Column>
                    <Column>
                      {currentEvent && (
                        <ColumnSection>
                          <DarkBlueHeaderM>Event Status</DarkBlueHeaderM>
                          <ColouredContainer
                            color={`${theme.primary.xlight}25`}
                            textColor={theme.primary.dark}
                          >
                            {eventStartText}
                          </ColouredContainer>
                          <ColouredContainer {...paymentStatusColors}>
                            {paymentStatusText}
                          </ColouredContainer>
                          <ColouredContainer {...registrationStatusColors}>
                            {registrationStatusText}
                          </ColouredContainer>
                        </ColumnSection>
                      )}
                      {currentEvent && !updatingAllotment ? (
                        <ColumnSection>
                          <DarkBlueHeaderM padding="1rem 0 2rem 0">
                            Participant Allotment Summary
                          </DarkBlueHeaderM>
                          <SummaryRow>
                            <Amount>
                              <BrightBlueHeaderS padding="0" fontStyle="none">
                                {currentEvent.maxParticipants}x
                              </BrightBlueHeaderS>
                            </Amount>
                            <Title>
                              <DarkBlueBodyM>{currentEvent.name}</DarkBlueBodyM>
                            </Title>
                            <Value>
                              <DarkBlueBodyM style={{ textAlign: 'right' }} fontStyle="none">
                                {`${currentEvent.registrationCount}/${currentEvent.maxParticipants} Used`}
                              </DarkBlueBodyM>
                            </Value>
                          </SummaryRow>
                          <SummaryRow>
                            <FlexRow justify="flex-end">
                              <BrightBlueHeaderXS padding="0">
                                <ClickableText onClick={() => setUpdatingAllotment(true)}>
                                  + Purchase more entries
                                </ClickableText>
                              </BrightBlueHeaderXS>
                            </FlexRow>
                          </SummaryRow>
                        </ColumnSection>
                      ) : (
                        <ColumnSection>
                          <DarkBlueHeaderM>Participant Allotment</DarkBlueHeaderM>
                          <Body margin="0">
                            How many participants would you like to include in this event?
                          </Body>
                          <Body margin="0 0 2rem 0">*You can add more later if required</Body>
                          <Body margin="0 0 2rem 0">
                            Cost per participant:&nbsp;
                            {centsToDollars(
                              currentEvent?.costPerParticipant ||
                                eventLinkingInfo?.costPerParticipant
                            )}
                          </Body>
                          {/*
                          Users must select a new "total" number of participant slots.
                          They cannot choose slots less than their current "paid for" slots.
                          They must choose at least 1 slot
                          They may choose slots less than their "last selected" slots, if they haven't paid yet
                          This is backend validated but should also be frontend validated and prevent submission.
                          Current paid slots is found in the "currentEvent" object as "paidParticipants"
                           */}
                          <Input
                            id="participantAllotment"
                            type="number"
                            error={errors.participantAllotment}
                            min={currentEvent?.paidParticipants}
                            name="participantAllotment"
                            label="Total participants slots"
                            value={values.participantAllotment}
                            onChange={handleChange}
                          />
                        </ColumnSection>
                      )}

                      <ColumnSection>
                        <PaymentSummary
                          eventPrice={
                            currentEvent?.costPerParticipant || eventLinkingInfo?.costPerParticipant
                          }
                          paidForCurrent={currentEvent?.paidParticipants}
                          participantAllotment={values.participantAllotment}
                          eventTitle={currentEvent?.name || eventLinkingInfo.name}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          {eventLinkingLoading ? (
                            <LoadingIndicator />
                          ) : (
                            <>
                              {currentEvent?.status === CompanyEventLinkStatus.PendingStripe &&
                                currentEvent?.checkoutUrl &&
                                currentEvent?.maxParticipants == values.participantAllotment && (
                                  <Button
                                    variant="primary"
                                    type="button"
                                    onClick={() => window.open(currentEvent.checkoutUrl, '_self')}
                                    buttonFlex={1}
                                  >
                                    Checkout with Stripe
                                  </Button>
                                )}
                              &nbsp;&nbsp;
                              {!isLinkedToEvent ? (
                                <Button
                                  variant="primary"
                                  type="button"
                                  onClick={() => handleSubmit()}
                                  buttonFlex={1}
                                >
                                  {currentEvent ? 'Update Registration' : 'Register For Event'}
                                </Button>
                              ) : (
                                hasEditedRego && (
                                  <Button
                                    variant="primary"
                                    type="button"
                                    onClick={() => handleSubmit()}
                                    buttonFlex={1}
                                  >
                                    {currentEvent
                                      ? 'Update Registration'
                                      : 'Update Event Registration'}
                                  </Button>
                                )
                              )}
                            </>
                          )}
                        </div>
                      </ColumnSection>
                    </Column>
                  </TwoColumnWrapper>
                )}
              </RegisterForm>
            </RegisterForEvent>
            <CompanyInvite />
            <CompanyDetailsEditCard />
          </>
        );
      }}
    </Formik>
  );
};

export default CompanyEventRegistration;
