import React, { FC, ChangeEvent } from 'react';
import { ActivityType, FormikForm } from '../../../../lib/Types';
import { EventSchema } from '../../validators/eventSchema';

import EventForm from '../EventForm/EventForm';
import LoadingIndicator from '../../../../components/UI/LoadingIndicator';

import { PageHeader } from './Styles';
import BackButton from '../../../../components/UI/Button/BackButton';

interface CreateEventProps extends FormikForm<EventSchema> {
  activityTypes: ActivityType[];
  loading: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  handleDateChangeCustom: (name: string, date: Date) => void;
  handleTimeChangeCustom: (name: string, timeOptionId: number) => void;
  handleSetCost: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CreateEvent: FC<CreateEventProps> = ({ loading, handleSetCost, ...props }) => (
  <>
    <PageHeader>
      <BackButton onClick={() => props.onCancel()} />
      Create an Event
    </PageHeader>
    {loading ? <LoadingIndicator /> : <EventForm handleSetCost={handleSetCost} {...props} />}
  </>
);

export default CreateEvent;
