import styled from 'styled-components/macro';
import { theme } from '../../../theme';

export const Wrapper = styled.div`
  padding: 1rem 0 0;
  text-align: center;
  width: 40rem;
  white-space: nowrap;
  overflow-x: scroll;
  display: flex;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    height: 4px;
    background-color: transparent;
    color: #ffffff;
    width: 40rem;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: transparent;
  }

  @media (max-width: ${theme.device.tablet}px) {
    width: 100vw;
  }
`;

export const SliderWrap = styled.div`
  width: 40rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.device.tablet}px) {
    width: 100vw;
  }
`;

export const ScrollButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ScrollLeft = styled.img`
  margin: 0 auto 0 2rem;
  transform: rotate(180deg);
  background-color: white;
  border-radius: 50px;
  cursor: pointer;
`;

export const ScrollRight = styled.img`
  margin: 0 2rem 0 auto;
  background-color: white;
  border-radius: 50px;
  cursor: pointer;
`;
