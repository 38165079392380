import styled from 'styled-components/macro';
import { DatePicker } from '../../components/UI';

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormColumn = styled.div`
  flex: 1;
  &:nth-child(1) {
    padding-right: ${({ theme }) => theme.padding * 8}px;
  }
  &:nth-child(2) {
    padding-right: ${({ theme }) => theme.padding * 3}px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: ${({ theme }) => theme.padding}px;
`;

export const Group = styled.div`
  margin-bottom: ${({ theme }) => theme.padding}px;
`;

export const Heading = styled.h4`
  font-size: ${({ theme }) => theme.fonts.subHeading / 1.25}rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.primary.dark};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.padding / 1.25}px;
  margin-top: ${({ theme }) => theme.padding * 3}px;
`;

export const InlineInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InlineInput = styled.div`
  flex: 1;
  &:nth-child(1) {
    padding-right: ${({ theme }) => theme.padding / 2}px;
  }
  &:nth-child(2) {
    padding-left: ${({ theme }) => theme.padding / 2}px;
  }
`;

export const InlineDateInput = styled(InlineInput)`
  max-width: 300px;
  min-width: 300px;
`;

export const ReportIcon = styled.img`
  width: 7.5%;
  display: inline-block;
  margin-top: ${({ theme }) => theme.padding}px;
`;

export const FileData = styled.div`
  display: inline-block;
  margin-left: ${({ theme }) => theme.padding}px;
`;

export const CreateReportContainer = styled.div`
  min-width: 600px;
  max-width: 600px;
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 160px;
  max-width: 100px;
  overflow: auto;
`;

export const Link = styled.span`
  text-decoration: none;
`;
