import styled from 'styled-components/macro';
import { Button } from '../../../../components/UI';

export const Container = styled.div`
  width: 43.5rem;
  margin-top: ${({ theme }) => theme.padding * 2}px;
`;

export const FormControl = styled.div`
  margin-bottom: 2.5rem;
`;

export const PicturePlaceholder = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  cursor: pointer;
  border: dashed 1px rgba(101, 140, 215, 0.5);
  background: #ffffff;
  display: inline-block;
  position: relative;
`;

export const PictureWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
`;

export const PictureHelper = styled.div`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`;

export const PictureInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Picture = styled.img`
  text-align: center;
  vertical-align: middle;
  width: 98px;
  height: 98px;
  object-fit: cover;
  border-radius: 100%;
  background: white;
`;

export const PictureText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 17px;
`;

export const PictureTitle = styled.h3`
  color: #366696;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.13125px;
`;

export const PictureDescription = styled.p`
  opacity: 0.5;
  color: #366696;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.1125px;
`;

export const UploadButton = styled(Button)`
  display: block;
  width: 151px;
  height: 50px;
  border: solid 1px #75dbff;
  background: #ffffff;
  color: #658cd7;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 15px;
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

export const LoginButton = styled(Button)`
  margin-top: ${({ theme }) => theme.padding * 4}px;
  height: 3.5em;
  border-radius: 10rem;
`;

// width = 75.59px
export const SkipLink = styled.a`
  width: 100%;
  height: 16px;
  color: #a3bae7;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  margin-top: ${({ theme }) => theme.padding}px;
  display: inline-block;
  cursor: pointer;
`;

export const Heading = styled.h4`
  font-size: ${({ theme }) => theme.fonts.subHeading / 1.25}rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.primary.dark};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.padding}px;
  text-align: center;
`;

export const CropTitle = styled.h3`
  color: #366696;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.13125px;
  margin-bottom: 15px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-style: italic;
`;

export const PictureDefault = styled.img`
  width: 45%;
  height: 45%;
  text-align: center;
  vertical-align: middle;
  object-fit: cover;
  margin-left: 29%;
  margin-top: 35%;
`;

export const LoaderContainer = styled.div`
  height: 25rem;
  width: calc(100% - 10vw);
  display: flex;
  align-items: center;
  justify-content: center;
`;
