import {
  User,
  Company,
  Event,
  Activity,
  ActivityType,
  FavoriteActivityType,
  Reward,
  FitnessDevice,
  CompanyEventStatus
} from '../../../Types';

export const SET_USER = 'SET_USER';
export const SET_USER_COMPANY_EVENT_ID = 'SET_USER_COMPANY_EVENT_ID';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const SET_COMPANY = 'SET_USER_COMPANY';
export const SET_EVENT = 'SET_USER_EVENT';
export const SET_PROFILE = 'SET_USER_PROFILE';
export const SET_REWARDS = 'SET_USER_REWARDS';
export const SET_REWARDS_TO_COMPLETE = 'SET_REWARDS_TO_COMPLETE';
export const SET_DEVICES = 'SET_USER_DEVICES';

export const SET_EVENT_ACTIVITY_TYPES = 'SET_EVENT_ACTIVITY_TYPES';
export const SET_EVENT_ACTIVITIES = 'SET_EVENT_ACTIVITIES';
export const ADD_EVENT_ACTIVITY = 'ADD_EVENT_ACTIVITY';
export const EDIT_EVENT_ACTIVITY = 'EDIT_EVENT_ACTIVITY';
export const DELETE_EVENT_ACTIVITY = 'DELETE_EVENT_ACTIVITY';

export interface UserState {
  userData: User;
  userCompany: Company;
  userEvent: CompanyEventStatus;
  userRewards: Reward[];
  rewardsToComplete: Reward[];
  userDevices: FitnessDevice[];
  userCompanyEventId: number;
}

export interface SetUserAction {
  type: typeof SET_USER;
  user: User;
}

export interface SetUserCompanyEventIdAction {
  type: typeof SET_USER_COMPANY_EVENT_ID;
  userCompanyEventId: number;
}

export interface UpdateUserAction {
  type: typeof UPDATE_USER;
  userUpdates: Partial<User>;
}

export interface RemoveUserAction {
  type: typeof REMOVE_USER;
}

export interface SetCompanyAction {
  type: typeof SET_COMPANY;
  company: Company;
}

export interface SetEventAction {
  type: typeof SET_EVENT;
  event: CompanyEventStatus;
}

export interface SetRewardsAction {
  type: typeof SET_REWARDS;
  rewards: Reward[];
}

export interface SetRewardsToCompleteAction {
  type: typeof SET_REWARDS_TO_COMPLETE;
  rewards: Reward[];
}

export interface SetDevicesAction {
  type: typeof SET_DEVICES;
  devices: FitnessDevice[];
}
export interface SetEventActivityTypesAction {
  type: typeof SET_EVENT_ACTIVITY_TYPES;
  allTypes: Array<ActivityType>;
  favoriteTypes: Array<FavoriteActivityType>;
}

export interface SetEventActivitiesAction {
  type: typeof SET_EVENT_ACTIVITIES;
  activities: Array<Activity>;
}

export interface AddEventActivityAction {
  type: typeof ADD_EVENT_ACTIVITY;
  activity: Activity;
}

export interface EditEventActivityAction {
  type: typeof EDIT_EVENT_ACTIVITY;
  activity: Activity;
}

export interface DeleteEventActivityAction {
  type: typeof DELETE_EVENT_ACTIVITY;
  activityId: number;
}

export type UserActionTypes =
  | SetUserAction
  | UpdateUserAction
  | RemoveUserAction
  | SetCompanyAction
  | SetEventAction
  | SetEventActivityTypesAction
  | SetEventActivitiesAction
  | AddEventActivityAction
  | EditEventActivityAction
  | DeleteEventActivityAction
  | SetRewardsAction
  | SetRewardsToCompleteAction
  | SetDevicesAction
  | SetUserCompanyEventIdAction;
