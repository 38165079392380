import React, { FC, useCallback, useState } from 'react';
import { ErrorLabel, RequiredLabel } from '../../';
import { dropdownArrow } from '../../../../assets/icons/index';
import Input from '../Input';

import { Container, Label, Option, OptionLabel, OptionsWrapper, Wrapper } from './Styles';

export interface AutoSuggestProps {
  label?: string;
  data: any[];
  value: number | string;
  error?: any;
  required?: boolean;
  valueProp: string;
  displayProp: string;
  placeholder: string;
  emailLabel?: boolean;
  onChange: (value: number | null) => void;
}

const AutoSuggest: FC<AutoSuggestProps> = ({
  data,
  value,
  error,
  label,
  required,
  valueProp,
  displayProp,
  placeholder,
  emailLabel = false,
  onChange
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [optionsOpen, setOptionsOpen] = useState(searchTerm.length > 1);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
    setOptionsOpen(e.currentTarget.value.length > 1);
  };

  const getSelected = (): string => {
    const item = data.find(x => x[valueProp] === value);
    return item ? item[displayProp] : placeholder;
  };

  const GenerateOptions = useCallback(
    (data: any) => {
      // Filter out any options that don't start with the display prop or email lowered
      const searchedOptions = data.filter(
        (d: any) =>
          (d[displayProp] as string)
            .toLocaleLowerCase()
            .startsWith(searchTerm.toLocaleLowerCase()) ||
          (d?.email as string).toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase())
      );

      return searchedOptions.map((d: any, i: number) => (
        <Option
          key={i}
          data-id={d[valueProp]}
          onClick={() => {
            onChange(d[valueProp]);
            setSearchTerm(d[displayProp]);
            setOptionsOpen(false);
          }}
        >
          <OptionLabel>
            {emailLabel ? `${d[displayProp]} - ${d.email}` : d[displayProp]}
          </OptionLabel>
        </Option>
      ));
    },
    [searchTerm, data]
  );

  return (
    <>
      <Container>
        {error ? <ErrorLabel>{error}</ErrorLabel> : ''}
        <Wrapper>
          {required ? <RequiredLabel /> : ''}
          {label && (
            <Label active={getSelected() !== placeholder} valid={!error}>
              {label}
            </Label>
          )}
          <Input
            id="existingParticipant"
            name="existingParticipant"
            label="Participant without a team"
            value={searchTerm}
            onChange={handleSearchChange}
            icon={dropdownArrow}
            iconWidth="15px"
            iconHeight="15px"
            iconTop="1.5rem"
            onClickIcon={e => setOptionsOpen(!optionsOpen)}
          />
          {optionsOpen ? (
            <OptionsWrapper>
              <>
                {searchTerm.length < 2 && (
                  <Option data-id={null} onClick={() => onChange(null)}>
                    <OptionLabel>{placeholder}</OptionLabel>
                  </Option>
                )}
                {GenerateOptions(data)}
              </>
            </OptionsWrapper>
          ) : null}
        </Wrapper>
      </Container>
    </>
  );
};

export default AutoSuggest;
