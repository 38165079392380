import React, { FC } from 'react';
import PageWrapper from '../../components/UI/PageWrapper';
import ParticipantsListView from './components/ParticipantsListView';
import ParticipantsNotRegistered from './components/ParticipantsNotRegistered';
import WithoutATeam from './components/WithoutATeam';

export interface ParticipantsProps {
  withoutTeamView?: boolean;
  notRegisteredView?: boolean;
}

const Participants: FC<ParticipantsProps> = ({
  withoutTeamView = false,
  notRegisteredView = false
}) => {
  const ParticipantList = withoutTeamView ? (
    <WithoutATeam />
  ) : notRegisteredView ? (
    <ParticipantsNotRegistered />
  ) : (
    <ParticipantsListView />
  );

  return <PageWrapper>{ParticipantList}</PageWrapper>;
};

export default Participants;
