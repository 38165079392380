import styled from 'styled-components/macro';
import Paper from '../Paper';

export const Container = styled(Paper)`
  text-align: center;
  padding: 6rem 0rem;
`;

export const Wrapper = styled.div`
  display: inline-block;
  text-align: center;
`;

export const Icon = styled.img`
  margin-bottom: 0.5rem;
`;

export const Text = styled.p`
  font-size: 1.4rem;
  font-style: italic;
  color: #658cd7;
`;
