import React, { FC } from 'react';

import {
  ImageContainer,
  HoldingImage,
  HoldingPageHeader,
  NoEventsBody,
  NoEventsHeader
} from './Styles';

interface HoldingPageProps {
  pageTitle: string;
  backgroundImage: string;
  backgroundImageMob?: string;
  noEvent?: boolean;
}

const HoldingPage: FC<HoldingPageProps> = ({
  pageTitle,
  backgroundImage,
  backgroundImageMob,
  noEvent = false
}) => {
  return (
    <>
      {!noEvent && <HoldingPageHeader>{pageTitle}</HoldingPageHeader>}

      <ImageContainer noEvent={noEvent}>
        <HoldingImage
          noEvent={noEvent}
          backgroundImage={backgroundImage}
          backgroundImageMob={backgroundImageMob}
        >
          {noEvent && (
            <>
              <NoEventsHeader>There are currently no events.</NoEventsHeader>
              <NoEventsBody>
                Keep an eye out for exciting new events from your company soon.
              </NoEventsBody>
            </>
          )}
        </HoldingImage>
      </ImageContainer>
    </>
  );
};

export default HoldingPage;
