import React, { ChangeEvent, FC } from 'react';
import { Container } from './Styles';
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  Input,
  PasswordInput
} from '../../../../components/UI';

export interface FormErrors {
  email?: string;
  password?: string;
  internal?: string;
}

export interface FormTouched {
  email?: boolean;
  password?: boolean;
}

export interface LoginFormProps {
  email: string;
  password: string;
  rememberMe: boolean;
  isSubmitting: boolean;
  formErrors: FormErrors;
  formTouched: FormTouched;
  disabled: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
}

const LoginForm: FC<LoginFormProps> = ({
  email,
  password,
  rememberMe,
  formErrors,
  formTouched,
  disabled,
  onChange,
  onSubmit,
  setFieldTouched,
  isSubmitting
}) => (
  <Container>
    {formErrors.internal ? <Alert message={formErrors.internal} /> : null}
    <form onSubmit={onSubmit}>
      <FormControl>
        <Input
          required
          id="email"
          name="email"
          value={email}
          label="Email Address"
          width="100%"
          error={formTouched.email && formErrors.email}
          disabled={disabled}
          onChange={onChange}
          onBlur={() => setFieldTouched('email')}
          autoFocus
        />
      </FormControl>
      <FormControl>
        <PasswordInput
          required
          id="password"
          name="password"
          value={password}
          label="Password"
          error={formTouched.password && formErrors.password}
          disabled={disabled}
          onChange={onChange}
          onBlur={() => setFieldTouched('password')}
        />
      </FormControl>
      <FormControl>
        <Checkbox
          name="rememberMe"
          label="Remember me"
          checked={rememberMe}
          disabled={disabled}
          onChange={onChange}
        />
      </FormControl>
      <FormControl>
        <Button variant="primary" type="submit" disabled={disabled} loading={isSubmitting}>
          Sign In
        </Button>
      </FormControl>
    </form>
  </Container>
);

export default LoginForm;
