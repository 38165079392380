import styled from 'styled-components/macro';
import { StyledPageHd } from '../../Themes';

export const MyStatsContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    margin-left: 0;
  }
`;

export const Title = styled(StyledPageHd)`
  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    margin: 0 0 1.5rem 1.5rem;
    font-size: calc(1.2em + 1vw);
  }
`;

export const CardRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    margin-bottom: 0;
  }
`;

export const CardColumn = styled.div`
  display: flex;
  width: 50%;

  @media (max-width: ${({ theme }) => theme.device.laptopL + 170}px) {
    flex-direction: column;
    width: 50%;
    margin-left: 2rem;
  }
  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    width: 100%;
    margin-bottom: 2rem;
    margin-left: 0;
    margin-top: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    width: 100%;
    margin-bottom: 0;
  }
`;
