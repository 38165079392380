import styled from 'styled-components/macro';
import Button from '../index';

export const StyledButton = styled(Button)`
  border-radius: ${({ theme }) => theme.borderRadius.md * 0.9}px;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  font-size: calc(0.676rem + 0.4vw);
  text-align: left;
  max-width: calc(33.33% - ${({ theme }) => theme.padding}px);
  font-style: initial;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  height: 40px;
  line-height: 1rem;
  align-items: center;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled.img``;
