import React, { FC } from 'react';
import { Container, Icon, Text } from './Styles';
import { StatusVariant } from '../../../lib/Types';

export interface AlertProps {
  message: string;
  variant?: StatusVariant;
}

const Alert: FC<AlertProps> = ({ message, variant = 'danger' }) => {
  return (
    <>
      <Container variant={variant}>
        <Icon />
        <Text>{message}</Text>
      </Container>
    </>
  );
};

export default Alert;
