import React, { FC } from 'react';

import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';

import { convertStringToMonth, getDaySuffix } from '../../../../lib/Utils';
import { FormErrors } from '../types';

export interface StepApprovalModalProps {
  stepApprovalReason: string;
  errors: FormErrors;
  handleSubmit: () => void;
  onCancel: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  date: string;
}

const StepApprovalModal: FC<StepApprovalModalProps> = ({
  stepApprovalReason,
  setFieldValue,
  handleSubmit,
  errors,
  onCancel,
  date
}) => {
  const displayDateParts = date.split('-');
  const day = getDaySuffix(displayDateParts[2]);
  const month = convertStringToMonth(displayDateParts[1]);
  const year = displayDateParts[0];
  const displayDate = `${day} of ${month}, ${year}`;

  return (
    <Modal
      onClose={onCancel}
      padding="40px"
      top={10}
      titlePadding="0px"
      title="You have exceeded the Daily Activity Limit."
    >
      <p>
        Wow! That&apos;s a superhuman effort. Please provide a brief explanation for steps/activity
        entered on {displayDate}.
      </p>
      <br></br>
      <p>
        We ask that all high steppers validate impressive step entries to ensure you are entering
        your steps and activities correctly. Keep up the great work!
      </p>
      <br></br>
      <Input
        textArea
        id="stepApprovalReason"
        name="stepApprovalReason"
        label=""
        error={errors.stepApprovalReason}
        value={stepApprovalReason}
        onChange={e => setFieldValue('stepApprovalReason', e.currentTarget.value)}
      />
      <Button
        margin="20px auto 0px 0px"
        type="button"
        onClick={() => {
          handleSubmit();
        }}
        variant="report"
      >
        Submit
      </Button>
    </Modal>
  );
};

export default StepApprovalModal;
