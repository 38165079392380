import * as yup from 'yup';

const companySchema = yup.object({
  name: yup.string().required('Company Name is required.'),
  profileImage: yup.string().notRequired(),
  active: yup.boolean().required(),
  allowManualPayment: yup.boolean().required(),
  contact: yup
    .object()
    .shape({
      firstName: yup.string().required('Contact First Name is required.'),
      lastName: yup.string().required('Contact Last Name is required.'),
      email: yup
        .string()
        .email()
        .required('Contact Email is required.'),
      phone: yup
        .string()
        .trim()
        .required('Contact Phone is required.'),
      password: yup
        .string()
        .trim()
        .matches(/^.*[0-9].*$/, 'Password must contain 1 number.')
        .matches(/^.*[A-Z].*$/, 'Password must contain 1 capital letter.')
        .min(8, 'Password must be greater than 8 characters.')
        .max(16, 'Password must be less than 16 characters.')
        .notRequired()
    })
    .required(),
  mustUpdatePassword: yup
    .boolean()
    .notRequired()
    .default(false)
});

export type CompanySchema = yup.InferType<typeof companySchema>;

export default companySchema;
