import styled from 'styled-components/macro';

import { Button } from '../../../../components/UI';

import { DarkBlueHeaderM } from '../../../../Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const Header = styled(DarkBlueHeaderM)`
  font-size: calc(1rem + 0.8vw);
  margin: 0.5rem 0 1.8rem;
  padding: 0;
`;

export const SubHeader = styled(DarkBlueHeaderM)`
  font-size: calc(1rem + 0.4vw);
  margin: 0.5rem 0 2rem;
  padding: 0;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
`;

export const CompanyPassCodeContainer = styled.div`
  margin: 1rem 0;
`;

export const LoginButton = styled(Button)`
  height: 5rem;
`;

export const StyledIframe = styled.iframe`
  height: 16000px;
  width: 100%;
  border: none;
  overflow-y: visible;
`;

export const IframeContainer = styled.div`
  border: 1px solid #44deff;
  overflow-y: scroll;
  width: 100%;
  height: 40vh;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.primary.main};
    border: 3px solid #ffffff;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.primary.main};
  }
`;

export const LoaderContainer = styled.div`
  height: 25rem;
  width: calc(100% - 10vw);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputGroup = styled.div`
  padding: ${({ theme }) => theme.padding}px 0;
  width: 100%;
`;
