import React, { FC } from 'react';
import Select, { components } from 'react-select';
import { ActivityType, FavoriteActivityType } from '../../../../../lib/Types';
import { theme } from '../../../../../theme';
import { iconDropdown } from '../../../../../assets/icons';
import { sortByActivityTypeName } from './utils';

interface ActivityTypesProps {
  activityTypes: Array<ActivityType>;
  favoriteTypes: Array<FavoriteActivityType>;
  onClick: (e: any) => void;
}

export interface Option {
  value: number;
  label: string;
  displayBottomBorder: boolean;
}

const mediaQuery = `@media (max-width: ${theme.device.mobileM}px)`;

// TODO: See if its possible to refactor this to use styled components
const customStyles = {
  container: provided => ({
    ...provided,
    width: '80%',
    borderRadius: '10px',
    [mediaQuery]: {
      width: '75vw'
    }
  }),
  option: (provided, { data }) => ({
    ...provided,
    color: '#21679a;',
    borderBottom: data.displayBottomBorder && '1px solid grey',
    fontStyle: 'italic',
    fontFamily: theme.fontFamily.heading,
    fontWeight: theme.fontWeights.regular,
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'left'
  }),
  placeholder: provided => ({
    ...provided,
    color: theme.primary.main,
    fontFamily: "'Sarabun', 'sans serif'",
    fontStyle: 'italic'
  })
};

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <img src={iconDropdown} />
  </components.DropdownIndicator>
);

const SelectActivityTypes: FC<ActivityTypesProps> = ({ activityTypes, favoriteTypes, onClick }) => {
  const favoriteTypeOptions: Option[] = favoriteTypes
    .map(({ activityTypeId: favoriteTypeId }, index) => {
      const { name } = activityTypes.find(
        ({ activityTypeId }) => activityTypeId === favoriteTypeId
      ) || { name: null };

      if (!name) return null;
      return {
        value: favoriteTypeId,
        label: name,
        displayBottomBorder: index === favoriteTypes.length - 1
      };
    })
    .filter(x => x !== null)
    .sort(sortByActivityTypeName);

  const activityTypeOptions: Option[] = activityTypes
    .filter(
      ({ activityTypeId }) =>
        !favoriteTypes.some(
          ({ activityTypeId: favoriteTypeId }) => favoriteTypeId === activityTypeId
        )
    )
    .map(activityType => ({
      value: activityType.activityTypeId,
      label: activityType.name,
      displayBottomBorder: false
    }))
    .sort(sortByActivityTypeName);

  return (
    <Select
      styles={customStyles}
      options={favoriteTypeOptions.concat(activityTypeOptions)}
      components={{ IndicatorSeparator: null, DropdownIndicator }}
      onChange={onClick}
      placeholder="Please select activity"
    />
  );
};

export default SelectActivityTypes;
