import React, { FC } from 'react';
import PageWrapper from '../../components/UI/PageWrapper';
import TeamList from './components/TeamListView';

export interface TeamsProps {}

const Teams: FC<TeamsProps> = () => {
  return (
    <PageWrapper>
      <TeamList />
    </PageWrapper>
  );
};

export default Teams;
