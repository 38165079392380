import { server } from '../../../CONSTANTS';
import { apiFetch, apiRequest } from '../Utils';
import {
  setCompanyOptions,
  setEventOptions,
  setSelectedCompanyIds,
  setSelectedEventId
} from '../../Store/contexts/eventCompanyPageFilter/actions';

import { APIThunkResult, Company, Event } from '../../../lib/Types';
import { setNotification } from '../../../lib/Store/contexts/notification/actions';
import { setTeamListFilter } from '../../../lib/Store/contexts/team/actions';
import { setParticipantsFilter } from '../../../lib/Store/contexts/participants/actions';
import { setSDAFilter } from 'src/lib/Store/contexts/suspectDailyActivity/actions';

export const setCompanyIds = (companyIds: number[] | null): APIThunkResult<void> => {
  return apiRequest<void>(async (dispatch, getState) => {
    try {
      dispatch(setSelectedCompanyIds(companyIds));

      const currentTeamFilter = getState().teamsState.filter;
      const currentParticipantFilter = getState().participantsState.filter;
      const currentSDAFilter = getState().SDAState.filter;

      if (companyIds?.length === 1) {
        const companyId = companyIds?.[0] || null;
        // Dispatch actions to set eventId in all other filters
        dispatch(setTeamListFilter({ ...currentTeamFilter, companyId: companyId }));
        dispatch(setParticipantsFilter({ ...currentParticipantFilter, companyId: companyId }));
        dispatch(setSDAFilter({ ...currentSDAFilter, companyId: companyId }));
      } else {
        // Dispatch actions to set eventId in all other filters
        dispatch(setTeamListFilter({ ...currentTeamFilter, companyId: null }));
        dispatch(setParticipantsFilter({ ...currentParticipantFilter, companyId: null }));
      }
    } catch (e) {
      throw e;
    }
  });
};

export const getCompanyOptions = (): APIThunkResult<Company[]> => {
  return apiRequest<Company[]>(async (dispatch, getState) => {
    try {
      const eventId = getState().eventCompanyPageFilterState.selectedEventId;
      if (!eventId) {
        dispatch(setCompanyOptions([]));
        dispatch(setCompanyIds([]));
        return;
      }

      const companies = await apiFetch<Company[]>({
        method: 'GET',
        url: `${server}/events/registered-companies/${eventId}/`
      });

      if (companies?.length) {
        dispatch(setCompanyOptions(companies));
      } else {
        dispatch(setCompanyOptions([]));
        dispatch(setCompanyIds([]));
      }

      return companies;
    } catch (e) {
      throw e;
    }
  });
};

export const setEventIdAndLoadCompanyOptions = (eventId: number | null): APIThunkResult<void> => {
  return apiRequest<void>(async (dispatch, getState) => {
    try {
      dispatch(setSelectedEventId(eventId));
      dispatch(setSelectedCompanyIds([]));

      const currentTeamFilter = getState().teamsState.filter;
      const newTeamFilter = { ...currentTeamFilter, eventId: eventId || null };
      const currentParticipantFilter = getState().participantsState.filter;
      const newParticipantFilter = { ...currentParticipantFilter, eventId: eventId || null };
      const currentSDAFilter = getState().SDAState.filter;
      const newSDAFilter = { ...currentSDAFilter, eventId: eventId || null };

      // Dispatch actions to set eventId in all other filters
      dispatch(setTeamListFilter(newTeamFilter));
      dispatch(setParticipantsFilter(newParticipantFilter));
      dispatch(setSDAFilter(newSDAFilter));

      await dispatch(getCompanyOptions());
    } catch (e) {
      throw e;
    }
  });
};
