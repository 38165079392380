import React, { FC, useEffect } from 'react';
import { eventGuy } from '../../../../../assets/icons';
import { ButtonWrapper, Container, ColumnWrapper, FormColumn } from '../Styles';
import { Modal } from '../../../../../components/UI';
import ModalButton from '../../../../../components/UI/Button/ModalButton';
import { RemoveOwnTeamMateSchema } from '../../../../../lib/validators/removeOwnTeamMateSchema';
import { FormikForm, ParticipantListView } from '../../../../../lib/Types';
import { DarkBlueBodyL } from '../../../../../Themes';

export interface RemoveTeamMateModalProps extends FormikForm<RemoveOwnTeamMateSchema> {
  loading: boolean;
  member: ParticipantListView;
  cancel: () => any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const RemoveTeamMateModal: FC<RemoveTeamMateModalProps> = ({
  member,
  loading,
  cancel,
  handleSubmit,
  setFieldValue
}) => {
  useEffect(() => {
    setFieldValue('email', member.email);
    setFieldValue('joinedStatus', member.joinedStatus);
  }, [member]);

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <ColumnWrapper>
          <FormColumn>
            <Modal
              icon={eventGuy}
              width={50}
              padding={'3rem 5rem'}
              title={'Are you sure you want to remove this team member?'}
              titlePadding={'0px 100px 15px 0px'}
            >
              {loading ? (
                'Loading'
              ) : (
                <>
                  <FormColumn>
                    <br />
                    <DarkBlueBodyL>
                      <b>
                        {member.firstName} {member.lastName}
                      </b>{' '}
                      - {member.email}
                    </DarkBlueBodyL>
                    <br />
                  </FormColumn>
                  <ButtonWrapper>
                    <ModalButton type={'submit'} variant={'remove'}>
                      YES, REMOVE
                    </ModalButton>
                    <ModalButton onClick={cancel} type={'button'} variant={'primary'}>
                      NO, KEEP
                    </ModalButton>
                  </ButtonWrapper>
                </>
              )}
            </Modal>
          </FormColumn>
        </ColumnWrapper>
      </form>
    </Container>
  );
};

export default RemoveTeamMateModal;
