import React, { FC } from 'react';
import { backArrow } from '../../../assets/icons';
import { TitleWrapper, BackBtn, FormTitle } from './Styles';

/* TODO: SWAP OUT ALL RANDOM ADMIN FORM HEADERS SO THEY'RE CONSISTENT */
interface FormHeaderProps {
  margin?: string;
  title: string;
  onClick: () => void;
  backBtn?: boolean;
}

const AdminFormHeader: FC<FormHeaderProps> = ({ margin, title, onClick, backBtn }) => {
  return (
    <TitleWrapper margin={margin}>
      {backBtn && <BackBtn alt="back" src={backArrow} onClick={onClick} />}
      <FormTitle>{title}</FormTitle>
    </TitleWrapper>
  );
};

export default AdminFormHeader;

/* TODO: the pages like Edit Teams etc should have a sub-route and ideally reference the teamId i.e. Teams/Edit/{team.teamId)
        so you can actually use react-router/react-router-dom navigation effectively/properly */

/*
This is how this component should function..

const AdminFormHeader: FC<FormHeaderProps> = ({ margin, title }) => {
  const history = useHistory();
  return (
    <TitleWrapper margin={margin}>
      {backBtn && <BackBtn alt="back" src={backArrow} onClick={() => history.goBack()} /> }
      <FormTitle>{title}</FormTitle>
    </TitleWrapper>
  );
};
*/
