import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import * as QueryString from 'query-string';
import jwt from 'jsonwebtoken';

import { signUp } from '../../lib/Api/User';

import RegisterForm from './components/RegisterForm';
import { ExternalPageWrapper } from '../../components/UI';
import { FormContainer } from './Styles';

import { ApiStatus, ErrorResponse, TokenPayload, APIThunkDispatch } from '../../lib/Types';
import { RegisterCredentials } from './validators/registerCredentialsSchema';

interface UserOnboardingProps {}

const UserOnboarding: React.FC<UserOnboardingProps> = () => {
  const history = useHistory();
  const dispatch: APIThunkDispatch = useDispatch();

  const [tokenErr, setTokenErr] = useState(false);
  const [token, setToken] = useState<TokenPayload>(null);
  const [signUpError, setSignUpError] = useState<ErrorResponse>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { t } = QueryString.parse(history.location.search); // fetch token from url query
    if (t) {
      const tokenPayload = jwt.decode(t as string) as TokenPayload;
      if (!tokenPayload) setTokenErr(true);
      else setToken(tokenPayload);
    }
  }, [history.location.search]);

  const handleSignUp = async (values: RegisterCredentials) => {
    setLoading(true);
    const { t } = QueryString.parse(history.location.search);

    const { status, error } = await dispatch(signUp(values, t as string));

    if (status === ApiStatus.FAILED) {
      setSignUpError(error);
      setLoading(false);
    } else {
      history.push('/Onboarding/ProfilePicture');
      setLoading(false);
    }
  };

  return (
    <ExternalPageWrapper header="Welcome! Create your account to get started.">
      <FormContainer>
        <br />
        {tokenErr ? (
          <p>
            Token Error! Please do not alter your url after clicking or pasting in your sign up
            link. Alternatively, ask your HR Manager for a new link.
          </p>
        ) : (
          <RegisterForm
            token={token}
            onSignUp={handleSignUp}
            signUpError={signUpError}
            loading={loading}
          />
        )}
      </FormContainer>
    </ExternalPageWrapper>
  );
};

export default UserOnboarding;
