import React, { FC } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { isAfter } from '../../lib/TimeUtils';

import Leaderboard from '../../pages/Leaderboard';
import MyStats from '../../pages/MyStats';
import TrophyCabinet from '../../pages/TrophyCabinet';
import TeamLeaderboard from '../../pages/TeamLeaderboard';

import Companies from '../../pages/Companies';
import CreatePost from '../../pages/CreatePost';
import Dashboard from '../../pages/Dashboard';
import EditProfile from '../../pages/EditProfile';
import EmployeeDashboardSwitch from '../../pages/EmployeeDashBoardSwitch';
import Events from '../../pages/Events';
import ForgotPassword from '../../pages/ForgotPassword/';
import LoginPage from '../../pages/LoginPage';
import OnboardingPictureForm from '../../pages/OnboardingPictureForm';
import Participants from '../../pages/Participants';
import PostsFeed from '../../pages/PostsFeed';
import PostThread from '../../pages/PostThread';
import Rewards from '../../pages/Rewards';
import Reports from '../../pages/Reports';
import SyncDevice from '../../pages/SyncDevice/SyncDevice';
import Teams from '../../pages/Teams';
import TermsAndConditions from '../../pages/TermsAndConditions';
import UserOnboarding from '../../pages/UserOnboarding';
import CompanyOnboarding from '../../pages/CompanyOnboarding';
import SuspectDailyActivities from '../../pages/SuspectDailyActivities';
import AdminTeamBuilder from '../../pages/AdminTeamBuilder';
import RegistrationDashboard from '../../pages/RegistrationDashboard';
import CompanyAdmins from '../../pages/CompanyAdmins';
import UpdatePassword from '../../pages/UpdatePassword';
import EventPayments from '../../pages/EventPayments';
import PastEvents from '../../pages/PastEvents';

import AuthRoute from './AuthRoute';

import { Permission, RoleCode } from '../../lib/Types';
import { ApplicationState } from '../../lib/Store';

const Routes: FC = () => {
  const { code, companyEvent, participantEvent } = useSelector(
    ({ permissionsState, companyAdminEventState, userState }: ApplicationState) => ({
      code: permissionsState.code,
      companyEvent: companyAdminEventState.currentEvent,
      participantEvent: userState.userEvent
    })
  );

  // Redirects a user if authenticated and trying to access non auth route
  const checkAuthRedirect = (routeCheck: JSX.Element, codeCheck: RoleCode): JSX.Element => {
    if (codeCheck === RoleCode.Employee) return <Redirect to="/EmployeeDashboard" />;
    if (codeCheck === RoleCode.MasterAdmin || codeCheck === RoleCode.CompanyAdmin) {
      return <Redirect to="/Dashboard" />;
    }
    return routeCheck;
  };

  // Redirects company admin to event registration dashboard if not registered for event
  const checkCompanyAdminRedirect = (routeCheck: JSX.Element, codeCheck: RoleCode): JSX.Element => {
    const beforeStartDate = isAfter(companyEvent?.startDate, moment().valueOf());
    if (codeCheck === RoleCode.CompanyAdmin && (!companyEvent || beforeStartDate)) {
      return <Redirect to="/EventRegistrationDashboard" />;
    }
    return routeCheck;
  };

  // Redirects the employee to the registration dashboard if event has not yet started?
  const checkEmployeeRedirect = (routeCheck: JSX.Element, codeCheck: RoleCode): JSX.Element => {
    const beforeStartDate = isAfter(participantEvent?.startDate, moment().valueOf());
    if (codeCheck === RoleCode.Employee && (!participantEvent || beforeStartDate)) {
      return <Redirect to="/EmployeeDashboard" />;
    }
    return routeCheck;
  };

  return (
    <Router>
      <Switch>
        <AuthRoute path="/sync-device">
          <SyncDevice />
        </AuthRoute>
        <Route path="/CompanyRegistration">{checkAuthRedirect(<CompanyOnboarding />, code)}</Route>
        <Route path="/Register">{checkAuthRedirect(<UserOnboarding />, code)}</Route>
        <Route path="/ForgotPassword">
          <ForgotPassword />
        </Route>
        <AuthRoute
          path={['/TeamDashboard', '/EmployeeDashboard']}
          requiresOneOfCode={[RoleCode.Employee]}
        >
          <EmployeeDashboardSwitch />
        </AuthRoute>
        <Route path="/TermsAndConditions">
          {code === null || code !== RoleCode.CompanyAdmin ? (
            <Redirect to="/Login" />
          ) : (
            <TermsAndConditions />
          )}
        </Route>
        <Route path="/UpdatePassword">
          {code === null ? <Redirect to="/Login" /> : <UpdatePassword />}
        </Route>
        <Route path="/Onboarding/ProfilePicture">
          {code === null ? <Redirect to="/Login" /> : <OnboardingPictureForm />}
        </Route>
        <AuthRoute path="/Leaderboard/TeamLeaderboard" requiresOneOfCode={[RoleCode.Employee]}>
          {checkEmployeeRedirect(<TeamLeaderboard />, code)}
        </AuthRoute>
        <AuthRoute path="/Leaderboard" requiresOneOfCode={[RoleCode.Employee]}>
          {checkEmployeeRedirect(<Leaderboard />, code)}
        </AuthRoute>
        <AuthRoute path="/WaterCooler/Post/:id" requiresOneOfCode={[RoleCode.Employee]}>
          {checkEmployeeRedirect(<PostThread />, code)}
        </AuthRoute>
        <AuthRoute path="/WaterCooler" requiresOneOfCode={[RoleCode.Employee]}>
          {checkEmployeeRedirect(<PostsFeed />, code)}
        </AuthRoute>
        <AuthRoute path="/MyStats" requiresOneOfCode={[RoleCode.Employee]}>
          {checkEmployeeRedirect(<MyStats />, code)}
        </AuthRoute>
        <AuthRoute path="/TrophyCabinet" requiresOneOfCode={[RoleCode.Employee]}>
          {checkEmployeeRedirect(<TrophyCabinet />, code)}
        </AuthRoute>
        <AuthRoute
          path="/Dashboard"
          requiresOneOfCode={[RoleCode.MasterAdmin, RoleCode.CompanyAdmin]}
        >
          {checkCompanyAdminRedirect(<Dashboard />, code)}
        </AuthRoute>
        <AuthRoute path="/EventRegistrationDashboard" requiresOneOfCode={[RoleCode.CompanyAdmin]}>
          <RegistrationDashboard />
        </AuthRoute>
        <AuthRoute path="/Teams*" requiredPermission={Permission.VIEW_ALL_COMPANY_TEAMS}>
          <Teams />
        </AuthRoute>
        <AuthRoute
          path="/Participants/NotRegistered"
          requiredPermission={Permission.VIEW_ALL_COMPANY_EVENT_PARTICIPANTS}
        >
          <Participants notRegisteredView={true} />
        </AuthRoute>
        <AuthRoute
          path="/Participants/WithoutTeams"
          requiredPermission={Permission.VIEW_ALL_COMPANY_EVENT_PARTICIPANTS_WITHOUT_TEAM}
        >
          <Participants withoutTeamView={true} />
        </AuthRoute>
        <AuthRoute
          path="/Participants"
          requiredPermission={Permission.VIEW_ALL_COMPANY_EVENT_PARTICIPANTS}
        >
          <Participants />
        </AuthRoute>
        <AuthRoute path="/Companies" requiredPermission={Permission.VIEW_COMPANIES}>
          <Companies />
        </AuthRoute>
        <AuthRoute path="/EditProfile" requiresOneOfCode={[RoleCode.Employee]}>
          <EditProfile />
        </AuthRoute>
        <AuthRoute path="/Events" requiredPermission={Permission.VIEW_EVENTS}>
          <Events />
        </AuthRoute>
        <AuthRoute
          path="/CreatePost"
          requiresOneOfCode={[RoleCode.MasterAdmin, RoleCode.CompanyAdmin]}
        >
          {checkCompanyAdminRedirect(<CreatePost />, code)}
        </AuthRoute>
        <AuthRoute path="/Rewards" requiresOneOfCode={[RoleCode.MasterAdmin]}>
          <Rewards />
        </AuthRoute>
        <AuthRoute path="/Reports" requiresOneOfCode={[RoleCode.MasterAdmin]}>
          <Reports />
        </AuthRoute>
        <AuthRoute path="/SuspectDailyActivities" requiresOneOfCode={[RoleCode.MasterAdmin]}>
          <SuspectDailyActivities />
        </AuthRoute>
        <AuthRoute path="/TeamBuilder" requiresOneOfCode={[RoleCode.MasterAdmin]}>
          <AdminTeamBuilder />
        </AuthRoute>
        <AuthRoute path="/CompanyAdmins" requiresOneOfCode={[RoleCode.CompanyAdmin]}>
          <CompanyAdmins />
        </AuthRoute>
        <AuthRoute path="/Invoices" requiresOneOfCode={[RoleCode.CompanyAdmin]}>
          <EventPayments />
        </AuthRoute>
        <AuthRoute path="/PastEvents" requiredPermission={Permission.VIEW_EVENT_REGISTRATIONS}>
          <PastEvents />
        </AuthRoute>
        <Route
          to={['/', '/Login']}
          render={() => (
            <>
              {code === null && <LoginPage />}
              {code === RoleCode.Employee && <Redirect to="/EmployeeDashboard" />}
              {(code === RoleCode.MasterAdmin || code === RoleCode.CompanyAdmin) && (
                <Redirect to="/Dashboard" />
              )}
            </>
          )}
        ></Route>
      </Switch>
    </Router>
  );
};

export default Routes;
