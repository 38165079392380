import styled from 'styled-components/macro';
import { StyledPageHd } from '../../Themes';

import { ContainerProps } from '../../lib/Types';

import { getBoxShadow } from '../../lib/Utils';

interface AchievementProps {
  earned: boolean;
}

export const StyledTrophyCabinet = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 2rem 0;
  background: ${({ theme }) => theme.backgrounds.white};
  border-radius: 1.5rem;
  box-shadow: ${({ elevation = 1, theme }) => getBoxShadow(elevation, theme)};
  min-height: 100px;
  z-index: 0;
  position: relative;

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    border-radius: 0;
  }
`;

export const Title = styled(StyledPageHd)`
  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    margin: 0 0 1.5rem 1.5rem;
    font-size: calc(1.2em + 1vw);
  }
`;

export const AchievementsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 1rem 0.75rem;
  margin: 2rem 0;
`;

export const AchievementWrapper = styled.div``;

export const AchievementName = styled.div`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  color: #525a65;
  margin-top: 0.4rem;
  text-align: center;
`;

export const AchievementPoints = styled.div`
  font-size: 10px;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: #525a65;
  margin-top: 0.4rem;
  text-align: center;
`;

export const AchievementImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Achievement = styled.img<AchievementProps>`
  width: 175px;
  height: 174px;
  margin-top: 0.5rem;
  position: relative;
  z-index: 0;
  filter: ${({ earned }) => !earned && 'grayscale(100%) opacity(65%)'};
`;

export const LockIcon = styled.img`
  width: 48px;
  height: 48px;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TitleCount = styled.div`
  display: inline;
  font-size: 17px;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  color: ${({ theme }) => theme.status.danger};
`;

export const TitleText = styled.div`
  display: inline;
  font-size: 17px;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  color: #366696;
`;
