import React, { FC, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { resendInvitationEmail } from '../../../../lib/Api/Team';
import { capitaliseUserFullName } from '../../../../lib/Utils';

import ApprovalStatus from './ApprovalStatus';
import AddTeamMate from './AddTeamMate';
import RemoveTeamMate from './RemoveTeamMate';
import TeamTable from '../../../../components/UI/TeamTable/TeamTable';
import { TableLink } from '../../../../components/UI';

import { MainContentContainerReg, ColA, ColB } from '../../Styles';
import {
  ApprovalStatusWrap,
  AddMemberRow,
  AddText,
  Row,
  TableCell,
  Joined,
  TableContainer,
  TableWrap
} from './Styles';
import { CompanyQuote, QuoteSymbol } from '../Registered/Styles';
import {
  DarkBlueHeaderL,
  DarkBlueBodyS,
  BrightBlueHeaderS,
  TurqText,
  BoldText,
  LightGreyText,
  LightBlueBodyS
} from '../../../../Themes';
import { addParticipantIcon } from '../../../../assets/icons/index';

import {
  Team,
  Event,
  User,
  Company,
  TeamApprovalStatus,
  ParticipantListView,
  UserTeamRegistration
} from '../../../../lib/Types';
import { ApplicationState } from '../../../../lib/Store';

const formatTime = (dateString: string, timezone: string): string =>
  `${moment(dateString, 'x')
    .tz(timezone)
    .format('h:mma z')}`;

const formatDate = (dateString: string, timezone: string): string =>
  `${moment(dateString, 'x')
    .tz(timezone)
    .format('Do of MMMM YYYY')}`;
export interface ReviewTeamProps {
  team?: Team;
  teamMemberRegistrations?: UserTeamRegistration[];
  user: User;
  event: Event;
  company: Company;
  companyLogo: string;
  eventTeamSize: number;
  resendInvitationEmail: (email: string) => void;
}

const ReviewTeam: FC<ReviewTeamProps> = ({
  team,
  teamMemberRegistrations,
  event,
  company,
  user,
  companyLogo,
  resendInvitationEmail,
  eventTeamSize
}) => {
  const teamApprovalStatus = team.approved
    ? TeamApprovalStatus.Approved
    : TeamApprovalStatus.AwaitingApproval;
  const isUserCaptain = (): boolean => {
    return (
      !teamMemberRegistrations ||
      teamMemberRegistrations.find(member => member.userId === user.userId).teamCaptain
    );
  };

  // Controls if Add team mate Modal is displayed
  const [addingTeamMate, setAddingTeamMate] = useState(false);

  // Controls if Remove team mate Modal is displayed, and stores the info the to be removed player
  const [removingTeamMate, setRemovingTeamMate] = useState(null);

  // Pass to children components to hide modal
  const cancelAddTeamMate = () => {
    setAddingTeamMate(false);
  };

  // Pass to children components to hide modal
  const cancelRemoveTeamMate = () => {
    setRemovingTeamMate(null);
  };

  // Function to pass to Team Table
  const removeTeamMateWrapper = (member: ParticipantListView) => {
    setRemovingTeamMate(member);
  };

  // If user is only registered team member, display just user. Else display team table.
  const registrationsComponent = () => {
    return teamMemberRegistrations && teamMemberRegistrations.length ? (
      <TeamTable
        members={teamMemberRegistrations.map(r => ({
          ...r.user,
          fullName: capitaliseUserFullName(r.user),
          profileImage: r.user.profileImage,
          teamName: team.name,
          eventId: event.eventId,
          companyName: company.name,
          userActive: r.user.active,
          participantId: r.user.userId,
          joinedStatus: r.joinedStatus,
          teamCaptain: r.teamCaptain,
          teamId: team.teamId
        }))}
        team={team}
        teamCaptainView={isUserCaptain()}
        resendInvitationEmail={resendInvitationEmail}
        removeTeamMate={removeTeamMateWrapper}
      />
    ) : (
      <Row odd={(teamMemberRegistrations.length + team.pendingRegistrations.length) % 2 === 1}>
        <TableCell>1</TableCell>
        <TableCell>
          {user.firstName} {user.lastName} <LightGreyText>(Captain)</LightGreyText>
        </TableCell>
        <TableCell>
          <Joined>Joined</Joined>
        </TableCell>
      </Row>
    );
  };

  // Display pending registrations table, if any.
  const pendingRegistrationsComponent = () => {
    return team.pendingRegistrations && team.pendingRegistrations.length ? (
      <TeamTable
        team={team}
        members={team.pendingRegistrations}
        teamCaptainView={isUserCaptain()}
        listOffset={teamMemberRegistrations.length}
        resendInvitationEmail={resendInvitationEmail}
        removeTeamMate={removeTeamMateWrapper}
      />
    ) : null;
  };

  const AddTeammateComponent = () => {
    if (!team.pendingRegistrations || !isUserCaptain()) return;
    return teamMemberRegistrations.length + team.pendingRegistrations.length < eventTeamSize ? (
      <AddMemberRow
        odd={(teamMemberRegistrations.length + team.pendingRegistrations.length) % 2 === 1}
      >
        <AddText onClick={() => setAddingTeamMate(true)}>
          <TableLink
            size={'45%'}
            margin={'6px 6px 2px 25px'}
            source={addParticipantIcon}
            verticalAlign={'sub'}
          />{' '}
          Add Participant
        </AddText>
      </AddMemberRow>
    ) : null;
  };

  return (
    <MainContentContainerReg>
      <ColA>
        <DarkBlueHeaderL>
          {teamApprovalStatus === TeamApprovalStatus.Approved
            ? "You're registered and your team is approved for the "
            : "You're registered for the "}
          <TurqText>{event.name}</TurqText>
          {' event'}
        </DarkBlueHeaderL>
        <DarkBlueBodyS padding={'0 0 0 0'}>
          {'From '}
          <BoldText>{formatTime(event.startDate, event.timezone)}</BoldText>
          {' on the '}
          <BoldText>{formatDate(event.startDate, event.timezone)}</BoldText>
          {' until '}
          <BoldText>{formatTime(event.endDate, event.timezone)}</BoldText>
          {' on the '}
          <BoldText>{formatDate(event.endDate, event.timezone)}</BoldText>
          {'.'}
        </DarkBlueBodyS>
        <DarkBlueBodyS padding={'0 0 1rem 0'}>
          {'Registrations close at'}
          <BoldText> {formatTime(event.registrationsEndDate, event.timezone)}</BoldText>
          {' on the '}
          <BoldText>{formatDate(event.registrationsEndDate, event.timezone)}</BoldText>
          {'.'}
        </DarkBlueBodyS>
        <BrightBlueHeaderS padding={'2rem 0 1rem'}>
          {'Your Team - '} {team.name}
        </BrightBlueHeaderS>
        <TableContainer>
          <TableWrap>
            {registrationsComponent()}
            {pendingRegistrationsComponent()}
          </TableWrap>
          {AddTeammateComponent()}
          {addingTeamMate ? <AddTeamMate cancel={cancelAddTeamMate} /> : null}
          {!!removingTeamMate ? (
            <RemoveTeamMate cancel={cancelRemoveTeamMate} member={removingTeamMate} />
          ) : null}
        </TableContainer>
        <ApprovalStatusWrap>
          <BrightBlueHeaderS>Approval Status</BrightBlueHeaderS>
          <ApprovalStatus approvalStatus={teamApprovalStatus} />
        </ApprovalStatusWrap>
      </ColA>
      <ColB>
        <CompanyQuote>
          <QuoteSymbol />
          <LightBlueBodyS>{event.greeting}</LightBlueBodyS>
        </CompanyQuote>
      </ColB>
    </MainContentContainerReg>
  );
};
const mapStateToProps = ({ userState, loadingState, participantTeamState }: ApplicationState) => ({
  user: userState.userData,
  company: userState.userCompany,
  team: participantTeamState.team,
  teamMemberRegistrations: participantTeamState.teamMemberRegistrations,
  event: userState.userEvent,
  apiLoading: loadingState.apiCallsInProgress > 0,
  eventTeamSize: userState.userEvent.teamSize
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resendInvitationEmail: (email: string) => resendInvitationEmail(email)
    },
    dispatch
  );
export default connect<{}, {}, ReviewTeamProps>(
  mapStateToProps,
  mapDispatchToProps
)(ReviewTeam) as any;
