import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { PageWrapper, ToolbarButton } from '../../components/UI';
import EventList from './components/EventList';
import { ApplicationState } from '../../lib/Store';
import { Permission, Event } from '../../lib/Types';
import CreateEvent from './components/CreateEvent';
import { plus } from '../../assets/icons';
import { bindActionCreators, Dispatch } from 'redux';
import EditEvent from './components/EditEvent';

interface EventsProps {
  event: Event;
  permissions: Permission[];
}

const Events: FC<EventsProps> = ({ event, permissions }) => {
  const [creating, setCreating] = useState(false);
  const toolbar =
    permissions.includes(Permission.CREATE_EVENT) && !creating && !event.eventId ? (
      <ToolbarButton variant="primary" icon={plus} onClick={() => setCreating(true)}>
        Create Event
      </ToolbarButton>
    ) : (
      <></>
    );
  return (
    <PageWrapper event={false} company={false} formOpener={toolbar}>
      {creating ? (
        <CreateEvent cancel={() => setCreating(false)} onSuccess={() => setCreating(false)} />
      ) : event.eventId ? (
        <EditEvent />
      ) : (
        <EventList />
      )}
    </PageWrapper>
  );
};

const mapStateToProps = ({ eventState, permissionsState }: ApplicationState) => ({
  event: eventState.event,
  permissions: permissionsState.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Events);
