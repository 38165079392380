import styled from 'styled-components/macro';
import { Paper, Button } from '../../../components/UI';
import theme from '../../../theme';
import { DarkBlueBodyM, DarkBlueHeaderM, DarkBlueHeaderL } from '../../../Themes';

const width = 50;

export const Container = styled(Paper)`
  padding: 36px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 0 1.5rem 0;
  line-height: 3rem;
`;

export const BackBtn = styled.img`
  cursor: pointer;
  margin-right: 10px;
  transform: scale(0.96);
  padding-top: 0.2rem;
`;

export const ModalTitle = styled(DarkBlueHeaderL)`
  font-size: 2.1rem;
`;

export const BodyHeader = styled(DarkBlueHeaderM)`
  font-size: 1.5rem;
  line-height: 2.8rem;
`;

export const BodyText = styled(DarkBlueBodyM)`
  font-size: 1.45rem;
  line-height: 2.5rem;
  margin: 0;
  padding: 0;
`;

export const AddImage = styled.div`
  width: 80%;
  margin: 0 0 2rem;
`;

export const Picture = styled.img`
  width: ${width}%;
  height: auto;
  object-fit: contain;
  position: center;
  font-size: 0;
  margin: 1rem 0;
  //border: 1px solid #888888;
`;

export const PictureWrapper = styled.div`
  font-size: 0;
  line-height: 0;
  padding: 0.25rem;
`;

export const RewardImage = styled.div``;

export const UploadButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.85rem 1.8rem;
  border: solid 1px ${theme.primary.light};
  background: #ffffff;
  color: ${theme.primary.light};
  font-size: 1.2rem;
  font-family: ${theme.fontFamily.heading};
  font-weight: ${theme.fontWeights.light};
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  min-width: unset;
  margin: -0.5rem 0;
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

export const CameraImage = styled.img`
  padding-right: 1.4rem;
  height: 1.7rem;
  width: auto;
`;

export const AddPoints = styled(DarkBlueBodyM)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 3.2rem 0;
  font-size: 1.45rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.padding * 4}px;
`;

export const CancelBtn = styled(Button)`
  text-transform: uppercase;
  width: 25%;
  padding: 1.5rem 1rem;
  margin: 0 1rem 0 0;
  min-width: 118px;
`;

export const SaveBtn = styled(Button)`
  text-transform: uppercase;
  color: #ffffff;
  width: 25%;
  padding: 1.5rem 1rem;
  margin: 0 0 0 1rem;
  min-width: 118px;
  opacity: 0.7;
  font-weight: 700;
`;
