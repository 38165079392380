import React, { FC } from 'react';
import { ReplaceSchema } from '../../../validators/replaceSchema';
import { FormikForm, ParticipantListView } from '../../../../../lib/Types';
import ReplaceIndividual from './formComponents/ReplaceIndividual';
import { StyledForm } from '../ModalFormContainer/Styles';

interface ReplaceIndividualFormProps extends FormikForm<ReplaceSchema> {
  participant: ParticipantListView;
  individualParticipants: ParticipantListView[];
  handleDropdownChange: (name: string, value: any) => void;
  onCancel: () => void;
}

const ReplaceIndividualForm: FC<ReplaceIndividualFormProps> = ({
  onCancel,
  handleSubmit,
  ...props
}) => (
  <>
    <StyledForm onSubmit={handleSubmit}>
      <ReplaceIndividual {...props} />
    </StyledForm>
  </>
);

export default ReplaceIndividualForm;
