import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
`;

interface SelectProps {
  valid: boolean;
  active?: boolean;
}

export const Label = styled.div<SelectProps>`
  background-color: white;
  padding: 0 0.4rem;
  font-size: 1.1rem;
  font-weight: 400;
  position: absolute;
  transition: all 0.1s;
  left: 1.5rem;
  color: ${({ valid, active }) => {
    if (valid) {
      if (active) {
        return '#44DEFF';
      } else {
        return '#D0DCF2';
      }
    } else {
      return '#FF5E86';
    }
  }};
  top: -0.9rem;
  z-index: 1;
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.primary.xlight};
  background-color: ${({ theme }) => theme.backgrounds.white};
  border: ${({ theme }) => theme.borders.input.default};
  right: -1px;
  left: -1px;
  top: calc(100% - 1px);
  z-index: 97;
  margin-bottom: 2rem;
  max-height: 280px;
  overflow-y: scroll;

  &:last-child {
    border-bottom-left-radius: 0.35rem;
    border-bottom-right-radius: 0.35rem;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: transparent;
    padding: 2px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.backgrounds.darkGrey};
    border: 2px solid #ffffff;
  }
`;

export const Option = styled.div`
  color: ${({ theme }) => theme.primary.xlight};
  height: 46px;
  padding: ${({ theme }) => theme.padding}px;
  display: flex;
  align-items: center;
  z-index: 98;
`;

export const OptionLabel = styled.p`
  font-size: ${({ theme }) => theme.fonts.subBody * 1.15}rem;
  color: ${({ theme }) => theme.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  z-index: 99;
  &:hover {
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    color: ${({ theme }) => theme.primary.dark};
    font-size: ${({ theme }) => theme.fonts.subBody * 1.18}rem;
  }
`;
