import React, { FC, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  OrderDirection,
  PagedResponse,
  TeamListFilter,
  Permission,
  TableHeaderItem,
  TeamListView as TeamListViewType,
  Team,
  ParticipantListView,
  Company,
  CompanyDemographic
} from '../../../../lib/Types';
import {
  setTeamListFilter,
  setTeam,
  setGeneratingTeam
} from '../../../../lib/Store/contexts/team/actions';
import { approveTeam, loadPagedTeamList, getTeam, generateTeams } from '../../../../lib/Api/Team';
import { loadParticipantsWithoutTeam } from '../../../../lib/Api/Participants';
import { ApplicationState } from '../../../../lib/Store';

import PaginatedTable from '../../../../components/UI/PaginatedTable';
import EditTeam from '../EditTeam';
import TeamGenerationWidget from '../TeamGenerationWidget';
import GenerateTeams from '../GenerateTeams';
import PaginatedTableSearch from 'src/components/UI/PaginatedTableSearch';
import { NoRecordsFound } from 'src/components/UI';
import { eventGuy } from 'src/assets/icons';

export interface TeamListProps {
  pagedTeamList: PagedResponse<TeamListViewType>;
  participantsWithoutTeams: ParticipantListView[];
  companyDemographics: CompanyDemographic[];
  usersCompanyId: number;
  usersEventId: number;
  generatingTeams: boolean;
  team: Team;
  permissions: Permission[];
  companyOptions: Company[];
  filter: TeamListFilter;
  loading: boolean;
  setFilter: (filter: TeamListFilter) => void;
  loadTeams: (filter: TeamListFilter) => void;
  loadParticipantsWithoutTeam: (eventId: number, companyId?: number) => void;
  editTeam: (teamId: number) => any;
  setTeam: (team: Team) => any;
  approveTeam: (teamId: number) => any;
  setGenerateTeam: () => any;
  generateTeam: (companyId: number, data: any) => any;
  cancelGenerateTeam: () => any;
}

const TeamListView: FC<TeamListProps> = ({
  filter,
  setFilter,
  loading,
  loadTeams,
  editTeam,
  setTeam,
  team,
  pagedTeamList,
  permissions,
  setGenerateTeam,
  generateTeam,
  generatingTeams,
  loadParticipantsWithoutTeam,
  participantsWithoutTeams,
  companyDemographics,
  cancelGenerateTeam,
  usersCompanyId,
  usersEventId,
  companyOptions,
  approveTeam
}) => {
  const { count } = pagedTeamList;
  const headers: TableHeaderItem[] = [
    { display: 'Team Name', mapProp: 'teamName' },
    { display: 'Members', mapProp: 'members' },
    { display: 'Created Date', mapProp: 'createdDate' },
    { display: 'Organisation', mapProp: 'companyName' },
    { display: 'Event', mapProp: 'eventName' },
    {
      display: 'Status',
      mapProp: 'approved',
      mapBool: {
        true: 'Approved',
        false: 'Unapproved'
      }
    }
  ];

  const onEdit = (teamId: number) => editTeam(teamId);
  const onGenerateCancel = () => cancelGenerateTeam();
  const setPage = (page: number) => setFilter({ ...filter, page });
  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    setFilter({ ...filter, orderColumn, orderDirection });

  useEffect(() => {
    setTeam({} as Team);
    loadTeams(filter);
    loadParticipantsWithoutTeam(filter.eventId, filter.companyId);
  }, [filter, loadParticipantsWithoutTeam, loadTeams, setTeam]);

  const setKeyword = (keyword: string) => setFilter({ ...filter, keyword });
  const onApprove = async (teamId: number) => {
    const res = await approveTeam(teamId);
    const { data } = res;
    return data;
  };

  const TeamListJSX = count ? (
    <>
      <PaginatedTable
        headers={headers}
        filter={filter}
        idProp={'teamId'}
        data={pagedTeamList}
        loading={loading}
        onSetPage={setPage}
        onSort={setSort}
        onApprove={onApprove}
        onEdit={onEdit}
        actions={[
          { type: 'edit', show: permissions.includes(Permission.EDIT_TEAM) },
          { type: 'approve', show: permissions.includes(Permission.APPROVE_TEAM) }
        ]}
      />
    </>
  ) : (
    <>
      {loading ? (
        ''
      ) : (
        <>
          <NoRecordsFound icon={eventGuy} message="There are currently no teams" />
        </>
      )}
    </>
  );

  return (
    <>
      {team.teamId ? (
        <EditTeam />
      ) : generatingTeams ? (
        <GenerateTeams
          loading={loading}
          companyDemographics={companyDemographics}
          generateCancel={onGenerateCancel}
          userCompanyId={filter.companyId || usersCompanyId}
          userEventId={filter.eventId || usersEventId}
          generateTeams={generateTeam}
          onCancel={cancelGenerateTeam}
        />
      ) : (
        <>
          {/* CPM */}
          {usersCompanyId && (
            <TeamGenerationWidget
              companyId={usersCompanyId}
              generateTeam={setGenerateTeam}
              participantsWithoutTeams={participantsWithoutTeams}
            />
          )}
          {/* Master Admin */}
          {!usersCompanyId && filter.companyId && (
            <TeamGenerationWidget
              companyOptions={companyOptions}
              selectedCompany={filter.companyId}
              generateTeam={setGenerateTeam}
              participantsWithoutTeams={participantsWithoutTeams}
            />
          )}
          <PaginatedTableSearch onKeywordFilter={setKeyword} />
          {TeamListJSX}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({
  teamsState,
  loadingState,
  participantsState,
  userState,
  permissionsState,
  companyState,
  companyAdminEventState
}: ApplicationState) => ({
  pagedTeamList: teamsState.pagedTeamList,
  usersCompanyId: userState.userData.companyId,
  usersEventId: userState.userEvent.eventId,
  generatingTeams: teamsState.generatingTeams,
  companyDemographics: companyAdminEventState.currentEvent?.companyDemographics,
  participantsWithoutTeams: participantsState.participantsWithoutTeam,
  team: teamsState.team,
  filter: teamsState.filter,
  loading: loadingState.apiCallsInProgress > 0,
  permissions: permissionsState.permissions,
  companyOptions: companyState.companyOptions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setFilter: (filter: TeamListFilter) => setTeamListFilter(filter),
      loadTeams: (filter: TeamListFilter) => loadPagedTeamList(filter),
      loadParticipantsWithoutTeam: (eventId: number, companyId?: number) =>
        loadParticipantsWithoutTeam(eventId, companyId),
      setTeam: (team: Team) => setTeam(team),
      editTeam: (teamId: number) => getTeam(teamId),
      approveTeam: (teamId: number) => approveTeam(teamId),
      setGenerateTeam: () => setGeneratingTeam(true),
      generateTeam: (companyId: number, eventId: number, companyDemographicId: string) =>
        generateTeams(companyId, eventId, companyDemographicId),
      cancelGenerateTeam: () => setGeneratingTeam(false)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeamListView);
