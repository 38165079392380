import React, { FC } from 'react';
import { NoRecordsFound, PaginatedTable } from '../../../../components/UI';
import PaginatedTableSearch from 'src/components/UI/PaginatedTableSearch';

import { eventGuy } from '../../../../assets/icons';
import {
  OrderDirection,
  PagedResponse,
  PaginationFilter,
  Permission,
  TableHeaderItem,
  EventListView
} from '../../../../lib/Types';

export interface EventListProps {
  events: PagedResponse<EventListView>;
  filter: PaginationFilter;
  loading: boolean;
  permissions: Permission[];
  onSort: (column: string, direction: OrderDirection) => void;
  onSetPage: (page: number) => void;
  onEdit: (industryId: number) => void;
  setFilter: (filter: PaginationFilter) => void;
}

const EventList: FC<EventListProps> = ({ events, loading, permissions, ...props }) => {
  const { count } = events;
  const headers: TableHeaderItem[] = [
    { display: 'Event Name', mapProp: 'name' },
    { display: 'Joined', mapProp: 'totalCompanyRegistrationCount' },
    { display: 'Timezone', mapProp: 'timezone' },
    { display: 'Event Dates', mapProp: 'eventDates' },
    { display: 'Grace Period', mapProp: 'gracePeriod' },
    { display: 'Status', mapProp: 'active', mapBool: { true: 'Active', false: 'Inactive' } },
    { display: 'Registration Code', mapProp: 'passCode' }
  ];

  const setKeyword = (keyword: string) => props.setFilter({ ...props.filter, keyword });

  return (
    <>
      <PaginatedTableSearch onKeywordFilter={setKeyword} />
      {count ? (
        <PaginatedTable
          idProp="eventId"
          headers={headers}
          data={events}
          loading={loading}
          actions={[
            { type: 'edit', show: permissions.includes(Permission.EDIT_EVENT) },
            { type: 'delete', show: permissions.includes(Permission.DELETE_EVENT) }
          ]}
          {...props}
        />
      ) : (
        <>
          {loading ? (
            ''
          ) : (
            <NoRecordsFound icon={eventGuy} message="There are currently no events" />
          )}
        </>
      )}
    </>
  );
};

export default EventList;
