import React, { FC, ChangeEvent, MouseEvent, FocusEvent, useState } from 'react';
import { StyledInput, StyledTextArea, Wrapper, Label, IconRight, CurrencySymbol } from './Styles';
import ErrorLabel from '../ErrorLabel';
import RequiredLabel from '../RequiredLabel';

interface InputProps {
  id: string;
  name?: string;
  type?: string;
  label: string;
  flex?: number;
  hideLabel?: boolean;
  error?: any;
  required?: boolean;
  readonly?: boolean;
  value: string | number | undefined;
  icon?: string;
  iconTop?: string;
  iconWidth?: string;
  iconHeight?: string;
  disabled?: boolean;
  margin?: string;
  width?: string;
  textArea?: boolean;
  horizontalSiblings?: boolean;
  autoFocus?: boolean;
  min?: number;
  max?: number;
  onClickIcon?: (e: MouseEvent<any>) => void;
  onChange: (e: ChangeEvent<any>) => void;
  onBlur?: (e: FocusEvent<any>) => void;
}

const Input: FC<InputProps> = ({
  id,
  name,
  type = 'text',
  label,
  hideLabel,
  error,
  required,
  value,
  icon,
  disabled = false,
  readonly = false,
  onChange,
  onClickIcon,
  onBlur,
  margin = '0px',
  flex,
  min,
  max,
  width,
  textArea = false,
  horizontalSiblings = false,
  iconTop = null,
  iconWidth = null,
  iconHeight = null,
  autoFocus = false
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <>
      {error ? <ErrorLabel error={error || error?.name} /> : ''}
      <Wrapper flex={flex} horizontalSiblings={horizontalSiblings}>
        {required ? <RequiredLabel /> : ''}
        {type === 'currency' && <CurrencySymbol>$</CurrencySymbol>}
        {textArea ? (
          <StyledTextArea
            id={id}
            name={name}
            valid={!error}
            value={value}
            disabled={disabled}
            readOnly={readonly}
            autoComplete="off"
            onChange={onChange}
            onClick={e => {
              e.preventDefault();
              setFocused(true);
              onChange(e);
            }}
            onBlur={e => {
              e.preventDefault();
              setFocused(false);
              onBlur && onBlur(e);
            }}
            margin={margin}
            placeholder={label}
          />
        ) : (
          <StyledInput
            id={id}
            name={name}
            type={type}
            min={min}
            max={max}
            valid={!error}
            value={value}
            disabled={disabled}
            readOnly={readonly}
            autoComplete="off"
            onChange={onChange}
            onClick={e => {
              e.preventDefault();
              setFocused(true);
              onChange(e);
            }}
            onBlur={e => {
              e.preventDefault();
              setFocused(false);
              onBlur && onBlur(e);
            }}
            margin={margin}
            width={width}
            placeholder={label}
            autoFocus={autoFocus}
          />
        )}
        {!((focused || value) && hideLabel) && (
          <Label htmlFor={id} inputValue={value} valid={!error}>
            {label}
          </Label>
        )}
        {icon && (
          <IconRight
            src={icon}
            iconWidth={iconWidth}
            iconHeight={iconHeight}
            iconTop={iconTop}
            onClick={onClickIcon}
          />
        )}
      </Wrapper>
    </>
  );
};

export default Input;
