import styled from 'styled-components/macro';
import { DarkBlueBodyXS } from '../../../Themes';

interface TableCellProps {
  numbered?: boolean;
  dropable?: boolean;
  highlight?: boolean;
  wrap?: boolean;
}

interface RowActionsProps {
  highlight?: boolean;
  leaderboard?: boolean;
}

interface TableRowProps {
  highlight?: boolean;
}

export const TableContainer = styled.div`
  overflow: auto;
  width: 100%;
  background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 2rem 100%, 2rem 100%, 1rem 100%, 1rem 100%;
  background-attachment: local, local, scroll, scroll;
`;

export const Table = styled.div`
  display: table;
  font-size: calc(${({ theme }) => theme.fonts.input}rem + 0.42vw);
  width: 100%;
  border-collapse: separate;
`;

export const TableHead = styled.div`
  display: table-header-group;
  /* white-space: nowrap; */
`;

export const ActionHeader = styled.div`
  display: table-cell;
  border-bottom: 1px solid ${({ theme }) => theme.borders.xlight};
  width: 13%;
`;

export const TableCell = styled(DarkBlueBodyXS)<TableCellProps>`
  font-size: 88%;
  text-align: ${props => (props.numbered ? 'center' : 'left')};
  display: table-cell;
  border-bottom: 1px solid ${({ theme }) => theme.borders.xlight};
  padding: 1vw 1vw 0.75vw 0;
  vertical-align: middle;
  min-width: 6rem;
  max-width: max-content;
  width: max-content;

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    display: ${props => (props.dropable ? 'none' : 'table-cell')};
  }

  &:nth-child(1) {
    border-top-left-radius: ${props => (props.highlight ? '100rem' : 0)};
    border-bottom-left-radius: ${props => (props.highlight ? '100rem' : 0)};
  }
`;

export const TableRow = styled.div<TableRowProps>`
  display: table-row;
  position: relative;
  background: ${props => (props.highlight ? 'rgba(0,220,210,0.3)' : 'rgba(255,255,255,0.3)')};
`;

export const TableBody = styled.div`
  color: ${({ theme }) => theme.primary.dark};
  display: table-row-group;
`;

export const RowActions = styled.div<RowActionsProps>`
  justify-content: space-between;
  text-align: right;
  display: table-cell;
  vertical-align: middle;
  border-bottom: 1px solid ${({ theme }) => theme.borders.xlight};
  padding: 0.8rem 1.6rem 0.8rem 0;
  width: 13%;
  align-items: center;
  min-width: 8rem;
  border-top-right-radius: ${props => (props.highlight ? '100rem' : 0)};
  border-bottom-right-radius: ${props => (props.highlight ? '100rem' : 0)};
  padding-right: ${props => (props.leaderboard ? '1.6rem' : 0)};
`;

export const PassCodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PassCodeCopyWrapper = styled.div`
  display: flex;
`;

export const PassCodeCopy = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  margin-left: 1.5rem;
  margin-bottom: calc(50% - 5px);
  border: 1px solid #b9bbc0;
  border-radius: 25%;
  background-color: ${({ theme }) => theme.primary.white};
  z-index: 1;
  cursor: pointer;

  :hover {
    transform: scale(1.075);
  }

  :after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    right: 50%;
    top: 50%;
    border: 1px solid #b9bbc0;
    border-radius: 25%;
    background-color: ${({ theme }) => theme.primary.white};
    z-index: -1;
  }
`;

export const ProfileImage = styled.div<{ src: string }>`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  margin: 0 0 0 3rem;
  overflow: hidden;
  background: ${props => `url('${props.src}') no-repeat`};
  background-position: center;
  background-size: cover;
`;

export const StatusPill = styled.div<{ status: boolean }>`
  background: ${({ theme, status }) => (status ? theme.primary.mediumGreen : '#B5BCC9')};
  border-radius: 5px;
  color: ${({ theme }) => theme.primary.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1rem;
  padding: 1rem 2rem;
  text-align: center;
  width: 100px;
`;

export const LoaderContainer = styled.div`
  padding: ${({ theme }) => theme.padding}px;
  margin-top: ${({ theme }) => theme.padding}px;
`;
