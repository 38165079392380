import React, { FC } from 'react';

import ProfileWidget from '../../ProfileWidget';
import UserInfo from '../../UserInfo';

import { ProfileWrapper, TopSectionWrapper } from './Styles';

import { RoleCode } from '../../../../lib/Types';

export interface PageContentHeaderProps {
  code: RoleCode;
  companyImg?: string;
}

const PageContentHeader: FC<PageContentHeaderProps> = ({ code, companyImg }) => {
  return (
    <TopSectionWrapper>
      {code === RoleCode.Employee ? (
        <UserInfo companyImg={companyImg} />
      ) : (
        <ProfileWrapper>
          <ProfileWidget />
        </ProfileWrapper>
      )}
    </TopSectionWrapper>
  );
};

export default PageContentHeader;
