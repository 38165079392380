import styled from 'styled-components/macro';
import { DarkBlueHeaderM, DarkBlueBodyS } from '../../../Themes';

export const SubHeading = styled(DarkBlueHeaderM)`
  padding: 0 0 0.5rem;
`;

export const StatSquareWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 1.5rem 0;
  justify-content: flex-start;
`;

export const HeroCount = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: ${({ theme }) => theme.fonts.heading}rem;
  color: ${props => (props.color ? props.color : props.theme.primary.dark)};
  margin-left: ${({ theme }) => theme.padding}px;
`;

export const BodyCopy = styled(DarkBlueBodyS)`
  padding: 0.5rem 0 0;
  line-height: normal;
`;
