import { AuthErrorContext, FirebaseAuthErrors } from '../Types';

export class AuthError extends Error {
  context: AuthErrorContext;

  constructor(m: string, context: AuthErrorContext) {
    super(m);
    this.context = context;
    Object.setPrototypeOf(this, AuthError.prototype);
  }
}

export const getSigninError = (code: FirebaseAuthErrors): AuthError => {
  switch (code) {
    case 'auth/user-not-found':
      return new AuthError('Invalid email address provided.', 'email');
    case 'auth/wrong-password':
      return new AuthError('Incorrect password provided.', 'password');
    case 'auth/user-disabled':
      return new AuthError('Your user account has been disabled.', 'email');
    case 'auth/too-many-requests':
      return new AuthError('Too many unsuccessful login attempts. Try again later.', 'email');
    case 'auth/internal-error':
    default:
      return new AuthError('An unknown error occured while trying to sign in.', 'internal');
  }
};

export const getSignupError = (code: FirebaseAuthErrors): AuthError => {
  switch (code) {
    case 'auth/email-already-in-use':
      return new AuthError('This email address is already in use.', 'email');
    case 'auth/invalid-email':
      return new AuthError('Invalid email address provided.', 'email');
    case 'auth/operation-not-allowed':
      return new AuthError('Email account registration is currently disabled.', 'internal');
    case 'auth/weak-password':
      return new AuthError("This password doesn't meet the complexity requirements.", 'password');
    case 'auth/internal-error':
    default:
      return new AuthError('An unknown error occurred while trying to register.', 'internal');
  }
};
