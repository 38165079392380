import styled from 'styled-components/macro';

export interface StyledInputProps {
  valid: boolean;
  width?: string;
  margin?: string;
}

export const StyledInput = styled.input<StyledInputProps>`
  color: ${({ theme }) => theme.primary.dark};
  border: ${({ theme, valid }) =>
    valid ? theme.borders.input.default : theme.borders.input.error};
  border-radius: 0.35rem;
  padding: 0.3rem 0.3rem 0.3rem 1.5rem;
  font-size: calc(${({ theme }) => theme.fontScale.sm} * 1.1);
  width: ${({ width }) => (width ? width : '100%')};
  margin: ${({ margin }) => (margin ? margin : '')};
  height: 4.35rem;
  letter-spacing: 0.02rem;

  ::-webkit-input-placeholder {
    color: #bbd3fa;
    font-size: 1.3rem;
    font-weight: lighter;
    letter-spacing: 0.08rem;
  }

  :focus {
    border: ${({ theme }) => theme.borders.input.focused};
  }

  :focus::-webkit-input-placeholder {
    color: transparent;
  }
`;
