import React, { FC, useState, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import moment from 'moment';

import { loadReportOptions, loadReport } from '../../lib/Api/reports';
import { loadCompanyOptions } from '../../lib/Api/Company';
import { LoadEventFilter, loadEventOptions } from '../../lib/Api/Event';

import { PageWrapper } from '../../components/UI';
import CreateReport from './components/CreateReport';
import DownloadReport from './components/DownloadReport';

import { DarkBlueHeaderL } from '../../Themes';

import { ApplicationState } from '../../lib/Store';
import { ApiStatus, FormikForm, Company, ReportType } from '../../lib/Types';
import validationSchema, { CreateReportSchema } from './validators/createReportSchema';

interface ReportsProps extends FormikForm<CreateReportSchema> {
  onLoadReport: (data: CreateReportSchema) => any;
  onLoadCompanyOptions: (eventId: number) => Company[];
  onLoadReportOptions: () => ReportType[];
  onLoadEventOptions: (filter?: LoadEventFilter) => Event[];
  companyOptions: Company[];
  reportTypes: ReportType[];
  eventOptions: Event[];
  loading: boolean;
}

interface CreateReportFormState {
  eventId: number;
  companyId: number;
  reportTypeId: number;
  startDate: Date;
  endDate: Date;
}

const Reports: FC<ReportsProps> = ({
  onLoadReport,
  onLoadReportOptions,
  onLoadEventOptions,
  reportTypes,
  loading
}) => {
  const { selectedCompanyIds, selectedEventId } = useSelector(
    ({ eventCompanyPageFilterState }: ApplicationState) => ({
      selectedEventId: eventCompanyPageFilterState.selectedEventId,
      selectedCompanyIds: eventCompanyPageFilterState.selectedCompanyIds
    })
  );

  // Load report type options
  useEffect(() => {
    onLoadReportOptions();
  }, []);

  // Load all event options
  useEffect(() => {
    onLoadEventOptions();
  }, []);

  const [reportData, setReportData] = useState(null);

  const cancelCreateReport = () => {
    setReportData(null);
  };

  const processReportData = (data, reportTypeId, startDate, endDate, timezone) => {
    const reportName = reportTypes.find(type => type.reportTypeId === reportTypeId).name;
    data.reportName = reportName;
    data.timezone = timezone;
    data.dateString =
      moment(startDate).format('DD-MM-YYYY') + ' - ' + moment(endDate).format('DD-MM-YYYY');
    setReportData(data);
  };

  const { values, handleSubmit, handleChange, errors, setFieldValue } = useFormik<
    CreateReportSchema
  >({
    initialValues: {
      eventId: selectedEventId || -1,
      companyId: selectedCompanyIds?.length > 1 ? -1 : selectedCompanyIds?.[0] || -1,
      reportTypeId: NaN,
      startDate: new Date(),
      endDate: new Date(),
      timezone: ''
    },
    validationSchema,
    validate: ({ eventId, reportTypeId, startDate, endDate, timezone }) => {
      const errors: { [key: string]: string } = {};
      if (!eventId) errors.eventId = 'Event is required.';
      if (!reportTypeId) errors.reportTypeId = 'Report type is required.';
      if (!startDate) errors.startDate = 'Start date required.';
      if (!endDate) errors.endDate = 'End date required.';
      if (startDate > endDate) errors.startDate = 'Valid date range required.';
      if (!timezone) errors.timezone = 'Report timezone is required.';
      return errors;
    },
    onSubmit: async (data: CreateReportSchema): Promise<void> => {
      const reportData = {
        ...data,
        eventId: selectedEventId,
        companyId: selectedCompanyIds?.length > 1 ? -1 : selectedCompanyIds?.[0] || -1
      };
      const response = await onLoadReport(reportData);
      if (response.status === ApiStatus.SUCCESS)
        processReportData(
          response.data,
          data.reportTypeId,
          data.startDate,
          data.endDate,
          data.timezone
        );
    }
  });

  // Handle drop down and date changes
  const handleChangeCustom = (name: string, data: any) => {
    if (name === 'companyId' && !data) data = -1;
    setFieldValue(name, data);
  };

  const getPageContent = () => {
    if (!selectedEventId) return <DarkBlueHeaderL>Please select an event above</DarkBlueHeaderL>;
    return reportData ? (
      <DownloadReport onCancel={cancelCreateReport} data={reportData} />
    ) : (
      <CreateReport
        onCancel={cancelCreateReport}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleChangeCustom={handleChangeCustom}
        values={values}
        errors={errors}
        reportTypes={reportTypes}
        loading={loading}
      />
    );
  };

  return <PageWrapper>{getPageContent()}</PageWrapper>;
};

const mapStateToProps = (state: ApplicationState) => ({
  companyOptions: state.companyState.companyOptions,
  reportTypes: state.reportState.reportTypes,
  eventOptions: state.eventState.eventOptions,
  loading: state.loadingState.apiCallsInProgress > 0
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onLoadReport: (data: CreateReportSchema) => loadReport(data),
      onLoadCompanyOptions: (eventId: number) => loadCompanyOptions(eventId),
      onLoadReportOptions: () => loadReportOptions(),
      onLoadEventOptions: (filter?: LoadEventFilter) => loadEventOptions(filter)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Reports as any);
