import { Reward, PaginationFilter, PagedResponse } from '../../../Types';
import {
  SetRewardsAction,
  SET_REWARDS,
  AddRewardAction,
  ADD_REWARD,
  UpdateRewardAction,
  UPDATE_REWARD,
  SET_REWARDS_FILTER,
  SET_DELETING_REWARD,
  RewardsActionTypes,
  SET_EDITING_REWARD,
  SET_REWARD_OPTIONS,
  SetRewardOptionsAction
} from './types';

export const setRewards = (rewards: PagedResponse<Reward>): SetRewardsAction => ({
  type: SET_REWARDS,
  rewards
});

export const setRewardOptions = (rewardOptions: Reward[]): SetRewardOptionsAction => ({
  type: SET_REWARD_OPTIONS,
  rewardOptions
});

export const addReward = (reward: Reward): AddRewardAction => ({
  type: ADD_REWARD,
  reward
});

export const updateReward = (rewardData: Partial<Reward>): UpdateRewardAction => ({
  type: UPDATE_REWARD,
  rewardData
});

export const setRewardFilter = (filter: PaginationFilter): RewardsActionTypes => ({
  type: SET_REWARDS_FILTER,
  filter
});

export const setDeletingReward = (
  deletingReward: boolean,
  rewardIDForDeleting?: number
): RewardsActionTypes => ({
  type: SET_DELETING_REWARD,
  deletingReward,
  rewardIDForDeleting
});

export const setEditingReward = (rewardIDForEditing: number | null): RewardsActionTypes => ({
  type: SET_EDITING_REWARD,
  rewardIDForEditing
});

export default {
  setRewards,
  addReward,
  setRewardOptions,
  updateReward,
  setDeletingReward,
  setEditingReward
};
