import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchRewards, deleteReward } from '../../../lib/Api/Rewards';
import { ApplicationState } from '../../../lib/Store';
import { RewardsState } from '../../../lib/Store/contexts/rewards/types';
import { LoadingState } from '../../../lib/Store/contexts/loading/types';
import {
  setRewardFilter,
  setDeletingReward
  //setEditingReward
} from '../../../lib/Store/contexts/rewards/actions';
import { OrderDirection } from '../../../lib/Types';

import PaginatedTable from '../../../components/UI/PaginatedTable';
//import EditRewardForm from '../EditRewardForm';
import DeleteReward from '../DeleteReward';
import { RewardsHeader } from './Styles';

const ListRewards: FC = () => {
  const dispatch = useDispatch();
  const { rewards, rewardsFilter, deletingReward, rewardIDForEditing } = useSelector<
    ApplicationState,
    RewardsState
  >(state => state.rewardsState);
  const { apiCallsInProgress } = useSelector<ApplicationState, LoadingState>(
    state => state.loadingState
  );

  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    dispatch(setRewardFilter({ ...rewardsFilter, orderColumn, orderDirection }));

  const onSetDelete = (rewardIDForDeleting: number) =>
    dispatch(setDeletingReward(true, rewardIDForDeleting));

  const onDeleteReward = (rewardIdToDelete: number) => dispatch(deleteReward(rewardIdToDelete));

  const setPage = (page: number) => dispatch(setRewardFilter({ ...rewardsFilter, page }));
  //const onSetEdit = (rewardIDForEditing: number) => dispatch(setEditingReward(rewardIDForEditing));

  useEffect(() => {
    dispatch(fetchRewards(rewardsFilter));
  }, [rewardsFilter]);

  return (
    <>
      {rewardIDForEditing && !deletingReward ? (
        // <EditRewardForm />
        <></>
      ) : (
        <>
          {deletingReward ? (
            <DeleteReward
              onCancel={() => dispatch(setDeletingReward(false, null))}
              onDelete={onDeleteReward}
            />
          ) : null}

          <RewardsHeader>Rewards</RewardsHeader>
          {rewards.results && (
            <PaginatedTable
              data={rewards}
              filter={rewardsFilter}
              onSort={setSort}
              alignNumberedCellsCenter={false}
              headers={[
                { display: 'Reward Name', mapProp: 'name' },
                { display: 'Points Earned', mapProp: 'points' }
              ]}
              idProp={'rewardId'}
              loading={apiCallsInProgress > 1}
              onSetPage={setPage}
              onDelete={onSetDelete}
              //onEdit={onSetEdit}
              actions={[
                //{ type: 'edit', show: true },
                { type: 'delete', show: true }
              ]}
            />
          )}
        </>
      )}
    </>
  );
};

export default ListRewards;
