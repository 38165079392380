import React, { FC, useEffect, useState, useRef } from 'react';
import { StatusVariant } from '../../../lib/Types';
import { Toast, Wrapper, IconContainer, Icon, Label } from './Styles';
import { StatusIcons } from '../../../assets/icons';

export interface ToastNotificationProps {
  variant?: StatusVariant;
  message: string;
  duration?: number;
  onDismiss: () => void;
}

const ToastNotification: FC<ToastNotificationProps> = ({
  variant = 'success',
  message,
  duration = 3,
  onDismiss
}) => {
  let interval: number;
  const toastRef = useRef<HTMLDivElement>(null);
  const [className, setClassName] = useState<'show' | 'hide'>('show');

  const dismissNotification = () => {
    setClassName('hide');
    toastRef.current?.addEventListener('animationend', () => {
      onDismiss();
    });
  };

  const onClick = () => {
    clearInterval(interval);
    dismissNotification();
  };

  useEffect(() => {
    interval = setTimeout(() => {
      dismissNotification();
    }, duration * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Toast ref={toastRef} className={className} variant={variant} onClick={onClick}>
      <Wrapper>
        <IconContainer variant={variant}>
          <Icon src={StatusIcons[variant]} />
        </IconContainer>
        <Label>{message}</Label>
      </Wrapper>
    </Toast>
  );
};

export default ToastNotification;
