import { APIThunkResult, YourSteps, TeamStats } from '../Types';
import { apiRequest, apiFetch } from './Utils';
import { server } from '../../CONSTANTS';
import { setYourSteps, setTeamStats } from '../Store/contexts/stats/actions';
import { getClientTimezoneOffset } from '../TimeUtils';

export function loadYourSteps(
  eventId: number,
  fromDate: string,
  toDate: string,
  utcOffset: number
): APIThunkResult<YourSteps[]> {
  return apiRequest<YourSteps[]>(async dispatch => {
    const results = await apiFetch<YourSteps[]>({
      method: 'GET',
      url: `${server}/activities/totalSteps/${eventId}/${fromDate}/${toDate}/${utcOffset}`
    });
    dispatch(setYourSteps(results));
    return results;
  });
}

export type LoadTeamStats = (teamId: number) => APIThunkResult<TeamStats[]>;
export function loadTeamStats(teamId: number): APIThunkResult<TeamStats[]> {
  return apiRequest<TeamStats[]>(async dispatch => {
    const results = await apiFetch<TeamStats[]>({
      method: 'GET',
      url: `${server}/team/stats/${getClientTimezoneOffset()}/${teamId}`
    });

    dispatch(setTeamStats(results));
    return results;
  });
}

export type LoadMyTeamStats = () => APIThunkResult<TeamStats[]>;
export function loadMyTeamStats(): APIThunkResult<TeamStats[]> {
  return apiRequest<TeamStats[]>(async dispatch => {
    const results = await apiFetch<TeamStats[]>({
      method: 'GET',
      url: `${server}/team/me/stats/${getClientTimezoneOffset()}`
    });

    dispatch(setTeamStats(results));
    return results;
  });
}
