import { SDAState, SDAActionTypes, SET_PAGED_SDA_LIST, SET_SDA, SET_SDA_FILTER } from './types';

export const initialState: SDAState = {
  pagedSDAList: {
    results: [],
    count: 0
  },
  SDA: {
    suspectDailyActivityId: null,
    completionDate: '',
    name: '',
    reason: '',
    approvalStatus: null,
    totalSteps: null
  },
  filter: {
    page: 1,
    rowsPerPage: 30,
    orderColumn: 'suspectDailyActivityId',
    orderDirection: 'ASC',
    keyword: '',
    companyId: null,
    eventId: null
  }
};

const SDAReducer = (state: SDAState = initialState, action: SDAActionTypes): SDAState => {
  switch (action.type) {
    case SET_PAGED_SDA_LIST:
      return {
        ...state,
        pagedSDAList: action.pagedSDAList
      };
    case SET_SDA:
      return {
        ...state,
        SDA: action.SDA
      };
    case SET_SDA_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    default:
      return state;
  }
};

export default SDAReducer;
