import styled from 'styled-components/macro';

interface WrapperProps {
  future: boolean;
  complete?: boolean;
  checked?: boolean;
}

interface ContentProps {
  future: boolean;
  complete?: boolean;
}

interface MarkerProps {
  checked?: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 58px;
  margin-left: 0.5rem;
  &:last-child {
    margin-right: 0.5rem;
  }

  flex-direction: column;
  align-items: center;
`;

export const Marker = styled.div<MarkerProps>`
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 12px solid #fff;
  width: 0;
  height: 0;
  margin-top: 1.3rem;
  display: ${props => (props.checked ? 'block' : 'none')};
`;

export const Wrapper = styled.div<WrapperProps>`
  text-align: center;
  /* display: inline-block; */
  padding-top: 6px;
  margin: 0 4px;
  width: 58px;
  height: 80px;
  position: relative;
  vertical-align: top;
  background: transparent;
`;

export const Label = styled.label<ContentProps>`
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: ${({ future }) => !future && 'pointer'};
  background-color: ${({ complete }) => (complete ? '#ffffff' : 'transparent')};
  border-radius: 17px;
  padding: 5px 0 0;
  border: 2px solid rgba(255, 255, 255, 0.4);
  color: ${({ complete }) => (complete ? '#f485af;' : '#ffffff')};
  opacity: ${({ future }) => (future ? 0.4 : 1)};
  height: 70px;
`;

export const Radio = styled.input<ContentProps>`
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;

  &:checked + ${Label} {
    border: 2px solid #ffffff;
    opacity: 1;
  }

  cursor: ${({ future }) => !future && 'pointer'};
`;

export const Day = styled.span`
  font-size: 16pt;
  line-height: 100%;
  font-weight: bold;
`;

export const Month = styled.span`
  font-size: 11pt;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
`;

export const Today = styled.span`
  font-size: 8pt;
  line-height: 130%;
  display: block;
  padding-bottom: 5px;
`;

export const Icon = styled.img`
  width: 1.4rem;
  position: relative;
  top: 3px;
`;
