import { apiRequest, apiFetch, objectToQueryString } from './Utils';
import { server } from '../../CONSTANTS';

import { generateTempAdminPassword } from '../Utils';
import Backend from '../Backend';

import { CompanySchema } from '../validators/CompanySchema';
import { APIThunkResult, PagedResponse, Company, PaginationFilter, APIResult } from '../Types';
import {
  setCompanies,
  setCompanyOptions,
  setCompany,
  removeCompany
} from '../Store/contexts/company/actions';
import { initialState } from '../Store/contexts/company/reducers';
import { setNotification } from '../Store/contexts/notification/actions';
import { setUser } from '../Store/contexts/user/actions';

export type LoadCompanies = (
  filter: PaginationFilter
) => Promise<APIResult<PagedResponse<Company>>>;
export function loadCompanies(filter: PaginationFilter): APIThunkResult<PagedResponse<Company>> {
  return apiRequest<PagedResponse<Company>>(async dispatch => {
    const query = objectToQueryString(filter);
    const results = await apiFetch<PagedResponse<Company>>({
      method: 'GET',
      url: `${server}/companies/paged?${query}`
    });
    dispatch(setCompanies(results));
    return results;
  });
}

export type LoadCompany = (companyId: number) => Promise<APIResult<Company>>;
export function loadCompany(companyId: number): APIThunkResult<Company> {
  return apiRequest<Company>(async dispatch => {
    const results = await apiFetch<Company>({
      method: 'GET',
      url: `${server}/companies/${companyId}`
    });
    dispatch(setCompany(results));
    return results;
  });
}

export type LoadCompanyOptions = (eventId: number) => Promise<APIResult<Company[]>>;
export function loadCompanyOptions(eventId: number): APIThunkResult<Company[]> {
  return apiRequest<Company[]>(async dispatch => {
    const companyOptions = await apiFetch<Company[]>({
      method: 'GET',
      url: `${server}/events/registered-companies/${eventId}`
    });

    dispatch(setCompanyOptions(companyOptions));
    return companyOptions;
  });
}

export type CreateCompany = (
  data: CompanySchema,
  publicSignup?: boolean
) => Promise<APIResult<Company>>;
export function createCompany(data: CompanySchema, publicSignup = false): APIThunkResult<Company> {
  return apiRequest<Company>(async dispatch => {
    try {
      const newCompanyData = {
        ...data,
        contact: {
          ...data.contact,
          password: publicSignup ? data.contact.password : generateTempAdminPassword()
        },
        mustUpdatePassword: !publicSignup
      };

      const newCompany = await apiFetch<Company>({
        method: 'POST',
        url: `${server}/companies/create`,
        body: newCompanyData
      });
      if (!publicSignup) {
        dispatch(loadCompanies(initialState.filter));
        dispatch(setNotification({ message: 'Company Created Successfully!' }));
      } else {
        await Backend.signIn(
          newCompanyData.contact.email.toLowerCase(),
          newCompanyData.contact.password,
          false
        );
        const newAdmin = newCompany.admins.find(
          a => a.user.email === newCompanyData.contact.email.toLowerCase()
        );
        dispatch(setUser(newAdmin.user));
      }
      return newCompany;
    } catch (err) {
      dispatch(
        setNotification({
          message: err?.message || 'Could not create company.',
          variant: 'danger',
          duration: 5
        })
      );
      throw err;
    }
  });
}
export type EditCompany = (
  companyId: number,
  data: CompanySchema,
  newContact: boolean // set to true to generate a new password + send email to given contact
) => Promise<APIResult<Company>>;
export function editCompany(
  companyId: number,
  data: CompanySchema,
  newContact: boolean
): APIThunkResult<Company> {
  return apiRequest<Company>(async dispatch => {
    try {
      const newCompanyData = {
        ...data,
        contact: {
          ...data.contact,
          password: ''
        }
      };
      if (newContact) newCompanyData.contact.password = generateTempAdminPassword();
      const newCompany = await apiFetch<Company>({
        method: 'PUT',
        url: `${server}/companies/edit/${companyId}`,
        body: newCompanyData
      });

      dispatch(loadCompanies(initialState.filter));
      dispatch(removeCompany());
      dispatch(setNotification({ message: 'Company Edited Successfully!' }));

      return newCompany;
    } catch (err) {
      dispatch(
        setNotification({
          message: err?.message || 'Could not edit company.',
          variant: 'danger',
          duration: 5
        })
      );
      throw err;
    }
  });
}

export type DeleteCompany = (companyId: number) => Promise<APIResult<{ companyId: number }>>;
export function deleteCompany(companyId: number): APIThunkResult<{ companyId: number }> {
  return apiRequest<{ companyId: number }>(async dispatch => {
    try {
      const results = await apiFetch<{ companyId: number }>({
        method: 'DELETE',
        url: `${server}/companies/delete/${companyId}`
      });

      dispatch(loadCompanies(initialState.filter));
      dispatch(setNotification({ message: 'Company Deleted Successfully!' }));

      return results;
    } catch (err) {
      dispatch(
        setNotification({
          message: err?.message || 'Could not delete company.',
          variant: 'danger',
          duration: 5
        })
      );
    }
  });
}
