import styled from 'styled-components/macro';

interface HeaderProps {
  margin?: string;
}

//  Wraps Team Name and Team Details
export const DetailsContainer = styled.div`
  width: 100%;
  margin-bottom: 4.5rem;
  max-width: 575px;
  @media (min-width: ${({ theme }) => theme.device.laptop}px) {
    width: 60%;
  }
  @media (min-width: ${({ theme }) => theme.device.laptopL}px) {
    width: 55%;
  }
`;

//  Team Details Header
export const Heading = styled.div<HeaderProps>`
  font-size: ${({ theme }) => theme.fonts.subHeading / 1.4}rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.primary.dark};
  font-style: italic;
  margin: ${({ margin }) => (margin ? margin : '0 0 1rem 0')};
  display: block;
`;

export const DetailsRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 0.5rem;
  justify-content: space-between;
`;

export const Details = styled.div`
  display: block;
`;

export const TeamDataHeading = styled.div`
  font-size: clamp(0.9rem, 1vw, 1.2rem);
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.primary.light};
  letter-spacing: 0.1rem;
`;

export const TeamDataValue = styled.div`
  font-size: clamp(1.3rem, 1vw, 1.7rem);
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.primary.dark};
  margin: 0.7rem 0 0;
  letter-spacing: 0.02rem;
`;
