import {
  SET_PARTICIPANTS_FILTER,
  SET_PARTICIPANTS,
  SET_PARTICIPANT,
  SET_PARTICIPANT_TEAM,
  SET_PARTICIPANT_DEMOGRAPHICS,
  SET_PAGED_PARTICIPANTS,
  SET_PAGED_PARTICIPANTS_WITHOUT_TEAM,
  ParticipantActionTypes,
  ParticipantsState,
  SET_PARTICIPANTS_WITHOUT_TEAM,
  SET_DELETING_PARTICIPANT,
  SET_PENDING_PARTICIPANT,
  SET_PAGED_PARTICIPANTS_NOT_REGISTERED
} from './types';
import { ParticipantListView } from '../../../Types';

export const initialState: ParticipantsState = {
  pagedParticipants: {
    results: [],
    count: 0
  },
  pagedParticipantsWithoutTeam: {
    results: [],
    count: 0
  },
  pagedParticipantsNotRegistered: {
    results: [],
    count: 0
  },
  participants: [] as ParticipantListView[],
  participantsWithoutTeam: [],
  pendingParticipant: {
    firstName: '',
    lastName: '',
    email: '',
    teamId: null
  },
  filter: {
    keyword: '',
    companyId: null,
    eventId: null,
    page: 1,
    rowsPerPage: 30,
    orderColumn: 'teamId',
    orderDirection: 'ASC'
  },
  participant: {
    teamId: null,
    eventId: null,
    participantId: null,
    fullName: '',
    profileImage: '',
    companyId: null,
    companyName: '',
    email: '',
    firstName: '',
    lastName: '',
    joinedStatus: false,
    teamCaptain: false,
    teamName: '',
    userActive: false,
    sdaExemption: false
  },
  participantTeam: {
    active: false,
    name: '',
    registrations: [],
    pendingRegistrations: [],
    activities: [],
    approved: false,
    companyId: null,
    eventId: null,
    teamId: null
  },
  participantDemographics: [],
  deletingParticipant: false
};

const participantReducer = (
  state: ParticipantsState = initialState,
  action: ParticipantActionTypes
): ParticipantsState => {
  switch (action.type) {
    case SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.participants
      };
    case SET_DELETING_PARTICIPANT:
      return {
        ...state,
        deletingParticipant: action.deletingParticipant
      };
    case SET_PARTICIPANTS_WITHOUT_TEAM:
      return {
        ...state,
        participantsWithoutTeam: action.participantsWithoutTeam
      };
    case SET_PAGED_PARTICIPANTS:
      return {
        ...state,
        pagedParticipants: action.pagedParticipants
      };
    case SET_PARTICIPANT:
      return {
        ...state,
        participant: action.participant
      };
    case SET_PENDING_PARTICIPANT:
      return {
        ...state,
        pendingParticipant: action.pendingParticipant
      };
    case SET_PARTICIPANT_TEAM:
      return {
        ...state,
        participantTeam: action.participantTeam
      };
    case SET_PARTICIPANT_DEMOGRAPHICS:
      return {
        ...state,
        participantDemographics: action.participantDemographics
      };
    case SET_PAGED_PARTICIPANTS_WITHOUT_TEAM:
      return {
        ...state,
        pagedParticipantsWithoutTeam: action.pagedParticipantsWithoutTeam
      };
    case SET_PAGED_PARTICIPANTS_NOT_REGISTERED:
      return {
        ...state,
        pagedParticipantsNotRegistered: action.pagedParticipantsNotRegistered
      };
    case SET_PARTICIPANTS_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    default:
      return state;
  }
};

export default participantReducer;
