import * as yup from 'yup';

const addOwnTeamMateSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .email()
    .required()
});

export type AddOwnTeamMateSchema = yup.InferType<typeof addOwnTeamMateSchema>;

export default addOwnTeamMateSchema;
