import styled, { keyframes } from 'styled-components/macro';
import { Theme, theme } from '../../../../theme';

interface TabProps {
  elevation?: 1 | 2 | 3 | 4 | 5;
  active: boolean;
}

const getBoxShadow = (elevation: number, { shadows, shadowsInvert }: Theme): string | undefined => {
  switch (elevation) {
    case 1:
      return `${shadowsInvert.lighter}, ${shadows.lighter}`;
    case 2:
      return `${shadowsInvert.light}, ${shadows.light}`;
    case 3:
      return `${shadowsInvert.medium}, ${shadows.medium}`;
    case 4:
      return `${shadowsInvert.heavy}, ${shadows.heavy}`;
    case 5:
      return `${shadowsInvert.heavier}, ${shadows.heavier}`;
  }
};

export const popup = keyframes`
  from {
    top: 80px
  }

  to {
    top: 0
  }
`;

export const TabContainer = styled.div<TabProps>`
  background: white;
  border-radius: 1.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: ${({ elevation = 1, theme }) => getBoxShadow(elevation, theme)};
  color: ${props => (props.active ? theme.primary.dark : theme.backgrounds.grey)};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.4rem;
  font-style: oblique;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-right: 0.5rem;
  padding: 1.5rem;
  position: relative;
  top: 80px;
  white-space: nowrap;
  z-index: ${({ active }) => (active ? 100 : 0)};
  transition: all 0.1s linear;

  &:hover {
    margin-top: -5px;
  }

  @media (max-width: ${({ theme }) => theme.device.laptopL}px) {
    font-size: 1.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: 1rem;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &:nth-child(2n) {
    animation: ${popup} 0.8s cubic-bezier(0.4, 0.01, 0, 1.38) 0.3s forwards;
  }

  &:nth-child(3n) {
    animation: ${popup} 0.8s cubic-bezier(0.4, 0.01, 0, 1.38) 0.5s forwards;
  }

  &:nth-child(4n) {
    animation: ${popup} 0.8s cubic-bezier(0.4, 0.01, 0, 1.38) 0.7s forwards;
  }

  &:nth-child(5n) {
    animation: ${popup} 0.8s cubic-bezier(0.4, 0.01, 0, 1.38) 0.9s forwards;
  }

  &:nth-child(6n) {
    animation: ${popup} 0.8s cubic-bezier(0.4, 0.01, 0, 1.38) 1s forwards;
  }

  &:first-child {
    top: 0;
  }
`;

export const StandAloneTabContainer = styled.div<TabProps>`
  background: white;
  border-radius: 1.5rem;
  box-shadow: ${({ elevation = 1, theme }) => getBoxShadow(elevation, theme)};
  color: ${props => (props.active ? theme.primary.dark : theme.backgrounds.grey)};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.4rem;
  font-style: oblique;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: 0 1rem 1rem 0;
  padding: 1.5rem;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.device.laptopL}px) {
    font-size: 1.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: 1rem;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
