import moment from 'moment';
import { server } from '../../CONSTANTS';
import { APIThunkResult, ReportType } from '../Types';
import { apiRequest, apiFetch } from './Utils';
import { setNotification } from '../Store/contexts/notification/actions';
import { setReportTypes } from '../Store/contexts/report/actions';
import { CreateReportSchema } from '../../pages/Reports/validators/createReportSchema';

const REPORTS_URL = `${server}/reports`;

export function loadReportOptions(): APIThunkResult<ReportType[]> {
  return apiRequest<ReportType[]>(async dispatch => {
    const reportTypes = await apiFetch<ReportType[]>({
      method: 'GET',
      url: `${server}/reports/options`
    });

    dispatch(setReportTypes(reportTypes));
    return reportTypes;
  });
}

export function loadReport(data: CreateReportSchema): APIThunkResult<any[]> {
  return apiRequest<any[]>(async dispatch => {
    const utcOffset = moment.tz(moment(), data.timezone).utcOffset();
    const startDate = moment(data.startDate)
      .valueOf()
      .toString();
    const endDate = moment(data.endDate)
      .valueOf()
      .toString();
    try {
      const response = await apiFetch<any[]>({
        method: 'POST',
        url: `${REPORTS_URL}/`,
        body: {
          ...data,
          utcOffset,
          startDate,
          endDate
        }
      });
      return response;
    } catch (error) {
      dispatch(
        setNotification({ message: 'Something went wrong, try again later', variant: 'danger' })
      );
      throw error;
    }
  });
}
