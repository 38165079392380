import {
  PagedResponse,
  PendingParticipant,
  ParticipantListView,
  Team,
  UserDemographic,
  ParticipantFilter
} from '../../../Types';

export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';
export const SET_DELETING_PARTICIPANT = 'SET_DELETING_PARTICIPANT';
export const SET_PARTICIPANTS_WITHOUT_TEAM = 'SET_PARTICIPANTS_WITHOUT_TEAM';
export const SET_PARTICIPANT = 'SET_PARTICIPANT';
export const SET_PENDING_PARTICIPANT = 'SET_PENDING_PARTICIPANT';
export const SET_PARTICIPANT_TEAM = 'SET_PARTICIPANT_TEAM';
export const SET_PARTICIPANT_DEMOGRAPHICS = 'SET_PARTICIPANT_DEMOGRAPHICS';
export const SET_PAGED_PARTICIPANTS = 'SET_PAGED_PARTICIPANTS';
export const SET_PAGED_PARTICIPANTS_WITHOUT_TEAM = 'SET_PAGED_PARTICIPANTS_WITHOUT_TEAM';
export const SET_PAGED_PARTICIPANTS_NOT_REGISTERED = 'SET_PAGED_PARTICIPANTS_NOT_REGISTERED';
export const SET_PARTICIPANTS_FILTER = 'SET_PARTICIPANTS_FILTER';

export interface ParticipantsState {
  pagedParticipants: PagedResponse<ParticipantListView>;
  pendingParticipant: PendingParticipant;
  pagedParticipantsWithoutTeam: PagedResponse<ParticipantListView>;
  pagedParticipantsNotRegistered: PagedResponse<ParticipantListView>;
  participants: ParticipantListView[];
  participantsWithoutTeam: ParticipantListView[];
  participant: ParticipantListView;
  participantTeam: Team;
  participantDemographics: UserDemographic[];
  filter: ParticipantFilter;
  deletingParticipant: boolean;
}

export interface SetParticipantsAction {
  type: typeof SET_PARTICIPANTS;
  participants: ParticipantListView[];
}

export interface SetDeletingParticipantAction {
  type: typeof SET_DELETING_PARTICIPANT;
  deletingParticipant: boolean;
}

export interface SetParticipantsWithoutTeamAction {
  type: typeof SET_PARTICIPANTS_WITHOUT_TEAM;
  participantsWithoutTeam: ParticipantListView[];
}

export interface SetParticipantAction {
  type: typeof SET_PARTICIPANT;
  participant: ParticipantListView;
}

export interface SetPendingParticipantAction {
  type: typeof SET_PENDING_PARTICIPANT;
  pendingParticipant: PendingParticipant;
}

export interface SetParticipantTeamAction {
  type: typeof SET_PARTICIPANT_TEAM;
  participantTeam: Team;
}

export interface SetParticipantDemographicsAction {
  type: typeof SET_PARTICIPANT_DEMOGRAPHICS;
  participantDemographics: UserDemographic[];
}

export interface SetPagedParticipantsAction {
  type: typeof SET_PAGED_PARTICIPANTS;
  pagedParticipants: PagedResponse<ParticipantListView>;
}

export interface SetPagedParticipantsWithoutTeamAction {
  type: typeof SET_PAGED_PARTICIPANTS_WITHOUT_TEAM;
  pagedParticipantsWithoutTeam: PagedResponse<ParticipantListView>;
}

export interface SetPagedParticipantsNotRegisteredAction {
  type: typeof SET_PAGED_PARTICIPANTS_NOT_REGISTERED;
  pagedParticipantsNotRegistered: PagedResponse<ParticipantListView>;
}

export interface SetParticipantsFilter {
  type: typeof SET_PARTICIPANTS_FILTER;
  filter: ParticipantFilter;
}

export type ParticipantActionTypes =
  | SetPagedParticipantsAction
  | SetParticipantsWithoutTeamAction
  | SetParticipantAction
  | SetPendingParticipantAction
  | SetParticipantTeamAction
  | SetParticipantDemographicsAction
  | SetPagedParticipantsWithoutTeamAction
  | SetPagedParticipantsNotRegisteredAction
  | SetDeletingParticipantAction
  | SetParticipantsAction
  | SetParticipantsFilter;
