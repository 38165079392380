import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import { Group, Heading, Body } from '../Styles';
import { Input } from '../../../../../components/UI';
import theme from '../../../../../theme';
import { FormikFormComponent } from '../../../../../lib/Types';
import { EventSchema } from '../../../validators/eventSchema';

const CostPerParticipant: FC<FormikFormComponent<EventSchema>> = ({
  values,
  errors,
  disabled = false,
  handleChange
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (values.costPerParticipant) setInputValue((values.costPerParticipant as unknown) as string);
  }, [values.costPerParticipant]);

  return (
    <Group>
      <Heading>Cost Per Participant</Heading>
      <Body>
        This will change the default cost per participant for all participants in this event.
      </Body>
      <Input
        required
        id="costPerParticipant"
        name="costPerParticipant"
        value={inputValue}
        error={errors.costPerParticipant}
        label="Cost Per Participant ($ AUD)"
        type="currency"
        margin={`0px 0px ${theme.padding * 1.5}px 0px`}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
          setInputValue(parseFloat(e.target.value).toFixed(2));
          handleChange(e);
        }}
        disabled={disabled}
      />
    </Group>
  );
};

export default CostPerParticipant;
