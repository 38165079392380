import React, { FC } from 'react';
import { ParticipantListView, FormikForm } from '../../../../../lib/Types';
import { ReplaceSchema } from '../../../validators/replaceSchema';
import ReplaceIndividualForm from '../ReplaceForm/ReplaceIndividualForm';
import LoadingIndicator from '../../../../../components/UI/LoadingIndicator';

interface ReplaceParticipantProps extends FormikForm<ReplaceSchema> {
  participant: ParticipantListView;
  individualParticipants: ParticipantListView[];
  loading: boolean;
  handleDropdownChange: (name: string, value: any) => void;
  onCancel: () => void;
}

const ReplaceParticipant: FC<ReplaceParticipantProps> = ({ loading, ...props }) => (
  <>{loading ? <LoadingIndicator /> : <ReplaceIndividualForm {...props} />}</>
);

export default ReplaceParticipant;
