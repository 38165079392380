import styled from 'styled-components/macro';
import ProfileImage from '../ProfileImage';

interface ProfileWidget {
  mobileNav?: boolean;
}

export const SubHeading = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-style: italic;
  font-size: 1.3rem;
  line-height: calc(${({ theme }) => theme.fontScale.sm} * 1.4);
  color: ${({ theme }) => theme.primary.dark};
`;

export const UserName = styled(SubHeading)`
  white-space: nowrap;
`;

export const UserInfoWrapper = styled.div<ProfileWidget>`
  margin-left: ${({ theme }) => theme.padding}px;
  text-align: left;
`;

export const WidgetProfileImage = styled(ProfileImage)`
  height: 50px;
  width: 50px;
  flex-shrink: 0;
`;

export const ProfileContainer = styled.div<ProfileWidget>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-right: ${({ theme }) => theme.pad.md}%;
  margin-bottom: ${props => (props.mobileNav ? '1.5rem' : '0')};
`;
