import * as yup from 'yup';
import moment from 'moment-timezone';

const eventSchema = yup.object({
  name: yup
    .string()
    .required()
    .default(''),
  activitiesToInclude: yup
    .array()
    .of(yup.number())
    .min(1, 'You must select at least one activity')
    .default([])
    .required(),
  timezone: yup.string().required('Event timezone is required.'),
  registrationsStartDate: yup
    .string()
    .when('registrationsEndDate', (registrationsEndDate: string) =>
      yup
        .string()
        .test(
          'registrationsStartDate',
          'Registrations start date must be before registrations end date.',
          value => {
            return (
              moment(value, 'x')
                .add(-1, 'day')
                .valueOf() < moment(registrationsEndDate, 'x').valueOf()
            );
          }
        )
    )
    .required('Registrations start date is required.'),
  registrationsStartDateTime: yup.string().required('Registrations start time is required.'),
  registrationsEndDate: yup
    .string()
    .when('endDate', (endDate: string) =>
      yup
        .string()
        .test(
          'registrationsEndDate',
          'Registrations end date must not overlap with event end date.',
          value => {
            return (
              moment(value, 'x')
                .add(-1, 'day')
                .valueOf() < moment(endDate, 'x').valueOf()
            );
          }
        )
    )
    .required('Registrations end date is required.'),
  registrationsEndDateTime: yup.string().required('Registrations end time is required.'),
  startDate: yup.string().required('Event start date is required.'),
  startDateTime: yup.string().required('Event start time is required.'),
  endDate: yup.string().when('startDate', (start: string) =>
    yup
      .string()
      .test(
        'endDate',
        'Event end date must be after start date.',
        value =>
          moment(value, 'x')
            .add(1, 'day')
            .valueOf() > moment(start, 'x').valueOf()
      )
      .required('Event end date is required.')
  ),
  endDateTime: yup.string().required('Start end time is required.'),
  gracePeriod: yup
    .number()
    .required()
    .default(7),
  teamSize: yup
    .number()
    .min(2, 'Team size must be at least 2')
    .required()
    .default(5),
  costPerParticipant: yup
    .number()
    .required('Cost is required')
    .test('costPerParticipant', 'Must match $00.00 format', value => {
      const regex = /^\d+(\.\d{1,2})?$/;
      return value && regex.test(value);
    }),
  active: yup
    .boolean()
    .default(true)
    .required(),
  stepApproval: yup
    .boolean()
    .default(true)
    .required(),
  stepApprovalThreshold: yup
    .number()
    .required()
    .default(0),
  averageSteps: yup
    .number()
    .required()
    .default(0)
});

export type EventSchema = yup.InferType<typeof eventSchema>;

export default eventSchema;
