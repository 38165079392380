import React, { FC, MouseEvent, useState } from 'react';

import theme from '../../../theme';
import { dropdownArrow } from '../../../assets/icons/index';

import {
  DropdownContainer,
  DropdownOption,
  DropdownOptionsWrapper,
  DropdownContainerOpen,
  DropdownParentOption,
  DropdownHd,
  DropdownMenuHd,
  DropdownArr
} from './Styles';

interface Dropdown {
  defaultLabel?: string;
  labelColor?: string;
  data?: any;
  dataType?: string;
  onOptionClick?: (dataType: string, props: any) => void;
  listLoadFunction?: (companyId: number) => void;
}

const Dropdown: FC<Dropdown> = ({
  defaultLabel = 'Select an Option',
  labelColor = theme.primary.dark,
  data = {},
  dataType = 'event',
  onOptionClick = (companyId: string, optionType: any) => null,
  listLoadFunction = () => null
}) => {
  const [DDOpen, setDDOpen] = useState(false);
  const [DDSelected, setDDSelected] = useState<string | null>(defaultLabel);

  const handleOptionSelect = (e: React.MouseEvent | any, data: any): void => {
    const optionVal: string | null = e.currentTarget.textContent;
    const id: string = e.currentTarget.attributes['data-id'].value;

    let optionType: string | null = null;
    if (id !== 'all') {
      optionType = e.currentTarget.attributes['data-optiontype'].value;
      listLoadFunction(parseInt(id, 10));
    } else {
      listLoadFunction(null);
    }
    setDDSelected(optionVal);
    onOptionClick(dataType, { optionType, id, data });
  };

  const generateOptions = (): JSX.Element[] => {
    let selectedData;
    if (dataType === 'nav') {
      selectedData = data.nav;
    } else {
      selectedData = data;
    }

    return selectedData.map((item: any, index: number) => {
      let id;
      let childOptions;

      if (dataType !== 'nav') {
        id = dataType === 'event' ? item.eventId : item.groupId;
      } else {
        id = 'nav';
      }

      if (dataType === 'company') {
        return (
          <DropdownOption
            data-dropdowntype={dataType}
            data-optiontype="parent"
            data-id={item.companyId}
            onClick={(e: MouseEvent) => handleOptionSelect(e, item)}
            key={`${index}-${item.name}`}
          >
            {item.name}
          </DropdownOption>
        );
      }

      return (
        <React.Fragment key={`${id}-${index}-${item.name}`}>
          <DropdownOption
            data-dropdowntype={dataType}
            data-optiontype="parent"
            data-id={id}
            onClick={(e: MouseEvent) => handleOptionSelect(e, item)}
          >
            {item.name}
          </DropdownOption>
          {dataType === 'company' ? childOptions : null}
        </React.Fragment>
      );
    });
  };

  return DDOpen ? (
    <DropdownContainerOpen onClick={() => setDDOpen(!DDOpen)}>
      <DropdownMenuHd>
        <DropdownHd color={labelColor}>{DDSelected}</DropdownHd>
        <DropdownArr alt="dropdown arrow" src={dropdownArrow} />
      </DropdownMenuHd>
      <DropdownOptionsWrapper>
        {dataType !== 'nav' && (
          <DropdownParentOption
            data-id={`all`}
            onClick={(e: MouseEvent) => {
              listLoadFunction(null);
              handleOptionSelect(e, null);
            }}
          >
            Show All
          </DropdownParentOption>
        )}
        {generateOptions()}
      </DropdownOptionsWrapper>
    </DropdownContainerOpen>
  ) : (
    <DropdownContainer onClick={() => setDDOpen(!DDOpen)}>
      <DropdownMenuHd>
        <DropdownHd color={labelColor}>{DDSelected}</DropdownHd>
        <DropdownArr alt="dropdown arrow" src={dropdownArrow} />
      </DropdownMenuHd>
    </DropdownContainer>
  );
};
export default Dropdown;
