import React, { FC, useEffect } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import validationSchema, { TeamSchema } from '../../validators/teamSchema';
import { ApplicationState } from '../../../../lib/Store';
import { editTeam, approveTeam, getTeam, removeTeamRegistration } from '../../../../lib/Api/Team';
import {
  loadParticipant,
  loadParticipantsWithoutTeam,
  deletePendingParticipant
} from '../../../../lib/Api/Participants';
import { setTeam, setAddingTeamMember } from '../../../../lib/Store/contexts/team/actions';
import {
  Team,
  ErrorResponse,
  ParticipantListView,
  PendingParticipant
} from '../../../../lib/Types';
import EditTeam from './EditTeam';
import { resendInvitationEmailInternal } from '../../../../lib/Api/Team';
import {
  setParticipant,
  setDeletingParticipant,
  setPendingParticipant
} from '../../../..//lib/Store/contexts/participants/actions';
import { initialState } from '../../../../lib/Store/contexts/participants/reducers';

export interface EditTeamContainerProps {
  team: Team;
  loading: boolean;
  participant: ParticipantListView;
  pendingParticipant: PendingParticipant;
  individualParticipants: ParticipantListView[];
  deletingParticipant: boolean;
  adding: boolean;
  editTeam: (teamId: number, data: TeamSchema) => any;
  getTeam: (teamId: number) => any;
  setTeam: (team: Team) => any;
  approveTeam: (teamId: number) => any;
  deletePendingParticipant: (teamId: number, email: string) => any;
  resendInvitationEmail: (email: string) => void;
  loadParticipant: (participantId: string) => void;
  loadPendingParticipant: (data: PendingParticipant) => void;
  loadIndividualParticipants: (eventId: number, companyId?: number) => void;
  removeRegistration: (team: Team, userId: string) => void;
  setAddingTeamMember: (addingTeamMember: boolean) => any;
  setDeleting: (deletingParticipant: boolean) => any;
  setParticipant: (participant: ParticipantListView) => any;
  cancel: () => void;
}

const formikEnhancer = withFormik<EditTeamContainerProps, TeamSchema>({
  validationSchema,
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: ({ team }) => ({
    //team values
    teamCaptainId: team.registrations.filter(data => data.teamCaptain === true)[0]?.userId || '',
    name: team?.name || ''
  }),
  handleSubmit: async (values: TeamSchema, { props, setErrors }) => {
    const { error }: { error: ErrorResponse } = await props.editTeam(props.team.teamId, values);

    if (error.data.field) setErrors({ [error.data.field]: error.message });
  }
});

const EditTeamContainer: FC<EditTeamContainerProps & FormikProps<TeamSchema>> = ({
  setFieldValue,
  cancel,
  loadIndividualParticipants,
  team,
  participant,
  ...props
}) => {
  useEffect(() => {
    loadIndividualParticipants(team.eventId, team.companyId);
  }, [participant]);

  const changeCustom = (name: string, data: any) => setFieldValue(name, data);
  const cancelReplace = () => {
    props.setParticipant(initialState.participant);
    props.setAddingTeamMember(false);
  };

  const handleApprove = async (teamId: number) => {
    await props.submitForm();
    await props.approveTeam(teamId);
  };

  return (
    <EditTeam
      {...props}
      approveTeam={handleApprove}
      team={team}
      participant={participant}
      handleChangeCustom={changeCustom}
      onCancel={cancel}
      onReplaceCancel={cancelReplace}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  team: state.teamsState.team,
  deletingParticipant: state.participantsState.deletingParticipant,
  adding: state.teamsState.addingTeamMember,
  participant: state.participantsState.participant,
  pendingParticipant: state.participantsState.pendingParticipant,
  individualParticipants: state.participantsState.participantsWithoutTeam,
  loading: state.loadingState.apiCallsInProgress > 0
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editTeam: (teamId: number, data: TeamSchema) => editTeam(teamId, data),
      deletePendingParticipant: (teamId: number, email: string) =>
        deletePendingParticipant(teamId, email),
      getTeam: (teamId: number) => getTeam(teamId),
      setTeam: (team: Team) => setTeam(team),
      setParticipant: (participant: ParticipantListView) => setParticipant(participant),
      setDeleting: (deletingParticipant: boolean) => setDeletingParticipant(deletingParticipant),
      setAddingTeamMember: (addingTeamMember: boolean) => setAddingTeamMember(addingTeamMember),
      approveTeam: (teamId: number) => approveTeam(teamId),
      removeRegistration: (team: Team, userId: string) => removeTeamRegistration(team, userId),
      resendInvitationEmail: (email: string) => resendInvitationEmailInternal(email),
      loadParticipant: (participantId: string) => loadParticipant(participantId),
      loadPendingParticipant: (data: PendingParticipant) => setPendingParticipant(data),
      loadIndividualParticipants: (eventId: number, companyId?: number) =>
        loadParticipantsWithoutTeam(eventId, companyId),
      cancel: () => setTeam({} as Team)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(formikEnhancer(EditTeamContainer));
