import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Button from '../Button';

import { yogaGraphic } from '../../../assets/icons';
import { DashboardPanelWrapper, Header } from '../DashboardTeamConnectionPoints/Styles';
import {
  Container,
  MascotIcon,
  ContentWrapper,
  CountWrapper,
  Count
} from '../DashboardMiniChallengesCount/Styles';

import { updatePostsFilter } from '../../../lib/Store/contexts/posts/actions';
import { initialPostsStateFilter } from '../../../lib/Store/contexts/posts/reducers';
import { APIThunkDispatch } from '../../../lib/Types';
import { ApplicationState } from '../../../lib/Store';

const DashboardDailyActivitiesCount: FC = () => {
  const dispatch: APIThunkDispatch = useDispatch();
  const history = useHistory();
  const {
    participantDashboardState: { dashBoardData }
  } = useSelector((state: ApplicationState) => state);

  const completed =
    dashBoardData &&
    dashBoardData.dailyActivities.filter(challenge => challenge.participated).length;
  const toDo = dashBoardData && dashBoardData.dailyActivities.length - completed;

  // Set filter then navigate
  const handleViewAllDailyActivities = () => {
    dispatch(
      updatePostsFilter({
        ...initialPostsStateFilter,
        primaryPostTypeFilter: 'DAILY_ACTIVITIES',
        secondaryPostTypeFilter: 'ALL'
      })
    );
    history.push('/WaterCooler');
  };

  return (
    <DashboardPanelWrapper as={Container}>
      <ContentWrapper>
        <Header>Daily Activities</Header>
        <CountWrapper>
          <Count completed>{`Completed (${completed})`}</Count>
          <Count>{`To Do (${toDo})`}</Count>
        </CountWrapper>
        <Button
          variant={'purple'}
          width={'100%'}
          margin={'2rem 0'}
          onClick={handleViewAllDailyActivities}
        >
          View All Daily Activities
        </Button>
      </ContentWrapper>
      <MascotIcon src={yogaGraphic} />
    </DashboardPanelWrapper>
  );
};

export default DashboardDailyActivitiesCount;
