import React, { FC } from 'react';

import { DarkBlueHeaderL } from '../../../../Themes';
import ActionButton from '../../../../components/UI/PaginatedTable/TableActions/ActionButton';

import { cogs } from '../../../../assets/icons';
import { Company, ParticipantListView } from '../../../../lib/Types';

import { Wrapper, Container, BrightBlueHeader, InnerCol, GenTeamsText } from './Styles';
import { theme } from '../../../../theme';

export interface TeamGenerationWidgetProps {
  participantsWithoutTeams: ParticipantListView[];
  companyOptions?: Company[];
  companyId?: number;
  selectedCompany?: number;
  generateTeam: (companyId: number, data: any) => any;
  handleSelect?: (val: number) => void;
}

const TeamGenerationWidget: FC<TeamGenerationWidgetProps> = ({
  participantsWithoutTeams,
  companyId,
  generateTeam,
  selectedCompany
}) => {
  const totalParticipants = selectedCompany
    ? participantsWithoutTeams.filter(participant => participant.companyId === selectedCompany)
        .length
    : participantsWithoutTeams.length;

  const handleSubmit = () => {
    if (companyId) generateTeam(companyId, {});
    else {
      if (!selectedCompany) return;
      else generateTeam(selectedCompany, {});
    }
  };

  return (
    <Wrapper>
      <Container>
        <InnerCol>
          <BrightBlueHeader>Participants without teams</BrightBlueHeader>
          <DarkBlueHeaderL fontStyle={'inherit'}>{totalParticipants}</DarkBlueHeaderL>
        </InnerCol>
        <InnerCol>
          <GenTeamsText textAlign="center">Allocate these users to new teams</GenTeamsText>
          <ActionButton
            onClick={handleSubmit}
            icon={cogs}
            backgroundColour={theme.primary.paleBlue}
            darkText
          >
            Generate teams
          </ActionButton>
        </InnerCol>
      </Container>
    </Wrapper>
  );
};

export default TeamGenerationWidget;
