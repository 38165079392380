import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';

import theme from '../../../theme';
import { signOut } from '../../../lib/Api/User';
import { isAfter } from '../../../lib/TimeUtils';
import useWindowSize from '../../../hooks/useWindowSize';

import MenuOptions from './MenuOptions';
import AnimatedLogo from '../AnimatedLogo';
import UserInfo from '../UserInfo';

import { menuLogoMob } from '../../../assets/img';
import { collapseArrow, logoSmall, menuGraphic } from '../../../assets/icons/index';
import Logo from '../Logo';
import {
  BurgerIcon,
  CollapseArrow,
  CollapseArrowActive,
  CollapsedSideNav,
  CollapsedSideBarWrapper,
  SideNav,
  LogoWrapper,
  LogoWrapperCollapsed,
  MobSideNav,
  MobLogo,
  NavIcon,
  NavIconActive,
  NavLink,
  NavLinkActive,
  NavText,
  NavTextActive,
  NavWrapper,
  SideBarMob,
  SideBarMobHead,
  SideBarWrapper,
  Icon
} from './Styles';

import { RoleCode, APIThunkDispatch } from '../../../lib/Types';
import { ApplicationState } from '../../../lib/Store';

interface SideBarProps extends RouteComponentProps {
  code: RoleCode;
}

const SideBar: FC<SideBarProps> = ({ code, history, location }) => {
  const { pathname } = location;
  const dispatch: APIThunkDispatch = useDispatch();
  const {
    companyAdminEventState: { currentEvent: companyEvent },
    userState: { userEvent }
  } = useSelector((state: ApplicationState) => state);
  const [windowWidth] = useWindowSize();

  const [parentRoute, setParentRoute] = useState(pathname);
  const [collapsed, setCollapsed] = useState(false);

  const noCompanyEvent = !companyEvent || isAfter(companyEvent.startDate, moment().valueOf());
  const noUserEvent = !userEvent.eventId || isAfter(userEvent.startDate, moment().valueOf());

  const handleSignOut = () => {
    dispatch(signOut()).then(() => history.push('/Login'));
  };

  const selectLink = React.useCallback(
    (route: { path: string; label: string }) => {
      if (collapsed) setCollapsed(false);
      if (route.path) {
        history.push(route.path);
      } else setParentRoute(route.label); // Open sub-routes
    },
    [setParentRoute, history.push]
  );

  const menuOptions = React.useMemo(() => {
    const options = MenuOptions.list.filter(mo => mo.requiresOneOfRole.some(oor => oor === code));
    if (code === RoleCode.Employee && noUserEvent) {
      return options.filter(
        ({ label }) => label === 'Dashboard' || label === 'Logout' || label === 'Account Settings'
      );
    }
    if (code === RoleCode.CompanyAdmin && noCompanyEvent) {
      return options.filter(({ label }) => label !== 'Posts' && label !== 'Dashboard');
    }
    return options;
  }, [code, companyEvent]);

  function generateLinks() {
    return menuOptions.map((item, index) => {
      // If using one of the many dashboard paths, set the name to '/Dashboard' for logic matching purposes
      const computedPath =
        pathname === '/Dashboard' ||
        pathname === '/TeamDashboard' ||
        pathname === '/EmployeeDashboard'
          ? '/Dashboard'
          : pathname;
      const active =
        item.path === computedPath ||
        item.label == parentRoute || // Match label to open sub routes
        item.paths.some(path => path.path === computedPath); // Match to sub-routes as well
      const Link = active ? NavLinkActive : NavLink;
      const Text = active ? NavTextActive : NavText;
      const Icon = active && !collapsed ? NavIconActive : NavIcon;

      return (
        <Link
          key={`${item.label}${index}`}
          data-activelink={item.label}
          onClick={item.label === 'Logout' ? handleSignOut : () => selectLink(item)}
        >
          <NavWrapper>
            <Icon src={item.icon} />
            {(!collapsed || windowWidth < theme.device.tablet) && <Text>{item.label}</Text>}
          </NavWrapper>
          {!collapsed &&
            active &&
            item.paths.map((path, index) => {
              const subActive = pathname === path.path;
              const SubText = subActive ? NavTextActive : NavText;
              return (
                <NavLinkActive
                  marginTop={index === 0}
                  key={`${path.label}`}
                  onClick={() => selectLink(path)}
                >
                  <SubText>{path.label}</SubText>
                </NavLinkActive>
              );
            })}
        </Link>
      );
    });
  }

  function MobMenuShow() {
    const [toggleState, setToggleState] = useState(false);

    function toggle() {
      setToggleState(toggleState => !toggleState);
    }

    return (
      <>
        <SideBarMobHead>
          <BurgerIcon iconState={toggleState} onClick={toggle} />
          <MobLogo src={menuLogoMob} alt="mob-logo" />
        </SideBarMobHead>
        <MobSideNav hidden={toggleState}>
          {generateLinks()}
          <UserInfo isAdmin={code !== RoleCode.Employee} mobileNav={true} />
        </MobSideNav>
      </>
    );
  }

  return collapsed && windowWidth > theme.device.tablet ? (
    <CollapsedSideBarWrapper>
      <SideBarMob>{MobMenuShow()}</SideBarMob>

      <CollapseArrowActive
        alt="collapse"
        src={collapseArrow}
        width="18px"
        onClick={() => setCollapsed(false)}
      />
      <LogoWrapperCollapsed>
        <Logo size="55%" source={logoSmall} />
      </LogoWrapperCollapsed>
      <CollapsedSideNav>{generateLinks()}</CollapsedSideNav>
    </CollapsedSideBarWrapper>
  ) : (
    <SideBarWrapper>
      <SideBarMob>{MobMenuShow()}</SideBarMob>
      <>
        <CollapseArrow alt="collapse" src={collapseArrow} onClick={() => setCollapsed(true)} />
        <LogoWrapper>
          <AnimatedLogo />
        </LogoWrapper>
        <SideNav>{generateLinks()}</SideNav>
        {code === RoleCode.Employee && <Icon src={menuGraphic} />}
      </>
    </SideBarWrapper>
  );
};

export default withRouter(SideBar);
