import React, { FC } from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../lib/Store';
import { TeamStats } from '../../../lib/Types';
import {
  Header,
  ProfileCell,
  ProfileName,
  ProfilePic,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaders,
  TableRow,
  YourStatsContainer
} from './Styles';

import { pfpPlaceholder } from '../../../assets/img';
import { getToday, getYesterday, getWeek, getAllSteps } from '../../../lib/Utils';
import { ToSIPrefix } from '@cognativ/utilities';

interface YourStatsProps {
  animationDelay?: number;
  loading: boolean;
  userId: string;
  myTeamStats: TeamStats[];
}

const YourStats: FC<YourStatsProps> = ({ animationDelay, loading, myTeamStats, userId }) => {
  if (myTeamStats.length === 0) {
    return (
      <YourStatsContainer delay={animationDelay}>
        <Header>Your Stats will appear here once you earn your first points!</Header>
      </YourStatsContainer>
    );
  }

  const myStatsData = myTeamStats.filter(data => {
    return data.userId === userId;
  });

  return (
    <YourStatsContainer delay={animationDelay}>
      <Header>Your Stats</Header>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Table>
          <TableHeaders>
            <TableHeader />
            <TableHeader dropable={true}>Today</TableHeader>
            <TableHeader dropable={true}>Yesterday</TableHeader>
            <TableHeader dropable={true}>This Week</TableHeader>
            <TableHeader dropable={true}>Total Steps Tracked</TableHeader>
            <TableHeader dropable={false}>Movement Points</TableHeader>
            <TableHeader dropable={false}>Connection Points</TableHeader>
            <TableHeader>Total Points</TableHeader>
          </TableHeaders>
          <TableBody>
            {myStatsData[0] &&
              myStatsData.length < 2 &&
              myStatsData.map((data, index) => (
                <TableRow highlight={index % 2 == 0} key={index}>
                  <TableCell highlight={index % 2 == 0} dropable={false}>
                    <ProfileCell>
                      <ProfilePic image={data.profileImage ? data.profileImage : pfpPlaceholder} />
                      <ProfileName>{data.name}</ProfileName>
                    </ProfileCell>
                  </TableCell>
                  <TableCell
                    none={getToday(data.steps) === 'none'}
                    highlight={index % 2 == 0}
                    dropable={true}
                  >
                    {getToday(data.steps)}
                  </TableCell>
                  <TableCell
                    none={getYesterday(data.steps) === 'none'}
                    highlight={index % 2 == 0}
                    dropable={true}
                  >
                    {getYesterday(data.steps)}
                  </TableCell>
                  <TableCell
                    none={getWeek(data.steps) === 'none'}
                    highlight={index % 2 == 0}
                    dropable={true}
                  >
                    {getWeek(data.steps)}
                  </TableCell>
                  <TableCell
                    none={getAllSteps(data.steps) === 'none'}
                    highlight={index % 2 == 0}
                    dropable={true}
                  >
                    {typeof getAllSteps(data.steps) === 'number'
                      ? ToSIPrefix(getAllSteps(data.steps) as number)
                      : getAllSteps(data.steps)}
                  </TableCell>
                  <TableCell highlight={index % 2 == 0} dropable={false}>
                    {data.movementPoints}
                  </TableCell>
                  <TableCell highlight={index % 2 == 0} dropable={false}>
                    {data.connectionPoints}
                  </TableCell>
                  <TableCell highlight={index % 2 == 0} dropable={false}>
                    {data.connectionPoints + data.movementPoints}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </YourStatsContainer>
  );
};

const mapStateToProps = ({ loadingState, userState, statsState }: ApplicationState) => ({
  loading: loadingState.apiCallsInProgress > 0,
  myTeamStats: statsState.myTeamStats,
  userId: userState.userData.userId
});

export default connect(mapStateToProps)(YourStats);
