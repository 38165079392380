import React, { FC, ChangeEvent } from 'react';

import { check } from '../../../../assets/icons';
import { StyledCheckbox, Label, CheckboxContainer, HiddenCheckbox, Icon } from './Styles';

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: FC<CheckboxProps> = ({ checked, onChange, disabled }) => {
  return (
    <Label>
      <CheckboxContainer>
        <HiddenCheckbox checked={checked} onChange={onChange} disabled={disabled} />
        <StyledCheckbox checked={checked}>
          <Icon src={check} />
        </StyledCheckbox>
      </CheckboxContainer>
    </Label>
  );
};

export default Checkbox;
