import { SyncServiceState, SyncServiceActionTypes, SET_AVAILABLE_SERVICES } from './types';
import { SyncService } from '../../../Types';

const initialState: SyncServiceState = {
  availableServices: []
};

const syncServiceReducer = (
  state: SyncServiceState = initialState,
  action: SyncServiceActionTypes
): SyncServiceState => {
  switch (action.type) {
    case SET_AVAILABLE_SERVICES:
      return {
        ...state,
        availableServices: action.availableServices
      };
    default:
      return state;
  }
};

export default syncServiceReducer;
