import { ActivityTypeState, ActivityTypeActionTypes, SET_ACTIVITY_TYPES } from './types';

const initialState: ActivityTypeState = {
  activityTypes: []
};

const activityTypeReducer = (
  state: ActivityTypeState = initialState,
  action: ActivityTypeActionTypes
): ActivityTypeState => {
  switch (action.type) {
    case SET_ACTIVITY_TYPES:
      return { activityTypes: action.activityTypes };
    default:
      return state;
  }
};

export default activityTypeReducer;
