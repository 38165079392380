import * as yup from 'yup';

const teamSchema = yup.object({
  name: yup.string().required(),
  teamCaptainId: yup.string().nullable(true)
});

export type TeamSchema = yup.InferType<typeof teamSchema>;

export default teamSchema;
