import { apiRequest, apiFetch } from '../Utils';
import { server } from '../../../CONSTANTS';

import { APIThunkResult, APIResult, CompanyAdmin } from '../../Types';

export type GetMyCompanyContact = () => Promise<APIResult<CompanyAdmin>>;
export function getMyCompanyContact(): APIThunkResult<CompanyAdmin> {
  return apiRequest<any>(async () => {
    const data = await apiFetch<CompanyAdmin>({
      method: 'GET',
      url: `${server}/companies/me/company-contact`
    });
    return data;
  });
}
