import moment from 'moment';
import { Post } from '../../../Types';

// Posts Store Utilities

export const uniquePosts = (posts: Post[]) => {
  const postIds = posts.map(({ postId }) => postId);
  const uniqueIds = [...new Set(postIds)];
  const uniquePosts = uniqueIds.map(id => posts.find(p => p.postId === id));
  return uniquePosts;
};

export const isPinned = (post: Post): boolean =>
  post.pinUntil && moment(post.pinUntil).diff(moment()) > 0;

export const comparePosts = (a: Post, b: Post): number => {
  const aPinned = isPinned(a);
  const bPinned = isPinned(b);

  if (aPinned && !bPinned) return -1; // A Pinned, B not
  if (bPinned && !aPinned) return 1; // B Pinned, A not
  if (aPinned && bPinned) return moment(b.createdAt).diff(moment(a.createdAt)); // Both pinned, sort by created data
  if (!aPinned && !bPinned) return moment(b.date).diff(moment(a.date)); // Neither pinned, sort by date

  return 0;
};

export default {
  uniquePosts
};
