import * as yup from 'yup';

const createReportSchema = yup.object({
  eventId: yup.number().required(),
  companyId: yup.number().required(),
  reportTypeId: yup.number().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  timezone: yup.string().required('Report timezone is required.')
});

export type CreateReportSchema = yup.InferType<typeof createReportSchema>;

export default createReportSchema;
