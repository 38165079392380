import styled from 'styled-components/macro';
import { Theme } from '../../../../theme';

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListInputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.padding * 1.5}px;
`;

export const RemoveIcon = styled.img`
  margin-left: ${({ theme }) => theme.padding}px;
  cursor: pointer;
  align-self: flex-start;
  margin-top: 7px;
`;

export const Button = styled.button`
  cursor: pointer;
  margin-top: 1rem;
  padding: 1rem 3rem;
  width: 28rem;
  height: 4rem;
  background: none;
  border: ${({ theme }: { theme: Theme }) => theme.borders.input.focused};
  border-radius: 3rem;
  color: #658cd7;
  font-size: 1.4rem;
  font-style: italic;
`;

export const AddIcon = styled.img`
  float: left;
  margin-top: ${({ theme }) => theme.padding / 3}px;
`;

export const ActionsContainer = styled.div<{ horizontal?: boolean }>`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
`;
