import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  color: white;
  text-align: center;
  display: flex;
  padding: 2rem 2rem 0 2rem;
`;

export const SyncButton = styled.button`
  cursor: pointer;
  font-weight: bold;
  color: #af173d;
  margin-top: 1rem;
  font-size: 1.5rem;
  border: none;
  background: none;
`;

export const SyncContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SyncLabel = styled.span`
  margin: 0 1rem 1rem 0;
`;

export const Column = styled.div`
  padding-top: 1.5rem;
  justify-content: center;
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
`;

export const SyncDeviceButton = styled.button`
  flex: 1;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  margin-right: 1rem;
  transition: all, 0.1s linear;

  &:hover {
    transform: scale(1.05);
  }
`;

export const DeviceImage = styled.img`
  user-select: none;
  width: 83px;
`;

export const SyncDeviceLabelContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const SyncDeviceScrollView = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  // padding to allow for button hover state
  padding: 2px;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;
