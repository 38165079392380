import React, { FC, useState, ReactElement } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps, FieldArray } from 'formik';
import Dropzone from 'react-dropzone';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  updateUserProfile,
  uploadProfilePicture,
  UpdateUserProfile,
  UploadProfilePic
} from '../../../../lib/Api/User';
import Auth from '../../../../lib/Backend/Authentication';
import { deleteMedia, DeleteMedia } from '../../../../lib/Api/Common';
import { capitaliseUserFullName } from '../../../../lib/Utils';
import { MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_LABEL, DEFAULT_PIC } from '../../../../CONSTANTS';

import { Input, SelectDropdown } from '../../../../components/UI';
import LoadingIndicator from '../../../../components/UI/LoadingIndicator';

import {
  BodyText,
  Column,
  Field,
  Form,
  FormBody,
  FormFooter,
  TeamCapNote,
  PhotoSection,
  Section,
  SectionHeading,
  Submit,
  TeamHeading,
  TeamInfo,
  UploadButton,
  Cancel,
  LoaderContainer,
  PictureInputContainer,
  PicturePlaceholder,
  PictureHelper,
  PictureText,
  PictureTitle,
  PictureDescription,
  Picture,
  PictureDefault,
  PictureWrapper,
  EditIcon
} from './Styles';

import {
  Company,
  CompanyDemographic,
  UserDemographic,
  ApiStatus,
  UserTeamRegistration,
  FilePreview
} from '../../../../lib/Types';
import validationSchema, { UserProfileSchema } from '../../validators/userProfileSchema';
import { ApplicationState } from '../../../../lib/Store';
import { DisplayState } from '../../../UserOnboarding/components/PictureForm/PictureFormContainer';
import { RedText } from '../../../../Themes';
import PictureCrop from '../../../UserOnboarding/components/PictureForm/PictureCrop';
import { cloudUpload } from '../../../../assets/img';
import { pencil } from '../../../../assets/icons';

interface EditProfileFormProps extends RouteComponentProps {
  companyDemographics: CompanyDemographic[];
  company: Company;
  email: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  teamMemberRegistrations: UserTeamRegistration[];
  teamId: number;
  teamName: string;
  userDemographics: UserDemographic[];
  loading: boolean;
  onUpdateProfile: UpdateUserProfile;
  userId: string;
  onUpload: UploadProfilePic;
  onDeleteMedia: DeleteMedia;
}

const formikEnhancer = withFormik<EditProfileFormProps, UserProfileSchema>({
  validationSchema,
  enableReinitialize: true,
  mapPropsToValues: ({ firstName, lastName, email, teamName, profileImage, userDemographics }) => ({
    firstName,
    lastName,
    email,
    teamName,
    oldPassword: '',
    newPassword: '',
    company: '',
    role: '',
    userDemographics,
    profileImage
  }),
  handleSubmit: async (
    {
      firstName,
      lastName,
      email,
      teamName,
      profileImage,
      oldPassword,
      newPassword,
      userDemographics
    },
    { props, setErrors, setFieldValue, setFieldError }
  ) => {
    if (newPassword) {
      try {
        const reAuth = await Auth.reAuthenticate(props.email, oldPassword);
        if (reAuth) {
          await Auth.updatePassword(oldPassword, newPassword);
          setFieldValue('newPassword', '');
          setFieldValue('oldPassword', '');
        }
      } catch (err) {
        if (err.code === 'auth/wrong-password') {
          setErrors({ oldPassword: 'Incorrect password provided' });
        } else setErrors({ oldPassword: 'Unexpected error' });
        return; // Dont update rest of profile if password update fails
      }
    }

    const oldImage = props.profileImage;
    let newImage = profileImage;

    if (oldImage !== newImage) {
      const fileImage = (newImage as unknown) as File;
      const {
        status,
        data: { profileImage: newUploadedProfileImage }
      } = await props.onUpload(fileImage, props.userId);

      if (status === ApiStatus.FAILED) {
        setFieldError('profileImage', 'File upload failed');
        return;
      }
      newImage = newUploadedProfileImage;
      if (oldImage !== DEFAULT_PIC) props.onDeleteMedia(oldImage);
    }

    await props.onUpdateProfile({
      firstName,
      lastName,
      email,
      teamName,
      profileImage: newImage,
      userDemographics: userDemographics as any
    });
  }
});

const EditProfileForm: FC<EditProfileFormProps & FormikProps<UserProfileSchema>> = ({
  history,
  companyDemographics,
  handleChange,
  handleSubmit,
  teamMemberRegistrations,
  teamId,
  teamName,
  loading,
  values,
  errors,
  setFieldValue,
  setFieldError,
  userId
}) => {
  const [loadingImage, setLoadingImage] = useState(false);

  const [displayState, setDisplayState] = useState(DisplayState.UPLOAD);
  const [originalImage, setOriginalImage] = useState<FilePreview | undefined>();
  const [localFile, setLocalFile] = useState<FilePreview | undefined>();

  const updateContainerState = React.useCallback(
    (file: FilePreview) => {
      setLocalFile(file);
      setFieldValue('profileImage', file);
    },
    [setLocalFile]
  );

  const editUploadedImage = (): void => {
    updateContainerState(localFile);
    setDisplayState(DisplayState.CROP);
  };

  const captain = teamMemberRegistrations?.find(tmr => tmr.teamCaptain);
  const teamCaptainId = captain?.userId;
  const teamCaptainName = captain ? capitaliseUserFullName(captain.user) : '';

  const demographicFields = loading ? (
    <></>
  ) : (
    <>
      <FieldArray
        name="userDemographics"
        render={arrayHelper => {
          return values.userDemographics.map((userDemographic, index) => {
            const field = companyDemographics.find(
              companyDemographic =>
                companyDemographic.companyDemographicId === userDemographic.companyDemographicId
            );
            return field ? (
              <React.Fragment key={index}>
                <Field>
                  {field.options.length > 0 ? (
                    <SelectDropdown
                      error={errors.userDemographics && errors.userDemographics[index]}
                      data={field.options}
                      label={field.name}
                      value={userDemographic.childDemographicId}
                      valueProp="companyDemographicId"
                      displayProp="name"
                      placeholder="Please select"
                      onChange={value =>
                        arrayHelper.replace(index, {
                          companyDemographicId: field.companyDemographicId,
                          userDemographicId: userDemographic.userDemographicId,
                          childDemographicId: value,
                          childDemographicAnswer: null
                        })
                      }
                    />
                  ) : (
                    <Input
                      required
                      id={`userDemographics[index].childDemographicAnswer`}
                      name={`userDemographics[index].childDemographicAnswer`}
                      value={userDemographic.childDemographicAnswer}
                      label={field.name}
                      error={null}
                      disabled={false}
                      onChange={e => {
                        arrayHelper.replace(index, {
                          ...userDemographic,
                          companyDemographicId: field.companyDemographicId,
                          childDemographicAnswer: e.currentTarget.value
                        });
                      }}
                      onBlur={() => null}
                    />
                  )}
                </Field>
              </React.Fragment>
            ) : (
              <></>
            );
          });
        }}
      />
    </>
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormBody>
          <Column>
            <PhotoSection>
              {loadingImage ? (
                <LoaderContainer>
                  <LoadingIndicator />
                </LoaderContainer>
              ) : (
                <>
                  {displayState === DisplayState.UPLOAD ? (
                    <Dropzone
                      accept="image/jpeg,image/png"
                      maxSize={MAX_IMAGE_SIZE}
                      onDrop={([acceptedFile]: FilePreview[]): void => {
                        setLoadingImage(true);
                        setFieldError('profileImage', null);

                        if (!acceptedFile)
                          return setFieldError(
                            'profileImage',
                            `Image must be JPG/PNG <${MAX_IMAGE_SIZE_LABEL}`
                          ); // throw getImageSizeError();
                        const imageFile = acceptedFile;
                        imageFile.preview = URL.createObjectURL(acceptedFile);

                        setOriginalImage(imageFile);
                        setLocalFile(imageFile);
                        setFieldValue('profileImage', imageFile);
                        setLoadingImage(false);
                      }}
                    >
                      {({ getRootProps, getInputProps }): ReactElement => (
                        <PictureInputContainer>
                          <PicturePlaceholder {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div>
                              <PictureHelper />
                              <PictureWrapper key={!localFile ? cloudUpload : localFile.name}>
                                <EditIcon src={pencil} />
                                {values.profileImage && !localFile ? (
                                  <Picture src={values.profileImage} />
                                ) : !values.profileImage && !localFile ? (
                                  <PictureDefault src={cloudUpload} />
                                ) : (
                                  <Picture src={localFile.preview} />
                                )}
                              </PictureWrapper>
                            </div>
                          </PicturePlaceholder>
                          <PictureText>
                            <PictureTitle>Upload Your Photo</PictureTitle>
                            <PictureDescription>
                              Files should be in JPG or PNG format (&lt;10MB)
                            </PictureDescription>
                            {errors.profileImage ? <RedText>Image is too big</RedText> : ''}
                            {localFile && (
                              <UploadButton
                                disabled={!localFile}
                                variant="clear"
                                type="button"
                                onClick={editUploadedImage}
                              >
                                Crop
                              </UploadButton>
                            )}
                          </PictureText>
                        </PictureInputContainer>
                      )}
                    </Dropzone>
                  ) : (
                    <PictureCrop
                      file={originalImage}
                      updateContainerState={updateContainerState}
                      setDisplayState={setDisplayState}
                    />
                  )}
                </>
              )}
            </PhotoSection>
            <Section>
              <SectionHeading>Personal Details</SectionHeading>
              <Field>
                <Input
                  required
                  id="firstName"
                  name="firstName"
                  type="text"
                  label="First Name"
                  value={values.firstName}
                  error={errors.firstName}
                  onChange={handleChange}
                />
              </Field>
              <Field>
                <Input
                  required
                  id="lastName"
                  name="lastName"
                  type="text"
                  label="Last Name"
                  value={values.lastName}
                  error={errors.lastName}
                  onChange={handleChange}
                />
              </Field>
              <Field>
                <Input
                  required
                  id="email"
                  name="email"
                  type="text"
                  label="Email"
                  value={values.email}
                  error={errors.email}
                  onChange={handleChange}
                />
              </Field>
            </Section>
            <Section>
              <SectionHeading>Your Password</SectionHeading>
              <Field>
                <Input
                  required
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  value={values.oldPassword}
                  error={errors.oldPassword}
                  label="Your Old Password"
                  onChange={handleChange}
                />
              </Field>
              <Field>
                <Input
                  required
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  value={values.newPassword}
                  error={errors.newPassword}
                  label="Create a New Password"
                  onChange={handleChange}
                />
              </Field>
            </Section>
          </Column>
          <Column>
            <Section>
              <SectionHeading>Team Details</SectionHeading>
              {userId === teamCaptainId && values.teamName ? (
                <>
                  <TeamCapNote>
                    As the team captain you have the ability to change the name of your team.
                  </TeamCapNote>
                  <Field>
                    <Input
                      id="teamName"
                      name="teamName"
                      type="text"
                      label="Your Team Name"
                      value={values.teamName}
                      error={errors.teamName}
                      onChange={handleChange}
                    />
                  </Field>
                </>
              ) : (
                <>
                  <TeamInfo>
                    <div>
                      <TeamHeading>Team Joined</TeamHeading>
                      <BodyText>{teamId ? teamName : 'Not yet'}</BodyText>
                    </div>
                    <div>
                      <TeamHeading>Team Captain</TeamHeading>
                      <BodyText>{teamCaptainId ? teamCaptainName : 'NA'}</BodyText>
                    </div>
                  </TeamInfo>
                </>
              )}
            </Section>
            {!!values.userDemographics.length && (
              <Section>
                <SectionHeading>Company Specific Questions</SectionHeading>
                {demographicFields}
              </Section>
            )}
          </Column>
        </FormBody>
        <FormFooter>
          <Cancel onClick={() => history.push('/')} type="button" variant="cancel">
            Cancel
          </Cancel>
          <Submit type="submit" variant="primary">
            Save
          </Submit>
        </FormFooter>
      </Form>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onUpload: uploadProfilePicture as any,
      onDeleteMedia: deleteMedia as any,
      onUpdateProfile: updateUserProfile as any
    },
    dispatch
  );

const mapStateToProps = ({
  userState,
  participantEventState,
  participantTeamState,
  loadingState
}: ApplicationState) => {
  const { company, email, firstName, lastName, profileImage, userId } = userState.userData;
  const team = participantTeamState?.team;
  const teamMemberRegistrations = participantTeamState?.teamMemberRegistrations;
  const { companyDemographics, userDemographics } = participantEventState;
  return {
    userId,
    companyDemographics,
    company,
    email,
    firstName,
    lastName,
    profileImage,
    teamId: team ? team.teamId : null,
    teamName: team ? team.name : null,
    teamMemberRegistrations,
    userDemographics,
    loading: loadingState.apiCallsInProgress > 0
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(formikEnhancer(EditProfileForm)));
