import React, { useEffect } from 'react';
import { PageContent, StyledPageHd } from '../../Themes';
import { EmployeePageWrapper } from '../../components/UI';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router';
import { ToSIPrefix } from '@cognativ/utilities';

import { loadTeamStats } from '../../lib/Api/Stats';
import { getToday, getYesterday, getWeek, getAllSteps } from '../../lib/Utils';
import { getTeam } from '../../lib/Api/Team';

import {
  TeamLeaderboardContainer,
  TeamLeaderboardHeader,
  TeamName,
  TeamTotal,
  Table,
  TableHeaders,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  ProfilePic,
  ProfileCell,
  Rank,
  ProfileCaptain,
  ProfileName
} from './Styles';
import BackButton from '../../components/UI/Button/BackButton';
import LoadingIndicator from '../../components/UI/LoadingIndicator';

import { ApplicationState } from '../../lib/Store';
import { pfpPlaceholder } from '../../assets/img';

const TeamLeaderboard = ({
  location,
  history,
  team,
  getTeam,
  loadTeamStats,
  teamStats,
  loading
}) => {
  // Fetch the team to get the eventID
  useEffect(() => {
    if (location.state?.teamId) {
      getTeam(+location.state.teamId);
    }
  }, []);

  // Now get the teamStats
  useEffect(() => {
    if (team.eventId) {
      loadTeamStats(location.state.teamId, team.eventId);
    }
  }, [team]);

  if (location.state?.teamId === undefined) {
    return <Redirect to="/Leaderboard" />;
  }

  const getTeamTotal = () => {
    let total = 0;

    teamStats.slice(0, 5).map(data => {
      const result = getAllSteps(data.steps);
      if (result !== 'none') {
        total += result;
      }
    });

    return ToSIPrefix(total);
  };

  return (
    <EmployeePageWrapper>
      <PageContent>
        <StyledPageHd>
          <BackButton onClick={() => history.push({ pathname: '/Leaderboard' })} />
          Leaderboards
        </StyledPageHd>
        <TeamLeaderboardContainer>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <TeamLeaderboardHeader>
                <TeamName>{team.name === '' ? 'No team name yet!' : team.name}</TeamName>
                <TeamTotal>Team Steps Total: {getTeamTotal()}</TeamTotal>
              </TeamLeaderboardHeader>
              <Table>
                <TableHeaders>
                  <TableHeader />
                  <TableHeader dropable={true}>Today</TableHeader>
                  <TableHeader dropable={true}>Yesterday</TableHeader>
                  <TableHeader dropable={true}>This Week</TableHeader>
                  <TableHeader dropable={true}>Total Steps Tracked</TableHeader>
                  <TableHeader dropable={true}>Connection Points</TableHeader>
                  <TableHeader>Total Points</TableHeader>
                </TableHeaders>
                <TableBody>
                  {teamStats.slice(0, 5).map((data, index) => (
                    <TableRow highlight={index % 2 === 0} key={index}>
                      <TableCell highlight={index % 2 === 0} dropable={false}>
                        <ProfileCell>
                          <Rank>{index + 1}</Rank>
                          <ProfilePic
                            image={data.profileImage ? data.profileImage : pfpPlaceholder}
                          />
                          <ProfileName>{data.name}</ProfileName>
                          {data.teamCaptain && <ProfileCaptain>(Captain)</ProfileCaptain>}
                        </ProfileCell>
                      </TableCell>
                      <TableCell
                        none={getToday(data.steps) === 'none'}
                        highlight={index % 2 == 0}
                        dropable={true}
                      >
                        {getToday(data.steps)}
                      </TableCell>
                      <TableCell
                        none={getYesterday(data.steps) === 'none'}
                        highlight={index % 2 == 0}
                        dropable={true}
                      >
                        {getYesterday(data.steps)}
                      </TableCell>
                      <TableCell
                        none={getWeek(data.steps) === 'none'}
                        highlight={index % 2 == 0}
                        dropable={true}
                      >
                        {getWeek(data.steps)}
                      </TableCell>
                      <TableCell
                        none={getAllSteps(data.steps) === 'none'}
                        highlight={index % 2 == 0}
                        dropable={true}
                      >
                        {typeof getAllSteps(data.steps) === 'number'
                          ? ToSIPrefix(getAllSteps(data.steps) as number)
                          : getAllSteps(data.steps)}
                      </TableCell>
                      <TableCell highlight={index % 2 == 0} dropable={true}>
                        {data.connectionPoints}
                      </TableCell>
                      <TableCell highlight={index % 2 == 0} dropable={false}>
                        {data.connectionPoints + data.movementPoints}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </TeamLeaderboardContainer>
      </PageContent>
    </EmployeePageWrapper>
  );
};

const mapStateToProps = ({ loadingState, teamsState, statsState }: ApplicationState) => ({
  loading: loadingState.apiCallsInProgress > 0,
  team: teamsState.team,
  teamStats: statsState.teamStats
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTeam: (teamId: number) => getTeam(teamId),
      loadTeamStats: (teamId: number) => loadTeamStats(teamId)
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamLeaderboard as any));
