import React, { FC, useState, useEffect } from 'react';

import { BarGraphContainer, BarWrapper, BarInnerWrapper, Bar, BarName, BarTotal } from './Styles';

import { RelativePercentTopFiveActivity } from '../../../../lib/Types';

interface BarGraphProps {
  data: RelativePercentTopFiveActivity[];
}

interface Bar {
  value: number;
  formattedValue: string;
  name: string;
  valueAsSteps: number;
}

const barGradients = [
  'linear-gradient(90deg, rgba(117,219,255,1) 0%, rgba(169,168,255,1) 100%)',
  'linear-gradient(90deg, rgba(255,216,107,1) 0%, rgba(229,163,68,1) 41%, rgba(167,129,70,1) 100%)',
  'linear-gradient(90deg, rgba(50,197,255,1) 0%, rgba(182,32,224,1) 44%, rgba(247,181,0,1) 100%)',
  'linear-gradient(90deg, rgba(0,212,136,1) 0%, rgba(0,247,159,1) 100%)',
  'linear-gradient(90deg, rgba(121,0,212,1) 0%, rgba(0,16,247,1) 100%)'
];

const BarGraph: FC<BarGraphProps> = ({ data }) => {
  const [maxValue, setMaxValue] = useState(0);
  const [bars, setBars] = useState<Bar[]>([]);

  useEffect(() => {
    if (data) {
      const filteredData = data
        .filter(row => row.repetitions > 0) // Only non zero rows
        .map(row => {
          let name;
          let value;
          let formattedValue;
          let valueAsSteps;
          if (row.name === 'Steps') {
            name = 'Walking';
            value = row.steps;
            formattedValue = `${row.repetitions} Steps`;
            valueAsSteps = row.steps;
          } else {
            name = row.name;
            value = row.repetitions;
            if (value > 60) formattedValue = `${(value / 60).toFixed(1)} hours`;
            else formattedValue = `${value} minutes`;
            valueAsSteps = row.repetitions * row.conversion;
          }
          return {
            name,
            value,
            formattedValue,
            valueAsSteps
          };
        })
        .sort((a, b) => (a.valueAsSteps > b.valueAsSteps ? -1 : 1))
        .slice(0, 5); // Top five only

      if (filteredData.length) {
        setBars(filteredData);
      } else setBars([]);
    }
  }, [data]);

  useEffect(() => {
    bars.length && setMaxValue(bars.map(r => r.valueAsSteps).reduce((a, b) => (a > b ? a : b)));
  }, [bars]);

  return (
    <BarGraphContainer>
      {bars.length ? (
        bars.map((bar, index) => {
          const width = (bar.valueAsSteps / maxValue) * 100; // Width based on steps or converted value
          return (
            <BarWrapper key={index}>
              <BarName>{bar.name}</BarName>
              <BarInnerWrapper>
                <Bar
                  width={`${width}%`}
                  gradient={barGradients[index % barGradients.length]} // cycle through colors
                />
                <BarTotal>{bar.valueAsSteps} Steps</BarTotal>
              </BarInnerWrapper>
            </BarWrapper>
          );
        })
      ) : (
        <BarName>{"You haven't completed any activities yet!"}</BarName>
      )}
    </BarGraphContainer>
  );
};

export default BarGraph;
