import React, { FC } from 'react';
import { Group, Heading, CheckboxContainer } from '../Styles';
import { Checkbox, ErrorLabel } from '../../../../../components/UI';
import { ActivityType, FormikFormComponent } from '../../../../../lib/Types';
import { FieldArray } from 'formik';
import { EventSchema } from '../../../validators/eventSchema';

interface ActivitiesToIncludeProps extends FormikFormComponent<EventSchema> {
  activityTypes: ActivityType[];
}

const ActivitiesToInclude: FC<ActivitiesToIncludeProps> = ({ activityTypes, values, errors }) => (
  <Group>
    <Heading>
      Activities to allow in tracking <span>(Steps per minute)</span>
    </Heading>
    {errors.activitiesToInclude && <ErrorLabel error={errors.activitiesToInclude} />}
    <CheckboxContainer>
      <FieldArray
        name="activitiesToInclude"
        render={arrayHelper => (
          <>
            {activityTypes.map(({ activityTypeId, name }) => (
              <Checkbox
                key={activityTypeId}
                name="activitiesToInclude"
                checked={values.activitiesToInclude.includes(activityTypeId)}
                label={name}
                onChange={e => {
                  if (e.target.checked) arrayHelper.push(activityTypeId);
                  else {
                    const idx = values.activitiesToInclude.indexOf(activityTypeId);
                    arrayHelper.remove(idx);
                  }
                }}
              />
            ))}
          </>
        )}
      />
    </CheckboxContainer>
  </Group>
);

export default ActivitiesToInclude;
