import React, { FC, useEffect, ChangeEvent } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import moment from 'moment-timezone';

import validationSchema, { EventSchema } from '../../validators/eventSchema';
import EditEvent from './EditEvent';
import { ApplicationState } from '../../../../lib/Store';
import { setEvent } from '../../../../lib/Store/contexts/event/actions';
import { editEvent } from '../../../../lib/Api/masterAdmin/Event';
import { loadActivityTypes } from '../../../../lib/Api/ActivityTypes';
import {
  Event,
  ActivityType,
  ErrorResponse,
  PaginationFilter,
  PagedResponse,
  CompanyRegistrationData
} from '../../../../lib/Types';
import { timeOptions } from '../../../../CONSTANTS';
import { buildTimezoneMoment, getClientTimezoneOffset } from '../../../../lib/TimeUtils';
import {
  LoadPagedCompaniesInEvent,
  loadPagedCompaniesInEvent
} from '../../../../lib/Api/masterAdmin/Event';

export interface EditEventContainerProps {
  activityTypes: ActivityType[];
  loading: boolean;
  event: Event;
  filter: PaginationFilter;
  companiesInEvent: PagedResponse<CompanyRegistrationData>;
  editEvent: (eventId: number, data: EventSchema) => any;
  loadActivityTypes: () => any;
  cancel: () => void;
  onLoadPagedCompaniesInEvent: LoadPagedCompaniesInEvent;
}

const formikEnhancer = withFormik<EditEventContainerProps, EventSchema>({
  validationSchema,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: ({ event }) => ({
    name: event.name,
    activitiesToInclude: event.activityTypes.map(({ activityTypeId }) => activityTypeId),
    timezone: event.timezone,
    registrationsStartDate: event.registrationsStartDate,
    registrationsStartDateTime: moment(event.registrationsStartDate, 'x')
      .tz(event.timezone)
      .format('HH:mm'),
    registrationsEndDate: event.registrationsEndDate,
    registrationsEndDateTime: moment(event.registrationsEndDate, 'x')
      .tz(event.timezone)
      .format('HH:mm'),
    startDate: event.startDate,
    startDateTime: moment.tz(moment(event.startDate, 'x'), event.timezone).format('HH:mm'),
    endDate: event.endDate,
    endDateTime: moment.tz(moment(event.endDate, 'x'), event.timezone).format('HH:mm'),
    gracePeriod: event.gracePeriod,
    teamSize: event.teamSize,
    costPerParticipant: event?.costPerParticipant / 100,
    active: event.active,
    stepApproval: event.stepApproval,
    stepApprovalThreshold: event.stepApprovalThreshold,
    averageSteps: event.averageSteps
  }),
  handleSubmit: async (values: EventSchema, { props, setErrors }) => {
    const submitValues = { ...values };
    submitValues.startDate = buildTimezoneMoment(
      submitValues.startDate,
      submitValues.startDateTime,
      submitValues.timezone
    );
    submitValues.endDate = buildTimezoneMoment(
      submitValues.endDate,
      submitValues.endDateTime,
      submitValues.timezone
    );
    submitValues.registrationsStartDate = buildTimezoneMoment(
      submitValues.registrationsStartDate,
      submitValues.registrationsStartDateTime,
      submitValues.timezone
    );
    submitValues.registrationsEndDate = buildTimezoneMoment(
      submitValues.registrationsEndDate,
      submitValues.registrationsEndDateTime,
      submitValues.timezone
    );

    // convert to cents before sending
    submitValues.costPerParticipant = submitValues.costPerParticipant * 100;

    const { error }: { error: ErrorResponse } = await props.editEvent(
      props.event.eventId,
      submitValues
    );
    if (error.data.field) setErrors({ [error.data.field]: error.message });
  }
});

const EditEventContainer: FC<EditEventContainerProps & FormikProps<EventSchema>> = ({
  setFieldValue,
  setValues,
  cancel,
  loadActivityTypes,
  onLoadPagedCompaniesInEvent,
  filter,
  companiesInEvent,
  ...props
}) => {
  useEffect(() => {
    loadActivityTypes();
  }, []);

  useEffect(() => {
    if (!props.values.name) return;
    (async () => {
      await onLoadPagedCompaniesInEvent(filter);
    })();
  }, [filter]);

  const changeCustom = (name: string, data: any) => setFieldValue(name, data);
  const changeDateCustom = (name: string, date: Date) => {
    const clientTimezone = getClientTimezoneOffset();
    const eventTimezoneOffset = moment.tz(moment(), props.values.timezone).utcOffset();
    setFieldValue(
      name,
      moment(date)
        .add('minutes', eventTimezoneOffset * -1)
        .add('minutes', clientTimezone * -1)
        .valueOf()
        .toString()
    );
  };
  const changeTimeCustom = (timeKey: string, timeOptionId: number) => {
    if (typeof timeOptionId === 'number') {
      const timeVal = timeOptions[timeOptionId - 1];

      setValues({
        ...props.values,
        [timeKey]: timeVal.value
      });
    }
  };

  const handleSetCost = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('costPerParticipant', parseFloat(e.target.value).toFixed(2));
  };

  return (
    <EditEvent
      {...props}
      companiesInEvent={companiesInEvent}
      filter={filter}
      handleDateChangeCustom={changeDateCustom}
      handleTimeChangeCustom={changeTimeCustom}
      handleChangeCustom={changeCustom}
      handleSetCost={handleSetCost}
      onCancel={cancel}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  event: state.eventState.event,
  activityTypes: state.activityTypeState.activityTypes,
  companiesInEvent: state.masterAdminEventState.pagedCompaniesInEvent,
  filter: state.masterAdminEventState.companiesInEventFilter,
  loading: state.loadingState.apiCallsInProgress > 0
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editEvent: (eventId: number, data: EventSchema) => editEvent(eventId, data),
      loadActivityTypes: () => loadActivityTypes(),
      cancel: () => setEvent({} as Event),
      onLoadPagedCompaniesInEvent: (filter: PaginationFilter) =>
        loadPagedCompaniesInEvent(filter) as any
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(formikEnhancer(EditEventContainer));
