import * as yup from 'yup';

const linkCompanyEventSchema = yup.object({
  passCode: yup.string(),
  participantAllotment: yup.number().required('Participant allotment must be set.'),
  welcomeMessage: yup.string().required('A Company greeting is required.'),
  demographics: yup
    .array()
    .of(
      yup.object({
        name: yup
          .string()
          .trim()
          .required('Demographic Question is required'),
        options: yup
          .array()
          .of(
            yup.object({
              name: yup
                .string()
                .trim()
                .required('Demographic Answer is required')
            })
          )
          .min(1, 'All demographics require at least one answer')
      })
    )
    .notRequired()
    .nullable(true)
});

export type LinkCompanyEventSchema = yup.InferType<typeof linkCompanyEventSchema>;

export default linkCompanyEventSchema;
