import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export interface RightColumnProps {
  tabletAlign?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.backgrounds.white};
  min-height: 100vh;
`;

export const LeftColumn = styled.div<{ bg: string }>`
  background-image: ${({ bg }) => `url(${bg})`};
  background-repeat: no-repeat;
  background-position: left;
  background-attachment: fixed;
  background-size: auto;
  display: none;
  flex: 1;
  width: 30vw;

  @media (min-width: ${({ theme }) => theme.device.tablet}px) {
    display: block;
    padding: ${({ theme }) => theme.padding * 4}px;
  }

  @media (min-width: ${({ theme }) => theme.device.laptop}px) {
    width: 52vw;
  }

  @media (min-width: ${({ theme }) => theme.device.laptopL}px) {
    background-size: 52vw;
  }
`;

export const RightColumn = styled.div<RightColumnProps>`
  flex: 1;
  padding: ${({ tabletAlign }) => (tabletAlign ? '2vh 5vw 10vh 5vw' : '2vh 15vw 10vh 13vw')};
  align-items: ${({ tabletAlign }) => (tabletAlign ? tabletAlign : 'flex-start')};
  box-sizing: border-box;

  @media (min-width: ${({ theme }) => theme.device.tablet}px) {
    padding: ${({ theme }) => theme.padding * 4}px;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Header = styled.h3`
  font-size: 2rem;
  font-style: italic;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  color: ${({ theme }) => theme.primary.dark};
  margin: ${({ theme }) => theme.padding}px 0 ${({ theme }) => theme.padding}px 0;
`;

export const BackArrow = styled.img`
  display: block;
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.padding * 2}px;
`;


export const LinkText = styled(Link)`
  text-decoration: none;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.primary.xlight};
`;
