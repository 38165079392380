import React, { FC } from 'react';
import { TabContainer, StandAloneTabContainer } from './Styles';

interface TabContainerProps {
  title: string;
  activeTab: string;
  setActiveTab: any;
  standAlone: boolean;
}

const Tab: FC<TabContainerProps> = ({ title, activeTab, setActiveTab, standAlone }) =>
  !standAlone ? (
    <TabContainer active={activeTab === title} onClick={() => setActiveTab(title)}>
      {title}
    </TabContainer>
  ) : (
    <StandAloneTabContainer active={activeTab === title} onClick={() => setActiveTab(title)}>
      {title}
    </StandAloneTabContainer>
  );

export default Tab;
