import styled from 'styled-components/macro';

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    margin: 0 1rem 1rem;
  }
`;

export const TabFilter = styled.div`
  display: flex;
  width: fit-content;

  background-color: #f5f5f5;
  box-shadow: inset 0px 1px 10px 0px rgba(163, 163, 163, 0.3);
  border-radius: 25px 25px 0 0;

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    width: 100%;
  }
`;

export const Tab = styled.div<{
  isLeft?: boolean;
  isRight?: boolean;
  selected?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 3rem;

  color: ${({ selected }) => (selected ? '#366696' : '#B9BBC0')};

  cursor: pointer;

  @media (max-width: 580px) {
    padding: 0 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    flex: 1;
    padding: 0 1rem;
  }
`;

export const TabText = styled.div`
  font-size: ${({ theme }) => theme.fontScale.sm};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin: 1rem 0;
  font-style: italic;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.device.laptop}px) {
    font-size: calc(0.5em + 0.5vw);
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}px) {
    font-size: calc(0.6em + 0.5vw);
  }
`;
