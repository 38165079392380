import {
  PagedResponse,
  APIThunkResult,
  PaginationFilter,
  ParticipantListView,
  Permission,
  Team,
  UserDemographic,
  EditParticipantResponse,
  CompanyDemographic
} from '../Types';
import { apiRequest, objectToQueryString, apiFetch } from './Utils';
import { ParticipantSchema } from '../../pages/Participants/validators/participantSchema';
import { server } from '../../CONSTANTS';
import {
  setPagedParticipants,
  setPagedParticipantsWithoutTeam,
  setParticipantsWithoutTeam,
  setParticipant,
  setParticipantTeam,
  setParticipantDemographics,
  setDeletingParticipant,
  setPendingParticipant,
  setPagedParticipantsNotRegistered
} from '../Store/contexts/participants/actions';
import { setNotification } from '../Store/contexts/notification/actions';
import { getTeam } from './Team';
import { ReplaceNewUserSchema } from 'src/pages/Teams/validators/replaceNewUserSchema';
import { initialState } from '../Store/contexts/participants/reducers';
import { setAddingTeamMember } from '../Store/contexts/team/actions';

export function loadPagedParticipants(
  filter: PaginationFilter,
  eventId: number | null
): APIThunkResult<PagedResponse<ParticipantListView>> {
  return apiRequest<PagedResponse<ParticipantListView>>(async (dispatch, getState) => {
    const query = objectToQueryString({ ...filter, eventId: eventId });
    const { permissionsState } = getState();
    let url = `${server}/participants/paged/company?${query}`;

    if (permissionsState.permissions.includes(Permission.VIEW_ALL_EVENT_PARTICIPANTS)) {
      url = `${server}/participants/paged?${query}`;
    }

    const results = await apiFetch<PagedResponse<ParticipantListView>>({
      method: 'GET',
      url
    });
    dispatch(setPagedParticipants(results));

    return results;
  });
}

export function loadPagedParticipantsWithoutTeam(
  filter: PaginationFilter,
  eventId: number
): APIThunkResult<PagedResponse<ParticipantListView>> {
  return apiRequest<PagedResponse<ParticipantListView>>(async (dispatch, getState) => {
    const query = objectToQueryString({ ...filter, eventId });
    const { permissionsState } = getState();
    let url = `${server}/participants/paged/company/without-team?${query}`;

    if (
      permissionsState.permissions.includes(Permission.VIEW_ALL_EVENT_PARTICIPANTS_WITHOUT_TEAM)
    ) {
      url = `${server}/participants/paged/without-team?${query}`;
    }

    const results = await apiFetch<PagedResponse<ParticipantListView>>({
      method: 'GET',
      url
    });
    dispatch(setPagedParticipantsWithoutTeam(results));

    return results;
  });
}

export function loadPagedParticipantsNotRegistered(
  filter: PaginationFilter
): APIThunkResult<PagedResponse<ParticipantListView>> {
  return apiRequest<PagedResponse<ParticipantListView>>(async (dispatch, getState) => {
    const query = objectToQueryString(filter);
    const { permissionsState } = getState();
    let url = `${server}/participants/paged/company/not-registered?${query}`;

    if (permissionsState.permissions.includes(Permission.VIEW_ALL_EVENT_PARTICIPANTS)) {
      url = `${server}/participants/paged/not-registered?${query}`;
    }

    const results = await apiFetch<PagedResponse<ParticipantListView>>({
      method: 'GET',
      url
    });
    dispatch(setPagedParticipantsNotRegistered(results));

    return results;
  });
}

export function loadParticipantsWithoutTeam(
  eventId: number,
  companyId?: number
): APIThunkResult<ParticipantListView[]> {
  return apiRequest<ParticipantListView[]>(async (dispatch, getState) => {
    const { permissionsState } = getState();
    let url = `${server}/participants/company/without-team?eventId=${eventId}`;

    if (permissionsState.permissions.includes(Permission.VIEW_ALL_EVENT_PARTICIPANTS)) {
      url = `${server}/participants/without-team?companyId=${companyId || null}&eventId=${eventId}`;
    }

    const results = await apiFetch<ParticipantListView[]>({
      method: 'GET',
      url
    });
    dispatch(setParticipantsWithoutTeam(results));

    return results;
  });
}

export function loadParticipantTeam(teamId: number | null): APIThunkResult<Team> {
  return apiRequest<Team>(async dispatch => {
    if (!teamId) {
      dispatch(setParticipantTeam(null));
      return;
    }
    const result = await apiFetch<Team>({
      method: 'GET',
      url: `${server}/team/${teamId}`
    });
    dispatch(setParticipantTeam(result));

    return result;
  });
}

export interface ParticipantDemographicsDTO {
  userDemos: UserDemographic[];
  companyDemos: CompanyDemographic[];
}

export function loadParticipantDemographics(
  participantId: string
): APIThunkResult<UserDemographic[]> {
  return apiRequest<UserDemographic[]>(async dispatch => {
    const url = `${server}/users/demographics/${participantId}`;

    const results = await apiFetch<ParticipantDemographicsDTO>({
      method: 'GET',
      url
    });
    dispatch(setParticipantDemographics(results.userDemos));

    return results.userDemos;
  });
}

export function loadParticipant(participantId: string): APIThunkResult<ParticipantListView> {
  return apiRequest<ParticipantListView>(async dispatch => {
    const url = `${server}/participants/single/${participantId}`;

    const results = await apiFetch<ParticipantListView>({
      method: 'GET',
      url
    });
    dispatch(setParticipant(results));
    return results;
  });
}

export function editParticipant(
  participantId: string,
  eventId: number | null,
  teamId: number | null,
  data: ParticipantSchema
): APIThunkResult<EditParticipantResponse> {
  return apiRequest<EditParticipantResponse>(async (dispatch, getState) => {
    const { participantsState } = getState();

    //eventId = event that team belongs to, meaning if null must be an team registration
    const url =
      teamId !== null
        ? `${server}/team/${teamId}/participants/${participantId}`
        : `${server}/events/${eventId}/individuals/${participantId}`;

    const result = await apiFetch<EditParticipantResponse>({
      method: 'POST',
      url,
      body: data
    });
    dispatch(setParticipant({} as ParticipantListView));
    dispatch(loadPagedParticipants(participantsState.filter, eventId));
    dispatch(loadPagedParticipantsWithoutTeam(participantsState.filter, eventId));
    dispatch(setNotification({ message: 'Saved Participant successfully' }));
    return result;
  });
}

export function editUser(
  userId: string,
  data: ParticipantSchema
): APIThunkResult<EditParticipantResponse> {
  return apiRequest<EditParticipantResponse>(async (dispatch, getState) => {
    const { participantsState } = getState();

    const url = `${server}/users/individuals/${userId}`;

    const result = await apiFetch<EditParticipantResponse>({
      method: 'PUT',
      url,
      body: data
    });
    dispatch(setParticipant({} as ParticipantListView));
    dispatch(loadPagedParticipantsNotRegistered(participantsState.filter));
    dispatch(setNotification({ message: 'Saved Participant successfully' }));
    return result;
  });
}

export function replaceIndividualParticipant(
  team: Team,
  participantId: string,
  replaceParticipantId: string
): APIThunkResult<EditParticipantResponse> {
  return apiRequest<EditParticipantResponse>(async (dispatch, getState) => {
    const { participantsState } = getState();

    const url = `${server}/team/${team.teamId}/participants/${participantId}/replace/${replaceParticipantId}`;

    const result = await apiFetch<EditParticipantResponse>({
      method: 'PUT',
      url
    });
    dispatch(getTeam(team.teamId));
    dispatch(setParticipant(initialState.participant));
    dispatch(setAddingTeamMember(false));
    dispatch(loadPagedParticipants(participantsState.filter, team.eventId));
    dispatch(loadPagedParticipantsWithoutTeam(participantsState.filter, team.eventId));
    dispatch(loadParticipantsWithoutTeam(team.eventId));
    dispatch(setNotification({ message: 'Replaced Participant successfully' }));
    return result;
  });
}

export function replaceInviteParticipant(
  team: Team,
  participantId: string,
  data: ReplaceNewUserSchema
): APIThunkResult<EditParticipantResponse> {
  return apiRequest<EditParticipantResponse>(async (dispatch, getState) => {
    const { participantsState } = getState();

    const url = `${server}/team/${team.teamId}/participants/${participantId}/replace`;

    const result = await apiFetch<EditParticipantResponse>({
      method: 'PUT',
      url,
      body: data
    });
    dispatch(getTeam(team.teamId));
    dispatch(setParticipant(initialState.participant));
    dispatch(setAddingTeamMember(false));
    dispatch(loadPagedParticipants(participantsState.filter, team.eventId));
    dispatch(loadPagedParticipantsWithoutTeam(participantsState.filter, team.eventId));
    dispatch(loadParticipantsWithoutTeam(team.eventId));
    dispatch(setNotification({ message: 'Replaced Participant successfully' }));
    return result;
  });
}

export function deleteIndividualParticipant(
  eventId: number,
  participantId: string
): APIThunkResult<any> {
  return apiRequest<any>(async (dispatch, getState) => {
    const { participantsState } = getState();
    const url =
      eventId === null
        ? `${server}/participants/unregistered/${participantId}`
        : `${server}/events/${eventId}/individuals/${participantId}`;
    const result = await apiFetch<any>({
      method: 'DELETE',
      url
    });

    dispatch(setParticipant(initialState.participant));
    dispatch(setDeletingParticipant(false));
    dispatch(loadPagedParticipantsWithoutTeam(participantsState.filter, eventId));
    dispatch(loadParticipantsWithoutTeam(eventId));
    dispatch(loadPagedParticipants(participantsState.filter, eventId));
    dispatch(loadPagedParticipantsNotRegistered(participantsState.filter));
    dispatch(setNotification({ message: 'Participant deleted successfully' }));
    return result;
  });
}

export function deleteTeamParticipant(
  teamId: number,
  participantId: string,
  eventId: number
): APIThunkResult<any> {
  return apiRequest<any>(async (dispatch, getState) => {
    const { participantsState } = getState();
    const url = `${server}/team/${teamId}/participants/${participantId}`;
    const result = await apiFetch<any>({
      method: 'DELETE',
      url
    });

    dispatch(setParticipant(initialState.participant));
    dispatch(setDeletingParticipant(false));
    dispatch(loadPagedParticipants(participantsState.filter, eventId));
    dispatch(loadParticipantsWithoutTeam(eventId));
    dispatch(setNotification({ message: 'Participant deleted successfully' }));
    return result;
  });
}

export function deletePendingParticipant(teamId: number, email: string): APIThunkResult<any> {
  return apiRequest<any>(async dispatch => {
    const url = `${server}/team/${teamId}/pendingRegistrations/${email}`;
    const result = await apiFetch<any>({
      method: 'DELETE',
      url
    });

    dispatch(setPendingParticipant(initialState.pendingParticipant));
    dispatch(setDeletingParticipant(false));
    dispatch(getTeam(teamId));
    dispatch(setNotification({ message: 'Participant deleted successfully' }));
    return result;
  });
}
