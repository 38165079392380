import {
  setApiLoading,
  apiLoadingSuccess,
  apiLoadingError
} from '../Store/contexts/loading/actions';
import { APIThunkResult, APIThunkDispatch, APIResult, ApiStatus, RequestType } from '../Types';
import { ApplicationState } from '../Store';
import Backend from '../Backend';
import { setNotification } from '../Store/contexts/notification/actions';

/** Helpers */

const apiSuccess = (
  dispatch: APIThunkDispatch,
  data: any = null,
  load = true
): APIResult<typeof data> => {
  if (load) {
    dispatch(apiLoadingSuccess());
  }
  return { status: ApiStatus.SUCCESS, data };
};

const apiError = (
  dispatch: APIThunkDispatch,
  error: Error,
  load = true,
  showError = false
): APIResult<any> => {
  if (load) {
    dispatch(apiLoadingError());
  }
  if (showError)
    dispatch(setNotification({ message: error.message, duration: 4, variant: 'danger' }));

  return { status: ApiStatus.FAILED, error };
};

export function apiRequest<T = null>(
  request: (dispatch: APIThunkDispatch, getState: () => ApplicationState) => Promise<T>,
  enableLoading = true,
  showError = false
): APIThunkResult<T> {
  return async (
    dispatch: APIThunkDispatch,
    getState: () => ApplicationState
  ): Promise<APIResult<T>> => {
    try {
      // Loads for any request. apiSuccess and apiError will stop it
      if (enableLoading) {
        dispatch(setApiLoading());
      }
      // Call specific request and retrieve return result
      const data: T = await request(dispatch, getState);
      return apiSuccess(dispatch, data, enableLoading);
    } catch (error) {
      return apiError(dispatch, error, enableLoading, showError);
    }
  };
}

export interface FetchOptions {
  method: RequestType;
  url: string;
  param?: any;
  body?: any;
}

export async function apiFetch<T>(options: FetchOptions): Promise<T> {
  const { method, url, param, body } = options;
  const getUrl = param ? `${url}/${param}` : url;
  const token = await Backend.getAuthUser()?.getIdToken();

  const response = await fetch(getUrl, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    },
    body: JSON.stringify(body)
  });

  if (response.status === 204) return;
  const json = await response.json();

  if (response.status === 401) {
    await Backend.signOut();
  }

  if (!response.ok) {
    return Promise.reject(json);
  }

  return json;
}

export const objectToQueryString = (params: Record<string, any>): string =>
  params &&
  Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&');
