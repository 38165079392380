import { apiRequest, apiFetch } from '../Utils';
import { server } from '../../../CONSTANTS';

import { APIThunkResult, APIResult, DashBoardData } from '../../Types';
import { setDashBoardData } from '../../Store/contexts/participant/dashboard/actions';

export type GetMyDashboardData = () => Promise<APIResult<DashBoardData>>;
export function getMyDashboardData(): APIThunkResult<DashBoardData> {
  return apiRequest<any>(async dispatch => {
    const data = await apiFetch<DashBoardData>({
      method: 'GET',
      url: `${server}/dashboard/participant-dashboard`
    });

    dispatch(setDashBoardData(data));
    return data;
  });
}
