import { apiRequest, apiFetch, objectToQueryString } from '../Utils';
import { server } from '../../../CONSTANTS';

import {
  APIThunkResult,
  APIResult,
  PagedResponse,
  PaginationFilter,
  TeamRankView,
  ParticipantRankView,
  APIThunkDispatch
} from '../../Types';
import {
  setIndividualRankings,
  setMyTeamRank,
  setOwnRanking,
  setPagedTeamRanks
} from '../../Store/contexts/participant/leaderBoard/actions';
import { ApplicationState } from '../../Store';

// Get paged team ranks for current event, for company or across whole event
export type LoadPagedTeamRanks = (
  filter: PaginationFilter,
  companyOnly: boolean,
  showLoader?: boolean
) => Promise<APIResult<PagedResponse<TeamRankView>>>;
export function loadPagedTeamRanks(
  filter: PaginationFilter,
  companyOnly = true,
  showLoader = false
): APIThunkResult<PagedResponse<TeamRankView>> {
  return apiRequest<PagedResponse<TeamRankView>>(async dispatch => {
    const query = objectToQueryString(filter);
    const url = companyOnly
      ? `${server}/team/paged/team-rankings/company?${query}`
      : `${server}/team/paged/team-rankings?${query}`;

    const results = await apiFetch<PagedResponse<TeamRankView>>({
      method: 'GET',
      url
    });

    dispatch(setPagedTeamRanks(results));
    return results;
  }, showLoader);
}

// Get my teams rank for current event, for company or across whole event
export type LoadOwnTeamRank = (
  companyOnly?: boolean,
  showLoader?: boolean
) => Promise<APIResult<TeamRankView>>;
export function loadOwnTeamRank(
  companyOnly = true,
  showLoader = true
): APIThunkResult<TeamRankView> {
  return apiRequest<TeamRankView>(async dispatch => {
    const url = companyOnly ? `${server}/team/me/rankings/company?` : `${server}/team/me/rankings?`;

    const result = await apiFetch<TeamRankView>({
      method: 'GET',
      url
    });

    dispatch(setMyTeamRank(result));
    return result;
  }, showLoader);
}

export type LoadParticipantRankings = (
  filter: PaginationFilter,
  companyOnly?: boolean,
  showLoader?: boolean
) => Promise<APIResult<PagedResponse<ParticipantRankView>>>;
export function loadParticipantRankings(
  filter: PaginationFilter,
  companyOnly = true,
  showLoader = true
): APIThunkResult<PagedResponse<ParticipantRankView>> {
  return apiRequest<PagedResponse<ParticipantRankView>>(async dispatch => {
    const query = objectToQueryString(filter);

    const url = companyOnly
      ? `${server}/participants/paged/rankings/company?${query}`
      : `${server}/participants/paged/rankings?${query}`;

    const results = await apiFetch<PagedResponse<ParticipantRankView>>({
      method: 'GET',
      url
    });
    dispatch(setIndividualRankings(results));
    return results;
  }, showLoader);
}

export type LoadOwnRanking = (
  companyOnly?: boolean,
  showLoader?: boolean
) => Promise<APIResult<ParticipantRankView>>;
export function loadOwnRanking(
  companyOnly = true,
  showLoader = true
): APIThunkResult<ParticipantRankView> {
  return apiRequest<ParticipantRankView>(async dispatch => {
    const url = companyOnly
      ? `${server}/participants/me/rankings/company`
      : `${server}/participants/me/rankings`;

    const results = await apiFetch<ParticipantRankView>({
      method: 'GET',
      url
    });
    dispatch(setOwnRanking(results));
    return results;
  }, showLoader);
}

export const checkUpdateLeaderBoards = async (
  updateRanks: string,
  dispatch: APIThunkDispatch,
  getState: () => ApplicationState
) => {
  const {
    waterCoolerLeaderBoardFilter,
    activeTab,
    filterByCompany,
    individualFilter,
    pagedTeamRankFilter
  } = getState().participantLeaderBoardState;
  if (updateRanks === '/WaterCooler') {
    dispatch(loadOwnRanking(false));
    dispatch(loadParticipantRankings(waterCoolerLeaderBoardFilter, false));
  } else if (updateRanks === '/leaderboard') {
    if (activeTab === 'Individual') {
      dispatch(loadOwnRanking(filterByCompany));
      dispatch(loadParticipantRankings(individualFilter, filterByCompany));
    } else {
      dispatch(loadPagedTeamRanks(pagedTeamRankFilter, filterByCompany));
      dispatch(loadOwnTeamRank(filterByCompany));
    }
  }
};
