export const theme = {
  backgrounds: {
    white: '#FFFFFF',
    black: '#000000',
    light: '#F9F9F9',
    dark: '#505A66',
    darkGrey: '#B2C5EA',
    grey: '#D5D9E1',
    lightGrey: '#E7E7E7',
    lightBlue: '#ECFAFF',
    disabled: '#898889',
    blueGrey: '#D5D9E1'
  },
  primary: {
    xlight: '#ADC6ED',
    light: '#44DEFF',
    main: '#00BCE2',
    red: '#FF4D85',
    turquoise: '#00DCD2',
    lightGreen: '#ccd138',
    mediumGreen: '#6BB649',
    green: '#78DB48',
    oceanGreen: '#3EC5B8',
    darkGreen: '#167771',
    paleGreen: '#E4FEFC',
    dark: '#21679A',
    yellow: '#FFDE43',
    purple: '#A888FF',
    orange: '#FFB578',
    white: '#ffffff',
    lightGrey: '#AEAEAE',
    lightBlue: '#4FB9DE',
    paleBlue: '#DBF5FF',
    connectionRed: '#FF8284',
    sleepBlue: '#99B8FF',
    mindfulnessOrange: '#FAA569',
    exerciseTurquoise: '#92E3AD'
  },
  secondary: {
    light: '',
    main: '',
    dark: ''
  },
  tertiary: {
    light: '',
    main: '',
    dark: ''
  },
  status: {
    warning: '#FFB982',
    danger: '#FF5E86',
    success: '#57D9D1',
    info: '#75DBFF'
  },
  social: {
    facebook: '',
    google: ''
  },
  // These gradients are wrong? Yellow isn't yellow, Orange isn't orange. Might also be a good idea to say what colour to what colour e.g redToBlue
  gradients: {
    blue: 'linear-gradient(130deg, rgba(68,222,255,1) 0%, rgba(169,168,255,1) 100%)',
    btw: 'linear-gradient(90deg, #AAE7FD 0%, #FFFFFF 100%)',
    green: 'linear-gradient(126deg, rgba(0,220,210,1) 41%, rgba(220,241,107,1) 100%)',
    yellow: 'linear-gradient(90deg, #57D9D1 0%, #E0F07E 100%)',
    yellowToGreen: 'linear-gradient(90deg, #fdc563 0%, #cbf88c 100%)',
    red: 'linear-gradient(135deg, rgba(255,94,134,1) 0%, rgba(255,185,130,1) 100%);',
    orange: 'linear-gradient(112deg, rgba(255,222,67,1) 0%, rgba(191,250,124,1) 86%)',
    turquoise: 'linear-gradient(112deg, rgba(0,220,210,1) 19%, rgba(220,241,107,1) 98%)',
    ltBlue: 'linear-gradient(135deg, rgba(202,239,255,1) 0%, rgba(217,221,252,1) 100%)',
    darkBlue: 'linear-gradient(180deg, rgba(70,201,245,1) 0%, rgba(35,167,250,1) 50%)',
    blueToPurple: 'linear-gradient(135deg, rgba(117,219,255,1) 0%, rgba(169,168,255,1) 100%)',
    purple: 'linear-gradient(90deg, rgba(168,131,255,1) 0%, rgba(209,203,255,1) 100%)'
  },
  opacity: {
    quarter: 0.25,
    half: 0.5,
    threeQuarter: 0.75
  },
  borders: {
    xlight: '#E7E7E7',
    light: '#D8D8D8',
    main: '',
    dark: '#333333',
    input: {
      default: '1px solid #CDDCF4',
      focused: '1px solid #44DEFF',
      error: '1px solid #FF5E86'
    }
  },
  borderRadius: {
    xsm: 2,
    sm: 4,
    md: 8,
    lg: 14,
    half: '50%',
    rounded: '25% / 50%'
  },
  fontFamily: {
    heading: "'Sarabun', 'sans serif'",
    body: "'Sarabun', 'sans serif'"
  },
  fonts: {
    body: 1.6,
    heading: 2.5,
    subBody: 1.2,
    subHeading: 2,
    input: 0.8,
    smButton: 0.9
  },
  fontWeights: {
    light: '300',
    regular: '400',
    semibold: '500',
    lightBold: '600',
    bold: '700',
    black: '800'
  },
  fontScale: {
    xsm: 'calc(0.2em + 0.5vw)',
    sm: 'calc(0.4em + 0.5vw)',
    md: 'calc(0.8em + 0.5vw)',
    lg: 'calc(0.8em + 1vw)',
    xlg: 'calc(2em + 0.5vw)'
  },
  shadows: {
    lighter: '1px 1px 12px 0px rgba(0,0,0,0.10)',
    light: '1px 1px 12px 0px rgba(0,0,0,0.15)',
    medium: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
    heavy: '0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)',
    heavier: '0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22)'
  },
  shadowsInvert: {
    // This is used to hide shadows below an element to blend them (see tabs as an example)
    lighter: '1px 8px 12px 0px white',
    light: '1px 8px 12px 0px white',
    medium: '0 16px 20px white, 0 12px 6px white',
    heavy: '0 20px 28px white, 0 16px 10px white',
    heavier: '0 25px 38px white, 0 21px 12px white'
  },
  device: {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2560
  },
  pad: {
    xxsm: 2,
    xsm: 2,
    sm: 4,
    md: 6,
    lg: 10,
    xlg: 14
  },
  padding: 12
};

export const datePickerTheme = {
  breakpoints: ['32em', '48em', '64em'],
  reactDatepicker: {
    dateSingleDatepickerWrapperLeft: 0,
    dateSingleDatepickerWrapperRight: 'unset',
    dateSingleInputPadding: `0px ${theme.padding}px`,
    inputFontWeight: 500,
    inputColor: '#333333',
    inputFontSize: theme.fonts.subBody + 'rem',
    inputLabelBorder: theme.borders.input.default,
    inputLabelBorderRadius: theme.borderRadius.sm,
    inputCalendarWrapperLeft: 'unset',
    inputCalendarWrapperRight: '8px',
    inputCalendarWrapperTop: '17px',
    dayBorderColor: theme.primary.light,
    daySize: [30, 36],
    fontFamily: 'system-ui, -apple-system',
    datepickerPadding: '2rem',
    colors: {
      accessibility: theme.primary.light,
      selectedDay: 'red',
      selectedDayHover: theme.primary.light,
      primaryColor: theme.primary.light
    },
    datepickerZIndex: 99,
    datepickerCloseWrapperMargin: '2px',
    datepickerResetDatesWrapperMargin: '14px 0 0 auto',
    datepickerMonthsWrapperMargin: '5rem 0 0 0',
    datepickerCloseWrapperTop: '2rem',
    datepickerCloseWrapperRight: '2rem'
  }
};

export type DPTheme = typeof datePickerTheme;
export type Theme = typeof theme;

export default theme;
