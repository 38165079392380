import React, { FC } from 'react';
import { FormikForm, Team, ParticipantListView, PendingParticipant } from '../../../../lib/Types';
import { TeamSchema } from '../../validators/teamSchema';
import TeamForm from '../TeamForm/TeamForm';
import LoadingIndicator from '../../../../components/UI/LoadingIndicator';

interface EditTeamProps extends FormikForm<TeamSchema> {
  resendInvitationEmail: (email: string) => void;
  approveTeam: (teamId: number) => any;
  getTeam: (teamId: number) => any;
  setTeam: (team: Team) => any;
  loadParticipant: (participantId: string) => void;
  loadPendingParticipant: (data: PendingParticipant) => void;
  removeRegistration: (team: Team, userId: string) => void;
  setAddingTeamMember: (addingTeamMember: boolean) => any;
  deletePendingParticipant: (teamId: number, email: string) => any;
  setDeleting: (deletingParticipant: boolean) => any;
  team: Team;
  participant: ParticipantListView;
  pendingParticipant: PendingParticipant;
  individualParticipants: ParticipantListView[];
  deletingParticipant: boolean;
  loading: boolean;
  adding: boolean;
  onCancel: () => void;
  onReplaceCancel: () => void;
}

const EditTeam: FC<EditTeamProps> = ({ loading, ...props }) => (
  <>{loading ? <LoadingIndicator /> : <TeamForm {...props} loading={loading} />}</>
);

export default EditTeam;
