import { ReportType } from '../../../Types';

export const SET_REPORT_TYPES = 'SET_REPORT_TYPES';

export interface ReportState {
  reportTypes: ReportType[];
}

export interface SetReportTypesAction {
  type: typeof SET_REPORT_TYPES;
  reportTypes: ReportType[];
}

export type ReportActionTypes = SetReportTypesAction;
