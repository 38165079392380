import React, { FC } from 'react';
import { withFormik, FormikProps } from 'formik';
import TermsAndConditionsForm from './TermsAndConditionsForm';
import validationSchema, { TermsAndConditions } from '../validators/termsAndConditionsSchema';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../lib/Store';
import { acceptTermsAndConditions } from '../../../lib/Api/User';
import { withRouter } from 'react-router';

interface TermsAndConditionsFormContainerProps {
  apiLoading: boolean;
  acceptTermsAndConditions: (accepted: boolean) => any;
  history: any;
  location: any;
  match: any;
}

interface TermsAndConditionsFormState {
  accepted: boolean;
}

const formikEnhancer = withFormik<
  TermsAndConditionsFormContainerProps,
  TermsAndConditionsFormState
>({
  mapPropsToValues: () => ({
    accepted: false
  }),
  validationSchema,
  handleSubmit: async ({ accepted }: TermsAndConditions, { props, setErrors }): Promise<void> => {
    const { error } = await props.acceptTermsAndConditions(accepted);
    if (error) setErrors({ [error.context]: error.message });
    else props.history.replace('/');
  }
});

const TermsAndConditionsFormContainer: FC<TermsAndConditionsFormContainerProps &
  FormikProps<TermsAndConditionsFormState>> = ({ apiLoading, ...props }) => {
  return <TermsAndConditionsForm {...props} disabled={apiLoading} />;
};

const mapStateToProps = ({ loadingState }: ApplicationState) => ({
  apiLoading: loadingState.apiCallsInProgress > 0
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      acceptTermsAndConditions: (accepted: boolean) => acceptTermsAndConditions(accepted)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(formikEnhancer(TermsAndConditionsFormContainer)));
