import achievementPlaceholder from './achievement-placeholder.svg';
import achievementPlaceholderPink from './achievement-placeholder-pink.svg';
import alert from './alert.svg';
import arrow from './arrow.svg';
import arrowUp from './arrow-up.svg';
import backArrow from './back-arrow.svg';
import bargraph from './bargraph.svg';
import blackForwardArrow from './black-forward-arrow.svg';
import browser from './browserwindow.svg';
import browserWindow from './browserwindow.svg';
import caretDown from './caret-down.svg';
import caretUp from './caret-up.svg';
import check from './check.svg';
import checkPink from './check-pink.svg';
import close from './close.svg';
import closeWhite from './close-white.svg';
import cog from './cogwheel.svg';
import cogwheel from './cogwheel.svg';
import collapseArrow from './collapse-arrow.svg';
import commentFilled from './comment-filled.svg';
import commentOutline from './comment-outline.svg';
import companyPlaceholder from './company-placeholder.svg';
import connections from './connections.svg';
import dashboard from './dashboard.svg';
import invoices from './invoices.svg';
import dropdownArrow from './dropdown-arrow.svg';
import emptyGuy from './empty-guy.svg';
import eventGuy from './event-guy.svg';
import eye from './eye.svg';
import eyeAlt from './eyeAlt.svg';
import hash from './hash.svg';
import hashIcon from './hash-icon.svg';
import heartFilled from './heart-filled.svg';
import heartOutline from './heart-outline.svg';
import icFlag from './ic-flag.svg';
import icTick from './ic-tick.svg';
import locations from './locations.svg';
import logoSmall from './logo-small.svg';
import logout from './logout.svg';
import mail from './mail.svg';
import notificationBell from './notification-bell.svg';
import pencil from './pencil.svg';
import pencilWhite from './pencil-white.svg';
import pin from './pin.svg';
import plus from './plus.svg';
import plusBlue from './plus-blue.svg';
import postConnection from './post-connection.svg';
import postExercise from './post-exercise.svg';
import postGratitude from './post-gratitude.svg';
import postLaugh from './post-laugh.svg';
import postMindfulness from './post-mindfulness.svg';
import postNutrition from './post-nutrition.svg';
import postSleep from './post-sleep.svg';
import profilePlaceholder from './profile-placeholder.svg';
import radar from './radar.svg';
import radioOff from './radio-off.svg';
import radioOn from './radio-on.svg';
import registrationIcon from './registration-icon.svg';
import remove from './remove.svg';
import removeIcon from './remove-icon.svg';
import signal from './signal.svg';
import stopwatch from './stopwatch.svg';
import team from './team.svg';
import teamIcon from './team-icon.svg';
import teamLeaderboardIcon from './team-leaderboard-icon.svg';
import threeDots from './3dots.svg';
import thumbsUp from './thumbsup.svg';
import tickGreen from './tick-green.svg';
import trashCan from './trashcan.svg';
import triangle from './triange.svg';
import unapprovedTeamIcon from './unapproved-team-icon.svg';
import users from './users.svg';
import withoutTeamIcon from './without-team-icon.svg';
import commentImage from './comment-image.svg';
import commentVideo from './comment-video.svg';
import noTeam from './no-team.svg';
import teamIc from './team-ic.svg';
import cursor from './cursor.svg';
import unapproved from './unapproved.svg';
import addParticipantIcon from './add-participant.svg';
import replaceParticipantIcon from './replace-participant.svg';
import downCarat from './down-carat.svg';
import plusOrg from './plus-org.svg';
import iconDropdown from './icondropdown.svg';
import achievement3DaysCaffineFree from './achievements/achievement-3-days-caffine-free.svg';
import achievement10kDay from './achievements/achievement-10k-day.svg';
import achievementAbsOfSteel from './achievements/achievement-abs-of-steel.svg';
import achievementActiveHousehold from './achievements/achievement-active-household.svg';
import achievementAppreciateAColleague from './achievements/achievement-appreciate-a-colleague.svg';
import achievementAstroBoots from './achievements/achievement-astro-boots.svg';
import achievementBallingShoes from './achievements/achievement-balling-shoes.svg';
import achievementBitterNotSweet from './achievements/achievement-bitter-not-sweet.svg';
import achievementBlastFromThePast from './achievements/achievement-blast-from-the-past.svg';
import achievementBodyScan from './achievements/achievement-body-scan.svg';
import achievementBookWorm from './achievements/achievement-book-worm.svg';
import achievementBronzeRunner from './achievements/achievement-bronze-runner.svg';
import achievementBronzeSlipper from './achievements/achievement-bronze-slipper.svg';
import achievementColleaguesVideoHangout from './achievements/achievement-colleagues-video-hangout.svg';
import achievementDanceMonkey from './achievements/achievement-dance-monkey.svg';
import achievementDayDreamer from './achievements/achievement-day-dreamer.svg';
import achievementDigitalDinnerDate from './achievements/achievement-digital-dinner-date.svg';
import achievementElasticAdventurer from './achievements/achievement-elastic-adventurer.svg';
import achievementEnergiserBunny from './achievements/achievement-energiser-bunny.svg';
import achievementGameMaster from './achievements/achievement-game-master.svg';
import achievementGoenWalkAbout from './achievements/achievement-goen-walk-about.svg';
import achievementGoldenSocks from './achievements/achievement-golden-socks.svg';
import achievementGratitudeList from './achievements/achievement-gratitude-list.svg';
import achievementGrinnersAreWinners from './achievements/achievement-grinners-are-winners.svg';
import achievementHappyCamper from './achievements/achievement-happy-camper.svg';
import achievementKidCurrency from './achievements/achievement-kid-currency.svg';
import achievementMasterChef from './achievements/achievement-master-chef.svg';
import achievementNinjaShoes from './achievements/achievement-ninja-shoes.svg';
import achievementOfficeClown from './achievements/achievement-office-clown.svg';
import achievementOscarNominee from './achievements/achievement-oscar-nominee.svg';
import achievementPenPal from './achievements/achievement-pen-pal.svg';
import achievementPlankingChallenge from './achievements/achievement-planking-challenge.svg';
import achievementPostExerciseDemo from './achievements/achievement-post-exercise-demo.svg';
import achievementRedRubySlippers from './achievements/achievement-red-ruby-slippers.svg';
import achievementRocketBoots from './achievements/achievement-rocket-boots.svg';
import achievementSecretSuperpower from './achievements/achievement-secret-superpower.svg';
import achievementSee10Do10Share10 from './achievements/achievement-see-10-do-10-share-10.svg';
import achievementShoeOfTheWeek from './achievements/achievement-shoe-of-the-week.svg';
import achievementShowOff from './achievements/achievement-show-off.svg';
import achievementShowSomeNeighbourlyLove from './achievements/achievement-show-some-neighbourly-love.svg';
import achievementSilderShoe from './achievements/achievement-silder-shoe.svg';
import achievementSnackAttack from './achievements/achievement-snack-attack.svg';
import achievementSugarFree from './achievements/achievement-sugar-free.svg';
import achievementTrackingHero from './achievements/achievement-tracking-hero.svg';
import achievementUltimateAce from './achievements/achievement-ultimate-ace.svg';
import achievementWalkRunRide from './achievements/achievement-walk-run-ride.svg';
import achievementWorkBuddy from './achievements/achievement-work-buddy.svg';
import cogs from './cogs.svg';
import hashBubble from './hash-bubble.svg';
import connectBubble from './connect-bubble.svg';
import arrowNext from './arrow-next.svg';
import setting from './setting.svg';
import fitbitBtn from './fitbit.svg';
import closeBlue from './close-blue.svg';
import fitbitLogoText from './fitbit-logo-text.svg';
import garminLogoText from './garmin-logo-text.svg';
import garminLogoBadge from './garmin-logo-badge.svg';
import googleFitIcon from './GF-icon.svg';
import googleFitLogo from './GF-logo.svg';
import googleFitLogoBG from './GF-logo-bg.svg';
import googleFitLogoBadge from './GF-logo-badge.svg';
import removeCircle from './remove-circle.svg';
import reports from './reports.svg';
import clockIcon from './clock-icon.svg';
import audioFileIcon from './audioFileIcon.svg';
import movementGraphic from './movement-graphic.svg';
import pumpingIron from './pumping-iron.svg';
import connectionBadge from './connection-badge.svg';
import gratitudeBadge from './gratitude-badge.svg';
import exerciseBadge from './exercise-badge.svg';
import laughterBadge from './laughter-badge.svg';
import sleepBadge from './sleep-badge.svg';
import nutritionBadge from './nutrition-badge.svg';
import mindfulnessBadge from './mindfulness-badge.svg';
import yogaGraphic from './yoga-graphic.svg';
import rightIconArrow from './right-icon-arrow.svg';
import rightIconArrowDisabled from './right-icon-arrow-disabled.svg';
import socialsGraphic from './socials-graphic.svg';
import walkingDogGraphic from './walking-dog-graphic.svg';
import trophyIcon from './trophy-icon.svg';
import lockIconWithCircle from './lock-icon-with-circle.svg';
import bluePlus from './blue-plus.svg';
import menuGraphic from './menu-graphic.svg';
import calendarIcon from './calendar.svg';
import searchIcon from './search.svg';

export {
  fitbitBtn,
  fitbitLogoText,
  garminLogoText,
  garminLogoBadge,
  closeBlue,
  setting,
  arrowNext,
  hashBubble,
  connectBubble,
  cogs,
  noTeam,
  cursor,
  unapproved,
  teamIc,
  achievementPlaceholder,
  achievementPlaceholderPink,
  alert,
  arrow,
  arrowUp,
  backArrow,
  bargraph,
  blackForwardArrow,
  browser,
  browserWindow,
  caretDown,
  caretUp,
  check,
  checkPink,
  close,
  closeWhite,
  cog,
  cogwheel,
  collapseArrow,
  commentFilled,
  commentOutline,
  companyPlaceholder,
  connections,
  dashboard,
  invoices,
  dropdownArrow,
  emptyGuy,
  eventGuy,
  eye,
  eyeAlt,
  hash,
  hashIcon,
  heartFilled,
  heartOutline,
  icFlag,
  icTick,
  locations,
  logoSmall,
  logout,
  mail,
  notificationBell,
  pencil,
  pencilWhite,
  pin,
  plus,
  plusBlue,
  postConnection,
  postExercise,
  postGratitude,
  postLaugh,
  postMindfulness,
  postNutrition,
  postSleep,
  profilePlaceholder,
  radar,
  radioOff,
  radioOn,
  registrationIcon,
  remove,
  removeIcon,
  signal,
  stopwatch,
  team,
  teamIcon,
  teamLeaderboardIcon,
  threeDots,
  thumbsUp,
  tickGreen,
  trashCan,
  triangle,
  unapprovedTeamIcon,
  users,
  withoutTeamIcon,
  commentImage,
  commentVideo,
  addParticipantIcon,
  replaceParticipantIcon,
  iconDropdown,
  achievement3DaysCaffineFree,
  achievement10kDay,
  achievementAbsOfSteel,
  achievementActiveHousehold,
  achievementAppreciateAColleague,
  achievementAstroBoots,
  achievementBallingShoes,
  achievementBitterNotSweet,
  achievementBlastFromThePast,
  achievementBodyScan,
  achievementBookWorm,
  achievementBronzeRunner,
  achievementBronzeSlipper,
  achievementColleaguesVideoHangout,
  achievementDanceMonkey,
  achievementDayDreamer,
  achievementDigitalDinnerDate,
  achievementElasticAdventurer,
  achievementEnergiserBunny,
  achievementGameMaster,
  achievementGoenWalkAbout,
  achievementGoldenSocks,
  achievementGratitudeList,
  achievementGrinnersAreWinners,
  achievementHappyCamper,
  achievementKidCurrency,
  achievementMasterChef,
  achievementNinjaShoes,
  achievementOfficeClown,
  achievementOscarNominee,
  achievementPenPal,
  achievementPlankingChallenge,
  achievementPostExerciseDemo,
  achievementRedRubySlippers,
  achievementRocketBoots,
  achievementSecretSuperpower,
  achievementSee10Do10Share10,
  achievementShoeOfTheWeek,
  achievementShowOff,
  achievementShowSomeNeighbourlyLove,
  achievementSilderShoe,
  achievementSnackAttack,
  achievementSugarFree,
  achievementTrackingHero,
  achievementUltimateAce,
  achievementWalkRunRide,
  achievementWorkBuddy,
  downCarat,
  plusOrg,
  googleFitIcon,
  googleFitLogo,
  googleFitLogoBG,
  googleFitLogoBadge,
  removeCircle,
  reports,
  clockIcon,
  audioFileIcon,
  movementGraphic,
  pumpingIron,
  connectionBadge,
  gratitudeBadge,
  exerciseBadge,
  laughterBadge,
  sleepBadge,
  nutritionBadge,
  mindfulnessBadge,
  yogaGraphic,
  rightIconArrow,
  rightIconArrowDisabled,
  socialsGraphic,
  walkingDogGraphic,
  trophyIcon,
  lockIconWithCircle,
  bluePlus,
  menuGraphic,
  calendarIcon,
  searchIcon
};

export const StatusIcons = {
  success: check,
  danger: closeWhite
};
