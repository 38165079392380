import React, { MouseEvent } from 'react';

import LoadingIndicator from '../LoadingIndicator';
import { StyledButton, LoaderWrapper } from './Styles';

import { InputVariant } from '../../../lib/Types';

const Button: React.FC<ButtonProps> = ({
  loading,
  children,
  loaderColour = 'white',
  loaderSize = '1.2rem',
  ...props
}) => (
  <StyledButton {...props}>
    {children}
    {loading && (
      <LoaderWrapper background={props.background} variant={props.variant}>
        <LoadingIndicator size={loaderSize} color={loaderColour} />
      </LoaderWrapper>
    )}
  </StyledButton>
);

export interface ButtonProps {
  variant: InputVariant;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  margin?: any;
  fontWeight?: string;
  padding?: string;
  color?: string;
  lineHeight?: string;
  height?: string;
  buttonFlex?: number;
  type?: 'button' | 'submit';
  value?: string;
  disabled?: boolean;
  loading?: boolean;
  background?: string;
  fontStyle?: string;
  loaderColour?: string;
  loaderSize?: string;
  scaleHover?: boolean;
}

export default Button;
