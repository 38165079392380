import React, { FC } from 'react';
import { Group, Heading, Body } from '../Styles';
import { Input } from '../../../../../components/UI';
import theme from '../../../../../theme';
import { FormikFormComponent } from '../../../../../lib/Types';
import { EventSchema } from '../../../validators/eventSchema';

const DailyStepThreshold: FC<FormikFormComponent<EventSchema>> = ({
  values,
  errors,
  handleChange
}) => (
  <Group>
    <Heading>Daily Step Threshold</Heading>
    <Body>
      This will flag any activities that equate to an amount of steps greater than or equal to this
      value.
    </Body>
    <Input
      id="stepApprovalThreshold"
      name="stepApprovalThreshold"
      value={values.stepApprovalThreshold}
      error={errors.stepApprovalThreshold}
      label="Daily Step Threshold"
      type="stepApprovalThreshold"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
    <Heading>Event Average Steps</Heading>
    <Body>
      This will value will be used as the average steps inserted for any activity records that have
      breached the Daily Step Threshold and been rejected twice.
    </Body>
    <Input
      id="averageSteps"
      name="averageSteps"
      value={values.averageSteps}
      error={errors.averageSteps}
      label="Average Steps"
      type="averageSteps"
      margin={`0px 0px ${theme.padding * 1.5}px 0px`}
      onChange={handleChange}
    />
  </Group>
);

export default DailyStepThreshold;
