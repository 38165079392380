import React, { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useSelector } from 'react-redux';

import { getPagedAdmins, GetPagedAdmins } from '../../lib/Api/companyAdmin/Company';
import { capitaliseUserFullName } from '../../lib/Utils';

import CreateOrEditCompanyAdmin from './CreateOrEditCompanyAdmin';
import { PageWrapper } from '../../components/UI';
import ToolbarButton from '../../components/UI/Button/ToolbarButton';
import PaginatedTable from '../../components/UI/PaginatedTable';
import PaginatedTableSearch from '../../components/UI/PaginatedTableSearch';
import { Paper, NoRecordsFound } from '../../components/UI';
import LoadingIndicator from '../../components/UI/LoadingIndicator';

import { eventGuy, plus } from '../../assets/icons/index';

import { DEFAULT_PIC } from '../../CONSTANTS';
import {
  CompanyAdminListView,
  OrderDirection,
  PagedResponse,
  PaginationFilter,
  TableHeaderItem
} from '../../lib/Types';
import { defaultCompanyAdminFilter } from '../../lib/Store/contexts/companyAdmin/company/types';
import { ApplicationState } from '../../lib/Store';

export interface CompaniesProps {
  onGetAdmins: GetPagedAdmins;
}

const tableHeaderItem: TableHeaderItem[] = [
  { display: '', mapProp: 'profileImage' },
  { display: 'Email', mapProp: 'email' },
  { display: 'Name', mapProp: 'firstName' },
  { display: 'Contact', mapProp: 'isContact', mapBool: { true: 'True', false: '' } },
  { display: 'Timezone', mapProp: 'timezone' }
];

const Companies: React.FC<CompaniesProps> = ({ onGetAdmins }) => {
  const { loading, user, company } = useSelector((state: ApplicationState) => ({
    user: state.userState.userData,
    company: state.userState.userCompany,
    loading: state.loadingState.apiCallsInProgress > 0
  }));
  const [filter, setFilter] = useState<PaginationFilter>(defaultCompanyAdminFilter);
  const [admins, setAdmins] = useState<PagedResponse<CompanyAdminListView>>({
    results: null,
    count: 0
  });
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState<CompanyAdminListView>();

  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    setFilter({ ...filter, orderColumn, orderDirection });
  const setPage = (page: number) => setFilter({ ...filter, page });
  const setKeyword = (keyword: string) => setFilter({ ...filter, keyword });

  const getAdmins = async () => {
    const { data } = await onGetAdmins(filter);
    if (data) {
      // filter out self
      setAdmins({
        results: data.results.filter(a => a.userId !== user.userId),
        count: data.count - 1
      });
    }
  };

  const handleReset = async () => {
    setCreating(false);
    setEditing(null);
    await getAdmins();
  };

  // TODO need a new table library where we dynamically create cell content.
  const adminData =
    admins.results &&
    admins.results
      .map(a => (a.profileImage ? a : { ...a, profileImage: DEFAULT_PIC }))
      .map(a => {
        return {
          ...a,
          firstName: capitaliseUserFullName({ firstName: a.firstName, lastName: a.lastName })
        };
      });

  useEffect(() => {
    (async () => await getAdmins())();
  }, []);

  const modalOpener = (
    <ToolbarButton variant="primary" icon={plus} onClick={() => setCreating(true)}>
      Create an Admin
    </ToolbarButton>
  );

  return (
    <PageWrapper event={false} company={false} formOpener={modalOpener}>
      {creating || editing ? (
        <Paper>
          <CreateOrEditCompanyAdmin
            companyAdmin={editing}
            company={company}
            onSuccess={handleReset}
            onCancel={handleReset}
          />
        </Paper>
      ) : (
        <>
          <PaginatedTableSearch onKeywordFilter={setKeyword} />
          {admins.count ? (
            <PaginatedTable
              idProp="companyAdminId"
              headers={tableHeaderItem}
              filter={filter}
              data={{ ...admins, results: adminData }}
              loading={!admins}
              onSort={setSort}
              onSetPage={setPage}
              onEdit={(id: number) => setEditing(admins.results.find(a => a.companyAdminId === id))}
              actions={[{ type: 'edit', show: true }]}
            />
          ) : loading ? (
            <LoadingIndicator />
          ) : (
            <NoRecordsFound icon={eventGuy} message="There are currently no other admins." />
          )}
        </>
      )}
    </PageWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onGetAdmins: (filter: PaginationFilter) => getPagedAdmins(filter) as any
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Companies);
