import React, { FC, ChangeEvent, useState, ReactElement } from 'react';
import Dropzone from 'react-dropzone';
import { FormikErrors } from 'formik';
import { values } from 'd3';
import {
  Container,
  FormControl,
  LoginButton,
  SkipLink,
  PicturePlaceholder,
  PictureTitle,
  PictureDescription,
  UploadButton,
  PictureText,
  PictureWrapper,
  Picture,
  PictureHelper,
  PictureDefault,
  PictureInputContainer
} from './Styles';
import { Alert } from '../../../../components/UI';
import { PictureFormState, DisplayState } from './PictureFormContainer';
import { cloudUpload } from '../../../../assets/img/index';
import { DarkBlueBodyM, DarkBlueHeaderL, RedText } from '../../../../Themes';
import { FilePreview } from '../../../../lib/Types';
import { MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_LABEL } from '../../../../CONSTANTS';

const initState = {
  localFile: null
};

export interface FormErrors {
  image?: FormikErrors<File> | FormikErrors<Blob>;
  internal?: string;
}

export interface PictureFormProps {
  image?: FilePreview;
  formErrors: FormErrors;
  disabled: boolean;
  setValues: (values: PictureFormState, shouldValidate?: boolean | undefined) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  updateContainerState: (image: FilePreview) => void;
  setDisplayState: (displayState: DisplayState) => void;
  skipUpload: () => void;
  setOriginalImage: (image: FilePreview) => void;
  setFieldError: (field: string, message: string) => void;
  setErrors: (errors: FormikErrors<PictureFormState>) => void;
}

const PictureForm: FC<PictureFormProps> = ({
  image,
  formErrors,
  onSubmit,
  setValues,
  updateContainerState,
  setDisplayState,
  skipUpload,
  setOriginalImage,
  setFieldError,
  setErrors
}) => {
  const [localFile, setLocalFile] = useState<FilePreview>(image || initState.localFile);

  const getThumbs = () => {
    return (
      <PictureWrapper key={!localFile ? cloudUpload : localFile.name}>
        {!localFile ? <PictureDefault src={cloudUpload} /> : <Picture src={localFile.preview} />}
      </PictureWrapper>
    );
  };

  const editUploadedImage = (): void => {
    updateContainerState(localFile);
    setDisplayState(DisplayState.CROP);
    // TODO WIP, move this up to be part of formik's submission process
    // TODO Actually read userId from local session
    // React Dropzone supports multi-file upload, so localFiles will always be an array
    // const uploadTask = uploadProfilePicture(localFiles[0], 'Rh1KwdRzv4NOYZ5WWG9yjC5Wc733');
    // uploadTask
    //   .then(snapshot => {
    //     console.warn('Snapshot', snapshot, 'from', localFiles);
    //     // TODO If valid snapshot, continue the registration process
    //     debugger;
    //   })
    //   .catch(err => err);
  };
  return (
    <Container>
      {formErrors.internal ? <Alert message={formErrors.internal} /> : null}
      <DarkBlueHeaderL>Add a photo, so participants can recognize you easily</DarkBlueHeaderL>
      <DarkBlueBodyM style={{ marginBottom: '4rem' }}>
        This image will appear on your profile and on your social posts.
      </DarkBlueBodyM>
      <form onSubmit={onSubmit}>
        <Dropzone
          accept="image/jpeg,image/png"
          maxSize={MAX_IMAGE_SIZE}
          onDrop={([acceptedFile]: FilePreview[]): void => {
            setErrors({});
            //const imageFile = { ...acceptedFile, preview: URL.createObjectURL(acceptedFile) };
            if (!acceptedFile)
              return setFieldError('image', `Image must be JPG/PNG <${MAX_IMAGE_SIZE_LABEL}`); // throw getImageSizeError();
            const imageFile = acceptedFile;
            imageFile.preview = URL.createObjectURL(acceptedFile);

            setOriginalImage(imageFile);
            setLocalFile(imageFile);

            // Possibly redundant - called in parent too?
            setValues({
              ...values,
              image: imageFile
            });
          }}
        >
          {({ getRootProps, getInputProps }): ReactElement => (
            <PictureInputContainer>
              <PicturePlaceholder {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                  <PictureHelper />
                  {getThumbs()}
                </div>
              </PicturePlaceholder>
              <PictureText>
                <PictureTitle>Upload Your Photo</PictureTitle>
                <PictureDescription>
                  Files should be in JPG or PNG format (&lt;10MB)
                </PictureDescription>
                {formErrors.image ? <RedText>Image is too big</RedText> : ''}
                {localFile && (
                  <UploadButton
                    disabled={!localFile}
                    variant="primary"
                    type="button"
                    onClick={editUploadedImage}
                  >
                    {/* Note: This upload button is not actually used as an upload button. 
                    It actually triggers the "crop" tool for the image. 
                    So this button text should stay as "Edit" */}
                    Crop
                  </UploadButton>
                )}
              </PictureText>
            </PictureInputContainer>
          )}
        </Dropzone>
        <FormControl>
          <LoginButton variant="primary" type="submit">
            Done
          </LoginButton>
        </FormControl>
        <FormControl>
          <SkipLink onClick={skipUpload} type="cancel">
            Skip for now
          </SkipLink>
        </FormControl>
      </form>
    </Container>
  );
};

export default PictureForm;
