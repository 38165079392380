import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './yup.locale';
import { Provider as ReduxProvider } from 'react-redux';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'styled-components/macro';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import theme from './theme';
import configureStore from './lib/Store/configureStore';
import moment from 'moment';

dayjs.extend(advancedFormat);

export const store = configureStore();

// Sets all moment timestamps to read "1d ago" etc
moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: (number, withoutSuffix) => (withoutSuffix ? 'now' : 'a few seconds'),
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mth',
    MM: '%dmth',
    y: '1y',
    yy: '%dy'
  }
});

ReactDOM.render(
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </ReduxProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
