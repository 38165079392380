import React, { FC, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { connect, useSelector } from 'react-redux';
import * as QueryString from 'query-string';
import moment from 'moment-timezone';

import { isAfter } from '../../../lib/TimeUtils';

import SideBar from '../SideBar';
import PageContentHeader from './PageContentHeader';
import AddActivityCard from '../../UI/AddActivityCard';
import AddActivities from '../../UI/AddActivities';
import HoldingPage from '../HoldingPage';
import { LoaderContainer } from '../../../pages/Companies/Styles';
import LoadingIndicator from '../../../components/UI/LoadingIndicator';

import {
  ContentWrap,
  CopyrightText,
  FooterWrap,
  GlobalWrap,
  HeaderWrap,
  MainWrap,
  PageWrap,
  SideBarWrap
} from './Styles';

import {
  footerLogo,
  leaderboardHolding,
  leaderboardHoldingMob,
  noEventsGraphic
} from '../../../assets/img/index';
import { ApplicationState } from '../../../lib/Store';
import { RoleCode, IgnoreHoldingStateCondition, Company } from '../../../lib/Types';

interface EmployeePageWrapperProps extends RouteComponentProps {
  code: RoleCode;
  holdingPageTitle: string;
  ignoreHoldingState?: IgnoreHoldingStateCondition;
  fixHeight?: boolean;
  company?: Company;
}

const EmployeePageWrapper: FC<EmployeePageWrapperProps> = ({
  children,
  location,
  code,
  holdingPageTitle,
  ignoreHoldingState,
  fixHeight,
  company
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const { userEvent, userTeam, userIndividualRegistration, loading } = useSelector(
    ({ userState, participantTeamState, loadingState }: ApplicationState) => ({
      userEvent: userState.userEvent,
      userTeam: participantTeamState.team,
      userIndividualRegistration: participantTeamState.individualEventRegistration,
      loading: loadingState.apiCallsInProgress > 0
    })
  );

  const isBeforeEvent = !userEvent.eventId || isAfter(userEvent.startDate, moment().valueOf());

  useEffect(() => {
    const params = QueryString.parse(location.search);
    if (params.syncDevice) {
      setCollapsed(!params.syncDevice);
    }
  }, [location.search]);

  const checkBetween = (from: number, to: number) =>
    moment().isBefore(from) && moment().isAfter(to);

  const checkMissingActiveEvent = () =>
    userEvent.eventId && !userTeam && !userIndividualRegistration;

  const renderActivityCard = () => {
    // before event or no registration
    if (isBeforeEvent || checkMissingActiveEvent()) return <></>;

    return collapsed ? (
      <AddActivityCard onClick={() => setCollapsed(false)} />
    ) : (
      <AddActivities setCollapsed={setCollapsed} />
    );
  };

  const renderContents = () => {
    if (loading && userEvent === undefined) {
      return (
        <LoaderContainer>
          <LoadingIndicator />
        </LoaderContainer>
      );
    }

    // Check if we want to ignore holding state
    if (
      ignoreHoldingState === IgnoreHoldingStateCondition.ALWAYS ||
      (ignoreHoldingState === IgnoreHoldingStateCondition.REG_OPEN &&
        checkBetween(+userEvent.registrationsEndDate, +userEvent.registrationsStartDate))
    ) {
      return children;
    }

    // Check if we are able to register but haven't and aren't already
    // on the employee/team dashboards
    if (
      // Event data exists but no registrations
      checkMissingActiveEvent() &&
      // is current between even rego dates
      checkBetween(+userEvent.registrationsEndDate, +userEvent.registrationsStartDate) &&
      // our event has paid participants
      userEvent?.paidParticipants &&
      // we aren't already on the dashboard/team dashboard
      !location.pathname.includes('EmployeeDashboard') &&
      !location.pathname.includes('TeamDashboard')
    ) {
      return <Redirect to="/EmployeeDashboard" />;
    }

    const beforeRegStart = !isAfter(moment().valueOf(), userEvent.registrationsStartDate);
    const beforeEventStart = !isAfter(moment().valueOf(), userEvent.startDate);
    const afterEventStart = isAfter(moment().valueOf(), userEvent.startDate);
    const afterRegEnd = isAfter(moment().valueOf(), userEvent.registrationsEndDate);
    const afterEventEnd = isAfter(moment().valueOf(), userEvent.endDate);
    const afterGracePeriodEnds = isAfter(
      moment().valueOf(),
      moment(parseInt(userEvent.endDate))
        .add(userEvent.gracePeriod, 'days')
        .valueOf()
    );
    const hasNotRegistered = !userIndividualRegistration && !userTeam;

    const showNoEvents =
      !userEvent.startDate || checkMissingActiveEvent() || (afterEventEnd && afterGracePeriodEnds);

    if (
      !userEvent.eventId ||
      beforeRegStart ||
      (afterRegEnd && beforeEventStart && userTeam) ||
      (afterRegEnd && beforeEventStart && hasNotRegistered) ||
      (afterRegEnd && afterEventStart && hasNotRegistered) ||
      (afterEventEnd && afterGracePeriodEnds)
    ) {
      return (
        <HoldingPage
          noEvent={showNoEvents}
          pageTitle={holdingPageTitle}
          backgroundImage={showNoEvents ? noEventsGraphic : leaderboardHolding}
          backgroundImageMob={showNoEvents ? noEventsGraphic : leaderboardHoldingMob}
        />
      );
    }
    return children;
  };

  return (
    <GlobalWrap>
      <PageWrap>
        <SideBarWrap>
          <SideBar code={code} />
        </SideBarWrap>
        <MainWrap>
          <HeaderWrap>
            <PageContentHeader
              code={code}
              // only show image if Add Activity Card isn't showing
              companyImg={(isBeforeEvent || checkMissingActiveEvent()) && company.profileImage}
            />
            {renderActivityCard()}
          </HeaderWrap>
          {renderContents()}
          <ContentWrap />
        </MainWrap>
      </PageWrap>
      {!fixHeight && (
        <FooterWrap>
          <img width="70px" src={footerLogo} alt={''} />
          <CopyrightText>&copy; 2020 Moving Mindz Pty Ltd</CopyrightText>
        </FooterWrap>
      )}
    </GlobalWrap>
  );
};

export default connect<{}, {}, EmployeePageWrapperProps>((state: ApplicationState) => ({
  user: state.userState.userData,
  code: state.permissionsState.code,
  company: state.userState.userCompany
}))(withRouter(EmployeePageWrapper)) as any;
