import { CompanyDemographic, UserDemographic } from '../../../../Types';
import {
  SetCompanyDemographicsAction,
  SetUserDemographicsAction,
  SET_COMPANY_DEMOGRAPHICS,
  SET_USER_DEMOGRAPHICS
} from './types';

export const setCompanyDemographics = (
  companyDemographics: CompanyDemographic[]
): SetCompanyDemographicsAction => ({
  type: SET_COMPANY_DEMOGRAPHICS,
  companyDemographics
});

export const setUserDemographics = (
  userDemographics: UserDemographic[]
): SetUserDemographicsAction => ({
  type: SET_USER_DEMOGRAPHICS,
  userDemographics
});

export default { setCompanyDemographics, setUserDemographics };
