import React, { FC, useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { setCompaniesInEventFilter } from 'src/lib/Store/contexts/masterAdmin/event/actions';
import { markCompanyEventAssignmentPaid } from '../../../../lib/Api/masterAdmin/Event';
import { centsToDollars } from '../../../../lib/Utils';

import { PaginatedTable, NoRecordsFound, Button } from 'src/components/UI';

import { PageHeader } from '../CreateEvent/Styles';
import { PaymentStatus } from './Styles';

import {
  CompanyRegistrationData,
  OrderDirection,
  PagedResponse,
  PaginationFilter,
  TableHeaderItem,
  CompanyEventLinkStatus,
  APIThunkDispatch
} from '../../../../lib/Types';
import { eventGuy } from 'src/assets/icons';
import { theme } from '../../../../theme';

interface CompaniesInEventTableProps {
  companiesInEvent: PagedResponse<CompanyRegistrationData>;
  filter: PaginationFilter;
}

const paymentStatusDisplay = {
  [CompanyEventLinkStatus.Paid]: CompanyEventLinkStatus.Paid,
  [CompanyEventLinkStatus.PendingManual]: 'Mark As Paid',
  [CompanyEventLinkStatus.PendingStripe]: 'Unpaid',
  ['Partially Paid']: 'Partially Paid'
};

const CompaniesInEventTable: FC<CompaniesInEventTableProps> = ({ companiesInEvent, filter }) => {
  const dispatch: APIThunkDispatch = useDispatch();
  const { count } = companiesInEvent;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleMarkAsPaid = async (companyId: number) => {
    setIsSubmitting(true);
    await dispatch(markCompanyEventAssignmentPaid(companyId));
    setIsSubmitting(false);
  };

  const renderPaymentStatus = useCallback(
    (status: string, companyId: number) =>
      status === CompanyEventLinkStatus.PendingManual ? (
        <Button
          fontStyle="default"
          width="auto"
          fontWeight="regular"
          padding="0.5rem 1.5rem"
          loaderSize="0.8rem"
          color="#666666"
          background={theme.primary.yellow}
          variant="primary"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => handleMarkAsPaid(companyId)}
          scaleHover
        >
          Mark As Paid
        </Button>
      ) : (
        <PaymentStatus
          isPaid={status === CompanyEventLinkStatus.Paid}
          hasPending={status === 'Partially Paid'}
        >
          {paymentStatusDisplay[status]}
        </PaymentStatus>
      ),
    [CompanyEventLinkStatus, isSubmitting] // Force re-render if isSubmitting or CompanyEventLinkStatus changes
  );

  const headers: TableHeaderItem[] = [
    { display: 'Organisation', mapProp: 'name' },
    { display: 'Event Name', mapProp: 'eventName' },
    {
      display: 'Participants',
      mapProp: 'registrationCount',
      render: datum =>
        `${datum.registrationCount}/${datum.maxParticipants}${datum.paidParticipants !== datum.maxParticipants
          ? ` (${datum.maxParticipants - datum.paidParticipants} Pending Payment)`
          : ''
        }`
    },
    {
      display: 'Total',
      mapProp: 'paidParticipants',
      render: datum =>
        `${centsToDollars(datum.maxParticipants * datum.costPerParticipant)}${datum.paidParticipants !== datum.maxParticipants
          ? ` (${centsToDollars(
            (datum.maxParticipants - datum.paidParticipants) * datum.costPerParticipant
          )} Pending)`
          : ''
        }`
    },
    {
      display: 'Payment Type',
      mapProp: 'allowManualPayment',
      mapBool: { true: 'Manual Payment', false: 'Stripe Payment' }
    },
    {
      display: 'Status',
      mapProp: '',
      render: datum =>
        renderPaymentStatus(
          datum.paidParticipants < datum.maxParticipants &&
            datum.paidParticipants !== 0 &&
            datum.status !== CompanyEventLinkStatus.PendingManual
            ? 'Partially Paid'
            : datum.status,
          datum.companyId
        )
    }
  ];

  const setSort = (orderColumn: string, orderDirection: OrderDirection) =>
    dispatch(setCompaniesInEventFilter({ ...filter, orderColumn, orderDirection }));
  const setPage = (page: number) => dispatch(setCompaniesInEventFilter({ ...filter, page }));

  return (
    <>
      <PageHeader>Companies Registered for this Event</PageHeader>
      {count ? (
        <PaginatedTable
          idProp="companyId"
          headers={headers}
          data={companiesInEvent}
          loading={false}
          filter={filter}
          onSort={setSort}
          onSetPage={setPage}
          alignNumberedCellsCenter={false}
        />
      ) : (
        <NoRecordsFound icon={eventGuy} message="No companies registered for this event" />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(null, mapDispatchToProps)(CompaniesInEventTable);
