import styled from 'styled-components/macro';

export const Wrapper = styled.details`
  width: 26.5px;
  max-height: 20.5px;
`;

export const ImageContainer = styled.summary`
  cursor: pointer;
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }
  display: block;
  margin-top: 0.05rem;
`;

export const Menu = styled.div`
  cursor: pointer;
  width: 84px;
  height: auto;
  border: solid 1px #c0bec2;
  background: #ffffff;
  position: relative;
  left: -33px;
  top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  letter-spacing: -0.09285713px;
  box-shadow: 0 2px 11px 2px rgba(20, 4, 32, 0.2);
  z-index: 10;

  &::after {
    // Pseudoselector used to create top arrow
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    background: #fff;
    left: 50%;
    margin-left: -3px;
    top: -6px;
    border-top: 1px solid #c0bec2;
    border-left: 1px solid #c0bec2;
    border-top-left-radius: 2px;
    -webkit-transform: rotate(45deg); /* Saf3.1+ */
    -moz-transform: rotate(45deg); /* FF3.5/3.6 */
    -ms-transform: rotate(45deg); /* IE 9+ */
    -o-transform: rotate(45deg); /* Opera 10.5 */
    transform: rotate(45deg); /* Newer browsers (incl IE9) */
  }
`;

export const Option = styled.p`
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  &:hover {
    font-weight: bold;
  }
`;
