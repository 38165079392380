import React, { MouseEvent } from 'react';

import { StyledButton, ButtonIcon } from './Styles';

export interface ActionButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  icon?: string;
  backgroundColour?: string;
  border?: string;
  textColourOverride?: string;
  maxWidth?: string;
  disabled?: boolean;
  iconInverted?: boolean;
  darkText?: boolean;
  opaque?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  icon,
  iconInverted = false,
  children,
  ...props
}) => (
  <StyledButton {...props}>
    {icon && <ButtonIcon src={icon} inverted={iconInverted} noMargin={!children} />}
    {children}
  </StyledButton>
);

export default ActionButton;
