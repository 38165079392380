import React, { FC } from 'react';
import { Group } from '../Styles';
import { Checkbox } from '../../../../../components/UI';
import { FormikFormComponent } from '../../../../../lib/Types';
import { ParticipantSchema } from '../../../validators/participantSchema';

interface ActiveStatusProps extends FormikFormComponent<ParticipantSchema> {
  handleCheckboxChange: (name: string, value: any) => void;
}

const ActiveStatus: FC<ActiveStatusProps> = ({ values, handleCheckboxChange }) => (
  <Group>
    <Checkbox
      checked={values.active}
      label={'Active Status'}
      name={'active'}
      onChange={val => handleCheckboxChange('active', !values.active)}
    />
  </Group>
);

export default ActiveStatus;
