import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import moment from 'moment';

import { getMyDashboardData, GetMyDashboardData } from '../../lib/Api/participant/Dashboard';
import { isAfter } from '../../lib/TimeUtils';

import { EmployeePageWrapper } from '../../components/UI';
import Tabs from '../../components/UI/Tabs';

import { PageContent } from '../../Themes';
import { lockIconWithCircle } from '../../assets/icons';
import {
  StyledTrophyCabinet,
  Title,
  TitleCount,
  TitleText,
  AchievementsContainer,
  AchievementWrapper,
  Achievement,
  AchievementName,
  AchievementPoints,
  AchievementImageWrapper,
  LockIcon
} from './Styles';

import { PointsType } from '../../lib/Types';
import { ApplicationState } from '../../lib/Store';

interface TrophyCabinetProps {
  getMyDashboardData: GetMyDashboardData;
}

const TrophyCabinet: React.FC<TrophyCabinetProps> = ({ getMyDashboardData }) => {
  const tabs = ['Connection Rewards', 'Movement Rewards'];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const { dashBoardData, userEvent } = useSelector(
    ({ participantDashboardState, userState }: ApplicationState) => ({
      dashBoardData: participantDashboardState.dashBoardData,
      userEvent: userState.userEvent
    })
  );

  useEffect(() => {
    if (!userEvent.eventId || !isAfter(moment().valueOf(), userEvent.startDate)) return;
    (async () => await getMyDashboardData())();
  }, []);

  const summaryText = () => {
    if (!dashBoardData) return <TitleText>Loading...</TitleText>;
    const allConnection = dashBoardData.rewards.filter(
      reward => reward.type === PointsType.Connection
    );
    const allMovement = dashBoardData.rewards.filter(reward => reward.type === PointsType.Movement);
    const earnedConnection = allConnection.filter(reward => reward.earned);
    const earnedMovement = allMovement.filter(reward => reward.earned);

    return activeTab === 'Connection Rewards' ? (
      <TitleCount>
        {`${earnedConnection.length} of ${allConnection.length}`}
        <TitleText>{` Connection Reward${
          earnedConnection.length > 1 ? 's' : ''
        } Achieved`}</TitleText>
      </TitleCount>
    ) : (
      <TitleCount>
        {`${earnedMovement.length} of ${allMovement.length}`}
        <TitleText>{` Movement Reward${earnedMovement.length > 1 ? 's' : ''} Achieved`}</TitleText>
      </TitleCount>
    );
  };

  return (
    <EmployeePageWrapper holdingPageTitle="Trophy Cabinet">
      <PageContent>
        <Title>Your Trophy Cabinet</Title>
        <Tabs activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} />
        <StyledTrophyCabinet>
          {summaryText()}
          <AchievementsContainer>
            {dashBoardData &&
              dashBoardData.rewards.map(
                reward =>
                  ((activeTab === 'Connection Rewards' && reward.type === PointsType.Connection) ||
                    (activeTab === 'Movement Rewards' && reward.type === PointsType.Movement)) && (
                    <AchievementWrapper key={reward.name}>
                      <AchievementImageWrapper>
                        <Achievement src={reward.image} earned={reward.earned} />
                        {!reward.earned && <LockIcon src={lockIconWithCircle} />}
                      </AchievementImageWrapper>
                      <AchievementName>{reward.name}</AchievementName>
                      <AchievementPoints>{reward.points}pts</AchievementPoints>
                    </AchievementWrapper>
                  )
              )}
          </AchievementsContainer>
        </StyledTrophyCabinet>
      </PageContent>
    </EmployeePageWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMyDashboardData: getMyDashboardData as any
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(TrophyCabinet as any);
