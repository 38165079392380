import styled from 'styled-components/macro';

// used to give background opacity to the whole page
// TODO: Replace with svg mask for proper opacity.
export const PageWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 101;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 28vh;
  left: 16.5vw;
  z-index: 102;
`;

export const SpeechBubble = styled.img`
  width: 48%;
  border-radius: 10px;
  margin-top: -3%;
  margin-left: 6%;
  cursor: pointer;
`;

export const KoalaWrapper = styled.div`
  width: 25%;
  margin-top: -6.5%;
  margin-left: 28%;
`;
