import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ horizontalSiblings?: boolean; flex?: number }>`
  position: relative;
  flex: ${({ flex }) => (flex ? flex : 1)};
  margin-top: 0;

  & ~ & {
    ${({ horizontalSiblings }) =>
      horizontalSiblings ? `margin-left: 1rem;` : `margin-top: 1rem; margin-bottom: 1rem;`}
  }
`;

interface LabelProps {
  inputValue: string | number | undefined;
  valid: boolean;
  hide?: boolean;
}

export const Label = styled.label<LabelProps>`
  background-color: white;
  padding: 0 0.4rem;
  font-size: ${({ theme }) => theme.fonts.subBody + 0.2}rem;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  position: absolute;
  transition: all 0.1s;
  left: 1.5rem;
  cursor: text;
  display: ${({ hide, inputValue }) => (inputValue && hide ? 'none' : 'initial')};
  top: ${({ inputValue }) => (inputValue ? '-1rem' : '1.3rem')};
  color: ${({ inputValue, valid, theme }) =>
    !valid ? theme.status.danger : inputValue ? theme.primary.dark : theme.primary.xlight};
`;

export interface StyledInputProps {
  valid: boolean;
  margin?: string;
}

export const StyledTextArea = styled.textarea<StyledInputProps>`
  color: ${({ theme }) => theme.primary.dark};
  border: ${({ theme, valid }) =>
    valid ? theme.borders.input.default : theme.borders.input.error};
  border-radius: 0.35rem;
  padding: 1rem 0.3rem 0.3rem 1.5rem;
  font-size: ${({ theme }) => theme.fonts.subBody + 0.2}rem;
  height: 15rem;
  margin: ${({ margin }) => (margin ? margin : '0px')};
  border: ${({ theme }) => theme.borders.input.default};
  width: 100%;
  resize: none;

  ::placeholder {
    color: #ffffff;
  }

  :focus {
    border: ${({ theme }) => theme.borders.input.focused};
  }

  :focus ~ ${Label} {
    color: ${({ theme }) => theme.primary.light};
    top: -0.9rem;
  }

  :not(:focus) &::placeholder {
    color: ${({ theme }) => theme.primary.xlight};
  }

  :not(:placeholder-shown) ~ ${Label} {
    color: ${({ theme, valid }) => (valid ? theme.primary.light : theme.status.danger)};
    top: -1rem;
  }

  :not(:placeholder-shown) {
    border: ${({ valid, theme }) =>
      valid ? theme.borders.input.focused : theme.borders.input.error};
  }
`;

export const StyledInput = styled.input<StyledInputProps>`
  color: ${({ theme }) => theme.primary.dark};
  border: ${({ theme, valid }) =>
    valid ? theme.borders.input.default : theme.borders.input.error};
  border-radius: 0.35rem;
  padding: 0.3rem 0.3rem 0.3rem 1.5rem;
  font-size: ${({ theme }) => theme.fonts.subBody + 0.2}rem;
  width: ${({ width }) => (width ? width : '100%')};
  height: 4.35rem;
  margin: ${({ margin }) => (margin ? margin : '0px')};
  border: ${({ theme }) => theme.borders.input.default};
  padding-left: ${({ type }) => type === 'currency' && '2.5rem'};

  ~ ${Label} {
    margin-left: ${({ type }) => type === 'currency' && '1rem'};
  }

  ::placeholder {
    color: #ffffff;
  }

  :focus {
    border: ${({ theme }) => theme.borders.input.focused};
  }

  :focus ~ ${Label} {
    color: ${({ theme }) => theme.primary.light};
    top: -0.9rem;
  }

  :not(:focus) &::placeholder {
    color: ${({ theme }) => theme.primary.xlight};
  }

  :not(:placeholder-shown) ~ ${Label} {
    color: ${({ theme, valid }) => (valid ? theme.primary.light : theme.status.danger)};
    top: -1rem;
  }

  :not(:placeholder-shown) {
    border: ${({ valid, theme }) =>
      valid ? theme.borders.input.focused : theme.borders.input.error};
  }
`;

export const IconRight = styled.img<{ iconTop?: string; iconWidth?: string; iconHeight?: string }>`
  position: absolute;
  ${({ iconWidth = null }) => iconWidth && `width: ${iconWidth}`};
  ${({ iconHeight = null }) => iconHeight && `height: ${iconHeight}`};
  top: ${({ iconTop = null }) => (iconTop ? iconTop : '1.1rem')};
  right: 1.3rem;
  cursor: pointer;
`;

export const CurrencySymbol = styled.span`
  color: ${({ theme }) => theme.primary.dark};
  position: absolute;
  left: 1.5rem;
  top: 1rem;
  z-index: 100;
`;
