import * as yup from 'yup';

const loginCredentialsSchema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .required()
    .min(8),
  rememberMe: yup.boolean()
});

export type LoginCredentials = yup.InferType<typeof loginCredentialsSchema>;

export default loginCredentialsSchema;
