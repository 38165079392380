import { apiRequest, apiFetch } from '../Utils';
import { getUserRegistrationData } from './Team';

import { server } from '../../../CONSTANTS';

import { APIResult, APIThunkResult, CompanyDemographic, UserDemographic } from '../../Types';
import { RegisterUserForEventResponse, CheckEventAvailabilityResponse } from '../../Types';
import { CreateUserEventRegistrationSchema } from '../../validators/createUserEventRegistrationSchema';
import { CheckEventAvailabilitySchema } from '../../validators/checkEventAvailabilitySchema';
import {
  setCompanyDemographics,
  setUserDemographics
} from 'src/lib/Store/contexts/participant/event/actions';

export type RegisterForEvent = (
  data: CreateUserEventRegistrationSchema
) => Promise<APIResult<RegisterUserForEventResponse>>;
export function registerForEvent(
  data: CreateUserEventRegistrationSchema
): APIThunkResult<RegisterUserForEventResponse> {
  return apiRequest<RegisterUserForEventResponse>(async dispatch => {
    const result = await apiFetch<RegisterUserForEventResponse>({
      method: 'POST',
      url: `${server}/users/me/event-register`,
      body: data
    });
    dispatch(getUserRegistrationData());
    return result;
  });
}

export type CheckEventAvailability = (
  data: CheckEventAvailabilitySchema
) => Promise<APIResult<CheckEventAvailabilityResponse>>;
export function checkEventAvailability(
  data: CreateUserEventRegistrationSchema
): APIThunkResult<CheckEventAvailabilityResponse> {
  return apiRequest<CheckEventAvailabilityResponse>(async () => {
    const result = await apiFetch<CheckEventAvailabilityResponse>({
      method: 'POST',
      url: `${server}/users/me/check-event-availability`,
      body: data
    });
    return result;
  });
}

export type GetMyCompanyDemographics = () => Promise<APIResult<CompanyDemographic[]>>;
export function getMyCompanyDemographics(): APIThunkResult<CompanyDemographic[]> {
  return apiRequest<any>(async (dispatch, getState) => {
    const { companyId } = getState().userState.userCompany;
    const data = await apiFetch<CompanyDemographic[]>({
      method: 'GET',
      url: `${server}/companies/current-demographics/${companyId}`
    });

    dispatch(setCompanyDemographics(data));
    return data;
  });
}

export function getMyUserDemographics(): APIThunkResult<UserDemographic[]> {
  return apiRequest<UserDemographic[]>(async dispatch => {
    const results = await apiFetch<UserDemographic[]>({
      method: 'GET',
      url: `${server}/users/me/demographic-answers`
    });

    dispatch(setUserDemographics(results));
    return results;
  });
}
