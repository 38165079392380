import styled from 'styled-components/macro';
import { openQuotes } from '../../../../assets/img';
import { FlexRow } from '../../../../Themes';
import { Button } from '../../../../components/UI';

export const QuoteSymbol = styled.div`
  background-image: url(${openQuotes});
  background-size: contain;
  background-repeat: no-repeat;
  height: 3vmin;
  width: auto;
  margin-bottom: 0.5vw;
`;

export const CompanyQuote = styled.div`
  padding: 0 ${({ theme }) => theme.pad.md}% ${({ theme }) => theme.pad.xsm}%
    ${({ theme }) => theme.pad.lg}%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 60%;
  margin-top: 1rem;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.white};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.lighter};
`;

export const BtnWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const RegBtn = styled(Button)`
  @media (max-width: ${({ theme }) => theme.device.desktop}px) {
    width: 65%;
  }
  @media (max-width: ${({ theme }) => theme.device.laptopL}px) {
    width: 75%;
  }

  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    width: 65%;
  }
`;

export const ORText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading}px;
  font-size: 24px;
  color: ${({ theme }) => theme.backgrounds.grey};
  letter-spacing: -0.06px;
  line-height: 28px;
  text-align: left;
  font-style: italic;
  margin-top: 2rem;
`;

// Mobile Styles
export const QuoteSymbolMob = styled.div`
  background-image: url(${openQuotes});
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  margin-top: auto;
  height: 15px;
  padding: 5px 0 0 0;
`;

export const CompanyQuoteMobile = styled(FlexRow)`
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  box-shadow: ${({ theme }) => theme.shadows.lighter};
  background-color: ${({ theme }) => theme.backgrounds.white};
  padding: ${({ theme }) => theme.pad.md}%;
  display: none;
  flex-direction: column;
  margin: ${({ theme }) => theme.pad.md}% 0;
  align-items: flex-end;
  @media (max-width: ${({ theme }) => theme.device.tablet}px) {
    display: flex;
  }
`;
